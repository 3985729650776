import * as PropTypes from "prop-types";
import React from "react";

import { ExpandableIcon } from "~/components/Checklist/TriangleIcon";

export const Category = ({
  hasChildren,
  collapsed,
  category,
  categoryName,
  toggleCollapsedCategory,
  style,
}) => {
  const isParent = !category.parentId;
  let toggleCollapse;
  let arrow;
  if (isParent && hasChildren) {
    toggleCollapse = () => toggleCollapsedCategory(category.id);
    arrow = <ExpandableIcon collapsed={collapsed} />;
  }
  const className = `${
    isParent ? "tr--parent" : "tr--child"
  } checklist-category`;
  return (
    <div className={className} style={style}>
      <div className="sticky-table-cell" onClick={toggleCollapse}>
        <div className="checklist-table__category-cell">
          {arrow}
          <div>{categoryName}</div>
        </div>
      </div>
    </div>
  );
};

Category.propTypes = {
  hasChildren: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool,
  category: PropTypes.object.isRequired,
  categoryName: PropTypes.string.isRequired,
  toggleCollapsedCategory: PropTypes.func.isRequired,
  style: PropTypes.object,
};

Category.defaultProps = { style: {} };

export default Category;
