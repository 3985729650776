import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { Object } from "ts-toolbelt";

import {
  useAddCategoryMutation,
  useAddTopicMutation,
  useRemoveCategoryMutation,
  useRemoveTopicMutation,
} from "../api/mutations.generated";
import {
  CategoriesQuery,
  TopicsQuery,
  useCategoriesLazyQuery,
  useTopicsLazyQuery,
} from "../api/queries.generated";
import { useSaving } from "./QAndAContext";
import { QuestionContextValue } from "./QuestionContext";

type Category = Object.Path<CategoriesQuery, ["questionCategories", number]>;
type Topic = Object.Path<TopicsQuery, ["questionTopics", number]>;

export interface QuestionCategoriesContextValue {
  availableCategories: readonly Category[];
  availableTopics: readonly Topic[];
  hasWritePermission: boolean;
  addCategory: (question: QuestionContextValue, category: Category) => void;
  removeCategory: (question: QuestionContextValue, category: Category) => void;
  addTopic: (question: QuestionContextValue, topic: Topic) => void;
  removeTopic: (question: QuestionContextValue, topic: Topic) => void;
}

const QuestionCategoriesContext = createContext<QuestionCategoriesContextValue>(
  null as any
);

export const useQuestionCategories = () =>
  useContext(QuestionCategoriesContext);

export const QuestionCategoriesProvider: FC<{
  logId: string | null;
  canEditQuestions: boolean;
}> = ({ logId, canEditQuestions, children }) => {
  const [getCategories, categoriesQuery] = useCategoriesLazyQuery();
  const [addCategory, { loading: loadingAddCategory }] =
    useAddCategoryMutation();
  const [removeCategory, { loading: loadingRemoveCategory }] =
    useRemoveCategoryMutation();

  useSaving(loadingAddCategory);
  useSaving(loadingRemoveCategory);

  const [getTopics, topicsQuery] = useTopicsLazyQuery();
  const [addTopic, { loading: loadingAddTopic }] = useAddTopicMutation();
  const [removeTopic, { loading: loadingRemoveTopic }] =
    useRemoveTopicMutation();
  useEffect(() => {
    if (logId) {
      getCategories({ variables: { logId } });
      getTopics({ variables: { logId } });
    }
  }, [logId, getCategories, getTopics]);

  useSaving(loadingAddTopic);
  useSaving(loadingRemoveTopic);

  const hasWritePermission = canEditQuestions;

  const context: QuestionCategoriesContextValue = useMemo(
    () => ({
      availableCategories: categoriesQuery.data?.questionCategories ?? [],

      availableTopics: topicsQuery.data?.questionTopics ?? [],

      hasWritePermission,

      addCategory: (question, category) => {
        addCategory({
          variables: { questionId: question.id, categoryId: category.id },
          optimisticResponse: {
            __typename: "Mutation",
            addQuestionCategory: {
              ...question,
              categories: [...question.categories, category],
            },
          },
        });
      },

      removeCategory: (question, category) => {
        removeCategory({
          variables: { questionId: question.id, categoryId: category.id },
          optimisticResponse: {
            __typename: "Mutation",
            removeQuestionCategory: {
              ...question,
              categories: question.categories.filter(
                (c) => c.id !== category.id
              ),
            },
          },
        });
      },

      addTopic: (question, topic) => {
        addTopic({
          variables: { questionId: question.id, topicId: topic.id },
          optimisticResponse: {
            __typename: "Mutation",
            addQuestionTopic: {
              ...question,
              topics: [...question.topics, topic],
            },
          },
        });
      },

      removeTopic: (question, topic) => {
        removeTopic({
          variables: { questionId: question.id, topicId: topic.id },
          optimisticResponse: {
            __typename: "Mutation",
            removeQuestionTopic: {
              ...question,
              topics: question.topics.filter((t) => t.id !== topic.id),
            },
          },
        });
      },
    }),
    [
      categoriesQuery.data,
      topicsQuery.data,
      hasWritePermission,
      addCategory,
      removeCategory,
      addTopic,
      removeTopic,
    ]
  );

  return (
    <QuestionCategoriesContext.Provider value={context} children={children} />
  );
};

export default QuestionCategoriesContext;
