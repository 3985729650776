import React from "react";

import { UserInvitationsManagerProps } from "~/components/Admin/User/useUserInvitationsManager";
import { DangerButton, SecondaryButton } from "~/components/common/buttons";
import { Toolbar } from "~/components/common/toolbars";
import Dialog from "~/components/generic/Dialog";

export interface DeleteUserDialogProps extends UserInvitationsManagerProps {
  orgName: string;
}

export const DeleteUserDialog = (props: DeleteUserDialogProps) => {
  const {
    deleteVisible,
    error,
    close,
    confirmDelete,
    invitation,
    loading,
    orgName,
  } = props;

  const cancel = () => {
    close();
  };

  return (
    <Dialog
      isOpen={deleteVisible}
      header="Remove User"
      onRequestClose={cancel}
      actions={
        <Toolbar>
          <SecondaryButton onClick={cancel}>Cancel</SecondaryButton>
          <DangerButton
            disabled={loading}
            onClick={() => {
              confirmDelete();
            }}
          >
            Delete
          </DangerButton>
        </Toolbar>
      }
    >
      <div className="alert alert-warning">
        {`You are about to remove user ${invitation?.email} from accessing ${orgName}. Are you sure?`}
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </Dialog>
  );
};
