import React from "react";

import ChecklistFilterDropdown from "~/components/Checklist/ChecklistFilterDropdown/ChecklistFilterDropdown";
import {
  ACTIVITY_TYPES,
  FILE_TYPES,
} from "~/components/Documents/useDocumentsHistoryFilterOptions";

export interface DocumentsHistoryFiltersProps {
  activityTypes: string[];
  setActivityTypes: (selected: number[]) => void;
  fileTypes: string[];
  setFileTypes: (selected: string[]) => void;
  userGroupIds: string[];
  setUserGroupIds: (selected: number[]) => void;
  ancestorIds: string[];
  setAncestorIds: (selected: number[]) => void;
  userGroupItems: any[];
  ancestorItems: any[];
  showUserGroupPicker: boolean;
}

interface RenderDropdownItemProps {
  item: { key: string; value: string };
  selected: boolean;
}

const renderDropdownItem = ({ item, selected }: RenderDropdownItemProps) => [
  <span key="ok" className="filter-dropdown__selected">
    {selected && <i className="icon icon--ok-blue" />}
  </span>,
  item.value,
];

export const DocumentsHistoryFilters = React.memo(
  function DocumentsHistoryFilters(props: DocumentsHistoryFiltersProps) {
    const {
      activityTypes,
      setActivityTypes,
      fileTypes,
      setFileTypes,
      userGroupIds,
      setUserGroupIds,
      ancestorIds,
      setAncestorIds,
      userGroupItems,
      ancestorItems,
      showUserGroupPicker,
    } = props;

    return (
      <div className="btn-group ml-auto" style={{ marginTop: "-1.375rem" }}>
        <ChecklistFilterDropdown
          items={ancestorItems}
          label={"Folders"}
          title={"Filter by Folders"}
          selectedOptions={ancestorIds}
          onSelected={(selected) => setAncestorIds(selected)}
          multiple
        >
          {renderDropdownItem}
        </ChecklistFilterDropdown>
        <ChecklistFilterDropdown
          items={FILE_TYPES}
          label={"File Type"}
          title={"Filter by File Type/Extension"}
          selectedOptions={fileTypes}
          onSelected={(selected) => setFileTypes(selected)}
          multiple
        >
          {renderDropdownItem}
        </ChecklistFilterDropdown>
        <ChecklistFilterDropdown
          items={ACTIVITY_TYPES}
          label={"Activity"}
          title={"Filter by Activity"}
          selectedOptions={activityTypes}
          onSelected={(selected) => setActivityTypes(selected)}
          multiple
        >
          {renderDropdownItem}
        </ChecklistFilterDropdown>
        {showUserGroupPicker && (
          <ChecklistFilterDropdown
            items={userGroupItems}
            label={"User Group"}
            title={"Filter by User Group"}
            selectedOptions={userGroupIds}
            onSelected={(selected) => setUserGroupIds(selected)}
            multiple
          >
            {renderDropdownItem}
          </ChecklistFilterDropdown>
        )}
      </div>
    );
  }
);
