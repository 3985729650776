import { gql } from "@apollo/client/core";
import React, { useRef, useState } from "react";
import styled from "styled-components";

import { PageContainer } from "~/components/common/layout";
import { Toolbar } from "~/components/common/toolbars";
import { useUpdateUserAgreementsMutation } from "~/components/generic/Agreements.generated";
import { EulaText } from "~/components/generic/eulaText";
import useIsScrollComplete from "~/components/hooks/useIsScrollComplete";

export const UPDATE_USER_AGREEMENTS_MUTATION_FRAGMENT = gql`
  mutation UpdateUserAgreements(
    $acceptedAgreements: Boolean!
    $emailAgreement: Boolean
  ) {
    updateUserAgreements(
      acceptedAgreements: $acceptedAgreements
      emailAgreement: $emailAgreement
    ) {
      agreements {
        acceptedEula
      }
    }
  }
`;

interface AgreementsProps {
  titleVariant?: "regular" | "large";
  widthVariant?: "small" | "medium" | "large";
}

export const Agreements = ({ titleVariant = "regular" }: AgreementsProps) => {
  const [eulaChecked, setEulaChecked] = useState(false);
  const [tosChecked, setTosChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const checkedTheBoxes = eulaChecked && tosChecked;
  const ref = useRef<HTMLDivElement | null>(null);
  const { isScrollComplete } = useIsScrollComplete({ ref: ref });
  const [updateAgreement] = useUpdateUserAgreementsMutation({
    onCompleted: () => (window.location.href = "/"),
  });

  const handleSubmit = () => {
    updateAgreement({
      variables: {
        acceptedAgreements: eulaChecked && tosChecked,
        emailAgreement: emailChecked,
      },
    });
  };

  return (
    <PageContainer>
      <Header>
        <Title variant={titleVariant}>End User License Agreement</Title>
      </Header>
      <Body>
        <ScrollArea ref={ref}>
          <EulaText />
        </ScrollArea>
      </Body>
      <Footer>
        <Actions>
          <div className="d-flex flex-column justify-content-between align-items-center">
            <div className="d-flex justify-content-between mb-4 font-weight-bold">
              <div className="d-flex align-items-center mr-4">
                <input
                  id="license"
                  type="checkbox"
                  className="mr-2"
                  disabled={!isScrollComplete}
                  checked={eulaChecked}
                  onChange={() => setEulaChecked(!eulaChecked)}
                />
                <label htmlFor="license" className="m-0 flex-grow-1">
                  {"Accept End User Agreement"}
                </label>
              </div>
              <div className="d-flex align-items-center ml-2">
                <input
                  id="email"
                  type="checkbox"
                  className="mr-2"
                  disabled={!isScrollComplete}
                  checked={tosChecked}
                  onChange={() => setTosChecked(!tosChecked)}
                />
                <label htmlFor="email" className="m-0 flex-grow-1">
                  I agree to the{" "}
                  <a href="https://www.dock.energy/terms">Terms of Service</a>{" "}
                  and{" "}
                  <a href="https://www.dock.energy/privacy">Privacy Policy</a>
                </label>
              </div>
              <div className="d-flex align-items-center ml-2">
                <input
                  id="email"
                  type="checkbox"
                  className="mr-2"
                  checked={emailChecked}
                  onChange={() => setEmailChecked(!emailChecked)}
                />
                <label htmlFor="email" className="m-0 flex-grow-1">
                  Email Agreement
                </label>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary btn-lg flex-grow-0"
              disabled={!checkedTheBoxes}
              onClick={handleSubmit}
            >
              {"OK"}
            </button>
          </div>
        </Actions>
      </Footer>
    </PageContainer>
  );
};

const Footer = styled.div`
  padding: 18px 25px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  font-size: 14px;
`;
const Body = styled.div`
  padding: 18px 25px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  font-size: 14px;
`;

const ScrollArea = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 25px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
`;

const Title = styled.span<{ variant?: "regular" | "large" }>`
  font-size: ${(props) => (props.variant === "large" ? "24px" : "21px")};
`;

const Actions = styled.div`
  text-align: center;
  padding-bottom: 4px;
  margin-top: 34px;

  ${Toolbar} {
    justify-content: center;
  }
`;
