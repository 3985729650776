// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { HomePageProjectsBannerFragment } from './ProjectsBanner.generated';
import { DealRoomBannerFragment } from '../DealRoom/DealRoomBanner.generated';
import { RecentlyVisitedFragment } from './RecentlyVisited.generated';
import { OrgFragmentFragment } from '../../Navbar.generated';
import { gql } from '@apollo/client';
import { HomePageProjectsBannerFragmentDoc } from './ProjectsBanner.generated';
import { DealRoomBannerFragmentDoc } from '../DealRoom/DealRoomBanner.generated';
import { RecentlyVisitedFragmentDoc } from './RecentlyVisited.generated';
import { OrgFragmentFragmentDoc } from '../../Navbar.generated';
import * as Apollo from '@apollo/client';
export type HomePageQueryVariables = Types.Exact<{
  recentlyVisitedLimit: Types.Scalars['Int'];
  orgSlug: Types.Scalars['String'];
}>;


export type HomePageQuery = (
  { readonly __typename: 'Query' }
  & HomePageProjectsBannerFragment
  & DealRoomBannerFragment
  & RecentlyVisitedFragment
);

export type HomePageViewerFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'fullName' | 'isSuperUser'>
  & { readonly company?: Types.Maybe<(
    { readonly __typename: 'Company' }
    & Pick<Types.Company, 'id' | 'name' | 'logoUrl'>
  )>, readonly permissions?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'UserPermissions' }
    & Pick<Types.UserPermissions, 'name' | 'holdingCompanyIds' | 'portfolioIds'>
  )>>, readonly organizations?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Organization' }
    & Pick<Types.Organization, 'id' | 'name' | 'slug' | 'permissions'>
    & OrgFragmentFragment
  )>> }
);

export const HomePageViewerFragmentDoc = gql`
    fragment HomePageViewer on User {
  id
  fullName
  company {
    id
    name
    logoUrl
  }
  isSuperUser
  permissions {
    name
    holdingCompanyIds
    portfolioIds
  }
  organizations {
    id
    name
    slug
    permissions
    ...OrgFragment
  }
}
    ${OrgFragmentFragmentDoc}`;
export const HomePageDocument = gql`
    query HomePage($recentlyVisitedLimit: Int!, $orgSlug: String!) {
  ...HomePageProjectsBanner
  ...DealRoomBanner
  ...RecentlyVisited
}
    ${HomePageProjectsBannerFragmentDoc}
${DealRoomBannerFragmentDoc}
${RecentlyVisitedFragmentDoc}`;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *      recentlyVisitedLimit: // value for 'recentlyVisitedLimit'
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useHomePageQuery(baseOptions: Apollo.QueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
        return Apollo.useQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, baseOptions);
      }
export function useHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
          return Apollo.useLazyQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, baseOptions);
        }
export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;
export type HomePageLazyQueryHookResult = ReturnType<typeof useHomePageLazyQuery>;
export type HomePageQueryResult = Apollo.QueryResult<HomePageQuery, HomePageQueryVariables>;