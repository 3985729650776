import { useEffect, useMemo } from "react";

import { useDocumentsUserGroupsLazyQuery } from "./DocumentsAPI.generated";
import { DocumentsQueries } from "./useDocumentsQueries";

export interface DocumentsHistoryFilters {
  userGroupItems: any[];
  ancestorItems: any[];
}

export const ACTIVITY_TYPES = [
  { key: "1", value: "Upload" },
  { key: "2", value: "Rename" },
  { key: "3", value: "Move" },
  { key: "4", value: "Create Folder" },
  { key: "5", value: "Delete" },
];

export const FILE_TYPES = [
  { key: "1", value: "Folder" },
  { key: "2", value: "File" },
  { key: ".pdf", value: ".pdf" },
  { key: ".csv", value: ".csv" },
  { key: ".xlsx", value: ".xlsx" },
];

/** Extracts filter options from history query and userGroups query */
export const useDocumentsHistoryFilterOptions = (
  treeId: string,
  { history }: DocumentsQueries,
  isDocumentsAdmin: boolean
): DocumentsHistoryFilters => {
  const [request, userGroupsQuery] = useDocumentsUserGroupsLazyQuery({
    variables: { treeId },
  });
  useEffect(() => {
    if (isDocumentsAdmin) request();
  }, [request, isDocumentsAdmin]);
  const userGroupItems = useMemo(
    () =>
      userGroupsQuery.data?.userGroups?.map(({ id, name }) => ({
        key: id,
        value: name ?? "",
      })) ?? [],
    [userGroupsQuery.data]
  );

  const ancestorItems = useMemo(
    () =>
      history.data?.document?.children?.map(({ id, name }) => ({
        key: id,
        value: name ?? "",
      })) ?? [],
    [history.data]
  );

  return { userGroupItems, ancestorItems };
};
