import { MAX_PIXEL_SIZE, MIN_PIXEL_SIZE } from "components/maps/constants";

export const getRelativeSize = (value: number, total: number) => {
  const degrees = MAX_PIXEL_SIZE - MIN_PIXEL_SIZE;
  return degrees * (value / total) + MIN_PIXEL_SIZE;
};

export const getMarkerStyle = (value: number, total: number, count: number) => {
  const size = count > 1 ? getRelativeSize(value, total) : MIN_PIXEL_SIZE;
  return { width: size, height: size };
};
