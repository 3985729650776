import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { black, blue, red, white } from "~/styles/theme/color";

interface SliderToggleProps {
  title: string;
  value: boolean;
  onClick: (state: { on: boolean; setOn: (value: boolean) => void }) => void;
  loading: boolean;
  activeLabel?: string;
  inactiveLabel?: string;
  labelColor?: string;
  activeColor?: string;
  inactiveColor?: string;
}

/** Update of the forms/ToggleButton component using styled components **/
const SliderToggle = ({
  title,
  value,
  onClick,
  loading,
  activeLabel = "On",
  inactiveLabel = "Off",
  activeColor = blue,
  inactiveColor = red,
  labelColor = black,
}: SliderToggleProps) => {
  const [on, setOn] = useState(false);

  // track the value prop
  useEffect(() => setOn(value), [value]);

  return (
    <Label disabled={loading} title={title} labelColor={labelColor}>
      <Input
        type="checkbox"
        tabIndex={0}
        checked={on}
        onChange={() => {
          if (loading) return;
          onClick ? onClick({ on, setOn }) : setOn(!on);
        }}
      />
      <Slider
        active={on}
        activeColor={activeColor}
        inactiveColor={inactiveColor}
      >
        <Ball active={on} inactiveColor={inactiveColor} />
      </Slider>
      {on ? activeLabel : inactiveLabel}
    </Label>
  );
};

const Label = styled.label<{ disabled: boolean; labelColor: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 0 10px;
  margin-bottom: 0;
  cursor: pointer;
  overflow: hidden;
  color: ${(props) => props.labelColor};

  ${(props) =>
    props.disabled &&
    css`
      cursor: wait;
    `}
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  left: -100%;

  &:focus {
    box-shadow: 0 0 0 1px #5e9ed6;
  }
`;

const Slider = styled.div<{
  active: boolean;
  activeColor: string;
  inactiveColor: string;
}>`
  position: relative;
  transition: all 0.4s ease;
  margin-right: 8px;

  height: 15px;
  width: 24px;
  border-radius: 7.5px;
  background-color: ${(props) => (props.active ? props.activeColor : white)};
  border: 1px solid
    ${(props) => (props.active ? props.activeColor : props.inactiveColor)};
`;

const Ball = styled.span<{ active: boolean; inactiveColor: string }>`
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  bottom: 1px;
  -webkit-transition: 0.4s;
  transition: 0.3s all ease-in;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? white : props.inactiveColor)};
  top: 1px;
  left: ${(props) => (props.active ? "10px" : "2px")};
`;

export default SliderToggle;
