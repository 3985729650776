// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { ReportingDetailFragment } from './ReportingDetail.generated';
import { ReportingListFragment } from './ReportingList.generated';
import { gql } from '@apollo/client';
import { ReportingDetailFragmentDoc } from './ReportingDetail.generated';
import { ReportingListFragmentDoc } from './ReportingList.generated';
import * as Apollo from '@apollo/client';
export type ReportingPageQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
}>;


export type ReportingPageQuery = (
  { readonly __typename: 'Query' }
  & ReportingDetailFragment
  & ReportingListFragment
);


export const ReportingPageDocument = gql`
    query ReportingPage($orgSlug: String!) {
  ...ReportingDetail
  ...ReportingList
}
    ${ReportingDetailFragmentDoc}
${ReportingListFragmentDoc}`;

/**
 * __useReportingPageQuery__
 *
 * To run a query within a React component, call `useReportingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingPageQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useReportingPageQuery(baseOptions: Apollo.QueryHookOptions<ReportingPageQuery, ReportingPageQueryVariables>) {
        return Apollo.useQuery<ReportingPageQuery, ReportingPageQueryVariables>(ReportingPageDocument, baseOptions);
      }
export function useReportingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportingPageQuery, ReportingPageQueryVariables>) {
          return Apollo.useLazyQuery<ReportingPageQuery, ReportingPageQueryVariables>(ReportingPageDocument, baseOptions);
        }
export type ReportingPageQueryHookResult = ReturnType<typeof useReportingPageQuery>;
export type ReportingPageLazyQueryHookResult = ReturnType<typeof useReportingPageLazyQuery>;
export type ReportingPageQueryResult = Apollo.QueryResult<ReportingPageQuery, ReportingPageQueryVariables>;