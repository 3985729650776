import React from "react";
import styled from "styled-components";

import { gray300 } from "~/styles/theme/color";

export interface DocumentsErrorProps {
  src: string;
}

export const DocumentsError = React.memo(function DocumentsError(
  props: DocumentsErrorProps
) {
  return (
    <FilePreviewContainer>
      <FilePreviewIframe
        title="Preview"
        src={`/file-store/${props.src}`}
        isLoading={false}
      />
    </FilePreviewContainer>
  );
});

const FilePreviewContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  margin-bottom: 0.75rem;
  border: 1px solid ${gray300};
`;

const FilePreviewIframe = styled.iframe<{ isLoading: boolean }>`
  display: ${(props) => props.isLoading && "none"};
  width: 100%;
  height: 100%;
  border: none;
`;
