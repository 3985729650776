import React from "react";

import { SecondaryButton } from "~/components/common/buttons";
import { PencilIcon } from "~/components/common/icons";

interface EditProjectButtonProps {
  orgSlug: string;
  projectId: string;
  portfolioSlug?: string;
}

export const EditProjectButton = React.memo(function ProjectsExportButton(
  props: EditProjectButtonProps
) {
  const { orgSlug, projectId, portfolioSlug } = props;
  const search = (portfolioSlug && `?portfolioSlug=${portfolioSlug}`) || "";

  return (
    <SecondaryButton
      as="a"
      href={`/${orgSlug}/projects/${projectId}/edit${search}`}
    >
      <PencilIcon />
      <span>Edit Project</span>
    </SecondaryButton>
  );
});
