// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type OAndMBannerFragment = (
  { readonly __typename: 'Query' }
  & { readonly partners?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'OAndMPartner' }
    & Pick<Types.OAndMPartner, 'id' | 'name' | 'projectCount' | 'totalCapacity'>
  )>>> }
);

export const OAndMBannerFragmentDoc = gql`
    fragment OAndMBanner on Query {
  partners: oAndMPartners(orgSlug: $orgSlug) {
    id
    name
    projectCount
    totalCapacity
  }
}
    `;