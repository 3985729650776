import { Icon } from "components/icons";
import * as PropTypes from "prop-types";
import React from "react";

class OkIcon extends React.PureComponent {
  render() {
    return (
      <Icon
        color="success"
        className={this.props.className}
        viewBox="0 0 14 12"
        withoutBackground={this.props.withoutBackground}
      >
        <defs>
          <path
            d="M14.544 3.332l-1.747-1.153c-.485-.314-1.154-.204-1.497.25L6.542
                        8.8 3.908 7.073c-.476-.321-1.154-.212-1.488.243L1.19 8.964a.972.972
                        0 0 0 .26 1.396l3.512 2.306.025.016 1.722
                        1.137c.243.157.535.212.803.165.268-.047.527-.189.694-.416l6.597-8.84a.96.96
                        0 0 0-.259-1.396z"
            id="ok-icon-a"
          />
        </defs>
        <g transform="translate(-1 -2)" fill="none" fillRule="evenodd">
          <mask id="ok-icon-b" fill="#fff">
            <use xlinkHref="#ok-icon-a" />
          </mask>
          <use xlinkHref="#ok-icon-a" />
          <g mask="url(#ok-icon-b)">
            <path d="M0 0h16v16H0z" />
          </g>
        </g>
      </Icon>
    );
  }
}

OkIcon.propTypes = {
  className: PropTypes.string,
  withoutBackground: PropTypes.bool,
};

OkIcon.defaultProps = {
  className: null,
  withoutBackground: false,
};

export default OkIcon;
