import { useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";

import { useDeleteGroupMutation } from "~/components/Admin/Group/GroupsAPI.generated";
import { GroupRowFragment } from "~/components/Admin/Group/GroupsTable.generated";

export interface GroupManagerProps {
  error: string | null;
  loading: boolean;
  group: GroupRowFragment | null;
  deleteVisible: boolean;
  updateVisible: boolean;
  remove(group: GroupRowFragment): void;
  update(group: GroupRowFragment): void;
  close(): void;
  confirmDelete(): Promise<void>;
}

/** Handles state and logic required for removing user groups. */
export const useGroupManager = (orgSlug: string): GroupManagerProps => {
  const client = useApolloClient();
  const [group, setGroup] = useState<GroupRowFragment | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [updateVisible, setUpdateVisible] = useState<boolean>(false);
  const [requestDelete, { loading }] = useDeleteGroupMutation();

  const evict = useCallback(
    (id: string) => {
      client.cache.evict({ id: `UserGroup:${id}` });
    },
    [client.cache]
  );

  const close = useCallback(() => {
    setGroup(null);
    setError(null);
    setDeleteVisible(false);
    setUpdateVisible(false);
  }, []);

  /** Set the group to update */
  const update = (group: GroupRowFragment) => {
    setGroup(group);
    setUpdateVisible(true);
  };

  /** Set the group to delete */
  const remove = (group: GroupRowFragment) => {
    setGroup(group);
    setDeleteVisible(true);
  };

  /** Deletes the group */
  const confirmDelete = useCallback(async () => {
    try {
      if (group === null) return;
      const { data } = await requestDelete({
        variables: { orgSlug, groupId: group.id },
      });
      if (data?.deleteGroup?.id) evict(data.deleteGroup.id);
      close();
    } catch (error) {
      setError("A server error has occurred");
      console.error(error);
    }
  }, [requestDelete, orgSlug, close, evict, group]);

  return {
    error,
    group,
    loading,
    deleteVisible,
    updateVisible,
    remove,
    update,
    confirmDelete,
    close,
  };
};
