import clsx from "clsx";
import ToggleButton from "components/forms/ToggleButton";
import Dialog from "components/generic/Dialog";
import { BetterAlert as Alert } from "components/profile/Alert";
import { compact } from "lodash";
import React, { useMemo } from "react";
import styled from "styled-components";

import { PermissionsSetup } from "~/api/types.generated";
import { DocumentsTableData } from "~/components/Documents/useDocumentsTableData";

import { DocumentsPermissions } from "./useDocumentsPermissions";

export interface DocumentsPermissionsDialogProps extends DocumentsPermissions {
  permissionType?: PermissionsSetup | null;
}

export const DocumentsPermissionsDialog = (
  props: DocumentsPermissionsDialogProps & DocumentsTableData
) => {
  const {
    updateSelectedVisible: visible,
    error,
    loading,
    updateSelected,
    close,
    permissionType,
    selection,
  } = props;
  const documents = selection.values();
  const permissions = useMemo(
    () =>
      documents.reduce((acc, document) => {
        const perms = compact(document?.permissions).map(
          ({ permissible, principal }) => ({
            // @ts-ignore
            id: principal.id,
            permissible: permissible,
            // @ts-ignore
            name: principal.name,
          })
        );
        perms.forEach((p) => {
          if (acc[p.id]) {
            acc[p.id].permissible =
              p.permissible === acc[p.id].permissible
                ? acc[p.id].permissible
                : null;
          } else {
            acc[p.id] = p;
          }
        });
        return acc;
      }, {} as Record<string, any>),
    [documents]
  );
  return (
    <Dialog
      widthVariant={"small"}
      isOpen={visible}
      onRequestClose={close}
      header={
        <div>
          <span style={{ maxWidth: 200 }}>
            <i className="thumbnail icon icon--folder" />
            <LabelText
              style={{ paddingLeft: "10px" }}
            >{`Selected ${documents.length} files/folders`}</LabelText>
          </span>
        </div>
      }
    >
      <div
        className="settings-page mb-5"
        style={{
          maxHeight: "calc(100vh - 210px)",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "9px 10px",
            fontSize: "12px",
          }}
        >
          <span>
            {permissionType === "GROUP" ? "Group" : "Role"} Permissions
          </span>
          <span>esc to close</span>
        </div>
        {error && <Alert message={error} classNames={["alert-danger"]} />}
        {Object.values(permissions).map(({ id, permissible, name }) => (
          <div
            key={id}
            className={clsx("config-entry", permissible && "_enabled")}
            style={{ fontSize: "14px" }}
          >
            <div
              className="_toggler"
              data-testid="toggle-all-notifications"
              style={{ width: "78px" }}
            >
              <ToggleButton
                title={name}
                value={permissible}
                zState={permissible === null}
                onClick={() => updateSelected(id, !permissible)}
                loading={loading}
              />
            </div>
            <div className="_summary">{name}</div>
            <div className="_description" />
          </div>
        ))}
      </div>
    </Dialog>
  );
};

const LabelText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
