import { gql } from "@apollo/client";
import classNames from "classnames";
import Portfolios, {
  HOLDING_COMPANY_PORTFOLIOS_FRAGMENT,
} from "components/HoldingCompany/Portfolios";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";

import { FundEntityType } from "~/api/types.generated";
import { DEAL_ROOM_CARD_FRAGMENT } from "~/components/DealRoom/DealRoomCard";
import { Documents } from "~/components/Documents/Documents";
import {
  HoldingCompanyBodyFragment,
  useViewHoldingCompanyMutation,
} from "~/components/HoldingCompany/HoldingCompanyBody.generated";
import {
  FUND_ENTITY_OVERVIEW_FRAGMENT,
  OverviewPage,
} from "~/components/Overview/OverviewPage";
import QAndA from "~/components/QAndA";
import { Q_AND_A_SUBJECT_FRAGMENT } from "~/components/QAndA/QAndA";
import { UserGroupMembersPage } from "~/components/UserGroupMembers/UserGroupMembersPage";
import { trimChar } from "~/utils/text";

import { useHoldingCompanyEventTracking } from "./useHoldingCompanyEventTracking";

const ADMIN_ROUTES = ["deal-teams"];

export const HOLDING_COMPANY_BODY_FRAGMENT = gql`
  fragment HoldingCompanyBody on HoldingCompany {
    id
    org {
      id
    }
    documentsTree {
      id
    }
    reportsTree {
      id
    }
    ...HoldingCompanyPortfolios
    ...FundEntityOverview
    ...QAndASubject
  }
  ${HOLDING_COMPANY_PORTFOLIOS_FRAGMENT}
  ${FUND_ENTITY_OVERVIEW_FRAGMENT}
  ${Q_AND_A_SUBJECT_FRAGMENT}
`;

export const HOLDING_COMPANY_VIEW_MUTATION_FRAGMENT = gql`
  mutation ViewHoldingCompany($holdingCompanyId: ID!) {
    createRecentlyVisitedHoldingCompany(id: $holdingCompanyId) {
      ...DealRoomCard
    }
    ${DEAL_ROOM_CARD_FRAGMENT}
  }
`;

interface HoldingCompanyBodyProps {
  holdco: HoldingCompanyBodyFragment;
  isSuperUser: boolean;
  isUsersAdmin: boolean;
  isDocumentsAdmin: boolean;
  areLabelsEnabled: boolean;
  canViewDocumentsHistory: boolean;
  canViewQAndA: boolean;
  isQAndAAdmin: boolean;
  canEditQuestions: boolean;
  canEditQuestionAnswers: boolean;
}

export const HoldingCompanyBody = ({
  holdco,
  isSuperUser,
  isUsersAdmin,
  isDocumentsAdmin,
  areLabelsEnabled,
  canViewDocumentsHistory,
  canViewQAndA,
  isQAndAAdmin,
  canEditQuestions,
  canEditQuestionAnswers,
}: HoldingCompanyBodyProps) => {
  const location = useLocation();
  const pathName = trimChar(location.pathname, "/");
  const [createViewEvent] = useViewHoldingCompanyMutation();
  useEffect(() => {
    createViewEvent({
      variables: { holdingCompanyId: holdco.id },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            recentlyVisited(existingFundEntities = []) {
              const targetRef = cache.writeFragment({
                data: data?.createRecentlyVisitedHoldingCompany,
                fragment: DEAL_ROOM_CARD_FRAGMENT,
              });
              const filteredFundEntities = existingFundEntities.filter(
                (f: any) => f.__ref !== targetRef?.__ref
              );
              return [targetRef, ...filteredFundEntities.slice(0, 5)];
            },
          },
        });
      },
    });
  }, [holdco.id, createViewEvent]);
  // Mixpanel tracking
  const track = useHoldingCompanyEventTracking();
  const boxClasses = classNames({
    "box--white": !pathName.startsWith("storage"),
    "box--admin": ADMIN_ROUTES.includes(pathName),
  });

  return (
    <div className={boxClasses}>
      <Switch>
        <Redirect exact from="/" to="/overview" />

        <Route path="/overview">
          <OverviewPage subject={holdco} onViewSubpage={track.overviewView} />
        </Route>

        <Route path="/portfolios">
          <Portfolios
            holdingCompany={holdco}
            onView={track.portfoliosView}
            onSearchChange={track.portfoliosSearch}
          />
        </Route>

        {holdco.documentsTree && (
          <Route path="/storage/documents/:documentId?">
            <Documents
              basePath="/storage/documents"
              treeId={holdco.documentsTree.id}
              isUsersAdmin={isUsersAdmin}
              isDocumentsAdmin={isDocumentsAdmin}
              areLabelsEnabled={areLabelsEnabled}
              canViewDocumentsHistory={canViewDocumentsHistory}
              onView={track.documentsView}
            />
          </Route>
        )}

        {holdco.reportsTree && (
          <Route path="/storage/reports/:documentId?">
            <Documents
              basePath="/storage/reports"
              treeId={holdco.reportsTree.id}
              isUsersAdmin={isUsersAdmin}
              isDocumentsAdmin={isDocumentsAdmin}
              areLabelsEnabled={areLabelsEnabled}
              canViewDocumentsHistory={canViewDocumentsHistory}
              onView={track.reportsView}
            />
          </Route>
        )}

        {isUsersAdmin && (
          <Route
            path="/deal-teams"
            render={() => (
              <UserGroupMembersPage
                fundEntityId={holdco?.id}
                fundEntityType={FundEntityType.HoldingCompany}
                isSuperUser={isSuperUser}
              />
            )}
          />
        )}

        {canViewQAndA && (
          <Route
            path="/q-and-a"
            render={() => (
              <QAndA
                subject={holdco}
                onView={() => {}}
                isQAndAAdmin={isQAndAAdmin}
                canEditQuestions={canEditQuestions}
                canEditQuestionAnswers={canEditQuestionAnswers}
              />
            )}
          />
        )}

        <Route path="*">
          <Redirect to="/overview" />
        </Route>
      </Switch>
    </div>
  );
};
