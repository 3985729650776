// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Timeline_DetailsFragment = (
  { readonly __typename: 'Timeline' }
  & Pick<Types.Timeline, 'id' | 'name' | 'canEdit'>
  & { readonly milestones?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'Milestone' }
    & Pick<Types.Milestone, 'id' | 'dueDate' | 'title' | 'body' | 'order' | 'status'>
  )>>> }
);

export type Milestone_DetailsFragment = (
  { readonly __typename: 'Milestone' }
  & Pick<Types.Milestone, 'id' | 'dueDate' | 'title' | 'body' | 'order' | 'status'>
);

export type TimelineQueryVariables = Types.Exact<{
  timelineId: Types.Scalars['ID'];
}>;


export type TimelineQuery = (
  { readonly __typename: 'Query' }
  & { readonly timeline?: Types.Maybe<(
    { readonly __typename: 'Timeline' }
    & Timeline_DetailsFragment
  )> }
);

export type CreateMilestoneMutationVariables = Types.Exact<{
  payload: Types.CreateMilestonePayload;
}>;


export type CreateMilestoneMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createMilestone?: Types.Maybe<(
    { readonly __typename: 'CreateMilestoneMutation' }
    & { readonly timeline?: Types.Maybe<(
      { readonly __typename: 'Timeline' }
      & Timeline_DetailsFragment
    )> }
  )> }
);

export type UpdateMilestoneMutationVariables = Types.Exact<{
  payload: Types.UpdateMilestonePayload;
}>;


export type UpdateMilestoneMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateMilestone?: Types.Maybe<(
    { readonly __typename: 'UpdateMilestoneMutation' }
    & { readonly milestone?: Types.Maybe<(
      { readonly __typename: 'Milestone' }
      & Milestone_DetailsFragment
    )> }
  )> }
);

export type DeleteMilestoneMutationVariables = Types.Exact<{
  payload: Types.DeleteMilestonePayload;
}>;


export type DeleteMilestoneMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deleteMilestone?: Types.Maybe<(
    { readonly __typename: 'DeleteMilestoneMutation' }
    & { readonly timeline?: Types.Maybe<(
      { readonly __typename: 'Timeline' }
      & Timeline_DetailsFragment
    )> }
  )> }
);

export type ReorderMilestoneMutationVariables = Types.Exact<{
  payload: Types.ReorderMilestonePayload;
}>;


export type ReorderMilestoneMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly reorderMilestone?: Types.Maybe<(
    { readonly __typename: 'ReorderMilestoneMutation' }
    & { readonly timeline?: Types.Maybe<(
      { readonly __typename: 'Timeline' }
      & Timeline_DetailsFragment
    )> }
  )> }
);

export const Timeline_DetailsFragmentDoc = gql`
    fragment Timeline_details on Timeline {
  id
  name
  canEdit
  milestones {
    id
    dueDate
    title
    body
    order
    status
  }
}
    `;
export const Milestone_DetailsFragmentDoc = gql`
    fragment Milestone_details on Milestone {
  id
  dueDate
  title
  body
  order
  status
}
    `;
export const TimelineDocument = gql`
    query Timeline($timelineId: ID!) {
  timeline(timelineId: $timelineId) {
    ...Timeline_details
  }
}
    ${Timeline_DetailsFragmentDoc}`;

/**
 * __useTimelineQuery__
 *
 * To run a query within a React component, call `useTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineQuery({
 *   variables: {
 *      timelineId: // value for 'timelineId'
 *   },
 * });
 */
export function useTimelineQuery(baseOptions: Apollo.QueryHookOptions<TimelineQuery, TimelineQueryVariables>) {
        return Apollo.useQuery<TimelineQuery, TimelineQueryVariables>(TimelineDocument, baseOptions);
      }
export function useTimelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelineQuery, TimelineQueryVariables>) {
          return Apollo.useLazyQuery<TimelineQuery, TimelineQueryVariables>(TimelineDocument, baseOptions);
        }
export type TimelineQueryHookResult = ReturnType<typeof useTimelineQuery>;
export type TimelineLazyQueryHookResult = ReturnType<typeof useTimelineLazyQuery>;
export type TimelineQueryResult = Apollo.QueryResult<TimelineQuery, TimelineQueryVariables>;
export const CreateMilestoneDocument = gql`
    mutation CreateMilestone($payload: CreateMilestonePayload!) {
  createMilestone(payload: $payload) {
    timeline {
      ...Timeline_details
    }
  }
}
    ${Timeline_DetailsFragmentDoc}`;
export type CreateMilestoneMutationFn = Apollo.MutationFunction<CreateMilestoneMutation, CreateMilestoneMutationVariables>;

/**
 * __useCreateMilestoneMutation__
 *
 * To run a mutation, you first call `useCreateMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMilestoneMutation, { data, loading, error }] = useCreateMilestoneMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<CreateMilestoneMutation, CreateMilestoneMutationVariables>) {
        return Apollo.useMutation<CreateMilestoneMutation, CreateMilestoneMutationVariables>(CreateMilestoneDocument, baseOptions);
      }
export type CreateMilestoneMutationHookResult = ReturnType<typeof useCreateMilestoneMutation>;
export type CreateMilestoneMutationResult = Apollo.MutationResult<CreateMilestoneMutation>;
export type CreateMilestoneMutationOptions = Apollo.BaseMutationOptions<CreateMilestoneMutation, CreateMilestoneMutationVariables>;
export const UpdateMilestoneDocument = gql`
    mutation UpdateMilestone($payload: UpdateMilestonePayload!) {
  updateMilestone(payload: $payload) {
    milestone {
      ...Milestone_details
    }
  }
}
    ${Milestone_DetailsFragmentDoc}`;
export type UpdateMilestoneMutationFn = Apollo.MutationFunction<UpdateMilestoneMutation, UpdateMilestoneMutationVariables>;

/**
 * __useUpdateMilestoneMutation__
 *
 * To run a mutation, you first call `useUpdateMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMilestoneMutation, { data, loading, error }] = useUpdateMilestoneMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMilestoneMutation, UpdateMilestoneMutationVariables>) {
        return Apollo.useMutation<UpdateMilestoneMutation, UpdateMilestoneMutationVariables>(UpdateMilestoneDocument, baseOptions);
      }
export type UpdateMilestoneMutationHookResult = ReturnType<typeof useUpdateMilestoneMutation>;
export type UpdateMilestoneMutationResult = Apollo.MutationResult<UpdateMilestoneMutation>;
export type UpdateMilestoneMutationOptions = Apollo.BaseMutationOptions<UpdateMilestoneMutation, UpdateMilestoneMutationVariables>;
export const DeleteMilestoneDocument = gql`
    mutation DeleteMilestone($payload: DeleteMilestonePayload!) {
  deleteMilestone(payload: $payload) {
    timeline {
      ...Timeline_details
    }
  }
}
    ${Timeline_DetailsFragmentDoc}`;
export type DeleteMilestoneMutationFn = Apollo.MutationFunction<DeleteMilestoneMutation, DeleteMilestoneMutationVariables>;

/**
 * __useDeleteMilestoneMutation__
 *
 * To run a mutation, you first call `useDeleteMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMilestoneMutation, { data, loading, error }] = useDeleteMilestoneMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useDeleteMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMilestoneMutation, DeleteMilestoneMutationVariables>) {
        return Apollo.useMutation<DeleteMilestoneMutation, DeleteMilestoneMutationVariables>(DeleteMilestoneDocument, baseOptions);
      }
export type DeleteMilestoneMutationHookResult = ReturnType<typeof useDeleteMilestoneMutation>;
export type DeleteMilestoneMutationResult = Apollo.MutationResult<DeleteMilestoneMutation>;
export type DeleteMilestoneMutationOptions = Apollo.BaseMutationOptions<DeleteMilestoneMutation, DeleteMilestoneMutationVariables>;
export const ReorderMilestoneDocument = gql`
    mutation ReorderMilestone($payload: ReorderMilestonePayload!) {
  reorderMilestone(payload: $payload) {
    timeline {
      ...Timeline_details
    }
  }
}
    ${Timeline_DetailsFragmentDoc}`;
export type ReorderMilestoneMutationFn = Apollo.MutationFunction<ReorderMilestoneMutation, ReorderMilestoneMutationVariables>;

/**
 * __useReorderMilestoneMutation__
 *
 * To run a mutation, you first call `useReorderMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderMilestoneMutation, { data, loading, error }] = useReorderMilestoneMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useReorderMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<ReorderMilestoneMutation, ReorderMilestoneMutationVariables>) {
        return Apollo.useMutation<ReorderMilestoneMutation, ReorderMilestoneMutationVariables>(ReorderMilestoneDocument, baseOptions);
      }
export type ReorderMilestoneMutationHookResult = ReturnType<typeof useReorderMilestoneMutation>;
export type ReorderMilestoneMutationResult = Apollo.MutationResult<ReorderMilestoneMutation>;
export type ReorderMilestoneMutationOptions = Apollo.BaseMutationOptions<ReorderMilestoneMutation, ReorderMilestoneMutationVariables>;