import React from "react";
import styled, { keyframes } from "styled-components";

import { borderRadius, centerVertically } from "~/styles/mixins";

export const wave = keyframes`
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const Skeleton = styled.span`
  ${borderRadius()};
  display: inline-block;
  vertical-align: middle;
  width: 10rem;
  height: 10rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    animation: ${wave} 1.5s ease-in-out infinite;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.5) 50%,
      transparent
    );
  }
`;

export interface SkeletonTextProps {
  width?: string;
}

export const SkeletonText = (props: SkeletonTextProps) => (
  <SkeletonTextContainer {...props}>
    <Skeleton />
  </SkeletonTextContainer>
);

export const SkeletonTextContainer = styled.span.withConfig<SkeletonTextProps>({
  shouldForwardProp: (prop) => prop !== "width",
})`
  ${centerVertically};
  display: flex;
  width: ${(props) => props.width || "10rem"};

  &:before {
    display: block;
    width: 0;
    content: "Loading...";
    visibility: hidden;
  }

  > ${Skeleton} {
    height: 0.8em;
    margin-top: 0.1em;
  }
`;

export const SkeletonCheckbox = styled(Skeleton)`
  width: 0.875rem;
  height: 0.875rem;
`;

export const SkeletonIcon = styled(Skeleton)`
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
`;

export const SkeletonThumbnail = styled(Skeleton)`
  width: 2rem;
  height: 2rem;
`;
