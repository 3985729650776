import styled from "styled-components";

const SubHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  min-height: 32px;
  font-size: 1.5rem;
`;

export default SubHeader;
