// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type ProjectsMapFragment = (
  { readonly __typename: 'ProjectsCollection' }
  & { readonly projects?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Project' }
    & Pick<Types.Project, 'id' | 'name' | 'capacity' | 'isOwned'>
    & { readonly location: (
      { readonly __typename: 'ProjectLocation' }
      & Pick<Types.ProjectLocation, 'id' | 'latitude' | 'longitude'>
    ) }
  )>> }
);

export const ProjectsMapFragmentDoc = gql`
    fragment ProjectsMap on ProjectsCollection {
  projects {
    id
    name
    capacity
    isOwned
    location {
      id
      latitude
      longitude
    }
  }
}
    `;