import gql from "graphql-tag";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { AddIcon } from "~/components/common/icons";
import { CloseIcon } from "~/components/icons";
import { BetterAlert as Alert } from "~/components/profile/Alert";
import DuplicateReportsTable, {
  DUPLICATE_REPORT_FRAGMENT,
} from "~/components/Settings/DuplicateReportsTable";
import ImportHistory, {
  DATA_IMPORT_FRAGMENT,
} from "~/components/Settings/ImportHistory";
import {
  useCreateDuplicateReportMutation,
  useDataImportsLazyQuery,
  useDuplicateReportsLazyQuery,
  useUploadDataImportMutation,
} from "~/components/Settings/SalesforceAdmin.generated";

export const DATA_IMPORT_QUERY = gql`
  query DataImports {
    dataImports {
      ...DataImport
    }
  }

  ${DATA_IMPORT_FRAGMENT}
`;

export const DATA_IMPORT_MUTATION_FRAGMENT = gql`
  mutation UploadDataImport($file: Upload!) {
    uploadDataImport(file: $file) {
      ...DataImport
    }
  }

  ${DATA_IMPORT_FRAGMENT}
`;

export const DUPLICATE_REPORT_MUTATION_FRAGMENT = gql`
  mutation CreateDuplicateReport {
    createDuplicateReport {
      ...DuplicateReport
    }
  }

  ${DUPLICATE_REPORT_FRAGMENT}
`;

export const DUPLICATE_REPORTS_QUERY = gql`
  query DuplicateReports {
    duplicateReports {
      ...DuplicateReport
    }
  }

  ${DUPLICATE_REPORT_FRAGMENT}
`;

export const SalesforceAdmin = () => {
  const [getUploads, { data, previousData, loading }] = useDataImportsLazyQuery(
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (result) => {
        const processing = result?.dataImports?.some(
          ({ status }: any) =>
            status === "Submitted" || status.startsWith("Processing")
        );
        if (processing) {
          setTimeout(() => getUploads(), 5000);
        }
      },
    }
  );
  const importHistoryRows =
    loading && previousData
      ? previousData.dataImports ?? []
      : data?.dataImports ?? [];
  const [progress, setProgress] = useState(null);
  const [uploadDataImport, { loading: uploading }] =
    useUploadDataImportMutation({
      onCompleted: () => {
        getUploads();
        clearFileSelection();
      },
      onError: (error) => {
        setProgress(null);
        setErrorMessage("Error: " + error);
      },
    });
  const [
    getDuplicateReports,
    {
      data: reportData,
      previousData: previousReportData,
      loading: loadingReports,
    },
  ] = useDuplicateReportsLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      const processing = result?.duplicateReports?.some(
        ({ status }: any) =>
          status === "Submitted" || status.startsWith("Processing")
      );
      if (processing) setTimeout(() => getDuplicateReports(), 5000);
    },
  });
  const duplicateReports =
    loadingReports && previousReportData
      ? previousReportData.duplicateReports ?? []
      : reportData?.duplicateReports ?? [];
  const [createDupeReport, { loading: loadingReport }] =
    useCreateDuplicateReportMutation({
      onCompleted: () => {
        getDuplicateReports();
      },
      onError: (error) => {
        setProgress(null);
        setErrorMessage("Error: " + error);
      },
    });
  const [errorMessage, setErrorMessage] = useState("");
  const [fileName, setFileName] = useState("Please select...");
  const [fileSelected, setFileSelected] = useState(false);
  const [showSelectFile, setShowSelectFile] = useState(false);
  const fileRef = useRef<any>(null);

  useEffect(
    () => {
      getUploads();
      getDuplicateReports();
    },
    [getUploads] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const progressBar = () => (
    <div className="upload-progress-bar">
      <div className="filler" style={{ width: `${progress}%` }} />
    </div>
  );

  const onFileSelected: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    setFileSelected(true);
    setFileName(e.currentTarget.files?.[0]?.name ?? "Please select...");
  };

  const handleSendFile: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    const file = fileRef.current.files[0];
    uploadDataImport({
      variables: { file: file },
    });
  };

  const handleOnClear: React.MouseEventHandler<SVGElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    clearFileSelection();
    return false;
  };

  const clearFileSelection = () => {
    fileRef.current.value = "";
    setFileSelected(false);
    setShowSelectFile(false);
    setFileName("Please select...");
    setProgress(null);
  };

  return (
    <>
      <h4 className="mb-0">
        <Link to="/applications">Applications</Link> &gt; Salesforce
      </h4>
      <div className="row">
        <div className="col-12">
          <div className="settings-page mb-5">
            <Alert message={errorMessage} classNames={["alert-danger"]} />
            <h6 className="settings-page__h3 mt-1">
              Import data to Salesforce.
            </h6>
            <OptionsContainer>
              {showSelectFile && (
                <FileSelectionArea>
                  <FileSelectionBox>
                    <FileLabel htmlFor="file-upload">
                      <ImportButtonText>{fileName}</ImportButtonText>
                      <CloseButton
                        width="12"
                        height="12"
                        fill={"#008CD8"}
                        onClick={handleOnClear}
                      />
                    </FileLabel>
                    <FileInput
                      id="file-upload"
                      type="file"
                      ref={fileRef}
                      accept={".xlsx"}
                      onChange={onFileSelected}
                    />
                  </FileSelectionBox>
                  <PrimaryButton
                    disabled={!fileSelected || uploading}
                    onClick={handleSendFile}
                  >
                    Submit
                  </PrimaryButton>
                </FileSelectionArea>
              )}
              {!showSelectFile && (
                <PrimaryButton onClick={() => setShowSelectFile(true)}>
                  <AddIcon />
                  <span>Import Data</span>
                </PrimaryButton>
              )}
              <SecondaryButton>Disconnect from Salesforce</SecondaryButton>
            </OptionsContainer>
            {progress && progressBar()}
            <TableHeader>Import History</TableHeader>
            <LineBreak />
            <ImportHistory rows={importHistoryRows} loading={loading} />
            <OptionsContainer>
              <TableHeader>Duplicate Reports</TableHeader>
              <PrimaryButton onClick={() => createDupeReport()}>
                Run report
              </PrimaryButton>
            </OptionsContainer>
            <LineBreak />
            <DuplicateReportsTable
              rows={duplicateReports}
              loading={loadingReport}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const TableHeader = styled.div`
  margin-top: 1rem;
  font-weight: 700;
  font-size: 0.875rem;
`;

const OptionsContainer = styled.div`
  display: flex;
  margin: 1rem 0;
  height: 2rem;
  justify-content: space-between;

  &:hover {
    color: #6c757d;
    border-color: #008cd8;
  }
`;

const FileSelectionArea = styled.div`
  display: flex;
  width: 50%;
`;

const FileSelectionBox = styled.div`
  width: 60%;
  min-width: 10rem;
  border: 1px solid;
  border-radius: 0.25rem;
  color: #6c757d;
  border-color: #008cd8;
  margin-right: 1rem;
`;

const FileLabel = styled.label`
  position: relative;
  display: inline-block;
  padding: 3px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  color: #6c757d;
  border-color: #6c757d;
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.5;
`;

const FileInput = styled.input`
  display: none;
  width: 100%;
`;

const ImportButtonText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
`;

const CloseButton = styled(CloseIcon)`
  position: absolute;
  left: 94%;
  top: 30%;
  z-index: 9;
`;

const LineBreak = styled.div`
  margin: 1rem 0;
  height: 1px;
  width: 100%;
  border: 1px solid #9b9b9b;
  box-sizing: border-box;
`;
