import gql from "graphql-tag";
import React, { useEffect } from "react";

import {
  MyProfilePageViewerFragment,
  useProfilePageLazyQuery,
} from "~/components/Settings/MyProfilePage.generated";
import {
  PROFILE_AVATAR_FRAGMENT,
  ProfileAvatar,
} from "~/components/Settings/ProfileAvatar";
import {
  PROFILE_FORM_FRAGMENT,
  ProfileForm,
} from "~/components/Settings/ProfileForm";

export const PROFILE_PAGE_QUERY = gql`
  query ProfilePage($id: ID!) {
    user(id: $id) {
      ...ProfileForm
      ...ProfileAvatar
    }
  }

  ${PROFILE_FORM_FRAGMENT}
  ${PROFILE_AVATAR_FRAGMENT}
`;

export const MY_PROFILE_PAGE_VIEWER_FRAGMENT = gql`
  fragment MyProfilePageViewer on User {
    id
  }
`;

export interface MyProfilePageProps {
  viewer: MyProfilePageViewerFragment;
}

export const MyProfilePage = ({ viewer }: MyProfilePageProps) => {
  const [getData, { data }] = useProfilePageLazyQuery();

  useEffect(() => {
    if (viewer?.id)
      getData({
        variables: { id: viewer.id },
      });
  }, [viewer, getData]);

  return (
    <>
      <h4 className="mb-4">My Profile</h4>
      <div className="row">
        <div className="col-md-7">
          <div>{data?.user && <ProfileForm user={data?.user} />}</div>
        </div>
        <div className="col-md-5">
          <div className="mb-1">Profile picture</div>
          <div className="user-picture-update">
            <ProfileAvatar data={data?.user} />
          </div>
        </div>
      </div>
      <div className="privacy-statement">
        We store your personal data in the United States. See our&nbsp;
        <a href="https://www.dock.energy/privacy">privacy policy</a> for more
        information
      </div>
    </>
  );
};
