import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { gettext } from "utils/text";

import { ThematicBreak } from "~/components/common/layout";
import { UPDATE_PHONE_NUMBER_FORM_FRAGMENT } from "~/components/forms/UpdatePhoneNumberConfirmAuth";
import { UPDATE_PHONE_NUMBER_SEND_AUTH_FORM_FRAGMENT } from "~/components/forms/UpdatePhoneNumberSendAuth";
import Alert from "~/components/profile/Alert";
import PhoneNumberUpdate from "~/components/profile/PhoneNumberUpdate";
import TwoFAStatus from "~/components/profile/TwoFAStatus";
import {
  CHANGE_PASSWORD_FORM_FRAGMENT,
  ChangePasswordForm,
} from "~/components/Settings/ChangePasswordForm";
import {
  SecurityPageViewerFragment,
  useSecurityPageLazyQuery,
} from "~/components/Settings/Security.generated";

export const SECURITY_PAGE_QUERY = gql`
  query SecurityPage($id: ID!) {
    user(id: $id) {
      ...ChangePasswordForm
      ...UpdatePhoneNumberForm
      ...UpdatePhoneNumberSendAuthForm
    }
  }

  ${CHANGE_PASSWORD_FORM_FRAGMENT}
  ${UPDATE_PHONE_NUMBER_SEND_AUTH_FORM_FRAGMENT}
  ${UPDATE_PHONE_NUMBER_FORM_FRAGMENT}
`;

export const SECURITY_PAGE_VIEWER_FRAGMENT = gql`
  fragment SecurityPageViewer on User {
    id
  }
`;

export interface SecurityPageProps {
  viewer: SecurityPageViewerFragment;
}

export const Security = ({ viewer }: SecurityPageProps) => {
  const [getData, { data }] = useSecurityPageLazyQuery();

  useEffect(() => {
    if (viewer?.id)
      getData({
        variables: { id: viewer.id },
      });
  }, [viewer, getData]);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const closeAlert = () => {
    setAlertVisible(false);
  };

  const handleFormSubmit = (message: string) => {
    setAlertVisible(true);
    setAlertMessage(message);
    setTimeout(() => closeAlert(), 4000);
  };

  return (
    <div className="settings-page">
      {alertVisible && <Alert message={alertMessage} />}
      <TwoFAStatus enabled={DJ_CONST.user.is_2fa_active} />
      <ThematicBreak />
      <p className="text-muted">
        {gettext(
          "Two-factor authentication adds an additional layer of security " +
            "to your account by requiring more than just a password to log in."
        )}
      </p>
      {data?.user && (
        <PhoneNumberUpdate
          phoneNumberChanged={handleFormSubmit}
          user={data.user}
        />
      )}
      {data?.user && (
        <ChangePasswordForm
          user={data.user}
          alertPasswordChanged={handleFormSubmit}
          passwordResetUrl="/account/password_reset/"
        />
      )}
    </div>
  );
};
