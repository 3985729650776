// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type SubpagesMenu_HoldCoSubpage_Fragment = (
  { readonly __typename: 'HoldCoSubpage' }
  & Pick<Types.HoldCoSubpage, 'id' | 'slug' | 'title'>
);

export type SubpagesMenu_Subpage_Fragment = (
  { readonly __typename: 'Subpage' }
  & Pick<Types.Subpage, 'id' | 'slug' | 'title'>
);

export type SubpagesMenuFragment = SubpagesMenu_HoldCoSubpage_Fragment | SubpagesMenu_Subpage_Fragment;

export const SubpagesMenuFragmentDoc = gql`
    fragment SubpagesMenu on SubpageInterface {
  id
  slug
  title
}
    `;