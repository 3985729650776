import { gql } from "@apollo/client";
import classnames from "classnames";
import React, { useContext } from "react";
import styled from "styled-components";
import { gettext } from "utils/text";

import OrderingContext, {
  Field,
} from "~/components/Admin/User/OrderingContext";
import { UserInviteFragment } from "~/components/Admin/User/UsersTable.generated";
import CancelCircle from "~/components/common/icons/CancelCircle";
import CheckCircle from "~/components/common/icons/CheckCircle";
import { gray100, lightBlue } from "~/styles/theme/color";
import { green, red } from "~/styles/theme/color";
import { Direction, Ordering, createOrdering, reverse } from "~/utils/ordering";

export const INVITATION_FRAGMENT = gql`
  fragment UserInvite on OrgInvitation {
    token
    firstName
    lastName
    email
    title
    accepted
    groupId
    existingUser(orgSlug: $orgSlug) {
      firstName
      lastName
      companyName
      email
    }
  }
`;

export interface HeaderCellProps {
  name: Field;
  ordering: Ordering<Field> | null;
  toggleSorting: (name: Field) => void;
  children?: any;
  style?: React.CSSProperties;
  arrowLeft?: boolean;
}

export const HeaderCell = ({
  name,
  ordering,
  toggleSorting,
  children,
  style = { textAlign: "left" },
  arrowLeft = false,
}: HeaderCellProps) => (
  <th scope="col" style={style}>
    <button
      className={classnames(
        "btn",
        ordering && ordering.field && "btn-link",
        ordering && ordering.field && "btn-link-hovered"
      )}
      style={{ padding: 0, textAlign: style.textAlign }}
      type="button"
      onClick={() => toggleSorting(name)}
    >
      {!arrowLeft ? children : null}
      {ordering && ordering.field && (
        <i
          className={classnames(
            "icon",
            "icon--sort-up",
            ordering.field.includes(name) && "active",
            ordering.field.includes(name) &&
              ordering.direction === Direction.ASC &&
              "asc",
            ordering.field.includes(name) &&
              ordering.direction === Direction.DESC &&
              "desc"
          )}
        />
      )}
      {arrowLeft ? children : null}
    </button>
  </th>
);

export interface UsersTableProps {
  users: readonly UserInviteFragment[];
  isLoading: boolean;
  renderUserMenu: (invite: UserInviteFragment) => React.ReactNode;
}

const UsersTable = ({ users, isLoading, renderUserMenu }: UsersTableProps) => {
  const { ordering, setOrdering } = useContext(OrderingContext);
  const toggleSorting = (field: Field) => {
    if (ordering.field === field) {
      setOrdering(reverse(ordering));
    } else {
      setOrdering(createOrdering(field));
    }
  };

  return (
    <TableContainer>
      <StyledTable className="table mb-3">
        <thead>
          <tr>
            <HeaderCell
              name={Field.USER_NAME}
              ordering={ordering}
              toggleSorting={toggleSorting}
              style={{ textAlign: "left", width: "30%" }}
            >
              {gettext("Name")}
            </HeaderCell>
            <HeaderCell
              name={Field.EMAIL}
              ordering={ordering}
              toggleSorting={toggleSorting}
              style={{ textAlign: "left", width: "30%" }}
            >
              {gettext("Email")}
            </HeaderCell>
            <HeaderCell
              name={Field.COMPANY_NAME}
              ordering={ordering}
              toggleSorting={toggleSorting}
              style={{ textAlign: "left", width: "25%" }}
            >
              {gettext("Company")}
            </HeaderCell>
            <HeaderCell
              name={Field.ACCEPTED}
              ordering={ordering}
              toggleSorting={toggleSorting}
              style={{ textAlign: "center" }}
            >
              {gettext("Accepted")}
            </HeaderCell>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {!users.length && !isLoading && (
            <tr className="skeleton">
              <td colSpan={5}>
                <div className="d-flex justify-content-center py-2">
                  <b>{gettext("No users found.")}</b>
                </div>
              </td>
            </tr>
          )}
          {users.map((user) => (
            <tr key={user.token}>
              <td>
                {!user.firstName && !user.lastName ? (
                  "-"
                ) : (
                  <span>
                    {user.firstName} <b>{user.lastName}</b>
                  </span>
                )}
              </td>
              <td>{user.email}</td>
              <td>
                {user.existingUser?.companyName ? (
                  <>{user.existingUser?.companyName}</>
                ) : (
                  "-"
                )}
              </td>
              <td className="accepted-cell">
                {user.accepted ? (
                  <CheckCircle style={{ color: green, marginRight: 20 }} />
                ) : (
                  <CancelCircle style={{ color: red, marginRight: 20 }} />
                )}
              </td>
              <td>{renderUserMenu(user)}</td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export const TableContainer = styled.div`
  min-height: 500px;
`;

export const StyledTable = styled.table`
  border: 0 !important;
  min-width: 100%;
  font-size: 0.875rem;
  border-collapse: separate;
  border-spacing: 0 5px;

  th,
  td {
    padding: 0.5rem;
  }

  thead tr {
    background-color: ${lightBlue};
    max-height: 40px;
    height: 40px;
  }

  && thead tr th {
    height: 40px;
    border-color: ${lightBlue};
    & :first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & :last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  && tbody tr {
    height: 40px;
    max-height: 40px;

    background-color: ${gray100};
    border-color: ${gray100};

    :first-child td {
      border-color: ${gray100};
    }

    td {
      border-color: ${gray100};
    }

    & td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  && tbody tr.skeleton {
    background-color: transparent;
    border: none;
  }

  .accepted-cell {
    text-align: center;
    max-width: 5rem;
  }
`;

export default UsersTable;
