import React from "react";
import styled from "styled-components";

import { DangerButton, SecondaryButton } from "~/components/common/buttons";
import { Toolbar } from "~/components/common/toolbars";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";
import { DocumentsRemove } from "~/components/Documents/useDocumentsRemove";
import Dialog from "~/components/generic/Dialog";

import { DocumentsTree } from "./DocumentsTree";

export interface DocumentsRemoveDialogProps extends DocumentsRemove {
  track: DocumentsEventTracking;
}

export const DocumentsRemoveDialog = React.memo(function DocumentsRemoveDialog(
  props: DocumentsRemoveDialogProps
) {
  const {
    track,
    confirmDisabled,
    confirmVisible,
    stagedCount,
    stagedIds,
    dryRunResult,
    error,
    reset,
    confirm,
  } = props;

  const cancel = () => {
    track.deleteCancel();
    reset();
  };

  return (
    <Dialog
      isOpen={confirmVisible}
      header="Delete Documents"
      onRequestClose={cancel}
      actions={
        <Toolbar>
          <SecondaryButton onClick={cancel}>Cancel</SecondaryButton>
          <DangerButton
            disabled={confirmDisabled}
            onClick={() => {
              track.deleteConfirm(stagedIds);
              confirm();
            }}
          >
            Delete
          </DangerButton>
        </Toolbar>
      }
    >
      <div className="alert alert-warning">
        You are about to delete the following files/folders and everything
        inside of them. Are you sure?
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
      {!error && (
        <Tree documents={dryRunResult ?? null} skeletons={stagedCount} />
      )}
    </Dialog>
  );
});

export const Tree = styled(DocumentsTree)`
  max-height: 60vh;
  overflow: auto;
`;
