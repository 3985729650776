import { gql } from "@apollo/client";
import classnames from "classnames";
import React, { useContext } from "react";
import styled from "styled-components";
import { gettext } from "utils/text";

import { GroupRowFragment } from "~/components/Admin/Group/GroupsTable.generated";
import OrderingContext, {
  Field,
} from "~/components/Admin/Group/OrderingContext";
import { gray100, lightBlue } from "~/styles/theme/color";
import { Direction, Ordering, createOrdering, reverse } from "~/utils/ordering";

export const GROUP_ROW_FRAGMENT = gql`
  fragment GroupRow on UserGroup {
    id
    name
    portfolioIds
    memberCount
    role {
      id
      name
    }
  }
`;

export interface HeaderCellProps {
  name: Field;
  ordering: Ordering<Field> | null;
  toggleSorting: (name: Field) => void;
  children?: any;
  style?: React.CSSProperties;
  arrowLeft?: boolean;
}

export const HeaderCell = ({
  name,
  ordering,
  toggleSorting,
  children,
  style = { textAlign: "left" },
  arrowLeft = false,
}: HeaderCellProps) => (
  <th scope="col" style={style}>
    <button
      className={classnames(
        "btn",
        ordering && ordering.field && "btn-link",
        ordering && ordering.field && "btn-link-hovered"
      )}
      style={{ padding: 0, textAlign: style.textAlign }}
      type="button"
      onClick={() => toggleSorting(name)}
    >
      {!arrowLeft ? children : null}
      {ordering && ordering.field && (
        <i
          className={classnames(
            "icon",
            "icon--sort-up",
            ordering.field.startsWith(name) && "active",
            ordering.field.startsWith(name) &&
              ordering.direction === Direction.ASC &&
              "asc",
            ordering.field.startsWith(name) &&
              ordering.direction === Direction.DESC &&
              "desc"
          )}
        />
      )}
      {arrowLeft ? children : null}
    </button>
  </th>
);

export interface GroupsTableProps {
  groups: readonly GroupRowFragment[];
  isLoading: boolean;
  renderGroupMenu: (group: GroupRowFragment) => React.ReactNode;
}

const GroupsTable = ({
  groups,
  isLoading,
  renderGroupMenu,
}: GroupsTableProps) => {
  const { ordering, setOrdering } = useContext(OrderingContext);
  const toggleSorting = (field: Field) => {
    if (ordering.field === field) {
      setOrdering(reverse(ordering));
    } else {
      setOrdering(createOrdering(field));
    }
  };

  return (
    <TableContainer>
      <StyledTable className="table mb-3">
        <thead>
          <tr>
            <HeaderCell
              name={Field.NAME}
              ordering={ordering}
              toggleSorting={toggleSorting}
              style={{ textAlign: "left", width: "40%" }}
            >
              {gettext("Name")}
            </HeaderCell>
            <HeaderCell
              name={Field.ROLE}
              ordering={ordering}
              toggleSorting={toggleSorting}
              style={{ textAlign: "left", width: "40%" }}
            >
              {gettext("Role")}
            </HeaderCell>
            <HeaderCell
              name={Field.MEMBER_COUNT}
              ordering={ordering}
              toggleSorting={toggleSorting}
              style={{ textAlign: "center", width: "15%" }}
            >
              {gettext("Member Count")}
            </HeaderCell>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {!groups.length && !isLoading && (
            <tr className="skeleton">
              <td colSpan={4}>
                <div className="d-flex justify-content-center py-2">
                  <b>{gettext("No groups found.")}</b>
                </div>
              </td>
            </tr>
          )}
          {groups.map((group) => (
            <tr key={group.id}>
              <td style={{ textAlign: "left", width: "40%" }}>
                {!group.name ? "-" : group.name}
              </td>
              <td style={{ textAlign: "left", width: "40%" }}>
                {group.role?.name ?? "-"}
              </td>
              <td style={{ textAlign: "center" }}>{group.memberCount}</td>
              <td style={{ textAlign: "center" }}>{renderGroupMenu(group)}</td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export const TableContainer = styled.div`
  min-height: 500px;
`;

export const StyledTable = styled.table`
  border: 0 !important;
  min-width: 100%;
  font-size: 0.875rem;
  border-collapse: separate;
  border-spacing: 0 5px;

  th,
  td {
    padding: 0.5rem;
  }

  thead tr {
    background-color: ${lightBlue};
    max-height: 40px;
    height: 40px;
  }

  && thead tr th {
    height: 40px;
    border-color: ${lightBlue};
    & :first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & :last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  && tbody tr {
    height: 40px;
    max-height: 40px;

    background-color: ${gray100};
    border-color: ${gray100};

    :first-child td {
      border-color: ${gray100};
    }

    td {
      border-color: ${gray100};
    }

    & td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  && tbody tr.skeleton {
    background-color: transparent;
    border: none;
  }

  .accepted-cell {
    text-align: center;
    max-width: 5rem;
  }
`;

export default GroupsTable;
