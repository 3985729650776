export const FILE_STORAGE_DROPBOX = 1;
export const FILE_STORAGE_S3 = 2;

export const FILE_TYPE_DIRECTORY = 0;
export const FILE_TYPE_FILE = 1;

export const MAX_SEARCH_RESULTS = 100;

// Activity Types
export const ACTIVITY_TYPE_DELETE = "Delete";

export const IS_SUPERUSER = 1;
export const IS_STAFF = 2;
export const IS_REGULAR = 3;

export const SUPERUSER = "Superuser";
