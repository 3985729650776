import { css } from "styled-components";

/**
 * Mixin that centers the element's content vertically using flexbox.
 *
 * @example
 *
 *   styled.div`
 *     ${centerVertically};
 *   `
 */
const centerVertically = css`
  display: flex;
  align-items: center;
`;

export default centerVertically;
