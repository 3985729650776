// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { OAndMBannerFragment } from './OAndMBanner.generated';
import { gql } from '@apollo/client';
import { OAndMBannerFragmentDoc } from './OAndMBanner.generated';
import * as Apollo from '@apollo/client';
export type OAndMQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
}>;


export type OAndMQuery = (
  { readonly __typename: 'Query' }
  & { readonly tree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & Pick<Types.DocumentTree, 'id'>
  )> }
  & OAndMBannerFragment
);


export const OAndMDocument = gql`
    query OAndM($orgSlug: String!) {
  ...OAndMBanner
  tree: oAndMTree(orgSlug: $orgSlug) {
    id
  }
}
    ${OAndMBannerFragmentDoc}`;

/**
 * __useOAndMQuery__
 *
 * To run a query within a React component, call `useOAndMQuery` and pass it any options that fit your needs.
 * When your component renders, `useOAndMQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOAndMQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useOAndMQuery(baseOptions: Apollo.QueryHookOptions<OAndMQuery, OAndMQueryVariables>) {
        return Apollo.useQuery<OAndMQuery, OAndMQueryVariables>(OAndMDocument, baseOptions);
      }
export function useOAndMLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OAndMQuery, OAndMQueryVariables>) {
          return Apollo.useLazyQuery<OAndMQuery, OAndMQueryVariables>(OAndMDocument, baseOptions);
        }
export type OAndMQueryHookResult = ReturnType<typeof useOAndMQuery>;
export type OAndMLazyQueryHookResult = ReturnType<typeof useOAndMLazyQuery>;
export type OAndMQueryResult = Apollo.QueryResult<OAndMQuery, OAndMQueryVariables>;