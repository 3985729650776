import React from "react";

import { DangerButton, SecondaryButton } from "~/components/common/buttons";
import { Text } from "~/components/common/text";
import { Toolbar } from "~/components/common/toolbars";
import Dialog from "~/components/generic/Dialog";
import LoadingSpinner from "~/components/generic/LoadingSpinner";

export interface RemoveMilestoneDialogProps {
  name?: string | null;
  isOpen: boolean;
  isRemoving: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const RemoveMilestoneDialog = (props: RemoveMilestoneDialogProps) => {
  const { name, isOpen, isRemoving, onConfirm, onCancel } = props;

  const handleCancel = () => {
    if (!isRemoving) onCancel();
  };

  return (
    <Dialog
      isOpen={isOpen}
      header="Delete Milestone"
      onRequestClose={handleCancel}
      actions={
        <Toolbar>
          <SecondaryButton disabled={isRemoving} onClick={handleCancel}>
            Cancel
          </SecondaryButton>
          <DangerButton disabled={isRemoving} onClick={onConfirm}>
            {isRemoving && <LoadingSpinner color="light" />}
            <span>Delete</span>
          </DangerButton>
        </Toolbar>
      }
    >
      <Text>
        Are you sure you want to delete{" "}
        {name ? `the milestone "${name}"` : "this milestone"}?
      </Text>
    </Dialog>
  );
};
