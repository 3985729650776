import { ChangeEventHandler, useCallback, useMemo, useState } from "react";

import { Document } from "~/api/types.generated";
import { useDocumentsCreateFolderMutation } from "~/components/Documents/DocumentsAPI.generated";
import { DocumentsCurrent } from "~/components/Documents/useDocumentsCurrent";

export interface DocumentsCreateFolder {
  isOpen: boolean;
  loading: boolean;
  name: string;
  error: string | null;
  validationError: string | null;
  disabled: boolean;
  open(): void;
  change: ChangeEventHandler<HTMLInputElement>;
  submit(): Promise<void>;
  reset(): void;
}

/** Handles state and logic required for creating a new folder */
export const useDocumentsCreateFolder = (
  treeId: string,
  { folder }: DocumentsCurrent
): DocumentsCreateFolder => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState<string | null>(null);

  const validationError = useMemo(() => {
    const nameExists = (folder as Document)?.children?.find(
      (d) => d.name?.toLowerCase() === name.toLowerCase()
    );
    if (nameExists) return `Folder ${name} already exists!`;
    return null;
  }, [folder, name]);

  const [request, { loading }] = useDocumentsCreateFolderMutation();

  const disabled = loading || !name || error != null || validationError != null;

  const open = useCallback(() => setIsOpen(true), []);

  const reset = useCallback(() => {
    setIsOpen(false);
    setName("");
    setError(null);
  }, []);

  const submit = useCallback(async () => {
    if (disabled) return;
    try {
      const parentId = folder?.id;
      await request({ variables: { treeId, parentId, name } });
      reset();
    } catch (error) {
      setError("A server error has occurred.");
    }
  }, [request, reset, treeId, folder, name, disabled]);

  const change: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setName(event.currentTarget.value);
  }, []);

  return {
    isOpen,
    loading,
    name,
    error,
    validationError,
    disabled,
    open,
    change,
    submit,
    reset,
  };
};
