// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ChangePasswordFormFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'email'>
);

export type UpdatePasswordMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  oldPassword: Types.Scalars['String'];
  newPassword: Types.Scalars['String'];
}>;


export type UpdatePasswordMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateUserPassword?: Types.Maybe<(
    { readonly __typename: 'User' }
    & ChangePasswordFormFragment
  )> }
);

export const ChangePasswordFormFragmentDoc = gql`
    fragment ChangePasswordForm on User {
  id
  email
}
    `;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($id: ID!, $oldPassword: String!, $newPassword: String!) {
  updateUserPassword(
    id: $id
    oldPassword: $oldPassword
    newPassword: $newPassword
  ) {
    ...ChangePasswordForm
  }
}
    ${ChangePasswordFormFragmentDoc}`;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, baseOptions);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;