import React, {
  Children,
  KeyboardEvent,
  cloneElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { isElement } from "react-is";
import styled from "styled-components";

import { GetPropsWithoutRef } from "~/utils/types";

import ActionMenuContext from "./ActionMenuContext";
import DropdownContext from "./DropdownContext";

export interface ActionMenuProps extends GetPropsWithoutRef<"ul"> {
  closeOnSelect?: boolean;
}

const ActionMenu = ({
  children,
  closeOnSelect = true,
  ...rest
}: ActionMenuProps) => {
  const dropdown = useContext(DropdownContext);
  const [focusIndex, setFocusIndex] = useState(0);

  const setAriaType = dropdown?.setAriaType;

  let items = children;

  useEffect(() => {
    setAriaType?.("menu");
  }, [setAriaType]);

  // While closing, don't change the menu contents
  const savedItems = useRef(items);
  useEffect(() => {
    if (dropdown?.isOpen) savedItems.current = items;
  });
  if (!dropdown?.isOpen) items = savedItems.current;

  // Provide each menu item with its index
  const itemArray = Children.toArray(items)
    .filter(isElement)
    .map((item, index) => cloneElement(item, { index }));

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case "ArrowUp":
        event.preventDefault();
        setFocusIndex((index) => Math.max(0, index - 1));
        break;

      case "ArrowDown":
        event.preventDefault();
        setFocusIndex((index) => Math.min(itemArray.length - 1, index + 1));
        break;

      default:
        break;
    }
  };

  return (
    <ActionMenuContext.Provider
      value={{ focusIndex, setFocusIndex, closeOnSelect }}
    >
      <ul role="menu" tabIndex={-1} onKeyDown={handleKeyDown} {...rest}>
        {itemArray}
      </ul>
    </ActionMenuContext.Provider>
  );
};

export default styled(ActionMenu)`
  list-style: none;
  margin: 0;
  padding: 0;
`;
