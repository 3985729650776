import TitleAnchor from "components/typography/TitleAnchor";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";
import { formatSize } from "utils/calculations";

import { RightArrow } from "~/components/HomePage/ArrowIcon";
import StatSection from "~/components/HomePage/StatSection";
import Stat from "~/components/typography/Stat";
import StatLabel from "~/components/typography/StatLabel";
import { bgColor, device } from "~/styles/mixins";

import { HomePageProjectsBannerFragment } from "./ProjectsBanner.generated";

export const HOME_PAGE_PROJECTS_BANNER_FRAGMENT = gql`
  fragment HomePageProjectsBanner on Query {
    acquiredProjectsStats(orgSlug: $orgSlug) {
      count
      totalCapacity
    }
  }
`;

export interface ProjectsBannerProps {
  orgSlug: string;
  data: HomePageProjectsBannerFragment["acquiredProjectsStats"];
  onClickAnchor?: () => void;
}

export const ProjectsBanner = ({
  orgSlug,
  data,
  onClickAnchor,
}: ProjectsBannerProps) => {
  return (
    <ContainerDiv>
      <TitleSection>
        <TitleAnchor to={`/${orgSlug}/projects`} onClick={onClickAnchor}>
          Projects <RightArrow />
        </TitleAnchor>
      </TitleSection>
      <StatSection>
        <Block>
          <StatLabel>Number</StatLabel>
          <Stat>{data?.count}</Stat>
        </Block>
        <Block>
          <StatLabel>Total Size</StatLabel>
          <Stat>{formatSize(data?.totalCapacity)}</Stat>
        </Block>
      </StatSection>
    </ContainerDiv>
  );
};

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  ${bgColor.gray800()};
  border-radius: 4px;
  flex-grow: 1;
  margin-right: 1px;
  margin-bottom: 10px;
  padding: 0px 14px;
  @media ${device.xs} {
    padding: 5px 25px 5px;
    margin-bottom: 1px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Block = styled.div<{ hide?: boolean }>`
  flex-grow: 1;
  font-weight: 200;
  display: ${(props) => (props.hide ? "none" : "block")};
  @media ${device.md} {
    display: block;
  }
`;
