const reverseUrl = function reverseUrl(urlName, ...params) {
  return DJ_CONST.reverse[urlName](...params);
};

export const getParams = (query) => {
  if (!query) {
    return {};
  }

  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split("&")
    .reduce((params, param) => {
      const newParams = { ...params };
      const [key, value] = param.split("=");
      newParams[key] = value
        ? decodeURIComponent(value.replace(/\+/g, " "))
        : "";
      return newParams;
    }, {});
};

export default reverseUrl;
