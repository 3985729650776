import { distanceInWordsToNow, max } from "date-fns";
import React, { useEffect } from "react";
import styled from "styled-components";

import BaseLoadingSpinner from "~/components/generic/LoadingSpinner";
import useForceUpdate from "~/hooks/useForceUpdate";
import { borderColor, borderTopColor, fgColor } from "~/styles/mixins";
import { GetPropsWithoutRef } from "~/utils/types";

import { ChildQuestion, ParentQuestion } from "../context/QuestionContext";

export interface LastEditProps extends GetPropsWithoutRef<"div"> {
  questions: Array<ParentQuestion | ChildQuestion>;
  saving: boolean;
}

const LastEdit = ({ questions, saving, ...rest }: LastEditProps) => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const interval = setInterval(forceUpdate, 5000);
    return () => clearInterval(interval);
  }, [forceUpdate]);

  if (!questions || questions.length === 0) {
    return null;
  }

  const answers = questions.map((q) => q.answer);

  const mostRecent = max(
    ...questions.map((q) => q.modifiedAt),
    ...answers.map((a) => a.modifiedAt)
  );

  return saving ? (
    <div {...rest}>
      Saving... <LoadingSpinner />
    </div>
  ) : (
    <div {...rest}>
      Last edit was{" "}
      {distanceInWordsToNow(mostRecent, {
        includeSeconds: true,
        addSuffix: true,
      })}
    </div>
  );
};

const LoadingSpinner = styled(BaseLoadingSpinner)`
  && {
    ${borderColor.gray600()};
    ${borderTopColor.transparent()};
  }
  margin-left: 4px;
`;

export default styled(LastEdit)`
  ${fgColor.gray600()};
  display: flex;
  align-items: center;
  margin: 0;
`;
