import classNames from "classnames";
import PropTypes from "prop-types";
/**
 * Common layout for the modal select dropdowns
 */
import React from "react";
import { gettext } from "utils/text";

const ModalSelect = (props) => {
  const { name, isLoading, items, defaultValue, inputLabel, extra, onChange } =
    props;
  return (
    <div className="form-group">
      <label htmlFor={name} className="col-form-label checklist-modal__label">
        {inputLabel}
      </label>
      {!isLoading && extra}
      <select
        className={classNames("custom-select", "checklist-modal__input", {
          loading: isLoading,
        })}
        name={name}
        id={name}
        onChange={(e) =>
          onChange({
            [name]: e.target.value ? e.target.value : null,
          })
        }
        value={defaultValue || ""}
        disabled={isLoading}
      >
        {!isLoading &&
          items.map(([value, label]) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        {isLoading && <option value="">{gettext("Loading...")}</option>}
      </select>
    </div>
  );
};

ModalSelect.propTypes = {
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
      .isRequired
  ).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputLabel: PropTypes.string.isRequired,
  extra: PropTypes.node,
  onChange: PropTypes.func.isRequired,
};

ModalSelect.defaultProps = {
  extra: null,
};

export default ModalSelect;
