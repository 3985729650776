import styled from "styled-components";

import { device } from "~/styles/mixins";
import { gray100, gray600 } from "~/styles/theme/color";

export const AppContent = styled.main`
  background: ${gray100};
  padding: 12px 0;
  min-height: calc(100vh - 110px);
`;

export const ThematicBreak = styled.hr`
  border-top: 1px solid ${gray600};
  margin: 5px 0;
  padding: 0;
`;

export const PageContainer = styled.div`
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;

  @media ${device.sm} {
    max-width: 540px;
  }

  @media ${device.md} {
    max-width: 720px;
  }

  @media ${device.lg} {
    max-width: 960px;
  }

  @media ${device.xl} {
    max-width: 1920px;
  }
`;
