import { SortIcon } from "components/icons";
import throttle from "lodash.throttle";
import * as PropTypes from "prop-types";
import React from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import { gettext } from "utils/text";

import { ChecklistItemStatus } from "~/api/types.generated";
import { ChecklistItemLinkForm } from "~/components/Checklist/modals/ChecklistItemLinkForm";

import { CloseButton } from "../../generic/CloseButton";
import Comments from "./Comments";
import StatusSelect from "./StatusSelect";
import UserSelect from "./UserSelect";

const defaultStatus = ChecklistItemStatus.StateRequested;

class ChecklistModal extends React.Component {
  static propTypes = {
    portfolioId: PropTypes.string.isRequired,
    checklistId: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    updateChecklistItem: PropTypes.func.isRequired,
    activeCategory: PropTypes.shape(),
    categoryName: PropTypes.string.isRequired,
    activeProject: PropTypes.shape(),
    activeChecklistItem: PropTypes.shape(),
    users: PropTypes.shape({}).isRequired,
    itemsLoading: PropTypes.bool,
    itemsError: PropTypes.string,
    moveActiveChecklistItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    categoryName: "",
    activeCategory: null,
    activeProject: null,
    activeChecklistItem: null,
    itemsLoading: null,
    itemsError: null,
  };

  constructor(props) {
    super(props);

    this.linkInputRef = null;
    this.throttledFunction = throttle(this.handleKeyPress, 300);
  }

  componentWillReceiveProps(nextProps) {
    const { isOpen, activeChecklistItem } = this.props;

    // region === Keybindings
    window.removeEventListener("keydown", this.handleKeyPress);

    if (!isOpen && nextProps.isOpen) {
      document.addEventListener("keydown", this.throttledFunction);
    } else if (isOpen && !nextProps.isOpen) {
      document.removeEventListener("keydown", this.throttledFunction);
    }

    // endregion

    // region === Change link input value if necessary

    const item = activeChecklistItem;
    const nextItem = nextProps.activeChecklistItem;
    const activeItemChanged =
      item &&
      nextItem &&
      (item.category !== nextItem.category ||
        item.project !== nextItem.project);
    if (activeItemChanged && this.linkInputRef) {
      // Since Link input is not controlled, we need to manually change it
      this.linkInputRef.value = nextItem.link || "";
    }

    // endregion
  }

  submitLinkForm = (e) => {
    e.preventDefault();

    if (this.linkInputRef) {
      this.updateChecklistItem({ link: this.linkInputRef.value });
    }
  };

  scrollItemIntoView = () => {
    // The purpose is to get the active element (selected cell). For no "Docs Cells" we attach cell--selected class.
    // And all docs cells have item id attached as a classname made so in order to make it possible to perform the
    // action below.
    const node =
      document.querySelector(".cell--selected") ||
      document.querySelector(`.docs-cell-${this.props.activeChecklistItem.id}`);

    scrollIntoView(node, {
      behavior: (actions) => {
        actions.forEach(({ el, top, left }) => {
          el.scrollTop = top; // eslint-disable-line no-param-reassign
          el.scrollLeft = left; // eslint-disable-line no-param-reassign

          const cellRect = node.getBoundingClientRect();
          const cellWidth = cellRect.width;
          const cellLeftPosition = cellRect.left;

          // A little math in order to fix keyboard navigating to the left issue (when horizontal scroll does
          // not move, as the cell is technically visible but stays behind the sticky column, due to z-index
          // property.
          //
          // #1 Move the scrollbar
          const cellLeft = parseInt(window.getComputedStyle(node).left, 10);
          const isNotVisible = left + cellWidth > cellLeft;

          if (isNotVisible) {
            // eslint-disable-next-line no-param-reassign
            el.scrollLeft -= Math.min(cellLeftPosition, left); // #1 Move the scrollbar
          }
        });
      },
      boundary: document.querySelector(".checklist-table"),
    });
  };

  handleKeyPress = (event) => {
    if (event.key === "Escape") {
      this.props.hideModal();
    }
    if (event.target.classList.contains("checklist-modal-no-navigation")) {
      return;
    }
    const keymap = {
      ArrowUp: "N",
      ArrowDown: "S",
      ArrowLeft: "W",
      ArrowRight: "E",
    };
    const direction = keymap[event.key];
    if (direction) {
      this.props.moveActiveChecklistItem(direction, this.scrollItemIntoView);
      event.preventDefault();
    }
  };

  updateChecklistItem = (fields) => {
    const item = this.props.activeChecklistItem;
    this.props.updateChecklistItem(item.id, fields);
  };

  renderLinkInput() {
    const isLoading = this.props.itemsLoading;

    if (isLoading) {
      return (
        <div className="text-center loading-py">{gettext("Loading....")}</div>
      );
    }

    return <ChecklistItemLinkForm />;
  }

  renderInputs() {
    if (this.props.activeCategory && this.props.activeCategory.parentId) {
      return (
        <div>
          <StatusSelect
            defaultValue={
              this.props.activeChecklistItem.status || defaultStatus
            }
            onChange={this.updateChecklistItem}
            isLoading={this.props.itemsLoading}
          />
          <UserSelect
            defaultValue={this.props.activeChecklistItem.userId || 0}
            onChange={this.updateChecklistItem}
            isLoading={this.props.itemsLoading}
            users={this.props.users}
          />
          <Comments
            portfolioId={this.props.portfolioId}
            checklistId={this.props.checklistId}
            selectedItems={[this.props.activeChecklistItem]}
            setNrOfComments={(itemId, count) => {}}
          />
        </div>
      );
    }

    return this.renderLinkInput();
  }

  renderError() {
    const { itemsError } = this.props;

    if (!itemsError) {
      return null;
    }

    return (
      <div className="alert alert-danger">
        <span>{itemsError}</span>
      </div>
    );
  }

  render() {
    const {
      isOpen,
      categoryName,
      activeProject,
      hideModal,
      moveActiveChecklistItem: moveActiveItem,
    } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <div className="checklist-modal-wrapper">
        <div className="checklist-modal" data-testid="checklist-single-modal">
          <div className="checklist-modal__nav-row">
            <div>
              <SortIcon
                dir="W"
                theme="black"
                onClick={() => moveActiveItem("W", this.scrollItemIntoView)}
              />
              <SortIcon
                dir="N"
                theme="black"
                onClick={() => moveActiveItem("N", this.scrollItemIntoView)}
              />
              <SortIcon
                dir="S"
                theme="black"
                onClick={() => moveActiveItem("S", this.scrollItemIntoView)}
              />
              <SortIcon
                dir="E"
                theme="black"
                onClick={() => moveActiveItem("E", this.scrollItemIntoView)}
              />
            </div>
            <CloseButton onClick={hideModal} />
          </div>
          <div className="checklist-modal__title-container">
            <h3 className="checklist-modal__title">{activeProject.name}</h3>
            <h4 className="checklist-modal__subtitle">{categoryName}</h4>
          </div>

          {this.renderError()}

          <div className="checklist-modal__form">{this.renderInputs()}</div>
        </div>
      </div>
    );
  }
}

export default ChecklistModal;
