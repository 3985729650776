// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateUserAgreementsMutationVariables = Types.Exact<{
  acceptedAgreements: Types.Scalars['Boolean'];
  emailAgreement?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type UpdateUserAgreementsMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateUserAgreements?: Types.Maybe<(
    { readonly __typename: 'User' }
    & { readonly agreements?: Types.Maybe<(
      { readonly __typename: 'UserAgreements' }
      & Pick<Types.UserAgreements, 'acceptedEula'>
    )> }
  )> }
);


export const UpdateUserAgreementsDocument = gql`
    mutation UpdateUserAgreements($acceptedAgreements: Boolean!, $emailAgreement: Boolean) {
  updateUserAgreements(
    acceptedAgreements: $acceptedAgreements
    emailAgreement: $emailAgreement
  ) {
    agreements {
      acceptedEula
    }
  }
}
    `;
export type UpdateUserAgreementsMutationFn = Apollo.MutationFunction<UpdateUserAgreementsMutation, UpdateUserAgreementsMutationVariables>;

/**
 * __useUpdateUserAgreementsMutation__
 *
 * To run a mutation, you first call `useUpdateUserAgreementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAgreementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAgreementsMutation, { data, loading, error }] = useUpdateUserAgreementsMutation({
 *   variables: {
 *      acceptedAgreements: // value for 'acceptedAgreements'
 *      emailAgreement: // value for 'emailAgreement'
 *   },
 * });
 */
export function useUpdateUserAgreementsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAgreementsMutation, UpdateUserAgreementsMutationVariables>) {
        return Apollo.useMutation<UpdateUserAgreementsMutation, UpdateUserAgreementsMutationVariables>(UpdateUserAgreementsDocument, baseOptions);
      }
export type UpdateUserAgreementsMutationHookResult = ReturnType<typeof useUpdateUserAgreementsMutation>;
export type UpdateUserAgreementsMutationResult = Apollo.MutationResult<UpdateUserAgreementsMutation>;
export type UpdateUserAgreementsMutationOptions = Apollo.BaseMutationOptions<UpdateUserAgreementsMutation, UpdateUserAgreementsMutationVariables>;