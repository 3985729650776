import styled from "styled-components";

import {
  bgColor,
  border,
  borderBottom,
  borderRadius,
  borderRight,
  centerVertically,
} from "~/styles/mixins";

export const SplitPanes = styled.div`
  display: flex;
  align-items: stretch;
  margin: 0.75rem 0;
  max-width: 100%;
`;

export const Panel = styled.div`
  ${bgColor.white()};
  ${border.gray300()};
  ${borderRadius()};
  font-size: 0.875rem;
  overflow: visible;

  ${SplitPanes} > & {
    border-radius: 0;
    border-right: none;
    margin: 0;
  }

  ${SplitPanes} > &:first-child {
    ${borderRadius("left")};
  }

  ${SplitPanes} > &:last-child {
    ${borderRadius("right")};
    ${borderRight.gray300()};
  }
`;

export const PrimaryPanel = styled(Panel)`
  padding: 1.125rem 1.5rem;
  margin: 0.75rem 0;
  flex: 1;
  overflow: auto;
`;

export const SecondaryPanel = styled(Panel)`
  padding: 1rem 0;
  min-width: 12rem;
  flex: 0 0 12rem;
`;

export const PanelHead = styled.div`
  ${bgColor.gray100()};
  ${borderBottom.gray300()};
  ${borderRadius("top")};
  ${centerVertically};
  min-height: 2.5rem;
  padding: 0 0.75rem;
  font-weight: bold;
`;
