import { DebouncedFunc, throttle } from "lodash";
import { useMemo } from "react";

import useLatest from "./useLatest";

const useThrottled = <F extends (...args: any[]) => any>(
  fn: F,
  time = 1000,
  { leading = false, trailing = true } = {}
): DebouncedFunc<F> => {
  const latestFn = useLatest(fn);

  return useMemo(
    () =>
      throttle(((...args) => latestFn.current(...args)) as F, time, {
        leading,
        trailing,
      }),
    [latestFn, time, leading, trailing]
  );
};

export default useThrottled;
