// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { MyProfilePageViewerFragment } from './MyProfilePage.generated';
import { ApplicationsViewerFragment } from './Applications.generated';
import { SecurityPageViewerFragment } from './Security.generated';
import { gql } from '@apollo/client';
import { MyProfilePageViewerFragmentDoc } from './MyProfilePage.generated';
import { ApplicationsViewerFragmentDoc } from './Applications.generated';
import { SecurityPageViewerFragmentDoc } from './Security.generated';
export type SettingsPageViewerFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id'>
  & { readonly organizations?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Organization' }
    & Pick<Types.Organization, 'id' | 'name' | 'slug'>
  )>> }
  & MyProfilePageViewerFragment
  & ApplicationsViewerFragment
  & SecurityPageViewerFragment
);

export const SettingsPageViewerFragmentDoc = gql`
    fragment SettingsPageViewer on User {
  id
  organizations {
    id
    name
    slug
  }
  ...MyProfilePageViewer
  ...ApplicationsViewer
  ...SecurityPageViewer
}
    ${MyProfilePageViewerFragmentDoc}
${ApplicationsViewerFragmentDoc}
${SecurityPageViewerFragmentDoc}`;