import styled from "styled-components";

import { centerVertically } from "~/styles/mixins";

import { Button, ButtonGroup } from "./buttons";
import { SecondaryPanel } from "./panels";
import { SecondaryHeading, Title } from "./text";

export const Toolbar = styled.div`
  ${centerVertically};
  flex: 1;
  margin-bottom: 0.5rem;

  ${SecondaryPanel} > & {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  & > .search-input-group {
    width: 50%;
    margin-right: auto;
  }

  & > ${Button}, & > ${ButtonGroup}, & > ${Title} {
    margin-left: 0.75rem;
  }

  & > ${Button}:first-child, & > ${ButtonGroup}:first-child {
    margin-left: 0;
  }

  & > ${SecondaryHeading} {
    margin-bottom: 0;
    margin-right: auto;
  }
`;
