import gql from "graphql-tag";
import React from "react";
import {
  BrowserRouter,
  NavLink,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";

// import Accordion from "react-bootstrap/Accordion";
// import Card from "react-bootstrap/Card";
import { PageContainer } from "~/components/common/layout";
import { PrimaryHeading } from "~/components/common/text";
import {
  APPLICATIONS_VIEWER_FRAGMENT,
  Applications,
} from "~/components/Settings/Applications";
import {
  MY_PROFILE_PAGE_VIEWER_FRAGMENT,
  MyProfilePage,
} from "~/components/Settings/MyProfilePage";
import { Notifications } from "~/components/Settings/Notifications";
import { SalesforceAdmin } from "~/components/Settings/SalesforceAdmin";
import {
  SECURITY_PAGE_VIEWER_FRAGMENT,
  Security,
} from "~/components/Settings/Security";
import { SettingsPageViewerFragment } from "~/components/Settings/SettingsPage.generated";

export const SETTINGS_PAGE_VIEWER_FRAGMENT = gql`
  fragment SettingsPageViewer on User {
    id
    organizations {
      id
      name
      slug
    }
    ...MyProfilePageViewer
    ...ApplicationsViewer
    ...SecurityPageViewer
  }
  ${MY_PROFILE_PAGE_VIEWER_FRAGMENT}
  ${APPLICATIONS_VIEWER_FRAGMENT}
  ${SECURITY_PAGE_VIEWER_FRAGMENT}
`;

interface SettingsPageProps {
  isAdmin: boolean;
  viewer: SettingsPageViewerFragment;
}

export const SettingsPage = ({ isAdmin, viewer }: SettingsPageProps) => {
  const location = useLocation();
  const expanded = location.pathname.includes("/notifications");
  return (
    <PageContainer>
      <div className="profile-update">
        <PrimaryHeading style={{ marginTop: "10px" }}>Settings</PrimaryHeading>
        <BrowserRouter basename="/profile">
          <div className="row">
            <div className="col-lg-2 mb-3">
              <ul className="nav nav-tabs flex-column nav-settings">
                <li className="nav-item">
                  <NavLink to="/" exact className="nav-link">
                    My Profile
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/security" className="nav-link">
                    Security
                  </NavLink>
                </li>
                {/*<Accordion className="nav-item" as="li" defaultActiveKey="0">*/}
                {/*  <Card>*/}
                {/*    <Accordion.Toggle as={Card.Header} eventKey="0" className="nav-link">*/}
                {/*      Notifications*/}
                {/*    </Accordion.Toggle>*/}
                {/*    <Accordion.Collapse eventKey="0">*/}
                {/*      <Card.Body>*/}
                {/*        {viewer.organizations?.map((org) => (*/}
                {/*          <SubNavLink*/}
                {/*            to={`/${org.slug}/notifications`}*/}
                {/*            className="nav-link"*/}
                {/*          >*/}
                {/*            {org.name}*/}
                {/*          </SubNavLink>*/}
                {/*        ))}*/}
                {/*      </Card.Body>*/}
                {/*    </Accordion.Collapse>*/}
                {/*  </Card>*/}
                {/*</Accordion>*/}
                {viewer.organizations && viewer.organizations.length === 1 && (
                  <li className="nav-item">
                    <NavLink
                      to={`/${viewer.organizations[0].slug}/notifications`}
                      className="nav-link"
                    >
                      Notifications
                    </NavLink>
                  </li>
                )}
                {viewer.organizations && viewer.organizations?.length > 1 && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="collapse"
                      href="#collapseOrgs"
                      aria-expanded="false"
                      aria-controls="collapseOrgs"
                    >
                      Notifications
                    </a>
                    <div
                      className={expanded ? "collapse show" : "collapse"}
                      id="collapseOrgs"
                    >
                      {viewer.organizations?.map((org) => (
                        <SubNavLink
                          key={`notification-admin-${org.slug}`}
                          to={`/${org.slug}/notifications`}
                          className="nav-link"
                        >
                          {org.name}
                        </SubNavLink>
                      ))}
                    </div>
                  </li>
                )}
                {isAdmin && (
                  <li className="nav-item">
                    <NavLink to="/applications" className="nav-link">
                      Applications
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
            <div className="col-lg-10">
              <div className="tab-content">
                <div className="container box--white box--white--profile">
                  <Switch>
                    <Route path="/" exact>
                      <MyProfilePage viewer={viewer} />
                    </Route>
                    <Route path="/security" exact>
                      <Security viewer={viewer} />
                    </Route>
                    <Route path="/:orgSlug/notifications" exact>
                      <Notifications viewer={viewer} />
                    </Route>
                    {isAdmin && (
                      <Route path="/applications" exact>
                        <Applications viewer={viewer} />
                      </Route>
                    )}
                    {isAdmin && (
                      <Route path="/applications/salesforce">
                        <SalesforceAdmin />
                      </Route>
                    )}
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </BrowserRouter>
      </div>
    </PageContainer>
  );
};

const SubNavLink = styled(NavLink)`
  display: flex;
  padding-left: 2rem;
`;
