import { useCallback, useState } from "react";

import { DocumentsTableData } from "~/components/Documents/useDocumentsTableData";

import { useDocumentsMoveMutation } from "./DocumentsAPI.generated";
import { DocumentsRouting } from "./useDocumentsRouting";

export interface DocumentsMove {
  stagedCount: number;
  stagedIds: readonly string[];
  stageDocument(documentId: string): void;
  stageSelected(): void;
  stageSelectedDisabled: boolean;
  confirm(): Promise<void>;
  confirmVisible: boolean;
  confirmDisabled: boolean;
  confirmLoading: boolean;
  reset(): void;
}

/** Handles state and logic required for moving documents. */
export const useDocumentsMove = (
  treeId: string,
  { documentId }: DocumentsRouting,
  { selection }: DocumentsTableData
): DocumentsMove => {
  const [stagedIds, setStagedIds] = useState<readonly string[]>([]);
  const stagedCount = stagedIds.length;
  const [request, { loading: confirmLoading }] = useDocumentsMoveMutation();

  const stageDocument = useCallback((id: string) => {
    setStagedIds([id]);
  }, []);

  const stageSelectedDisabled = confirmLoading || selection.count === 0;
  const stageSelected = useCallback(() => {
    setStagedIds(selection.values().map((d) => d.id));
  }, [selection]);

  const confirmVisible = stagedIds.length > 0;
  const confirmDisabled = confirmLoading;

  /**
   * Moves the staged documents to the current folder and resets the
   * move state.
   */
  const confirm = useCallback(async () => {
    await request({
      variables: {
        treeId,
        destinationParentId: documentId,
        sourceDocumentIds: stagedIds,
      },
    });
    setStagedIds([]);
  }, [request, treeId, documentId, stagedIds]);

  /** Unstages any files staged for moving */
  const reset = useCallback(() => setStagedIds([]), []);

  return {
    stagedCount,
    stagedIds,
    stageDocument,
    stageSelected,
    stageSelectedDisabled,
    confirm,
    confirmVisible,
    confirmDisabled,
    confirmLoading,
    reset,
  };
};
