import classnames from "classnames";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import { CloseButton } from "../generic/CloseButton";

export const DropdownMenuTheme = Object.freeze({
  BLUE: "blue",
  LIGHT_BORDERLESS: "light_borderless",
  ORANGE: "orange",
  DARK: "dark",
});

export const DropdownMenuAlign = Object.freeze({
  RIGHT: "right",
  LEFT: "left",
});

/**
 * Sadly React would complain with "Function components cannot be given refs"
 * warnings if we would write this as a pure component. It is used in
 * `Dropdown.Trigger` as a custom component.
 */
export const DropdownTrigger = React.forwardRef(
  ({ children, theme, className, arrowClass, ...rest }, ref) => {
    // eslint-disable-line no-unused-vars
    const showMore = !children[0] ? `icon-show-more--${theme}` : "";
    return (
      <button
        type="button"
        aria-haspopup
        aria-expanded={false}
        data-testid="dropdown-trigger"
        {...rest}
        className={classnames(
          className,
          `dropdown-toggle btn btn--${theme}`,
          showMore,
          arrowClass
        )}
        style={{ height: "100%" }}
      >
        {children}
      </button>
    );
  }
);

DropdownTrigger.propTypes = {
  arrowClass: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(DropdownMenuTheme)).isRequired,
  children: PropTypes.node,
  className: PropTypes.node,
};

DropdownTrigger.defaultProps = {
  children: undefined,
  className: "",
};

const CustomDropdownMenu = React.forwardRef(
  (
    { children, withClose, className, close },
    ref // eslint-disable-line no-unused-vars
  ) => (
    <div className={className}>
      {withClose && <CloseButton onClick={close} />}
      {children}
    </div>
  )
);

CustomDropdownMenu.propTypes = {
  children: PropTypes.node,
  withClose: PropTypes.bool,
  className: PropTypes.string,
  close: PropTypes.func.isRequired,
};

CustomDropdownMenu.defaultProps = {
  withClose: false,
  className: "",
  children: undefined,
};

export const DropdownMenu = ({
  theme,
  menuAlign,
  btnText,
  showArrow,
  className,
  menuClassName,
  children,
  withClose,
  showIcon,
  iconStyles,
  iconComponent,
  iconColor,
  hoverIconColor,
  isOpen,
  toggleOpen,
}) => {
  const [hovered, setHovered] = useState(false);

  const toggleHover = () => {
    setHovered(!hovered);
  };

  const renderIcon = (component) => {
    const IconComponent = component;
    return (
      <div style={iconStyles}>
        <IconComponent color={hovered ? hoverIconColor : iconColor} />
      </div>
    );
  };

  return (
    <Dropdown show={isOpen} onToggle={toggleOpen} className={className}>
      <Dropdown.Toggle
        as={DropdownTrigger}
        theme={theme}
        arrowClass={showArrow ? `icon-arrow` : ""}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        {btnText} {showIcon ? renderIcon(iconComponent) : null}
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={CustomDropdownMenu}
        className={[
          "dropdown-menu",
          menuClassName,
          `dropdown-menu-${menuAlign}`,
        ].join(" ")}
        withClose={withClose}
      >
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownMenu.propTypes = {
  theme: PropTypes.oneOf(Object.values(DropdownMenuTheme)).isRequired,
  menuAlign: PropTypes.oneOf(Object.values(DropdownMenuAlign)),
  btnText: PropTypes.string,
  showArrow: PropTypes.bool,
  children: PropTypes.node,
  // classNames for the dropdown trigger
  className: PropTypes.string,
  menuClassName: PropTypes.string,
  withClose: PropTypes.bool,
  showIcon: PropTypes.bool,
  iconStyles: PropTypes.shape({}),
  iconComponent: PropTypes.func,
  iconColor: PropTypes.string,
  hoverIconColor: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func,
};

DropdownMenu.defaultProps = {
  menuAlign: DropdownMenuAlign.RIGHT,
  showArrow: false,
  children: undefined,
  className: "",
  menuClassName: "",
  btnText: "",
  withClose: false,
  showIcon: false,
  iconStyles: {},
  iconComponent: null,
  iconColor: "",
  hoverIconColor: "",
  // Setting `isOpen` to `undefined` means that the open state is controlled
  // INSIDE react-bootstrap's Dropdown compnent. So, if no `isOpen` is passed
  // as the prop, the Dropdown will control itself. If an `isOpen` IS passed
  // in, then the caller is responsible for controlling the dropdown (using
  // the `toggleOpen` prop).
  isOpen: undefined,
  toggleOpen: undefined,
};
