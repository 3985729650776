import type { Dispatch, Reducer } from "~/hooks/useSimpleStore";
import * as rect from "~/utils/rect";
import * as vec2 from "~/utils/vec2";

import * as anchor from "./anchor";

type Anchor = anchor.Anchor;
type Rect = rect.Rect;
type Vec2 = vec2.Vec2;

/*********
 * STATE *
 *********/
export type WindowMetrics = {
  animate: boolean;
  minimized: boolean;
  position: Rect;
  lastSize: Vec2;
  bounds: Rect;
  constraints: Rect;
};
export type WindowOperation =
  | { operation: "IDLE" }
  | { operation: "DRAGGING"; point: Vec2 }
  | { operation: "RESIZING"; point: Vec2; anchor: Anchor };
export type WindowState = WindowMetrics & WindowOperation;

/***********
 * ACTIONS *
 ***********/
export type WindowAction =
  | { type: "MINIMIZE" }
  | { type: "MAXIMIZE" }
  | { type: "DRAG_START"; point: Vec2 }
  | { type: "DRAG_MOVE"; point: Vec2 }
  | { type: "DRAG_END" }
  | { type: "RESIZE_START"; point: Vec2; anchor: Anchor }
  | { type: "RESIZE_MOVE"; point: Vec2 }
  | { type: "RESIZE_END" }
  | { type: "RECONFIGURE"; bounds: Rect; constraints: Rect };

export type WindowDispatch = Dispatch<WindowState, WindowAction>;

/***********
 * REDUCER *
 ***********/
export const windowReducer: Reducer<WindowState, WindowAction> = (
  state,
  action
): WindowState => {
  switch (action.type) {
    case "MINIMIZE": {
      const lastSize = rect.size(state.position);
      const minimizedSize = vec2.create(state.constraints.min.x, 54);
      const position = rect.create(
        vec2.subtract(state.position.max, minimizedSize),
        state.position.max
      );

      return { ...state, minimized: true, lastSize, position, animate: true };
    }

    case "MAXIMIZE": {
      const position = rect.create(
        vec2.subtract(state.position.max, state.lastSize),
        state.position.max
      );
      return normalizeState({
        ...state,
        position,
        minimized: false,
        animate: true,
      });
    }

    case "DRAG_START": {
      // (IDLE -> DRAGGING): set the initial drag point
      const { point } = action;
      return { ...state, operation: "DRAGGING", point, animate: false };
    }

    case "DRAG_MOVE": {
      // (DRAGGING): update the `offset` and the latest `point`
      if (state.operation !== "DRAGGING") return state;

      // Constrain the cursor location based on the drag bounds
      const translateBounds = rect.subtract(state.bounds, state.position);
      const cursorBounds = rect.translate(translateBounds, state.point);
      const point = vec2.constrain(action.point, cursorBounds);

      // Translate the window
      const offset = vec2.subtract(point, state.point);
      const position = rect.translate(state.position, offset);

      return { ...state, point, position, animate: false };
    }

    case "DRAG_END": {
      // (DRAGGING -> IDLE)
      return { ...state, operation: "IDLE", animate: false };
    }

    case "RESIZE_START": {
      // (IDLE -> RESIZING)
      const { point, anchor } = action;
      return {
        ...state,
        operation: "RESIZING",
        anchor,
        point,
        animate: false,
      };
    }

    case "RESIZE_MOVE": {
      // (RESIZING)
      if (state.operation !== "RESIZING") return state;

      // Get the maximum positive/negative distance each side of the window can be moved
      const size = rect.size(state.position);
      const grow = vec2.subtract(state.constraints.max, size);
      const shrink = vec2.subtract(size, state.constraints.min);
      const positive = rect.create(shrink, grow);
      const negative = rect.invert(positive);

      // Determine how far the resize anchor can move based on the window constraints
      const anchorNegative = rect.multiply(state.anchor.sides, negative);
      const anchorPositive = rect.multiply(state.anchor.sides, positive);
      const scaleBounds = rect.create(
        rect.sum(anchorNegative),
        rect.sum(anchorPositive)
      );

      // Determine how far the resize anchor can move based on the drag bounds
      const anchorPosition = rect.multiply(state.anchor.sides, state.position);
      const anchorPoint = rect.point(rect.sum(anchorPosition));
      const translateBounds = rect.subtract(state.bounds, anchorPoint);

      // Constrain the cursor location based on the window constraints and drag bounds
      const transformBounds = rect.intersection(scaleBounds, translateBounds);
      const cursorBounds = rect.translate(transformBounds, state.point);
      const point = vec2.constrain(action.point, cursorBounds);

      // Transform the window
      const anchorOffset = rect.point(vec2.subtract(point, state.point));
      const offset = rect.multiply(state.anchor.sides, anchorOffset);
      const position = rect.add(state.position, offset);

      return { ...state, point, position, animate: false };
    }

    case "RESIZE_END": {
      // (RESIZING -> IDLE)
      return { ...state, operation: "IDLE", animate: false };
    }

    case "RECONFIGURE": {
      const { bounds, constraints } = action;
      return normalizeState({ ...state, bounds, constraints, animate: false });
    }

    default:
      return state;
  }
};

const normalizeState = (state: WindowState): WindowState => {
  // First, clone & normalize all rects
  let position = rect.normalize(state.position);
  let bounds = rect.normalize(state.bounds);
  let constraints = rect.normalize(state.constraints);

  // Ensure the bounds are no smaller than the min constraints
  bounds.max = vec2.max(bounds.max, vec2.add(bounds.min, constraints.min));

  // Next, move the window up and to the left to fix south/east overflow
  const distanceSE = vec2.subtract(bounds.max, position.max);
  const overflowSE = vec2.min(vec2.zero, distanceSE);
  position = rect.translate(position, overflowSE);

  // Then, move the window down and to the right to fix north/west overflow
  const distanceNW = vec2.subtract(bounds.min, position.min);
  const overflowNW = vec2.max(vec2.zero, distanceNW);
  position = rect.translate(position, overflowNW);

  // Finally, shrink the window to fit within the bounds
  position = rect.intersection(position, bounds);

  return { ...state, position, bounds, constraints };
};

/*************
 * SELECTORS *
 *************/

/** Builds a CSS transform `translate` string for the given window state */
export const getTranslation = (state: WindowState): string => {
  let { x, y } = state.position.min;
  if (state.minimized) {
    x = state.position.max.x - 386;
    y = state.position.max.y - 54;
  }

  return `translate3d(${x}px, ${y}px, 0)`;
};

/** Gets the CSS cursor that should be shown for the current operation */
export const getCursor = (state: WindowState): string => {
  if (state.operation === "DRAGGING") return "move";
  if (state.operation === "RESIZING") return state.anchor.cursor;
  return "";
};

/** Gets the dynamic CSS window styles for the given window state */
export const getWindowStyle = (state: WindowState) => {
  const size = state.minimized ? { x: 386, y: 54 } : rect.size(state.position);
  return {
    transform: getTranslation(state),
    width: `${size.x}px`,
    height: `${size.y}px`,
  } as const;
};
