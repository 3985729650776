import Select from "@material-ui/core/Select";
import React, { useRef, useState } from "react";

import { WorkflowStatus } from "~/api/types.generated";
import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { InputLabel } from "~/components/common/inputs";
import { Text } from "~/components/common/text";
import { Toolbar } from "~/components/common/toolbars";
import Dialog from "~/components/generic/Dialog";
import {
  getWorkflowStatusDisplay,
  usableStates,
} from "~/components/Projects/constants";

import { UpdatePortfolio } from "./useUpdatePortfolio";

function getEnumKeys<T extends string, TEnumValue extends string | number>(
  enumVariable: { [key in T]: TEnumValue }
) {
  return Object.keys(enumVariable) as Array<T>;
}

export interface UpdatePortfolioDialogProps extends UpdatePortfolio {}

export const UpdatePortfolioDialog = React.memo(function UpdatePortfolioDialog(
  props: UpdatePortfolioDialogProps
) {
  const { isOpen, status, error, disabled, orgSlug } = props;
  const statusDisplay = getWorkflowStatusDisplay(orgSlug);
  const { submit, reset } = props;
  const [selectStatus, setSelectStatus] = useState<WorkflowStatus>(
    status || WorkflowStatus.Draft
  );
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectStatus(event.target.value as WorkflowStatus);
  };
  const input = useRef<HTMLInputElement>(null);

  const close = () => {
    reset();
  };

  return (
    <Dialog
      isOpen={isOpen}
      header="Update Status"
      onRequestClose={close}
      actions={
        <Toolbar>
          <SecondaryButton onClick={close}>Cancel</SecondaryButton>
          <PrimaryButton
            disabled={disabled}
            onClick={() => submit(selectStatus)}
          >
            Update
          </PrimaryButton>
        </Toolbar>
      }
    >
      {error && <div className="alert alert-danger">{error}</div>}
      <InputLabel>
        <Text>Deal Status</Text>
        <Select
          variant="outlined"
          ref={input}
          native
          value={selectStatus}
          onChange={handleChange}
          label="status"
        >
          {getEnumKeys(WorkflowStatus)
            .filter((key) => usableStates.includes(key as any))
            .map((key) => (
              <option
                key={key}
                aria-label="None"
                value={WorkflowStatus[key]}
              >{`${statusDisplay[WorkflowStatus[key]]}`}</option>
            ))}
        </Select>
      </InputLabel>
    </Dialog>
  );
});
