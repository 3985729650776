import { ellipsis } from "polished";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import styled from "styled-components";

import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "~/components/common/buttons";
import { DownloadIcon, Robot } from "~/components/common/icons";
import { Checkbox } from "~/components/common/inputs";
import { CircleProgress } from "~/components/common/progress/CircleProgress";
import { Toolbar } from "~/components/common/toolbars";
import { useSummaryJobsQuery } from "~/components/Documents/DocumentsAPI.generated";
import DocumentsFilter from "~/components/Documents/DocumentsCopilot/DocumentsFilter";
import DocumentsFilterContext from "~/components/Documents/DocumentsCopilot/DocumentsFilterContext";
import JobTypeDropdown from "~/components/Documents/DocumentsCopilot/JobTypeDropdown";
import { CopilotProps } from "~/components/Documents/DocumentsCopilot/useCopilot";
import { OkIcon } from "~/components/icons";
import { StyledTab, StyledTabs } from "~/components/menus/AppleTabs";
import TabPanel from "~/components/menus/TabPanel";
import { Window } from "~/components/Window";
import { border } from "~/styles/mixins";
import { darkBlue, gray300 } from "~/styles/theme/color";
import { borderRadius } from "~/styles/theme/common";
import * as rect from "~/utils/rect";
import * as vec2 from "~/utils/vec2";

const getBounds = () =>
  rect.create(
    vec2.create(12, 166),
    vec2.create(window.innerWidth - 12, window.innerHeight - 12)
  );

const getInitialPosition = (bounds: rect.Rect) =>
  rect.create(vec2.subtract(bounds.max, vec2.create(640, 480)), bounds.max);

const constraints = rect.create(vec2.create(386, 150), vec2.create(720, 560));

export const CopilotDialog = (props: CopilotProps) => {
  const { disabled, submit, close, documentsFilter } = props;
  const [tabValue, setTabValue] = useState<number>(0);
  const [bounds, setBounds] = useState(getBounds);
  const [initialPosition] = useState(() => getInitialPosition(bounds));
  const { data } = useSummaryJobsQuery({
    variables: { treeId: props.treeId },
  });
  const jobs = data?.summaryJobs ?? [];
  const summaryJobRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleViewportResize = () => setBounds(getBounds);
    window.addEventListener("resize", handleViewportResize);
    return () => window.removeEventListener("resize", handleViewportResize);
  }, []);

  const onClose = useCallback(() => {
    close();
  }, [close]);

  if (!props.isOpen) {
    return null;
  }

  return (
    <Window
      title={
        <>
          <Robot />
          <span className="ml-2">Copilot</span>
        </>
      }
      initialPosition={initialPosition}
      bounds={bounds}
      constraints={constraints}
      canClose={true}
      onClose={onClose}
      minimize={false}
      overflowYScroll={true}
    >
      <DocumentsFilterContext.Provider value={documentsFilter}>
        <StyledTabs
          ref={summaryJobRef}
          value={tabValue}
          onChange={(_: object, value: number) => setTabValue(value)}
        >
          <StyledTab label="New" index={0} />
          <StyledTab label="Completed" index={1} />
        </StyledTabs>
        <DialogTabPanel index={0} value={tabValue}>
          <Controls>
            <JobTypeDropdown
              selectedType={props.selectedJobType}
              setSelectedType={props.setSelectedJobType}
            />
            <DocumentsFilter />
          </Controls>
          <Options>
            <CheckboxArea>
              <Title>Extract</Title>
              {props.selectionOptions.map((item) => (
                <span key={item.id}>
                  <Checkbox
                    checked={props.selection.includes(item)}
                    onChange={() => props.selection.toggle(item)}
                  />{" "}
                  {item.displayName}
                </span>
              ))}
            </CheckboxArea>
            <Toolbar>
              <PrimaryButton
                disabled={disabled}
                onClick={() =>
                  submit().then(() => {
                    setTabValue(1);
                    if (summaryJobRef.current !== null) {
                      summaryJobRef.current.scrollIntoView();
                      summaryJobRef.current.focus();
                    }
                  })
                }
              >
                Create
              </PrimaryButton>
              <SecondaryButton onClick={close}>Cancel</SecondaryButton>
            </Toolbar>
          </Options>
        </DialogTabPanel>
        <DialogTabPanel index={1} value={tabValue}>
          <Table>
            <div className="header-row">
              <div className="header thumbnail-cell"></div>
              <div className="header name-cell">Name</div>
              <div className="header status-cell">Status</div>
              <div className="header download-cell">Download</div>
            </div>
            <div className="body">
              <AutoSizer>
                {({ height, width }) => (
                  <FixedSizeList
                    height={height}
                    width={width}
                    itemCount={jobs.length}
                    itemSize={30}
                  >
                    {({ index, style }) => {
                      const job = jobs[index];
                      return (
                        <div className="row" key={index} style={style as any}>
                          <div className="cell name-cell">
                            <span>{job.name}</span>
                          </div>
                          <div className="cell status-cell">
                            {!job.complete && (
                              <>
                                <CircleProgress percent={100} />
                                <span className="ml-2">Processing...</span>
                              </>
                            )}
                            {job.complete && (
                              <>
                                <OkIcon withoutBackground className="mr-2" />
                                Complete
                              </>
                            )}
                          </div>
                          <div className="cell download-cell">
                            {job.complete && (
                              <DownloadButton
                                download
                                as="a"
                                target="_blank"
                                href={`${job?.outputFileUrl}`}
                              >
                                <DownloadIcon width={10} height={10} />
                              </DownloadButton>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </div>
          </Table>
        </DialogTabPanel>
      </DocumentsFilterContext.Provider>
    </Window>
  );
};

const Title = styled.div``;

const CheckboxArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-radius: ${borderRadius};
  ${border.gray300()};
`;

const DialogTabPanel = styled(TabPanel)`
  & {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;
    margin: 12px 0 0 0;
    width: 100%;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  width: 100%;
  gap: 10px;
`;

const DownloadButton = styled(TextButton)`
  &:hover {
    color: ${darkBlue};
  }

  & svg:first-child:last-child {
    width: 1rem;
    height: 1rem;
    margin: 0;
  }
`;

const Table = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 0.875rem;
  border: 1px solid ${gray300};
  border-radius: ${borderRadius};

  .body {
    flex: 1 1 auto;
    overflow: hidden;
    width: 100%;
  }

  .header-row {
    font-weight: 700;
    border-bottom: 1px solid ${gray300};
  }

  .header-row,
  .row {
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    padding: 0 15px;
  }

  .header,
  .cell {
    height: 2.5rem;
    display: flex;
    align-items: center;
  }

  .name-cell {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    > span {
      ${ellipsis()};
    }
  }

  .status-cell {
    width: 10rem;
    flex: 0 0 10rem;
    overflow: hidden;
    > span {
      ${ellipsis()};
    }
  }

  .download-cell {
    width: 8rem;
    flex: 0 0 8rem;
    justify-content: center;
    align-items: center;
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
`;
