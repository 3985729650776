import Dialog from "components/generic/Dialog";
import React from "react";

import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { Toolbar } from "~/components/common/toolbars";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";
import { DocumentsZipDownloads } from "~/components/Documents/useDocumentsZipDownloads";

export interface DocumentsZipDownloadsDialogProps
  extends DocumentsZipDownloads {
  track: DocumentsEventTracking;
  downloadFile: (documentId: string) => void;
}

export const DocumentsZipDownloadsDialog = (
  props: DocumentsZipDownloadsDialogProps
) => {
  const {
    track,
    stagedCount,
    error,
    result,
    confirm,
    confirmAvailable,
    confirmLoading,
    confirmVisible,
    reset,
    downloadFile,
  } = props;

  const header =
    stagedCount === 1
      ? "You selected one file or folder to be zipped."
      : `You selected ${stagedCount} files or folders to be zipped.`;

  const successMsg =
    "You can now close this window and continue browsing the site. " +
    "It should take no longer than 15 minutes to receive the email " +
    "with the download link.";

  const close = () => {
    track.zipDownloadCancel();
    reset();
  };

  return (
    <Dialog
      isOpen={confirmVisible}
      onRequestClose={close}
      header="Create a Zip Container"
      actions={
        <Toolbar>
          {confirmAvailable && (
            <>
              <SecondaryButton onClick={close}>Close</SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  track.zipDownloadConfirm();
                  confirm();
                }}
                disabled={confirmLoading}
              >
                Export
              </PrimaryButton>
            </>
          )}
          {!confirmAvailable && (
            <PrimaryButton onClick={close}>Close</PrimaryButton>
          )}
        </Toolbar>
      }
    >
      <div className="form-group mt-3">
        <div>
          <p>{header}</p>
          {result?.exportable && result.exportable.length > 0 && (
            <p>{successMsg}</p>
          )}
        </div>
        {error && (
          <div className="text-danger font-weight-bold mt-1">{error}</div>
        )}
        {result?.unexportable && result.unexportable.length > 0 && (
          <>
            <div className="font-weight-bold mt-3">
              {
                "The following files exceed the 512MB file size limit. Please download them directly."
              }
            </div>
            <div className="unexportable-files text-break">
              {result.unexportable.map((file, index: number) => (
                <div key={index} className="mt-1">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/"
                    onClick={(event) => {
                      event.preventDefault();
                      if (file?.id) downloadFile(file.id);
                      return false;
                    }}
                  >
                    {index + 1}. {file?.filePath}
                  </a>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
