// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type ProjectsBannerFragment = (
  { readonly __typename: 'ProjectsCollection' }
  & { readonly aggregations?: Types.Maybe<(
    { readonly __typename: 'ProjectsCollectionAggregations' }
    & Pick<Types.ProjectsCollectionAggregations, 'minCapacity' | 'maxCapacity' | 'count' | 'totalCapacity'>
  )> }
);

export const ProjectsBannerFragmentDoc = gql`
    fragment ProjectsBanner on ProjectsCollection {
  aggregations {
    minCapacity
    maxCapacity
    count
    totalCapacity
  }
}
    `;