import Dialog from "components/generic/Dialog";
import React, { useState } from "react";
import { gettext } from "utils/text";

import { CheckPasswordFormFragment } from "~/components/forms/PasswordConfirm.generated";
import { UpdatePhoneNumberFormFragment } from "~/components/forms/UpdatePhoneNumberConfirmAuth.generated";
import { UpdatePhoneNumberDialog } from "~/components/profile/UpdatePhoneNumberDialog";

import { ConfirmPasswordForm } from "../forms/PasswordConfirm";

export interface PhoneNumberUpdateProps {
  user: CheckPasswordFormFragment & UpdatePhoneNumberFormFragment;
  phoneNumberChanged: (messsage: string) => void;
}

export const PhoneNumberUpdate = ({
  user,
  phoneNumberChanged,
}: PhoneNumberUpdateProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [phoneNumberUpdateVisible, setPhoneNumberUpdateVisible] =
    useState(false);
  const renderPasswordConfirmModal = () => (
    <Dialog
      isOpen={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      header={gettext("Edit two-factor authentication")}
      titleVariant="large"
      actionsVariant={false}
    >
      <div className="text-center">
        <ConfirmPasswordForm
          user={user}
          togglePhoneUpdateVisible={() =>
            setPhoneNumberUpdateVisible(!phoneNumberUpdateVisible)
          }
          closeModal={() => setModalOpen(false)}
        />
      </div>
    </Dialog>
  );

  return (
    <div className="phone-number-update">
      <div className="col-md-6 col-sm-10 mb-2 p-0">
        <UpdatePhoneNumberDialog
          user={user}
          phoneNumberUpdateVisible={phoneNumberUpdateVisible}
          phoneNumberChanged={phoneNumberChanged}
          togglePhoneUpdateVisible={() =>
            setPhoneNumberUpdateVisible(!phoneNumberUpdateVisible)
          }
        />
      </div>
      <div className="col-md-4 pl-0" hidden={phoneNumberUpdateVisible}>
        <button
          type="button"
          className="btn btn-link text-left edit-button mt-1"
          onClick={() => setModalOpen(true)}
        >
          {gettext("Edit")}
        </button>
      </div>
      {renderPasswordConfirmModal()}
    </div>
  );
};

export default PhoneNumberUpdate;
