// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type HomePageProjectsBannerFragment = (
  { readonly __typename: 'Query' }
  & { readonly acquiredProjectsStats?: Types.Maybe<(
    { readonly __typename: 'ProjectsStats' }
    & Pick<Types.ProjectsStats, 'count' | 'totalCapacity'>
  )> }
);

export const HomePageProjectsBannerFragmentDoc = gql`
    fragment HomePageProjectsBanner on Query {
  acquiredProjectsStats(orgSlug: $orgSlug) {
    count
    totalCapacity
  }
}
    `;