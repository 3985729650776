import "bootstrap";

import { enableMapSet } from "immer";
import Raven from "raven-js";
import React from "react";
import ReactDOM from "react-dom";
import submitButton from "utils/submitButton";
import initAdmin from "widgets/AdminViewEnhancers";
import initCategoryEditor from "widgets/CategoryEditor";

import { App } from "~/App";

enableMapSet();

// Install Raven in production envs
if (process.env.NODE_ENV === "production") {
  Raven.config(DJ_CONST.RAVEN_PUBLIC_DSN).install(); // eslint-disable-line
  // handle rejected promises
  window.addEventListener("unhandledrejection", (evt) => {
    Raven.captureException(evt.reason);
  });
  // If we have authenticated user, pass its data on to Raven
  if (DJ_CONST.user) {
    Raven.setUserContext({
      id: DJ_CONST.user.id,
      email: DJ_CONST.user.email,
      name: DJ_CONST.user.name,
    });
  }
}

function init() {
  // IE fix
  HTMLCollection.prototype.forEach = Array.prototype.forEach;
  NodeList.prototype.forEach = Array.prototype.forEach;

  submitButton();

  // Initialize Mixpanel
  if (DJ_CONST.user) {
    if (DJ_CONST.user.is_hijacked_user) {
      mixpanel.disable();
    }

    mixpanel.identify(DJ_CONST.user.id);
    mixpanel.people.set({
      $email: DJ_CONST.user.email,
      $phone: DJ_CONST.user.phone_number,
      $first_name: DJ_CONST.user.first_name,
      $last_name: DJ_CONST.user.last_name,
      $created: DJ_CONST.user.date_joined,
      "Is Staff": DJ_CONST.user.is_staff,
      Company: DJ_CONST.user.company,
    });
  }
}

function initApp() {
  ReactDOM.render(<App />, document.getElementById("app"));
}

export { init, initApp, initAdmin, initCategoryEditor };
