import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
// import { Operation, split } from '@apollo/client';
// import { getMainDefinition } from '@apollo/client/utilities';
// import { WebSocketLink } from '@apollo/link-ws';
import { getCookie } from "utils/cookie";

import introspection, { TypedTypePolicies } from "./introspection.generated";

const httpLink = createUploadLink({
  uri: "/graphql",
  credentials: "same-origin",
  headers: {
    "X-CSRFToken": getCookie("csrftoken"),
  },
});

/*
const socketProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
const socketUrl = `${socketProtocol}${window.location.host}/ws/graphql/`;
const wsLink = new WebSocketLink({
  uri: socketUrl,
  options: {
    reconnect: true,
  },
});

const isSubscription = ({ query }: Operation) => {
  const definition = getMainDefinition(query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
};

const splitLink = split(isSubscription, wsLink, httpLink);
*/

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      document: (_, { args, toReference }) =>
        toReference({ __typename: "Document", id: args?.documentId }),
      user: (_, { args, toReference }) =>
        toReference({ __typename: "User", id: args?.id }),
      companyAggs: {
        // Don't cache separate results for pagination field args.
        keyArgs: ["ordering", "treeId"],
        // Concatenate the incoming list items with the existing list items.
        merge(existing, incoming) {
          if (!existing) return incoming;
          return {
            ...existing,
            ...incoming,
            companies: [...existing.companies, ...incoming.companies],
          };
        },
      },
      userAggs: {
        // Don't cache separate results for pagination field args.
        keyArgs: ["ordering", "treeId"],
        // Concatenate the incoming list items with the existing list items.
        merge(existing, incoming) {
          if (!existing) return incoming;
          return {
            ...existing,
            ...incoming,
            users: [...existing.users, ...incoming.users],
          };
        },
      },
      documentAggs: {
        // Don't cache separate results for pagination field args.
        keyArgs: ["ordering", "treeId"],
        // Concatenate the incoming list items with the existing list items.
        merge(existing, incoming) {
          if (!existing) return incoming;
          return {
            ...existing,
            ...incoming,
            documents: [...existing.documents, ...incoming.documents],
          };
        },
      },
    },
  },
  OrgInvitation: {
    keyFields: ["token"],
  },
};

const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: introspection.possibleTypes,
    typePolicies,
  }),
  link: httpLink,
});

export default client;
