import { transparentize } from "polished";

import theme, { Theme, ThemeProps } from "~/styles/theme";

export type ColorName = keyof Theme["color"];
export const COLOR_NAMES = [
  ...Object.keys(theme.color),
] as readonly ColorName[];

export const mapColors = <T>(mapper: (name: ColorName) => T) =>
  COLOR_NAMES.reduce(
    (result, name) => ({ ...result, [name]: mapper(name) }),
    {} as Record<ColorName, T>
  );

export interface ColorOptions {
  opacity?: number;
}

export const getColor = (
  name: ColorName,
  { opacity = 1 }: ColorOptions,
  props: ThemeProps
) => transparentize(1 - opacity, props.theme.color[name]);

/**
 * Helpers for getting theme colors.
 *
 * @example
 *
 *   styled.div`
 *     box-shadow: 10px 5px 5px ${color.black({ opacity: 0.5 })};
 *   `
 */
const color = mapColors(
  (name) =>
    (options: ColorOptions = {}) =>
    (props: ThemeProps) =>
      getColor(name, options, props)
);

export default color;
