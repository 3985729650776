import React from "react";
import styled from "styled-components";

import {
  borderBottom,
  borderLeft,
  borderRight,
  borderTop,
} from "~/styles/mixins";
import { GetPropsWithRef } from "~/utils/types";

import DataGrid from "./DataGrid";

export interface ScrollableGridProps extends GetPropsWithRef<"div"> {}

const ScrollableGrid = ({ children, ...rest }: ScrollableGridProps) => (
  <div {...rest}>
    <ScrollArea children={children} />
  </div>
);

const ScrollArea = styled.div`
  width: 100%;
  height: 680px;
  overflow: auto;
`;

export default styled(ScrollableGrid)`
  ${borderBottom.gray300()};
  ${borderLeft.gray300()};
  ${borderRight.gray300()};
  position: relative;
  z-index: 0;

  & ${DataGrid.Cell} {
    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  & ${DataGrid.Header} ${DataGrid.HeaderCell} {
    position: sticky;
    top: 0px;
    border-bottom-color: transparent;
    border-top: none;
    z-index: 2;

    &:before {
      ${borderTop.gray300()};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      content: "";
    }

    &:after {
      ${borderBottom.black()};
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      content: "";
    }

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  & ${DataGrid.Row} {
    scroll-margin-top: 40px;
  }
`;
