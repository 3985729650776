import { gql } from "@apollo/client";
import React, { useMemo } from "react";
import styled from "styled-components";
import { gettext } from "utils/text";

import { SecondaryButton } from "~/components/common/buttons";
import { FileExcelIcon } from "~/components/common/icons";
import { PrimaryHeading } from "~/components/common/text";
import { useDocumentTreeQuery } from "~/components/DataroomAnalytics/AnalyticsPage.generated";
import { CompanyTable } from "~/components/DataroomAnalytics/CompanyTable";
import OrderingContext, {
  useOrderingContext,
} from "~/components/DataroomAnalytics/context/OrderingContext";
import { DocumentTable } from "~/components/DataroomAnalytics/DocumentTable";
import { UserTable } from "~/components/DataroomAnalytics/UserTable";
import { useEventTracking } from "~/event-tracking/EventTrackingProvider";

export const TREE_QUERY = gql`
  query DocumentTree($treeId: ID!) {
    documentTree(id: $treeId) {
      id
      accessLogUrl
    }
  }
`;

export interface AnalyticsPageProps {
  treeId: string;
}

export const AnalyticsPage = ({ treeId }: AnalyticsPageProps) => {
  const ordering = useOrderingContext();
  return (
    <OrderingContext.Provider value={ordering}>
      <RenderAnalyticsPage treeId={treeId} />
    </OrderingContext.Provider>
  );
};

export const RenderAnalyticsPage = ({ treeId }: AnalyticsPageProps) => {
  const { data } = useDocumentTreeQuery({ variables: { treeId } });
  const extraContext = useMemo(
    () => ({
      "Tree ID": treeId,
      "View Mode": "analytics",
    }),
    [treeId]
  );
  const track = useEventTracking(
    "Documents",
    {
      exportAccessLogClick: "Clicked Export Access Log",
    },
    extraContext
  );
  return (
    <div>
      <Header>
        <PrimaryHeading>{gettext("Analytics")}</PrimaryHeading>
        {data?.documentTree?.accessLogUrl && (
          <SecondaryButton
            as="a"
            target="_blank"
            href={data.documentTree.accessLogUrl}
            onClick={() => {
              track.exportAccessLogClick();
            }}
            style={{ height: "10px" }}
          >
            <FileExcelIcon />
            <span>Export</span>
          </SecondaryButton>
        )}
      </Header>
      <CompanyTable treeId={treeId} />
      <Space />
      <UserTable treeId={treeId} />
      <Space />
      <DocumentTable treeId={treeId} />
    </div>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Space = styled.div`
  margin: 10px 0;
`;
