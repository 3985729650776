import { createContext } from "react";

export interface SelectMenuContextValue<T> {
  focusIndex: number;
  setFocusIndex: (index: number) => unknown;
  closeOnSelect: boolean;
  isSelected: (value: T) => boolean;
}

const SelectMenuContext = createContext<SelectMenuContextValue<any>>({
  focusIndex: 0,
  setFocusIndex: () => {},
  closeOnSelect: true,
  isSelected: () => false,
});

export default SelectMenuContext;
