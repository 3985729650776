import { useCallback, useState } from "react";

import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";

import { useDocumentsDownloadMutation } from "./DocumentsAPI.generated";

export interface DocumentsDownloads {
  error: Error | null;
  downloadFile(documentId: string): Promise<void>;
  loadingDocument(documentId: string): boolean;
  reset(): void;
}

/**
 * Manages file downloads. Allows fetching a signed document URL and then
 * downloading the file asynchronously.
 */
export const useDocumentsDownloads = (
  treeId: string,
  track: DocumentsEventTracking
): DocumentsDownloads => {
  const [error, setError] = useState<Error | null>(null);
  const reset = useCallback(() => setError(null), []);
  const [loadUrl] = useDocumentsDownloadMutation({
    fetchPolicy: "no-cache",
  });
  const [downloadId, setDownloadId] = useState<string | null>(null);
  const loadingDocument = useCallback(
    (documentId: string) => documentId === downloadId,
    [downloadId]
  );

  /** Fetches a document url and then asynchronously downloads the file */
  const downloadFile = useCallback(
    async (documentId: string) => {
      setDownloadId(documentId);
      try {
        const result = await loadUrl({ variables: { treeId, documentId } });
        const url = result.data?.downloadFile?.url;
        const name = result.data?.downloadFile?.name ?? "file";
        if (url) {
          track.fileDownload(documentId, name);
          const anchor = document.createElement("a");
          anchor.setAttribute("href", url);
          anchor.setAttribute("download", name);
          anchor.click();
        }
      } catch (error) {
        setError(error);
      } finally {
        setDownloadId(null);
      }
    },
    [track, loadUrl, treeId]
  );

  return { error, downloadFile, loadingDocument, reset };
};
