// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdatePhoneNumberSendAuthFormFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'phoneNumber'>
);

export type UpdatePhoneNumberSendAuthMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  phoneNumber: Types.Scalars['String'];
  countryCode: Types.Scalars['String'];
}>;


export type UpdatePhoneNumberSendAuthMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateUserPhoneNumberSendAuth?: Types.Maybe<(
    { readonly __typename: 'User' }
    & UpdatePhoneNumberSendAuthFormFragment
  )> }
);

export const UpdatePhoneNumberSendAuthFormFragmentDoc = gql`
    fragment UpdatePhoneNumberSendAuthForm on User {
  id
  phoneNumber
}
    `;
export const UpdatePhoneNumberSendAuthDocument = gql`
    mutation UpdatePhoneNumberSendAuth($id: ID!, $phoneNumber: String!, $countryCode: String!) {
  updateUserPhoneNumberSendAuth(
    id: $id
    phoneNumber: $phoneNumber
    countryCode: $countryCode
  ) {
    ...UpdatePhoneNumberSendAuthForm
  }
}
    ${UpdatePhoneNumberSendAuthFormFragmentDoc}`;
export type UpdatePhoneNumberSendAuthMutationFn = Apollo.MutationFunction<UpdatePhoneNumberSendAuthMutation, UpdatePhoneNumberSendAuthMutationVariables>;

/**
 * __useUpdatePhoneNumberSendAuthMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberSendAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberSendAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberSendAuthMutation, { data, loading, error }] = useUpdatePhoneNumberSendAuthMutation({
 *   variables: {
 *      id: // value for 'id'
 *      phoneNumber: // value for 'phoneNumber'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useUpdatePhoneNumberSendAuthMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhoneNumberSendAuthMutation, UpdatePhoneNumberSendAuthMutationVariables>) {
        return Apollo.useMutation<UpdatePhoneNumberSendAuthMutation, UpdatePhoneNumberSendAuthMutationVariables>(UpdatePhoneNumberSendAuthDocument, baseOptions);
      }
export type UpdatePhoneNumberSendAuthMutationHookResult = ReturnType<typeof useUpdatePhoneNumberSendAuthMutation>;
export type UpdatePhoneNumberSendAuthMutationResult = Apollo.MutationResult<UpdatePhoneNumberSendAuthMutation>;
export type UpdatePhoneNumberSendAuthMutationOptions = Apollo.BaseMutationOptions<UpdatePhoneNumberSendAuthMutation, UpdatePhoneNumberSendAuthMutationVariables>;