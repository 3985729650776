import React from "react";
import styled from "styled-components";

import { QuestionLog } from "~/api/types.generated";
import { MenuButton, SecondaryButton } from "~/components/common/buttons";
import {
  AddIcon,
  FileExcelIcon,
  MoreIcon,
  PencilIcon,
} from "~/components/common/icons";
import {
  MenuTooltipContainer,
  useMenuTooltip,
} from "~/components/common/tooltips";
import reverseUrl from "~/utils/urls";

export interface QuestionLogMenuProps {
  isQAndAAdmin: boolean;
  selectedLog: QuestionLog;
  addLog: () => void;
  editLog: () => void;
}

export const QuestionLogMenu = React.memo(function QuestionLogMenu(
  props: QuestionLogMenuProps
) {
  const { isQAndAAdmin, selectedLog, addLog, editLog } = props;

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    useMenuTooltip();

  const spreadsheetUrl =
    selectedLog?.id && reverseUrl(`q-and-a-spreadsheet`, selectedLog.id);

  return (
    <>
      <QuestionLogMenuButton ref={setTriggerRef} children={<MoreIcon />} />
      {visible && (
        <MenuTooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
          {isQAndAAdmin && (
            <MenuButton onClick={() => addLog()}>
              <AddIcon />
              <span>Add Log</span>
            </MenuButton>
          )}
          <MenuButton onClick={() => editLog()}>
            <PencilIcon />
            <span>Edit Log</span>
          </MenuButton>
          {spreadsheetUrl && (
            <MenuButton as="a" target="_blank" href={spreadsheetUrl}>
              <FileExcelIcon />
              <span>Export</span>
            </MenuButton>
          )}
        </MenuTooltipContainer>
      )}
    </>
  );
});

const QuestionLogMenuButton = styled(SecondaryButton)`
  margin-left: 0.25rem;
`;
