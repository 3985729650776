// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
export type GroupRowFragment = (
  { readonly __typename: 'UserGroup' }
  & Pick<Types.UserGroup, 'id' | 'name' | 'portfolioIds' | 'memberCount'>
  & { readonly role?: Types.Maybe<(
    { readonly __typename: 'Role' }
    & Pick<Types.Role, 'id' | 'name'>
  )> }
);

export const GroupRowFragmentDoc = gql`
    fragment GroupRow on UserGroup {
  id
  name
  portfolioIds
  memberCount
  role {
    id
    name
  }
}
    `;