import { useMemo } from "react";

import { PermissionType } from "~/api/types.generated";
import { HomePageViewerFragment } from "~/components/HomePage/HomePage.generated";
import { FeatureFlagFragment, NavbarViewerFragment } from "~/Navbar.generated";

export type SubjectPermission = Record<string, Array<PermissionType>>;
export type OrgPermission = Record<string, ReadonlyArray<PermissionType>>;
export type OrgFeatureFlags = Record<string, FeatureFlagFragment>;

export type PermissionBySubject = {
  portfolio: SubjectPermission;
  holdingCompany: SubjectPermission;
};

export type ViewerPermissions = {
  byOrg: OrgPermission;
  featureFlagsByOrg: OrgFeatureFlags;
  bySubject: PermissionBySubject;
};

const useViewerPermissions = (
  viewer: NavbarViewerFragment | HomePageViewerFragment
): ViewerPermissions => {
  // Memoize the viewers permissions for flat and key by portfolio/holdingCompany id
  return useMemo(() => {
    const portfolio: SubjectPermission = {};
    const holdingCompany: SubjectPermission = {};
    const bySubject: PermissionBySubject = {
      portfolio: portfolio,
      holdingCompany: holdingCompany,
    };
    const byOrg: OrgPermission = {};
    const featureFlagsByOrg: OrgFeatureFlags = {};

    for (let org of viewer.organizations ?? []) {
      byOrg[org.id] = org.permissions;
      byOrg[org.slug] = org.permissions;
      featureFlagsByOrg[org.id] = org.featureFlags;
      featureFlagsByOrg[org.slug] = org.featureFlags;
    }
    for (let thing of viewer.permissions ?? []) {
      if (thing.name) {
        for (let portfolioId of thing.portfolioIds ?? []) {
          bySubject.portfolio[portfolioId] =
            bySubject.portfolio[portfolioId] || [];
          bySubject.portfolio[portfolioId].push(thing.name);
        }
        for (let holdingCompanyId of thing.holdingCompanyIds ?? []) {
          bySubject.holdingCompany[holdingCompanyId] =
            bySubject.holdingCompany[holdingCompanyId] || [];
          bySubject.holdingCompany[holdingCompanyId].push(thing.name);
        }
      }
    }
    return { byOrg, bySubject, featureFlagsByOrg };
  }, [viewer.permissions, viewer.organizations]);
};

export default useViewerPermissions;
