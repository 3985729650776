import * as PropTypes from "prop-types";
import React from "react";

const Project = ({ project, style }) => (
  <div
    className="sticky-table-header sticky-table-cell"
    key={project.id}
    style={style}
  >
    <div className="checklist-table__header-cell">{project.name}</div>
  </div>
);

Project.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  style: PropTypes.object,
};

Project.defaultProps = { style: {} };

export default Project;
