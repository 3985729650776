/**
 * Set cookie value and when it expires
 */
export function setCookie(
  name: string,
  value: string,
  days: number | null = null
) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  const path = "; path=/"; // Separate variable to get around warning from django's JS translation processing
  document.cookie = `${name}=${value}${expires}${path}`;
}

/**
 * Get cookie value for `name`
 */
export function getCookie(name: string) {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.startsWith(name)) {
      return trimmedCookie.substring(name.length + 1, trimmedCookie.length);
    }
  }
  return null;
}

/**
 * Delete cookie with name
 */
export function deleteCookie(name: string) {
  setCookie(name, "", -1);
}
