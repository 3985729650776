import classnames from "classnames";
import React from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { formatSize } from "utils/calculations";

import { ProjectDetailFragment } from "~/components/Projects/ProjectPage.generated";

export interface ProjectRadiusBarProps {
  project?: ProjectDetailFragment | null;
}

const ProjectRadiusBar = ({ project }: ProjectRadiusBarProps) => {
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const match = useRouteMatch();
  const basePath = match.path.replace(":orgSlug", orgSlug);

  return (
    <div className={classnames("project-section", "grid")}>
      <div>
        <span className="project-header">Add'l Projects 1 Mi Radius</span>
        {project?.withinOne?.length ? (
          project?.withinOne?.map((project) => (
            <div key={`one-mile-${project.id}`} className="project-content">
              <Link to={`${basePath.replace(":projectId", project.id || "")}`}>
                {project.name}
              </Link>
            </div>
          ))
        ) : (
          <div>--</div>
        )}
      </div>
      <div>
        <span className="project-header">Total System Size (1 Mile)</span>
        <div className="project-content">
          {project && formatSize(project.withinOneSumCapacityAc, "AC")}
        </div>
      </div>
      <div>
        <span className="project-header">Add'l Projects 10 Mi Radius</span>
        {project?.withinTen?.length ? (
          project?.withinTen?.map((project) => (
            <div key={`ten-mile-${project.id}`} className="project-content">
              <Link to={`${basePath.replace(":projectId", project.id || "")}`}>
                {project.name}
              </Link>
            </div>
          ))
        ) : (
          <div>--</div>
        )}
      </div>
      <div>
        <span className="project-header">Total System Size (10 Miles)</span>
        <div className="project-content">
          {project && formatSize(project.withinTenSumCapacityAc, "AC")}
        </div>
      </div>
    </div>
  );
};

export default ProjectRadiusBar;
