import { ApolloProvider } from "@apollo/client";
import React, { FC } from "react";

import client from "./client";

const GraphQLProvider: FC = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default GraphQLProvider;
