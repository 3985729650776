import React from "react";

export default function nl2br(text) {
  const res = [];
  text.split("\n").forEach((x, i) => {
    if (i !== 0) {
      res.push(<br key={`br-${i}`} />); // eslint-disable-line react/no-array-index-key
    }

    res.push(x);
  });

  return res;
}

/** Renders translated text containing a link with React.
 *
 * The text must contain [[ and ]] markers. Everything between them will be part of an <a> element.
 * Result will be wrapped in <span>
 *
 * Usage:
 *
 *   linkify(gettext("Maybe [[click here]] for fun!"), 'https://thorgate.eu', 'btn btn-text');
 */
export function linkify(text, url, linkClassName = "") {
  const linkBeginIdx = text.indexOf("[[");
  const linkEndIdx = text.indexOf("]]");
  const linkText = text.substr(linkBeginIdx + 2, linkEndIdx - linkBeginIdx - 2);

  return (
    <span>
      {text.substr(0, linkBeginIdx)}
      <a href={url} className={linkClassName}>
        {linkText}
      </a>
      {text.substr(linkEndIdx + 2)}
    </span>
  );
}

/* The following are global functions that get injected from Django. They allow for translations in JavaScript
   to make their way to .po files.
 */
export function gettext(value) {
  return window.gettext(value);
}

export function pgettext(context, value) {
  return window.pgettext(context, value);
}

export function ngettext(singular, plural, objectCount) {
  return window.ngettext(singular, plural, objectCount);
}

export function interpolate(formats, counts, kwargs) {
  return window.interpolate(formats, counts, kwargs);
}

/**
 * Utility function to trim a character from both start and end of a string.
 * From: https://stackoverflow.com/a/43333491.
 *
 * @param {string} string
 * @param {string} character
 *
 * @returns {string}
 */
export const trimChar = (string, character) => {
  const first = [...string].findIndex((char) => char !== character);
  const last = [...string].reverse().findIndex((char) => char !== character);
  return string.substring(first, string.length - last);
};
