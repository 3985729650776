import { css } from "styled-components";

import color from "./color";

export interface FocusOutlineOptions {
  inset?: boolean;
  subtle?: boolean;
}

/**
 * Mixin that sets a standard focus outline that follows the element's border radius,
 * by using a box shadow instead of an outline.
 *
 * @example
 *
 *   styled.button`
 *     ${focusOutline()};
 *   `
 *
 *   styled.input`
 *     ${focusOutline({ inset: true })};
 *   `
 */
const focusOutline = ({ inset, subtle }: FocusOutlineOptions = {}) =>
  css`
    &:focus {
      outline: none;
      ${inset
        ? css`
            box-shadow: inset 0 0 0 2px
              ${subtle ? color.secondary() : color.primary({ opacity: 0.5 })};
          `
        : css`
            box-shadow: 0 0 0 3px
              ${subtle ? color.secondary() : color.primary({ opacity: 0.5 })};
          `}
    }
  `;

export default focusOutline;
