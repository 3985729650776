import * as vec2 from "./vec2";
import type { Vec2 } from "./vec2";

/**
 * An axis-aligned rectangle with min and max coords
 *
 * A rect is "normalized" if `min.x <= max.x` and `min.y <= max.y`
 */
export interface Rect {
  min: Vec2;
  max: Vec2;
}

/** Creates a rect from a min vector and max vector */
export const create = (min: Vec2, max: Vec2): Rect => {
  return { min, max };
};

/** Creates a rect from four numbers representing the min and max x/y values */
export const values = (
  minX: number,
  minY: number,
  maxX: number,
  maxY: number
): Rect => {
  return create(vec2.create(minX, minY), vec2.create(maxX, maxY));
};

/** Returns a normalized rect where min.x <= max.x and min.y <= max.y */
export const normalize = (rect: Rect): Rect => {
  return create(vec2.min(rect.min, rect.max), vec2.max(rect.min, rect.max));
};

/** Creates a zero-width, zero-height rect from a vector */
export const point = (vec: Vec2): Rect => {
  return create(vec, vec);
};

/**
 * Gets a vector representing the size (width & height) of the rect
 *
 * Will return negative components if the rect is non-normalized
 */
export const size = (rect: Rect): Vec2 => {
  return vec2.subtract(rect.max, rect.min);
};

/** Gets a vector representing the sum of a rect's components */
export const sum = (rect: Rect): Vec2 => {
  return vec2.add(rect.min, rect.max);
};

/** Swaps and inverts the min and max coords of a rect */
export const invert = (rect: Rect): Rect => {
  return create(vec2.invert(rect.max), vec2.invert(rect.min));
};

/** Returns the component-wise sum of two rects */
export const add = (a: Rect, b: Rect): Rect => {
  return create(vec2.add(a.min, b.min), vec2.add(a.max, b.max));
};

export const translate = (rect: Rect, vec: Vec2): Rect => {
  return create(vec2.add(rect.min, vec), vec2.add(rect.max, vec));
};

/** Returns the component-wise difference of two rects */
export const subtract = (a: Rect, b: Rect): Rect => {
  return create(vec2.subtract(a.min, b.min), vec2.subtract(a.max, b.max));
};

/** Returns the component-wise product of two rects */
export const multiply = (a: Rect, b: Rect): Rect => {
  return create(vec2.multiply(a.min, b.min), vec2.multiply(a.max, b.max));
};

/**
 * Gets a rect representing the intersecting area of rects
 *
 * The input rects are assumed to be normalized. If the rects do not intersect,
 * the result will be non-normalized.
 */
export const intersection = (a: Rect, b: Rect): Rect => {
  return create(vec2.max(a.min, b.min), vec2.min(a.max, b.max));
};
