import { compact } from "lodash";
import { useCallback, useMemo } from "react";

import {
  Document_BreadcrumbsFragment,
  Document_MetaFragment,
  Document_PermissionsFragment,
} from "~/components/Documents/DocumentsAPI.generated";
import useSelection, { UseSelectionResult } from "~/hooks/useSelection";

import { DocumentsQueries } from "./useDocumentsQueries";
import { DocumentsRouting } from "./useDocumentsRouting";
import { DocumentsSearch } from "./useDocumentsSearch";

export type DocumentsTableItem = Document_BreadcrumbsFragment &
  Document_MetaFragment &
  Document_PermissionsFragment;

export interface DocumentsTableData {
  data: readonly DocumentsTableItem[] | null;
  selection: UseSelectionResult<DocumentsTableItem>;
  highlightedId: string | null;
}

/** Manages table data and row selection state */
export const useDocumentsTableData = (
  { view, queryParams }: DocumentsRouting,
  { showSearch }: DocumentsSearch,
  { detail, searchDocs }: DocumentsQueries
): DocumentsTableData => {
  // Extract table data from the active GQL query
  const data = useMemo(() => {
    if (
      showSearch &&
      (view === "single" || view === "columns") &&
      searchDocs.data?.document?.descendants
    ) {
      return compact(searchDocs.data.document.descendants);
    }
    if (view === "single" && detail.data?.document?.children) {
      return compact(detail.data.document.children);
    }
    return null;
  }, [view, showSearch, detail.data, searchDocs.data]);

  // Manage table row selection state
  const selectionOptions = useMemo(() => data ?? [], [data]);
  const selection = useSelection(
    selectionOptions,
    useCallback((d) => d.id, [])
  );

  const highlightedId = queryParams.get("highlightedId");
  // There is an issue with incompatible types "DocumentsTableItem" (composed of a few fragments)
  // and the results of the searchDocs/detail queries (more complex types). Ignore this for now
  // @ts-ignore
  return { data, selection, highlightedId };
};
