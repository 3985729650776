// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ChecklistViewerFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'isSuperUser'>
);

export type ActiveChecklistFragment = (
  { readonly __typename: 'Checklist' }
  & Pick<Types.Checklist, 'id' | 'name'>
);

export type ChecklistItemFragment = (
  { readonly __typename: 'Item' }
  & Pick<Types.Item, 'id' | 'link' | 'projectId' | 'categoryId' | 'commentCount' | 'status' | 'userId'>
);

export type ChecklistCategoryFragment = (
  { readonly __typename: 'Category' }
  & Pick<Types.Category, 'id' | 'parentId' | 'name' | 'rowNumber' | 'isDevelopment' | 'isOperating'>
  & { readonly children?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Category' }
    & Pick<Types.Category, 'id' | 'parentId' | 'name' | 'rowNumber' | 'isDevelopment' | 'isOperating'>
  )>> }
);

export type ChecklistDataFragment = (
  { readonly __typename: 'Checklist' }
  & Pick<Types.Checklist, 'id' | 'name'>
  & { readonly categories?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Category' }
    & ChecklistCategoryFragment
  )>> }
);

export type ChecklistDataQueryVariables = Types.Exact<{
  checklistId: Types.Scalars['ID'];
}>;


export type ChecklistDataQuery = (
  { readonly __typename: 'Query' }
  & { readonly checklist?: Types.Maybe<(
    { readonly __typename: 'Checklist' }
    & ChecklistDataFragment
  )>, readonly checklistItems?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Item' }
    & ChecklistItemFragment
  )>> }
);

export type ActiveChecklistsQueryVariables = Types.Exact<{
  portfolioId: Types.Scalars['ID'];
}>;


export type ActiveChecklistsQuery = (
  { readonly __typename: 'Query' }
  & { readonly activeChecklists?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Checklist' }
    & ActiveChecklistFragment
  )>> }
);

export type ChecklistProjectFragment = (
  { readonly __typename: 'Project' }
  & Pick<Types.Project, 'id' | 'name' | 'capacity' | 'cod' | 'offtakerName'>
  & { readonly location: (
    { readonly __typename: 'ProjectLocation' }
    & Pick<Types.ProjectLocation, 'city' | 'state' | 'zipCode'>
  ) }
);

export type ChecklistUserFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'fullName' | 'companyName' | 'avatarUrl'>
);

export type PortfolioProjectsQueryVariables = Types.Exact<{
  portfolioId: Types.Scalars['ID'];
}>;


export type PortfolioProjectsQuery = (
  { readonly __typename: 'Query' }
  & { readonly portfolio?: Types.Maybe<(
    { readonly __typename: 'Portfolio' }
    & { readonly projects?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'Project' }
      & ChecklistProjectFragment
    )>> }
  )>, readonly portfolioUsers?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'User' }
    & ChecklistUserFragment
  )>> }
);

export const ChecklistViewerFragmentDoc = gql`
    fragment ChecklistViewer on User {
  id
  isSuperUser
}
    `;
export const ActiveChecklistFragmentDoc = gql`
    fragment ActiveChecklist on Checklist {
  id
  name
}
    `;
export const ChecklistItemFragmentDoc = gql`
    fragment ChecklistItem on Item {
  id
  link
  projectId
  categoryId
  commentCount
  status
  userId
}
    `;
export const ChecklistCategoryFragmentDoc = gql`
    fragment ChecklistCategory on Category {
  id
  parentId
  name
  rowNumber
  isDevelopment
  isOperating
  children {
    id
    parentId
    name
    rowNumber
    isDevelopment
    isOperating
  }
}
    `;
export const ChecklistDataFragmentDoc = gql`
    fragment ChecklistData on Checklist {
  id
  name
  categories {
    ...ChecklistCategory
  }
}
    ${ChecklistCategoryFragmentDoc}`;
export const ChecklistProjectFragmentDoc = gql`
    fragment ChecklistProject on Project {
  id
  name
  capacity
  cod
  offtakerName
  location {
    city
    state
    zipCode
  }
}
    `;
export const ChecklistUserFragmentDoc = gql`
    fragment ChecklistUser on User {
  id
  fullName
  companyName
  avatarUrl
}
    `;
export const ChecklistDataDocument = gql`
    query ChecklistData($checklistId: ID!) {
  checklist(id: $checklistId) {
    ...ChecklistData
  }
  checklistItems(checklistId: $checklistId) {
    ...ChecklistItem
  }
}
    ${ChecklistDataFragmentDoc}
${ChecklistItemFragmentDoc}`;

/**
 * __useChecklistDataQuery__
 *
 * To run a query within a React component, call `useChecklistDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useChecklistDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistDataQuery({
 *   variables: {
 *      checklistId: // value for 'checklistId'
 *   },
 * });
 */
export function useChecklistDataQuery(baseOptions: Apollo.QueryHookOptions<ChecklistDataQuery, ChecklistDataQueryVariables>) {
        return Apollo.useQuery<ChecklistDataQuery, ChecklistDataQueryVariables>(ChecklistDataDocument, baseOptions);
      }
export function useChecklistDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChecklistDataQuery, ChecklistDataQueryVariables>) {
          return Apollo.useLazyQuery<ChecklistDataQuery, ChecklistDataQueryVariables>(ChecklistDataDocument, baseOptions);
        }
export type ChecklistDataQueryHookResult = ReturnType<typeof useChecklistDataQuery>;
export type ChecklistDataLazyQueryHookResult = ReturnType<typeof useChecklistDataLazyQuery>;
export type ChecklistDataQueryResult = Apollo.QueryResult<ChecklistDataQuery, ChecklistDataQueryVariables>;
export const ActiveChecklistsDocument = gql`
    query ActiveChecklists($portfolioId: ID!) {
  activeChecklists(portfolioId: $portfolioId) {
    ...ActiveChecklist
  }
}
    ${ActiveChecklistFragmentDoc}`;

/**
 * __useActiveChecklistsQuery__
 *
 * To run a query within a React component, call `useActiveChecklistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveChecklistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveChecklistsQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useActiveChecklistsQuery(baseOptions: Apollo.QueryHookOptions<ActiveChecklistsQuery, ActiveChecklistsQueryVariables>) {
        return Apollo.useQuery<ActiveChecklistsQuery, ActiveChecklistsQueryVariables>(ActiveChecklistsDocument, baseOptions);
      }
export function useActiveChecklistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveChecklistsQuery, ActiveChecklistsQueryVariables>) {
          return Apollo.useLazyQuery<ActiveChecklistsQuery, ActiveChecklistsQueryVariables>(ActiveChecklistsDocument, baseOptions);
        }
export type ActiveChecklistsQueryHookResult = ReturnType<typeof useActiveChecklistsQuery>;
export type ActiveChecklistsLazyQueryHookResult = ReturnType<typeof useActiveChecklistsLazyQuery>;
export type ActiveChecklistsQueryResult = Apollo.QueryResult<ActiveChecklistsQuery, ActiveChecklistsQueryVariables>;
export const PortfolioProjectsDocument = gql`
    query PortfolioProjects($portfolioId: ID!) {
  portfolio(id: $portfolioId) {
    projects {
      ...ChecklistProject
    }
  }
  portfolioUsers(portfolioId: $portfolioId) {
    ...ChecklistUser
  }
}
    ${ChecklistProjectFragmentDoc}
${ChecklistUserFragmentDoc}`;

/**
 * __usePortfolioProjectsQuery__
 *
 * To run a query within a React component, call `usePortfolioProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioProjectsQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function usePortfolioProjectsQuery(baseOptions: Apollo.QueryHookOptions<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>) {
        return Apollo.useQuery<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>(PortfolioProjectsDocument, baseOptions);
      }
export function usePortfolioProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>) {
          return Apollo.useLazyQuery<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>(PortfolioProjectsDocument, baseOptions);
        }
export type PortfolioProjectsQueryHookResult = ReturnType<typeof usePortfolioProjectsQuery>;
export type PortfolioProjectsLazyQueryHookResult = ReturnType<typeof usePortfolioProjectsLazyQuery>;
export type PortfolioProjectsQueryResult = Apollo.QueryResult<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>;