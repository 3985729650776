import { Document } from "~/api/types.generated";

export const sortByName = (
  a: Pick<Document, "fileType" | "name">,
  b: Pick<Document, "fileType" | "name">
) =>
  a.fileType === "Folder" && b.fileType === "File"
    ? -1
    : a.fileType === "File" && b.fileType === "Folder"
    ? 1
    : (a.name ?? "").localeCompare(b.name ?? "");
