import classnames from "classnames";
import { formattedNumber } from "components/utils/portfolio";
import gql from "graphql-tag";
import React, { useContext } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Direction, Ordering, createOrdering, reverse } from "utils/ordering";

import OrderingContext, {
  Field,
} from "~/components/Projects/context/OrderingContext";
import EmptyState from "~/components/QAndA/components/EmptyState";

import { ProjectsTableFragment } from "./ProjectsTable.generated";

export interface HeaderCellProps {
  name: Field;
  ordering: Ordering<Field> | null;
  toggleSorting: (name: Field) => void;
  children?: any;
  style?: React.CSSProperties;
  arrowLeft?: boolean;
}

export const HeaderCell = ({
  name,
  ordering,
  toggleSorting,
  children,
  style = { textAlign: "left" },
  arrowLeft = false,
}: HeaderCellProps) => (
  <div style={style}>
    <button
      className={classnames(
        "btn",
        ordering && ordering.field && "btn-link",
        ordering && ordering.field && "btn-link-hovered"
      )}
      style={{ padding: 0, textAlign: style.textAlign }}
      type="button"
      onClick={() => toggleSorting(name)}
    >
      {!arrowLeft ? children : null}
      {ordering && ordering.field && (
        <i
          className={classnames(
            "icon",
            "icon--sort-up",
            ordering.field.includes(name) && "active",
            ordering.field.includes(name) &&
              ordering.direction === Direction.ASC &&
              "asc",
            ordering.field.includes(name) &&
              ordering.direction === Direction.DESC &&
              "desc"
          )}
        />
      )}
      {arrowLeft ? children : null}
    </button>
  </div>
);

const getBody = (projects: ProjectsTableProps["projects"], url: string) => (
  <>
    {projects &&
      projects.map((project) => (
        <Link
          key={project.id}
          to={`${url}/${project.id}`.replace("//", "/")}
          className="projects-table-row"
        >
          <div className="large">{project.name}</div>
          <div>{project.status}</div>
          <div className="large">
            <span>
              {project.location.city}, {project.location.state}
            </span>
          </div>
          <div style={{ width: "10%", textAlign: "right" }}>
            {formattedNumber(project.capacity)}
          </div>
          <div style={{ width: "10%", textAlign: "right" }}>
            {formattedNumber(project.ppaRemainingAvg, 2)}
          </div>
        </Link>
      ))}
  </>
);

export const PROJECTS_TABLE_FRAGMENT = gql`
  fragment ProjectsTable on ProjectsCollection {
    projects {
      id
      name
      status
      capacity
      ppaRemainingAvg
      location {
        id
        city
        state
      }
    }
  }
`;

export interface ProjectsTableProps {
  projects: ProjectsTableFragment["projects"];
  previousProjects: ProjectsTableFragment["projects"];
  loading: boolean;
}

const ProjectsTable = ({
  projects,
  previousProjects,
  loading,
}: ProjectsTableProps) => {
  const { ordering, setOrdering } = useContext(OrderingContext);
  const { url } = useRouteMatch();

  const toggleSorting = (field: Field) => {
    if (ordering.field === field) {
      setOrdering(reverse(ordering));
    } else {
      setOrdering(createOrdering(field));
    }
  };

  return (
    <>
      <div className="projects-table">
        <div className="projects-table-header">
          <HeaderCell
            name={Field.NAME}
            ordering={ordering}
            toggleSorting={toggleSorting}
          >
            Name
          </HeaderCell>
          <HeaderCell
            name={Field.STATUS}
            ordering={ordering}
            toggleSorting={toggleSorting}
          >
            Status
          </HeaderCell>
          <HeaderCell
            name={Field.LOCATION}
            ordering={ordering}
            toggleSorting={toggleSorting}
          >
            Location
          </HeaderCell>
          <HeaderCell
            style={{ width: "16%", textAlign: "right" }}
            name={Field.CAPACITY}
            ordering={ordering}
            toggleSorting={toggleSorting}
            arrowLeft
          >
            System Size (kW DC)
          </HeaderCell>
          <HeaderCell
            style={{ width: "16%", textAlign: "right" }}
            name={Field.PPA_REMAINING_AVG}
            ordering={ordering}
            toggleSorting={toggleSorting}
            arrowLeft
          >
            PPA Remaining (Years)
          </HeaderCell>
        </div>
        {getBody(projects ?? [], url)}
        {loading && getBody(previousProjects ?? [], url)}
      </div>
      {(!projects || !projects.length) && !loading && (
        <EmptyState text="No projects found." />
      )}
    </>
  );
};

export default ProjectsTable;
