import Markdown from "markdown-to-jsx";
import React from "react";
import styled from "styled-components";

import * as types from "~/api/types.generated";
import { TertiaryButton } from "~/components/common/buttons";
import {
  CalendarIcon,
  OkayIcon,
  PencilIcon,
  TrashIcon,
} from "~/components/common/icons";
import * as panels from "~/components/common/panels";
import * as text from "~/components/common/text";
import {
  black,
  gray100,
  gray300,
  green,
  sushi,
  white,
} from "~/styles/theme/color";

export interface MilestoneProps {
  milestone: types.Milestone;
  isLast: boolean;
  isLoading: boolean;
  canEdit: boolean;
  canRemove: boolean;
  onEdit: () => void;
  onRemove: () => void;
}

export const Milestone = ({
  milestone,
  isLast,
  isLoading,
  canEdit,
  canRemove,
  onEdit,
  onRemove,
}: MilestoneProps) => {
  const isComplete = milestone.status === types.MilestoneStatus.Complete;

  const actions = canEdit && (
    <Actions>
      <ActionButton onClick={onEdit} disabled={isLoading}>
        <PencilIcon />
        <span>Edit</span>
      </ActionButton>
      {canRemove && (
        <ActionButton onClick={onRemove} disabled={isLoading}>
          <TrashIcon />
          <span>Del</span>
        </ActionButton>
      )}
    </Actions>
  );

  return (
    <>
      <Panel isComplete={isComplete}>
        <Head isComplete={isComplete}>
          {isComplete && <OkayIcon />}
          {!isComplete && milestone.dueDate?.trim() && <CalendarIcon />}
          <DueDate>{milestone.dueDate}</DueDate>
          {actions}
        </Head>
        <Content>
          <Title>
            {(milestone.order ?? 0) + 1}. {milestone.title}
          </Title>
          <Markdown>{milestone.body ?? ""}</Markdown>
        </Content>
      </Panel>
      <Spacer isComplete={isComplete} isLast={isLast} />
    </>
  );
};

const Panel = styled(panels.Panel)<{ isComplete: boolean }>`
  overflow: hidden;
  border-color: ${(props) => (props.isComplete ? sushi : gray300)};
`;

const Head = styled(panels.PanelHead)<{ isComplete: boolean }>`
  min-height: 2rem;
  border-radius: 0;
  background-color: ${(props) => (props.isComplete ? green : gray100)};
  border-bottom-color: ${(props) => (props.isComplete ? sushi : gray300)};
  color: ${(props) => (props.isComplete ? white : black)};
`;

const Actions = styled.div`
  margin-left: auto;
`;

const ActionButton = styled(TertiaryButton)`
  border: none;
  min-height: 1.5rem;
  background: transparent;
  text-transform: uppercase;
  display: inline-flex;
  margin-left: 0.5rem;

  &:hover {
    background: rgba(0, 0, 0, 0.375);
  }

  &:disabled {
    border: none;
  }
`;

const Content = styled.div`
  padding: 0.625rem 0.75rem;
`;

const DueDate = styled(text.Text)`
  display: inline;
  margin: 0;
  margin-left: 0.75rem;
  text-transform: uppercase;
`;

const Title = styled(text.Title)`
  font-size: 1.125rem !important;
  margin-bottom: 0.5rem !important;
`;

const Spacer = styled.div<{
  isComplete: boolean;
  isLast: boolean;
}>`
  height: 1.5rem;
  margin-left: 1.25rem;
  border-left-width: ${(props) => (props.isLast ? 0 : 1)}px;
  border-left-color: ${(props) => (props.isComplete ? sushi : gray300)};
  border-left-style: solid;
`;
