// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CheckPasswordFormFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'email'>
);

export type CheckPasswordMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  password: Types.Scalars['String'];
}>;


export type CheckPasswordMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly checkUserPassword?: Types.Maybe<(
    { readonly __typename: 'User' }
    & CheckPasswordFormFragment
  )> }
);

export const CheckPasswordFormFragmentDoc = gql`
    fragment CheckPasswordForm on User {
  id
  email
}
    `;
export const CheckPasswordDocument = gql`
    mutation CheckPassword($id: ID!, $password: String!) {
  checkUserPassword(id: $id, password: $password) {
    ...CheckPasswordForm
  }
}
    ${CheckPasswordFormFragmentDoc}`;
export type CheckPasswordMutationFn = Apollo.MutationFunction<CheckPasswordMutation, CheckPasswordMutationVariables>;

/**
 * __useCheckPasswordMutation__
 *
 * To run a mutation, you first call `useCheckPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPasswordMutation, { data, loading, error }] = useCheckPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCheckPasswordMutation(baseOptions?: Apollo.MutationHookOptions<CheckPasswordMutation, CheckPasswordMutationVariables>) {
        return Apollo.useMutation<CheckPasswordMutation, CheckPasswordMutationVariables>(CheckPasswordDocument, baseOptions);
      }
export type CheckPasswordMutationHookResult = ReturnType<typeof useCheckPasswordMutation>;
export type CheckPasswordMutationResult = Apollo.MutationResult<CheckPasswordMutation>;
export type CheckPasswordMutationOptions = Apollo.BaseMutationOptions<CheckPasswordMutation, CheckPasswordMutationVariables>;