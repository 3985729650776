import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import { DocumentsRouting } from "~/components/Documents/useDocumentsRouting";

export interface DocumentsHistoryFilters {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  pageSize: number;
  ancestorIds: any[];
  activityTypes: any[];
  fileTypes: any[];
  userGroupIds: any[];
  setAncestorIds: (selected: number[]) => void;
  setActivityTypes: (selected: number[]) => void;
  setFileTypes: (selected: string[]) => void;
  setUserGroupIds: (selected: number[]) => void;
}

/** Extracts history filters state from the URL / routing state */
export const useDocumentsHistoryFilters = ({
  history,
  queryParams,
}: DocumentsRouting): DocumentsHistoryFilters => {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(100);
  const ancestorIds = useMemo(
    () => queryParams.getAll("ancestorIds"),
    [queryParams]
  );
  const activityTypes = useMemo(
    () => queryParams.getAll("activityTypes"),
    [queryParams]
  );
  const fileTypes = useMemo(
    () => queryParams.getAll("fileTypes"),
    [queryParams]
  );
  const userGroupIds = useMemo(
    () => queryParams.getAll("userGroupIds"),
    [queryParams]
  );

  const setAncestorIds = useCallback(
    (selected: any[]) => {
      const queryParams = new URLSearchParams(history.location.search);
      queryParams.delete("ancestorIds");
      selected.forEach((value) => queryParams.append("ancestorIds", value));
      history.push({ search: `?${queryParams.toString()}` });
    },
    [history]
  );

  const setActivityTypes = useCallback(
    (selected: any[]) => {
      const queryParams = new URLSearchParams(history.location.search);
      queryParams.delete("activityTypes");
      selected.forEach((value) => {
        queryParams.append("activityTypes", value);
      });
      history.push({ search: `?${queryParams.toString()}` });
    },
    [history]
  );

  const setFileTypes = useCallback(
    (selected: any[]) => {
      const queryParams = new URLSearchParams(history.location.search);
      queryParams.delete("fileTypes");
      selected.forEach((value) => {
        queryParams.append("fileTypes", value);
      });
      history.push({ search: `?${queryParams.toString()}` });
    },
    [history]
  );

  const setUserGroupIds = useCallback(
    (selected: any[]) => {
      const queryParams = new URLSearchParams(history.location.search);
      queryParams.delete("userGroupIds");
      selected.forEach((value) => {
        queryParams.append("userGroupIds", value);
      });
      history.push({ search: `?${queryParams.toString()}` });
    },
    [history]
  );

  return {
    page,
    setPage,
    pageSize,
    ancestorIds,
    setAncestorIds,
    activityTypes,
    fileTypes,
    setActivityTypes,
    setFileTypes,
    userGroupIds,
    setUserGroupIds,
  };
};
