import { AnimatePresence, motion, useAnimation } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import usePortal from "react-useportal";
import styled, { css } from "styled-components";

import { PrimaryButton } from "~/components/common/buttons";
import { CloseIcon, CollapseIcon, ExpandIcon } from "~/components/common/icons";
import { Title } from "~/components/common/text";
import { useSimpleStore } from "~/hooks/useSimpleStore";
import { indigo, white } from "~/styles/theme/color";
import { borderRadius, boxShadow } from "~/styles/theme/common";
import * as rect from "~/utils/rect";
import * as vec2 from "~/utils/vec2";

import { Anchor, anchors } from "./anchor";
import { getCursor, getWindowStyle, windowReducer } from "./windowReducer";

export interface WindowProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  initialPosition?: rect.Rect;
  bounds?: rect.Rect;
  constraints?: rect.Rect;
  canClose?: boolean;
  onClose?: () => void;
  minimize?: boolean;
  overflowYScroll?: boolean;
}

const MIN = Number.MIN_SAFE_INTEGER / 2;
const MAX = Number.MAX_SAFE_INTEGER / 2;
const MIN_VEC = vec2.create(MIN, MIN);
const MAX_VEC = vec2.create(MAX, MAX);

const DEFAULT_SIZE = vec2.create(1200, 480);
const DEFAULT_POSITION = rect.create(vec2.zero, DEFAULT_SIZE);
const DEFAULT_BOUNDS = rect.create(MIN_VEC, MAX_VEC);
const DEFAULT_CONSTRAINTS = rect.create(vec2.zero, MAX_VEC);

export const Window = (props: WindowProps) => {
  const {
    title,
    children,
    initialPosition: position = DEFAULT_POSITION,
    bounds = DEFAULT_BOUNDS,
    constraints = DEFAULT_CONSTRAINTS,
    canClose = false,
    onClose = () => {},
    minimize = true,
    overflowYScroll = false,
  } = props;

  // Create a portal element to render the window at the end of the document body
  const { Portal } = usePortal();

  // A reducer store to manage the window state
  const store = useSimpleStore(windowReducer, {
    animate: false,
    minimized: !!minimize,
    position,
    lastSize: rect.size(position),
    bounds,
    constraints,
    operation: "IDLE",
  });
  const { dispatch } = store;

  const animation = useAnimation();
  const [minimized, setMinimized] = useState(store.getState().minimized);

  // Synchronize the state with the window style
  useEffect(() => {
    return store.subscribe((state) => {
      document.body.style.cursor = getCursor(state);
      animation[state.animate ? "start" : "set"](getWindowStyle(state));
      if (state.animate) setMinimized(state.minimized);
    });
  }, [store, animation]);

  // Reconfigure the state when the provided bounds or constraints change
  useEffect(
    () => store.dispatch({ type: "RECONFIGURE", bounds, constraints }),
    [store, bounds, constraints]
  );

  // The window's title bar
  const titleBar = (
    <TitleBar
      onPanStart={(_, { point }) => dispatch({ type: "DRAG_START", point })}
      onPan={(_, { point }) => dispatch({ type: "DRAG_MOVE", point })}
      onPanEnd={() => dispatch({ type: "DRAG_END" })}
      onMouseDown={(event) => event.preventDefault()}
    >
      <WindowTitle>{title}</WindowTitle>
      <WindowButton
        onMouseDown={(event) => event.stopPropagation()}
        onClick={() => dispatch({ type: minimized ? "MAXIMIZE" : "MINIMIZE" })}
      >
        {minimized ? <ExpandIcon /> : <CollapseIcon />}
      </WindowButton>
      {canClose && (
        <WindowButton onClick={onClose}>
          <CloseIcon />
        </WindowButton>
      )}
    </TitleBar>
  );

  // The window's eight resize anchors (sides & corners)
  const resizeAnchors = useMemo(
    () =>
      anchors.map((anchor, i) => (
        <AnchorHandle
          key={i}
          {...anchor}
          onPanStart={(_, { point }) =>
            dispatch({ type: "RESIZE_START", point, anchor })
          }
          onPan={(_, { point }) => dispatch({ type: "RESIZE_MOVE", point })}
          onPanEnd={() => dispatch({ type: "RESIZE_END" })}
          onMouseDown={(event) => event.preventDefault()}
        />
      )),
    [dispatch]
  );

  return (
    <Portal>
      <Root
        animate={animation}
        transition={{ bounce: 0, duration: 0.2 }}
        initial={false}
      >
        <Box>
          {titleBar}
          <AnimatePresence>
            {!minimized && (
              <Content
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                overflowYScroll={overflowYScroll}
              >
                {children}
              </Content>
            )}
          </AnimatePresence>
        </Box>
        {!minimized && resizeAnchors}
      </Root>
    </Portal>
  );
};

const Root = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;
`;

const Box = styled.div`
  position: relative;
  border-radius: ${borderRadius};
  box-shadow: ${boxShadow};
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const TitleBar = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.375rem;
  width: 100%;
  flex: 0 0 3.375rem;
  padding: 0 1rem 0 1.5rem;
  background: ${indigo};
  cursor: move;
  touch-action: none;
  user-select: none;
  -webkit-touch-callout: none;
`;

const WindowTitle = styled(Title)`
  color: ${white};
  font-size: 1.125rem;
  font-weight: 400;
  margin-right: auto;
  display: flex;
  align-items: center;
`;

const WindowButton = styled(PrimaryButton)`
  border: none;
  background: transparent;
  outline: none;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  margin-left: 0.25rem;

  svg:first-child:last-child {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    border: none;
    background: rgba(255, 255, 255, 0.25);
  }

  &:focus {
    box-shadow: none;
  }
`;

const Content = styled(motion.div)<{ overflowYScroll?: boolean }>`
  background: ${white};
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  ${(props) =>
    props.overflowYScroll &&
    css`
      overflow-y: scroll;
    `};
  ${(props) =>
    !props.overflowYScroll &&
    css`
      overflow: hidden;
    `};
`;

const AnchorHandle = styled(motion.div)<Anchor>(
  ({ sides, cursor }) => css`
    position: absolute;
    cursor: ${cursor};
    touch-action: none;
    user-select: none;
    -webkit-touch-callout: none;
    ${rect.sum(sides).x ? { width: "8px" } : { left: "8px", right: "8px" }};
    ${rect.sum(sides).y ? { height: "8px" } : { top: "8px", bottom: "8px" }};
    ${sides.min.x && { left: "-4px" }};
    ${sides.max.x && { right: "-4px" }};
    ${sides.min.y && { top: "-4px" }};
    ${sides.max.y && { bottom: "-4px" }};
  `
);
