import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import { useDebounce } from "use-debounce";

export interface UserGroupMembersSearch {
  searchTerm: string;
  debouncedSearchTerm: string;
  searchPending: boolean;
  showSearch: boolean;
  highlight: string | null;
  setSearchTerm(searchTerm: string): void;
  clearSearch(): void;
  queryParams: URLSearchParams;
}

/** Extracts search-related state from the URL / routing state */
export const useUserGroupMembersSearch = (): UserGroupMembersSearch => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const searchTerm = queryParams.get("q") ?? "";
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300, { leading: true });
  const searchPending = searchTerm !== debouncedSearchTerm;
  const showSearch = debouncedSearchTerm.length >= 3;
  const highlight = showSearch ? debouncedSearchTerm : null;

  /** Updates the search term state via the "q" query parameter */
  const setSearchTerm = useCallback(
    (searchTerm: string) => {
      const queryParams = new URLSearchParams(history.location.search);
      searchTerm ? queryParams.set("q", searchTerm) : queryParams.delete("q");
      history.push({ search: `?${queryParams.toString()}` });
    },
    [history]
  );

  /** Resets the search state */
  const clearSearch = useCallback(() => {
    setSearchTerm("");
  }, [setSearchTerm]);

  return {
    searchTerm,
    debouncedSearchTerm,
    searchPending,
    showSearch,
    highlight,
    setSearchTerm,
    clearSearch,
    queryParams,
  };
};
