import { gql } from "@apollo/client";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { PortfolioObj } from "~/components/QAndA/context/PortfolioFilterContext";
import {
  QuestionPortfolioFragment,
  useAddPortfolioMutation,
  useAddPortfoliosMutation,
  usePortfoliosLazyQuery,
  useRemovePortfolioMutation,
  useRemovePortfoliosMutation,
} from "~/components/QAndA/context/QuestionPortfoliosContext.generated";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";

import { useSaving } from "./QAndAContext";
import { QuestionContextValue } from "./QuestionContext";

export const QUESTION_PORTFOLIO_FRAGMENT = gql`
  fragment QuestionPortfolio on Portfolio {
    id
    name
  }
`;

export const PORTFOLIOS_QUERY = gql`
  query Portfolios($holdingCompanyId: ID!) {
    portfolios(holdingCompanyId: $holdingCompanyId) {
      ...QuestionPortfolio
    }
  }

  ${QUESTION_PORTFOLIO_FRAGMENT}
`;

export const ADD_PORTFOLIO_MUTATION_FRAGMENT = gql`
  mutation AddPortfolio($questionId: ID!, $portfolioId: ID!) {
    addQuestionPortfolio(questionId: $questionId, portfolioId: $portfolioId) {
      id
      portfolios {
        ...QuestionPortfolio
      }
      modifiedAt
    }
  }
  ${QUESTION_PORTFOLIO_FRAGMENT}
`;

export const ADD_PORTFOLIOS_MUTATION_FRAGMENT = gql`
  mutation AddPortfolios($questionId: ID!) {
    addAllQuestionPortfolios(questionId: $questionId) {
      id
      portfolios {
        ...QuestionPortfolio
      }
      modifiedAt
    }
  }
  ${QUESTION_PORTFOLIO_FRAGMENT}
`;

export const REMOVE_PORTFOLIO_MUTATION_FRAGMENT = gql`
  mutation RemovePortfolio($questionId: ID!, $portfolioId: ID!) {
    removeQuestionPortfolio(
      questionId: $questionId
      portfolioId: $portfolioId
    ) {
      id
      portfolios {
        ...QuestionPortfolio
      }
      modifiedAt
    }
  }
  ${QUESTION_PORTFOLIO_FRAGMENT}
`;

export const REMOVE_PORTFOLIOS_MUTATION_FRAGMENT = gql`
  mutation RemovePortfolios($questionId: ID!) {
    removeAllQuestionPortfolios(questionId: $questionId) {
      id
      portfolios {
        ...QuestionPortfolio
      }
      modifiedAt
    }
  }
  ${QUESTION_PORTFOLIO_FRAGMENT}
`;

export interface QuestionPortfoliosContextValue {
  availablePortfolios: readonly QuestionPortfolioFragment[];
  hasWritePermission: boolean;
  addPortfolio: (
    question: QuestionContextValue,
    portfolio: PortfolioObj
  ) => void;
  addAllPortfolios: (question: QuestionContextValue) => void;
  removePortfolio: (
    question: QuestionContextValue,
    portfolio: PortfolioObj
  ) => void;
  removeAllPortfolios: (question: QuestionContextValue) => void;
}

const QuestionPortfoliosContext = createContext<QuestionPortfoliosContextValue>(
  null as any
);

export const useQuestionPortfolios = () =>
  useContext(QuestionPortfoliosContext);

export const QuestionPortfoliosProvider: FC<{
  subject: QAndASubjectFragment;
  canEditQuestions: boolean;
}> = ({ subject, canEditQuestions, children }) => {
  const [loadPortfolios, portfoliosQuery] = usePortfoliosLazyQuery();
  useEffect(() => {
    if (subject.__typename === "HoldingCompany") {
      loadPortfolios({ variables: { holdingCompanyId: subject.id } });
    }
  }, [subject.__typename, subject.id, loadPortfolios]);

  const [addPortfolio, { loading: loadingAdd }] = useAddPortfolioMutation();
  const [addAllPortfolios, { loading: loadingAddAll }] =
    useAddPortfoliosMutation();
  const [removePortfolio, { loading: loadingRemove }] =
    useRemovePortfolioMutation();
  const [removeAllPortfolios, { loading: loadingRemoveAll }] =
    useRemovePortfoliosMutation();

  useSaving(loadingAdd);
  useSaving(loadingAddAll);
  useSaving(loadingRemove);
  useSaving(loadingRemoveAll);

  const hasWritePermission = canEditQuestions;

  const context: QuestionPortfoliosContextValue = useMemo(
    () => ({
      availablePortfolios: portfoliosQuery.data?.portfolios ?? [],

      hasWritePermission,

      addPortfolio: (question, portfolio) => {
        addPortfolio({
          variables: { questionId: question.id, portfolioId: portfolio.id },
          optimisticResponse: {
            __typename: "Mutation",
            addQuestionPortfolio: {
              ...question,
              portfolios: [...question.portfolios, portfolio],
            },
          },
        });
      },

      addAllPortfolios: (question) => {
        addAllPortfolios({
          variables: { questionId: question.id },
          optimisticResponse: {
            __typename: "Mutation",
            addAllQuestionPortfolios: {
              ...question,
              portfolios: portfoliosQuery.data?.portfolios ?? [],
            },
          },
        });
      },

      removePortfolio: (question, portfolio) => {
        removePortfolio({
          variables: { questionId: question.id, portfolioId: portfolio.id },
          optimisticResponse: {
            __typename: "Mutation",
            removeQuestionPortfolio: {
              ...question,
              portfolios: question.portfolios.filter(
                (p) => p.id !== portfolio.id
              ),
            },
          },
        });
      },

      removeAllPortfolios: (question) => {
        removeAllPortfolios({
          variables: { questionId: question.id },
          optimisticResponse: {
            __typename: "Mutation",
            removeAllQuestionPortfolios: { ...question, portfolios: [] },
          },
        });
      },
    }),
    [
      portfoliosQuery.data,
      hasWritePermission,
      addPortfolio,
      addAllPortfolios,
      removePortfolio,
      removeAllPortfolios,
    ]
  );

  return (
    <QuestionPortfoliosContext.Provider value={context} children={children} />
  );
};

export default QuestionPortfoliosContext;
