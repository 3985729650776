import { gql } from "@apollo/client/core";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers as FormikActions,
} from "formik";
import { trim } from "lodash";
import React, { useCallback } from "react";
import { gettext } from "utils/text";
import * as yup from "yup";

import {
  UpdatePhoneNumberFormFragment,
  useUpdatePhoneNumberMutation,
} from "~/components/forms/UpdatePhoneNumberConfirmAuth.generated";

export const UPDATE_PHONE_NUMBER_FORM_FRAGMENT = gql`
  fragment UpdatePhoneNumberForm on User {
    id
    phoneNumber
  }
`;

export const UPDATE_PHONE_NUMBER_FORM_MUTATION_FRAGMENT = gql`
  mutation UpdatePhoneNumber(
    $id: ID!
    $phoneNumber: String!
    $countryCode: String!
    $authCode: String!
  ) {
    updateUserPhoneNumber(
      id: $id
      phoneNumber: $phoneNumber
      countryCode: $countryCode
      authCode: $authCode
    ) {
      ...UpdatePhoneNumberForm
    }
  }

  ${UPDATE_PHONE_NUMBER_FORM_FRAGMENT}
`;

const validationSchema = yup.object().shape({
  authCodeConfirm: yup
    .mixed()
    .required(gettext("This field is required"))
    .test(
      "only digits",
      gettext("Authentication code must contain only digits"),
      (value) => /^\d+$/.test(value)
    )
    .test(
      "auth code length",
      gettext("Authentication code must contain 6 digits"),
      (value) => value && value.length === 6
    ),
});

interface FormFields {
  authCodeConfirm: string;
  newPhoneNumber: string;
  newCountryCode: string;
}

export interface UpdatePhoneNumberConfirmAuthProps {
  user: UpdatePhoneNumberFormFragment;
  closeModal: () => void;
  togglePhoneUpdateVisible: () => void;
  handleResendCode: () => void;
  handlePhoneNumberUpdate: (message: string) => void;
  newPhoneNumber: string;
  newCountryCode: string;
  isResending: boolean;
}

export const UpdatePhoneNumberConfirmAuth = ({
  user,
  newPhoneNumber,
  newCountryCode,
  togglePhoneUpdateVisible,
  closeModal,
  handleResendCode,
  handlePhoneNumberUpdate,
  isResending,
}: UpdatePhoneNumberConfirmAuthProps) => {
  const [update, { loading: isSubmitting }] = useUpdatePhoneNumberMutation({
    onCompleted: () => {
      handlePhoneNumberUpdate("Phone number was updated");
    },
  });
  const handleSubmit = useCallback(
    async (values: FormFields, actions: FormikActions<FormFields>) => {
      try {
        await update({
          variables: {
            id: user.id,
            phoneNumber: values.newPhoneNumber,
            countryCode: values.newCountryCode,
            authCode: values.authCodeConfirm,
          },
        });
        togglePhoneUpdateVisible();
        closeModal();
      } catch (error) {
        actions.setFieldError("authCodeConfirm", trim(error.message, "'"));
      } finally {
        actions.setSubmitting(false);
      }
    },
    [update, closeModal, togglePhoneUpdateVisible, user.id]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        authCodeConfirm: "",
        newPhoneNumber: newPhoneNumber,
        newCountryCode: newCountryCode,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <p>
          {gettext("Please enter verification code sent to your phone number")}
        </p>
        <div className="form-group">
          <Field
            className="input-group form-control"
            type="password"
            id="authCodeConfirm"
            name="authCodeConfirm"
            placeholder="6-digit code"
          />
          <p className="text-danger">
            <ErrorMessage name="authCodeConfirm" />
          </p>
        </div>
        <div className="form-group mt-4 p-2">
          <button
            type="button"
            className="btn btn-outline-primary btn-lg mr-4"
            disabled={isSubmitting || isResending}
            onClick={closeModal}
          >
            {gettext("Cancel")}
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-lg"
            disabled={isSubmitting || isResending}
          >
            {gettext("Next")}
          </button>
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn btn-link"
            onClick={handleResendCode}
            disabled={isResending}
          >
            {gettext("Re-send the code")}
          </button>
        </div>
      </Form>
    </Formik>
  );
};
