import { WebMercatorViewport } from "react-map-gl";

import { MINI_MAP_HEIGHT, MINI_MAP_WIDTH } from "~/components/maps/constants";

// Bounds are [ne.lng, ne.lat, sw.lng, sw.lat]
export const USA_BOUNDS = [-127.869207, 50.894008, -127.869207, 50.894008];

export const DEFAULT_VIEW_PORT = {
  latitude: 58.26561,
  longitude: -33.50349,
  zoom: 1.4299981981854746,
};

export const calculateMiniMapViewPort = (
  neLng: number,
  neLat: number,
  swLng: number,
  swLat: number
) => {
  /* eslint-disable-next-line no-underscore-dangle */
  const bounds: [[number, number], [number, number]] = [
    [neLng, neLat],
    [swLng, swLat],
  ];
  const miniVp = new WebMercatorViewport({
    width: MINI_MAP_WIDTH,
    height: MINI_MAP_HEIGHT,
  });
  const miniViewPort = miniVp.fitBounds(bounds);
  return {
    ...miniViewPort,
    zoom: miniViewPort.zoom > 0 ? miniViewPort.zoom : 0,
  };
};
