import { createContext, useEffect, useMemo } from "react";

import { QuestionCategory, QuestionTopic } from "~/api/types.generated";
import useSelection, { UseSelectionResult } from "~/hooks/useSelection";

import {
  useCategoriesLazyQuery,
  useTopicsLazyQuery,
} from "../api/queries.generated";
import { ChildQuestion, ParentQuestion } from "./QuestionContext";

export type CategoryObj = Pick<QuestionCategory, "__typename" | "id" | "name">;
export type TopicObj = Pick<QuestionTopic, "__typename" | "id" | "name">;

export interface CategoryFilterContextValue {
  availableCategories: readonly CategoryObj[];
  availableTopics: readonly TopicObj[];
  categorySelection: UseSelectionResult<CategoryObj>;
  topicSelection: UseSelectionResult<TopicObj>;
  filterQuestion: (question: ParentQuestion | ChildQuestion) => boolean;
}

export const useCategoryFilter = (
  logId: string | null
): CategoryFilterContextValue => {
  const [loadCategories, { data: categoriesQuery }] = useCategoriesLazyQuery();
  const [loadTopics, { data: topicsQuery }] = useTopicsLazyQuery();
  useEffect(() => {
    if (logId) {
      loadCategories({ variables: { logId } });
      loadTopics({ variables: { logId } });
    }
  }, [logId, loadCategories, loadTopics]);

  const availableCategories = useMemo(
    () => categoriesQuery?.questionCategories ?? [],
    [categoriesQuery]
  );
  const availableTopics = useMemo(
    () => topicsQuery?.questionTopics ?? [],
    [topicsQuery]
  );

  const categorySelection = useSelection(
    availableCategories,
    (category) => category.id
  );
  const topicSelection = useSelection(availableTopics, (topic) => topic.id);

  return useMemo(
    () => ({
      availableCategories,
      availableTopics,
      categorySelection,
      topicSelection,
      filterQuestion: (question) =>
        (!categorySelection.anySelected ||
          categorySelection.includesAny(question.categories)) &&
        (!topicSelection.anySelected ||
          topicSelection.includesAny(question.topics)),
    }),
    [availableCategories, availableTopics, categorySelection, topicSelection]
  );
};

const CategoryFilterContext = createContext<CategoryFilterContextValue>(
  null as any
);

export default CategoryFilterContext;
