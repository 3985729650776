// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { HoldingCompanyPortfoliosFragment } from './Portfolios.generated';
import { FundEntityOverview_HoldingCompany_Fragment, FundEntityOverview_Portfolio_Fragment } from '../Overview/OverviewPage.generated';
import { QAndASubject_HoldingCompany_Fragment, QAndASubject_Portfolio_Fragment } from '../QAndA/QAndA.generated';
import { DealRoomCard_HoldingCompany_Fragment, DealRoomCard_Portfolio_Fragment } from '../DealRoom/DealRoomCard.generated';
import { gql } from '@apollo/client';
import { HoldingCompanyPortfoliosFragmentDoc } from './Portfolios.generated';
import { FundEntityOverviewFragmentDoc } from '../Overview/OverviewPage.generated';
import { QAndASubjectFragmentDoc } from '../QAndA/QAndA.generated';
import { DealRoomCardFragmentDoc } from '../DealRoom/DealRoomCard.generated';
import * as Apollo from '@apollo/client';
export type HoldingCompanyBodyFragment = (
  { readonly __typename: 'HoldingCompany' }
  & Pick<Types.HoldingCompany, 'id'>
  & { readonly org: (
    { readonly __typename: 'Organization' }
    & Pick<Types.Organization, 'id'>
  ), readonly documentsTree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & Pick<Types.DocumentTree, 'id'>
  )>, readonly reportsTree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & Pick<Types.DocumentTree, 'id'>
  )> }
  & HoldingCompanyPortfoliosFragment
  & FundEntityOverview_HoldingCompany_Fragment
  & QAndASubject_HoldingCompany_Fragment
);

export type ViewHoldingCompanyMutationVariables = Types.Exact<{
  holdingCompanyId: Types.Scalars['ID'];
}>;


export type ViewHoldingCompanyMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createRecentlyVisitedHoldingCompany?: Types.Maybe<(
    { readonly __typename: 'HoldingCompany' }
    & DealRoomCard_HoldingCompany_Fragment
  ) | (
    { readonly __typename: 'Portfolio' }
    & DealRoomCard_Portfolio_Fragment
  )> }
);

export const HoldingCompanyBodyFragmentDoc = gql`
    fragment HoldingCompanyBody on HoldingCompany {
  id
  org {
    id
  }
  documentsTree {
    id
  }
  reportsTree {
    id
  }
  ...HoldingCompanyPortfolios
  ...FundEntityOverview
  ...QAndASubject
}
    ${HoldingCompanyPortfoliosFragmentDoc}
${FundEntityOverviewFragmentDoc}
${QAndASubjectFragmentDoc}`;
export const ViewHoldingCompanyDocument = gql`
    mutation ViewHoldingCompany($holdingCompanyId: ID!) {
  createRecentlyVisitedHoldingCompany(id: $holdingCompanyId) {
    ...DealRoomCard
  }
}
    ${DealRoomCardFragmentDoc}`;
export type ViewHoldingCompanyMutationFn = Apollo.MutationFunction<ViewHoldingCompanyMutation, ViewHoldingCompanyMutationVariables>;

/**
 * __useViewHoldingCompanyMutation__
 *
 * To run a mutation, you first call `useViewHoldingCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewHoldingCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewHoldingCompanyMutation, { data, loading, error }] = useViewHoldingCompanyMutation({
 *   variables: {
 *      holdingCompanyId: // value for 'holdingCompanyId'
 *   },
 * });
 */
export function useViewHoldingCompanyMutation(baseOptions?: Apollo.MutationHookOptions<ViewHoldingCompanyMutation, ViewHoldingCompanyMutationVariables>) {
        return Apollo.useMutation<ViewHoldingCompanyMutation, ViewHoldingCompanyMutationVariables>(ViewHoldingCompanyDocument, baseOptions);
      }
export type ViewHoldingCompanyMutationHookResult = ReturnType<typeof useViewHoldingCompanyMutation>;
export type ViewHoldingCompanyMutationResult = Apollo.MutationResult<ViewHoldingCompanyMutation>;
export type ViewHoldingCompanyMutationOptions = Apollo.BaseMutationOptions<ViewHoldingCompanyMutation, ViewHoldingCompanyMutationVariables>;