import CommentBox from "components/generic/CommentBox";
import { CommentIcon } from "components/icons";
import * as PropTypes from "prop-types";
import React, { useEffect } from "react";
import { gettext } from "utils/text";

export const CommentsList = ({ selectedItems, comments, sentMultiple }) => {
  let commentContainerRef = null;

  useEffect(() => {
    if (commentContainerRef)
      commentContainerRef.scrollTop = commentContainerRef.scrollHeight;
  }, [comments, commentContainerRef]);

  const renderChecklistComments = () => {
    if (selectedItems.length === 0) {
      return null;
    }

    let commentsToRender = comments[selectedItems[0].id] || [];

    if (selectedItems.length > 1 && commentsToRender.length > 0) {
      // it doesn't matter which item choose (latest should be the same for all)
      commentsToRender = commentsToRender.slice(-1);
    }

    return commentsToRender.map((commentObj) => (
      <CommentBox key={commentObj.id} commentObj={commentObj} />
    ));
  };

  return (
    (sentMultiple || selectedItems.length === 1) && (
      <div className="form-group d-block pt-1">
        <span className="checklist-modal__label">
          {gettext("Comments")}
          <CommentIcon className="comments__icon" />
        </span>
        <div
          className="comments__container"
          ref={(elem) => {
            commentContainerRef = elem;
          }}
        >
          {renderChecklistComments()}
        </div>
      </div>
    )
  );
};

CommentsList.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  comments: PropTypes.object.isRequired,
  sentMultiple: PropTypes.bool.isRequired,
};

export default CommentsList;
