import { History, Location } from "history";
import { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router";

export type View = "single" | "columns" | "history" | "upload";

export interface DocumentsRouting {
  history: History<unknown>;
  location: Location<unknown>;
  rootId: string;
  documentId: string;
  isRoot: boolean;
  queryParams: URLSearchParams;
  view: View;
}

/**
 * Extracts state for documents that is stored in the URL and query params.
 *
 * | URL Pattern                        | Description                      |
 * | ---------------------------------  | -------------------------------- |
 * | /                                  | Root list view                   |
 * | /?view=columns                     | Root columns view                |
 * | /?view=history                     | History                          |
 * | /:documentId                       | Folder list view / file preview  |
 * | /:documentId?view=columns          | Folder/file columns view         |
 * | /:documentId?view=upload           | Upload to folder                 |
 * | /:documentId?q=:searchterm         | Search folder descendants        |
 * | /:documentId?q=:searchterm&qs=all  | Search all files                 |
 */
export const useDocumentsRouting = (treeId: string): DocumentsRouting => {
  const history = useHistory();
  const location = useLocation();
  const rootId = `tree:${treeId}`;
  const { documentId = rootId } = useParams<{ documentId?: string }>();
  const isRoot = documentId === rootId;
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const view = getView(queryParams);

  return { history, location, rootId, documentId, isRoot, queryParams, view };
};

/** Gets the current documents view mode from the URL */
const getView = (queryParams: URLSearchParams): View => {
  const view = queryParams.get("view");
  return view === "columns" || view === "history" || view === "upload"
    ? view
    : "single";
};
