import { useEffect, useRef } from "react";

/**
 * A hook to get the previous props or state.
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * It’s possible that in the future React will provide a
 * usePrevious Hook out of the box since it’s a relatively common use case.
 */
const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T | undefined>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export default usePrevious;
