import React, { ReactNode, useContext } from "react";
import styled, { css } from "styled-components";

import { bgColor, fgColor } from "~/styles/mixins";
import { GetPropsWithoutRef } from "~/utils/types";

import DropdownContext from "./DropdownContext";

export type DropdownHeaderVariant = "compact" | "large";

export interface DropdownHeaderProps
  extends Omit<GetPropsWithoutRef<"div">, "title"> {
  title?: ReactNode;
  variant?: DropdownHeaderVariant;
}

const DropdownHeader = ({
  children,
  title,
  variant = "compact",
  ...rest
}: DropdownHeaderProps) => {
  const dropdown = useContext(DropdownContext);

  return (
    <div {...rest}>
      <TitleRow>
        <Title variant={variant}>{title}</Title>{" "}
        <CloseButton onClick={() => dropdown?.close()} />
      </TitleRow>
      {children}
    </div>
  );
};

const CloseIcon = () => (
  <svg viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor">
      <path
        id="a"
        d="M11.535 8l3.098-3.1a1.252 1.252 0 000-1.77l-1.769-1.77a1.268 1.268 0 00-1.769 0l-3.099 3.1-3.089-3.1a1.268 1.268 0 00-1.769 0L1.36 3.13a1.27 1.27 0 000 1.77L4.458 8l-3.089 3.1a1.252 1.252 0 000 1.77l1.77 1.77c.478.48 1.28.48 1.768 0l3.09-3.1 3.098 3.1c.489.48 1.28.48 1.77 0l1.768-1.77a1.252 1.252 0 000-1.77L11.535 8z"
      />
    </g>
  </svg>
);

const CloseButton = styled.button.attrs({
  children: <CloseIcon />,
})`
  cursor: pointer;
  background: none;
  border: none;
  flex: 0 0 0.75rem;
  width: 0.75rem;
  height: 0.75rem;
  margin: 0.375rem;
  padding: 0;

  ${fgColor.blue()};
  &:hover {
    ${fgColor.darkBlue()};
  }
  &:focus {
    outline: none;
  }

  & > svg {
    display: block;
  }
`;

const TitleRow = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h4<{ variant: DropdownHeaderVariant }>`
  ${fgColor.gray800()};
  margin-bottom: 0;

  ${(props) =>
    props.variant === "large"
      ? css`
          font-size: 1.25rem;
          line-height: 1.25rem;
        `
      : css`
          font-size: 0.75rem;
          line-height: 0.75rem;
          margin: 0 1.5rem 0 0.5rem;
          padding: 0.375rem 0;
        `};
`;

export default styled(DropdownHeader)`
  ${(props) =>
    props.variant === "large"
      ? css`
          ${bgColor.gray100()};
          padding: 0.75rem;
        `
      : css`
          ${bgColor.lightBlue()};
          height: 1.5rem;
        `};
`;
