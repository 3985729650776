import { Grid, MenuItem, TextField } from "@material-ui/core";
import Dialog from "components/generic/Dialog";
import {
  FieldArray,
  Form,
  Formik,
  FormikHelpers as FormikActions,
  getIn,
} from "formik";
import gql from "graphql-tag";
import { map, trim } from "lodash";
import React, { EventHandler, useCallback, useState } from "react";
import styled, { css } from "styled-components";
import * as yup from "yup";

import { PortfolioInput } from "~/api/types.generated";
import GroupAccessTable from "~/components/Admin/Group/GroupAccessTable";
import { GROUP_ROW_FRAGMENT } from "~/components/Admin/Group/GroupsTable";
import { GroupRowFragment } from "~/components/Admin/Group/GroupsTable.generated";
import { OrgRoleFragment } from "~/components/Admin/Group/NewGroupDialog.generated";
import { useUpdateGroupMutation } from "~/components/Admin/Group/UpdateGroupDialog.generated";
import { GroupManagerProps } from "~/components/Admin/Group/useGroupManager";
import { OrgPortfolioFragment } from "~/components/Admin/User/NewUserDialog.generated";
import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { AddIcon } from "~/components/common/icons";
import CancelCircle from "~/components/common/icons/CancelCircle";
import { StyledTextField } from "~/components/common/inputs";
import { BetterAlert as Alert } from "~/components/profile/Alert";
import {
  blue,
  danger,
  gray100,
  gray300,
  gray700,
  indigo,
} from "~/styles/theme/color";
import { borderRadius } from "~/styles/theme/common";

export const UPDATE_GROUP_MUTATION = gql`
  mutation UpdateGroup(
    $orgSlug: String!
    $groupId: ID!
    $name: String
    $roleId: ID
    $portfolios: [PortfolioInput!]
  ) {
    updateGroup(
      orgSlug: $orgSlug
      groupId: $groupId
      name: $name
      roleId: $roleId
      portfolios: $portfolios
    ) {
      ...GroupRow
    }
  }
  ${GROUP_ROW_FRAGMENT}
`;

export const validationSchema = yup
  .object()
  .shape({
    name: yup.string().required("Name is a required field"),
    roleId: yup.string(),
    portfolios: yup.array().of(
      yup.object().shape({
        id: yup
          .string()
          .trim()
          .typeError("Please select a portfolio.")
          .required("Please select a portfolio."),
      })
    ),
  })
  .required();
export type GroupValues = yup.InferType<typeof validationSchema>;

export interface UpdateGroupDialogProps extends GroupManagerProps {
  orgSlug: string;
  group: GroupRowFragment | null;
  availableRoles: readonly OrgRoleFragment[];
  availablePortfolios: readonly OrgPortfolioFragment[];
  portfoliosLoading: boolean;
}

export const UpdateGroupDialog = (props: UpdateGroupDialogProps) => {
  // This is complex enough it really should be a page (not a dialog)
  // We should also show the users belonging to the group on the page. (TODO)
  const {
    orgSlug,
    group,
    updateVisible,
    close,
    availableRoles,
    availablePortfolios,
    portfoliosLoading,
  } = props;
  const [alertMessage, setAlertMessage] = useState("");
  const availablePortfolioIds = availablePortfolios.map((p) => p.id);
  const initialPortfolioIds = (group?.portfolioIds ?? []).filter(
    (pId) => pId && availablePortfolioIds.includes(pId)
  );
  const initialPortfolios = map(initialPortfolioIds, (portfolioId: number) => ({
    id: portfolioId,
  }));

  const initialValues: GroupValues = {
    name: group?.name ?? "",
    roleId: group?.role?.id ?? "",
    portfolios: initialPortfolios,
  };

  const [update] = useUpdateGroupMutation({
    onError: () =>
      setAlertMessage(
        "Unable to update group. Please contact support@dock.energy for assistance."
      ),
  });

  const handleSubmit = useCallback(
    async (formFields: GroupValues, actions: FormikActions<GroupValues>) => {
      try {
        await update({
          variables: {
            orgSlug: orgSlug,
            groupId: group?.id ?? "",
            name: formFields.name,
            roleId: formFields.roleId,
            portfolios: formFields.portfolios,
          },
          refetchQueries: ["OrgGroups", "OrgData"],
        });
        close();
        actions.resetForm({
          values: {
            orgSlug: "",
            name: "",
            roleId: "",
            portfolios: [],
          },
        });
      } catch (error) {
        actions.setFieldError("name", trim(error.message, "'"));
      } finally {
        actions.setSubmitting(false);
      }
    },
    [update, close, orgSlug, group?.id]
  );
  const submitButton = (disabled: boolean, submitForm: EventHandler<any>) => (
    <div>
      <PrimaryButton type="submit" disabled={disabled} onClick={submitForm}>
        Save Group
      </PrimaryButton>
    </div>
  );
  return (
    <div>
      <Alert message={alertMessage} />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {({
          submitForm,
          isSubmitting,
          handleChange,
          handleBlur,
          errors,
          values,
          touched,
        }) => (
          <Dialog
            isOpen={updateVisible}
            onRequestClose={close}
            header={
              <span>
                <i
                  className="thumbnail icon icon--lock-active"
                  style={{ width: "20px", height: "20px", marginBottom: "5px" }}
                />
                <span style={{ paddingLeft: "10px" }}>Update Group</span>
              </span>
            }
            actions={submitButton(isSubmitting, submitForm)}
          >
            <div className="settings-page mb-5">
              <Form>
                <Grid
                  container
                  direction="column"
                  spacing={4}
                  style={{ paddingTop: 24, minHeight: 500 }}
                >
                  <Grid item>
                    <StyledTextField
                      className="form-control"
                      variant="outlined"
                      id="name"
                      name="name"
                      label="Name*"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item>
                    <Tile>
                      <TableTitle>Deal Room Access</TableTitle>
                      <TableContainer>
                        <FieldArray
                          name="portfolios"
                          render={(arrayHelpers) => (
                            <>
                              {values.portfolios.length > 0 &&
                                values.portfolios.map(
                                  (p: PortfolioInput, index: number) => {
                                    const wasTouched =
                                      touched.portfolios &&
                                      Boolean(
                                        getIn(touched, `portfolios.${index}.id`)
                                      );
                                    const hasError =
                                      wasTouched &&
                                      Boolean(
                                        getIn(errors, `portfolios.${index}.id`)
                                      );
                                    return (
                                      <InputRow
                                        key={`${index}-${p.id}`}
                                        error={hasError}
                                      >
                                        <div style={{ width: "90%" }}>
                                          <TableRowSelect
                                            InputProps={{
                                              disableUnderline: true,
                                            }}
                                            defaultValue={p.id ?? ""}
                                            disabled={portfoliosLoading}
                                            name={`portfolios.${index}.id`}
                                            variant="standard"
                                            select
                                            onChange={handleChange}
                                            error={hasError}
                                          >
                                            <MenuItem key="" value="">
                                              ---Select a portfolio---
                                            </MenuItem>
                                            {availablePortfolios.map(
                                              ({ id, name }) => (
                                                <MenuItem
                                                  selected={p.id === id}
                                                  key={`${name}`}
                                                  value={id}
                                                >{`${name}`}</MenuItem>
                                              )
                                            )}
                                          </TableRowSelect>
                                        </div>
                                        <div
                                          style={{
                                            width: "10%",
                                            alignSelf: "center",
                                          }}
                                        >
                                          <DeleteButton
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          />
                                        </div>
                                      </InputRow>
                                    );
                                  }
                                )}
                              <InputRow last>
                                <SecondaryButton
                                  style={{ width: "100%" }}
                                  onClick={() => {
                                    arrayHelpers.push({
                                      id: "",
                                    });
                                  }}
                                >
                                  <AddIcon />
                                  <span>Allow Deal Room Access</span>
                                </SecondaryButton>
                              </InputRow>
                            </>
                          )}
                        />
                      </TableContainer>
                      {touched.portfolios && errors.portfolios && (
                        <TableError>Please select a portfolio.</TableError>
                      )}
                    </Tile>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="roleId"
                      name="roleId"
                      select
                      fullWidth
                      defaultValue={""}
                      label="Role*"
                      variant="outlined"
                      value={values.roleId}
                      onChange={handleChange}
                      error={touched.roleId && Boolean(errors.roleId)}
                      helperText="Refer to the table below"
                    >
                      <MenuItem key="---" value="">
                        ---
                      </MenuItem>
                      {availableRoles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <GroupAccessTable
                      orgSlug={orgSlug}
                      roleId={values.roleId}
                    />
                  </Grid>
                </Grid>
              </Form>
            </div>
          </Dialog>
        )}
      </Formik>
    </div>
  );
};

export const Tile = styled.div`
  border-radius: ${borderRadius};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid ${gray300};
  padding: 16px;
`;

export const TableTitle = styled.div<{ disabled?: boolean }>`
  font-size: 14;
  margin-bottom: 12px;
  ${(props) =>
    props.disabled &&
    css`
      color: ${gray700};
    `}
`;

export const TableError = styled.div`
  color: ${danger};
  margin-top: 12px;
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  border-top: 1px solid ${gray100};
  display: flex;
  flex-direction: column;
`;

export const InputRow = styled.div<{ last?: boolean; error?: boolean }>`
  display: flex;
  align-items: stretch;
  max-height: 50px;
  column-gap: 10px;
  justify-content: ${(props) => (props.last ? "flex-start" : "space-between")};
  border: ${(props) =>
    props.last
      ? "none"
      : props.error
      ? `1px solid ${danger}`
      : `1px solid ${gray100}`};
`;

export const TableRowSelect = styled(TextField)({
  "& .MuiFormControl-root ": {
    height: "100%",
  },
  "& .MuiSelect-select": {
    padding: "6px",
  },
});

export const DeleteButton = styled(CancelCircle)`
  color: ${indigo};

  &:hover {
    color: ${blue};
  }
`;
