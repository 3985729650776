// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { FundEntityOverview_HoldingCompany_Fragment, FundEntityOverview_Portfolio_Fragment } from '../Overview/OverviewPage.generated';
import { HoldingCompanyBodyFragment } from './HoldingCompanyBody.generated';
import { HoldingCompanyTabsFragment } from './HoldingCompanyTabs.generated';
import { gql } from '@apollo/client';
import { FundEntityOverviewFragmentDoc } from '../Overview/OverviewPage.generated';
import { HoldingCompanyBodyFragmentDoc } from './HoldingCompanyBody.generated';
import { HoldingCompanyTabsFragmentDoc } from './HoldingCompanyTabs.generated';
import * as Apollo from '@apollo/client';
export type HoldingCompanyPageQueryVariables = Types.Exact<{
  slug: Types.Scalars['String'];
}>;


export type HoldingCompanyPageQuery = (
  { readonly __typename: 'Query' }
  & { readonly holdingCompany?: Types.Maybe<(
    { readonly __typename: 'HoldingCompany' }
    & Pick<Types.HoldingCompany, 'id'>
    & { readonly documentsTree?: Types.Maybe<(
      { readonly __typename: 'DocumentTree' }
      & Pick<Types.DocumentTree, 'id'>
    )>, readonly reportsTree?: Types.Maybe<(
      { readonly __typename: 'DocumentTree' }
      & Pick<Types.DocumentTree, 'id'>
    )> }
    & FundEntityOverview_HoldingCompany_Fragment
    & HoldingCompanyBodyFragment
    & HoldingCompanyTabsFragment
  )> }
);


export const HoldingCompanyPageDocument = gql`
    query HoldingCompanyPage($slug: String!) {
  holdingCompany(slug: $slug) {
    id
    documentsTree {
      id
    }
    reportsTree {
      id
    }
    ...FundEntityOverview
    ...HoldingCompanyBody
    ...HoldingCompanyTabs
  }
}
    ${FundEntityOverviewFragmentDoc}
${HoldingCompanyBodyFragmentDoc}
${HoldingCompanyTabsFragmentDoc}`;

/**
 * __useHoldingCompanyPageQuery__
 *
 * To run a query within a React component, call `useHoldingCompanyPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHoldingCompanyPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHoldingCompanyPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useHoldingCompanyPageQuery(baseOptions: Apollo.QueryHookOptions<HoldingCompanyPageQuery, HoldingCompanyPageQueryVariables>) {
        return Apollo.useQuery<HoldingCompanyPageQuery, HoldingCompanyPageQueryVariables>(HoldingCompanyPageDocument, baseOptions);
      }
export function useHoldingCompanyPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HoldingCompanyPageQuery, HoldingCompanyPageQueryVariables>) {
          return Apollo.useLazyQuery<HoldingCompanyPageQuery, HoldingCompanyPageQueryVariables>(HoldingCompanyPageDocument, baseOptions);
        }
export type HoldingCompanyPageQueryHookResult = ReturnType<typeof useHoldingCompanyPageQuery>;
export type HoldingCompanyPageLazyQueryHookResult = ReturnType<typeof useHoldingCompanyPageLazyQuery>;
export type HoldingCompanyPageQueryResult = Apollo.QueryResult<HoldingCompanyPageQuery, HoldingCompanyPageQueryVariables>;