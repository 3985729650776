import classnames from "classnames";
import React from "react";
import { useParams } from "react-router-dom";

import { useProjectCrossLinksQuery } from "~/components/Documents/DocumentsAPI.generated";
import { ProjectDetailFragment } from "~/components/Projects/ProjectPage.generated";

export interface ProjectDocsLinkProps {
  project?: ProjectDetailFragment | null;
  portfolioId?: string;
}

const ProjectDocsLink = ({ project, portfolioId }: ProjectDocsLinkProps) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data } = useProjectCrossLinksQuery({
    variables: { projectId: projectId },
  });
  const crossLinkData = portfolioId
    ? data?.projectCrossLinks?.filter((c) => c?.portfolio?.id === portfolioId)
    : data?.projectCrossLinks;
  return crossLinkData ? (
    <div className={classnames("project-section", "grid")}>
      <div>
        <span className="project-header">PORTFOLIO</span>
        {crossLinkData.map(
          (crossLink, i) =>
            crossLink &&
            crossLink.portfolio &&
            crossLink.portfolio.slug && (
              <div
                key={`portfolio-link-${i}`}
                className="project-content docs-link"
              >
                <a href={`/portfolios/${crossLink.portfolio?.slug}`}>
                  {crossLink.portfolio.name}
                </a>
              </div>
            )
        )}
      </div>
      <div>
        <span className="project-header">DOCUMENTS</span>
        {crossLinkData.map(
          (crossLink, i) =>
            crossLink &&
            crossLink.document && (
              <div
                key={`document-link-${i}`}
                className="project-content docs-link"
              >
                <a href={crossLink.document.link ?? ""}>
                  {project && project.name}
                </a>
              </div>
            )
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ProjectDocsLink;
