import styled from "styled-components";

const WhiteBox = styled.div`
  padding: 18px 25px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  margin: 12px 0;
  font-size: 14px;
`;

export default WhiteBox;
