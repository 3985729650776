import { gql } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { gettext } from "utils/text";

import ToggleButton from "~/components/forms/ToggleButton";
import { BetterAlert as Alert } from "~/components/profile/Alert";
import {
  HoldCoNotificationFragment,
  NotificationsPageViewerFragment,
  PortfolioNotificationFragment,
  useNotificationPageLazyQuery,
  useUpdateHoldCoNotificationSettingsMutation,
  useUpdateNotificationSettingsMutation,
  useUpdatePortfolioNotificationSettingsMutation,
} from "~/components/Settings/Notifications.generated";

export const NOTIFICATION_FRAGMENT = gql`
  fragment Notification on NotificationSetting {
    id
    enabled
  }
`;

export const PORTFOLIO_NOTIFICATION_FRAGMENT = gql`
  fragment PortfolioNotification on PortfolioNotificationSetting {
    id
    name
    enabled
  }
`;

export const HOLD_CO_NOTIFICATION_FRAGMENT = gql`
  fragment HoldCoNotification on HoldCoNotificationSetting {
    id
    name
    enabled
  }
`;

export const NOTIFICATIONS_PAGE_QUERY = gql`
  query NotificationPage($userId: ID!, $orgSlug: String!) {
    notificationSettings(userId: $userId, orgSlug: $orgSlug) {
      ...Notification
    }
    portfolioNotifications(userId: $userId, orgSlug: $orgSlug) {
      ...PortfolioNotification
    }
    holdCoNotifications(userId: $userId, orgSlug: $orgSlug) {
      ...HoldCoNotification
    }
  }

  mutation UpdateNotificationSettings(
    $userId: ID!
    $orgSlug: String!
    $enabled: Boolean!
  ) {
    updateNotificationSettings(
      userId: $userId
      orgSlug: $orgSlug
      enabled: $enabled
    ) {
      ...Notification
    }
  }

  mutation UpdatePortfolioNotificationSettings(
    $portfolioId: ID!
    $userId: ID!
    $enabled: Boolean!
  ) {
    updatePortfolioNotification(
      portfolioId: $portfolioId
      userId: $userId
      enabled: $enabled
    ) {
      ...PortfolioNotification
    }
  }

  mutation UpdateHoldCoNotificationSettings(
    $holdingCompanyId: ID!
    $userId: ID!
    $enabled: Boolean!
  ) {
    updateHoldCoNotification(
      holdingCompanyId: $holdingCompanyId
      userId: $userId
      enabled: $enabled
    ) {
      ...HoldCoNotification
    }
  }

  ${NOTIFICATION_FRAGMENT}
  ${PORTFOLIO_NOTIFICATION_FRAGMENT}
  ${HOLD_CO_NOTIFICATION_FRAGMENT}
`;

export const NOTIFICATIONS_PAGE_VIEWER_FRAGMENT = gql`
  fragment NotificationsPageViewer on User {
    id
  }
`;

export interface NotificationsPageProps {
  viewer: NotificationsPageViewerFragment;
}

export const Notifications = ({ viewer }: NotificationsPageProps) => {
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const [errorMessage, setErrorMessage] = useState("");
  // Prevent race conditions, allowing only one update request at a time
  const [loadNotifications, { data, loading: isLoading }] =
    useNotificationPageLazyQuery({
      onError: (error) => setErrorMessage(error.message),
    });
  useEffect(() => {
    loadNotifications({
      variables: { userId: viewer.id, orgSlug: orgSlug },
    });
  }, [loadNotifications, viewer.id, orgSlug]);
  const isEnabled = data?.notificationSettings?.enabled;
  const portfolios = data?.portfolioNotifications ?? [];
  const holdcos = data?.holdCoNotifications ?? [];

  const [updateSettings] = useUpdateNotificationSettingsMutation({
    onError: (error) => setErrorMessage(error.message),
  });
  const [updatePortfolio] = useUpdatePortfolioNotificationSettingsMutation({
    onError: (error) => setErrorMessage(error.message),
  });
  const [updateHoldCo] = useUpdateHoldCoNotificationSettingsMutation({
    onError: (error) => setErrorMessage(error.message),
  });
  const updateGlobalSettings = useCallback(
    (orgSlug: string) => {
      updateSettings({
        variables: {
          userId: viewer.id,
          orgSlug: orgSlug,
          enabled: !data?.notificationSettings?.enabled,
        },
      });
    },
    [updateSettings, viewer.id, data?.notificationSettings?.enabled]
  );

  const updatePortfolioSettings = useCallback(
    (portfolioId: string, enabled: boolean) => {
      updatePortfolio({
        variables: {
          portfolioId: portfolioId,
          userId: viewer.id,
          enabled: enabled,
        },
      });
    },
    [updatePortfolio, viewer.id]
  );

  const updateHoldcoSettings = useCallback(
    (holdcoId: string, enabled: boolean) => {
      updateHoldCo({
        variables: {
          holdingCompanyId: holdcoId,
          userId: viewer.id,
          enabled: enabled,
        },
      });
    },
    [updateHoldCo, viewer.id]
  );

  return (
    <div className="settings-page mb-5">
      <Alert message={errorMessage} classNames={["alert-danger"]} />
      <h3 className="settings-page__h3 mt-1">{gettext("All")}</h3>
      <hr className="settings-page__hr mb-3 mt-3" />
      <div className={`config-entry ${isEnabled && "_enabled"}`}>
        <div className="_toggler" data-testid="toggle-all-notifications">
          <ToggleButton
            title={gettext("Toggle all notifications")}
            value={data?.notificationSettings?.enabled}
            onClick={() => updateGlobalSettings(orgSlug)}
            loading={isLoading}
          />
        </div>
        <div className="_summary">{gettext("Allow notifications")}</div>
        <div className="_description">
          {gettext(
            "You can disable all notifications while on vacation " +
              "or if you no longer need updates"
          )}
        </div>
      </div>

      {holdcos.length > 0 && (
        <>
          <h3 className="settings-page__h3 mt-4">{gettext("HoldCos")}</h3>
          <hr className="settings-page__hr mb-3 mt-3" />
          {holdcos.map((holdco: HoldCoNotificationFragment) => (
            <div
              key={holdco.id}
              className={`config-entry ${
                isEnabled ? holdco.enabled && "_enabled" : "_inactive"
              }`}
            >
              <div className="_toggler">
                <ToggleButton
                  title={gettext("Holding company notifications")}
                  value={isEnabled && holdco.enabled}
                  onClick={
                    isEnabled
                      ? () => updateHoldcoSettings(holdco.id, !holdco.enabled)
                      : undefined
                  }
                  loading={isLoading}
                />
              </div>
              <div className="_summary">{holdco.name}</div>
              <div className="_description">
                {/* <Link to={`/notifications/holdco/${holdco.id}`}>Configure</Link> */}
              </div>
            </div>
          ))}
        </>
      )}

      {portfolios.length > 0 && (
        <>
          <h3 className="settings-page__h3 mt-4">{gettext("Portfolios")}</h3>
          <hr className="settings-page__hr mb-3 mt-3" />
          {portfolios.map((portfolio: PortfolioNotificationFragment) => (
            <div
              key={portfolio.id}
              className={`config-entry ${
                isEnabled ? portfolio.enabled && "_enabled" : "_inactive"
              }`}
            >
              <div className="_toggler">
                <ToggleButton
                  title={gettext("Portfolio notifications")}
                  value={isEnabled && portfolio.enabled}
                  onClick={
                    isEnabled
                      ? () =>
                          updatePortfolioSettings(
                            portfolio.id,
                            !portfolio.enabled
                          )
                      : undefined
                  }
                  loading={isLoading}
                />
              </div>
              <div className="_summary">{portfolio.name}</div>
              <div className="_description">
                {/* <Link to={`/notifications/portfolio/${portfolio.id}`}>Configure</Link> */}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
