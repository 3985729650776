import { format } from "date-fns";
import React from "react";

import { CellDropdownButton } from "~/components/DataGrid";
import ActionMenu from "~/components/menus/ActionMenu";
import ActionMenuItem from "~/components/menus/ActionMenuItem";
import Dropdown from "~/components/menus/Dropdown";
import DropdownHeader from "~/components/menus/DropdownHeader";

import { useAddFollowUpMutation } from "../api/mutations.generated";
import { useQAndA, useSaving } from "../context/QAndAContext";
import { ChildQuestion, useQuestion } from "../context/QuestionContext";

interface QuestionNumberProps {
  canEditQuestions: boolean;
}

const QuestionNumber = ({ canEditQuestions }: QuestionNumberProps) => {
  const qAndA = useQAndA();
  const question = useQuestion();
  const [addFollowUp, { loading }] = useAddFollowUpMutation();

  useSaving(loading);

  const handleSelect = () => {
    (async () => {
      const result = await addFollowUp({
        variables: {
          questionId: (question as ChildQuestion).parentId ?? question.id,
        },
      });

      const followUps = result.data?.addFollowUpQuestion?.followUps;
      if (followUps) {
        qAndA.focusQuestion(followUps[followUps.length - 1].id);
      }
    })();
  };

  const disabled = !canEditQuestions;

  return (
    <CellDropdownButton
      disabled={disabled}
      dropdown={
        <Dropdown anchor="top right" origin="top left">
          <DropdownHeader
            title={`Created on ${format(question.createdAt, "MM/DD/YYYY")}`}
          />
          <ActionMenu>
            <ActionMenuItem onSelect={handleSelect}>
              Add follow-up question
            </ActionMenuItem>
          </ActionMenu>
        </Dropdown>
      }
    >
      {question.number}
    </CellDropdownButton>
  );
};

export default QuestionNumber;
