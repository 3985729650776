import styled from "styled-components";

import { bgColor } from "~/styles/mixins";

const BlackBanner = styled.div`
  padding: 5px 25px 5px;
  ${bgColor.gray800()};
  border-radius: 4px;
  font-size: 14px;
  color: #fffbd2;
`;

export default BlackBanner;
