import React, { ReactNode } from "react";

import { GetPropsWithoutRef } from "~/utils/types";

import DropdownTriggerContext, {
  useDropdownTrigger,
} from "./DropdownTriggerContext";

export interface DropdownButtonProps extends GetPropsWithoutRef<"button"> {
  dropdown: ReactNode;
}

const DropdownButton = ({ dropdown, ...rest }: DropdownButtonProps) => {
  const { Portal, triggerProps, dropdownTrigger } = useDropdownTrigger();

  return (
    <>
      <button
        type="button"
        onClick={dropdownTrigger.toggle}
        {...triggerProps}
        {...rest}
      />
      {dropdownTrigger.openCount > 0 && (
        <DropdownTriggerContext.Provider value={dropdownTrigger}>
          <Portal>{dropdown}</Portal>
        </DropdownTriggerContext.Provider>
      )}
    </>
  );
};

export default DropdownButton;
