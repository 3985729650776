import styled from "styled-components";

import { centerContent, centerVertically } from "~/styles/mixins";
import { blue, gray100, gray300, gray800 } from "~/styles/theme/color";
import { borderRadius } from "~/styles/theme/common";

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid ${gray300};
  border-bottom-width: 0;
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  min-width: 100%;
  font-size: 0.875rem;

  th,
  td {
    border-bottom: 1px solid ${gray300};
    padding: 0;

    & > div,
    & > a {
      ${centerVertically};
      white-space: nowrap;
      color: ${gray800};
      padding: 0 0.5rem;
      width: 100%;
      height: 100%;
    }
  }

  th {
    height: 2.5rem;
    background-color: ${gray100};

    &:first-child {
      border-top-left-radius: ${borderRadius};
    }

    &:last-child {
      border-top-right-radius: ${borderRadius};
    }
  }

  td {
    min-height: 3rem;

    > div,
    > a {
      min-height: 3rem;
      padding: 0 0.5rem;
    }
  }

  tr:hover > td {
    border-bottom-color: ${blue};
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
`;

export const EmptyRow = styled.div`
  ${centerContent};
  height: 15rem;
  border: 1px solid ${gray300};
  border-top-width: 0;
  font-weight: bold;
`;
