// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateChecklistItemStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  status?: Types.Maybe<Types.ChecklistItemStatus>;
}>;


export type UpdateChecklistItemStatusMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateChecklistItem?: Types.Maybe<(
    { readonly __typename: 'Item' }
    & Pick<Types.Item, 'id' | 'status'>
  )> }
);

export type UpdateChecklistItemUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  userId: Types.Scalars['ID'];
}>;


export type UpdateChecklistItemUserMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateChecklistItem?: Types.Maybe<(
    { readonly __typename: 'Item' }
    & Pick<Types.Item, 'id' | 'userId'>
  )> }
);


export const UpdateChecklistItemStatusDocument = gql`
    mutation UpdateChecklistItemStatus($id: ID!, $status: ChecklistItemStatus) {
  updateChecklistItem(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type UpdateChecklistItemStatusMutationFn = Apollo.MutationFunction<UpdateChecklistItemStatusMutation, UpdateChecklistItemStatusMutationVariables>;

/**
 * __useUpdateChecklistItemStatusMutation__
 *
 * To run a mutation, you first call `useUpdateChecklistItemStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChecklistItemStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChecklistItemStatusMutation, { data, loading, error }] = useUpdateChecklistItemStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateChecklistItemStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChecklistItemStatusMutation, UpdateChecklistItemStatusMutationVariables>) {
        return Apollo.useMutation<UpdateChecklistItemStatusMutation, UpdateChecklistItemStatusMutationVariables>(UpdateChecklistItemStatusDocument, baseOptions);
      }
export type UpdateChecklistItemStatusMutationHookResult = ReturnType<typeof useUpdateChecklistItemStatusMutation>;
export type UpdateChecklistItemStatusMutationResult = Apollo.MutationResult<UpdateChecklistItemStatusMutation>;
export type UpdateChecklistItemStatusMutationOptions = Apollo.BaseMutationOptions<UpdateChecklistItemStatusMutation, UpdateChecklistItemStatusMutationVariables>;
export const UpdateChecklistItemUserDocument = gql`
    mutation UpdateChecklistItemUser($id: ID!, $userId: ID!) {
  updateChecklistItem(id: $id, userId: $userId) {
    id
    userId
  }
}
    `;
export type UpdateChecklistItemUserMutationFn = Apollo.MutationFunction<UpdateChecklistItemUserMutation, UpdateChecklistItemUserMutationVariables>;

/**
 * __useUpdateChecklistItemUserMutation__
 *
 * To run a mutation, you first call `useUpdateChecklistItemUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChecklistItemUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChecklistItemUserMutation, { data, loading, error }] = useUpdateChecklistItemUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateChecklistItemUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChecklistItemUserMutation, UpdateChecklistItemUserMutationVariables>) {
        return Apollo.useMutation<UpdateChecklistItemUserMutation, UpdateChecklistItemUserMutationVariables>(UpdateChecklistItemUserDocument, baseOptions);
      }
export type UpdateChecklistItemUserMutationHookResult = ReturnType<typeof useUpdateChecklistItemUserMutation>;
export type UpdateChecklistItemUserMutationResult = Apollo.MutationResult<UpdateChecklistItemUserMutation>;
export type UpdateChecklistItemUserMutationOptions = Apollo.BaseMutationOptions<UpdateChecklistItemUserMutation, UpdateChecklistItemUserMutationVariables>;