import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

const CheckIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox={"0 0 24 24"} fill="currentColor">
    <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
  </SvgIcon>
);

export default CheckIcon;
