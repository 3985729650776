import distanceInWordsToNow from "date-fns/distance_in_words_to_now";
import formatTimestamp from "date-fns/format";
import isFuture from "date-fns/is_future";
import parseTimestamp from "date-fns/parse";

export function formatMoney(amount) {
  return `€ ${amount.toFixed(2).toString()}`;
}

export function formatNumber(value, precision = 1, suffix = "") {
  const suffixWithSpace = suffix.length ? ` ${suffix}` : "";
  return `${value.toFixed(precision).toString()}${suffixWithSpace}`;
}

export function formatDate(date) {
  return formatTimestamp(parseTimestamp(date), "DD.MM.YYYY");
}

export function formatDateAgo(date) {
  const ts = parseTimestamp(date);

  // Change isFuture block if "in the future" is actually a valid message
  // as in our use case it currently only happens due to rounding errors
  if (isFuture(ts)) {
    return "just now";
  } else {
    return `${distanceInWordsToNow(ts)} ago`;
  }
}

export function formatBytes(value) {
  let currentValue = value;
  const suffixes = ["B", "KB", "MB", "GB"];
  for (let i = 0; ; i++) {
    if (currentValue < 1000 || i + 1 >= suffixes.length) {
      const precision = currentValue >= 100 ? 0 : 1;
      return `${currentValue.toFixed(precision)} ${suffixes[i]}`;
    }
    currentValue /= 1000;
  }
}

export function formatCommentsTitleDate(date) {
  const day = formatTimestamp(parseTimestamp(date), "MMMM D, YYYY");
  const time = formatTimestamp(parseTimestamp(date), "h:m a");
  return `${day} at ${time}`;
}
