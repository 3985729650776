// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdatePhoneNumberFormFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'phoneNumber'>
);

export type UpdatePhoneNumberMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  phoneNumber: Types.Scalars['String'];
  countryCode: Types.Scalars['String'];
  authCode: Types.Scalars['String'];
}>;


export type UpdatePhoneNumberMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateUserPhoneNumber?: Types.Maybe<(
    { readonly __typename: 'User' }
    & UpdatePhoneNumberFormFragment
  )> }
);

export const UpdatePhoneNumberFormFragmentDoc = gql`
    fragment UpdatePhoneNumberForm on User {
  id
  phoneNumber
}
    `;
export const UpdatePhoneNumberDocument = gql`
    mutation UpdatePhoneNumber($id: ID!, $phoneNumber: String!, $countryCode: String!, $authCode: String!) {
  updateUserPhoneNumber(
    id: $id
    phoneNumber: $phoneNumber
    countryCode: $countryCode
    authCode: $authCode
  ) {
    ...UpdatePhoneNumberForm
  }
}
    ${UpdatePhoneNumberFormFragmentDoc}`;
export type UpdatePhoneNumberMutationFn = Apollo.MutationFunction<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>;

/**
 * __useUpdatePhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberMutation, { data, loading, error }] = useUpdatePhoneNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      phoneNumber: // value for 'phoneNumber'
 *      countryCode: // value for 'countryCode'
 *      authCode: // value for 'authCode'
 *   },
 * });
 */
export function useUpdatePhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>) {
        return Apollo.useMutation<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>(UpdatePhoneNumberDocument, baseOptions);
      }
export type UpdatePhoneNumberMutationHookResult = ReturnType<typeof useUpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationResult = Apollo.MutationResult<UpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationOptions = Apollo.BaseMutationOptions<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>;