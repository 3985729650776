import ToolTip from "components/DocumentUpload/ToolTip";
import React from "react";

export const RolesTrunc = ({ roles }: { roles: Array<string> }) => {
  let roleOne =
    roles[0] &&
    (roles[0].length > 25 ? `${roles[0].slice(0, 20)}...` : roles[0]);
  let roleTwo =
    roles[1] &&
    (roles[1].length > 25 ? `${roles[1].slice(0, 20)}...` : roles[1]);

  if (roles.length > 2 && roleOne.includes("...")) {
    return (
      <ToolTip placement="top" tooltip={roles.join(", ")} delayShow={250}>
        {`${roleOne}, +${roles.length - 1}`}
      </ToolTip>
    );
  } else if (roles.length > 2 && !roleOne.includes("...")) {
    return (
      <ToolTip placement="top" tooltip={roles.join(", ")} delayShow={250}>
        {`${roleOne}, ${roleTwo}, +${roles.length - 2}`}
      </ToolTip>
    );
  } else if (roles.length === 2 && ![roleOne, roleTwo].join().includes("...")) {
    return <span>{[roleOne, roleTwo].join(", ")}</span>;
  } else if (roles.length === 2 && [roleOne, roleTwo].join().includes("...")) {
    if (roleOne.includes("...")) {
      return (
        <ToolTip placement="top" tooltip={roles.join(", ")} delayShow={250}>
          {`${roleOne}, +${roles.length - 1}`}
        </ToolTip>
      );
    } else {
      return (
        <ToolTip placement="top" tooltip={roles.join(", ")} delayShow={250}>
          {[roleOne, roleTwo].join(", ")}
        </ToolTip>
      );
    }
  } else {
    return <span>{roles[0] ?? null}</span>;
  }
};
