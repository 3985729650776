import { Tab, Tabs } from "@material-ui/core";
import styled from "styled-components";

import { borderRadius } from "~/styles/mixins";
import { ThemeProps } from "~/styles/theme";
import { blue } from "~/styles/theme/color";

export const StyledTabs = styled(Tabs)`
  background-color: #eee;
  min-height: 44px;

  ${borderRadius()};

  .MuiTabs-indicator {
    top: 3px;
    bottom: 3px;
    right: 3px;
    height: auto;
    background: none;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 4px;
      right: 4px;
      bottom: 0px;
      ${borderRadius()};
      background-color: #fff;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
    }
  }

  .MuiTabs-flexContainer {
    display: inline-flex;
    position: relative;
    z-index: 1;
  }
`;

export interface BaseTabProps {
  index: number;
  error?: boolean;
}

export const StyledTab = styled(Tab)
  .withConfig<BaseTabProps>({
    shouldForwardProp: (prop) => prop !== "error" && prop !== "index",
  })
  .attrs((props: ThemeProps & BaseTabProps) => ({
    id: `tab-${props.index}`,
    color: "error",
  }))`

  &:hover {
    opacity: 1;
  }
  min-height: 44px;
  min-width: 96px;

  .MuiTab-wrapper {
    color: ${blue};
    text-transform: initial;
  }

  .Mui-selected {
    color: green;
  }

  &:focus {
    outline: none;
  }
`;
