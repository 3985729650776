// Grayscale
export const white = "#FFFFFF";
export const gray100 = "#F2F2F2";
export const gray200 = "#e9ecef";
export const gray300 = "#D8D8D8";
export const gray400 = "#D0D0D0";
export const gray500 = "#C0C0C0";
export const gray600 = "#9B9B9B";
export const gray700 = "#5A5A5A";
export const gray800 = "#231F20";
export const gray900 = "#0F1419";
export const black = "#201f23";

// Colors
export const arcticBlue = "#27A4BF";
export const blue = "#008CD8";
export const lightBlue = "#E0F1FA";
export const darkBlue = "#315977";
export const indigo = "#305876";
export const purple = "#292DFF";
export const pink = "#e83e8c";
export const red = "#E64632";
export const orange = "#FF6929";
export const yellow = "#FFAE29";
export const green = "#8DC727";
export const teal = "#20c997";
export const cyan = "#8DF4FF";
export const sushi = "#789E38";

// Palette
export const primary = blue;
export const secondary = gray600;
export const success = green;
export const info = cyan;
export const warning = yellow;
export const danger = red;
export const light = gray100;
export const dark = darkBlue;

// Special
export const transparent = "transparent";
