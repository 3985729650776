import { createContext, useContext, useEffect } from "react";

import { Scalars } from "~/api/types.generated";
import usePrevious from "~/hooks/usePrevious";

import { ChildQuestion, ParentQuestion } from "./QuestionContext";

export type QuestionList = Array<ParentQuestion | ChildQuestion>;

export interface QAndAContextValue {
  incSavingCount: () => void;
  decSavingCount: () => void;
  focusQuestion: (id: Scalars["ID"]) => void;
}

export const useQAndA = () => {
  return useContext(QAndAContext);
};

export const useSaving = (saving: boolean) => {
  const { incSavingCount, decSavingCount } = useQAndA();
  const prevSaving = usePrevious(saving);

  useEffect(() => {
    if (!prevSaving && saving) {
      incSavingCount();
    } else if (prevSaving && !saving) {
      decSavingCount();
    }
  }, [saving, prevSaving, incSavingCount, decSavingCount]);
};

const QAndAContext = createContext<QAndAContextValue>(null as any);

export default QAndAContext;
