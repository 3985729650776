import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";
import { formatDateAgo } from "utils/formats";

import { FileExcelIcon } from "~/components/common/icons";
import { EmptyRow, Table } from "~/components/common/tables";
import { DuplicateReportFragment } from "~/components/Settings/DuplicateReportsTable.generated";
import { fgColor } from "~/styles/mixins";

export const DUPLICATE_REPORT_FRAGMENT = gql`
  fragment DuplicateReport on SalesforceDuplicateReport {
    id
    status
    fileUrl
    fileName
    createdBy
    createdAt
  }
`;

export interface DuplicateReportsTableProps {
  rows: readonly DuplicateReportFragment[];
  loading: boolean;
}

/* eslint-disable-next-line react/prop-types */
const DuplicateReportsTable = ({
  rows,
  loading,
}: DuplicateReportsTableProps) => {
  return (
    <div className="table-overflow-wrapper entries-table-wrapper">
      <StyledTable>
        <thead>
          <tr>
            <th className="name-cell">File</th>
            <th className="other-cell">User</th>
            <th className="other-cell">Last Modified</th>
            <th className="other-cell">Status</th>
          </tr>
        </thead>

        <tbody>
          {rows.length
            ? rows.map((row, i) => (
                <tr key={i}>
                  <td className="name-cell">
                    <div>
                      <Thumbnail as={FileExcelIcon} />
                      {row.fileUrl && <a href={row.fileUrl}>{row.fileName}</a>}
                      {!row.fileUrl && <div>{row.fileName}</div>}
                    </div>
                  </td>
                  <td className="other-cell">
                    <div>{row.createdBy}</div>
                  </td>
                  <td className="other-cell">
                    <div>{formatDateAgo(row.createdAt)}</div>
                  </td>
                  <td className="other-cell">
                    <div>{row.status}</div>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </StyledTable>
      {!loading && !rows.length && <EmptyRow>No duplicate reports.</EmptyRow>}
    </div>
  );
};

const Thumbnail = styled.span`
  ${fgColor.blue()};
  display: inline-block;
  flex: 0 0 2rem;
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
  padding-right: 0.5rem;
`;

const StyledTable = styled(Table)`
  th {
    padding-left: 0.5rem;
  }
  td.name-cell {
    cursor: pointer;
    max-width: 20rem;
  }
  td.other-cell {
    width: 10rem;
  }
`;

export default DuplicateReportsTable;
