// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DocumentAggsDataFragment = (
  { readonly __typename: 'DocumentAggs' }
  & Pick<Types.DocumentAggs, 'id' | 'downloadCount' | 'viewCount'>
  & { readonly document?: Types.Maybe<(
    { readonly __typename: 'Document' }
    & Pick<Types.Document, 'name'>
  )> }
);

export type TreeDocumentAnalyticsQueryVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  ordering?: Types.Maybe<Types.Scalars['String']>;
}>;


export type TreeDocumentAnalyticsQuery = (
  { readonly __typename: 'Query' }
  & { readonly documentAggs: (
    { readonly __typename: 'PaginatedDocumentAggs' }
    & Pick<Types.PaginatedDocumentAggs, 'count' | 'maxDownload' | 'maxView'>
    & { readonly documents?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'DocumentAggs' }
      & DocumentAggsDataFragment
    )>> }
  ) }
);

export const DocumentAggsDataFragmentDoc = gql`
    fragment DocumentAggsData on DocumentAggs {
  id
  document {
    name
  }
  downloadCount
  viewCount
}
    `;
export const TreeDocumentAnalyticsDocument = gql`
    query TreeDocumentAnalytics($treeId: ID!, $limit: Int, $offset: Int, $ordering: String) {
  documentAggs(
    treeId: $treeId
    limit: $limit
    offset: $offset
    ordering: $ordering
  ) {
    count
    maxDownload
    maxView
    documents {
      ...DocumentAggsData
    }
  }
}
    ${DocumentAggsDataFragmentDoc}`;

/**
 * __useTreeDocumentAnalyticsQuery__
 *
 * To run a query within a React component, call `useTreeDocumentAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreeDocumentAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreeDocumentAnalyticsQuery({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useTreeDocumentAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<TreeDocumentAnalyticsQuery, TreeDocumentAnalyticsQueryVariables>) {
        return Apollo.useQuery<TreeDocumentAnalyticsQuery, TreeDocumentAnalyticsQueryVariables>(TreeDocumentAnalyticsDocument, baseOptions);
      }
export function useTreeDocumentAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TreeDocumentAnalyticsQuery, TreeDocumentAnalyticsQueryVariables>) {
          return Apollo.useLazyQuery<TreeDocumentAnalyticsQuery, TreeDocumentAnalyticsQueryVariables>(TreeDocumentAnalyticsDocument, baseOptions);
        }
export type TreeDocumentAnalyticsQueryHookResult = ReturnType<typeof useTreeDocumentAnalyticsQuery>;
export type TreeDocumentAnalyticsLazyQueryHookResult = ReturnType<typeof useTreeDocumentAnalyticsLazyQuery>;
export type TreeDocumentAnalyticsQueryResult = Apollo.QueryResult<TreeDocumentAnalyticsQuery, TreeDocumentAnalyticsQueryVariables>;