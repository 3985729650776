import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { DEAL_ROOM_CARD_FRAGMENT } from "~/components/DealRoom/DealRoomCard";
import { DealRoomCardFragment } from "~/components/DealRoom/DealRoomCard.generated";
import { DealRoomGrid } from "~/components/DealRoom/DealRoomGrid";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { device } from "~/styles/mixins";

import { RecentlyVisitedFragment } from "./RecentlyVisited.generated";

export const RECENTLY_VISITED_FRAGMENT = gql`
  fragment RecentlyVisited on Query {
    recentlyVisited(limit: $recentlyVisitedLimit) {
      ...DealRoomCard
    }
  }

  ${DEAL_ROOM_CARD_FRAGMENT}
`;

export interface RecentlyVisitedProps {
  loading: boolean;
  entities: RecentlyVisitedFragment["recentlyVisited"];
  onClickCard?: (entity: DealRoomCardFragment) => void;
}

export const RecentlyVisited = ({
  loading,
  entities,
  onClickCard,
}: RecentlyVisitedProps) => {
  return (
    <>
      <Title>Recently Visited</Title>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <LoadingSpinner big />
        </div>
      ) : (
        <DealRoomGrid entities={entities} onClickCard={onClickCard} />
      )}
    </>
  );
};

const Title = styled.h3`
  color: #5a5a5a;
  font-size: 14px;
  margin: 15px 15px;
  @media ${device.xs} {
    font-size: 18px;
    margin: 20px 0;
  }
`;
