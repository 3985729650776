// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type DataImportFragment = (
  { readonly __typename: 'SalesforceDataImport' }
  & Pick<Types.SalesforceDataImport, 'id' | 'status' | 'vendor' | 'importedBy' | 'fileUrl' | 'fileName' | 'createdAt'>
);

export const DataImportFragmentDoc = gql`
    fragment DataImport on SalesforceDataImport {
  id
  status
  vendor
  importedBy
  fileUrl
  fileName
  createdAt
}
    `;