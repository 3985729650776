import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { PrimaryButton } from "~/components/common/buttons";
import { PageContainer } from "~/components/common/layout";
import { device } from "~/styles/mixins";
import { white } from "~/styles/theme/color";

export const NotFound = () => {
  return (
    <NotFoundContainer>
      <Sorry>
        <Content>
          <Headline>
            Sorry. We can’t seem to find the page you’re looking for.
          </Headline>
          <ReturnButton as={Link} to="/">
            Return to homepage
          </ReturnButton>
        </Content>
      </Sorry>
      <ErrorCode>Error 404 to be precise.</ErrorCode>
    </NotFoundContainer>
  );
};

const NotFoundContainer = styled(PageContainer)`
  @media ${device.sm} {
    max-width: 540px;
  }
  @media ${device.md} {
    max-width: 720px;
  }
  @media ${device.lg} {
    max-width: 960px;
  }
  @media ${device.xl} {
    max-width: 1140px;
  }
`;

const Sorry = styled.div`
  @media ${device.md} {
    background: url("${DJ_CONST.STATIC_URL}images/sorry-background.png") top
      center no-repeat;
    background-size: contain;
    width: 100%;
    padding-bottom: 41.7%;
    position: relative;
  }
`;

const Content = styled.div`
  padding-bottom: 35px;
  text-align: center;

  @media ${device.md} {
    padding: 35px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const Headline = styled.h1`
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0 0 90px;

  @media ${device.md} {
    color: ${white};
    font-size: 22px;
    margin-bottom: 40px;
  }

  @media ${device.lg} {
    font-size: 30px;
    margin-bottom: 60px;
  }

  @media ${device.xl} {
    font-size: 38px;
    margin-bottom: 90px;
  }
`;

const ReturnButton = styled(PrimaryButton)`
  font-size: 1.125rem;
  padding: 0.375rem 1rem;
  line-height: 1.5;
`;

const ErrorCode = styled.div`
  font-size: 14px;
  margin-bottom: 64px;
  margin-top: 18px;
  text-align: center;
`;
