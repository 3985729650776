import { debounce } from "lodash";
import { createContext, useContext, useEffect, useMemo, useRef } from "react";

const EventTrackingContext = createContext({});

/**
 * A context provider for mixpanel tracking. The value passed to this
 * provider should include common properties shared by all tracking events
 * inside the provider (e.g. portfolio name/id, etc)
 */
export const EventTrackingProvider = EventTrackingContext.Provider;

/**
 * The input type of the useEventTracking hook. This is an interface for an
 * object that maps event tracking functions to event signatures. Event
 * signatures may simply be a string (the event name sent to mixpanel),
 * or a tuple.
 */
export interface EventMap {
  [name: string]: string | EventTuple;
}

/**
 * A type that represents an event signature tuple, including an event name
 * and a function which maps call-time arguments to event properties.
 */
export type EventTuple = readonly [string, (...args: any[]) => {}];

/** Extracts the call-time arguments from an event signature tuple. */
export type EventArgs<T extends EventTuple> = Parameters<T[1]>;

/**
 * The return type of the useEventTracking hook. This is an object with the
 * same keys as the provided EventMap, but the values are void-ruturning functions
 * that actually send the tracking events to mixpanel.
 */
export type EventFns<T extends EventMap> = {
  [K in keyof T]: T[K] extends EventTuple
    ? (...args: EventArgs<T[K]>) => void
    : () => void;
};

/**
 * Creates an object with event tracking functions, given an event signature map.
 * If this is used inside the descendants of an EventTrackingProvider, any context
 * properties passed to the provider will be included in each event tracking call.
 *
 * @example
 *
 *   const tracking = useEventTracking("Feature Name", {
 *     buttonClick: "Button Clicked",
 *     search: [
 *       "Searched",
 *       (event: React.ChangeEvent<HTMLInputElement>) => ({
 *         "Search Query": event.currentTarget.value,
 *       }),
 *     ],
 *   });
 *
 *   <button onClick={tracking.buttonClick} />
 *   <input type="text" onChange={tracking.search} />
 */
export const useEventTracking = <T extends EventMap>(
  featureName: string,
  eventMap: T,
  extraContext?: {}
): EventFns<T> => {
  const context = useContext(EventTrackingContext);
  const contextRef = useRef(context);
  const extraContextRef = useRef(extraContext);
  const eventMapRef = useRef(eventMap);

  useEffect(() => {
    contextRef.current = context;
    extraContextRef.current = extraContext;
    eventMapRef.current = eventMap;
  });

  return useMemo(() => {
    const eventFns = {} as any;

    for (const key of Object.keys(eventMapRef.current)) {
      eventFns[key as keyof T] = debounce((...args: any[]) => {
        const signature = eventMapRef.current[key];
        const [eventName, getProps] =
          typeof signature === "string" ? [signature, () => ({})] : signature;

        mixpanel.track(`${featureName}: ${eventName}`, {
          ...contextRef.current,
          ...extraContextRef.current,
          ...getProps(...args),
        });
      }, 300);
    }

    return eventFns;
  }, [featureName]);
};
