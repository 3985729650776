import React from "react";

import { PrimaryButton } from "~/components/common/buttons";
import { ErrorText, Text } from "~/components/common/text";
import { Toolbar } from "~/components/common/toolbars";
import Dialog from "~/components/generic/Dialog";
import { useKeyDown } from "~/hooks/useKeyDown";

import { DocumentsRename } from "./useDocumentsRename";

export interface DocumentsRenameFailedDialogProps extends DocumentsRename {}

export const DocumentsRenameFailedDialog = React.memo(
  function DocumentsRenameFailedDialog(
    props: DocumentsRenameFailedDialogProps
  ) {
    const { error, reset } = props;
    const isOpen = error != null;

    useKeyDown("Enter", isOpen, reset);

    return (
      <Dialog
        isOpen={isOpen}
        header="Rename Failed"
        onRequestClose={reset}
        actions={
          <Toolbar>
            <PrimaryButton onClick={reset}>Close</PrimaryButton>
          </Toolbar>
        }
      >
        <div className="alert alert-warning">
          Renaming of the entry below failed
        </div>
        {error && (
          <div className="text-center">
            <Text>
              {error.type}: <strong>{error.name}</strong>
            </Text>
            <ErrorText>{error.message}</ErrorText>
          </div>
        )}
      </Dialog>
    );
  }
);
