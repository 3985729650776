// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ProfileAvatarFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'avatarUrl'>
);

export type UploadAvatarMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
  file: Types.Scalars['Upload'];
}>;


export type UploadAvatarMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly uploadAvatar?: Types.Maybe<(
    { readonly __typename: 'User' }
    & ProfileAvatarFragment
  )> }
);

export type DeleteAvatarMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type DeleteAvatarMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deleteAvatar?: Types.Maybe<(
    { readonly __typename: 'User' }
    & ProfileAvatarFragment
  )> }
);

export const ProfileAvatarFragmentDoc = gql`
    fragment ProfileAvatar on User {
  id
  avatarUrl
}
    `;
export const UploadAvatarDocument = gql`
    mutation UploadAvatar($userId: ID!, $file: Upload!) {
  uploadAvatar(userId: $userId, file: $file) {
    ...ProfileAvatar
  }
}
    ${ProfileAvatarFragmentDoc}`;
export type UploadAvatarMutationFn = Apollo.MutationFunction<UploadAvatarMutation, UploadAvatarMutationVariables>;

/**
 * __useUploadAvatarMutation__
 *
 * To run a mutation, you first call `useUploadAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAvatarMutation, { data, loading, error }] = useUploadAvatarMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UploadAvatarMutation, UploadAvatarMutationVariables>) {
        return Apollo.useMutation<UploadAvatarMutation, UploadAvatarMutationVariables>(UploadAvatarDocument, baseOptions);
      }
export type UploadAvatarMutationHookResult = ReturnType<typeof useUploadAvatarMutation>;
export type UploadAvatarMutationResult = Apollo.MutationResult<UploadAvatarMutation>;
export type UploadAvatarMutationOptions = Apollo.BaseMutationOptions<UploadAvatarMutation, UploadAvatarMutationVariables>;
export const DeleteAvatarDocument = gql`
    mutation DeleteAvatar($userId: ID!) {
  deleteAvatar(userId: $userId) {
    ...ProfileAvatar
  }
}
    ${ProfileAvatarFragmentDoc}`;
export type DeleteAvatarMutationFn = Apollo.MutationFunction<DeleteAvatarMutation, DeleteAvatarMutationVariables>;

/**
 * __useDeleteAvatarMutation__
 *
 * To run a mutation, you first call `useDeleteAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAvatarMutation, { data, loading, error }] = useDeleteAvatarMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteAvatarMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAvatarMutation, DeleteAvatarMutationVariables>) {
        return Apollo.useMutation<DeleteAvatarMutation, DeleteAvatarMutationVariables>(DeleteAvatarDocument, baseOptions);
      }
export type DeleteAvatarMutationHookResult = ReturnType<typeof useDeleteAvatarMutation>;
export type DeleteAvatarMutationResult = Apollo.MutationResult<DeleteAvatarMutation>;
export type DeleteAvatarMutationOptions = Apollo.BaseMutationOptions<DeleteAvatarMutation, DeleteAvatarMutationVariables>;