// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type QAndASubject_HoldingCompany_Fragment = (
  { readonly __typename: 'HoldingCompany' }
  & Pick<Types.HoldingCompany, 'id'>
  & { readonly org: (
    { readonly __typename: 'Organization' }
    & Pick<Types.Organization, 'id'>
  ), readonly userGroups?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'UserGroup' }
    & Pick<Types.UserGroup, 'id' | 'name'>
  )>>, readonly qAndALogs?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'QuestionLog' }
    & Pick<Types.QuestionLog, 'id' | 'name' | 'restricted'>
    & { readonly groups?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'UserGroup' }
      & Pick<Types.UserGroup, 'id'>
    )>> }
  )>> }
);

export type QAndASubject_Portfolio_Fragment = (
  { readonly __typename: 'Portfolio' }
  & Pick<Types.Portfolio, 'id'>
  & { readonly org: (
    { readonly __typename: 'Organization' }
    & Pick<Types.Organization, 'id'>
  ), readonly userGroups?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'UserGroup' }
    & Pick<Types.UserGroup, 'id' | 'name'>
  )>>, readonly qAndALogs?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'QuestionLog' }
    & Pick<Types.QuestionLog, 'id' | 'name' | 'restricted'>
    & { readonly groups?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'UserGroup' }
      & Pick<Types.UserGroup, 'id'>
    )>> }
  )>> }
);

export type QAndASubjectFragment = QAndASubject_HoldingCompany_Fragment | QAndASubject_Portfolio_Fragment;

export const QAndASubjectFragmentDoc = gql`
    fragment QAndASubject on FundEntity {
  __typename
  id
  org {
    id
  }
  userGroups {
    id
    name
  }
  qAndALogs {
    id
    name
    restricted
    groups {
      id
    }
  }
}
    `;