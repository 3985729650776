import styled from "styled-components";

import { border, borderRadius } from "~/styles/mixins";

const NavTabs = styled.div`
  ${border.gray300()};
  ${borderRadius()};
  display: flex;
  font-size: 14px;
  margin: 10px 0;
`;

export default NavTabs;
