import { useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";

import { UserInvitationDetailFragment } from "~/components/Admin/User/UpdateUserDialog.generated";
import {
  useDeleteUserInvitationMutation,
  useResendUserInvitationMutation,
} from "~/components/Admin/User/UsersAPI.generated";

import { UserInviteFragment } from "./UsersTable.generated";

export interface UserInvitationsManagerProps {
  error: string | null;
  invitation: (UserInviteFragment & UserInvitationDetailFragment) | null;
  loading: boolean;
  resend(token: string): Promise<void>;
  remove(invitation: UserInviteFragment): void;
  update(invitation: UserInviteFragment): void;
  deleteVisible: boolean;
  updateVisible: boolean;
  close(): void;
  confirmDelete(): Promise<void>;
}

/** Handles state and logic required for updating user invitations. */
export const useUserInvitationsManager = (
  orgSlug: string
): UserInvitationsManagerProps => {
  const client = useApolloClient();
  const [invitation, setInvitation] = useState<UserInviteFragment | null>(null);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [updateVisible, setUpdateVisible] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [requestDelete, { loading: deleteLoading }] =
    useDeleteUserInvitationMutation();
  const [requestResend, { loading: resendLoading }] =
    useResendUserInvitationMutation();

  const evict = useCallback(
    (token: string) => {
      client.cache.evict({ id: `OrgInvitation:{"token":"${token}"}` });
    },
    [client.cache]
  );

  const close = useCallback(() => {
    setInvitation(null);
    setError(null);
    setDeleteVisible(false);
    setUpdateVisible(false);
  }, []);

  /** Set the invitation to update */
  const update = (invitation: UserInviteFragment) => {
    setInvitation(invitation);
    setUpdateVisible(true);
  };

  /** Set the invitation to delete */
  const remove = (invitation: UserInviteFragment) => {
    setInvitation(invitation);
    setDeleteVisible(true);
  };

  /** Deletes the invitation */
  const confirmDelete = useCallback(async () => {
    try {
      if (invitation === null) return;
      const { data } = await requestDelete({
        variables: { orgSlug, token: invitation.token },
      });
      if (data?.deleteUserInvitation?.token)
        evict(data.deleteUserInvitation.token);
      close();
    } catch (error) {
      setError("A server error has occurred");
      console.error(error);
    }
  }, [requestDelete, orgSlug, close, evict, invitation]);

  const resend = useCallback(
    async (token: string) => {
      try {
        await requestResend({ variables: { orgSlug, token: token } });
        close();
      } catch (error) {
        setError("Unable to resend invitation.");
      }
    },
    [requestResend, orgSlug, close]
  );

  const loading = deleteLoading || resendLoading;

  return {
    error,
    invitation,
    loading,
    resend,
    remove,
    update,
    deleteVisible,
    updateVisible,
    confirmDelete,
    close,
  };
};
