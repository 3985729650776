import { createTheme } from "@material-ui/core/styles";

import * as breakpoints from "./breakpoints";
import * as color from "./color";
import * as common from "./common";

const theme = createTheme({
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
  color: color,
  common: common,
});

export type Theme = typeof theme;
export type ThemeProps = { theme: Theme };

export default theme;
