import { createContext, useEffect, useMemo } from "react";

import { Project } from "~/api/types.generated";
import { usePortfolioProjectsLazyQuery } from "~/components/QAndA/context/QuestionProjectsContext.generated";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";
import useSelection, { UseSelectionResult } from "~/hooks/useSelection";

import { ChildQuestion, ParentQuestion } from "./QuestionContext";

export type ProjectObj = Pick<Project, "__typename" | "id" | "name">;

export interface ProjectFilterContextValue {
  availableProjects: readonly ProjectObj[];
  selection: UseSelectionResult<ProjectObj>;
  filterQuestion: (question: ParentQuestion | ChildQuestion) => boolean;
}

export const useProjectFilter = (
  subject: QAndASubjectFragment
): ProjectFilterContextValue => {
  const [loadProjects, projectsQuery] = usePortfolioProjectsLazyQuery();

  useEffect(() => {
    if (subject.__typename === "Portfolio") {
      loadProjects({ variables: { portfolioId: subject.id } });
    }
  }, [subject.__typename, subject.id, loadProjects]);

  const availableProjects = useMemo(
    () => projectsQuery.data?.portfolio?.projects ?? [],
    [projectsQuery.data?.portfolio]
  );

  // FIXME: Fix the backend types - project.id shouldn't be nullable
  const selection = useSelection(availableProjects, (project) => project.id!);

  return useMemo(
    () => ({
      availableProjects,
      selection,
      filterQuestion: (question) =>
        !selection.anySelected || selection.includesAny(question.projects),
    }),
    [availableProjects, selection]
  );
};

const ProjectFilterContext = createContext<ProjectFilterContextValue>(
  null as any
);

export default ProjectFilterContext;
