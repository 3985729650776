import { ThemeProps } from "~/styles/theme";

import { ColorOptions, getColor, mapColors } from "./color";

const borderColorMixin = (
  property:
    | "borderColor"
    | "borderTopColor"
    | "borderRightColor"
    | "borderBottomColor"
    | "borderLeftColor"
) =>
  mapColors((name) => (options: ColorOptions = {}) => (props: ThemeProps) => ({
    [property]: getColor(name, options, props),
  }));

/**
 * Helper mixins for setting border colors
 *
 * @example
 *
 *   styled.div`
 *     ${borderColor.blue()};
 *     ${borderTopColor.gray500({ opacity: 0.75 })};
 *     ${borderRightColor.black({ opacity: 0.75 })};
 *     ${borderBottomColor.red()};
 *     ${borderLeftColor.primary()};
 *   `
 */
const borderColor = borderColorMixin("borderColor");
export const borderTopColor = borderColorMixin("borderTopColor");
export const borderRightColor = borderColorMixin("borderRightColor");
export const borderBottomColor = borderColorMixin("borderBottomColor");
export const borderLeftColor = borderColorMixin("borderLeftColor");

export default borderColor;
