import { gql } from "@apollo/client/core";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers as FormikActions,
} from "formik";
import { trim } from "lodash";
import React, { useCallback } from "react";
import countryDialCodes from "utils/countryDialCodes";
import { gettext } from "utils/text";
import * as yup from "yup";

import {
  UpdatePhoneNumberSendAuthFormFragment,
  useUpdatePhoneNumberSendAuthMutation,
} from "~/components/forms/UpdatePhoneNumberSendAuth.generated";

export const UPDATE_PHONE_NUMBER_SEND_AUTH_FORM_FRAGMENT = gql`
  fragment UpdatePhoneNumberSendAuthForm on User {
    id
    phoneNumber
  }
`;

export const UPDATE_PHONE_NUMBER_SEND_AUTH_FORM_MUTATION_FRAGMENT = gql`
  mutation UpdatePhoneNumberSendAuth(
    $id: ID!
    $phoneNumber: String!
    $countryCode: String!
  ) {
    updateUserPhoneNumberSendAuth(
      id: $id
      phoneNumber: $phoneNumber
      countryCode: $countryCode
    ) {
      ...UpdatePhoneNumberSendAuthForm
    }
  }

  ${UPDATE_PHONE_NUMBER_SEND_AUTH_FORM_FRAGMENT}
`;

const validationSchema = yup.object().shape({
  phoneNumber: yup.string().required("This field is required"),
});

/**
 * Parse the number code from '+1-US'. We need to keep track of the country code
 * since there are multiple countries with the same code (US and Canada both use
 * +1).
 */
const parseNumberCodeFromCodeValue = (code: string) => {
  return code.split("-")[0];
};

interface FormFields {
  phoneNumber: string;
  code: string;
}

export interface UpdatePhoneNumberSendAuthProps {
  user: UpdatePhoneNumberSendAuthFormFragment;
  phoneNumber: string;
  code: string;
  phoneNumberUpdateVisible: boolean;
  togglePhoneUpdateVisible: () => void;
  triggerTwoFactorAuth: (phoneNumber: string, code: string) => void;
}

export const UpdatePhoneNumberSendAuth = ({
  user,
  phoneNumber,
  phoneNumberUpdateVisible,
  togglePhoneUpdateVisible,
  triggerTwoFactorAuth,
  code,
}: UpdatePhoneNumberSendAuthProps & FormFields) => {
  const [submitAuth, { loading: isSubmitting }] =
    useUpdatePhoneNumberSendAuthMutation();
  const handleSubmit = useCallback(
    async (formFields: FormFields, actions: FormikActions<FormFields>) => {
      const code = parseNumberCodeFromCodeValue(formFields.code);
      try {
        await submitAuth({
          variables: {
            id: user.id,
            phoneNumber: formFields.phoneNumber,
            countryCode: code,
          },
        });
        triggerTwoFactorAuth(formFields.phoneNumber, code);
      } catch (error) {
        actions.setFieldError("phoneNumber", trim(error.message, "'"));
      }
    },
    [submitAuth, triggerTwoFactorAuth, user.id]
  );

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={{ phoneNumber: phoneNumber, code: code }}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          {phoneNumberUpdateVisible && (
            <div className="form-group">
              <label htmlFor="code">{gettext("Country/region")}</label>
              <Field
                component="select"
                id="code"
                name="code"
                className="custom-select"
              >
                {countryDialCodes.map((country) => (
                  <option
                    value={`${country.dial_code}-${country.code}`}
                    key={`${country.dial_code}-${country.code}`}
                  >
                    {country.name}
                  </option>
                ))}
              </Field>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="phoneNumber">{gettext("SMS delivery to")}</label>
            <div className="input-group">
              {phoneNumberUpdateVisible && (
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    {parseNumberCodeFromCodeValue(values.code)}
                  </span>
                </div>
              )}
              <Field
                type="tel"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                disabled={!phoneNumberUpdateVisible}
              />
            </div>
            <p className="text-danger">
              <ErrorMessage name="phoneNumber" />
            </p>
          </div>
          {phoneNumberUpdateVisible && (
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary mr-3"
                disabled={isSubmitting}
              >
                {gettext("Update mobile number")}
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                disabled={isSubmitting}
                onClick={togglePhoneUpdateVisible}
              >
                {gettext("Cancel")}
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};
