import { Checkbox, FormControlLabel, Grid, MenuItem } from "@material-ui/core";
import Dialog from "components/generic/Dialog";
import {
  FieldArray,
  Form,
  Formik,
  FormikHelpers as FormikActions,
  getIn,
} from "formik";
import { trim } from "lodash";
import React, { EventHandler, useCallback, useState } from "react";
import * as yup from "yup";

import { QuestionLog } from "~/api/types.generated";
import {
  DeleteButton,
  InputRow,
  TableContainer,
  TableError,
  TableRowSelect,
  TableTitle,
  Tile,
} from "~/components/Admin/Group/UpdateGroupDialog";
import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { AddIcon } from "~/components/common/icons";
import { StyledTextField } from "~/components/common/inputs";
import { BetterAlert as Alert } from "~/components/profile/Alert";
import { useUpdateQuestionLogMutation } from "~/components/QAndA/api/mutations.generated";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";

export const validationSchema = yup
  .object()
  .shape({
    name: yup.string().required("Name is a required field"),
    restricted: yup.boolean(),
    groupIds: yup
      .array()
      .of(
        yup
          .number()
          .typeError("Please select a group.")
          .required("Please select a group.")
      ),
  })
  .required();
export type UpdateQuestionLogValues = yup.InferType<typeof validationSchema>;

export interface UpdateQuestionLogDialogProps {
  selectedLog: QuestionLog;
  subject: QAndASubjectFragment;
  visible: boolean;
  close(): void;
}

export const UpdateQuestionLogDialog = (
  props: UpdateQuestionLogDialogProps
) => {
  const { selectedLog: log, subject, visible, close } = props;
  const [alertMessage, setAlertMessage] = useState("");

  const initialValues: UpdateQuestionLogValues = {
    name: log.name,
    restricted: log.restricted,
    groupIds: log.groups?.map((g) => g.id),
  };

  const [update] = useUpdateQuestionLogMutation({
    onError: () =>
      setAlertMessage(
        "Unable to create question log. Please contact support@dock.energy for assistance."
      ),
  });

  const handleSubmit = useCallback(
    async (
      formFields: UpdateQuestionLogValues,
      actions: FormikActions<UpdateQuestionLogValues>
    ) => {
      try {
        const { data } = await update({
          variables: {
            logId: log.id,
            name: formFields.name,
            restricted: formFields.restricted,
            groupIds: formFields.restricted === true ? formFields.groupIds : [],
          },
          refetchQueries: ["PortfolioPage", "HoldingCompanyPage"],
        });
        actions.resetForm({
          values: {
            name: data?.updateQuestionLog?.name ?? "",
            restricted: data?.updateQuestionLog?.restricted ?? false,
            groupIds: data?.updateQuestionLog?.groups?.map((g) => g.id) ?? [],
          },
        });
        close();
      } catch (error) {
        actions.setFieldError("email", trim(error.message, "'"));
      } finally {
        actions.setSubmitting(false);
      }
    },
    [update, close, log]
  );

  const submitButton = (disabled: boolean, submitForm: EventHandler<any>) => {
    return (
      <div>
        <PrimaryButton type="submit" disabled={disabled} onClick={submitForm}>
          Save Changes
        </PrimaryButton>
      </div>
    );
  };

  return (
    <div>
      <Alert message={alertMessage} />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          submitForm,
          isSubmitting,
          handleChange,
          handleBlur,
          errors,
          values,
          touched,
        }) => (
          <Dialog
            isOpen={visible}
            onRequestClose={close}
            header={
              <span>
                <i
                  className="thumbnail icon icon--doc"
                  style={{ width: "20px", height: "20px", marginBottom: "5px" }}
                />
                <span style={{ paddingLeft: "10px" }}>Edit Q&A Log</span>
              </span>
            }
            actions={submitButton(isSubmitting, submitForm)}
          >
            <div className="settings-page">
              <Form>
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  style={{ paddingTop: 24, minHeight: 500 }}
                >
                  <Grid item>
                    <StyledTextField
                      className="form-control"
                      variant="outlined"
                      id="name"
                      name="name"
                      label="Name*"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item />
                  <Grid item />
                  <Grid item>
                    <FormControlLabel
                      label="Limit access to the selected groups"
                      control={
                        <Checkbox
                          id="restricted"
                          value={values.restricted}
                          checked={values.restricted}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "Restricted" }}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Tile>
                      <TableTitle disabled={!values.restricted}>
                        Group Access
                      </TableTitle>
                      <TableContainer>
                        <FieldArray
                          name="groupIds"
                          render={(arrayHelpers) => (
                            <>
                              {values.groupIds.length > 0 &&
                                values.groupIds.map(
                                  (groupId: string, index: number) => {
                                    const wasTouched =
                                      touched.groupIds &&
                                      Boolean(
                                        getIn(touched, `groupIds.${index}`)
                                      );
                                    const hasError =
                                      wasTouched &&
                                      Boolean(
                                        getIn(errors, `groupIds.${index}`)
                                      );
                                    return (
                                      <InputRow
                                        key={`${index}-${groupId}`}
                                        error={hasError}
                                      >
                                        <div style={{ width: "90%" }}>
                                          <TableRowSelect
                                            fullWidth
                                            InputProps={{
                                              disableUnderline: true,
                                            }}
                                            defaultValue={groupId ?? ""}
                                            name={`groupIds.${index}`}
                                            variant="standard"
                                            select
                                            onChange={handleChange}
                                            error={hasError}
                                          >
                                            <MenuItem key="" value="">
                                              ---Select a group---
                                            </MenuItem>
                                            {subject.userGroups?.map(
                                              ({ id, name }) => (
                                                <MenuItem
                                                  selected={groupId === id}
                                                  key={`${name}`}
                                                  value={id}
                                                >{`${name}`}</MenuItem>
                                              )
                                            )}
                                          </TableRowSelect>
                                        </div>
                                        <div
                                          style={{
                                            width: "10%",
                                            alignSelf: "center",
                                          }}
                                        >
                                          <DeleteButton
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          />
                                        </div>
                                      </InputRow>
                                    );
                                  }
                                )}
                              <InputRow last>
                                <SecondaryButton
                                  disabled={!values.restricted}
                                  type="button"
                                  style={{ width: "100%" }}
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  <AddIcon />
                                  <span>Add another group</span>
                                </SecondaryButton>
                              </InputRow>
                            </>
                          )}
                        />
                      </TableContainer>
                      {touched.groupIds && errors.groupIds && (
                        <TableError>Please select a group.</TableError>
                      )}
                    </Tile>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </Dialog>
        )}
      </Formik>
    </div>
  );
};
