import { useCallback, useState } from "react";

import { WorkflowStatus } from "~/api/types.generated";
import { useUpdatePortfolioMutation } from "~/components/Portfolio/PortfolioAPI.generated";
import { PortfolioHeaderFragment } from "~/components/Portfolio/PortfolioHeader.generated";

export interface UpdatePortfolio {
  isOpen: boolean;
  loading: boolean;
  status: WorkflowStatus;
  error: string | null;
  disabled: boolean;
  open(): void;
  submit(status: WorkflowStatus): Promise<void>;
  reset(): void;
  orgSlug: string;
}

/** Handles state and logic required for updating a portfolio */
export const useUpdatePortfolio = (
  orgSlug: string,
  portfolio: PortfolioHeaderFragment
): UpdatePortfolio => {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(
    portfolio.status || WorkflowStatus.Draft
  );
  const [error, setError] = useState<string | null>(null);

  const [request, { loading }] = useUpdatePortfolioMutation();

  const disabled = loading || error != null;

  const open = useCallback(() => setIsOpen(true), [setIsOpen]);

  const reset = useCallback(() => {
    setIsOpen(false);
    setStatus(portfolio.status || WorkflowStatus.Draft);
    setError(null);
  }, [portfolio.status]);

  const submit = useCallback(
    async (status: WorkflowStatus) => {
      if (disabled) return;
      try {
        const portfolioId = portfolio?.id;
        await request({ variables: { orgSlug, portfolioId, status } });
        reset();
      } catch (error) {
        setError("A server error has occurred.");
      }
    },
    [request, reset, orgSlug, portfolio, disabled]
  );

  return {
    isOpen,
    loading,
    status,
    error,
    disabled,
    open,
    submit,
    reset,
    orgSlug,
  };
};
