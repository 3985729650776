import { useState } from "react";

import { Subscribe, createPubSub } from "~/utils/pubSub";

/**
 * A simple, Redux-like store for subscribable, reducer-based state management
 *
 * Unlike a Redux provider or the useReducer hook, this store doesn't trigger
 * React component updates automatically. This is useful to manage complex
 * state that doesn't need to be global and might not need to cause React
 * components to update on each state change. Use the subscribe method to react
 * to state updates - for example, to apply CSS changes in animation frames.
 */
export interface Store<S, A> {
  /** Gets the current state value */
  getState: GetState<S>;

  /**
   * Synchronously dispatches an action and calls all subscribed listeners with the new state.
   * Also supports functional thunk actions which can dispatch additional actions asynchronously.
   */
  dispatch: Dispatch<S, A>;

  /**
   * Registers a new listener which will be called every time the state changes.
   * Returns an unsubscribe function to remove the listener.
   */
  subscribe: Subscribe<S>;
}

/** A state reducer that takes a state and an aciton object and returns a new state */
export type Reducer<S, A> = (state: S, action: A) => S;

/** A state getter function */
export type GetState<S> = () => S;

/** An action dispatcher function */
export type Dispatch<S, A> = (action: A | Thunk<S, A>) => void;

/** A functional "thunk" action type */
export type Thunk<S, A> = (dispatch: Dispatch<S, A>, getState: () => S) => void;

/** Creates a simple, subscribable Redux-like store */
export const simpleStore = <S, A extends { type: string }>(
  reducer: Reducer<S, A>,
  state: S
): Store<S, A> => {
  const { publish, subscribe } = createPubSub<S>();

  const getState: GetState<S> = () => state;

  const dispatch: Dispatch<S, A> = (action) => {
    if (typeof action === "function") {
      // Handle thunk actions
      action(dispatch, getState);
    } else {
      // Handle object actions
      state = reducer(state, action);
      publish(state);
    }
  };

  return { getState, dispatch, subscribe };
};

/** A hook to create a simple store */
export const useSimpleStore = <S, A extends { type: string }>(
  reducer: Reducer<S, A>,
  initialState: S
): Store<S, A> => {
  const [store] = useState(() => simpleStore(reducer, initialState));
  return store;
};
