import { createContext, useMemo, useState } from "react";

import { Direction, Ordering, createOrdering } from "~/utils/ordering";

export enum Field {
  NAME = "name",
  ROLE = "role__name",
  MEMBER_COUNT = "member_count",
}

export interface OrderingContextValue {
  ordering: Ordering<Field>;
  setOrdering: (ordering: Ordering<Field>) => void;
  serializeOrdering: (ordering: Ordering<Field>) => string;
}

export const useOrderingContext = (): OrderingContextValue => {
  const [ordering, setOrdering] = useState(createOrdering(Field.NAME));

  return useMemo(() => {
    const serializeOrdering = (orderObj: Ordering<Field>): string => {
      return orderObj.direction === Direction.ASC
        ? orderObj.field
        : `-${orderObj.field}`;
    };
    return { ordering, setOrdering, serializeOrdering };
  }, [ordering, setOrdering]);
};

const OrderingContext = createContext<OrderingContextValue>(null as any);

export default OrderingContext;
