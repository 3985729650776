import $ from "jquery";

export default function initAdmin() {
  const setTabIndex = () => $(this).attr("tabindex", -1);

  $("a").not(".deletelink").each(setTabIndex);
  $("form").focusout(() => $("a").not(".deletelink").each(setTabIndex));

  const cleanCommas = () => {
    const current = $(this).val();
    $(this).val(current.replace(/[ ,]/g, ""));
  };
  $("input.clean-thousand-separator-js").on("input", cleanCommas);
}
