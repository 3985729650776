import { useCallback } from "react";

import { DocumentsCurrent } from "./useDocumentsCurrent";
import { DocumentsLinks } from "./useDocumentsLinks";
import { DocumentsRouting } from "./useDocumentsRouting";

export interface DocumentsSearchActions {
  setSearchTerm(searchTerm: string): void;
  toggleSearchAll(searchAll?: boolean): void;
  clearSearch(): void;
}

/** Creates handlers for mutating the current search state */
export const useDocumentsSearchActions = (
  { rootId, history }: DocumentsRouting,
  { getBasePath }: DocumentsLinks,
  { folder }: DocumentsCurrent
): DocumentsSearchActions => {
  /** Updates the search term state via the "q" query parameter */
  const setSearchTerm = useCallback(
    (searchTerm: string) => {
      const folderPath = getBasePath(folder?.id ?? rootId);
      const queryParams = new URLSearchParams(history.location.search);
      const prevTerm = queryParams.get("q");
      searchTerm ? queryParams.set("q", searchTerm) : queryParams.delete("q");
      const url = `${folderPath}?${queryParams.toString()}`;
      searchTerm && prevTerm ? history.replace(url) : history.push(url);
    },
    [history, getBasePath, rootId, folder?.id]
  );

  /** Toggles the "search all" state via the "qs" query param */
  const toggleSearchAll = useCallback(
    (searchAll?: boolean) => {
      const queryParams = new URLSearchParams(history.location.search);
      const prevSearchAll = queryParams.get("qs") === "all";
      searchAll ?? !prevSearchAll
        ? queryParams.set("qs", "all")
        : queryParams.delete("qs");
      history.push(`${history.location.pathname}?${queryParams.toString()}`);
    },
    [history]
  );

  /** Resets the search state */
  const clearSearch = useCallback(() => {
    setSearchTerm("");
    toggleSearchAll(false);
  }, [setSearchTerm, toggleSearchAll]);

  return { setSearchTerm, toggleSearchAll, clearSearch };
};
