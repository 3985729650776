import clsx from "clsx";
import { LocationDescriptorObject } from "history";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { PermissionsSetup } from "~/api/types.generated";
import { Checkbox } from "~/components/common/inputs";
import { SkeletonCheckbox, SkeletonText } from "~/components/common/skeletons";
import { EmptyRow, Table } from "~/components/common/tables";
import { RolesTrunc } from "~/components/Documents/RolesTrunc";
import { lightBlue } from "~/styles/theme/color";
import { formatBytes, formatDateAgo } from "~/utils/formats";

import {
  Document_BreadcrumbsFragment,
  Document_MetaFragment,
  Document_PermissionsFragment,
} from "./DocumentsAPI.generated";
import DocumentsThumbnail from "./DocumentsThumbnail";
import { DocumentsTableData } from "./useDocumentsTableData";
import { sortByName } from "./utils";

type Data = Document_BreadcrumbsFragment &
  Document_MetaFragment &
  Document_PermissionsFragment;

export interface DocumentsTableProps extends DocumentsTableData {
  permissionsType?: PermissionsSetup | null;
  isDocumentsAdmin: boolean;
  areLabelsEnabled: boolean;
  disableLink: (documentId: string) => boolean;
  renderDocumentMenu: (document: Data) => React.ReactNode;
  renderDocumentName: (document: Data) => React.ReactNode;
  getLink: (documentId: string) => LocationDescriptorObject;
}

const skeletonData = [1, 2, 3, 4, 5].map(
  (n) => ({ id: `skeleton-${n}` } as Data)
);

const hexToRGBLabels = (hexColor: string) => {
  if (hexColor.length !== 7) return { r: 0, g: 0, b: 0 };
  return {
    r: parseInt("0x" + hexColor[1] + hexColor[2]),
    g: parseInt("0x" + hexColor[3] + hexColor[4]),
    b: parseInt("0x" + hexColor[5] + hexColor[6]),
  };
};

const hexToHSLLabels = (hexColor: string) => {
  let { r, g, b } = hexToRGBLabels(hexColor);
  // Make r, g, and b fractions of 1
  r /= 255;
  g /= 255;
  b /= 255;

  // Find greatest and smallest channel values
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;
  // Calculate hue
  // No difference
  if (delta === 0) h = 0;
  // Red is max
  else if (cmax === r) h = ((g - b) / delta) % 6;
  // Green is max
  else if (cmax === g) h = (b - r) / delta + 2;
  // Blue is max
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  // Make negative hues positive behind 360°
  if (h < 0) h += 360;

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Multiply l and s by 100
  s = Math.round(+(s * 100).toFixed(1));
  l = Math.round(+(l * 100).toFixed(1));
  return {
    h: h,
    s: s,
    l: l,
  };
};

export const DocumentsTable = React.memo(function DocumentsTable(
  props: DocumentsTableProps
) {
  const {
    data,
    selection,
    highlightedId,
    permissionsType,
    disableLink,
    renderDocumentMenu,
    renderDocumentName,
    getLink,
    isDocumentsAdmin,
    areLabelsEnabled,
  } = props;

  const documents = useMemo(
    () => (data ? [...data].sort(sortByName) : skeletonData),
    [data]
  );
  const loading = documents === skeletonData;
  const empty = !loading && documents.length === 0;

  const principalsHeader =
    permissionsType === PermissionsSetup.Group
      ? "Group"
      : permissionsType && "Roles";
  const labelsColumn =
    areLabelsEnabled &&
    column("labels", true, "Labels", ({ labels }) => {
      if (!labels) return "-";
      // return labels.join(',');
      return (
        <LabelWrapper>
          {labels.map(({ label, color }) => (
            <Label
              key={label}
              {...hexToRGBLabels(color)}
              {...hexToHSLLabels(color)}
            >
              <LabelText>{label}</LabelText>
            </Label>
          ))}
        </LabelWrapper>
      );
    });
  const principalsColumn =
    isDocumentsAdmin &&
    column("principals", true, principalsHeader, ({ permissions }) => {
      if (!permissions) return <SkeletonText />;
      return (
        <RolesTrunc
          roles={permissions
            .filter((perm) => perm?.permissible)
            .map((perm) => perm?.principal?.name ?? "")}
        />
      );
    });

  const columns = [
    column(
      "selection",
      false,
      !empty && !loading && (
        <Checkbox
          checked={selection.allSelected}
          indeterminate={selection.anySelected && !selection.allSelected}
          onChange={() => selection.toggleAll()}
        />
      ),
      (document) =>
        document.id.startsWith("skeleton") ? (
          <SkeletonCheckbox />
        ) : (
          <Checkbox
            checked={selection.includes(document)}
            onChange={() => selection.toggle(document)}
          />
        )
    ),
    column("thumbnail", true, "", (document) => (
      <DocumentsThumbnail document={document} />
    )),
    column("name", true, "Name", (document) => renderDocumentName(document)),
    ...(labelsColumn ? [labelsColumn] : []),
    ...(principalsColumn ? [principalsColumn] : []),
    column("size", true, "Size", ({ size }) => {
      if (size === undefined) return <SkeletonText />;
      if (size === null) return "-";
      return formatBytes(size);
    }),
    column("modified", true, "Last Modified", ({ modifiedTimestamp }) => {
      if (modifiedTimestamp === undefined) return <SkeletonText />;
      if (modifiedTimestamp === null) return "-";
      return formatDateAgo(modifiedTimestamp);
    }),
    column("actions", false, "", renderDocumentMenu),
  ];

  const table = (
    <StyledTable>
      <thead>
        <tr>
          {columns.map(({ name, header }) => (
            <th key={name} className={`${name}-cell`}>
              <div className={`${name}-cell`}>{header}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {documents.map((document) => (
          <tr
            key={document.id}
            className={clsx(document.id === highlightedId && "highlight")}
          >
            {columns.map(({ name, link, cell }) => (
              <td key={name} className={`${name}-cell`}>
                {link &&
                !disableLink(document.id) &&
                !document.id.startsWith("skeleton") ? (
                  <Link className={`${name}-cell`} to={getLink(document.id)}>
                    {cell(document)}
                  </Link>
                ) : (
                  <div className={`${name}-cell`}>{cell(document)}</div>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );

  return (
    <Root>
      {table}
      {empty && <EmptyRow>This folder is empty</EmptyRow>}
    </Root>
  );
});

const column = (
  name: string,
  link: boolean,
  header: React.ReactNode,
  cell: (document: Data) => React.ReactNode
) => ({ name, link, header, cell });

const Root = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled(Table)`
  tr.highlight {
    background-color: ${lightBlue};
  }
  .selection-cell {
    width: 2.5rem;
    justify-content: center;
  }
  .thumbnail-cell {
    width: 3rem;
  }
  td.name-cell {
    max-width: 20rem;
  }
  .size-cell {
    justify-content: flex-end;
  }
  .modified-cell {
    justify-content: flex-end;
  }
  .actions-cell {
    width: 3rem;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4px;
`;

const Label = styled.div<{
  r: number;
  g: number;
  b: number;
  h: number;
  s: number;
  l: number;
}>`
  display: inline-block;
  padding: 0 7px;
  font-size: 12px;
  font-weight: var(--base-text-weight-medium, 500);
  line-height: 18px;
  white-space: nowrap;
  border-radius: 2em;
  --lightness-threshold: 0.6;
  --background-alpha: 0.18;
  --border-alpha: 0.3;
  --lighten-by: calc(
    ((var(--lightness-threshold) - var(--perceived-lightness)) * 100) *
      var(--lightness-switch)
  );
  color: hsl(
    ${(props) => props.h},
    calc(${(props) => props.s} * 1%),
    calc((${(props) => props.l} + var(--lighten-by)) * 1%)
  );
  background: rgba(
    ${(props) => props.r},
    ${(props) => props.g},
    ${(props) => props.b},
    var(--background-alpha)
  );
  border-color: hsla(
    ${(props) => props.h},
    calc(${(props) => props.s} * 1%),
    calc((${(props) => props.l} + var(--lighten-by)) * 1%),
    var(--border-alpha)
  );
  --perceived-lightness: calc(
    (
        (${(props) => props.r} * 0.2126) + (${(props) => props.g} * 0.7152) +
          (${(props) => props.b} * 0.0722)
      ) / 255
  );
  --lightness-switch: max(
    0,
    min(
      calc((1 / (var(--lightness-threshold) - var(--perceived-lightness)))),
      1
    )
  );
`;

const LabelText = styled.span`
  display: inline-block;
  max-width: 125px;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
