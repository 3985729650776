// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from './api/types.generated';

import { NavbarViewerFragment } from './Navbar.generated';
import { FooterViewerFragment } from './Footer.generated';
import { HomePageViewerFragment } from './components/HomePage/HomePage.generated';
import { SettingsPageViewerFragment } from './components/Settings/SettingsPage.generated';
import { gql } from '@apollo/client';
import { NavbarViewerFragmentDoc } from './Navbar.generated';
import { FooterViewerFragmentDoc } from './Footer.generated';
import { HomePageViewerFragmentDoc } from './components/HomePage/HomePage.generated';
import { SettingsPageViewerFragmentDoc } from './components/Settings/SettingsPage.generated';
import * as Apollo from '@apollo/client';
export type ViewerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ViewerQuery = (
  { readonly __typename: 'Query' }
  & { readonly viewer?: Types.Maybe<(
    { readonly __typename: 'User' }
    & Pick<Types.User, 'id'>
    & NavbarViewerFragment
    & FooterViewerFragment
    & HomePageViewerFragment
    & SettingsPageViewerFragment
  )> }
);


export const ViewerDocument = gql`
    query Viewer {
  viewer {
    id
    ...NavbarViewer
    ...FooterViewer
    ...HomePageViewer
    ...SettingsPageViewer
  }
}
    ${NavbarViewerFragmentDoc}
${FooterViewerFragmentDoc}
${HomePageViewerFragmentDoc}
${SettingsPageViewerFragmentDoc}`;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
        return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, baseOptions);
      }
export function useViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
          return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, baseOptions);
        }
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<ViewerQuery, ViewerQueryVariables>;