import { LocationDescriptorObject } from "history";
import { compact } from "lodash";
import { ellipsis } from "polished";
import React, { useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { SecondaryButton } from "~/components/common/buttons";
import { TriangleIcon } from "~/components/common/icons";
import { Panel, PanelHead } from "~/components/common/panels";
import { SkeletonText, SkeletonThumbnail } from "~/components/common/skeletons";
import { Label, Title } from "~/components/common/text";
import { Toolbar } from "~/components/common/toolbars";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";
import {
  bgColor,
  borderRight,
  borderTop,
  centerContent,
  centerVertically,
  fgColor,
} from "~/styles/mixins";
import { formatBytes, formatDateAgo } from "~/utils/formats";

import {
  Document_BreadcrumbsFragment,
  DocumentsColumnsQuery,
} from "./DocumentsAPI.generated";
import { DocumentsPreview } from "./DocumentsPreview";
import DocumentsThumbnail from "./DocumentsThumbnail";
import { View } from "./useDocumentsRouting";
import { sortByName } from "./utils";

export interface DocumentsColumnsProps {
  document?: DocumentsColumnsQuery["document"];
  track: DocumentsEventTracking;
  getLink: (documentId: string, view?: View) => LocationDescriptorObject;
  renderDocumentMenu: (
    document: Document_BreadcrumbsFragment
  ) => React.ReactNode;
  renderDocumentName: (
    document: Document_BreadcrumbsFragment
  ) => React.ReactNode;
}

export const DocumentsColumns = React.memo(function DocumentsColumns(
  props: DocumentsColumnsProps
) {
  const { document, track, getLink, renderDocumentMenu, renderDocumentName } =
    props;

  const container = useRef<HTMLDivElement>(null);

  // Scroll all the way to the right when a new document is selected/loaded
  useEffect(() => {
    const el = container.current;
    if (el) el.scrollLeft = el.scrollWidth - el.offsetWidth;
  }, [document]);

  const ancestorColumns = useMemo(
    () =>
      compact(document?.ancestors ?? []).map((ancestor, index) =>
        ancestor.children ? (
          <Column key={ancestor.id}>
            {compact(ancestor.children)
              .sort(sortByName)
              .map((child) => (
                <Row
                  key={child.id}
                  to={getLink(child.id)}
                  replace
                  isSelected={Boolean(
                    document?.ancestors?.find((d) => d?.id === child.id)
                  )}
                  isCurrent={document?.id === child.id}
                >
                  <RowIcon>
                    <DocumentsThumbnail document={child} />
                  </RowIcon>
                  <RowText>{child.name}</RowText>{" "}
                  {child.fileType === "Folder" && <TriangleIcon />}
                </Row>
              ))}
          </Column>
        ) : (
          <SkeletonColumn key={`skeleton-${index}`} />
        )
      ),
    [getLink, document?.id, document?.ancestors]
  );

  const fileColumn = document?.fileType === "File" && (
    <FileColumn key={document.id}>
      <FileColumnPreview key={document.id} document={document} />
      <FileColumnToolbar>
        <SecondaryButton
          as={Link}
          to={getLink(document.id, "single")}
          onClick={() => {
            track.previewClick();
          }}
        >
          Preview
        </SecondaryButton>
        {renderDocumentMenu(document)}
      </FileColumnToolbar>
      <FileMeta>
        <FileMetaGroup>
          <FileMetaSection>
            <Title>{renderDocumentName(document)}</Title>
            <Label>Document</Label>
          </FileMetaSection>
        </FileMetaGroup>
        <FileMetaGroup>
          <FileMetaSection>
            <Title>Roles</Title>
            {document.permissions
              ? compact(document.permissions)
                  .filter((permission) => permission.permissible)
                  .map((permission) => (
                    <Label key={permission.id}>
                      {permission.principal?.name ?? ""}{" "}
                    </Label>
                  ))
              : [1, 2, 3].map((n) => (
                  <Label key={n}>
                    <SkeletonText />
                  </Label>
                ))}
          </FileMetaSection>
          <FileMetaSection>
            <Title>Information</Title>
            <Label>
              {document.size != null ? (
                <>Size: {formatBytes(document.size)}</>
              ) : (
                <SkeletonText />
              )}
            </Label>
            <Label>
              {document.modifiedTimestamp ? (
                <>Modified: {formatDateAgo(document.modifiedTimestamp)}</>
              ) : (
                <SkeletonText />
              )}
            </Label>
          </FileMetaSection>
        </FileMetaGroup>
      </FileMeta>
    </FileColumn>
  );

  const folderColumn = useMemo(
    () =>
      document?.fileType === "Folder" &&
      (document.children ? (
        <Column>
          {document.children.length === 0 ? (
            <EmptyFolder>
              <Title>This folder is empty</Title>
            </EmptyFolder>
          ) : (
            compact(document.children)
              .sort(sortByName)
              .map((child) => (
                <Row key={child.id} to={getLink(child.id)} replace>
                  <RowIcon>
                    <DocumentsThumbnail document={child} />
                  </RowIcon>
                  <RowText>{child.name}</RowText>
                  {child.fileType === "Folder" && <TriangleIcon />}
                </Row>
              ))
          )}
        </Column>
      ) : (
        <SkeletonColumn />
      )),
    [getLink, document?.fileType, document?.children]
  );

  return (
    <Panel>
      <ColumnsPanelHead>
        <div>{document?.name ?? <SkeletonText />}</div>
      </ColumnsPanelHead>
      <ColumnsContainer ref={container}>
        <Columns>
          {ancestorColumns}
          {fileColumn}
          {folderColumn}
          {!document?.fileType && <SkeletonColumn />}
        </Columns>
      </ColumnsContainer>
    </Panel>
  );
});

const SkeletonColumn = () => (
  <Column>
    {[1, 2, 3, 4, 5].map((row) => (
      <Row key={row} to="#">
        <RowIcon>
          <SkeletonThumbnail />
        </RowIcon>
        <RowText>
          <SkeletonText />
        </RowText>
      </Row>
    ))}
  </Column>
);

const ColumnsPanelHead = styled(PanelHead)`
  ${centerContent};
  & > div {
    ${ellipsis()};
  }
`;

const ColumnsContainer = styled.div`
  overflow-x: auto;
`;

const Columns = styled.div`
  height: 39rem;
  display: flex;
`;

const Column = styled.div`
  ${borderRight.gray300()};
  align-self: stretch;
  flex-shrink: 0;
  overflow-y: auto;
  min-width: 15rem;
  max-width: 30rem;
`;

const Row = styled(Link).withConfig<{
  isSelected?: boolean;
  isCurrent?: boolean;
}>({
  shouldForwardProp: (prop) => !["isSelected", "isCurrent"].includes(prop),
})`
  ${centerVertically};
  ${fgColor.gray800()};
  display: flex;
  height: 2.5rem;
  padding: 0 0.5rem;

  ${(props) => props.isSelected && bgColor.gray100()};
  ${(props) => props.isCurrent && bgColor.lightBlue()};

  &:hover {
    text-decoration: none;
  }
`;

const RowIcon = styled.div`
  flex: 0;
  margin-right: 0.5rem;
`;

const RowText = styled.div`
  ${ellipsis()};
  flex: 1;
`;

const FileColumn = styled(Column)`
  border: none;
  flex: 1 0 26rem;
  min-width: 26rem;
  max-width: none;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FileColumnPreview = styled(DocumentsPreview)`
  ${centerContent};
  width: 16rem;
  height: 20.7rem;
`;

const FileColumnToolbar = styled(Toolbar)`
  flex: 0 0 auto;
  margin-bottom: 0.75rem;
`;

const FileMeta = styled.div`
  ${borderTop.gray300()};
  width: 24rem;
  padding-top: 0.75rem;
`;

const FileMetaGroup = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
`;

const FileMetaSection = styled.div`
  max-width: 100%;
  flex: 1;
`;

const EmptyFolder = styled.div`
  ${centerContent};
  height: 2.5rem;
`;
