import Icon from "components/icons/Icon";
import * as PropTypes from "prop-types";
import React from "react";

class AlertIcon extends React.PureComponent {
  render() {
    return (
      <Icon
        color={this.props.color}
        withoutBackground={this.props.withoutBackground}
        viewBox="0 0 16 16"
        className={this.props.className}
      >
        <path
          fillRule="evenodd"
          d="M7.99957528,14 C8.82770028,14 9.49957528,13.328125 9.49957528,12.5 C9.49957528,11.671875
                    8.82770028,11 7.99957528,11 C7.17145028,11 6.49957528,11.671875 6.49957528,12.5
                    C6.49957528,13.328125 7.17145028,14 7.99957528,14 Z M15.5777003,12.1875 C16.6792628,14.28125
                    15.5620753,16 13.1089503,16 L2.89020028,16 C0.437075279,16 -0.672299721,14.28125
                    0.421450279,12.1953125 L5.99957528,1.5703125 C6.55426278,0.5234375 7.27301278,0 7.99957528,0
                    C8.72613778,0 9.45270028,0.5234375 9.99957528,1.5703125 L15.5777003,12.1875 Z M7.49957528,3
                    C6.95270028,3 6.53082528,3.4453125 6.56988778,3.9921875 L6.92926278,9 C6.96832528,9.546875
                    7.45270028,10 7.99957528,10 C8.54645028,10 9.03082528,9.546875 9.06988778,9 L9.42926278,3.9921875
                    C9.46832528,3.4453125 9.05426278,3 8.49957528,3 L7.49957528,3 Z"
        />
      </Icon>
    );
  }
}

AlertIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  withoutBackground: PropTypes.bool,
};

AlertIcon.defaultProps = {
  className: null,
  color: "orange",
  withoutBackground: false,
};

export default AlertIcon;
