import gql from "graphql-tag";
import { compact } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { FileFolderIcon } from "~/components/common/icons";
import { Checkbox } from "~/components/common/inputs";
import { PrimaryPanel } from "~/components/common/panels";
import { EmptyRow, Table } from "~/components/common/tables";
import { Thumbnail } from "~/components/Documents/DocumentsThumbnail";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { blue } from "~/styles/theme/color";

import { REPORTING_BANNER_FRAGMENTS, ReportingBanner } from "./ReportingBanner";
import { ReportingListFragment } from "./ReportingList.generated";

export const REPORTING_LIST_FRAGMENT = gql`
  fragment ReportingList on Query {
    reportingOperatingStats(orgSlug: $orgSlug) {
      ...ReportingBannerOperatingStats
    }
    reportingProjectsStats(orgSlug: $orgSlug) {
      ...ReportingBannerProjectsStats
    }
    reportingHoldingCompanies(orgSlug: $orgSlug) {
      id
      name
      slug
    }
  }

  ${REPORTING_BANNER_FRAGMENTS}
`;

export interface ReportingListProps {
  orgSlug: string;
  data: ReportingListFragment | null | undefined;
}

export const ReportingList = ({ orgSlug, data }: ReportingListProps) => {
  const history = useHistory();
  const holdcos = compact(data?.reportingHoldingCompanies);
  const empty = holdcos.length === 0;
  return (
    <>
      <ReportingBanner
        operatingStats={data?.reportingOperatingStats}
        projectsStats={data?.reportingProjectsStats}
        showAdmin={false}
      />
      {data ? (
        <PrimaryPanel>
          <Title>Reports</Title>
          <div className="table-overflow-wrapper entries-table-wrapper">
            <StyledTable>
              <thead>
                <tr>
                  <th />
                  <th style={{ minWidth: 50, width: 50 }} />
                  <th className="name">Name</th>
                  <th style={{ minWidth: 50, width: 50 }} />
                  <th style={{ minWidth: 50, width: 50 }} />
                  <th style={{ minWidth: 50, width: 50 }} />
                  <th style={{ minWidth: 50, width: 50 }} />
                  <th style={{ minWidth: 50, width: 50 }} />
                </tr>
              </thead>
              <tbody>
                {!empty &&
                  holdcos.map((holdco) => (
                    <tr
                      key={holdco.id}
                      onClick={() =>
                        history.push(
                          `/${orgSlug}/reporting/${holdco.slug}/reports`
                        )
                      }
                    >
                      <td className="selection-cell">
                        <Checkbox disabled />
                      </td>
                      <td className="thumbnail">
                        <Thumbnail as={FileFolderIcon} />
                      </td>
                      <td className="name">{holdco.name}</td>
                      <td className="pl-2" />
                      <td className="pl-2" />
                      <td className="pl-2" />
                      <td className="pl-2" />
                      <td className="pl-2" />
                    </tr>
                  ))}
              </tbody>
            </StyledTable>
            {empty && <EmptyRow>No reporting data.</EmptyRow>}
          </div>
        </PrimaryPanel>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <LoadingSpinner big />
        </div>
      )}
    </>
  );
};

const StyledTable = styled(Table)`
  td {
    height: 3rem;
    cursor: pointer;

    &:hover {
      &.name {
        color: ${blue};
      }
    }
  }

  .selection-cell {
    width: 2.5rem;
    text-align: center;
  }

  .thumbnail {
    width: 3rem;
    text-align: center;
  }

  .name {
    padding-left: 0.5rem;
  }
`;

const Title = styled.div`
  padding: 0;
  margin-bottom: 12px;
  font-size: 1.5rem;
`;
