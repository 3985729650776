// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type ApplicationsViewerFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'hasSalesforceToken'>
);

export const ApplicationsViewerFragmentDoc = gql`
    fragment ApplicationsViewer on User {
  id
  hasSalesforceToken
}
    `;