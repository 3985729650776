// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { DealRoomCard_HoldingCompany_Fragment, DealRoomCard_Portfolio_Fragment } from './DealRoomCard.generated';
import { gql } from '@apollo/client';
import { DealRoomCardFragmentDoc } from './DealRoomCard.generated';
export type DealRoomGridFragment = (
  { readonly __typename: 'FundEntityCollection' }
  & { readonly entities?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'HoldingCompany' }
    & DealRoomCard_HoldingCompany_Fragment
  ) | (
    { readonly __typename: 'Portfolio' }
    & DealRoomCard_Portfolio_Fragment
  )>>> }
);

export const DealRoomGridFragmentDoc = gql`
    fragment DealRoomGrid on FundEntityCollection {
  entities {
    ...DealRoomCard
  }
}
    ${DealRoomCardFragmentDoc}`;