import throttle from "lodash.throttle";
/* global google */
import { gettext } from "utils/text";

function NetYieldChart(containerId, rows) {
  // Load the Visualization API and the corechart package.
  google.charts.load("current", { packages: ["corechart", "line"] });

  const drawChart = () => {
    const container = document.getElementById(containerId);

    // if no container was found, just return
    if (!container) {
      return;
    }

    // Set chart options
    const options = {
      curveType: "function",
      chartArea: {
        left: 100,
        right: 210,
        height: 400,
      },
      focusTarget: "category",
      height: 500,
      colors: ["#F4865C", "#27A4BF", "#8A61A3"],
      hAxis: {
        title: gettext("Year"),
        baselineColor: "#9B9B9B",
        minTextSpacing: 1,
        showTextEvery: 1,
        // displays the axis label every 5 years and for the last one
        ticks: rows.map((r, index) =>
          r[0] % 5 === 0 || index + 1 === rows.length
            ? r[0]
            : { v: r[0], f: "" }
        ),
        gridlines: {
          color: "#D8D8D8",
        },
      },
      vAxis: {
        title: gettext("kWh"),
        minValue: 0,
        baselineColor: "#9B9B9B",
        gridlines: {
          color: "#D8D8D8",
        },
      },
    };

    if (container.offsetWidth < 700) {
      delete options.chartArea.right;
      options.chartArea.width = "80%";
      options.legend = { position: "top", alignment: "start" };
    }

    const data = new google.visualization.DataTable();
    data.addColumn("number", "X");
    data.addColumn("number", "Actual Energy (kWh)");
    data.addColumn("number", "Net Yield P50 (kWh)");
    data.addColumn("number", "Net Yield P90 (kWh)");

    data.addRows(rows);

    const chart = new google.visualization.LineChart(container);
    chart.draw(data, options);
  };

  const setUp = () => {
    drawChart();
    window.addEventListener("resize", throttle(drawChart, 200));
    // stupid IE
    window.addEventListener("DOMAttrModified", throttle(drawChart, 200));
  };

  // Set a callback to run when the Google Visualization API is loaded.
  google.charts.setOnLoadCallback(setUp);
}

export default NetYieldChart;
