import * as rect from "~/utils/rect";
import type { Rect } from "~/utils/rect";

export interface Anchor {
  sides: Rect;
  cursor: string;
}

export const anchors = [
  { sides: rect.values(0, 1, 0, 0), cursor: "ns-resize" },
  { sides: rect.values(0, 0, 0, 1), cursor: "ns-resize" },
  { sides: rect.values(1, 0, 0, 0), cursor: "ew-resize" },
  { sides: rect.values(0, 0, 1, 0), cursor: "ew-resize" },
  { sides: rect.values(1, 1, 0, 0), cursor: "nwse-resize" },
  { sides: rect.values(0, 0, 1, 1), cursor: "nwse-resize" },
  { sides: rect.values(0, 1, 1, 0), cursor: "nesw-resize" },
  { sides: rect.values(1, 0, 0, 1), cursor: "nesw-resize" },
];
