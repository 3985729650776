import styled from "styled-components";

const StatLabel = styled.div<{ color?: string }>`
  color: ${(props) => props.color};
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
`;

StatLabel.defaultProps = {
  color: "#C0C0C0",
};

export default StatLabel;
