// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { PortfolioHeaderFragment } from './PortfolioHeader.generated';
import { PortfolioBodyFragment } from './PortfolioBody.generated';
import { gql } from '@apollo/client';
import { PortfolioHeaderFragmentDoc } from './PortfolioHeader.generated';
import { PortfolioBodyFragmentDoc } from './PortfolioBody.generated';
import * as Apollo from '@apollo/client';
export type PortfolioPageQueryVariables = Types.Exact<{
  slug: Types.Scalars['String'];
}>;


export type PortfolioPageQuery = (
  { readonly __typename: 'Query' }
  & { readonly portfolio?: Types.Maybe<(
    { readonly __typename: 'Portfolio' }
    & PortfolioHeaderFragment
    & PortfolioBodyFragment
  )> }
);


export const PortfolioPageDocument = gql`
    query PortfolioPage($slug: String!) {
  portfolio(slug: $slug) {
    ...PortfolioHeader
    ...PortfolioBody
  }
}
    ${PortfolioHeaderFragmentDoc}
${PortfolioBodyFragmentDoc}`;

/**
 * __usePortfolioPageQuery__
 *
 * To run a query within a React component, call `usePortfolioPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePortfolioPageQuery(baseOptions: Apollo.QueryHookOptions<PortfolioPageQuery, PortfolioPageQueryVariables>) {
        return Apollo.useQuery<PortfolioPageQuery, PortfolioPageQueryVariables>(PortfolioPageDocument, baseOptions);
      }
export function usePortfolioPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortfolioPageQuery, PortfolioPageQueryVariables>) {
          return Apollo.useLazyQuery<PortfolioPageQuery, PortfolioPageQueryVariables>(PortfolioPageDocument, baseOptions);
        }
export type PortfolioPageQueryHookResult = ReturnType<typeof usePortfolioPageQuery>;
export type PortfolioPageLazyQueryHookResult = ReturnType<typeof usePortfolioPageLazyQuery>;
export type PortfolioPageQueryResult = Apollo.QueryResult<PortfolioPageQuery, PortfolioPageQueryVariables>;