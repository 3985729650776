import { differenceInDays, max } from "date-fns";
import { ellipsis } from "polished";
import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";

import { bgColor, borderRadius, fgColor, focusOutline } from "~/styles/mixins";
import { GetPropsWithoutRef } from "~/utils/types";

import { ChildQuestion, ParentQuestion } from "../context/QuestionContext";

export type Status = "all" | "open" | "closed" | "priority" | "new";

export interface StatusFilterProps
  extends Omit<GetPropsWithoutRef<"div">, "onSelect"> {
  questions: Array<ParentQuestion | ChildQuestion>;
  value: Status;
  onSelect: (status: Status) => unknown;
}

const StatusFilter = ({
  questions,
  value,
  onSelect,
  ...rest
}: StatusFilterProps) => {
  const now = useMemo(() => new Date(), []);

  const isNewOrUpdated = useCallback(
    (question: ParentQuestion | ChildQuestion) => {
      const viewedAt = max(
        question.viewedAt,
        question.modifiedAt,
        question.answer.modifiedAt
      );
      const isNew = differenceInDays(now, question.firstViewedAt) < 1;
      const isUpdated = !isNew && differenceInDays(now, viewedAt) < 1;
      return isNew || isUpdated;
    },
    [now]
  );

  const options = useMemo(
    () => [
      option("open", "Open", questions.filter((q) => !q.isClosed).length),
      option("closed", "Closed", questions.filter((q) => q.isClosed).length),
      option(
        "priority",
        "Priority",
        questions.filter((q) => !q.isClosed && q.isPriority).length
      ),
      option("new", "New", questions.filter(isNewOrUpdated).length),
      option("all", "All", questions.length),
    ],
    [questions, isNewOrUpdated]
  );

  return (
    <div {...rest}>
      {options.map(({ key, name, count }) => (
        <Option
          key={key}
          selected={value === key}
          disabled={value === key || count < 1}
          onClick={() => onSelect(key)}
        >
          {count} {name}
        </Option>
      ))}
    </div>
  );
};

const option = (key: Status, name: string, count: number) => ({
  key,
  name,
  count,
});

const Option = styled.button<{ selected?: boolean }>`
  ${borderRadius()};
  ${focusOutline()};
  ${bgColor.transparent()};
  ${fgColor.black()};
  ${ellipsis()};
  height: 1.75rem;
  line-height: 1.75rem;
  padding: 0 0.5rem;
  border: none;
  margin-right: 0.25rem;
  white-space: nowrap;

  ${(props) =>
    props.selected &&
    css`
      ${bgColor.darkBlue()};
      ${fgColor.white()};
    `}

  &:hover:not([disabled]) {
    background-color: ${(props) => props.theme.color.gray300};
  }

  &:last-child {
    margin-right: 0;
  }
`;

export default styled(StatusFilter)`
  display: flex;
  margin-right: auto;
`;
