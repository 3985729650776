import { useCallback, useEffect, useRef, useState } from "react";

/**
 * Check if two arrays are equal. Supports mainly arrays of primitive types
 * (arrays of strings or numbers work well).
 *
 * Taken from a useful StackOverflow answer:
 * https://stackoverflow.com/a/16436975/7044732
 *
 * @param {Array<number|string>} a
 * @param {Array<number|string>} b
 *
 * @returns {boolean}
 */
const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // We don't care about the order of the items in the array, so we can sort
  // both arrays and then check each of their items.
  const sortedA = [...a].sort();
  const sortedB = [...b].sort();

  for (let i = 0; i < a.length; ++i) {
    if (sortedA[i] !== sortedB[i]) return false;
  }

  return true;
};

/**
 * The different ways a state can be changed inside the useFilterDropdown hook.
 *
 * Whenever a change is triggered (e.g., the user selects an item or writes
 * something in the filtering input), then state is set inside the
 * useFilterDropdown hook. In addition, if the caller opts into using the state
 * reducer prop, then the caller can make their own adjustments to the state
 * setting process. In order to do this effectively, the caller needs to know
 * what exactly changed and what triggered the state change. For example, they
 * would want to know if the user clicked on an item or if they changed the
 * filter search text.
 *
 * In order to help the caller in their state reducer implementation, the type
 * of action is given to the state reducer. This means that the caller can
 * react to specific types of events inside their state reducer. For example,
 * something like "if itemClicked, close dropdown" can be done. It works the
 * same way as Redux actions do, there is a type associated with each action
 * and a reducer is used to react to the type of action.
 */
export const stateChangeTypes = {
  itemClicked: 1,
  setSelected: 2,
  inputChanged: 3,
  buttonClicked: 4,
  controlledPropsUpdateSelected: 5,
};

/**
 * Some state reducer examples/recipies for common actions. Some of these can
 * even be composed together like so:
 *
 * ```
 * stateReducer={(state, nextState) => stateReducerExamples.closeAfterSelected(
 *     state, stateReducerExamples.allowExactlyOneSelection(state, nextState),
 * )}
 * ```
 */
export const stateReducerExamples = {
  /**
   * Close the dropdown after a selection has been made.
   * @type {StateReducer}
   */
  closeAfterSelected: (_state, nextState) => {
    switch (nextState.type) {
      case stateChangeTypes.itemClicked:
        return {
          ...nextState,
          isOpen: false,
        };

      default:
        return nextState;
    }
  },

  /**
   * Allow exactly one selected item. If a new item is selected, set that
   * item as the selected one.
   * @type {StateReducer}
   */
  allowExactlyOneSelection: (state, nextState) => {
    switch (nextState.type) {
      case stateChangeTypes.itemClicked:
        return {
          ...nextState,
          selected:
            nextState.selected.length > 1
              ? [nextState.selected[nextState.selected.length - 1]]
              : state.selected, // Do not allow 0 selected items
        };

      default:
        return nextState;
    }
  },
};

/**
 * Custom hook to keep track of the previous value. Need this in order to check
 * if some value in a hook has changed.
 * @template T {any}
 * @param {T} value
 * @returns {T}
 */
const usePrevious = (value) => {
  /** @type {React.MutableRefObject<T>} */
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * @typedef {object} State
 * @property {string} keyword
 * @property {Array<number|string>} selected
 * @property {boolean} isOpen
 */

/**
 * @typedef {object} Action
 * @property {number} type
 */

/**
 * @typedef {Partial<State & Action>} SetStateAction
 */

/**
 * @typedef {(state: State, stateToSet: SetStateAction) => SetStateAction} StateReducer
 */

/**
 * @typedef {object} Params
 * @property {State['selected']} [selected]
 * @property {boolean} [isOpen]
 * @property {(selected: State['selected']) => void} [onSelected]
 * @property {StateReducer} [stateReducer]
 */

/**
 * Custom hook for constructing a filter dropdown used in multiple places
 * throughout the application. It keeps track of the open state of the
 * dropdown, the inputted keyword and the selected items. In addition, it
 * exposes a useful state reducer API that allows the caller to control this
 * hook's inner state.
 *
 * This is a "Headless UI Component" meaning that it renders no HTML/JSX. The
 * only thing that this component does is handle state and state updates. The
 * caller of this hook needs to render their own UI by using the returned
 * values from this hook.
 *
 * Read more about headless UI components here:
 * - https://medium.com/merrickchristensen/headless-user-interface-components-565b0c0f2e18
 *
 * And about the state reducer pattern here:
 * - https://kentcdodds.com/blog/the-state-reducer-pattern
 *
 * Also check out some popular headless UI components:
 * - https://github.com/downshift-js/downshift
 * - https://github.com/tannerlinsley/react-table
 *
 * Simple usage example that renders a button, when the button is clicked, a
 * menu is opened showing a filterable list of items:
 *
 * ```
 * const {
 *     getItemProps,
 *     getInputProps,
 *     keyword,
 *     selected,
 *     isOpen,
 *     getToggleProps,
 * } = useFilterDropdown({
 *     onSelected: (selected) =>
 *         console.log('You have selected these ones:', selected)
 * });
 *
 * const myItems = ['foo', 'bar', 'baz', 'asdf'];
 *
 * return (
 *     <>
 *         <button {...getToggleProps}>Open!</button>
 *         {isOpen && (
 *             <div>
 *                 <input {...getInputProps()}/>
 *                 <ul>
 *                     {items
 *                         .filter(item => item.includes(keyword))
 *                         .map(item => (
 *                             <li {...getItemProps({ key: item })}>
 *                                 {item}
 *                             </li>
 *                         ))
 *                     }
 *                 </ul>
 *             </div>
 *         )}
 *     </>
 * );
 * ```
 *
 * @param {Params} param
 */
const useFilterDropdown = ({
  selected = [],
  isOpen = false,
  onSelected = () => undefined,
  stateReducer = (_state, stateToSet) => stateToSet,
}) => {
  const prevSelected = usePrevious(selected);
  const [state, setState] = useState({
    keyword: "",
    selected,
    isOpen,
  });

  /**
   * Internal set state call which is meant to be used any time some state
   * needs to be updated. This will call the provided state reducer as well
   * as call the `onSelected` callback if needed.
   */
  const internalSetState = useCallback(
    /**
     * @param {SetStateAction} stateToSet
     */
    (stateToSet) => {
      setState((prevState) => {
        const nextState = { ...prevState };
        const newStateToSet = stateReducer(prevState, stateToSet);

        Object.keys(newStateToSet).forEach((key) => {
          if (key === "type") {
            return;
          }

          nextState[key] = newStateToSet[key];
        });

        const isItemSelected = "selected" in newStateToSet;
        if (isItemSelected && newStateToSet.selected !== prevState.selected) {
          onSelected(newStateToSet.selected);
        }

        return nextState;
      });
    },
    [onSelected, stateReducer]
  );

  useEffect(() => {
    // We only want to update the internal selected items if the given
    // selected options are new. Since the selected elements are arrays, we
    // can't just check `previous === current` but need to actually check
    // that the arrays are equal.
    if (!arraysEqual(prevSelected, selected)) {
      internalSetState({
        selected,
        type: stateChangeTypes.controlledPropsUpdateSelected,
      });
    }
  }, [internalSetState, prevSelected, selected]);

  /** @param {React.FormEvent<HTMLInputElement>} e */
  const handleInputChanged = (e) => {
    internalSetState({
      keyword: e.target.value,
      type: stateChangeTypes.inputChanged,
    });
  };

  /** @param {string|number} key */
  const handleItemClicked = (key) => {
    const includes = state.selected.includes(key);
    const nextSelected = includes
      ? state.selected.filter((item) => item !== key)
      : [...state.selected, key];

    internalSetState({
      selected: nextSelected,
      type: stateChangeTypes.itemClicked,
    });
  };

  const handleToggleClicked = () => {
    internalSetState({
      isOpen: !state.isOpen,
      type: stateChangeTypes.buttonClicked,
    });
  };

  return {
    keyword: state.keyword,
    selected: state.selected,
    isOpen: state.isOpen,
    internalSetState,

    /**
     * @param {{key: string|number}} params
     * @returns {{key: string|number, onClick: () => void}}
     */
    getItemProps: ({ key, ...rest }) => ({
      key,
      onClick: () => handleItemClicked(key),
      ...rest,
    }),
    /**
     * @returns {{type: 'text', value: string, onChange: typeof handleInputChanged}}
     */
    getInputProps: (rest = {}) => ({
      type: "text",
      value: state.keyword,
      onChange: handleInputChanged,
      ...rest,
    }),
    /**
     * @returns {{onClick: typeof handleToggleClicked}}
     */
    getToggleProps: (rest = {}) => ({
      onClick: handleToggleClicked,
      ...rest,
    }),
  };
};

export default useFilterDropdown;
