// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UserInvitationDetailFragment = (
  { readonly __typename: 'OrgInvitation' }
  & Pick<Types.OrgInvitation, 'token' | 'groupIds'>
);

export type InvitationDetailQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  token: Types.Scalars['ID'];
}>;


export type InvitationDetailQuery = (
  { readonly __typename: 'Query' }
  & { readonly invitation: (
    { readonly __typename: 'OrgInvitation' }
    & UserInvitationDetailFragment
  ) }
);

export type UpdateUserGroupsMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  invitationToken: Types.Scalars['ID'];
  groupIds?: Types.Maybe<ReadonlyArray<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type UpdateUserGroupsMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateUserGroups?: Types.Maybe<(
    { readonly __typename: 'OrgInvitation' }
    & UserInvitationDetailFragment
  )> }
);

export const UserInvitationDetailFragmentDoc = gql`
    fragment UserInvitationDetail on OrgInvitation {
  token
  groupIds(orgSlug: $orgSlug)
}
    `;
export const InvitationDetailDocument = gql`
    query InvitationDetail($orgSlug: String!, $token: ID!) {
  invitation(orgSlug: $orgSlug, token: $token) {
    ...UserInvitationDetail
  }
}
    ${UserInvitationDetailFragmentDoc}`;

/**
 * __useInvitationDetailQuery__
 *
 * To run a query within a React component, call `useInvitationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationDetailQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInvitationDetailQuery(baseOptions: Apollo.QueryHookOptions<InvitationDetailQuery, InvitationDetailQueryVariables>) {
        return Apollo.useQuery<InvitationDetailQuery, InvitationDetailQueryVariables>(InvitationDetailDocument, baseOptions);
      }
export function useInvitationDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationDetailQuery, InvitationDetailQueryVariables>) {
          return Apollo.useLazyQuery<InvitationDetailQuery, InvitationDetailQueryVariables>(InvitationDetailDocument, baseOptions);
        }
export type InvitationDetailQueryHookResult = ReturnType<typeof useInvitationDetailQuery>;
export type InvitationDetailLazyQueryHookResult = ReturnType<typeof useInvitationDetailLazyQuery>;
export type InvitationDetailQueryResult = Apollo.QueryResult<InvitationDetailQuery, InvitationDetailQueryVariables>;
export const UpdateUserGroupsDocument = gql`
    mutation UpdateUserGroups($orgSlug: String!, $invitationToken: ID!, $groupIds: [ID!]) {
  updateUserGroups(
    orgSlug: $orgSlug
    invitationToken: $invitationToken
    groupIds: $groupIds
  ) {
    ...UserInvitationDetail
  }
}
    ${UserInvitationDetailFragmentDoc}`;
export type UpdateUserGroupsMutationFn = Apollo.MutationFunction<UpdateUserGroupsMutation, UpdateUserGroupsMutationVariables>;

/**
 * __useUpdateUserGroupsMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupsMutation, { data, loading, error }] = useUpdateUserGroupsMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      invitationToken: // value for 'invitationToken'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useUpdateUserGroupsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserGroupsMutation, UpdateUserGroupsMutationVariables>) {
        return Apollo.useMutation<UpdateUserGroupsMutation, UpdateUserGroupsMutationVariables>(UpdateUserGroupsDocument, baseOptions);
      }
export type UpdateUserGroupsMutationHookResult = ReturnType<typeof useUpdateUserGroupsMutation>;
export type UpdateUserGroupsMutationResult = Apollo.MutationResult<UpdateUserGroupsMutation>;
export type UpdateUserGroupsMutationOptions = Apollo.BaseMutationOptions<UpdateUserGroupsMutation, UpdateUserGroupsMutationVariables>;