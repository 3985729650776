// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type ProjectsRadiusTableFragment = (
  { readonly __typename: 'ProjectsCollection' }
  & { readonly projects?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Project' }
    & Pick<Types.Project, 'id' | 'name' | 'status' | 'capacity' | 'ppaRemainingAvg' | 'acquiredPortfolio' | 'withinOneCount' | 'withinTenCount' | 'withinOneSumCapacityAc' | 'withinTenSumCapacityAc'>
    & { readonly location: (
      { readonly __typename: 'ProjectLocation' }
      & Pick<Types.ProjectLocation, 'id' | 'city' | 'state'>
    ), readonly withinOne?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'ProjectDict' }
      & Pick<Types.ProjectDict, 'id' | 'name'>
    )>>, readonly withinTen?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'ProjectDict' }
      & Pick<Types.ProjectDict, 'id' | 'name'>
    )>> }
  )>> }
);

export const ProjectsRadiusTableFragmentDoc = gql`
    fragment ProjectsRadiusTable on ProjectsCollection {
  projects {
    id
    name
    status
    capacity
    ppaRemainingAvg
    acquiredPortfolio
    location {
      id
      city
      state
    }
    withinOne {
      id
      name
    }
    withinTen {
      id
      name
    }
    withinOneCount
    withinTenCount
    withinOneSumCapacityAc
    withinTenSumCapacityAc
  }
}
    `;