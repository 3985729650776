// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type DealRoomBannerFragment = (
  { readonly __typename: 'Query' }
  & { readonly diligence?: Types.Maybe<(
    { readonly __typename: 'FundEntityCollection' }
    & Pick<Types.FundEntityCollection, 'count'>
    & { readonly stats?: Types.Maybe<(
      { readonly __typename: 'FundEntityCollectionStats' }
      & Pick<Types.FundEntityCollectionStats, 'projectCount' | 'maxSize' | 'minSize' | 'totalSize'>
    )> }
  )>, readonly acquired?: Types.Maybe<(
    { readonly __typename: 'FundEntityCollection' }
    & Pick<Types.FundEntityCollection, 'count'>
  )>, readonly market?: Types.Maybe<(
    { readonly __typename: 'FundEntityCollection' }
    & Pick<Types.FundEntityCollection, 'count'>
  )> }
);

export const DealRoomBannerFragmentDoc = gql`
    fragment DealRoomBanner on Query {
  diligence: fundEntities(category: DILIGENCE) {
    count
    stats {
      projectCount
      maxSize
      minSize
      totalSize
    }
  }
  acquired: fundEntities(category: ACQUIRED) {
    count
  }
  market: fundEntities(category: MARKET) {
    count
  }
}
    `;