import { css } from "styled-components";

import border from "./border";
import color from "./color";

export interface FocusOutlineOptions {
  inset?: boolean;
  secondary?: boolean;
}

const outline = ({ inset, secondary }: FocusOutlineOptions = {}) => css`
  ${secondary
    ? border.secondary({ style: "double" })
    : border.primary({ style: "double" })};
  ${inset
    ? css`
        box-shadow: inset 0 0 0 0.2rem
          ${secondary
            ? color.secondary({ opacity: 0.25 })
            : color.primary({ opacity: 0.25 })};
      `
    : css``};
`;

export default outline;
