/** An enumeration of sort directions */
export enum Direction {
  ASC = "asc",
  DESC = "desc",
}

export interface Ordering<T> {
  field: T;
  direction: Direction;
}

/**
 * Creates an "Ordering" object, describing a sorting "field" and direction.
 *
 * @example
 * // returns { field: 'name', direction: Direction.ASC }
 * createOrdering('name')
 */
export const createOrdering = <T>(
  field: T,
  direction = Direction.ASC
): Ordering<T> => ({
  field,
  direction,
});

export const ariaSort = <T>(field: T, ordering: Ordering<any>) => {
  if (ordering.field !== field) return "none";
  if (ordering.direction === Direction.ASC) return "ascending";
  return "descending";
};

/** Given a Direction, returns the opposite Direction */
export const opposite = (direction: Direction) =>
  direction === Direction.ASC ? Direction.DESC : Direction.ASC;

/**
 * Given an Ordering object, returns a new Ordering object in the opposite direction.
 *
 * @example
 * // returns { field: 'date', direction: Direction.DESC }
 * reverse(createOrdering('date', Direction.ASC))
 */
export const reverse = <T>(ordering: Ordering<T>): Ordering<T> => ({
  ...ordering,
  direction: opposite(ordering.direction),
});
