/**
 * Adapted from:
 *
 * https://www.npmjs.com/package/react-use-dimensions
 * https://github.com/Swizec/useDimensions/pull/17
 */

import { useCallback, useLayoutEffect, useState } from "react";

export type MeasureReason = "normal" | "manual" | "live";

export interface Dimensions {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
  measureReason: MeasureReason;
}

export type UseDimensionsResult<T extends HTMLElement> = [
  <N extends T>(node: N | null) => void,
  Dimensions | null,
  T | null,
  () => void
];

export interface UseDimensionsOptions {
  liveMeasure?: boolean;
}

function getDimensionObject(
  node: HTMLElement,
  measureReason: MeasureReason
): Dimensions {
  const rect: DOMRect | ClientRect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: "y" in rect ? rect.y : rect.top,
    left: "x" in rect ? rect.x : rect.left,
    x: "x" in rect ? rect.x : rect.left,
    y: "y" in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
    measureReason,
  };
}

function useDimensions<T extends HTMLElement>({
  liveMeasure = true,
}: UseDimensionsOptions = {}): UseDimensionsResult<T> {
  const [dimensions, setDimensions] = useState<Dimensions | null>(null);
  const [node, setNode] = useState<T | null>(null);

  const ref = useCallback(<N extends T>(node: null | N) => {
    setNode(node);
  }, []);

  const measure = useCallback(
    (reason: MeasureReason = "manual") => {
      if (node)
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node, reason))
        );
    },
    [node]
  );

  useLayoutEffect(() => {
    if (node) {
      measure("normal");

      const measureLive = () => measure("live");

      if (liveMeasure) {
        window.addEventListener("resize", measureLive);
        window.addEventListener("scroll", measureLive, true);

        return () => {
          window.removeEventListener("resize", measureLive);
          window.removeEventListener("scroll", measureLive);
        };
      }
    }

    return () => {};
  }, [node, measure, liveMeasure]);

  return [ref, dimensions, node, measure];
}

export default useDimensions;
