import styled from "styled-components";

const StatGrid = styled.div<{ collapse: boolean; repeat: number }>`
  display: ${(props) => (props.collapse ? "none" : "grid")};
  grid-gap: 10px;
  grid-template-columns: ${(props) => `repeat(${props.repeat}, 1fr);`};
  color: #fff !important;
  min-height: 74px;
`;

export default StatGrid;
