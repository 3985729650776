import { Grid, MenuItem } from "@material-ui/core";
import Dialog from "components/generic/Dialog";
import { Form, Formik, FormikHelpers as FormikActions } from "formik";
import gql from "graphql-tag";
import React, { EventHandler, useCallback, useState } from "react";
import * as yup from "yup";

import { OrgGroupFragment } from "~/components/Admin/User/NewUserDialog.generated";
import { useUpdateInviteMutation } from "~/components/Admin/User/UpdateInviteDialog.generated";
import { UserInvitationsManagerProps } from "~/components/Admin/User/useUserInvitationsManager";
import { PrimaryButton } from "~/components/common/buttons";
import { StyledTextField } from "~/components/common/inputs";
import { BetterAlert as Alert } from "~/components/profile/Alert";

export const INVITE_USER_MUTATION = gql`
  mutation UpdateInvite(
    $orgSlug: String!
    $invitationToken: ID!
    $firstName: String
    $lastName: String
    $title: String
    $groupId: ID
  ) {
    updateInvitation(
      orgSlug: $orgSlug
      invitationToken: $invitationToken
      firstName: $firstName
      lastName: $lastName
      title: $title
      groupId: $groupId
    ) {
      token
      firstName
      lastName
      title
      groupId
    }
  }
`;

export const validationSchema = yup
  .object()
  .shape({
    firstName: yup.string().required("First Name is a required field"),
    lastName: yup.string().required("Last Name is a required field"),
    title: yup.string(),
    groupId: yup
      .string()
      .ensure()
      .trim()
      .typeError("Please select a group.")
      .required("Please select a group."),
  })
  .required();
export type UpdateInviteValues = yup.InferType<typeof validationSchema>;

export interface UpdateInviteDialogProps extends UserInvitationsManagerProps {
  orgSlug: string;
  availableGroups: readonly OrgGroupFragment[];
}

export const UpdateInviteDialog = (props: UpdateInviteDialogProps) => {
  const { orgSlug, invitation, updateVisible, close, availableGroups } = props;
  const [alertMessage, setAlertMessage] = useState("");

  const initialValues: UpdateInviteValues = {
    firstName: invitation?.firstName,
    lastName: invitation?.lastName,
    title: invitation?.title,
    groupId: invitation?.groupId,
  };
  const [update] = useUpdateInviteMutation({
    onError: (error) =>
      setAlertMessage(`Unable to update invitation: ${error}.`),
  });
  const handleSubmit = useCallback(
    async (
      formFields: UpdateInviteValues,
      actions: FormikActions<UpdateInviteValues>
    ) => {
      try {
        const result = await update({
          variables: {
            orgSlug: orgSlug,
            invitationToken: invitation?.token,
            firstName: formFields.firstName,
            lastName: formFields.lastName,
            title: formFields.title,
            groupId: formFields.groupId,
          },
          refetchQueries: ["InvitationCounts", "Invitations"],
        });
        if (result.data) {
          actions.resetForm({
            values: {
              firstName: invitation?.firstName,
              lastName: invitation?.lastName,
              title: invitation?.title,
              groupId: invitation?.groupId,
            },
          });
          actions.setSubmitting(false);
          close();
        }
        if (result.errors) {
          // @ts-ignore
          actions.setFieldError("email", result.errors.message);
        }
      } catch (error) {
        actions.setFieldError("email", error.message);
      } finally {
        actions.setSubmitting(false);
      }
    },
    [update, close, orgSlug, invitation]
  );
  const submitButton = (disabled: boolean, submitForm: EventHandler<any>) => {
    return (
      <div>
        <PrimaryButton type="submit" disabled={disabled} onClick={submitForm}>
          Save
        </PrimaryButton>
      </div>
    );
  };
  return (
    <div>
      <Alert message={alertMessage} />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({
          submitForm,
          isSubmitting,
          handleChange,
          handleBlur,
          errors,
          values,
          touched,
        }) => (
          <Dialog
            isOpen={updateVisible && invitation?.accepted === false}
            onRequestClose={close}
            header={
              <span>
                <i
                  className="thumbnail icon icon--user"
                  style={{ width: "20px", height: "20px", marginBottom: "5px" }}
                />
                <span style={{ paddingLeft: "10px" }}>Add user</span>
              </span>
            }
            actions={submitButton(isSubmitting, submitForm)}
          >
            <div className="settings-page mb-5">
              <Form>
                <Grid
                  container
                  direction="column"
                  spacing={7}
                  style={{ paddingTop: 24, minHeight: 500 }}
                >
                  <Grid item>
                    <StyledTextField
                      className="form-control"
                      variant="outlined"
                      id="firstName"
                      name="firstName"
                      label="First Name*"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid>
                  <Grid item>
                    <StyledTextField
                      id="lastName"
                      className="form-control"
                      name="lastName"
                      label="Last Name*"
                      variant="outlined"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid>
                  <Grid item>
                    <StyledTextField
                      id="email"
                      className="form-control"
                      name="email"
                      label="Email*"
                      variant="outlined"
                      value={invitation?.email}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item>
                    <StyledTextField
                      id="title"
                      className="form-control"
                      name="title"
                      label="Title"
                      variant="outlined"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.title && Boolean(errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>
                  <Grid item>
                    <StyledTextField
                      id="groupId"
                      className="form-control"
                      name="groupId"
                      select
                      defaultValue={null}
                      label="Group to assign*"
                      variant="outlined"
                      value={values.groupId}
                      onChange={handleChange}
                      error={touched.groupId && Boolean(errors.groupId)}
                      helperText={touched.groupId && errors.groupId}
                    >
                      <MenuItem key="---" value="">
                        ---
                      </MenuItem>
                      {availableGroups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </StyledTextField>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </Dialog>
        )}
      </Formik>
    </div>
  );
};
