import React, { useState } from "react";
import styled from "styled-components";

import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { Toolbar } from "~/components/common/toolbars";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";
import Dialog from "~/components/generic/Dialog";
import { gray800 } from "~/styles/theme/color";

interface DocumentsExportIndexModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (selectedColumns: string[]) => void;
  track: DocumentsEventTracking;
}

const columns = [
  "Modified",
  "Path",
  "Name",
  "Link",
  "Download URL",
  "Created",
  "Size",
];
const defaultCheckedColumns = ["Modified", "Path", "Name", "Link"];

const DocumentsExportIndexModal = ({
  isOpen,
  onRequestClose,
  onSubmit,
  track,
}: DocumentsExportIndexModalProps) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    defaultCheckedColumns
  );

  const handleCheckboxChange = (column: string) => {
    setSelectedColumns((prev) =>
      prev.includes(column)
        ? prev.filter((c) => c !== column)
        : [...prev, column]
    );
  };

  const handleSubmit = () => {
    onSubmit(selectedColumns);
  };

  return (
    <Dialog
      isOpen={isOpen}
      header={
        <>
          File Index Export<sup>1</sup>
        </>
      }
      onRequestClose={onRequestClose}
      actions={
        <Toolbar>
          <SecondaryButton onClick={onRequestClose}>Cancel</SecondaryButton>
          <PrimaryButton
            onClick={() => {
              handleSubmit();
              track.exportIndexClick();
            }}
          >
            Submit
          </PrimaryButton>
        </Toolbar>
      }
    >
      <SubHeader>Columns to Include:</SubHeader>
      <CheckboxContainer>
        {columns.map((column) => (
          <CheckboxLabel key={column}>
            <input
              type="checkbox"
              checked={selectedColumns.includes(column)}
              onChange={() => handleCheckboxChange(column)}
              disabled={defaultCheckedColumns.includes(column)}
            />
            {column}
          </CheckboxLabel>
        ))}
      </CheckboxContainer>
      <Footnote>
        <sup>1</sup> File Index exports are relative to the current folder.
      </Footnote>
    </Dialog>
  );
};

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-left: 5rem;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Footnote = styled.div`
  margin-top: 10px;
  font-size: 0.9em;
  color: ${gray800};
`;

const SubHeader = styled.div`
  font-size: 18px;
  margin-left: 40px;
  margin-bottom: 10px;
`;

export default DocumentsExportIndexModal;
