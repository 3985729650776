/** A PubSub object - allows mutiple subscribers to listen for published values */
export interface PubSub<T> {
  publish: Publish<T>;
  subscribe: Subscribe<T>;
}

/** A function to publish a new value to all subscribed listeners */
export type Publish<T> = (value: T) => void;

/** A function to register a new listener */
export type Subscribe<T> = (listener: Listener<T>) => Unsubscribe;

/** A function to unsubscribe a listener */
export type Unsubscribe = () => void;

/** A listener function that receives each new value when subscribed */
export type Listener<T> = (value: T) => void;

/**
 * Creates a PubSub object
 *
 * Usage:
 *     const { publish, subscribe } = createPubSub<number>();
 *     const unsubscribe = subscribe((value) => console.log(value));
 *     publish(42); // Console: 42
 *     unsubscribe();
 */
export const createPubSub = <T>(): PubSub<T> => {
  const subscriptions = new Set<{ listener: Listener<T> }>();

  const publish: Publish<T> = (value) => {
    for (const { listener } of subscriptions) {
      listener(value);
    }
  };

  const subscribe: Subscribe<T> = (listener) => {
    // Create a new subscription object, so that the same listener function
    // can be subscribed more than once.
    const subscription = { listener };
    subscriptions.add(subscription);

    // Return an unsubscribe function
    return () => void subscriptions.delete(subscription);
  };

  return { publish, subscribe };
};
