import React from "react";
import styled from "styled-components";

import BannerHeader from "~/components/Banners/BannerHeader";
import { ProjectDetailFragment } from "~/components/Projects/ProjectPage.generated";
import { bgColor } from "~/styles/mixins";

export interface ProjectsBannerProps {
  project?: ProjectDetailFragment | null;
  orgSlug: string;
  loading: boolean;
  showAdmin: boolean;
}

export const ProjectBanner = ({
  project,
  orgSlug,
  loading,
  showAdmin = false,
}: ProjectsBannerProps) => {
  return (
    <BlackBanner>
      <BannerHeader
        title={`${project?.name || (loading && "Loading...")}`}
        adminLink={`/${orgSlug}/projects/${project?.id}/edit`}
        collapsable={false}
        showAdmin={showAdmin}
        adminLinkDisplay={"Edit Project"}
      />
    </BlackBanner>
  );
};

const BlackBanner = styled.div`
  padding: 5px 25px 5px;
  ${bgColor.gray800()};
  border-radius: 4px;
  font-size: 14px;
  color: #fffbd2;
`;
