// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateChecklistItemLinkMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  link: Types.Scalars['String'];
}>;


export type UpdateChecklistItemLinkMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateChecklistItem?: Types.Maybe<(
    { readonly __typename: 'Item' }
    & Pick<Types.Item, 'id' | 'link'>
  )> }
);


export const UpdateChecklistItemLinkDocument = gql`
    mutation UpdateChecklistItemLink($id: ID!, $link: String!) {
  updateChecklistItem(id: $id, link: $link) {
    id
    link
  }
}
    `;
export type UpdateChecklistItemLinkMutationFn = Apollo.MutationFunction<UpdateChecklistItemLinkMutation, UpdateChecklistItemLinkMutationVariables>;

/**
 * __useUpdateChecklistItemLinkMutation__
 *
 * To run a mutation, you first call `useUpdateChecklistItemLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChecklistItemLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChecklistItemLinkMutation, { data, loading, error }] = useUpdateChecklistItemLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useUpdateChecklistItemLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChecklistItemLinkMutation, UpdateChecklistItemLinkMutationVariables>) {
        return Apollo.useMutation<UpdateChecklistItemLinkMutation, UpdateChecklistItemLinkMutationVariables>(UpdateChecklistItemLinkDocument, baseOptions);
      }
export type UpdateChecklistItemLinkMutationHookResult = ReturnType<typeof useUpdateChecklistItemLinkMutation>;
export type UpdateChecklistItemLinkMutationResult = Apollo.MutationResult<UpdateChecklistItemLinkMutation>;
export type UpdateChecklistItemLinkMutationOptions = Apollo.BaseMutationOptions<UpdateChecklistItemLinkMutation, UpdateChecklistItemLinkMutationVariables>;