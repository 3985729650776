import { useEffect, useState } from "react";
import {
  Config,
  PropsGetterArgs,
  usePopperTooltip,
} from "react-popper-tooltip";
import styled from "styled-components";

import { borderRadius } from "~/styles/mixins";

export const TooltipContainer = styled.div.attrs({
  className: "tooltip-container",
})`
  ${borderRadius()};
`;

export const TooltipArrow = styled.div.attrs({
  className: "tooltip-arrow",
})``;

export const MenuTooltipContainer = styled(TooltipContainer)`
  padding: 0;
`;

export const useMenuTooltip = (config?: Config) => {
  const [controlledVisible, setControlledVisible] = useState(false);

  const { getTooltipProps, ...rest } = usePopperTooltip({
    trigger: "click",
    placement: "bottom-end",
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
    ...config,
  });

  useEffect(() => {
    const handleKeyDown = ({ key }: KeyboardEvent) => {
      if (key === "Escape") setControlledVisible(false);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return {
    setControlledVisible,
    getTooltipProps: (args?: PropsGetterArgs) => ({
      ...getTooltipProps(args),
      onClick: () => setControlledVisible(false),
    }),
    ...rest,
  };
};
