import React from "react";

export const EulaText = () => (
  <div>
    <h5>DOCK ENERGY, INC END USER LICENSE AGREEMENT</h5>
    <p>
      PLEASE READ THIS END USER LICENSE AGREEMENT (THE "AGREEMENT") CAREFULLY.
      THIS AGREEMENT GOVERNS YOUR ACCESS TO AND USE OF THE PRODUCTS, SERVICES
      AND RESOURCES AVAILABLE ON THE DOCK PLATFORM (EACH A "SERVICE" AND
      COLLECTIVELY, THE "SERVICES") PROVIDED BY DOCK ENERGY, INC ("DOCK"). THIS
      AGREEMENT IS EFFECTIVE AS OF THE DATE ON WHICH YOU FIRST ACCESS OR USE ANY
      SERVICE OR CHECK THE "I ACCEPT" BOX. IF YOU DO NOT AGREE TO BE BOUND BY
      THE AGREEMENT, YOU MAY NOT ACCESS OR USE THE SERVICES.
    </p>
    <p>
      The Service is an Internet-based hosted platform designed to enable
      provision, sharing and review of information related to energy-based
      projects. You have been added to the Service as a user (an "End User"). By
      accessing or using any Service or clicking on the "I ACCEPT" box you
      represent that (1) you have read and agree to be bound by this Agreement,
      (2) you are of legal age to form a binding contract with Dock, and (3) you
      have the authority to enter into this Agreement personally or on behalf of
      the entity that you are using the Service (e.g., your employer). The term
      "you" refers to any individual using the Services on his or her own behalf
      or, if any individual is using the Services on behalf of an entity, to the
      applicable entity. Dock and you are sometimes referred to herein,
      individually as a "Party" and, collectively, the "Parties."
    </p>
    <p>
      Your use of, and participation in, certain Services may be subject to
      additional terms ("Supplemental Terms") and such Supplemental Terms will
      either be listed in the Agreement or will be presented to you for your
      acceptance when you sign up to use the supplemental Service. If the
      Agreement is inconsistent with the Supplemental Terms, the Supplemental
      Terms will control with respect to such Service. For purposes of this
      Agreement, the term "Agreement" includes all Supplemental Terms.
    </p>
    <p>
      PLEASE NOTE THAT THE TERMS OF THIS AGREEMENT ARE SUBJECT TO CHANGE BY DOCK
      IN ITS SOLE DISCRETION AT ANY TIME. When changes are made, Dock will make
      a new copy of the Agreement available within, or through, the affected
      Service or through any other method that we think is appropriate. Dock
      will also update the "Last Updated" date at the top of the Agreement. If
      Dock makes any material changes, and you have registered with Dock to
      create an Account (as defined in Section 2.1 below) Dock will also send an
      e-mail to you at the last e-mail address you provided to Dock pursuant to
      the Agreement. Any changes to the Agreement will be effective immediately
      for new users of the Services and will be effective thirty (30) days after
      posting notice of such changes on the Service for existing Registered
      Users (as defined below), provided that any material changes shall be
      effective for Registered Users who have an Account with us upon the
      earlier of thirty (30) days after posting notice of such changes on the
      Services or thirty (30) days after dispatch of an e-mail notice of such
      changes to Registered Users. Dock may require you to provide consent to
      the updated Agreement in a specified manner before further use of any
      Service is permitted. If you do not agree to any change(s) after receiving
      a notice of such change(s), you must stop using the Services. Otherwise,
      your continued use of any Service constitutes your acceptance of such
      change(s). PLEASE REGULARLY CHECK THE SERVICE TO VIEW THE THEN-CURRENT
      AGREEMENT.
    </p>
    <ol className="numbered-list">
      <li>
        <b>Use of the Services and Dock Technology</b>
        <ol>
          <li>
            <u>Access Grant.</u> Subject to the terms and conditions set forth
            in this Agreement, Dock hereby grants, during the Term (as defined
            below) you a limited, non-exclusive, non-transferable,
            non-sublicensable, revocable right to access the Service to which
            you have been invited as an End User by Dock, your employer or any
            entity that is party to an agreement with Dock for use of the
            applicable Service and the technical manuals, instructions, user
            information, training materials, and other documentation that
            accompany the Service and contain its technical specifications, as
            may be amended from time to time ("Documentation") solely for
            internal use in the ordinary course of your or your employer's
            business, as applicable (the "License"). All rights, title and
            interest to the Services, the software and technology underlying the
            Services, the Documentation and any other materials provided by Dock
            (collectively, "Dock Technology") are expressly reserved and
            retained by Dock or its licensors or customers, including any
            program or other application that is designed to integrate and be
            used with the Services, whether or not developed independently by
            Dock, and all improvements, modifications and intellectual property
            rights therein. You will not (i) sell, license, lease, transfer,
            assign, reproduce, distribute, export, sublicense, or otherwise
            commercially exploit the Dock Technology except as specifically set
            forth herein, or its license rights thereto, to any other person,
            organization or entity, including through rental, timesharing,
            service bureau, subscription, hosting, or outsourcing the Dock
            Technology (whether or not such sublicense, hosting or outsourcing
            is by you or for you or your employer, as applicable); (ii) frame or
            utilize framing techniques to enclose any trademark, logo, or other
            Dock Technology (including images, text, page layout or form); (iii)
            use any metatags or other "hidden text" using Dock's name or
            trademarks; (iv) modify, translate, adapt, merge, or attempt to
            create any derivative version of any Dock Technology; (v) use any
            manual or automated software, devices or other processes (including
            but not limited to spiders, robots, scrapers, crawlers, avatars,
            data mining tools or the like) to "scrape" or download data from any
            Service; (vi) access Dock Technology in order to build a similar or
            competitive Service; (vii) remove or modify any marking or notice on
            or displayed through the Dock Technology, including those related to
            Dock's or its licensors' proprietary rights in and to any of the
            Dock Technology; (viii) de-compile, decrypt, reverse engineer,
            disassemble, or otherwise reduce any software to human-readable
            form; or (ix) use the Service to harm, threaten or harass another
            person or entity. Without limiting the foregoing, you may not
            sublicense, outsource or otherwise grant access to the Dock
            Technology to any third party vendor without Dock's prior written
            consent.
          </li>
          <li>
            <u>Customer Content.</u> You may access through the Services data or
            materials made available through the Services which have been
            uploaded by you, another End User or a customer of Dock
            (collectively, "Customer Content"). For the avoidance of doubt,
            neither the Services nor the Dock Technology include (and each
            expressly excludes) Customer Content. You represent and warrant that
            you will not store or transmit inappropriate Customer Content ("Your
            Customer Content"), as determined in Dock's sole discretion, which
            may include Customer Content containing (i) unlawful, defamatory,
            threatening, pornographic, abusive, libelous or otherwise
            objectionable material of any kind or nature, (ii) any material that
            encourages conduct that could constitute a criminal offense or (iii)
            that violates the intellectual property rights or rights to the
            publicity or privacy of others. Dock will only access and process
            Customer Content (a) upon the request of the Dock customer that
            uploaded the Customer Content, (b) at the direction of an End User
            granted access to such Customer Content (e.g., in furtherance of
            delivery of the Service or performance of related customer support
            functions); or (c) as otherwise required by applicable law. You
            acknowledge and agree that Dock does not control and is not
            responsible for, does not warrant, support, or make any
            representations regarding Your Customer Content or any other
            Customer Content.
          </li>
          <li>
            <u>Applicable Policies.</u> In addition to the terms of this
            Agreement, you acknowledge the terms of Dock's then-current policies
            relating to the Services, including, without limitation, the Dock
            Privacy Policy which can be viewed by clicking{" "}
            <a href="https://www.dock.energy/privacy">here</a>. It is important
            that you understand how Dock uses your data. By accepting this
            Agreement, you also acknowledge that you have read and agree to the
            terms of the Dock Privacy Policy. As part of the Services, Dock may
            use your Registration Data (defined below) to send alerts and
            updates to you regarding Customer Content posted to the Services and
            other service-related email communications relating to the Service.
          </li>
          <li>
            <u>Your Responsibilities.</u> You represent and warrant that you
            will only access and use the Service, Customer Content and other
            resources available on the Service to which you have been granted
            authorized access via the Service and in accordance with applicable
            law. When you access Customer Content on the Service (e.g. a
            publication, comment or attachments to a publication or comment), a
            copy of that Customer Content may be copied to your local computer
            (this is called "caching" and is part of how web browsers work).
            Caching is used to improve the speed of the Service when you
            repeatedly access graphics or data during a session. Once pages
            and/or data have been cached on your local computer that data is
            beyond the control of Dock and may be accessible to anyone with
            access to your computer. Each End User assumes all risk and
            liability associated with any data cached to that End User's local
            computer. Dock strongly recommends that you empty your web browser
            cache when you log out of a Dock session to ensure that any cached
            files from your Dock session are deleted from your computer. The
            Service should only be used as a conduit for information, not as
            primary information storage. As an End User, you are responsible for
            adopting reasonable measures to limit the impact of data loss (such
            as corruption or virus infection), including (a) keeping on your
            local computer backup copies of data you transmit via the Service in
            the event you have to re-transmit the data; (b) verifying the data
            in documents or other materials before transmitting them via the
            Service; and (c) verifying that documents and other information you
            have posted to the Service has been properly posted and have been
            permissioned to only those persons to whom you wish to grant access.
            You also understand that other End Users with appropriate access
            rights may have access to Customer Content you transmit through the
            Service. You shall also run antivirus software on any computer you
            use to access the Service and shall ensure that documents and files
            transmitted through the Service have been scanned for viruses.
          </li>
        </ol>
      </li>
      <li>
        <b>Registration</b>
        <ol>
          <li>
            <u>Registering Your Account.</u> In order to access any Service, you
            may be required to become a Registered User. For purposes of the
            Agreement, a <b>"Registered User"</b> is a user who has registered
            an account through a particular Service (<b>"Account"</b>). In
            registering an account through a Service, you agree to (a) provide
            true, accurate, current and complete information about yourself as
            prompted by the registration form (the <b>"Registration Data"</b>);
            and (b) maintain and promptly update the Registration Data to keep
            it true, accurate, current and complete. You represent that you are
            (1) at least thirteen (13) years old; (2) of legal age to form a
            binding contract; and (3) not a person barred from using Dock
            Technology under the laws of the United States, your place of
            residence or any other applicable jurisdiction. If you provide any
            information that is untrue, inaccurate, not current or incomplete,
            or Dock has reasonable grounds to suspect that any information you
            provide is untrue, inaccurate, not current or incomplete, Dock has
            the right to suspend or terminate your Account and refuse any and
            all current or future use of Dock Technology (or any portion
            thereof). You agree not to create an Account using a false identity
            or information, or on behalf of someone other than yourself. You
            agree that you will not have more than one Account at any given
            time. Dock reserves the right to remove or reclaim any usernames at
            any time and for any reason, including but not limited to, claims by
            a third party that a username violates the third party's rights. You
            agree not to create an Account or use Dock Technology if you have
            been previously removed by Dock, or if you have been previously
            banned from any of Dock Technology.
          </li>
          <li>
            <u>Access Credentials.</u> You may not share your Account or
            password (collectively, your <b>"Access Credentials"</b>) with
            anyone, and you agree to (i) notify Dock immediately of any
            unauthorized use of your Access Credentials or any other breach of
            security; and (ii) exit from your Account at the end of each
            session. You are responsible for all activities that occur under
            your Access Credentials. You agree that you will monitor your
            Account to restrict use by minors, and you will accept full
            responsibility for any unauthorized use of Dock Technology by
            minors.
          </li>
          <li>
            <u>Your Account.</u> Notwithstanding anything to the contrary
            herein, you acknowledge and agree that you will have no ownership or
            other property interest in your Account, and you further acknowledge
            and agree that all rights in and to your Account are and will
            forever be owned by and inure to the benefit of Dock.
          </li>
          <li>
            <u>Necessary Equipment and Software.</u> You must provide all
            equipment and software necessary to connect to Dock Technology. You
            are solely responsible for any fees, including Internet connection
            or mobile fees, that you incur when accessing Dock Technology.
          </li>
        </ol>
      </li>
      <li>
        <b>Confidential Information. "Confidential Information"</b> means any
        nonpublic information of Dock, whether disclosed orally or in written or
        digital media, that is identified as "confidential" or with a similar
        legend at the time of such disclosure or that you know or should have
        known is the confidential or proprietary information of Dock, including
        Dock Technology. Information will not constitute Confidential
        Information if it (i) is already known by you without obligation of
        confidentiality; (ii) is independently developed by you without access
        to or use of Confidential Information; (iii) is publicly known without
        breach of this Agreement; or (iv) is lawfully received from a third
        party without obligation of confidentiality. You will not use or
        disclose any Confidential Information except as expressly authorized by
        this Agreement and will protect Confidential Information using the same
        degree of care that it uses with respect to its own confidential
        information, but in no event with safeguards less than a reasonably
        prudent business would exercise under similar circumstances. You may
        disclose Confidential Information to your affiliates, employees, and
        representatives as necessary to perform your obligations or exercise
        your rights under this Agreement and will cause such affiliates,
        employees, and representatives to comply with confidentiality
        restrictions consistent with this Section. You will take prompt and
        appropriate action to prevent unauthorized use or disclosure of
        Confidential Information. If any Confidential Information must be
        disclosed to any third party by reason of legal, accounting or
        regulatory requirements, you will promptly notify Dock of the order or
        request and permit Dock (at its own expense) to seek an appropriate
        protective order. Your rights to use certain Customer Content may be
        governed by other confidentiality obligations, such as provisions in a
        written agreement between you and the owner of such Customer Content.
      </li>
      <li>
        <b>Feedback.</b> You agree that submission of any ideas, suggestions,
        documents, and/or proposals to Dock by any means and in any medium (
        <b>"Feedback"</b>) is at your own risk and that Dock has no obligations
        (including without limitation obligations of confidentiality) with
        respect to such Feedback. You represent and warrant that you have all
        rights necessary to submit the Feedback. You hereby grant to Dock a
        fully paid, royalty-free, perpetual, irrevocable, worldwide,
        non-exclusive, and fully sublicensable right and license to use,
        reproduce, perform, display, distribute, adapt, modify, re-format,
        create derivative works of, and otherwise commercially or
        non-commercially exploit in any manner, any and all Feedback, and to
        sublicense the foregoing rights, in connection with the operation and
        maintenance of Dock Technology and/or Dock's business. Dock may collect,
        use and disclosure statistical data regarding your usage of the
        Services; however, no End User, Customer or particular transaction shall
        be identified in connection with such statistics.
      </li>
      <li>
        <b>Hosting.</b> The Services will be hosted by an authorized
        subcontractor (the <b>"Hosting Service Provider"</b>) that has been
        engaged by Dock and will only be accessed by you on websites, using your
        or your employer's computers. The Hosting Service Provider is an
        independent third party not controlled by Dock. Accordingly, IN NO EVENT
        WILL DOCK BE LIABLE FOR ANY DIRECT, GENERAL, SPECIAL, INCIDENTAL,
        INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING BUT NOT LIMITED
        TO LOSS OR DAMAGE TO DATA, DAMAGES FOR LOSS OF BUSINESS PROFITS,
        BUSINESS INTERRUPTION, LOSS OF INFORMATION OR ANY OTHER PECUNIARY LOSS)
        ARISING OUT OF THE USE OF OR INABILITY TO USE ANY SERVICE, DUE TO
        PROBLEMS (INCLUDING BUT NOT LIMITED TO ERRORS, MALFUNCTIONS) ASSOCIATED
        WITH THE FUNCTIONS OF SERVERS MAINTAINED BY THE HOSTING SERVICE
        PROVIDER, EVEN IF DOCK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES.
      </li>
      <li>
        <b>Limited Remedy and Disclaimers.</b>
        <ol>
          <li>
            <u>By Dock.</u> Dock represents and warrants that the Services will
            conform to the Documentation provided for the Services. As your sole
            and exclusive remedy for any breach of the foregoing, Dock will
            attempt through reasonable efforts to correct or cure any such
            reproducible and documented material non-conformance. Dock will not
            be obligated to correct, cure or otherwise remedy any nonconformance
            in the Services (a) if caused by software or other equipment used by
            you which has not been provided by Dock; (b) if you have made any
            changes whatsoever to the Service; (c) if the Service has been
            misused or damaged in any respect; or (d) if you have not reported
            to Dock the existence and nature of any nonconformity or defect
            promptly upon discovering it.
          </li>
          <li>
            <u>By You.</u> You represent and warrant that you (i) have all
            rights to the Customer Content (including, for the avoidance of
            doubt, Your Customer Content), necessary for you to use the Services
            to transmit, store or process such Customer Content and (ii) your
            use of the Services and Customer Content (including, for the
            avoidance of doubt, Your Customer Content) will comply in all
            material respects with all applicable laws.
          </li>
          <li>
            <u>Disclaimers.</u> YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE
            EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF THE DOCK TECHNOLOGY
            AND CONTENT IS AT YOUR SOLE RISK, AND THE SERVICES, OTHER DOCK
            TECHNOLOGY AND CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
            BASIS, WITH ALL FAULTS. EXCEPT AS EXPRESSLY PROVIDED HEREIN, DOCK
            AND ITS LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES,
            REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS,
            IMPLIED, OR STATUTORY, AS TO ANY ASPECT OF THE CONTENT, DOCK
            TECHNOLOGY OR OTHER PRODUCTS, INCLUDING WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. DOCK AND ITS
            LICENSORS DO NOT WARRANT THAT THE SERVICES OR OTHER DOCK TECHNOLOGY
            WILL BE UNINTERRUPTED, OR ERROR-FREE; NOR DO THEY MAKE ANY WARRANTY
            AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES OR
            OTHER DOCK TECHNOLOGY. You acknowledge and agree that Dock shall not
            be responsible for any Customer Content or the modification, use or
            publication of Customer Content by you, any End User or third party
            (other than Dock's agents and subcontractors). Dock shall not be
            responsible for the accuracy, nature, content or completeness of
            information obtained by you from the Services (including any
            Customer Content).
          </li>
        </ol>
      </li>
      <li>
        <b>Indemnification.</b> You will indemnify Dock and its officers,
        directors, employees, and agents and hold them harmless from all
        liabilities, claims, expenses, and losses (including attorneys' fees and
        expenses) arising from or related to Your Customer Content or any breach
        by you of this Agreement.
      </li>
      <li>
        <b>Limitations of Liability.</b> TO THE EXTENT PERMITTED BY APPLICABLE
        LAW, IN NO EVENT WILL DOCK BE LIABLE TO YOU OR ANY THIRD PARTY FOR
        INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
        WHETHER FORESEEABLE OR UNFORESEEABLE, OF ANY KIND WHATSOEVER (INCLUDING
        LOST PROFITS) ARISING FROM OR RELATING TO THIS AGREEMENT OR THE USE OR
        NON-USE OF THE SERVICES. NOTWITHSTANDING ANYTHING CONTAINED IN THIS
        AGREEMENT TO THE CONTRARY, TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN
        NO EVENT WILL DOCK'S TOTAL LIABILITY ARISING FROM OR RELATING TO THIS
        AGREEMENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
        NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EXCEED ONE HUNDRED DOLLARS
        ($100). Each Party acknowledges and agrees that the warranty disclaimers
        and liability and remedy limitations in this Agreement are material,
        bargained for provisions of this Agreement and that fees and
        consideration payable hereunder reflects these disclaimers and
        limitations.
      </li>
      <li>
        <b>Term and Termination.</b> This Agreement will be in effect for as
        long as you use the Services (the
        <b>"Term"</b>), unless terminated earlier as set forth herein. Either
        Party may terminate this Agreement, for any reason or no reason, with or
        without notice. Without limiting the foregoing, Dock may immediately
        suspend your access to the Services if your use (i) may, in Dock's
        reasonable opinion, impair the ability of other End Users to access or
        use the Service or (ii) is, in Dock's reasonable opinion, in violation
        of any law, rule, regulation or ordinance. Upon the termination or
        expiration of this Agreement you (a) will immediately cease using the
        Services and promptly destroy all materials in your possession
        pertaining to the Services (but not including any Customer Content which
        is rightfully in your possession) and (b) the License granted pursuant
        to this Agreement will automatically terminate. You agree to promptly
        provide Dock with written certification of your compliance with the
        terms of this section upon Dock's request. All provisions of this
        Agreement which by their nature should survive, will survive termination
        of the Agreement, including without limitation, ownership provisions,
        warranty disclaimers, and limitation of liability.
      </li>
      <li>
        <b>No Subsequent Registration.</b> If your registration(s) with or
        ability to access Services, or any other Dock community is discontinued
        by Dock due to your violation of any portion of the Agreement or for
        conduct otherwise inappropriate for the community, then you agree that
        you shall not attempt to re-register with or access the Services through
        use of a different member name or otherwise. In the event that you
        violate the immediately preceding sentence, Dock reserves the right, in
        its sole discretion, to immediately take any or all of the actions set
        forth herein without any notice or warning to you.
      </li>
      <li>
        <b>Export Control.</b> You may not use, export, import, or transfer Dock
        Technology except as authorized by U.S. law, the laws of the
        jurisdiction in which you obtained Dock Technology, and any other
        applicable laws. You also will not use Dock Technology for any purpose
        prohibited by U.S. law, including the development, design, manufacture
        or production of missiles, nuclear, chemical or biological weapons. You
        acknowledge and agree that products, services or technology provided by
        Dock are subject to the export control laws and regulations of the
        United States. You will comply with these laws and regulations and will
        not, without prior U.S. government authorization, export, re-export, or
        transfer Dock products, services or technology, either directly or
        indirectly, to any country, person or entity in violation of such laws
        and regulations.
      </li>
      <li>
        <b>Electronic Communications.</b> The communications between you and
        Dock use electronic means, whether you access any Service or send Dock
        e-mails, or whether Dock posts notices on Dock Technology or
        communicates with you via e-mail. For contractual purposes, you (1)
        consent to receive communications from Dock in an electronic form; and
        (2) agree that all terms and conditions, agreements, notices,
        disclosures, and other communications that Dock provides to you
        electronically satisfy any legal requirement that such communications
        would satisfy if it were to be in writing. The foregoing does not affect
        your statutory rights, Including but not limited to the Electronic
        Signatures in Global and National Commerce Act at 15 U.S.C. §7001 et
        seq. ("E-Sign").
      </li>
      <li>
        <b>Notice.</b> Where Dock requires that you provide an e-mail address,
        you are responsible for providing Dock with your most current e-mail
        address. In the event that the last e-mail address you provided to Dock
        is not valid, or for any reason is not capable of delivering to you any
        notices required/ permitted by the Agreement, Dock's dispatch of the
        e-mail containing such notice will nonetheless constitute effective
        notice. You may give notice to Dock at the following address: 17905
        Spire Ct, Santa Clarita, CA 91350. Such notice shall be deemed given
        when received by Dock by letter delivered by nationally recognized
        overnight delivery service or first class postage prepaid mail at the
        above address.
      </li>
      <li>
        <b>Release.</b> You hereby release Dock, Dock's affiliates, current and
        former shareholders, directors, officers, employees and agents, and each
        of the foregoing's successors (the <b>"Dock Parties"</b>) from claims,
        demands, any and all losses, damages, rights, and actions of any kind,
        including personal injuries, death, and property damage, that is either
        directly or indirectly related to or arises from your use of Services,
        including but not limited to, any interactions with or conduct of other
        Registered Users or third-party websites of any kind arising in
        connection with or as a result of the Agreement or your use of Services.
        If you are a California resident, you hereby waive California Civil Code
        Section 1542, which states, "A general release does not extend to claims
        that the creditor or releasing party does not know or suspect to exist
        in his or her favor at the time of executing the release and that, if
        known by him or her, would have materially affected his or her
        settlement with the debtor or released party." The foregoing release
        does not apply to any claims, demands, or any losses, damages, rights
        and actions of any kind, including personal injuries, death or property
        damage for any unconscionable commercial practice by a Dock Party or for
        such party's fraud, deception, false, promise, misrepresentation or
        concealment, suppression or omission of any material fact in connection
        with any Services provided hereunder.
      </li>
      <li>
        <b>Governing Law, Venue, Remedy.</b> This Agreement, and any disputes
        arising out of or related hereto, will be governed exclusively by the
        laws of the State of California, without regard to its conflicts of laws
        rules. The state and federal courts located in Los Angeles County,
        California will have exclusive jurisdiction to adjudicate any dispute
        arising out of or relating to this Agreement. Each Party hereby consents
        to the exclusive jurisdiction of such courts. Each Party hereby waives
        any right to jury trial in connection with any action nor litigation in
        any way arising out of or related to this Agreement. You acknowledge
        that your breach of any provision of this Agreement may cause immediate
        and irreparable harm to Dock for which Dock may not have an adequate
        remedy in money or damages. Dock will therefore be entitled to obtain an
        injunction against such breach from any court of competent jurisdiction
        immediately upon request and will be entitled to recover from you the
        costs incurred in seeking such an injunction. Dock's right to obtain
        injunctive relief will not limit its right to seek or obtain further
        remedies.
      </li>
      <li>
        <b>General.</b> Dock and you are each independent contractors and
        neither Party will be, nor represent itself to be, the franchiser,
        partner, broker, employee, servant, agent, or legal representative of
        the other Party for any purpose whatsoever. You may not sublicense,
        assign, or transfer this Agreement, or any rights and obligations under
        this Agreement, in whole or in part, without Dock's prior written
        consent. Any attempted assignment in violation of this Section will be
        void. This Agreement will be binding upon, and inure to the benefit of,
        the permitted successors and assigns of each Party. Notwithstanding
        anything to the contrary in this Agreement, neither Party will be deemed
        to be in default of any provision of this Agreement for any delay,
        error, failure, or interruption of performance due to any act of God,
        terrorism, war, strike, or other labor or civil disturbance,
        interruption of power service, interruption of communications services,
        problems with the Internet, act of any other person not under the
        control of such Party, or other similar cause. This Agreement may be
        amended only by written agreement of the Parties, and any attempted
        amendment in violation of this Section will be void. The waiver or
        failure of either Party to exercise in any respect any right provided
        under this Agreement will not be deemed a waiver of such right in the
        future or a waiver of any other rights established under this Agreement.
        If any portion of this Agreement is held invalid or unenforceable, that
        portion shall be construed in a manner to reflect, as nearly as
        possible, the original intention of the Parties, and the remaining
        portions shall remain in full force and effect. This Agreement does not
        confer any rights or remedies upon any person other than the Parties,
        except Dock's licensors. When used herein, the words "includes" and
        "including" and their syntactical variations will be deemed followed by
        the words "without limitation."
      </li>
      <li>
        <b>Questions, Complaints, Claims.</b> If you have any questions,
        complaints or claims with respect to the Services, please contact us at:{" "}
        <a href="mailto:hello@dock.energy">hello@dock.energy</a>. We will do our
        best to address your concerns. If you feel that your concerns have been
        addressed incompletely, we invite you to let us know for further
        investigation.
      </li>
    </ol>
  </div>
);
