import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  DropdownMenu,
  DropdownMenuTheme,
} from "~/components/menus/DropdownMenu";
import { fgColor } from "~/styles/mixins";
import { gettext } from "~/utils/text";

export interface BannerHeaderProps {
  title: React.ReactNode;
  adminLink?: string;
  adminLinkDisplay?: string;
  collapsable?: boolean;
  collapsed?: boolean;
  setCollapsed?: (collapsed: boolean) => void;
  showAdmin: boolean;
  showUsersGroups?: boolean;
}

const BannerHeader = ({
  title,
  adminLink,
  adminLinkDisplay,
  collapsed,
  setCollapsed,
  collapsable = true,
  showAdmin,
  showUsersGroups = false,
}: BannerHeaderProps) => {
  return (
    <Header>
      <Title>{title}</Title>
      <div className="mr-2">
        {collapsable && (
          <CollapseButton
            type="button"
            className="btn btn-link btn-collapse"
            onClick={() => setCollapsed && setCollapsed(!collapsed)}
          >
            {collapsed ? gettext("Expand") : gettext("Collapse")}
          </CollapseButton>
        )}
      </div>
      <div>
        {(showAdmin || showUsersGroups) && adminLink && (
          <DropdownMenu
            theme={DropdownMenuTheme.ORANGE}
            btnText={gettext("Admin")}
            showArrow
          >
            {showAdmin && (
              <Dropdown.Item href={adminLink}>
                {gettext(adminLinkDisplay || "Edit content")}
              </Dropdown.Item>
            )}
            {showUsersGroups && (
              <Link to="/deal-teams" className="dropdown-item">
                Users & Groups
              </Link>
            )}
          </DropdownMenu>
        )}
      </div>
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  flex-grow: 1;
  font-size: 2rem;
  color: #ffffff;
  margin: 10px 0;
`;

const CollapseButton = styled.button`
  ${fgColor.white()};
  font-weight: 400;
  text-decoration: none !important;
  &:hover {
    ${fgColor.white()};
    text-decoration: none;
  }
`;

export default BannerHeader;
