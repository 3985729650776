// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { DataImportFragment } from './ImportHistory.generated';
import { DuplicateReportFragment } from './DuplicateReportsTable.generated';
import { gql } from '@apollo/client';
import { DataImportFragmentDoc } from './ImportHistory.generated';
import { DuplicateReportFragmentDoc } from './DuplicateReportsTable.generated';
import * as Apollo from '@apollo/client';
export type DataImportsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DataImportsQuery = (
  { readonly __typename: 'Query' }
  & { readonly dataImports?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'SalesforceDataImport' }
    & DataImportFragment
  )>> }
);

export type UploadDataImportMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload'];
}>;


export type UploadDataImportMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly uploadDataImport?: Types.Maybe<(
    { readonly __typename: 'SalesforceDataImport' }
    & DataImportFragment
  )> }
);

export type CreateDuplicateReportMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateDuplicateReportMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createDuplicateReport?: Types.Maybe<(
    { readonly __typename: 'SalesforceDuplicateReport' }
    & DuplicateReportFragment
  )> }
);

export type DuplicateReportsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DuplicateReportsQuery = (
  { readonly __typename: 'Query' }
  & { readonly duplicateReports?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'SalesforceDuplicateReport' }
    & DuplicateReportFragment
  )>> }
);


export const DataImportsDocument = gql`
    query DataImports {
  dataImports {
    ...DataImport
  }
}
    ${DataImportFragmentDoc}`;

/**
 * __useDataImportsQuery__
 *
 * To run a query within a React component, call `useDataImportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataImportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataImportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataImportsQuery(baseOptions?: Apollo.QueryHookOptions<DataImportsQuery, DataImportsQueryVariables>) {
        return Apollo.useQuery<DataImportsQuery, DataImportsQueryVariables>(DataImportsDocument, baseOptions);
      }
export function useDataImportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataImportsQuery, DataImportsQueryVariables>) {
          return Apollo.useLazyQuery<DataImportsQuery, DataImportsQueryVariables>(DataImportsDocument, baseOptions);
        }
export type DataImportsQueryHookResult = ReturnType<typeof useDataImportsQuery>;
export type DataImportsLazyQueryHookResult = ReturnType<typeof useDataImportsLazyQuery>;
export type DataImportsQueryResult = Apollo.QueryResult<DataImportsQuery, DataImportsQueryVariables>;
export const UploadDataImportDocument = gql`
    mutation UploadDataImport($file: Upload!) {
  uploadDataImport(file: $file) {
    ...DataImport
  }
}
    ${DataImportFragmentDoc}`;
export type UploadDataImportMutationFn = Apollo.MutationFunction<UploadDataImportMutation, UploadDataImportMutationVariables>;

/**
 * __useUploadDataImportMutation__
 *
 * To run a mutation, you first call `useUploadDataImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDataImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDataImportMutation, { data, loading, error }] = useUploadDataImportMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadDataImportMutation(baseOptions?: Apollo.MutationHookOptions<UploadDataImportMutation, UploadDataImportMutationVariables>) {
        return Apollo.useMutation<UploadDataImportMutation, UploadDataImportMutationVariables>(UploadDataImportDocument, baseOptions);
      }
export type UploadDataImportMutationHookResult = ReturnType<typeof useUploadDataImportMutation>;
export type UploadDataImportMutationResult = Apollo.MutationResult<UploadDataImportMutation>;
export type UploadDataImportMutationOptions = Apollo.BaseMutationOptions<UploadDataImportMutation, UploadDataImportMutationVariables>;
export const CreateDuplicateReportDocument = gql`
    mutation CreateDuplicateReport {
  createDuplicateReport {
    ...DuplicateReport
  }
}
    ${DuplicateReportFragmentDoc}`;
export type CreateDuplicateReportMutationFn = Apollo.MutationFunction<CreateDuplicateReportMutation, CreateDuplicateReportMutationVariables>;

/**
 * __useCreateDuplicateReportMutation__
 *
 * To run a mutation, you first call `useCreateDuplicateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDuplicateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDuplicateReportMutation, { data, loading, error }] = useCreateDuplicateReportMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateDuplicateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateDuplicateReportMutation, CreateDuplicateReportMutationVariables>) {
        return Apollo.useMutation<CreateDuplicateReportMutation, CreateDuplicateReportMutationVariables>(CreateDuplicateReportDocument, baseOptions);
      }
export type CreateDuplicateReportMutationHookResult = ReturnType<typeof useCreateDuplicateReportMutation>;
export type CreateDuplicateReportMutationResult = Apollo.MutationResult<CreateDuplicateReportMutation>;
export type CreateDuplicateReportMutationOptions = Apollo.BaseMutationOptions<CreateDuplicateReportMutation, CreateDuplicateReportMutationVariables>;
export const DuplicateReportsDocument = gql`
    query DuplicateReports {
  duplicateReports {
    ...DuplicateReport
  }
}
    ${DuplicateReportFragmentDoc}`;

/**
 * __useDuplicateReportsQuery__
 *
 * To run a query within a React component, call `useDuplicateReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDuplicateReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDuplicateReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDuplicateReportsQuery(baseOptions?: Apollo.QueryHookOptions<DuplicateReportsQuery, DuplicateReportsQueryVariables>) {
        return Apollo.useQuery<DuplicateReportsQuery, DuplicateReportsQueryVariables>(DuplicateReportsDocument, baseOptions);
      }
export function useDuplicateReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DuplicateReportsQuery, DuplicateReportsQueryVariables>) {
          return Apollo.useLazyQuery<DuplicateReportsQuery, DuplicateReportsQueryVariables>(DuplicateReportsDocument, baseOptions);
        }
export type DuplicateReportsQueryHookResult = ReturnType<typeof useDuplicateReportsQuery>;
export type DuplicateReportsLazyQueryHookResult = ReturnType<typeof useDuplicateReportsLazyQuery>;
export type DuplicateReportsQueryResult = Apollo.QueryResult<DuplicateReportsQuery, DuplicateReportsQueryVariables>;