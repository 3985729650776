// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateSupportTicketMutationVariables = Types.Exact<{
  issue: Types.Scalars['String'];
  description: Types.Scalars['String'];
}>;


export type CreateSupportTicketMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createSupportTicket?: Types.Maybe<(
    { readonly __typename: 'SupportTicketOutput' }
    & Pick<Types.SupportTicketOutput, 'success'>
  )> }
);


export const CreateSupportTicketDocument = gql`
    mutation CreateSupportTicket($issue: String!, $description: String!) {
  createSupportTicket(issue: $issue, description: $description) {
    success
  }
}
    `;
export type CreateSupportTicketMutationFn = Apollo.MutationFunction<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>;

/**
 * __useCreateSupportTicketMutation__
 *
 * To run a mutation, you first call `useCreateSupportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupportTicketMutation, { data, loading, error }] = useCreateSupportTicketMutation({
 *   variables: {
 *      issue: // value for 'issue'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateSupportTicketMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>) {
        return Apollo.useMutation<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>(CreateSupportTicketDocument, baseOptions);
      }
export type CreateSupportTicketMutationHookResult = ReturnType<typeof useCreateSupportTicketMutation>;
export type CreateSupportTicketMutationResult = Apollo.MutationResult<CreateSupportTicketMutation>;
export type CreateSupportTicketMutationOptions = Apollo.BaseMutationOptions<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>;