import clsx from "clsx";
import { format as formatDate } from "date-fns";
import { LocationDescriptorObject } from "history";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Maybe } from "~/api/types.generated";
import { PrimaryButton } from "~/components/common/buttons";
import { SkeletonText } from "~/components/common/skeletons";
import { EmptyRow, Table } from "~/components/common/tables";
import { EllipsisText, LightLabel } from "~/components/common/text";
import { View } from "~/components/Documents/useDocumentsRouting";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { formatBytes, formatDateAgo } from "~/utils/formats";

import {
  DocumentActivity_DetailFragment,
  Document_BreadcrumbsFragment,
} from "./DocumentsAPI.generated";
import DocumentsThumbnail from "./DocumentsThumbnail";

type Data = DocumentActivity_DetailFragment;

export interface DocumentsHistoryTableProps {
  data?: Maybe<readonly Data[]>;
  renderDocumentName: (
    document: Document_BreadcrumbsFragment
  ) => React.ReactNode;
  getLink: (documentId: string, view?: View) => LocationDescriptorObject;
  downloadFile: (documentId: string) => void;
  downloadLoading: (documentId: string) => boolean;
}

const skeletonData = [1, 2, 3, 4, 5].map(
  (n) => ({ id: `skeleton-${n}`, document: {}, user: {} } as Data)
);

export const DocumentsHistoryTable = React.memo(function DocumentsHistoryTable(
  props: DocumentsHistoryTableProps
) {
  const { data, renderDocumentName, getLink, downloadFile, downloadLoading } =
    props;

  const loading = data == null;
  const empty = !loading && data?.length === 0;
  const rows = data ?? skeletonData;

  const columns = [
    column("thumbnail", true, "", ({ document }) => (
      <DocumentsThumbnail document={document} />
    )),
    column("name", true, "Name", ({ document }) =>
      renderDocumentName(document)
    ),
    column("size", true, "Size", ({ document: { size } }) => {
      if (size === undefined) return <SkeletonText />;
      if (size === null) return "-";
      return formatBytes(size);
    }),
    column("user", true, "By", ({ user: { fullName } }) => {
      if (fullName === undefined) return <SkeletonText />;
      if (fullName === null) return "-";
      return fullName;
    }),
    column("groups", true, "Member of", ({ userGroups }) => {
      if (userGroups === undefined) return <SkeletonText />;
      if (userGroups === null) return "No user group";
      return (
        <EllipsisText>
          {userGroups.map((group) => group.name).join(",")}
        </EllipsisText>
      );
    }),
    column("type", true, "Activity type", ({ activityType }) => activityType),
    column("date", true, "Date/Time", ({ createdAt }) => {
      if (createdAt === undefined) return <SkeletonText />;
      if (createdAt === null) return "-";
      return (
        <>
          <EllipsisText>{formatDateAgo(createdAt)}</EllipsisText>
          <LightLabel>{formatDate(createdAt, "MMMM D, YYYY h:m a")}</LightLabel>
        </>
      );
    }),
    column("download", false, "", ({ active, document: { id, fileType } }) => {
      const loading = downloadLoading(id);
      if (!active || fileType !== "File") return null;
      return (
        <PrimaryButton disabled={loading} onClick={() => downloadFile(id)}>
          {loading ? <LoadingSpinner color="light" /> : "Download"}
        </PrimaryButton>
      );
    }),
  ];

  const table = (
    <StyledTable>
      <thead>
        <tr>
          {columns.map(({ name, header }) => (
            <th key={name} className={`${name}-cell`}>
              <div className={`${name}-cell`}>{header}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((activity) => (
          <tr
            key={activity.id}
            className={clsx(!activity.active && "inactive")}
          >
            {columns.map(({ name, link, cell }) => (
              <td key={name} className={`${name}-cell`}>
                {link &&
                activity.active &&
                !activity.id.startsWith("skeleton") ? (
                  <Link
                    className={`${name}-cell`}
                    to={getLink(activity.document.id, "single")}
                  >
                    {cell(activity)}
                  </Link>
                ) : (
                  <div className={`${name}-cell`}>{cell(activity)}</div>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );

  return (
    <Root>
      {table}
      {empty && <EmptyRow>This folder is empty</EmptyRow>}
    </Root>
  );
});

const column = (
  name: string,
  link: boolean,
  header: React.ReactNode,
  cell: (activity: Data) => React.ReactNode
) => ({ name, link, header, cell });

const Root = styled.div`
  overflow-x: auto;
  min-height: 382px;
`;

const StyledTable = styled(Table)`
  tr.inactive {
    opacity: 0.5;
  }
  .thumbnail-cell {
    width: 3rem;
  }
  td.name-cell {
    max-width: 20rem;
  }
  .size-cell {
    justify-content: flex-end;
  }
  .groups-cell {
    max-width: 20rem;
  }
  td .date-cell {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 1;
    > ${LightLabel} {
      margin: 0.125rem 0;
    }
  }
  .download-cell {
    justify-content: center;
  }
`;
