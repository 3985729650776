import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ApplicationsViewerFragment } from "~/components/Settings/Applications.generated";
import { black, white } from "~/styles/theme/color";

export const salesforceImage = `${DJ_CONST.STATIC_URL}images/salesforce-logo.jpg`;

export const APPLICATIONS_VIEWER_FRAGMENT = gql`
  fragment ApplicationsViewer on User {
    id
    hasSalesforceToken
  }
`;

export interface ApplicationsProps {
  viewer: ApplicationsViewerFragment;
}

export const Applications = ({ viewer }: ApplicationsProps) => {
  return (
    <>
      <h4 className="mb-0">Applications</h4>
      <div className="mb-3">
        "Third party apps augment and extend your workflows from the Dock
        platform."
      </div>
      <div className="row">
        <div className="col-12">
          <div className="settings-page mb-5">
            <h3 className="settings-page__h3 mt-1">Installed Apps</h3>
            <hr className="settings-page__hr mb-3 mt-3" />
            {!viewer.hasSalesforceToken && (
              <div className={"config-entry"}>No apps installed</div>
            )}
            {viewer.hasSalesforceToken && (
              <AppArea as={Link} to="/applications/salesforce">
                <AppIcon>
                  <img src={salesforceImage} alt="Salesforce" />
                </AppIcon>
                <AppDescription>
                  <AppTitle>Salesforce</AppTitle>
                  <div>
                    Send Project/Account information and run/view duplication
                    reports
                  </div>
                </AppDescription>
              </AppArea>
            )}
            <h3 className="settings-page__h3 mt-4">Available Apps</h3>
            <hr className="settings-page__hr mb-3 mt-3" />
            {viewer?.hasSalesforceToken && (
              <div className={"config-entry"}>No additional apps available</div>
            )}
            {!viewer?.hasSalesforceToken && (
              <AppArea>
                <AppIcon>
                  <img src={salesforceImage} alt="Salesforce" />
                </AppIcon>
                <AppDescription>
                  <AppTitle>Salesforce</AppTitle>
                  <div>
                    Connect your Salesforce account with CleanCapital to send
                    Project/Account imports.
                  </div>
                </AppDescription>
                <a
                  type="button"
                  className="btn btn-primary"
                  href={"/salesforce/oauth/?applications=true"}
                >
                  Connect to Salesforce
                </a>
              </AppArea>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const AppArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  background-color: #f2f2f2;
  color: ${black};
`;

const AppIcon = styled.div`
  height: 3.5rem;
  width: 3.5rem;
  margin-right: 0.75rem;
  flex: 0 0 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${white};
  border-radius: 4px;
  max-height: 70%;

  > img {
    width: 2.875rem;
    flex: 0 0 2.875rem;
  }
`;

const AppDescription = styled.div`
  flex: 1 1 auto;
  margin-right: 0.75rem;
`;

const AppTitle = styled.div`
  font-weight: bold;
`;
