import { gql } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";

import { NavbarViewerFragment } from "~/Navbar.generated";

export const FOOTER_VIEWER_FRAGMENT = gql`
  fragment FooterViewer on User {
    id
    isSuperUser
  }
`;

export interface FooterProps {
  viewer: NavbarViewerFragment;
}

export const Footer = ({ viewer }: FooterProps) => {
  return (
    <footer className="footer clearfix">
      <div className="container">
        <ul className="nav nav-footer">
          <li className="nav-item">
            <a className="nav-link" href="/support">
              Support
            </a>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/about">
              About
            </Link>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              href="https://www.dock.energy/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              href="https://www.dock.energy/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </li>

          {viewer.isSuperUser && (
            <li className="nav-item">
              <a className="nav-link text-warning" href="/tagauks">
                Admin
              </a>
            </li>
          )}
        </ul>
        <div className="footer-copyright">
          Copyright &copy; {new Date().getFullYear()} Dock Energy, Inc - All
          Rights Reserved.
        </div>
      </div>
    </footer>
  );
};
