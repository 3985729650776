// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UserAggsDataFragment = (
  { readonly __typename: 'UserAggs' }
  & Pick<Types.UserAggs, 'id' | 'sessionCount' | 'downloadCount' | 'viewCount'>
  & { readonly user?: Types.Maybe<(
    { readonly __typename: 'User' }
    & Pick<Types.User, 'fullName'>
  )>, readonly company?: Types.Maybe<(
    { readonly __typename: 'Company' }
    & Pick<Types.Company, 'name'>
  )> }
);

export type TreeUserAnalyticsQueryVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  ordering?: Types.Maybe<Types.Scalars['String']>;
}>;


export type TreeUserAnalyticsQuery = (
  { readonly __typename: 'Query' }
  & { readonly userAggs: (
    { readonly __typename: 'PaginatedUserAggs' }
    & Pick<Types.PaginatedUserAggs, 'count' | 'maxSession' | 'maxDownload' | 'maxView'>
    & { readonly users?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'UserAggs' }
      & UserAggsDataFragment
    )>> }
  ) }
);

export const UserAggsDataFragmentDoc = gql`
    fragment UserAggsData on UserAggs {
  id
  user {
    fullName
  }
  company {
    name
  }
  sessionCount
  downloadCount
  viewCount
}
    `;
export const TreeUserAnalyticsDocument = gql`
    query TreeUserAnalytics($treeId: ID!, $limit: Int, $offset: Int, $ordering: String) {
  userAggs(treeId: $treeId, limit: $limit, offset: $offset, ordering: $ordering) {
    count
    maxSession
    maxDownload
    maxView
    users {
      ...UserAggsData
    }
  }
}
    ${UserAggsDataFragmentDoc}`;

/**
 * __useTreeUserAnalyticsQuery__
 *
 * To run a query within a React component, call `useTreeUserAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreeUserAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreeUserAnalyticsQuery({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useTreeUserAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<TreeUserAnalyticsQuery, TreeUserAnalyticsQueryVariables>) {
        return Apollo.useQuery<TreeUserAnalyticsQuery, TreeUserAnalyticsQueryVariables>(TreeUserAnalyticsDocument, baseOptions);
      }
export function useTreeUserAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TreeUserAnalyticsQuery, TreeUserAnalyticsQueryVariables>) {
          return Apollo.useLazyQuery<TreeUserAnalyticsQuery, TreeUserAnalyticsQueryVariables>(TreeUserAnalyticsDocument, baseOptions);
        }
export type TreeUserAnalyticsQueryHookResult = ReturnType<typeof useTreeUserAnalyticsQuery>;
export type TreeUserAnalyticsLazyQueryHookResult = ReturnType<typeof useTreeUserAnalyticsLazyQuery>;
export type TreeUserAnalyticsQueryResult = Apollo.QueryResult<TreeUserAnalyticsQuery, TreeUserAnalyticsQueryVariables>;