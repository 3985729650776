import React, { useMemo } from "react";
import { Node } from "slate";

import { CellEditor } from "~/components/DataGrid";
import useRemoteState from "~/hooks/useRemoteState";

import { useUpdateAnswerMutation } from "../api/mutations.generated";
import { useSaving } from "../context/QAndAContext";
import { useQuestion } from "../context/QuestionContext";

interface QuestionAnswerProps {
  canEditQuestionAnswers: boolean;
}

const QuestionAnswer = ({ canEditQuestionAnswers }: QuestionAnswerProps) => {
  const question = useQuestion();
  const [updateAnswer, { loading }] = useUpdateAnswerMutation();

  useSaving(loading);

  const readOnly = question.isClosed || !canEditQuestionAnswers;

  // TODO: Implement a `merge` function for realtime collaboration
  const [value, setValue] = useRemoteState<Node[]>(
    useMemo(() => JSON.parse(question.answer.body), [question.answer.body]),
    (value) =>
      updateAnswer({
        variables: { questionId: question.id, body: JSON.stringify(value) },
      })
  );

  return <CellEditor readOnly={readOnly} value={value} onChange={setValue} />;
};

export default QuestionAnswer;
