import { useCallback, useState } from "react";

const useForceUpdate = () => {
  const [, setCount] = useState(0);

  const forceUpdate = useCallback(() => {
    setCount((c) => c + 1);
  }, [setCount]);

  return forceUpdate;
};

export default useForceUpdate;
