import { ellipsis } from "polished";
import React from "react";
import styled from "styled-components";

import { CaretIcon } from "~/components/common/icons";
import { fgColor } from "~/styles/mixins";

export const Breadcrumbs: React.FC = ({ children }) => {
  const items = intersperseSeparators(React.Children.toArray(children));

  return <BreadcrumbsList>{items}</BreadcrumbsList>;
};

export const BreadcrumbsList = styled.ol`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const BreadcrumbsItem = styled.li`
  &:last-child {
    ${ellipsis()};
  }
`;

export const BreadcrumbsSeparator = styled.li`
  ${fgColor.gray700()};
  display: flex;
  user-select: none;
  margin: 0em 0.1em 0rem 0.2em;
  font-size: 0.75em;
`;

const intersperseSeparators = (items: React.ReactNode[]): React.ReactNode[] =>
  items
    .filter((item) => React.isValidElement(item))
    .map((item, index) => (
      <BreadcrumbsItem key={`item-${index}`}>{item}</BreadcrumbsItem>
    ))
    .reduce<React.ReactNode[]>((items, item, index) => {
      if (index > 0) {
        items.push(
          <BreadcrumbsSeparator key={`separator-${index - 1}`} aria-hidden>
            <CaretIcon />
          </BreadcrumbsSeparator>
        );
      }
      items.push(item);
      return items;
    }, []);
