import * as PropTypes from "prop-types";
import { speReducedType } from "propTypes/portfolios";
import React from "react";

import Card from "./Card";

const portfolioUrl = (portfolio) => `/portfolios/${portfolio.slug}`;
export const defaultCoverImage = `${DJ_CONST.STATIC_URL}images/portfolio/portfolio-image5.jpg`;
export const systemSize = (portfolio) => {
  if (!portfolio.dynamic_system_size) {
    return null;
  }
  const size = Math.floor(portfolio.dynamic_system_size).toLocaleString("en", {
    useGrouping: true,
  });
  return `${size} kW`;
};

const PortfolioCard = ({ portfolio, isAcquired }) => {
  const stats = [
    ["System Size", systemSize(portfolio)],
    ["Projects", portfolio.dynamic_number_of_projects],
  ];

  if (isAcquired) {
    stats.push([
      "Remaining PPA",
      portfolio.dynamic_remaining_ppa_term
        ? `${portfolio.dynamic_remaining_ppa_term} Yrs`
        : null,
    ]);
  } else {
    stats.push([
      "Deal Manager",
      portfolio.deal_manager ? portfolio.deal_manager.name : null,
    ]);
  }

  return (
    <a className="link-unstyled" href={portfolioUrl(portfolio)}>
      <Card
        title={portfolio.name}
        stats={stats}
        picture={
          <img
            src={
              portfolio.cover_image ? portfolio.cover_image : defaultCoverImage
            }
            alt="portfolio"
          />
        }
      />
    </a>
  );
};

PortfolioCard.propTypes = {
  portfolio: speReducedType.isRequired,
  isAcquired: PropTypes.bool,
};

PortfolioCard.defaultProps = {
  isAcquired: false,
};

export default PortfolioCard;
