import gql from "graphql-tag";
import { compact } from "lodash";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { DealRoomCardFragment } from "~/components/DealRoom/DealRoomCard.generated";
import { device } from "~/styles/mixins";

import { DEAL_ROOM_CARD_FRAGMENT, DealRoomCard } from "./DealRoomCard";
import { DealRoomGridFragment } from "./DealRoomGrid.generated";

export const DEAL_ROOM_GRID_FRAGMENT = gql`
  fragment DealRoomGrid on FundEntityCollection {
    entities {
      ...DealRoomCard
    }
  }

  ${DEAL_ROOM_CARD_FRAGMENT}
`;

export interface DealRoomGridProps {
  entities?: DealRoomGridFragment["entities"];
  emptyState?: ReactNode;
  onClickCard?: (entity: DealRoomCardFragment) => void;
}

export const DealRoomGrid = ({
  entities,
  emptyState,
  onClickCard,
}: DealRoomGridProps) => {
  const cards = compact(entities);

  if (cards.length === 0) {
    return <>{emptyState}</>;
  }

  return (
    <Grid>
      {cards.map((entity) => (
        <DealRoomCard
          key={`${entity.__typename}-${entity.id}`}
          entity={entity}
          onClick={onClickCard}
        />
      ))}
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(1, 1fr);
  // force cards parent (link anchor) to take 100% of the available frame to allow truncation of longer texts
  a {
    min-width: 100%;
  }
  @media ${device.md} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
