// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { GroupRowFragment } from './GroupsTable.generated';
import { gql } from '@apollo/client';
import { GroupRowFragmentDoc } from './GroupsTable.generated';
import * as Apollo from '@apollo/client';
export type UpdateGroupMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  groupId: Types.Scalars['ID'];
  name?: Types.Maybe<Types.Scalars['String']>;
  roleId?: Types.Maybe<Types.Scalars['ID']>;
  portfolios?: Types.Maybe<ReadonlyArray<Types.PortfolioInput> | Types.PortfolioInput>;
}>;


export type UpdateGroupMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateGroup?: Types.Maybe<(
    { readonly __typename: 'UserGroup' }
    & GroupRowFragment
  )> }
);


export const UpdateGroupDocument = gql`
    mutation UpdateGroup($orgSlug: String!, $groupId: ID!, $name: String, $roleId: ID, $portfolios: [PortfolioInput!]) {
  updateGroup(
    orgSlug: $orgSlug
    groupId: $groupId
    name: $name
    roleId: $roleId
    portfolios: $portfolios
  ) {
    ...GroupRow
  }
}
    ${GroupRowFragmentDoc}`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      groupId: // value for 'groupId'
 *      name: // value for 'name'
 *      roleId: // value for 'roleId'
 *      portfolios: // value for 'portfolios'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, baseOptions);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;