import { PencilIcon } from "components/icons";
import * as PropTypes from "prop-types";
import React from "react";
import { gettext } from "utils/text";

export class ParentChecklistItem extends React.PureComponent {
  static propTypes = {
    setSelectedChecklistItemId: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    selectedChecklistItemId: PropTypes.string,
    style: PropTypes.object,
    item: PropTypes.shape({
      link: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { item, isLoading, style, selectedChecklistItemId } = this.props;
    const isOpen = selectedChecklistItemId === item.id;
    const checklistItem = item;
    const itemLink = checklistItem.link;

    let cellHighlightClass = "";
    if (isOpen) {
      cellHighlightClass = "cell--highlighted info";
    }

    // first level categories are without parents
    let docs = <span title="click to edit">No docs linked</span>;
    let onClick = () =>
      this.props.setSelectedChecklistItemId([checklistItem.id]);
    let className = cellHighlightClass;

    if (itemLink) {
      if (isLoading) {
        docs = <div>{gettext("Loading...")}</div>;
      } else {
        docs = (
          <div>
            <a href={itemLink}>View Docs</a>
            <PencilIcon className="ml-1" onClick={onClick} withoutBackground />
          </div>
        );
      }
      onClick = null;
      className += " td--no-hover";
    }

    return (
      <div
        className={`checklist-entry sticky-table-cell docs-cell-${checklistItem.id} ${className}`}
        key={item.id}
        onClick={onClick}
        style={style}
        // Firefox selects the contents of a cell when ctrl clicking, which looks
        // weird when doing multiple-selections in the checklist,
        // this allows us to prevent that while still allowing normal copy+paste
        onMouseDown={(e) => (e.ctrlKey || e.metaKey) && e.preventDefault()}
      >
        {docs}
      </div>
    );
  }
}

ParentChecklistItem.defaultProps = { style: {} };

export default ParentChecklistItem;
