// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CompanyAggsDataFragment = (
  { readonly __typename: 'CompanyAggs' }
  & Pick<Types.CompanyAggs, 'id' | 'userCount' | 'sessionCount' | 'downloadCount' | 'viewCount'>
  & { readonly company?: Types.Maybe<(
    { readonly __typename: 'Company' }
    & Pick<Types.Company, 'name'>
  )> }
);

export type TreeCompanyAnalyticsQueryVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  ordering?: Types.Maybe<Types.Scalars['String']>;
}>;


export type TreeCompanyAnalyticsQuery = (
  { readonly __typename: 'Query' }
  & { readonly companyAggs: (
    { readonly __typename: 'PaginatedCompanyAggs' }
    & Pick<Types.PaginatedCompanyAggs, 'count' | 'maxUser' | 'maxSession' | 'maxDownload' | 'maxView'>
    & { readonly companies?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'CompanyAggs' }
      & CompanyAggsDataFragment
    )>> }
  ) }
);

export const CompanyAggsDataFragmentDoc = gql`
    fragment CompanyAggsData on CompanyAggs {
  id
  company {
    name
  }
  userCount
  sessionCount
  downloadCount
  viewCount
}
    `;
export const TreeCompanyAnalyticsDocument = gql`
    query TreeCompanyAnalytics($treeId: ID!, $limit: Int, $offset: Int, $ordering: String) {
  companyAggs(
    treeId: $treeId
    limit: $limit
    offset: $offset
    ordering: $ordering
  ) {
    count
    maxUser
    maxSession
    maxDownload
    maxView
    companies {
      ...CompanyAggsData
    }
  }
}
    ${CompanyAggsDataFragmentDoc}`;

/**
 * __useTreeCompanyAnalyticsQuery__
 *
 * To run a query within a React component, call `useTreeCompanyAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreeCompanyAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreeCompanyAnalyticsQuery({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useTreeCompanyAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<TreeCompanyAnalyticsQuery, TreeCompanyAnalyticsQueryVariables>) {
        return Apollo.useQuery<TreeCompanyAnalyticsQuery, TreeCompanyAnalyticsQueryVariables>(TreeCompanyAnalyticsDocument, baseOptions);
      }
export function useTreeCompanyAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TreeCompanyAnalyticsQuery, TreeCompanyAnalyticsQueryVariables>) {
          return Apollo.useLazyQuery<TreeCompanyAnalyticsQuery, TreeCompanyAnalyticsQueryVariables>(TreeCompanyAnalyticsDocument, baseOptions);
        }
export type TreeCompanyAnalyticsQueryHookResult = ReturnType<typeof useTreeCompanyAnalyticsQuery>;
export type TreeCompanyAnalyticsLazyQueryHookResult = ReturnType<typeof useTreeCompanyAnalyticsLazyQuery>;
export type TreeCompanyAnalyticsQueryResult = Apollo.QueryResult<TreeCompanyAnalyticsQuery, TreeCompanyAnalyticsQueryVariables>;