// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { DealRoomCard_HoldingCompany_Fragment, DealRoomCard_Portfolio_Fragment } from '../DealRoom/DealRoomCard.generated';
import { gql } from '@apollo/client';
import { DealRoomCardFragmentDoc } from '../DealRoom/DealRoomCard.generated';
export type RecentlyVisitedFragment = (
  { readonly __typename: 'Query' }
  & { readonly recentlyVisited?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'HoldingCompany' }
    & DealRoomCard_HoldingCompany_Fragment
  ) | (
    { readonly __typename: 'Portfolio' }
    & DealRoomCard_Portfolio_Fragment
  )>> }
);

export const RecentlyVisitedFragmentDoc = gql`
    fragment RecentlyVisited on Query {
  recentlyVisited(limit: $recentlyVisitedLimit) {
    ...DealRoomCard
  }
}
    ${DealRoomCardFragmentDoc}`;