// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { OrgRoleFragment } from './NewGroupDialog.generated';
import { GroupRowFragment } from './GroupsTable.generated';
import { gql } from '@apollo/client';
import { OrgRoleFragmentDoc } from './NewGroupDialog.generated';
import { GroupRowFragmentDoc } from './GroupsTable.generated';
import * as Apollo from '@apollo/client';
export type OrgRolesQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
}>;


export type OrgRolesQuery = (
  { readonly __typename: 'Query' }
  & { readonly roles?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Role' }
    & OrgRoleFragment
  )>> }
);

export type OrgGroupsQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  ordering?: Types.Maybe<Types.Scalars['String']>;
  search?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type OrgGroupsQuery = (
  { readonly __typename: 'Query' }
  & { readonly groupPages: (
    { readonly __typename: 'PaginatedGroups' }
    & Pick<Types.PaginatedGroups, 'count'>
    & { readonly groups?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'UserGroup' }
      & GroupRowFragment
    )>> }
  ) }
);


export const OrgRolesDocument = gql`
    query OrgRoles($orgSlug: String!) {
  roles(orgSlug: $orgSlug) {
    ...OrgRole
  }
}
    ${OrgRoleFragmentDoc}`;

/**
 * __useOrgRolesQuery__
 *
 * To run a query within a React component, call `useOrgRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgRolesQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useOrgRolesQuery(baseOptions: Apollo.QueryHookOptions<OrgRolesQuery, OrgRolesQueryVariables>) {
        return Apollo.useQuery<OrgRolesQuery, OrgRolesQueryVariables>(OrgRolesDocument, baseOptions);
      }
export function useOrgRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgRolesQuery, OrgRolesQueryVariables>) {
          return Apollo.useLazyQuery<OrgRolesQuery, OrgRolesQueryVariables>(OrgRolesDocument, baseOptions);
        }
export type OrgRolesQueryHookResult = ReturnType<typeof useOrgRolesQuery>;
export type OrgRolesLazyQueryHookResult = ReturnType<typeof useOrgRolesLazyQuery>;
export type OrgRolesQueryResult = Apollo.QueryResult<OrgRolesQuery, OrgRolesQueryVariables>;
export const OrgGroupsDocument = gql`
    query OrgGroups($orgSlug: String!, $ordering: String, $search: String, $offset: Int, $limit: Int) {
  groupPages(
    orgSlug: $orgSlug
    ordering: $ordering
    search: $search
    limit: $limit
    offset: $offset
  ) {
    count
    groups {
      ...GroupRow
    }
  }
}
    ${GroupRowFragmentDoc}`;

/**
 * __useOrgGroupsQuery__
 *
 * To run a query within a React component, call `useOrgGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgGroupsQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      ordering: // value for 'ordering'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useOrgGroupsQuery(baseOptions: Apollo.QueryHookOptions<OrgGroupsQuery, OrgGroupsQueryVariables>) {
        return Apollo.useQuery<OrgGroupsQuery, OrgGroupsQueryVariables>(OrgGroupsDocument, baseOptions);
      }
export function useOrgGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgGroupsQuery, OrgGroupsQueryVariables>) {
          return Apollo.useLazyQuery<OrgGroupsQuery, OrgGroupsQueryVariables>(OrgGroupsDocument, baseOptions);
        }
export type OrgGroupsQueryHookResult = ReturnType<typeof useOrgGroupsQuery>;
export type OrgGroupsLazyQueryHookResult = ReturnType<typeof useOrgGroupsLazyQuery>;
export type OrgGroupsQueryResult = Apollo.QueryResult<OrgGroupsQuery, OrgGroupsQueryVariables>;