import { createContext } from "react";

export interface ActionMenu {
  focusIndex: number;
  setFocusIndex: (index: number) => unknown;
  closeOnSelect: boolean;
}

const ActionMenuContext = createContext<ActionMenu>({
  focusIndex: 0,
  setFocusIndex: () => {},
  closeOnSelect: true,
});

export default ActionMenuContext;
