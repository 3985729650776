import * as PropTypes from "prop-types";
import React from "react";

/**
 * The CloseButton component
 */
export const CloseButton = ({ onClick }) => (
  // FIXME: Should be a <button /> instead of an <a />
  // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
  <a
    className="btn--close"
    onClick={onClick}
    aria-label="close-button"
    title={gettext("Close")}
  />
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
