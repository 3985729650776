// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { ReportingBannerOperatingStatsFragment, ReportingBannerProjectsStatsFragment } from './ReportingBanner.generated';
import { gql } from '@apollo/client';
import { ReportingBannerOperatingStatsFragmentDoc, ReportingBannerProjectsStatsFragmentDoc } from './ReportingBanner.generated';
export type ReportingDetailFragment = (
  { readonly __typename: 'Query' }
  & { readonly reportingHoldingCompanies?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'HoldingCompany' }
    & Pick<Types.HoldingCompany, 'id' | 'name' | 'slug'>
    & { readonly reportsTree?: Types.Maybe<(
      { readonly __typename: 'DocumentTree' }
      & Pick<Types.DocumentTree, 'id'>
    )>, readonly operatingStats?: Types.Maybe<(
      { readonly __typename: 'OperatingStats' }
      & ReportingBannerOperatingStatsFragment
    )>, readonly projectsStats?: Types.Maybe<(
      { readonly __typename: 'ProjectsStats' }
      & ReportingBannerProjectsStatsFragment
    )> }
  )>>> }
);

export const ReportingDetailFragmentDoc = gql`
    fragment ReportingDetail on Query {
  reportingHoldingCompanies(orgSlug: $orgSlug) {
    id
    name
    slug
    reportsTree {
      id
    }
    operatingStats {
      ...ReportingBannerOperatingStats
    }
    projectsStats {
      ...ReportingBannerProjectsStats
    }
  }
}
    ${ReportingBannerOperatingStatsFragmentDoc}
${ReportingBannerProjectsStatsFragmentDoc}`;