import { Projects } from "components/Projects/ProjectsLayout";
import React from "react";
import { Redirect } from "react-router";
import { Route, Switch } from "react-router-dom";

import { PermissionType } from "~/api/types.generated";
import { AdminPage } from "~/components/Admin/AdminPage";
import { DealRoom } from "~/components/DealRoom";
import { OAndM } from "~/components/Documents/OAndM";
import { HoldingCompanyPage } from "~/components/HoldingCompany/HoldingCompanyPage";
import { HomePage } from "~/components/HomePage";
import { HomePageViewerFragment } from "~/components/HomePage/HomePage.generated";
import { PortfolioPage } from "~/components/Portfolio/PortfolioPage";
import EditProjectPage from "~/components/Projects/EditProjectPage";
import NewProjectPage from "~/components/Projects/NewProjectPage";
import { ReportingPage } from "~/components/Reporting/ReportingPage";
import { SettingsPage } from "~/components/Settings/SettingsPage";
import { About } from "~/pages/About";
import { NotFound } from "~/pages/NotFound";
import { Support } from "~/pages/Support";
import useViewerPermissions from "~/permissions/useViewerPermissions";

export interface RoutesProps {
  viewer: HomePageViewerFragment;
}

export const Routes = ({ viewer }: RoutesProps) => {
  const permissions = useViewerPermissions(viewer);
  const isSuperUser = !!viewer.isSuperUser;
  // TODO: This should be on an org-by-org basis
  const isOrgAdmin = Object.entries(permissions.byOrg).some(
    ([_, perms]) =>
      perms.includes(PermissionType.OrgAdmin) ||
      perms.includes(PermissionType.UsersAdmin)
  );
  const isUsersAdmin = Object.entries(permissions.byOrg).some(([_, perms]) =>
    perms.includes(PermissionType.UsersAdmin)
  );
  const isOAndMAdmin = Object.entries(permissions.byOrg).some(([_, perms]) =>
    perms.includes(PermissionType.OAndMAdmin)
  );
  const canViewDocumentsHistory = Object.entries(permissions.byOrg).some(
    ([_, perms]) => perms.includes(PermissionType.DocumentsHistoryView)
  );
  const showDealRoom = Object.entries(permissions.byOrg).some(([_, perms]) =>
    perms.includes(PermissionType.DealroomView)
  );
  const showReporting = Object.entries(permissions.byOrg).some(([_, perms]) =>
    perms.includes(PermissionType.ReportsView)
  );

  return (
    <Switch>
      <Route path="/" exact>
        <HomeRoutes
          showReporting={showReporting}
          showDealRoom={showDealRoom}
          isAdmin={isSuperUser}
          viewer={viewer}
        />
      </Route>
      <Route path="/dealroom">
        <DealRoom isAdmin={isSuperUser} viewer={viewer} />
      </Route>
      <Route path="/:orgSlug/o-and-m">
        <OAndM
          isAdmin={isSuperUser}
          isUsersAdmin={isUsersAdmin}
          isOAndMAdmin={isOAndMAdmin}
          featureFlagsByOrg={permissions.featureFlagsByOrg}
          canViewDocumentsHistory={canViewDocumentsHistory}
        />
      </Route>
      <Route exact path="/:orgSlug/projects/new">
        <NewProjectPage permissionsByOrg={permissions.byOrg} />
      </Route>
      <Route exact path="/:orgSlug/projects/:projectId/edit">
        <EditProjectPage permissionsByOrg={permissions.byOrg} />
      </Route>
      <Route path="/:orgSlug/projects/:projectId">
        <Projects permissionsByOrg={permissions.byOrg} />
      </Route>
      <Route exact path="/:orgSlug/projects">
        <Projects permissionsByOrg={permissions.byOrg} />
      </Route>
      <Route path="/portfolios/:slug">
        <PortfolioPage
          portfolioPermissions={permissions.bySubject.portfolio}
          viewer={viewer}
          featureFlagsByOrg={permissions.featureFlagsByOrg}
        />
      </Route>
      <Route path="/holding-company/:slug">
        <HoldingCompanyPage
          holdingCompanyPermissions={permissions.bySubject.holdingCompany}
          viewer={viewer}
          featureFlagsByOrg={permissions.featureFlagsByOrg}
        />
      </Route>
      <Route path="/profile">
        <SettingsPage viewer={viewer} isAdmin={isSuperUser} />
      </Route>
      {isOrgAdmin && (
        <Route path="/:orgSlug/admin">
          <AdminPage viewer={viewer} />
        </Route>
      )}
      <Route path="/:orgSlug/reporting">
        <ReportingPage
          holdingCompanyPermissions={permissions.bySubject.holdingCompany}
          featureFlagsByOrg={permissions.featureFlagsByOrg}
        />
      </Route>
      <Route path="/support">
        <Support />
      </Route>
      <Route path="/about">
        <About />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

interface HomeRoutesProps {
  isAdmin: boolean;
  showDealRoom: boolean;
  showReporting: boolean;
  viewer: HomePageViewerFragment;
}

export const HomeRoutes = ({
  isAdmin,
  showDealRoom,
  showReporting,
  viewer,
}: HomeRoutesProps) => {
  const primaryPortfolio = DJ_CONST.user.primary_portfolio;
  const redirectPortfolio = !showDealRoom && !showReporting && primaryPortfolio;
  const redirectDealRoom = showDealRoom && !showReporting;
  const emptyView = !showDealRoom && !showReporting && !primaryPortfolio;

  return (
    <Switch>
      {redirectPortfolio && <Redirect to={`/portfolios/${primaryPortfolio}`} />}
      {redirectDealRoom && <Redirect to="/dealroom" />}
      <Route>
        <HomePage showAdmin={isAdmin} viewer={viewer} emptyView={emptyView} />
      </Route>
    </Switch>
  );
};
