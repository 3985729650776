import { gql } from "@apollo/client/core";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers as FormikActions,
} from "formik";
import React, { useState } from "react";
import * as yup from "yup";

import { PageContainer } from "~/components/common/layout";
import { PrimaryPanel } from "~/components/common/panels";
import {
  PrimaryHeading,
  SecondaryHeading,
  Text,
} from "~/components/common/text";
import { ScrollToTop } from "~/components/utils/ScrollToTop";
import { useCreateSupportTicketMutation } from "~/pages/Support.generated";

export const SUPPORT_TICKET_MUTATION_FRAGMENT = gql`
  mutation CreateSupportTicket($issue: String!, $description: String!) {
    createSupportTicket(issue: $issue, description: $description) {
      success
    }
  }
`;

interface FormFields {
  issue: string;
  description: string;
}

const validationSchema = yup.object().shape({
  issue: yup
    .string()
    .required("This field is required")
    .test("length", "Max 128 characters", (val) => !!val && val.length <= 128),
  description: yup
    .string()
    .required("This field is required")
    .test(
      "length",
      "Max 1024 characters",
      (val) => !!val && val.length <= 1024
    ),
});

export const Support = () => {
  const [created, setCreated] = useState(false);
  const [createTicket] = useCreateSupportTicketMutation({
    onCompleted: () => setCreated(true),
  });
  const handleSubmit = (
    formFields: FormFields,
    actions: FormikActions<FormFields>
  ) => {
    createTicket({
      variables: {
        issue: formFields.issue,
        description: formFields.description,
      },
    }).then(() => actions.setSubmitting(false));
  };
  const newSupportClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    setCreated(false);
  };
  return (
    <PageContainer>
      <ScrollToTop />
      <PrimaryHeading>Support</PrimaryHeading>
      <PrimaryPanel>
        {created && (
          <>
            <Text>Thank you for contacting us.</Text>
            <Text>We'll follow up shortly.</Text>
            <p>
              Submit a<>&nbsp;</>
              <a href="/" onClick={newSupportClick}>
                new support
              </a>
              <>&nbsp;</>issue. Or, return to <a href="/">home</a>
            </p>
          </>
        )}
        {!created && (
          <Formik
            initialValues={{
              issue: "",
              description: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <div className="row mb-4">
                <div className="col-lg-7">
                  <Form>
                    <SecondaryHeading>
                      Please contact us if you're having trouble using the
                      platform
                    </SecondaryHeading>
                    <div className="mt-3">
                      <label htmlFor="issue">Issue</label>
                      <Field
                        className="input-group form-control"
                        id="issue"
                        name="issue"
                      />
                      <p className="text-danger">
                        <ErrorMessage name="issue" />
                      </p>
                    </div>
                    <div className="mt-3">
                      <label htmlFor="description">Description</label>
                      <Field
                        className="input-group textarea form-control"
                        component="textarea"
                        id="description"
                        name="description"
                        rows="10"
                        cols="40"
                      />
                      <p className="text-danger">
                        <ErrorMessage name="description" />
                      </p>
                    </div>
                    <div className="privacy-statement mb-3">
                      Your feedback helps us to improve. Many thanks in advance
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </Formik>
        )}
      </PrimaryPanel>
    </PageContainer>
  );
};
