import { useDebounce } from "use-debounce";

import { DocumentsRouting } from "./useDocumentsRouting";

export interface DocumentsSearch {
  searchTerm: string;
  searchAll: boolean;
  searchId: string;
  debouncedSearchTerm: string;
  searchPending: boolean;
  showSearch: boolean;
  highlight: string | null;
}

/** Extracts search-related state from the URL / routing state */
export const useDocumentsSearch = ({
  rootId,
  documentId,
  queryParams,
}: DocumentsRouting): DocumentsSearch => {
  const searchTerm = queryParams.get("q") ?? "";
  const searchAll = queryParams.get("qs") === "all";
  const searchId = searchAll ? rootId : documentId;
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300, { leading: true });
  const searchPending = searchTerm !== debouncedSearchTerm;
  const showSearch = debouncedSearchTerm.length >= 3;
  const highlight = showSearch ? debouncedSearchTerm : null;

  return {
    searchTerm,
    searchAll,
    searchId,
    debouncedSearchTerm,
    searchPending,
    showSearch,
    highlight,
  };
};
