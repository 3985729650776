import styled from "styled-components";

const StatSection = styled.div<{ collapse?: boolean }>`
  display: ${(props) => (props.collapse ? "none" : "flex")};
  color: #ffffff;
  flex-grow: 1;
  height: 73px;
`;

export default StatSection;
