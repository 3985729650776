import { LocationDescriptorObject } from "history";
import React from "react";
import { Link } from "react-router-dom";

import { CopyButton, MenuButton } from "~/components/common/buttons";
import { DownloadIcon, NewTabIcon, TrashIcon } from "~/components/common/icons";
import { SecondaryPanel } from "~/components/common/panels";
import { Toolbar } from "~/components/common/toolbars";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";

import { DocumentsPrevNext } from "./DocumentsPrevNext";

export interface DocumentsSidebarProps {
  track: DocumentsEventTracking;
  documentId: string;
  prevId?: string;
  nextId?: string;
  downloadFile: (documentId: string) => void;
  getLink: (documentId: string) => LocationDescriptorObject;
  getFullLink: (documentId: string) => string;
  remove: () => void;
}

export const DocumentsSidebar = React.memo(function DocumentsSidebar(
  props: DocumentsSidebarProps
) {
  const {
    track,
    documentId,
    prevId,
    nextId,
    downloadFile,
    getLink,
    getFullLink,
    remove,
  } = props;

  return (
    <SecondaryPanel>
      {document && (
        <>
          <Toolbar>
            <DocumentsPrevNext
              prevId={prevId}
              nextId={nextId}
              getLink={getLink}
            />
          </Toolbar>

          <CopyButton
            text={getFullLink(documentId)}
            onClick={() => {
              track.copyLinkClick();
            }}
          />

          <MenuButton onClick={() => downloadFile(documentId)}>
            <DownloadIcon />
            <span>Download</span>
          </MenuButton>

          <MenuButton
            as={Link}
            to={getLink(documentId)}
            target="_blank"
            onClick={() => {
              track.newTabClick();
            }}
          >
            <NewTabIcon />
            <span>Open in new tab</span>
          </MenuButton>

          <MenuButton
            danger
            onClick={() => {
              track.deleteClick();
              remove();
            }}
          >
            <TrashIcon />
            <span>Delete</span>
          </MenuButton>
        </>
      )}
    </SecondaryPanel>
  );
});
