import copy from "copy-to-clipboard";
import { darken } from "polished";
import React from "react";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { CopyLinkIcon, DoneIcon } from "~/components/common/icons";
import {
  bgColor,
  border,
  borderRadius,
  centerContent,
  fgColor,
  focusOutline,
} from "~/styles/mixins";
import {
  blue,
  gray700,
  gray800,
  green,
  lightBlue,
  red,
  white,
} from "~/styles/theme/color";
import { GetPropsWithoutRef } from "~/utils/types";

import { MenuTooltipContainer } from "./tooltips";

export const ButtonGroup = styled.div.attrs({
  role: "group",
})`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  z-index: 0;
`;

export const Button = styled.button`
  ${centerContent};
  ${borderRadius()};
  ${focusOutline()};
  display: inline-flex;
  min-height: 2rem;
  padding: 0 0.5rem;
  user-select: none;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  transition: color 150ms ease-in-out, background-color 150ms ease-in-out,
    border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  white-space: nowrap;

  &:not(:disabled) {
    cursor: pointer;
  }

  :disabled {
    pointer-events: none;
    opacity: 0.65;
  }

  &:hover {
    text-decoration: none;
  }

  & svg {
    width: 1rem;
    height: 1rem;

    &:first-child:not(:last-child) {
      margin-left: -0.125rem;
      margin-right: 0.25rem;
    }

    &:last-child:not(:first-child) {
      margin-left: -0.25rem;
      margin-right: 0.125rem;
    }

    &:first-child:last-child {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: -0.25rem;
      margin-right: -0.25rem;
    }
  }

  & > .loading-spinner {
    margin-right: 0.5rem;
    margin-bottom: 0;
    width: 1rem;
    height: 1rem;
  }

  ${ButtonGroup} > & {
    position: relative;
    flex: 1 1 auto;
    border-radius: 0;
    margin-left: -1px;
    z-index: -1;
    &:first-child {
      ${borderRadius("left")};
      margin-left: 0;
    }
    &:last-child {
      ${borderRadius("right")};
    }
    &.active,
    &:focus {
      z-index: 0;
    }
  }
`;

export const PrimaryButton = styled(Button)`
  ${bgColor.blue()};
  ${fgColor.white()};
  ${border.blue()};

  &.active,
  &:hover {
    ${bgColor.darkBlue()};
    ${fgColor.white()};
  }
`;

export const DangerButton = styled(Button)`
  background-color: ${red};
  color: ${white};
  border: 1px solid ${red};

  &.active,
  &:hover {
    background-color: ${darken("25%", red)};
  }
`;

export const SecondaryButton = styled(Button)`
  ${bgColor.white()};
  ${fgColor.blue()};
  ${border.blue()};

  &.active,
  &:hover {
    ${bgColor.blue()};
    ${fgColor.white()};
  }

  :disabled {
    ${border.gray700()};
    color: ${gray700};

    & svg {
      color: ${gray700};
    }
  }
`;

export const TertiaryButton = styled(SecondaryButton)`
  ${fgColor.gray800()};
  border: none;
`;

export const TextButton = styled(Button)`
  ${bgColor.white()};
  color: ${blue};
  border: none;

  &.active {
    color: ${gray700};
  }

  &:hover {
    color: ${lightBlue};
  }

  :disabled {
    color: ${gray700};
    & svg {
      color: ${blue};
    }
  }
`;

export const MenuButton = styled(Button)<{ danger?: boolean }>`
  ${bgColor.white()};
  color: ${gray800};
  border: none;
  border-radius: 0;
  justify-content: flex-start;
  min-width: 10rem;
  min-height: 2.5rem;
  width: 100%;
  padding-right: 1rem;
  font-weight: normal;
  position: relative;

  &.active,
  &:hover {
    ${bgColor.blue()};
    ${fgColor.white()};
    transition: color 150ms ease-in-out, background-color 150ms ease-in-out;

    & svg {
      ${fgColor.white()};
    }
  }

  &:active,
  &:focus {
    z-index: 1;
  }

  ${MenuTooltipContainer} & {
    &:first-child {
      ${borderRadius("top")};
    }

    &:last-child {
      ${borderRadius("bottom")};
    }
  }

  & svg {
    ${fgColor.blue()};
    width: 1rem;
    height: 1rem;

    &:first-child:not(:last-child) {
      margin-left: 0.5rem;
      margin-right: 0.625rem;
    }
  }

  ${(props) =>
    props.danger &&
    css`
      &:active,
      &:hover {
        ${bgColor.red()};
      }

      & svg {
        ${fgColor.red()};
      }
    `}
`;

export interface CopyButtonProps extends GetPropsWithoutRef<typeof MenuButton> {
  text: string;
}

export const CopyButton = (props: CopyButtonProps) => {
  const { text, ...rest } = props;
  const [copied, setCopied] = useState(false);

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    copy(text);
    setCopied(true);
    rest.onClick?.(event);
  };

  useEffect(() => {
    let timeout: number | null = null;

    if (copied) {
      timeout = window.setTimeout(() => {
        setCopied(false);
      }, 1000);
    }

    return () => {
      if (timeout !== null) clearTimeout(timeout);
    };
  }, [copied]);

  return (
    <MenuButton {...rest} onClick={handleCopy} disabled={copied}>
      {copied ? <DoneIcon style={{ color: green }} /> : <CopyLinkIcon />}
      <span>{copied ? "Copied!" : "Copy link"}</span>
    </MenuButton>
  );
};

export interface FlashButtonProps
  extends GetPropsWithoutRef<typeof MenuButton> {
  label: string;
  message: string;
  icon: React.ReactNode;
}

export const FlashButton = (props: FlashButtonProps) => {
  const { label, message, icon, ...rest } = props;
  const [flashed, setFlashed] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFlashed(true);
    rest.onClick?.(event);
  };

  useEffect(() => {
    let timeout: number | null = null;

    if (flashed) {
      timeout = window.setTimeout(() => {
        setFlashed(false);
      }, 1000);
    }

    return () => {
      if (timeout !== null) clearTimeout(timeout);
    };
  }, [flashed]);

  return (
    <MenuButton {...rest} onClick={handleClick} disabled={flashed}>
      {flashed ? <DoneIcon style={{ color: green }} /> : icon}
      <span>{flashed ? message : label}</span>
    </MenuButton>
  );
};
