import Icon from "components/icons/Icon";
import * as PropTypes from "prop-types";
import React from "react";

class UserIcon extends React.PureComponent {
  render() {
    return (
      <Icon
        color="primary"
        viewBox="0 0 12 16"
        className={this.props.className}
      >
        <path
          fillRule="evenodd"
          d="M8,9 C4.703125,9 2,11.703125 2,15 C2,15.546875 2.453125,16 3,16 L13,16 C13.5546875,16
                    14,15.546875 14,15 C14,11.703125 11.3046875,9 8,9 Z M8,8 C9.6484375,8 11,6.6484375 11,5 L11,3
                    C11,1.3515625 9.6484375,0 8,0 C6.3515625,0 5,1.3515625 5,3 L5,5 C5,6.6484375 6.3515625,8 8,8 Z"
          transform="translate(-2)"
        />
      </Icon>
    );
  }
}

UserIcon.propTypes = {
  className: PropTypes.string,
};

UserIcon.defaultProps = {
  className: null,
};

export default UserIcon;
