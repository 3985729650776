import { gql } from "@apollo/client";
import classNames from "classnames";
import { formattedNumber } from "components/utils/portfolio";
import moment from "moment";
import React, { useState } from "react";
import { calculateSideData, formatSize } from "utils/calculations";

import { WorkflowStatus } from "~/api/types.generated";
import { SortIcon } from "~/components/icons";
import { AdminMenu } from "~/components/Portfolio/AdminMenu";
import { PortfolioHeaderFragment } from "~/components/Portfolio/PortfolioHeader.generated";
import { UpdatePortfolioDialog } from "~/components/Portfolio/UpdateDialog";
import { useUpdatePortfolio } from "~/components/Portfolio/useUpdatePortfolio";
import { getWorkflowStatusDisplay } from "~/components/Projects/constants";

interface StatsField {
  attribute: String;
  value: String | number;
  vsForecast: String;
  green: boolean;
  isSelected: boolean;
  forecastDown: boolean;
}

interface HeaderField {
  title: String;
  data: String;
  up?: boolean;
  suppress_render?: boolean;
}

export const PORTFOLIO_HEADER_FRAGMENT = gql`
  fragment PortfolioHeader on Portfolio {
    id
    name
    slug
    status
    buildStatus
    systemSize
    systemSizeAc
    systemSizeEss
    projectCount
    averageOfftakerQuality
    remainingPpaTerm
    headerEnabled
    operatingStats {
      startDate
      actualExpenses
      actualProduction
      actualRevenue
      forecastExpenses
      forecastProduction
      forecastRevenue
    }
    org {
      name
      slug
    }
  }
`;

interface PortfolioHeaderProps {
  portfolio: PortfolioHeaderFragment;
  showAdminLink: boolean;
  showUsersGroups: boolean;
  dealAdmin: boolean;
}

export const PortfolioHeader = ({
  portfolio,
  showAdminLink,
  showUsersGroups,
  dealAdmin,
}: PortfolioHeaderProps) => {
  const showExtendedSummary = portfolio.status === WorkflowStatus.Acquired;
  const noStats = portfolio.headerEnabled
    ? portfolio.projectCount !== null ||
      portfolio.operatingStats ||
      portfolio.systemSize ||
      portfolio.averageOfftakerQuality
    : false;
  const [collapsedHeader, setCollapsedHeader] = useState(!noStats);

  const showAdminDropdown = showAdminLink || showUsersGroups || dealAdmin;
  const data = portfolio.operatingStats && [
    {
      actualProduction: portfolio.operatingStats.actualProduction,
      actualRevenue: portfolio.operatingStats.actualRevenue,
      actualExpenses: portfolio.operatingStats.actualExpenses,
      forecastProduction: portfolio.operatingStats.forecastProduction,
      forecastRevenue: portfolio.operatingStats.forecastRevenue,
      forecastExpenses: portfolio.operatingStats.forecastExpenses,
    },
  ];
  const recalculatedStatsData = calculateSideData(data, true);
  const readableStatsDate =
    portfolio.operatingStats?.startDate &&
    moment(portfolio.operatingStats.startDate).format("Y");

  const renderStatsFields = (data: StatsField, i: number) => (
    <div className="w-25" key={i}>
      <div className="title">
        {data.attribute} {readableStatsDate || "-"}
      </div>
      {readableStatsDate ? (
        <div className="d-flex justify-content-start flex-wrap">
          <h3 className="mb-0 mr-1">{data.value}</h3>
          <div className="mt-1 mb-3 d-flex flex-column">
            <div
              className={classNames(
                {
                  "text-success": data.green,
                  "text-danger": !data.green,
                },
                "d-flex",
                "align-items-center",
                "forecast-data"
              )}
            >
              <SortIcon
                theme={data.green ? "success" : "danger"}
                dir={data.forecastDown ? "S" : "N"}
              />
              {data.vsForecast}
            </div>
            <div className="ml-1 mt-n1 forecast-title">vs Forecast</div>
          </div>
        </div>
      ) : (
        "-"
      )}
    </div>
  );

  const renderHeaderField = (fieldContent: HeaderField, i: number) =>
    !fieldContent.suppress_render && (
      <div className={showExtendedSummary ? "w-25" : ""} key={i}>
        <div className="title">{fieldContent.title}</div>
        <h3 className={fieldContent.up ? "text-uppercase" : ""}>
          {fieldContent.data || "-"}
        </h3>
      </div>
    );

  const getPortfolioHeaders = (
    portfolio: PortfolioHeaderFragment
  ): Array<HeaderField> => {
    // Segue requested custom data displayed on the deal room. Eventually we should move this
    // backend in case other orgs want similar customization. Until then we will have logic here:
    const displayStatus = getWorkflowStatusDisplay(portfolio.org.slug);
    if (portfolio.org.slug === "segue-infra") {
      return [
        {
          title: "Developer",
          data: portfolio.org.name ?? "",
        },
        {
          title: "Project Count",
          data: `${portfolio.projectCount}`,
        },
        {
          title: "MW ac PV",
          data: formatSize(portfolio.systemSizeAc, null, "MW", true),
        },
        {
          title: "MW ESS",
          data: formatSize(portfolio.systemSizeEss, null, "MW", true),
        },
      ];
    }
    if (portfolio.status === WorkflowStatus.Market) {
      return [
        {
          title: "Owner",
          data: portfolio.org.name ?? "",
        },
        {
          title: "Workflow Status",
          data: portfolio.status ? displayStatus[portfolio.status] : "",
        },
        {
          title: "Build Status",
          data: portfolio.buildStatus ?? "",
        },
        {
          title: "Project Count",
          data: `${portfolio.projectCount}`,
        },
        {
          title: "Total Size",
          data: formatSize(portfolio.systemSize),
        },
      ];
    }
    return [
      {
        title: "Status",
        up: true,
        data: portfolio.status ? displayStatus[portfolio.status] : "",
        suppress_render: showExtendedSummary,
      },
      {
        title: "System Size",
        data: formatSize(portfolio.systemSize),
      },
      {
        title: "Projects",
        data: `${portfolio.projectCount}`,
      },
      {
        title: "Remaining PPA term",
        data: portfolio.remainingPpaTerm
          ? `${formattedNumber(portfolio.remainingPpaTerm, 1)} years`
          : "-",
      },
      {
        title: "Status",
        data: portfolio.buildStatus ?? "",
        up: true,
        suppress_render: !showExtendedSummary,
      },
    ];
  };
  const headerData: Array<HeaderField> = getPortfolioHeaders(portfolio);
  const updateManager = useUpdatePortfolio(portfolio.org.slug, portfolio);
  const dialogs = (
    <>
      <UpdatePortfolioDialog {...updateManager} />
    </>
  );
  return (
    <div
      className={classNames("box--black", {
        collapsed: collapsedHeader,
      })}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="flex-grow-1">
          <h1 className="spe-name">{portfolio.name}</h1>
        </div>
        <div className="mr-2">
          {noStats && (
            <button
              type="button"
              className="btn btn-link btn-collapse"
              onClick={() => setCollapsedHeader(!collapsedHeader)}
            >
              {collapsedHeader ? "Expand" : "Collapse"}
            </button>
          )}
        </div>
        <div>
          {showAdminDropdown && (
            <AdminMenu
              dealAdmin={dealAdmin}
              showAdminLink={showAdminLink}
              showUsersGroups={showUsersGroups}
              portfolio={portfolio}
              open={updateManager.open}
            />
          )}
        </div>
      </div>
      <div className="header-row d-flex justify-content-xl-between text-white flex-wrap flex-column flex-sm-row">
        {headerData.map((field, i) => renderHeaderField(field, i))}
        {showExtendedSummary &&
          recalculatedStatsData?.map((data, i) => renderStatsFields(data, i))}
      </div>
      {dialogs}
    </div>
  );
};
