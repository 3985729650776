import gql from "graphql-tag";
import { compact } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";

import { PermissionType } from "~/api/types.generated";
import { Documents } from "~/components/Documents/Documents";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { SubjectPermission } from "~/permissions/useViewerPermissions";

import { REPORTING_BANNER_FRAGMENTS, ReportingBanner } from "./ReportingBanner";
import { ReportingDetailFragment } from "./ReportingDetail.generated";

export const REPORTING_DETAIL_FRAGMENT = gql`
  fragment ReportingDetail on Query {
    reportingHoldingCompanies(orgSlug: $orgSlug) {
      id
      name
      slug
      reportsTree {
        id
      }
      operatingStats {
        ...ReportingBannerOperatingStats
      }
      projectsStats {
        ...ReportingBannerProjectsStats
      }
    }
  }

  ${REPORTING_BANNER_FRAGMENTS}
`;

export interface ReportingDetailProps {
  data: ReportingDetailFragment | null | undefined;
  holdingCompanyPermissions: SubjectPermission;
  areLabelsEnabled: boolean;
}

export const ReportingDetail = ({
  data,
  holdingCompanyPermissions,
  areLabelsEnabled,
}: ReportingDetailProps) => {
  const { slug } = useParams<{ slug: string }>();
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const holdco = compact(data?.reportingHoldingCompanies).find(
    (hc) => hc.slug === slug
  );
  const permissions = holdingCompanyPermissions[holdco?.id ?? ""] ?? [];
  const isUsersAdmin = permissions.includes(PermissionType.UsersAdmin);
  return (
    <>
      <ReportingBanner
        name={holdco?.name}
        operatingStats={holdco?.operatingStats}
        projectsStats={holdco?.projectsStats}
        showAdmin={false}
      />
      {data ? (
        holdco?.reportsTree && (
          <Documents
            treeId={holdco.reportsTree.id}
            isDocumentsAdmin={permissions.includes(
              PermissionType.DocumentsAdmin
            )}
            areLabelsEnabled={areLabelsEnabled}
            canViewDocumentsHistory={permissions.includes(
              PermissionType.ReportsHistoryView
            )}
            basePath={`/${orgSlug}/reporting/${holdco.slug}/reports`}
            isUsersAdmin={isUsersAdmin}
          />
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <LoadingSpinner big />
        </div>
      )}
    </>
  );
};
