// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { OrgGroupFragment } from './NewUserDialog.generated';
import { UserInviteFragment } from './UsersTable.generated';
import { gql } from '@apollo/client';
import { OrgGroupFragmentDoc } from './NewUserDialog.generated';
import { UserInviteFragmentDoc } from './UsersTable.generated';
import * as Apollo from '@apollo/client';
export type OrgDataQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
}>;


export type OrgDataQuery = (
  { readonly __typename: 'Query' }
  & { readonly orgUserGroups?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'UserGroup' }
    & OrgGroupFragment
  )>> }
);

export type InvitationCountsQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
}>;


export type InvitationCountsQuery = (
  { readonly __typename: 'Query' }
  & { readonly invitationsData?: Types.Maybe<(
    { readonly __typename: 'InvitationsData' }
    & Pick<Types.InvitationsData, 'accepted' | 'waiting'>
  )> }
);

export type InvitationsQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  ordering?: Types.Maybe<Types.Scalars['String']>;
  search?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  accepted?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type InvitationsQuery = (
  { readonly __typename: 'Query' }
  & { readonly invitationPages: (
    { readonly __typename: 'PaginatedOrgInvitation' }
    & Pick<Types.PaginatedOrgInvitation, 'count'>
    & { readonly invitations?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'OrgInvitation' }
      & UserInviteFragment
    )>> }
  ) }
);


export const OrgDataDocument = gql`
    query OrgData($orgSlug: String!) {
  orgUserGroups(orgSlug: $orgSlug) {
    ...OrgGroup
  }
}
    ${OrgGroupFragmentDoc}`;

/**
 * __useOrgDataQuery__
 *
 * To run a query within a React component, call `useOrgDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgDataQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useOrgDataQuery(baseOptions: Apollo.QueryHookOptions<OrgDataQuery, OrgDataQueryVariables>) {
        return Apollo.useQuery<OrgDataQuery, OrgDataQueryVariables>(OrgDataDocument, baseOptions);
      }
export function useOrgDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgDataQuery, OrgDataQueryVariables>) {
          return Apollo.useLazyQuery<OrgDataQuery, OrgDataQueryVariables>(OrgDataDocument, baseOptions);
        }
export type OrgDataQueryHookResult = ReturnType<typeof useOrgDataQuery>;
export type OrgDataLazyQueryHookResult = ReturnType<typeof useOrgDataLazyQuery>;
export type OrgDataQueryResult = Apollo.QueryResult<OrgDataQuery, OrgDataQueryVariables>;
export const InvitationCountsDocument = gql`
    query InvitationCounts($orgSlug: String!) {
  invitationsData(orgSlug: $orgSlug) {
    accepted
    waiting
  }
}
    `;

/**
 * __useInvitationCountsQuery__
 *
 * To run a query within a React component, call `useInvitationCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationCountsQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useInvitationCountsQuery(baseOptions: Apollo.QueryHookOptions<InvitationCountsQuery, InvitationCountsQueryVariables>) {
        return Apollo.useQuery<InvitationCountsQuery, InvitationCountsQueryVariables>(InvitationCountsDocument, baseOptions);
      }
export function useInvitationCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationCountsQuery, InvitationCountsQueryVariables>) {
          return Apollo.useLazyQuery<InvitationCountsQuery, InvitationCountsQueryVariables>(InvitationCountsDocument, baseOptions);
        }
export type InvitationCountsQueryHookResult = ReturnType<typeof useInvitationCountsQuery>;
export type InvitationCountsLazyQueryHookResult = ReturnType<typeof useInvitationCountsLazyQuery>;
export type InvitationCountsQueryResult = Apollo.QueryResult<InvitationCountsQuery, InvitationCountsQueryVariables>;
export const InvitationsDocument = gql`
    query Invitations($orgSlug: String!, $ordering: String, $search: String, $offset: Int, $limit: Int, $accepted: Boolean) {
  invitationPages(
    orgSlug: $orgSlug
    ordering: $ordering
    search: $search
    limit: $limit
    offset: $offset
    accepted: $accepted
  ) {
    count
    invitations {
      ...UserInvite
    }
  }
}
    ${UserInviteFragmentDoc}`;

/**
 * __useInvitationsQuery__
 *
 * To run a query within a React component, call `useInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationsQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      ordering: // value for 'ordering'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      accepted: // value for 'accepted'
 *   },
 * });
 */
export function useInvitationsQuery(baseOptions: Apollo.QueryHookOptions<InvitationsQuery, InvitationsQueryVariables>) {
        return Apollo.useQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, baseOptions);
      }
export function useInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationsQuery, InvitationsQueryVariables>) {
          return Apollo.useLazyQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, baseOptions);
        }
export type InvitationsQueryHookResult = ReturnType<typeof useInvitationsQuery>;
export type InvitationsLazyQueryHookResult = ReturnType<typeof useInvitationsLazyQuery>;
export type InvitationsQueryResult = Apollo.QueryResult<InvitationsQuery, InvitationsQueryVariables>;