import { gql } from "@apollo/client";
import LoadingSpinner from "components/generic/LoadingSpinner";
import SearchBar from "components/SearchBar";
import React, { useContext, useEffect, useState } from "react";
import { gettext } from "utils/text";

import { FundEntityType } from "~/api/types.generated";
import { Pagination } from "~/components/common/pagination";
import OrderingContext, {
  useOrderingContext,
} from "~/components/UserGroupMembers/context/OrderingContext";
import {
  UserGroupMembersPageQuery,
  useUserGroupMembersPageLazyQuery,
} from "~/components/UserGroupMembers/UserGroupMembersPage.generated";
import UserGroupMembersTable, {
  USER_GROUP_MEMBER_FRAGMENT,
} from "~/components/UserGroupMembers/UserGroupMembersTable";
import { useUserGroupMembersSearch } from "~/components/UserGroupMembers/useUserGroupMembersSearch";

export const USER_GROUP_MEMBERS_PAGE_QUERY = gql`
  query UserGroupMembersPage(
    $fundEntityId: ID!
    $fundEntityType: FundEntityType!
    $ordering: String
    $search: String
    $offset: Int
    $limit: Int
  ) {
    userGroupMembers(
      fundEntityId: $fundEntityId
      fundEntityType: $fundEntityType
      ordering: $ordering
      search: $search
      limit: $limit
      offset: $offset
    ) {
      count
      userGroupMemberships {
        ...UserGroupMember
      }
    }
  }
  ${USER_GROUP_MEMBER_FRAGMENT}
`;

export interface DealTeamPageProps {
  fundEntityId?: string | null;
  fundEntityType: FundEntityType;
  isSuperUser: boolean;
}

export const UserGroupMembersPage = ({
  fundEntityId,
  fundEntityType,
  isSuperUser,
}: DealTeamPageProps) => {
  const ordering = useOrderingContext();
  return (
    <OrderingContext.Provider value={ordering}>
      <RenderUserGroupMembersPage
        fundEntityId={fundEntityId}
        fundEntityType={fundEntityType}
        isSuperUser={isSuperUser}
      />
    </OrderingContext.Provider>
  );
};

export const RenderUserGroupMembersPage = ({
  fundEntityId,
  fundEntityType,
  isSuperUser,
}: DealTeamPageProps) => {
  const search = useUserGroupMembersSearch();
  const { ordering, serializeOrdering } = useContext(OrderingContext);
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState(50);
  const [membersData, setMembersData] =
    useState<UserGroupMembersPageQuery | null>(null);
  const [getMembers, { loading }] = useUserGroupMembersPageLazyQuery({
    onCompleted: setMembersData,
  });
  const members = membersData?.userGroupMembers?.userGroupMemberships ?? [];
  useEffect(() => {
    if (fundEntityId) {
      getMembers({
        variables: {
          fundEntityId: fundEntityId,
          fundEntityType: fundEntityType,
          ordering: serializeOrdering(ordering),
          search: search.debouncedSearchTerm ? search.debouncedSearchTerm : "",
          limit: pageSize,
          offset: (page - 1) * pageSize,
        },
      });
    }
  }, [
    fundEntityId,
    fundEntityType,
    getMembers,
    search.debouncedSearchTerm,
    ordering,
    serializeOrdering,
    page,
    pageSize,
  ]);

  return (
    <div>
      <h2 className="mb-3">{gettext("Users & Groups")}</h2>

      <div className="row mb-3">
        <div className="col-md-6">
          <SearchBar
            keyword={search.searchTerm}
            onChange={(e) => search.setSearchTerm(e.target.value)}
            renderInputGroupPrepend={() => (
              <button type="button" className="btn">
                {search.searchPending ? (
                  <LoadingSpinner />
                ) : (
                  <i className="icon icon--search-dark" />
                )}
              </button>
            )}
            clearable
            onClear={() => search.clearSearch()}
          />
        </div>

        <div className="col d-flex justify-content-end">
          {isSuperUser && (
            <>
              <a
                href="/tagauks/accounts/user/"
                className="btn btn-outline-primary ml-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Manage users
              </a>
              <a
                href="/tagauks/accounts/usergroup/"
                className="btn btn-outline-primary ml-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Manage user groups
              </a>
            </>
          )}
        </div>
      </div>

      <UserGroupMembersTable members={members} isLoading={loading} />
      <Pagination
        page={page}
        pageSize={pageSize}
        count={membersData?.userGroupMembers?.count ?? 0}
        setPage={(page) => setPage(page)}
      />
    </div>
  );
};
