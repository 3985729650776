import React from "react";

import { useEventTracking } from "~/event-tracking/EventTrackingProvider";

export const RestrictedNavBar = () => {
  const track = useEventTracking("Nav", {
    logo: "Clicked Logo",
  });
  // Determine which navbar tabs the user should see
  const env = DJ_CONST.ENVIRONMENT_TAG || "live";
  const userName = DJ_CONST.user.full_name;

  return (
    <header className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <a className="navbar-brand" href="/" onClick={track.logo}>
          Dock Platform
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#headerNavigation"
          aria-controls="headerNavigation"
          aria-expanded="false"
        >
          <i className="icon icon--nav"></i>
        </button>

        <div className="collapse navbar-collapse" id="headerNavigation">
          {env !== "live" && (
            <div className="nav-env-label">{env.toUpperCase()}</div>
          )}
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <div className="dropdown">
                <button
                  className="nav-link nav-link-user"
                  id="dropdownUserMenu"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="nav-user clearfix">
                    <span className="nav-username">{userName}</span>
                    <span
                      className="nav-avatar"
                      style={{
                        backgroundImage: `url('${DJ_CONST.user.avatar}')`,
                      }}
                    />
                  </span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownUserMenu"
                >
                  <a className="dropdown-item icon icon--logout" href="/logout">
                    Log out
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};
