// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { ProfileFormFragment } from './ProfileForm.generated';
import { ProfileAvatarFragment } from './ProfileAvatar.generated';
import { gql } from '@apollo/client';
import { ProfileFormFragmentDoc } from './ProfileForm.generated';
import { ProfileAvatarFragmentDoc } from './ProfileAvatar.generated';
import * as Apollo from '@apollo/client';
export type ProfilePageQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ProfilePageQuery = (
  { readonly __typename: 'Query' }
  & { readonly user?: Types.Maybe<(
    { readonly __typename: 'User' }
    & ProfileFormFragment
    & ProfileAvatarFragment
  )> }
);

export type MyProfilePageViewerFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id'>
);

export const MyProfilePageViewerFragmentDoc = gql`
    fragment MyProfilePageViewer on User {
  id
}
    `;
export const ProfilePageDocument = gql`
    query ProfilePage($id: ID!) {
  user(id: $id) {
    ...ProfileForm
    ...ProfileAvatar
  }
}
    ${ProfileFormFragmentDoc}
${ProfileAvatarFragmentDoc}`;

/**
 * __useProfilePageQuery__
 *
 * To run a query within a React component, call `useProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfilePageQuery(baseOptions: Apollo.QueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
        return Apollo.useQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, baseOptions);
      }
export function useProfilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
          return Apollo.useLazyQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, baseOptions);
        }
export type ProfilePageQueryHookResult = ReturnType<typeof useProfilePageQuery>;
export type ProfilePageLazyQueryHookResult = ReturnType<typeof useProfilePageLazyQuery>;
export type ProfilePageQueryResult = Apollo.QueryResult<ProfilePageQuery, ProfilePageQueryVariables>;