import { gql } from "@apollo/client";
import classnames from "classnames";
import Avatar from "components/generic/Avatar";
import moment from "moment";
import React, { useContext } from "react";
import { gettext } from "utils/text";

import { Field } from "~/components/UserGroupMembers/context/OrderingContext";
import OrderingContext from "~/components/UserGroupMembers/context/OrderingContext";
import {
  UserGroupMemberFragment,
  User_InfoFragment,
} from "~/components/UserGroupMembers/UserGroupMembersTable.generated";
import { Direction, Ordering, createOrdering, reverse } from "~/utils/ordering";

const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm:ss";

export const COMPANY_FRAGMENT = gql`
  fragment Company_Info on Company {
    id
    name
  }
`;

export const USER_FRAGMENT = gql`
  fragment User_Info on User {
    id
    avatarUrl
    fullName
    firstName
    lastName
    company {
      ...Company_Info
    }
    notificationsEnabled(
      fundEntityId: $fundEntityId
      fundEntityType: $fundEntityType
    )
    lastLogin
  }
  ${COMPANY_FRAGMENT}
`;

export const USER_GROUP_FRAGMENT = gql`
  fragment UserGroup_Info on UserGroup {
    id
    name
    role {
      name
    }
  }
`;

export const USER_GROUP_MEMBER_FRAGMENT = gql`
  fragment UserGroupMember on UserGroupMembership {
    id
    user {
      ...User_Info
    }
    userGroup {
      ...UserGroup_Info
    }
  }
  ${USER_FRAGMENT}
  ${USER_GROUP_FRAGMENT}
`;

export interface HeaderCellProps {
  name: Field;
  ordering: Ordering<Field> | null;
  toggleSorting: (name: Field) => void;
  children?: any;
  style?: React.CSSProperties;
  arrowLeft?: boolean;
}

export const HeaderCell = ({
  name,
  ordering,
  toggleSorting,
  children,
  style = { textAlign: "left" },
  arrowLeft = false,
}: HeaderCellProps) => (
  <th scope="col" style={style}>
    <button
      className={classnames(
        "btn",
        ordering && ordering.field && "btn-link",
        ordering && ordering.field && "btn-link-hovered"
      )}
      style={{ padding: 0, textAlign: style.textAlign }}
      type="button"
      onClick={() => toggleSorting(name)}
    >
      {!arrowLeft ? children : null}
      {ordering && ordering.field && (
        <i
          className={classnames(
            "icon",
            "icon--sort-up",
            ordering.field.includes(name) && "active",
            ordering.field.includes(name) &&
              ordering.direction === Direction.ASC &&
              "asc",
            ordering.field.includes(name) &&
              ordering.direction === Direction.DESC &&
              "desc"
          )}
        />
      )}
      {arrowLeft ? children : null}
    </button>
  </th>
);

const formatLastLogin = (user: User_InfoFragment) =>
  user.lastLogin && moment(user.lastLogin).format(DATE_TIME_FORMAT);

export interface UserGroupMembersTableProps {
  members: readonly UserGroupMemberFragment[];
  isLoading: boolean;
}

const UserGroupMembersTable = ({
  members,
  isLoading,
}: UserGroupMembersTableProps) => {
  const { ordering, setOrdering } = useContext(OrderingContext);
  const toggleSorting = (field: Field) => {
    if (ordering.field === field) {
      setOrdering(reverse(ordering));
    } else {
      setOrdering(createOrdering(field));
    }
  };

  return (
    <table className="table table-hover mb-3">
      <thead>
        <tr>
          <HeaderCell
            name={Field.USER_NAME}
            ordering={ordering}
            toggleSorting={toggleSorting}
          >
            {gettext("User")}
          </HeaderCell>
          <HeaderCell
            name={Field.COMPANY_NAME}
            ordering={ordering}
            toggleSorting={toggleSorting}
          >
            {gettext("Company")}
          </HeaderCell>
          <HeaderCell
            name={Field.USER_GROUP_NAME}
            ordering={ordering}
            toggleSorting={toggleSorting}
          >
            {gettext("User Group")}
          </HeaderCell>
          <HeaderCell
            name={Field.ROLE_NAME}
            ordering={ordering}
            toggleSorting={toggleSorting}
          >
            {gettext("Role")}
          </HeaderCell>
          <HeaderCell
            name={Field.NOTIFICATIONS_ENABLED}
            ordering={ordering}
            toggleSorting={toggleSorting}
          >
            {gettext("Notifications")}
          </HeaderCell>
          <HeaderCell
            name={Field.LAST_LOGIN}
            ordering={ordering}
            toggleSorting={toggleSorting}
          >
            {gettext("Last Login")}
          </HeaderCell>
        </tr>
      </thead>
      <tbody>
        {!members.length && !isLoading && (
          <tr>
            <td colSpan={3}>
              <div className="d-flex justify-content-center py-2">
                <b>{gettext("No team members found.")}</b>
              </div>
            </td>
          </tr>
        )}

        {members.map((member) => (
          <tr key={member.id}>
            <td>
              {member.user && (
                <>
                  <Avatar
                    image={member.user?.avatarUrl}
                    className="mr-2 my-neg-2"
                  />
                  <span>
                    {member.user?.firstName} <b>{member.user?.lastName}</b>
                  </span>
                </>
              )}
            </td>
            <td>
              {member.user?.company ? <>{member.user.company.name}</> : "-"}
            </td>
            <td>{member.userGroup && <>{member.userGroup?.name}</>}</td>
            <td>
              {member.userGroup?.role ? <>{member.userGroup.role.name}</> : ""}
            </td>
            <td>
              {member.user?.notificationsEnabled
                ? gettext("Yes")
                : gettext("No")}
            </td>
            <td>{member.user ? formatLastLogin(member.user) : ""}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UserGroupMembersTable;
