// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { UserGroupMemberFragment } from './UserGroupMembersTable.generated';
import { gql } from '@apollo/client';
import { UserGroupMemberFragmentDoc } from './UserGroupMembersTable.generated';
import * as Apollo from '@apollo/client';
export type UserGroupMembersPageQueryVariables = Types.Exact<{
  fundEntityId: Types.Scalars['ID'];
  fundEntityType: Types.FundEntityType;
  ordering?: Types.Maybe<Types.Scalars['String']>;
  search?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type UserGroupMembersPageQuery = (
  { readonly __typename: 'Query' }
  & { readonly userGroupMembers: (
    { readonly __typename: 'PaginatedUserGroupMemberships' }
    & Pick<Types.PaginatedUserGroupMemberships, 'count'>
    & { readonly userGroupMemberships?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'UserGroupMembership' }
      & UserGroupMemberFragment
    )>> }
  ) }
);


export const UserGroupMembersPageDocument = gql`
    query UserGroupMembersPage($fundEntityId: ID!, $fundEntityType: FundEntityType!, $ordering: String, $search: String, $offset: Int, $limit: Int) {
  userGroupMembers(
    fundEntityId: $fundEntityId
    fundEntityType: $fundEntityType
    ordering: $ordering
    search: $search
    limit: $limit
    offset: $offset
  ) {
    count
    userGroupMemberships {
      ...UserGroupMember
    }
  }
}
    ${UserGroupMemberFragmentDoc}`;

/**
 * __useUserGroupMembersPageQuery__
 *
 * To run a query within a React component, call `useUserGroupMembersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupMembersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupMembersPageQuery({
 *   variables: {
 *      fundEntityId: // value for 'fundEntityId'
 *      fundEntityType: // value for 'fundEntityType'
 *      ordering: // value for 'ordering'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUserGroupMembersPageQuery(baseOptions: Apollo.QueryHookOptions<UserGroupMembersPageQuery, UserGroupMembersPageQueryVariables>) {
        return Apollo.useQuery<UserGroupMembersPageQuery, UserGroupMembersPageQueryVariables>(UserGroupMembersPageDocument, baseOptions);
      }
export function useUserGroupMembersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupMembersPageQuery, UserGroupMembersPageQueryVariables>) {
          return Apollo.useLazyQuery<UserGroupMembersPageQuery, UserGroupMembersPageQueryVariables>(UserGroupMembersPageDocument, baseOptions);
        }
export type UserGroupMembersPageQueryHookResult = ReturnType<typeof useUserGroupMembersPageQuery>;
export type UserGroupMembersPageLazyQueryHookResult = ReturnType<typeof useUserGroupMembersPageLazyQuery>;
export type UserGroupMembersPageQueryResult = Apollo.QueryResult<UserGroupMembersPageQuery, UserGroupMembersPageQueryVariables>;