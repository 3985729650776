import gql from "graphql-tag";
import React from "react";

import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { ProfileAvatarFragment } from "~/components/Settings/ProfileAvatar.generated";
import {
  useDeleteAvatarMutation,
  useUploadAvatarMutation,
} from "~/components/Settings/ProfileAvatar.generated";

export const PROFILE_AVATAR_FRAGMENT = gql`
  fragment ProfileAvatar on User {
    id
    avatarUrl
  }
`;

export const PROFILE_AVATAR_MUTATION_FRAGMENT = gql`
  mutation UploadAvatar($userId: ID!, $file: Upload!) {
    uploadAvatar(userId: $userId, file: $file) {
      ...ProfileAvatar
    }
  }

  mutation DeleteAvatar($userId: ID!) {
    deleteAvatar(userId: $userId) {
      ...ProfileAvatar
    }
  }

  ${PROFILE_AVATAR_FRAGMENT}
`;

export interface ProfileAvatarProps {
  data?: ProfileAvatarFragment | null;
}

export const ProfileAvatar = ({ data }: ProfileAvatarProps) => {
  const displayUrl = data?.avatarUrl
    ? data.avatarUrl
    : DJ_CONST.user.default_avatar_url;
  const [uploadAvatar, { loading: loadingUploadAvatar }] =
    useUploadAvatarMutation();
  const [deleteAvatar] = useDeleteAvatarMutation();
  // if we don't have the avatarUrl data, nothing to render
  if (!data) return <></>;
  return (
    <>
      <div
        className="preview"
        style={{ backgroundImage: `url("${displayUrl}")` }}
      >
        {loadingUploadAvatar && <LoadingSpinner />}
        {!loadingUploadAvatar &&
          displayUrl !== DJ_CONST.user.default_avatar_url && (
            <button
              className="btn btn-danger"
              onClick={() => deleteAvatar({ variables: { userId: data.id } })}
              type="button"
            >
              Delete Photo
            </button>
          )}
      </div>
      <label
        htmlFor="user-picture-input"
        className="btn btn-outline-primary change-button"
      >
        Upload new picture
      </label>
      <input
        id="user-picture-input"
        className="hidden-input"
        name="picture"
        type="file"
        value="" // we don't need to save the file to the field
        onChange={(e) => {
          e.preventDefault();
          if (!e.target.files) return;
          const file = e.target.files[0];
          uploadAvatar({
            variables: { userId: data.id, file: file },
          });
        }}
      />
    </>
  );
};
