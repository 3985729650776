import gql from "graphql-tag";
import { compact } from "lodash";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { PermissionType } from "~/api/types.generated";
import { PageContainer } from "~/components/common/layout";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { HomePageViewerFragment } from "~/components/HomePage/HomePage.generated";
import NavTab from "~/components/navigation/NavTab";
import NavTabs from "~/components/navigation/NavTabs";
import { getWorkflowStatusDisplay } from "~/components/Projects/constants";
import EmptyState from "~/components/QAndA/components/EmptyState";

import { useDealRoomQuery } from "./DealRoom.generated";
import { DEAL_ROOM_BANNER_FRAGMENT, DealRoomBanner } from "./DealRoomBanner";
import { DEAL_ROOM_GRID_FRAGMENT, DealRoomGrid } from "./DealRoomGrid";

export const DEAL_ROOM_QUERY = gql`
  query DealRoom {
    ...DealRoomBanner

    diligence: fundEntities(category: DILIGENCE) {
      ...DealRoomGrid
    }

    acquired: fundEntities(category: ACQUIRED) {
      ...DealRoomGrid
    }

    market: fundEntities(category: MARKET) {
      ...DealRoomGrid
    }
  }

  ${DEAL_ROOM_BANNER_FRAGMENT}
  ${DEAL_ROOM_GRID_FRAGMENT}
`;

interface DealRoomProps {
  isAdmin: boolean;
  viewer?: HomePageViewerFragment;
}

export const DealRoom = ({ isAdmin, viewer }: DealRoomProps) => {
  const { data, loading } = useDealRoomQuery({
    returnPartialData: true,
    pollInterval: 60000,
  });
  const orgAdminPages = viewer?.organizations?.filter((org) =>
    org.permissions.includes(PermissionType.OrgAdmin)
  );
  const adminOrg = (orgAdminPages ?? [null])[0];
  const statusDisplay = getWorkflowStatusDisplay(adminOrg?.slug || "");
  const navTabs = (
    <NavTabs>
      <NavTab to="/dealroom/market" activeClassName="active">
        {statusDisplay.MARKET}
      </NavTab>
      <NavTab to="/dealroom/diligence" activeClassName="active">
        {statusDisplay.DILIGENCE}
      </NavTab>
      <NavTab to="/dealroom/acquired" activeClassName="active">
        {statusDisplay.ACQUIRED}
      </NavTab>
    </NavTabs>
  );
  const marketCount = data?.market?.count ?? 0;
  const defaultPath =
    marketCount > 0 ? "/dealroom/market" : "/dealroom/diligence";
  const allEntities = compact([
    ...(data?.acquired?.entities ?? []),
    ...(data?.diligence?.entities ?? []),
    ...(data?.market?.entities ?? []),
  ]);

  // If the user only has access to a single portfolio/holdco, redirect them to that
  const singleEntity = allEntities.length === 1 ? allEntities[0] : null;
  if (singleEntity) {
    if (singleEntity.__typename === "Portfolio") {
      return <Redirect to={`/portfolios/${singleEntity.slug}`} />;
    } else {
      return <Redirect to={`/holding-company/${singleEntity.slug}`} />;
    }
  }

  return (
    <PageContainer>
      <DealRoomBanner
        showAdmin={isAdmin}
        data={data}
        orgSlug={adminOrg?.slug || ""}
      />

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <LoadingSpinner big />
        </div>
      ) : (
        <>
          {navTabs}

          <Switch>
            <Route path="/dealroom/diligence">
              <DealRoomGrid
                entities={data?.diligence?.entities}
                emptyState={
                  <EmptyState
                    text={`No portfolios in ${statusDisplay.DILIGENCE}.`}
                  />
                }
              />
            </Route>
            <Route path="/dealroom/acquired">
              <DealRoomGrid
                entities={data?.acquired?.entities}
                emptyState={
                  <EmptyState
                    text={`No ${statusDisplay.ACQUIRED} portfolios.`}
                  />
                }
              />
            </Route>
            <Route path="/dealroom/market">
              <DealRoomGrid
                entities={data?.market?.entities}
                emptyState={
                  <EmptyState text={`No ${statusDisplay.MARKET} deals.`} />
                }
              />
            </Route>
            <Redirect to={defaultPath} />
          </Switch>
        </>
      )}
    </PageContainer>
  );
};
