import React, { useContext } from "react";
import styled from "styled-components";

import Dropdown, { DropdownProps } from "~/components/menus/Dropdown";
import DropdownHeader from "~/components/menus/DropdownHeader";
import DropdownMultiselectButtons from "~/components/menus/DropdownMultiselectButtons";
import DropdownTriggerContext from "~/components/menus/DropdownTriggerContext";
import SelectMenu from "~/components/menus/SelectMenu";
import SelectMenuOption from "~/components/menus/SelectMenuOption";

export interface MenuItem {
  readonly id: string;
  readonly name: string;
}

export interface ItemMenuProps<T extends MenuItem> extends DropdownProps {
  title?: string;
  currentItems: readonly T[];
  availableItems: readonly T[] | undefined;
  onSelectItem: (item: T) => unknown;
  onSelectAll: () => unknown;
  onDeselectItem: (item: T) => unknown;
  onDeselectAll: () => unknown;
}

const ItemMenu = <T extends MenuItem>({
  title = "Item",
  currentItems,
  availableItems,
  onSelectItem,
  onSelectAll,
  onDeselectItem,
  onDeselectAll,
  ...rest
}: ItemMenuProps<T>) => {
  const dropdownTrigger = useContext(DropdownTriggerContext);

  const onSelect = (item: T) => {
    onSelectItem(item);
    dropdownTrigger?.measure();
  };

  const onDeselect = (item: T) => {
    onDeselectItem(item);
    dropdownTrigger?.measure();
  };

  const isSelected = (item: T) => currentItems.some((i) => i.id === item.id);

  const sortedItems = [...(availableItems ?? [])].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );

  return (
    <Dropdown {...rest}>
      <DropdownHeader title={`${title}`} variant="large">
        <DropdownMultiselectButtons
          onSelectAll={onSelectAll}
          onDeselectAll={onDeselectAll}
        />
      </DropdownHeader>
      <MenuScroller>
        <SelectMenu
          isSelected={isSelected}
          onSelect={onSelect}
          onDeselect={onDeselect}
          aria-multiselectable="true"
        >
          {sortedItems.map((item) => (
            <SelectMenuOption key={item.id} value={item} label={item.name} />
          ))}
        </SelectMenu>
      </MenuScroller>
    </Dropdown>
  );
};

const MenuScroller = styled.div`
  min-width: 20rem;
  max-height: 20rem;
  overflow-y: scroll;
`;

export default ItemMenu;
