import React, {
  KeyboardEvent,
  MouseEvent,
  useContext,
  useEffect,
  useRef,
} from "react";
import styled from "styled-components";

import { bgColor, borderBottom, centerContent, fgColor } from "~/styles/mixins";
import { GetPropsWithoutRef } from "~/utils/types";

import DropdownContext from "./DropdownContext";
import SelectMenuContext from "./SelectMenuContext";

export interface SelectMenuOptionProps<T>
  extends Omit<GetPropsWithoutRef<"li">, "onSelect" | "value"> {
  index?: number;
  onSelect?: (event: MouseEvent | KeyboardEvent, value: T) => unknown;
  value: T;
  label: string;
}

const SelectMenuOption = <T extends any>({
  index = -1,
  onSelect,
  value,
  label,
  children = label,
  ...rest
}: SelectMenuOptionProps<T>) => {
  const selectMenu = useContext(SelectMenuContext);
  const dropdown = useContext(DropdownContext);
  const ref = useRef<HTMLLIElement>(null);

  // Synchronize the menu focus state to the browser focus state
  useEffect(() => {
    if (
      ref.current &&
      dropdown?.isOpen &&
      dropdown?.transitionState?.phase !== "leave" &&
      selectMenu.focusIndex === index
    ) {
      ref.current.focus();
    }
  }, [
    index,
    dropdown?.isOpen,
    dropdown?.transitionState?.phase,
    selectMenu.focusIndex,
  ]);

  const handleSelect = (event: MouseEvent | KeyboardEvent) => {
    if (selectMenu.closeOnSelect) dropdown?.close();
    onSelect?.(event, value);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case "Enter":
      case " ":
        event.preventDefault();
        handleSelect(event);
        break;

      case "Escape":
      case "Tab": {
        if (dropdown) {
          event.preventDefault();
          dropdown.close();
        }
        break;
      }

      default:
        break;
    }
  };

  const handleMouseEnter = () => {
    selectMenu.setFocusIndex(index);
  };

  const isSelected = selectMenu.isSelected(value);

  return (
    <li
      role="option"
      aria-selected={isSelected}
      tabIndex={-1}
      onClick={handleSelect}
      onKeyDown={handleKeyDown}
      onMouseEnter={handleMouseEnter}
      ref={ref}
      {...rest}
    >
      <SelectionIndicator>{isSelected && <CheckIcon />}</SelectionIndicator>
      <Label>{children}</Label>
    </li>
  );
};

const SelectionIndicator = styled.div`
  ${centerContent};
  ${fgColor.primary()};
  flex: 0 0 20px;
  width: 20px;
  margin-right: 8px;
`;

const CheckIcon = () => (
  <svg width="14" height="12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g
      transform="translate(-1 -2)"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="evenodd"
    >
      <path
        d="M14.544 3.332l-1.747-1.153c-.485-.314-1.154-.204-1.497.25L6.542 8.8 3.908 7.073c-.476-.321-1.154-.212-1.488.243L1.19 8.964a.972.972 0 0 0 .26 1.396l3.512 2.306.025.016 1.722 1.137c.243.157.535.212.803.165.268-.047.527-.189.694-.416l6.597-8.84a.96.96 0 0 0-.259-1.396z"
        id="a"
      />
    </g>
  </svg>
);

const Label = styled.div`
  flex: 1;
`;

export default styled(SelectMenuOption)`
  ${borderBottom.gray300()};
  display: flex;
  white-space: nowrap;
  font-size: 0.875rem;
  padding: 0 8px;
  min-width: 10rem;
  height: 40px;
  align-items: center;
  line-height: 1.2;

  &:last-child {
    border-bottom: none;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &:focus {
    ${bgColor.primary()};
    ${fgColor.white()};

    ${SelectionIndicator} {
      ${fgColor.white()};
    }
  }
`;
