import React from "react";
import styled, { css } from "styled-components";

const Triangle = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 12 16"
  >
    <path fill="evenodd" d="M0 5l6 6 6-6H0z" />
  </svg>
);

export const TriangleDown = styled(Triangle)`
  transform: rotate(0deg);
  vertical-align: top;
`;

export const ExpandableIcon = styled(TriangleDown)<{ collapsed: boolean }>`
  margin: 0 5px 0 0;
  transition: transform 0.2s ease-out;
  ${(props) =>
    props.collapsed &&
    css`
      transform: rotate(270deg);
    `};
`;
