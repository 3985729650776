import React from "react";
import styled from "styled-components";

import { GroupRowFragment } from "~/components/Admin/Group/GroupsTable.generated";
import { MenuButton, TertiaryButton } from "~/components/common/buttons";
import { MoreIcon, PencilIcon, TrashIcon } from "~/components/common/icons";
import {
  MenuTooltipContainer,
  useMenuTooltip,
} from "~/components/common/tooltips";
import { gray100 } from "~/styles/theme/color";

export interface GroupMenuProps {
  group: GroupRowFragment;
  loading: boolean;
  remove(group: GroupRowFragment): void;
  update(group: GroupRowFragment): void;
}

export const GroupMenu = React.memo(function GroupMenu(props: GroupMenuProps) {
  const { group, update, remove, loading } = props;

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    useMenuTooltip();

  return (
    <>
      <DropdownButton ref={setTriggerRef} children={<MoreIcon />} />
      {visible && (
        <MenuTooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
          <MenuButton onClick={() => update(group)} disabled={loading}>
            <PencilIcon />
            <span>Edit</span>
          </MenuButton>
          <MenuButton danger onClick={() => remove(group)} disabled={loading}>
            <TrashIcon />
            <span>Delete</span>
          </MenuButton>
        </MenuTooltipContainer>
      )}
    </>
  );
});

const DropdownButton = styled(TertiaryButton)`
  background-color: ${gray100};
`;
