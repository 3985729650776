import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { SkeletonThumbnail } from "~/components/common/skeletons";
import { gray300 } from "~/styles/theme/color";

import {
  Document_InfoFragment,
  Document_PreviewFragment,
} from "./DocumentsAPI.generated";

const IMAGE_TYPES = [".png", ".jpg", ".jpeg"];

export interface DocumentsPreviewProps {
  className?: string;
  document?: null | (Document_InfoFragment & Document_PreviewFragment);
}

export const DocumentsPreview = React.memo(function DocumentsPreview(
  props: DocumentsPreviewProps
) {
  const { className, document } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const isImage =
    document?.previewUrl &&
    IMAGE_TYPES.some((ext) => document?.name?.toLowerCase().endsWith(ext));
  const isPdf = document?.previewUrl && !isImage;

  const handleLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  const handleError = useCallback(() => {
    setIsLoading(false);
    setIsError(true);
  }, []);

  // Chrome has an issue where PDFs in iframes will not load successfully
  // if the page/tab is in the background.
  // See: https://github.com/CleanCapital/cleancapital_dataroom/issues/2092
  // To fix this, we use requestAnimationFrame to defer rendering the iframe
  // in background tabs.
  const [isViewportReady, setIsViewportReady] = useState(false);
  useEffect(() => {
    requestAnimationFrame(() => setIsViewportReady(true));
  }, []);

  return (
    <FilePreviewContainer className={className}>
      {isLoading && !isError && <FilePreviewSkeleton />}
      {isImage && !isError && (
        <FilePreviewImage
          alt="Preview"
          src={document?.previewUrl ?? ""}
          isLoading={isLoading}
          onLoad={handleLoad}
          onError={handleError}
        />
      )}
      {isViewportReady && isPdf && (
        <FilePreviewIframe
          title="Preview"
          src={`${document?.previewUrl}#toolbar=0`}
          isLoading={isLoading}
          onLoad={handleLoad}
        />
      )}
      {isError && (
        <FilePreviewIframe
          title="Preview"
          src="/file-store/no-preview"
          isLoading={false}
        />
      )}
    </FilePreviewContainer>
  );
});

const FilePreviewContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  margin-bottom: 0.75rem;
  border: 1px solid ${gray300};
`;

const FilePreviewImage = styled.img<{ isLoading: boolean }>`
  display: ${(props) => props.isLoading && "none"};
  max-width: 100%;
`;

const FilePreviewIframe = styled.iframe<{ isLoading: boolean }>`
  display: ${(props) => props.isLoading && "none"};
  width: 100%;
  height: 100%;
  border: none;
`;

const FilePreviewSkeleton = styled(SkeletonThumbnail)`
  width: 100%;
  height: 100%;
`;
