import React from "react";
import { Config, usePopperTooltip } from "react-popper-tooltip";

interface ToolTipProps extends Config {
  children: React.ReactNode;
  tooltip: React.ReactNode;
  hideArrow?: boolean;
}

const ToolTip = (props: ToolTipProps) => {
  const { children, tooltip, hideArrow = false, ...rest } = props;

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(rest);

  return (
    <>
      <div ref={setTriggerRef}>{children}</div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
        >
          {tooltip}
          {!hideArrow && (
            <div {...getArrowProps({ className: "tooltip-arrow" })} />
          )}
        </div>
      )}
    </>
  );
};

export default ToolTip;
