import classNames from "classnames";
import { SortIcon } from "components/icons";
import React, { useState } from "react";
import styled from "styled-components";
import {
  displayPercentChange,
  formatProduction,
  formatSize,
  formatThousands,
  percentChange,
} from "utils/calculations";

import BannerHeader from "~/components/Banners/BannerHeader";
import Stat from "~/components/typography/Stat";
import StatLabel from "~/components/typography/StatLabel";
import { formattedNumber } from "~/components/utils/portfolio";
import { bgColor } from "~/styles/mixins";
import { gettext } from "~/utils/text";

export interface HoldcoStatsProps {
  // FIXME: Convert this page to GraphQL and use strong types
  holdco: any;
  showAdmin: boolean;
  showUsersGroups: boolean;
}

const HoldcoStats = ({
  holdco,
  showAdmin,
  showUsersGroups,
}: HoldcoStatsProps) => {
  const [collapsedHeader, setCollapsedHeader] = useState(false);
  const revenuePercentChange = percentChange(
    holdco.total_actual_revenue_ytd,
    holdco.total_forecast_revenue_ytd
  );
  const expensesPercentChange = percentChange(
    holdco.total_actual_expenses_ytd,
    holdco.total_forecast_expenses_ytd
  );
  const productionPercentChange = percentChange(
    holdco.total_actual_production_ytd,
    holdco.total_forecast_production_ytd
  );

  return (
    <BlackBanner enableDashboardData={holdco.enable_dashboard_data}>
      <BannerHeader
        title={holdco.name}
        adminLink={`/tagauks/portfolios/holdingcompany/${holdco.id}/change`}
        collapsed={collapsedHeader}
        setCollapsed={setCollapsedHeader}
        showAdmin={showAdmin}
        showUsersGroups={showUsersGroups}
      />
      <StatHolder collapse={collapsedHeader}>
        <div>
          <StatLabel>Type</StatLabel>
          <Stat>HOLDCO</Stat>
        </div>
        <div>
          <StatLabel>System Size</StatLabel>
          <Stat>{formatSize(holdco.total_size)}</Stat>
        </div>
        {holdco.enable_dashboard_data && (
          <div>
            <StatLabel>Coupon - Senior Debt</StatLabel>
            <Stat>{`${formattedNumber(holdco.coupon_senior_debt)} %`}</Stat>
          </div>
        )}
        <div>
          <StatLabel>Offering Type</StatLabel>
          <Stat>{holdco.offering_type}</Stat>
        </div>
        {holdco.enable_dashboard_data && (
          <div>
            <StatLabel>Revenue YTD {holdco.production_year}</StatLabel>
            <Row>
              <Stat>{`$${formatThousands(
                holdco.total_actual_revenue_ytd
              )}`}</Stat>
              <StackedContainer>
                <Forecast
                  className={classNames(
                    {
                      "text-success": revenuePercentChange >= 0,
                      "text-danger": revenuePercentChange < 0,
                    },
                    "d-flex",
                    "align-items-center",
                    "forecast-data"
                  )}
                >
                  <StatIcon
                    theme={revenuePercentChange >= 0 ? "success" : "danger"}
                    dir={revenuePercentChange < 0 ? "S" : "N"}
                  />
                  {displayPercentChange(revenuePercentChange)}
                </Forecast>
                <StatNote>{gettext("vs Forecast")}</StatNote>
              </StackedContainer>
            </Row>
          </div>
        )}
        {holdco.enable_dashboard_data && (
          <div>
            <StatLabel>Expenses YTD {holdco.production_year}</StatLabel>
            <Row>
              <Stat>{`$${formatThousands(
                holdco.total_actual_expenses_ytd
              )}`}</Stat>
              <StackedContainer>
                <Forecast
                  className={classNames(
                    {
                      "text-success": expensesPercentChange < 0,
                      "text-danger": expensesPercentChange >= 0,
                    },
                    "d-flex",
                    "align-items-center",
                    "forecast-data"
                  )}
                >
                  <StatIcon
                    theme={expensesPercentChange < 0 ? "success" : "danger"}
                    dir={expensesPercentChange < 0 ? "S" : "N"}
                  />
                  {displayPercentChange(expensesPercentChange)}
                </Forecast>
                <StatNote>{gettext("vs Forecast")}</StatNote>
              </StackedContainer>
            </Row>
          </div>
        )}
        {holdco.enable_dashboard_data && (
          <div>
            <StatLabel>Production YTD {holdco.production_year}</StatLabel>
            <Row>
              <Stat>
                {formatProduction(holdco.total_actual_production_ytd)}
              </Stat>
              <StackedContainer>
                <Forecast
                  className={classNames(
                    {
                      "text-success": productionPercentChange >= 0,
                      "text-danger": productionPercentChange < 0,
                    },
                    "d-flex",
                    "align-items-center",
                    "forecast-data"
                  )}
                >
                  <StatIcon
                    theme={productionPercentChange >= 0 ? "success" : "danger"}
                    dir={productionPercentChange < 0 ? "S" : "N"}
                  />
                  {displayPercentChange(productionPercentChange)}
                </Forecast>
                <StatNote>{gettext("vs Forecast")}</StatNote>
              </StackedContainer>
            </Row>
          </div>
        )}
      </StatHolder>
    </BlackBanner>
  );
};

const BlackBanner = styled.div<{ enableDashboardData: boolean }>`
  padding: 5px 25px 5px;
  ${bgColor.gray800()};
  border-radius: 4px;
  font-size: 14px;
  color: #fffbd2;
  padding-bottom: ${(props) => (props.enableDashboardData ? "10px" : "20px")};
`;

const StatHolder = styled.div<{ collapse: boolean }>`
  display: ${(props) => (props.collapse ? "none" : "grid")};
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  color: #fff !important;
`;

const StackedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 0.45rem;
  margin-left: 0.25rem;
`;

const Forecast = styled.div`
  line-height: 12px;
`;

const StatNote = styled.div`
  color: #c0c0c0;
  font-size: 12px;
`;

const StatIcon = styled(SortIcon)`
  height: 10px;
  padding: 0;
  margin: 0 5px 0 0;
  width: 8px;
`;

const Row = styled.span`
  display: flex;
  flex-direction: row !important;
`;

export default HoldcoStats;
