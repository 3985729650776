import styled from "styled-components";

import { device } from "~/styles/mixins";

const Stat = styled.div`
  font-size: 24px;
  font-weight: 200;
  line-height: 1.2;
  @media ${device.xs} {
    font-size: 32px;
  }
`;

export default Stat;
