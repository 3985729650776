// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DocumentTree_InfoFragment = (
  { readonly __typename: 'DocumentTree' }
  & Pick<Types.DocumentTree, 'id' | 'type' | 'name' | 'permissionsType' | 'accessLogUrl' | 'changeLogUrl' | 'fileIndexUrl' | 'canRename' | 'canEdit' | 'canDelete' | 'canEditPermissions' | 'canViewAccessLog' | 'canViewHistory' | 'allowRootFiles'>
);

export type Document_InfoFragment = (
  { readonly __typename: 'Document' }
  & Pick<Types.Document, 'id' | 'fileType' | 'name' | 'thumbnailUrl'>
);

export type Document_MetaFragment = (
  { readonly __typename: 'Document' }
  & Pick<Types.Document, 'size' | 'modifiedTimestamp'>
  & { readonly labels?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Label' }
    & Pick<Types.Label, 'label' | 'color'>
  )>> }
);

export type Document_PreviewFragment = (
  { readonly __typename: 'Document' }
  & Pick<Types.Document, 'previewUrl'>
);

export type Document_PermissionsFragment = (
  { readonly __typename: 'Document' }
  & Pick<Types.Document, 'id' | 'fileType' | 'name' | 'thumbnailUrl'>
  & { readonly permissions?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'DocumentPermission' }
    & Pick<Types.DocumentPermission, 'id' | 'permissible'>
    & { readonly principal?: Types.Maybe<(
      { readonly __typename: 'UserGroup' }
      & Pick<Types.UserGroup, 'id' | 'name'>
    ) | (
      { readonly __typename: 'Role' }
      & Pick<Types.Role, 'id' | 'name'>
    )> }
  )>>> }
);

export type Document_BreadcrumbsFragment = (
  { readonly __typename: 'Document' }
  & Pick<Types.Document, 'id' | 'fileType' | 'name' | 'thumbnailUrl'>
  & { readonly ancestors?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'Document' }
    & Pick<Types.Document, 'id' | 'fileType' | 'name' | 'thumbnailUrl'>
    & { readonly ancestors?: Types.Maybe<ReadonlyArray<Types.Maybe<(
      { readonly __typename: 'Document' }
      & Pick<Types.Document, 'id'>
    )>>> }
  )>>> }
);

export type Document_SiblingsFragment = (
  { readonly __typename: 'Document' }
  & { readonly parent?: Types.Maybe<(
    { readonly __typename: 'Document' }
    & Pick<Types.Document, 'id'>
    & { readonly children?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'Document' }
      & Pick<Types.Document, 'id' | 'fileType' | 'name' | 'thumbnailUrl'>
      & { readonly parent?: Types.Maybe<(
        { readonly __typename: 'Document' }
        & Pick<Types.Document, 'id'>
      )>, readonly ancestors?: Types.Maybe<ReadonlyArray<Types.Maybe<(
        { readonly __typename: 'Document' }
        & Pick<Types.Document, 'id'>
      )>>> }
    )>> }
  )> }
);

export type Document_ChildrenFragment = (
  { readonly __typename: 'Document' }
  & { readonly children?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Document' }
    & Pick<Types.Document, 'id' | 'fileType' | 'name' | 'thumbnailUrl' | 'size' | 'modifiedTimestamp'>
    & { readonly labels?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'Label' }
      & Pick<Types.Label, 'label' | 'color'>
    )>>, readonly permissions?: Types.Maybe<ReadonlyArray<Types.Maybe<(
      { readonly __typename: 'DocumentPermission' }
      & Pick<Types.DocumentPermission, 'id' | 'permissible'>
      & { readonly principal?: Types.Maybe<(
        { readonly __typename: 'UserGroup' }
        & Pick<Types.UserGroup, 'id' | 'name'>
      ) | (
        { readonly __typename: 'Role' }
        & Pick<Types.Role, 'id' | 'name'>
      )> }
    )>>>, readonly ancestors?: Types.Maybe<ReadonlyArray<Types.Maybe<(
      { readonly __typename: 'Document' }
      & Pick<Types.Document, 'id'>
    )>>> }
  )>> }
);

export type Document_ColumnFragment = (
  { readonly __typename: 'Document' }
  & Pick<Types.Document, 'id'>
  & { readonly children?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Document' }
    & Pick<Types.Document, 'id' | 'fileType' | 'name' | 'thumbnailUrl'>
    & { readonly ancestors?: Types.Maybe<ReadonlyArray<Types.Maybe<(
      { readonly __typename: 'Document' }
      & Pick<Types.Document, 'id'>
    )>>> }
  )>> }
);

export type Document_AncestorColumnsFragment = (
  { readonly __typename: 'Document' }
  & { readonly ancestors?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'Document' }
    & Pick<Types.Document, 'id'>
    & { readonly children?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'Document' }
      & Pick<Types.Document, 'id' | 'fileType' | 'name' | 'thumbnailUrl'>
      & { readonly ancestors?: Types.Maybe<ReadonlyArray<Types.Maybe<(
        { readonly __typename: 'Document' }
        & Pick<Types.Document, 'id'>
      )>>> }
    )>> }
  )>>> }
);

export type DocumentActivity_DetailFragment = (
  { readonly __typename: 'DocumentActivity' }
  & Pick<Types.DocumentActivity, 'id' | 'activityType' | 'active' | 'recordedPath' | 'version' | 'createdAt'>
  & { readonly user: (
    { readonly __typename: 'UserType' }
    & Pick<Types.UserType, 'id' | 'fullName'>
  ), readonly userGroups?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'UserGroup' }
    & Pick<Types.UserGroup, 'id' | 'name'>
  )>>, readonly document: (
    { readonly __typename: 'Document' }
    & Document_BreadcrumbsFragment
    & Document_MetaFragment
  ) }
);

export type SummaryJobFragmentFragment = (
  { readonly __typename: 'SummaryJob' }
  & Pick<Types.SummaryJob, 'id' | 'name' | 'complete' | 'outputFileUrl'>
);

export type DocumentsDetailQueryVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  documentId: Types.Scalars['ID'];
}>;


export type DocumentsDetailQuery = (
  { readonly __typename: 'Query' }
  & { readonly documentTree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & DocumentTree_InfoFragment
  )>, readonly document?: Types.Maybe<(
    { readonly __typename: 'Document' }
    & Pick<Types.Document, 'previewUrl'>
    & Document_BreadcrumbsFragment
    & Document_MetaFragment
    & Document_SiblingsFragment
    & Document_ChildrenFragment
  )> }
);

export type DocumentsColumnsQueryVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  documentId: Types.Scalars['ID'];
}>;


export type DocumentsColumnsQuery = (
  { readonly __typename: 'Query' }
  & { readonly documentTree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & DocumentTree_InfoFragment
  )>, readonly document?: Types.Maybe<(
    { readonly __typename: 'Document' }
    & Document_BreadcrumbsFragment
    & Document_MetaFragment
    & Document_PermissionsFragment
    & Document_ColumnFragment
    & Document_AncestorColumnsFragment
    & Document_PreviewFragment
  )> }
);

export type DocumentsSearchQueryVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  documentId: Types.Scalars['ID'];
  search: Types.Scalars['String'];
}>;


export type DocumentsSearchQuery = (
  { readonly __typename: 'Query' }
  & { readonly documentTree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & DocumentTree_InfoFragment
  )>, readonly document?: Types.Maybe<(
    { readonly __typename: 'Document' }
    & { readonly descendants?: Types.Maybe<ReadonlyArray<Types.Maybe<(
      { readonly __typename: 'Document' }
      & Document_BreadcrumbsFragment
      & Document_MetaFragment
      & Document_PermissionsFragment
    )>>> }
    & Document_BreadcrumbsFragment
  )> }
);

export type DocumentsHistoryQueryVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  rootId: Types.Scalars['ID'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  search?: Types.Maybe<Types.Scalars['String']>;
  userGroupIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
  ancestorIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
  activityTypes?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['Int']>> | Types.Maybe<Types.Scalars['Int']>>;
  fileTypes?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
}>;


export type DocumentsHistoryQuery = (
  { readonly __typename: 'Query' }
  & { readonly documentTree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & DocumentTree_InfoFragment
  )>, readonly document?: Types.Maybe<(
    { readonly __typename: 'Document' }
    & Document_BreadcrumbsFragment
    & Document_ChildrenFragment
  )>, readonly writeHistory: (
    { readonly __typename: 'PaginatedDocumentActivity' }
    & Pick<Types.PaginatedDocumentActivity, 'count'>
    & { readonly activity?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'DocumentActivity' }
      & DocumentActivity_DetailFragment
    )>> }
  ) }
);

export type DocumentsUserGroupsQueryVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
}>;


export type DocumentsUserGroupsQuery = (
  { readonly __typename: 'Query' }
  & { readonly userGroups?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'UserGroup' }
    & Pick<Types.UserGroup, 'id' | 'name'>
  )>> }
);

export type CopilotDocumentsQueryVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  labels?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
}>;


export type CopilotDocumentsQuery = (
  { readonly __typename: 'Query' }
  & { readonly documents?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Document' }
    & Pick<Types.Document, 'id' | 'name'>
  )>> }
);

export type ProjectCrossLinksQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID'];
}>;


export type ProjectCrossLinksQuery = (
  { readonly __typename: 'Query' }
  & { readonly projectCrossLinks?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'ProjectCrossLink' }
    & { readonly document?: Types.Maybe<(
      { readonly __typename: 'Document' }
      & Pick<Types.Document, 'id' | 'name' | 'link'>
    )>, readonly portfolio?: Types.Maybe<(
      { readonly __typename: 'Portfolio' }
      & Pick<Types.Portfolio, 'id' | 'name' | 'slug'>
    )> }
  )>>> }
);

export type SummaryJobsQueryVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
}>;


export type SummaryJobsQuery = (
  { readonly __typename: 'Query' }
  & { readonly summaryJobs?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'SummaryJob' }
    & SummaryJobFragmentFragment
  )>> }
);

export type SummaryExtractItemsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SummaryExtractItemsQuery = (
  { readonly __typename: 'Query' }
  & { readonly summaryExtractItems?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'SummaryExtractItem' }
    & Pick<Types.SummaryExtractItem, 'id' | 'displayName' | 'jobType'>
  )>> }
);

export type CreateSummaryJobMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  jobType: Types.SummaryJobType;
  documentIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
}>;


export type CreateSummaryJobMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createSummaryJob?: Types.Maybe<(
    { readonly __typename: 'SummaryJob' }
    & SummaryJobFragmentFragment
  )> }
);

export type DocumentsCreateFoldersMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  parentId?: Types.Maybe<Types.Scalars['ID']>;
  folderPaths: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type DocumentsCreateFoldersMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createFolders?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'Document' }
    & Document_BreadcrumbsFragment
    & Document_SiblingsFragment
  )>>> }
);

export type DocumentsPresignUploadsMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  files: ReadonlyArray<Types.FileInput> | Types.FileInput;
}>;


export type DocumentsPresignUploadsMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createPresignedFiles?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'CreatePresignedFilesResult' }
    & Pick<Types.CreatePresignedFilesResult, 'url' | 'version'>
    & { readonly file?: Types.Maybe<(
      { readonly __typename: 'Document' }
      & Pick<Types.Document, 'parentId'>
      & Document_BreadcrumbsFragment
      & Document_SiblingsFragment
    )> }
  )>>> }
);

export type DocumentsConfirmUploadsMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  uploads: ReadonlyArray<Types.ConfirmUploadInput> | Types.ConfirmUploadInput;
}>;


export type DocumentsConfirmUploadsMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly confirmUploads: (
    { readonly __typename: 'ConfirmUploadsResult' }
    & Pick<Types.ConfirmUploadsResult, 'failedIds'>
    & { readonly files: ReadonlyArray<(
      { readonly __typename: 'Document' }
      & Pick<Types.Document, 'parentId'>
      & Document_BreadcrumbsFragment
      & Document_SiblingsFragment
    )> }
  ) }
);

export type DocumentsMoveMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  sourceDocumentIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
  destinationParentId: Types.Scalars['ID'];
}>;


export type DocumentsMoveMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly moveDocument?: Types.Maybe<(
    { readonly __typename: 'ModifiedFolderOutput' }
    & { readonly parents?: Types.Maybe<ReadonlyArray<Types.Maybe<(
      { readonly __typename: 'Document' }
      & { readonly children?: Types.Maybe<ReadonlyArray<(
        { readonly __typename: 'Document' }
        & Document_BreadcrumbsFragment
      )>> }
      & Document_BreadcrumbsFragment
    )>>> }
  )> }
);

export type DocumentsDownloadMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  documentId: Types.Scalars['ID'];
}>;


export type DocumentsDownloadMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly downloadFile?: Types.Maybe<(
    { readonly __typename: 'FileUrl' }
    & Pick<Types.FileUrl, 'url' | 'name'>
  )> }
);

export type DocumentsRenameMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  documentId: Types.Scalars['ID'];
  name: Types.Scalars['String'];
}>;


export type DocumentsRenameMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly renameDocument?: Types.Maybe<(
    { readonly __typename: 'RenameDocumentMutationResult' }
    & Pick<Types.RenameDocumentMutationResult, 'error'>
    & { readonly document?: Types.Maybe<(
      { readonly __typename: 'Document' }
      & Pick<Types.Document, 'id' | 'name' | 'modifiedTimestamp'>
    )> }
  )> }
);

export type DocumentsRemoveDryRunMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  documentIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
}>;


export type DocumentsRemoveDryRunMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly removeDocumentsDryRun?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'Document' }
    & Pick<Types.Document, 'id' | 'fileType' | 'name' | 'parentId'>
  )>>> }
);

export type DocumentsRemoveMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  documentIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
}>;


export type DocumentsRemoveMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly removeDocuments?: Types.Maybe<(
    { readonly __typename: 'ModifiedFolderOutput' }
    & Pick<Types.ModifiedFolderOutput, 'deletedIds'>
    & { readonly parents?: Types.Maybe<ReadonlyArray<Types.Maybe<(
      { readonly __typename: 'Document' }
      & Pick<Types.Document, 'id'>
      & { readonly children?: Types.Maybe<ReadonlyArray<(
        { readonly __typename: 'Document' }
        & Pick<Types.Document, 'id'>
      )>> }
    )>>> }
  )> }
);

export type DocumentsCreateFolderMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  parentId?: Types.Maybe<Types.Scalars['ID']>;
  name: Types.Scalars['String'];
}>;


export type DocumentsCreateFolderMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createFolder?: Types.Maybe<(
    { readonly __typename: 'Document' }
    & Document_InfoFragment
    & Document_SiblingsFragment
  )> }
);

export type DocumentsUpdatePermissionsMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  documentPermissionId: Types.Scalars['ID'];
  permissible: Types.Scalars['Boolean'];
}>;


export type DocumentsUpdatePermissionsMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateDocumentPermission?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'DocumentPermission' }
    & Pick<Types.DocumentPermission, 'id' | 'permissible'>
  )>>> }
);

export type DocumentsSubmitZipDownloadsMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  documentIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
}>;


export type DocumentsSubmitZipDownloadsMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly submitZipDownload?: Types.Maybe<(
    { readonly __typename: 'ZipDownloadOutput' }
    & { readonly exportable?: Types.Maybe<ReadonlyArray<Types.Maybe<(
      { readonly __typename: 'Document' }
      & Pick<Types.Document, 'id' | 'name' | 'filePath' | 'fileType' | 'size'>
    )>>>, readonly unexportable?: Types.Maybe<ReadonlyArray<Types.Maybe<(
      { readonly __typename: 'Document' }
      & Pick<Types.Document, 'id' | 'name' | 'filePath' | 'fileType' | 'size'>
    )>>> }
  )> }
);

export const DocumentTree_InfoFragmentDoc = gql`
    fragment DocumentTree_info on DocumentTree {
  id
  type
  name
  permissionsType
  accessLogUrl
  changeLogUrl
  fileIndexUrl
  canRename
  canEdit
  canDelete
  canEditPermissions
  canViewAccessLog
  canViewHistory
  allowRootFiles
}
    `;
export const Document_InfoFragmentDoc = gql`
    fragment Document_info on Document {
  id
  fileType
  name
  thumbnailUrl
}
    `;
export const Document_PreviewFragmentDoc = gql`
    fragment Document_preview on Document {
  previewUrl
}
    `;
export const Document_PermissionsFragmentDoc = gql`
    fragment Document_permissions on Document {
  id
  fileType
  name
  thumbnailUrl
  permissions {
    id
    permissible
    principal {
      ... on Role {
        id
        name
      }
      ... on UserGroup {
        id
        name
      }
    }
  }
}
    `;
export const Document_SiblingsFragmentDoc = gql`
    fragment Document_siblings on Document {
  parent {
    id
    children {
      id
      fileType
      name
      thumbnailUrl
      parent {
        id
      }
      ancestors {
        id
      }
    }
  }
}
    `;
export const Document_ChildrenFragmentDoc = gql`
    fragment Document_children on Document {
  children {
    id
    fileType
    name
    thumbnailUrl
    size
    labels {
      label
      color
    }
    modifiedTimestamp
    permissions {
      id
      permissible
      principal {
        ... on Role {
          id
          name
        }
        ... on UserGroup {
          id
          name
        }
      }
    }
    ancestors {
      id
    }
  }
}
    `;
export const Document_ColumnFragmentDoc = gql`
    fragment Document_column on Document {
  id
  children {
    id
    fileType
    name
    thumbnailUrl
    ancestors {
      id
    }
  }
}
    `;
export const Document_AncestorColumnsFragmentDoc = gql`
    fragment Document_ancestorColumns on Document {
  ancestors {
    id
    children {
      id
      fileType
      name
      thumbnailUrl
      ancestors {
        id
      }
    }
  }
}
    `;
export const Document_BreadcrumbsFragmentDoc = gql`
    fragment Document_breadcrumbs on Document {
  id
  fileType
  name
  thumbnailUrl
  ancestors {
    id
    fileType
    name
    thumbnailUrl
    ancestors {
      id
    }
  }
}
    `;
export const Document_MetaFragmentDoc = gql`
    fragment Document_meta on Document {
  labels {
    label
    color
  }
  size
  modifiedTimestamp
}
    `;
export const DocumentActivity_DetailFragmentDoc = gql`
    fragment DocumentActivity_detail on DocumentActivity {
  id
  activityType
  user {
    id
    fullName
  }
  userGroups {
    id
    name
  }
  document {
    ...Document_breadcrumbs
    ...Document_meta
  }
  active
  recordedPath
  version
  createdAt
}
    ${Document_BreadcrumbsFragmentDoc}
${Document_MetaFragmentDoc}`;
export const SummaryJobFragmentFragmentDoc = gql`
    fragment SummaryJobFragment on SummaryJob {
  id
  name
  complete
  outputFileUrl
}
    `;
export const DocumentsDetailDocument = gql`
    query DocumentsDetail($treeId: ID!, $documentId: ID!) {
  documentTree(id: $treeId) {
    ...DocumentTree_info
  }
  document(treeId: $treeId, documentId: $documentId) {
    ...Document_breadcrumbs
    ...Document_meta
    ...Document_siblings
    ...Document_children
    previewUrl
  }
}
    ${DocumentTree_InfoFragmentDoc}
${Document_BreadcrumbsFragmentDoc}
${Document_MetaFragmentDoc}
${Document_SiblingsFragmentDoc}
${Document_ChildrenFragmentDoc}`;

/**
 * __useDocumentsDetailQuery__
 *
 * To run a query within a React component, call `useDocumentsDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsDetailQuery({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentsDetailQuery(baseOptions: Apollo.QueryHookOptions<DocumentsDetailQuery, DocumentsDetailQueryVariables>) {
        return Apollo.useQuery<DocumentsDetailQuery, DocumentsDetailQueryVariables>(DocumentsDetailDocument, baseOptions);
      }
export function useDocumentsDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsDetailQuery, DocumentsDetailQueryVariables>) {
          return Apollo.useLazyQuery<DocumentsDetailQuery, DocumentsDetailQueryVariables>(DocumentsDetailDocument, baseOptions);
        }
export type DocumentsDetailQueryHookResult = ReturnType<typeof useDocumentsDetailQuery>;
export type DocumentsDetailLazyQueryHookResult = ReturnType<typeof useDocumentsDetailLazyQuery>;
export type DocumentsDetailQueryResult = Apollo.QueryResult<DocumentsDetailQuery, DocumentsDetailQueryVariables>;
export const DocumentsColumnsDocument = gql`
    query DocumentsColumns($treeId: ID!, $documentId: ID!) {
  documentTree(id: $treeId) {
    ...DocumentTree_info
  }
  document(treeId: $treeId, documentId: $documentId) {
    ...Document_breadcrumbs
    ...Document_meta
    ...Document_permissions
    ...Document_column
    ...Document_ancestorColumns
    ...Document_preview
  }
}
    ${DocumentTree_InfoFragmentDoc}
${Document_BreadcrumbsFragmentDoc}
${Document_MetaFragmentDoc}
${Document_PermissionsFragmentDoc}
${Document_ColumnFragmentDoc}
${Document_AncestorColumnsFragmentDoc}
${Document_PreviewFragmentDoc}`;

/**
 * __useDocumentsColumnsQuery__
 *
 * To run a query within a React component, call `useDocumentsColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsColumnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsColumnsQuery({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentsColumnsQuery(baseOptions: Apollo.QueryHookOptions<DocumentsColumnsQuery, DocumentsColumnsQueryVariables>) {
        return Apollo.useQuery<DocumentsColumnsQuery, DocumentsColumnsQueryVariables>(DocumentsColumnsDocument, baseOptions);
      }
export function useDocumentsColumnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsColumnsQuery, DocumentsColumnsQueryVariables>) {
          return Apollo.useLazyQuery<DocumentsColumnsQuery, DocumentsColumnsQueryVariables>(DocumentsColumnsDocument, baseOptions);
        }
export type DocumentsColumnsQueryHookResult = ReturnType<typeof useDocumentsColumnsQuery>;
export type DocumentsColumnsLazyQueryHookResult = ReturnType<typeof useDocumentsColumnsLazyQuery>;
export type DocumentsColumnsQueryResult = Apollo.QueryResult<DocumentsColumnsQuery, DocumentsColumnsQueryVariables>;
export const DocumentsSearchDocument = gql`
    query DocumentsSearch($treeId: ID!, $documentId: ID!, $search: String!) {
  documentTree(id: $treeId) {
    ...DocumentTree_info
  }
  document(treeId: $treeId, documentId: $documentId) {
    ...Document_breadcrumbs
    descendants(search: $search) {
      ...Document_breadcrumbs
      ...Document_meta
      ...Document_permissions
    }
  }
}
    ${DocumentTree_InfoFragmentDoc}
${Document_BreadcrumbsFragmentDoc}
${Document_MetaFragmentDoc}
${Document_PermissionsFragmentDoc}`;

/**
 * __useDocumentsSearchQuery__
 *
 * To run a query within a React component, call `useDocumentsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsSearchQuery({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      documentId: // value for 'documentId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDocumentsSearchQuery(baseOptions: Apollo.QueryHookOptions<DocumentsSearchQuery, DocumentsSearchQueryVariables>) {
        return Apollo.useQuery<DocumentsSearchQuery, DocumentsSearchQueryVariables>(DocumentsSearchDocument, baseOptions);
      }
export function useDocumentsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsSearchQuery, DocumentsSearchQueryVariables>) {
          return Apollo.useLazyQuery<DocumentsSearchQuery, DocumentsSearchQueryVariables>(DocumentsSearchDocument, baseOptions);
        }
export type DocumentsSearchQueryHookResult = ReturnType<typeof useDocumentsSearchQuery>;
export type DocumentsSearchLazyQueryHookResult = ReturnType<typeof useDocumentsSearchLazyQuery>;
export type DocumentsSearchQueryResult = Apollo.QueryResult<DocumentsSearchQuery, DocumentsSearchQueryVariables>;
export const DocumentsHistoryDocument = gql`
    query DocumentsHistory($treeId: ID!, $rootId: ID!, $limit: Int, $offset: Int, $search: String, $userGroupIds: [ID], $ancestorIds: [ID], $activityTypes: [Int], $fileTypes: [String]) {
  documentTree(id: $treeId) {
    ...DocumentTree_info
  }
  document(treeId: $treeId, documentId: $rootId) {
    ...Document_breadcrumbs
    ...Document_children
  }
  writeHistory(
    treeId: $treeId
    limit: $limit
    offset: $offset
    search: $search
    userGroupIds: $userGroupIds
    ancestorIds: $ancestorIds
    activityTypes: $activityTypes
    fileTypes: $fileTypes
  ) {
    count
    activity {
      ...DocumentActivity_detail
    }
  }
}
    ${DocumentTree_InfoFragmentDoc}
${Document_BreadcrumbsFragmentDoc}
${Document_ChildrenFragmentDoc}
${DocumentActivity_DetailFragmentDoc}`;

/**
 * __useDocumentsHistoryQuery__
 *
 * To run a query within a React component, call `useDocumentsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsHistoryQuery({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      rootId: // value for 'rootId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      userGroupIds: // value for 'userGroupIds'
 *      ancestorIds: // value for 'ancestorIds'
 *      activityTypes: // value for 'activityTypes'
 *      fileTypes: // value for 'fileTypes'
 *   },
 * });
 */
export function useDocumentsHistoryQuery(baseOptions: Apollo.QueryHookOptions<DocumentsHistoryQuery, DocumentsHistoryQueryVariables>) {
        return Apollo.useQuery<DocumentsHistoryQuery, DocumentsHistoryQueryVariables>(DocumentsHistoryDocument, baseOptions);
      }
export function useDocumentsHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsHistoryQuery, DocumentsHistoryQueryVariables>) {
          return Apollo.useLazyQuery<DocumentsHistoryQuery, DocumentsHistoryQueryVariables>(DocumentsHistoryDocument, baseOptions);
        }
export type DocumentsHistoryQueryHookResult = ReturnType<typeof useDocumentsHistoryQuery>;
export type DocumentsHistoryLazyQueryHookResult = ReturnType<typeof useDocumentsHistoryLazyQuery>;
export type DocumentsHistoryQueryResult = Apollo.QueryResult<DocumentsHistoryQuery, DocumentsHistoryQueryVariables>;
export const DocumentsUserGroupsDocument = gql`
    query DocumentsUserGroups($treeId: ID!) {
  userGroups(treeId: $treeId) {
    id
    name
  }
}
    `;

/**
 * __useDocumentsUserGroupsQuery__
 *
 * To run a query within a React component, call `useDocumentsUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsUserGroupsQuery({
 *   variables: {
 *      treeId: // value for 'treeId'
 *   },
 * });
 */
export function useDocumentsUserGroupsQuery(baseOptions: Apollo.QueryHookOptions<DocumentsUserGroupsQuery, DocumentsUserGroupsQueryVariables>) {
        return Apollo.useQuery<DocumentsUserGroupsQuery, DocumentsUserGroupsQueryVariables>(DocumentsUserGroupsDocument, baseOptions);
      }
export function useDocumentsUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsUserGroupsQuery, DocumentsUserGroupsQueryVariables>) {
          return Apollo.useLazyQuery<DocumentsUserGroupsQuery, DocumentsUserGroupsQueryVariables>(DocumentsUserGroupsDocument, baseOptions);
        }
export type DocumentsUserGroupsQueryHookResult = ReturnType<typeof useDocumentsUserGroupsQuery>;
export type DocumentsUserGroupsLazyQueryHookResult = ReturnType<typeof useDocumentsUserGroupsLazyQuery>;
export type DocumentsUserGroupsQueryResult = Apollo.QueryResult<DocumentsUserGroupsQuery, DocumentsUserGroupsQueryVariables>;
export const CopilotDocumentsDocument = gql`
    query CopilotDocuments($treeId: ID!, $labels: [String]) {
  documents(treeId: $treeId, labels: $labels) {
    id
    name
  }
}
    `;

/**
 * __useCopilotDocumentsQuery__
 *
 * To run a query within a React component, call `useCopilotDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopilotDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopilotDocumentsQuery({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      labels: // value for 'labels'
 *   },
 * });
 */
export function useCopilotDocumentsQuery(baseOptions: Apollo.QueryHookOptions<CopilotDocumentsQuery, CopilotDocumentsQueryVariables>) {
        return Apollo.useQuery<CopilotDocumentsQuery, CopilotDocumentsQueryVariables>(CopilotDocumentsDocument, baseOptions);
      }
export function useCopilotDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopilotDocumentsQuery, CopilotDocumentsQueryVariables>) {
          return Apollo.useLazyQuery<CopilotDocumentsQuery, CopilotDocumentsQueryVariables>(CopilotDocumentsDocument, baseOptions);
        }
export type CopilotDocumentsQueryHookResult = ReturnType<typeof useCopilotDocumentsQuery>;
export type CopilotDocumentsLazyQueryHookResult = ReturnType<typeof useCopilotDocumentsLazyQuery>;
export type CopilotDocumentsQueryResult = Apollo.QueryResult<CopilotDocumentsQuery, CopilotDocumentsQueryVariables>;
export const ProjectCrossLinksDocument = gql`
    query ProjectCrossLinks($projectId: ID!) {
  projectCrossLinks(projectId: $projectId) {
    document {
      id
      name
      link
    }
    portfolio {
      id
      name
      slug
    }
  }
}
    `;

/**
 * __useProjectCrossLinksQuery__
 *
 * To run a query within a React component, call `useProjectCrossLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCrossLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCrossLinksQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectCrossLinksQuery(baseOptions: Apollo.QueryHookOptions<ProjectCrossLinksQuery, ProjectCrossLinksQueryVariables>) {
        return Apollo.useQuery<ProjectCrossLinksQuery, ProjectCrossLinksQueryVariables>(ProjectCrossLinksDocument, baseOptions);
      }
export function useProjectCrossLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectCrossLinksQuery, ProjectCrossLinksQueryVariables>) {
          return Apollo.useLazyQuery<ProjectCrossLinksQuery, ProjectCrossLinksQueryVariables>(ProjectCrossLinksDocument, baseOptions);
        }
export type ProjectCrossLinksQueryHookResult = ReturnType<typeof useProjectCrossLinksQuery>;
export type ProjectCrossLinksLazyQueryHookResult = ReturnType<typeof useProjectCrossLinksLazyQuery>;
export type ProjectCrossLinksQueryResult = Apollo.QueryResult<ProjectCrossLinksQuery, ProjectCrossLinksQueryVariables>;
export const SummaryJobsDocument = gql`
    query SummaryJobs($treeId: ID!) {
  summaryJobs(treeId: $treeId) {
    ...SummaryJobFragment
  }
}
    ${SummaryJobFragmentFragmentDoc}`;

/**
 * __useSummaryJobsQuery__
 *
 * To run a query within a React component, call `useSummaryJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummaryJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummaryJobsQuery({
 *   variables: {
 *      treeId: // value for 'treeId'
 *   },
 * });
 */
export function useSummaryJobsQuery(baseOptions: Apollo.QueryHookOptions<SummaryJobsQuery, SummaryJobsQueryVariables>) {
        return Apollo.useQuery<SummaryJobsQuery, SummaryJobsQueryVariables>(SummaryJobsDocument, baseOptions);
      }
export function useSummaryJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummaryJobsQuery, SummaryJobsQueryVariables>) {
          return Apollo.useLazyQuery<SummaryJobsQuery, SummaryJobsQueryVariables>(SummaryJobsDocument, baseOptions);
        }
export type SummaryJobsQueryHookResult = ReturnType<typeof useSummaryJobsQuery>;
export type SummaryJobsLazyQueryHookResult = ReturnType<typeof useSummaryJobsLazyQuery>;
export type SummaryJobsQueryResult = Apollo.QueryResult<SummaryJobsQuery, SummaryJobsQueryVariables>;
export const SummaryExtractItemsDocument = gql`
    query SummaryExtractItems {
  summaryExtractItems {
    id
    displayName
    jobType
  }
}
    `;

/**
 * __useSummaryExtractItemsQuery__
 *
 * To run a query within a React component, call `useSummaryExtractItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummaryExtractItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummaryExtractItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSummaryExtractItemsQuery(baseOptions?: Apollo.QueryHookOptions<SummaryExtractItemsQuery, SummaryExtractItemsQueryVariables>) {
        return Apollo.useQuery<SummaryExtractItemsQuery, SummaryExtractItemsQueryVariables>(SummaryExtractItemsDocument, baseOptions);
      }
export function useSummaryExtractItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummaryExtractItemsQuery, SummaryExtractItemsQueryVariables>) {
          return Apollo.useLazyQuery<SummaryExtractItemsQuery, SummaryExtractItemsQueryVariables>(SummaryExtractItemsDocument, baseOptions);
        }
export type SummaryExtractItemsQueryHookResult = ReturnType<typeof useSummaryExtractItemsQuery>;
export type SummaryExtractItemsLazyQueryHookResult = ReturnType<typeof useSummaryExtractItemsLazyQuery>;
export type SummaryExtractItemsQueryResult = Apollo.QueryResult<SummaryExtractItemsQuery, SummaryExtractItemsQueryVariables>;
export const CreateSummaryJobDocument = gql`
    mutation CreateSummaryJob($treeId: ID!, $jobType: SummaryJobType!, $documentIds: [ID]) {
  createSummaryJob(jobType: $jobType, treeId: $treeId, documentIds: $documentIds) {
    ...SummaryJobFragment
  }
}
    ${SummaryJobFragmentFragmentDoc}`;
export type CreateSummaryJobMutationFn = Apollo.MutationFunction<CreateSummaryJobMutation, CreateSummaryJobMutationVariables>;

/**
 * __useCreateSummaryJobMutation__
 *
 * To run a mutation, you first call `useCreateSummaryJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSummaryJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSummaryJobMutation, { data, loading, error }] = useCreateSummaryJobMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      jobType: // value for 'jobType'
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useCreateSummaryJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateSummaryJobMutation, CreateSummaryJobMutationVariables>) {
        return Apollo.useMutation<CreateSummaryJobMutation, CreateSummaryJobMutationVariables>(CreateSummaryJobDocument, baseOptions);
      }
export type CreateSummaryJobMutationHookResult = ReturnType<typeof useCreateSummaryJobMutation>;
export type CreateSummaryJobMutationResult = Apollo.MutationResult<CreateSummaryJobMutation>;
export type CreateSummaryJobMutationOptions = Apollo.BaseMutationOptions<CreateSummaryJobMutation, CreateSummaryJobMutationVariables>;
export const DocumentsCreateFoldersDocument = gql`
    mutation DocumentsCreateFolders($treeId: ID!, $parentId: ID, $folderPaths: [String!]!) {
  createFolders(treeId: $treeId, parentId: $parentId, folderPaths: $folderPaths) {
    ...Document_breadcrumbs
    ...Document_siblings
  }
}
    ${Document_BreadcrumbsFragmentDoc}
${Document_SiblingsFragmentDoc}`;
export type DocumentsCreateFoldersMutationFn = Apollo.MutationFunction<DocumentsCreateFoldersMutation, DocumentsCreateFoldersMutationVariables>;

/**
 * __useDocumentsCreateFoldersMutation__
 *
 * To run a mutation, you first call `useDocumentsCreateFoldersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsCreateFoldersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsCreateFoldersMutation, { data, loading, error }] = useDocumentsCreateFoldersMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      parentId: // value for 'parentId'
 *      folderPaths: // value for 'folderPaths'
 *   },
 * });
 */
export function useDocumentsCreateFoldersMutation(baseOptions?: Apollo.MutationHookOptions<DocumentsCreateFoldersMutation, DocumentsCreateFoldersMutationVariables>) {
        return Apollo.useMutation<DocumentsCreateFoldersMutation, DocumentsCreateFoldersMutationVariables>(DocumentsCreateFoldersDocument, baseOptions);
      }
export type DocumentsCreateFoldersMutationHookResult = ReturnType<typeof useDocumentsCreateFoldersMutation>;
export type DocumentsCreateFoldersMutationResult = Apollo.MutationResult<DocumentsCreateFoldersMutation>;
export type DocumentsCreateFoldersMutationOptions = Apollo.BaseMutationOptions<DocumentsCreateFoldersMutation, DocumentsCreateFoldersMutationVariables>;
export const DocumentsPresignUploadsDocument = gql`
    mutation DocumentsPresignUploads($treeId: ID!, $files: [FileInput!]!) {
  createPresignedFiles(treeId: $treeId, files: $files) {
    url
    version
    file {
      ...Document_breadcrumbs
      ...Document_siblings
      parentId
    }
  }
}
    ${Document_BreadcrumbsFragmentDoc}
${Document_SiblingsFragmentDoc}`;
export type DocumentsPresignUploadsMutationFn = Apollo.MutationFunction<DocumentsPresignUploadsMutation, DocumentsPresignUploadsMutationVariables>;

/**
 * __useDocumentsPresignUploadsMutation__
 *
 * To run a mutation, you first call `useDocumentsPresignUploadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsPresignUploadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsPresignUploadsMutation, { data, loading, error }] = useDocumentsPresignUploadsMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useDocumentsPresignUploadsMutation(baseOptions?: Apollo.MutationHookOptions<DocumentsPresignUploadsMutation, DocumentsPresignUploadsMutationVariables>) {
        return Apollo.useMutation<DocumentsPresignUploadsMutation, DocumentsPresignUploadsMutationVariables>(DocumentsPresignUploadsDocument, baseOptions);
      }
export type DocumentsPresignUploadsMutationHookResult = ReturnType<typeof useDocumentsPresignUploadsMutation>;
export type DocumentsPresignUploadsMutationResult = Apollo.MutationResult<DocumentsPresignUploadsMutation>;
export type DocumentsPresignUploadsMutationOptions = Apollo.BaseMutationOptions<DocumentsPresignUploadsMutation, DocumentsPresignUploadsMutationVariables>;
export const DocumentsConfirmUploadsDocument = gql`
    mutation DocumentsConfirmUploads($treeId: ID!, $uploads: [ConfirmUploadInput!]!) {
  confirmUploads(treeId: $treeId, uploads: $uploads) {
    files {
      ...Document_breadcrumbs
      ...Document_siblings
      parentId
    }
    failedIds
  }
}
    ${Document_BreadcrumbsFragmentDoc}
${Document_SiblingsFragmentDoc}`;
export type DocumentsConfirmUploadsMutationFn = Apollo.MutationFunction<DocumentsConfirmUploadsMutation, DocumentsConfirmUploadsMutationVariables>;

/**
 * __useDocumentsConfirmUploadsMutation__
 *
 * To run a mutation, you first call `useDocumentsConfirmUploadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsConfirmUploadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsConfirmUploadsMutation, { data, loading, error }] = useDocumentsConfirmUploadsMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      uploads: // value for 'uploads'
 *   },
 * });
 */
export function useDocumentsConfirmUploadsMutation(baseOptions?: Apollo.MutationHookOptions<DocumentsConfirmUploadsMutation, DocumentsConfirmUploadsMutationVariables>) {
        return Apollo.useMutation<DocumentsConfirmUploadsMutation, DocumentsConfirmUploadsMutationVariables>(DocumentsConfirmUploadsDocument, baseOptions);
      }
export type DocumentsConfirmUploadsMutationHookResult = ReturnType<typeof useDocumentsConfirmUploadsMutation>;
export type DocumentsConfirmUploadsMutationResult = Apollo.MutationResult<DocumentsConfirmUploadsMutation>;
export type DocumentsConfirmUploadsMutationOptions = Apollo.BaseMutationOptions<DocumentsConfirmUploadsMutation, DocumentsConfirmUploadsMutationVariables>;
export const DocumentsMoveDocument = gql`
    mutation DocumentsMove($treeId: ID!, $sourceDocumentIds: [ID], $destinationParentId: ID!) {
  moveDocument(
    treeId: $treeId
    sourceDocumentIds: $sourceDocumentIds
    destinationParentId: $destinationParentId
  ) {
    parents {
      ...Document_breadcrumbs
      children {
        ...Document_breadcrumbs
      }
    }
  }
}
    ${Document_BreadcrumbsFragmentDoc}`;
export type DocumentsMoveMutationFn = Apollo.MutationFunction<DocumentsMoveMutation, DocumentsMoveMutationVariables>;

/**
 * __useDocumentsMoveMutation__
 *
 * To run a mutation, you first call `useDocumentsMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsMoveMutation, { data, loading, error }] = useDocumentsMoveMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      sourceDocumentIds: // value for 'sourceDocumentIds'
 *      destinationParentId: // value for 'destinationParentId'
 *   },
 * });
 */
export function useDocumentsMoveMutation(baseOptions?: Apollo.MutationHookOptions<DocumentsMoveMutation, DocumentsMoveMutationVariables>) {
        return Apollo.useMutation<DocumentsMoveMutation, DocumentsMoveMutationVariables>(DocumentsMoveDocument, baseOptions);
      }
export type DocumentsMoveMutationHookResult = ReturnType<typeof useDocumentsMoveMutation>;
export type DocumentsMoveMutationResult = Apollo.MutationResult<DocumentsMoveMutation>;
export type DocumentsMoveMutationOptions = Apollo.BaseMutationOptions<DocumentsMoveMutation, DocumentsMoveMutationVariables>;
export const DocumentsDownloadDocument = gql`
    mutation DocumentsDownload($treeId: ID!, $documentId: ID!) {
  downloadFile(treeId: $treeId, documentId: $documentId) {
    url
    name
  }
}
    `;
export type DocumentsDownloadMutationFn = Apollo.MutationFunction<DocumentsDownloadMutation, DocumentsDownloadMutationVariables>;

/**
 * __useDocumentsDownloadMutation__
 *
 * To run a mutation, you first call `useDocumentsDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsDownloadMutation, { data, loading, error }] = useDocumentsDownloadMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentsDownloadMutation(baseOptions?: Apollo.MutationHookOptions<DocumentsDownloadMutation, DocumentsDownloadMutationVariables>) {
        return Apollo.useMutation<DocumentsDownloadMutation, DocumentsDownloadMutationVariables>(DocumentsDownloadDocument, baseOptions);
      }
export type DocumentsDownloadMutationHookResult = ReturnType<typeof useDocumentsDownloadMutation>;
export type DocumentsDownloadMutationResult = Apollo.MutationResult<DocumentsDownloadMutation>;
export type DocumentsDownloadMutationOptions = Apollo.BaseMutationOptions<DocumentsDownloadMutation, DocumentsDownloadMutationVariables>;
export const DocumentsRenameDocument = gql`
    mutation DocumentsRename($treeId: ID!, $documentId: ID!, $name: String!) {
  renameDocument(treeId: $treeId, documentId: $documentId, name: $name) {
    error
    document {
      id
      name
      modifiedTimestamp
    }
  }
}
    `;
export type DocumentsRenameMutationFn = Apollo.MutationFunction<DocumentsRenameMutation, DocumentsRenameMutationVariables>;

/**
 * __useDocumentsRenameMutation__
 *
 * To run a mutation, you first call `useDocumentsRenameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsRenameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsRenameMutation, { data, loading, error }] = useDocumentsRenameMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      documentId: // value for 'documentId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDocumentsRenameMutation(baseOptions?: Apollo.MutationHookOptions<DocumentsRenameMutation, DocumentsRenameMutationVariables>) {
        return Apollo.useMutation<DocumentsRenameMutation, DocumentsRenameMutationVariables>(DocumentsRenameDocument, baseOptions);
      }
export type DocumentsRenameMutationHookResult = ReturnType<typeof useDocumentsRenameMutation>;
export type DocumentsRenameMutationResult = Apollo.MutationResult<DocumentsRenameMutation>;
export type DocumentsRenameMutationOptions = Apollo.BaseMutationOptions<DocumentsRenameMutation, DocumentsRenameMutationVariables>;
export const DocumentsRemoveDryRunDocument = gql`
    mutation DocumentsRemoveDryRun($treeId: ID!, $documentIds: [ID]) {
  removeDocumentsDryRun(treeId: $treeId, documentIds: $documentIds) {
    id
    fileType
    name
    parentId
  }
}
    `;
export type DocumentsRemoveDryRunMutationFn = Apollo.MutationFunction<DocumentsRemoveDryRunMutation, DocumentsRemoveDryRunMutationVariables>;

/**
 * __useDocumentsRemoveDryRunMutation__
 *
 * To run a mutation, you first call `useDocumentsRemoveDryRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsRemoveDryRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsRemoveDryRunMutation, { data, loading, error }] = useDocumentsRemoveDryRunMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useDocumentsRemoveDryRunMutation(baseOptions?: Apollo.MutationHookOptions<DocumentsRemoveDryRunMutation, DocumentsRemoveDryRunMutationVariables>) {
        return Apollo.useMutation<DocumentsRemoveDryRunMutation, DocumentsRemoveDryRunMutationVariables>(DocumentsRemoveDryRunDocument, baseOptions);
      }
export type DocumentsRemoveDryRunMutationHookResult = ReturnType<typeof useDocumentsRemoveDryRunMutation>;
export type DocumentsRemoveDryRunMutationResult = Apollo.MutationResult<DocumentsRemoveDryRunMutation>;
export type DocumentsRemoveDryRunMutationOptions = Apollo.BaseMutationOptions<DocumentsRemoveDryRunMutation, DocumentsRemoveDryRunMutationVariables>;
export const DocumentsRemoveDocument = gql`
    mutation DocumentsRemove($treeId: ID!, $documentIds: [ID]) {
  removeDocuments(treeId: $treeId, documentIds: $documentIds) {
    deletedIds
    parents {
      id
      children {
        id
      }
    }
  }
}
    `;
export type DocumentsRemoveMutationFn = Apollo.MutationFunction<DocumentsRemoveMutation, DocumentsRemoveMutationVariables>;

/**
 * __useDocumentsRemoveMutation__
 *
 * To run a mutation, you first call `useDocumentsRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsRemoveMutation, { data, loading, error }] = useDocumentsRemoveMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useDocumentsRemoveMutation(baseOptions?: Apollo.MutationHookOptions<DocumentsRemoveMutation, DocumentsRemoveMutationVariables>) {
        return Apollo.useMutation<DocumentsRemoveMutation, DocumentsRemoveMutationVariables>(DocumentsRemoveDocument, baseOptions);
      }
export type DocumentsRemoveMutationHookResult = ReturnType<typeof useDocumentsRemoveMutation>;
export type DocumentsRemoveMutationResult = Apollo.MutationResult<DocumentsRemoveMutation>;
export type DocumentsRemoveMutationOptions = Apollo.BaseMutationOptions<DocumentsRemoveMutation, DocumentsRemoveMutationVariables>;
export const DocumentsCreateFolderDocument = gql`
    mutation DocumentsCreateFolder($treeId: ID!, $parentId: ID, $name: String!) {
  createFolder(treeId: $treeId, parentId: $parentId, name: $name) {
    ...Document_info
    ...Document_siblings
  }
}
    ${Document_InfoFragmentDoc}
${Document_SiblingsFragmentDoc}`;
export type DocumentsCreateFolderMutationFn = Apollo.MutationFunction<DocumentsCreateFolderMutation, DocumentsCreateFolderMutationVariables>;

/**
 * __useDocumentsCreateFolderMutation__
 *
 * To run a mutation, you first call `useDocumentsCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsCreateFolderMutation, { data, loading, error }] = useDocumentsCreateFolderMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      parentId: // value for 'parentId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDocumentsCreateFolderMutation(baseOptions?: Apollo.MutationHookOptions<DocumentsCreateFolderMutation, DocumentsCreateFolderMutationVariables>) {
        return Apollo.useMutation<DocumentsCreateFolderMutation, DocumentsCreateFolderMutationVariables>(DocumentsCreateFolderDocument, baseOptions);
      }
export type DocumentsCreateFolderMutationHookResult = ReturnType<typeof useDocumentsCreateFolderMutation>;
export type DocumentsCreateFolderMutationResult = Apollo.MutationResult<DocumentsCreateFolderMutation>;
export type DocumentsCreateFolderMutationOptions = Apollo.BaseMutationOptions<DocumentsCreateFolderMutation, DocumentsCreateFolderMutationVariables>;
export const DocumentsUpdatePermissionsDocument = gql`
    mutation DocumentsUpdatePermissions($treeId: ID!, $documentPermissionId: ID!, $permissible: Boolean!) {
  updateDocumentPermission(
    treeId: $treeId
    documentPermissionId: $documentPermissionId
    permissible: $permissible
  ) {
    id
    permissible
  }
}
    `;
export type DocumentsUpdatePermissionsMutationFn = Apollo.MutationFunction<DocumentsUpdatePermissionsMutation, DocumentsUpdatePermissionsMutationVariables>;

/**
 * __useDocumentsUpdatePermissionsMutation__
 *
 * To run a mutation, you first call `useDocumentsUpdatePermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsUpdatePermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsUpdatePermissionsMutation, { data, loading, error }] = useDocumentsUpdatePermissionsMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      documentPermissionId: // value for 'documentPermissionId'
 *      permissible: // value for 'permissible'
 *   },
 * });
 */
export function useDocumentsUpdatePermissionsMutation(baseOptions?: Apollo.MutationHookOptions<DocumentsUpdatePermissionsMutation, DocumentsUpdatePermissionsMutationVariables>) {
        return Apollo.useMutation<DocumentsUpdatePermissionsMutation, DocumentsUpdatePermissionsMutationVariables>(DocumentsUpdatePermissionsDocument, baseOptions);
      }
export type DocumentsUpdatePermissionsMutationHookResult = ReturnType<typeof useDocumentsUpdatePermissionsMutation>;
export type DocumentsUpdatePermissionsMutationResult = Apollo.MutationResult<DocumentsUpdatePermissionsMutation>;
export type DocumentsUpdatePermissionsMutationOptions = Apollo.BaseMutationOptions<DocumentsUpdatePermissionsMutation, DocumentsUpdatePermissionsMutationVariables>;
export const DocumentsSubmitZipDownloadsDocument = gql`
    mutation DocumentsSubmitZipDownloads($treeId: ID!, $documentIds: [ID]) {
  submitZipDownload(treeId: $treeId, documentIds: $documentIds) {
    exportable {
      id
      name
      filePath
      fileType
      size
    }
    unexportable {
      id
      name
      filePath
      fileType
      size
    }
  }
}
    `;
export type DocumentsSubmitZipDownloadsMutationFn = Apollo.MutationFunction<DocumentsSubmitZipDownloadsMutation, DocumentsSubmitZipDownloadsMutationVariables>;

/**
 * __useDocumentsSubmitZipDownloadsMutation__
 *
 * To run a mutation, you first call `useDocumentsSubmitZipDownloadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsSubmitZipDownloadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsSubmitZipDownloadsMutation, { data, loading, error }] = useDocumentsSubmitZipDownloadsMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useDocumentsSubmitZipDownloadsMutation(baseOptions?: Apollo.MutationHookOptions<DocumentsSubmitZipDownloadsMutation, DocumentsSubmitZipDownloadsMutationVariables>) {
        return Apollo.useMutation<DocumentsSubmitZipDownloadsMutation, DocumentsSubmitZipDownloadsMutationVariables>(DocumentsSubmitZipDownloadsDocument, baseOptions);
      }
export type DocumentsSubmitZipDownloadsMutationHookResult = ReturnType<typeof useDocumentsSubmitZipDownloadsMutation>;
export type DocumentsSubmitZipDownloadsMutationResult = Apollo.MutationResult<DocumentsSubmitZipDownloadsMutation>;
export type DocumentsSubmitZipDownloadsMutationOptions = Apollo.BaseMutationOptions<DocumentsSubmitZipDownloadsMutation, DocumentsSubmitZipDownloadsMutationVariables>;