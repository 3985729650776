import { gql } from "@apollo/client";
import classnames from "classnames";
import React, { useContext } from "react";

import { SecondaryButton } from "~/components/common/buttons";
import { SkeletonText } from "~/components/common/skeletons";
import { EmptyRow } from "~/components/common/tables";
import {
  BarText,
  BoundedBox,
  ButtonRow,
  Fill,
  ProgressBar,
  StyledTable,
  TableTitle,
} from "~/components/DataroomAnalytics/CompanyTable";
import OrderingContext, {
  UserFields,
} from "~/components/DataroomAnalytics/context/OrderingContext";
import {
  UserAggsDataFragment,
  useTreeUserAnalyticsQuery,
} from "~/components/DataroomAnalytics/UserTable.generated";
import { Direction, Ordering, createOrdering, reverse } from "~/utils/ordering";

const maxColor = "#479DC6";
const color = "#6CC2D5";

export const USER_AGGS_DATA_FRAGMENT = gql`
  fragment UserAggsData on UserAggs {
    id
    user {
      fullName
    }
    company {
      name
    }
    sessionCount
    downloadCount
    viewCount
  }
`;

export const TREE_USER_ANALYTICS_QUERY = gql`
  query TreeUserAnalytics(
    $treeId: ID!
    $limit: Int
    $offset: Int
    $ordering: String
  ) {
    userAggs(
      treeId: $treeId
      limit: $limit
      offset: $offset
      ordering: $ordering
    ) {
      count
      maxSession
      maxDownload
      maxView
      users {
        ...UserAggsData
      }
    }
  }

  ${USER_AGGS_DATA_FRAGMENT}
`;

type Data = UserAggsDataFragment;

export interface UserTableProps {
  treeId: string;
}

const skeletonData = [1, 2, 3, 4, 5].map(
  (n) =>
    ({
      id: `skeleton-${n}`,
      user: null,
      company: null,
      sessionCount: null,
      downloadCount: null,
      viewCount: null,
    } as Data)
);

const column = (
  name: string,
  renderHeader: () => React.ReactNode,
  cell: (row: Data, index: number) => React.ReactNode,
  fillWidth: (row: Data) => number
) => ({ name, renderHeader, cell, fillWidth });

export interface HeaderCellProps {
  name: UserFields;
  key: string;
  ordering: Ordering<UserFields> | null;
  toggleSorting: (name: UserFields) => void;
  children?: any;
  style?: React.CSSProperties;
  arrowLeft?: boolean;
}

export const HeaderCell = ({
  name,
  key,
  ordering,
  toggleSorting,
  children,
  style = { textAlign: "left" },
  arrowLeft = false,
}: HeaderCellProps) => (
  <th
    key={key}
    scope="col"
    style={style}
    className={classnames(
      ordering && ordering.field.startsWith(name) && "active"
    )}
  >
    <button
      className={classnames(
        "btn",
        ordering && ordering.field && "btn-link",
        ordering && ordering.field && "btn-link-hovered"
      )}
      style={{ padding: 0, textAlign: style.textAlign }}
      type="button"
      onClick={() => toggleSorting(name)}
    >
      {!arrowLeft ? children : null}
      {ordering && ordering.field && (
        <i
          className={classnames(
            "icon",
            "icon--circle-sort-up",
            ordering.field.startsWith(name) && "active",
            ordering.field.startsWith(name) &&
              ordering.direction === Direction.ASC &&
              "asc",
            ordering.field.startsWith(name) &&
              ordering.direction === Direction.DESC &&
              "desc"
          )}
        />
      )}
      {arrowLeft ? children : null}
    </button>
  </th>
);

export const UserTable = React.memo(function UserTable({
  treeId,
}: UserTableProps) {
  const {
    usersOrdering: ordering,
    setUsersOrdering: setOrdering,
    serializeOrdering,
  } = useContext(OrderingContext);
  const toggleSorting = (field: UserFields) => {
    if (ordering.field === field) {
      setOrdering(reverse(ordering));
    } else {
      setOrdering(createOrdering(field));
    }
  };
  const {
    loading,
    data: requestData,
    fetchMore,
    error,
  } = useTreeUserAnalyticsQuery({
    variables: {
      treeId: treeId,
      ordering: serializeOrdering(ordering),
    },
  });
  const data = requestData?.userAggs?.users;

  const onLoadMore = () => {
    fetchMore({
      variables: {
        offset: data?.length,
      },
    });
  };
  const empty = !loading && data?.length === 0;
  const rows = data ?? skeletonData;
  const showLoadMore = !!(
    requestData?.userAggs?.count &&
    data?.length &&
    data?.length < requestData.userAggs.count
  );
  const columns = [
    column(
      "user-name",
      () => (
        <th key="user-name" className="name-cell">
          <div className="Name">Name</div>
        </th>
      ),
      (row, index) => {
        if (row.id.startsWith("skeleton")) return <SkeletonText />;
        return `${index + 1}. ${row.user?.fullName ?? "--"}`;
      },
      () => 0
    ),
    column(
      "company-name",
      () => (
        <th key="company-name" className="name-cell">
          <div className="Name">Company</div>
        </th>
      ),
      (row) => {
        if (row.id.startsWith("skeleton")) return <SkeletonText />;
        return `${row.company?.name ?? "--"}`;
      },
      () => 0
    ),
    column(
      "sessionCount",
      () => (
        <HeaderCell
          key="sessionCount"
          name={UserFields.SESSIONS}
          ordering={ordering}
          toggleSorting={toggleSorting}
        >
          Sessions
        </HeaderCell>
      ),
      (row) => {
        if (row.id.startsWith("skeleton")) return <SkeletonText />;
        return `${row.sessionCount}`;
      },
      (row) =>
        requestData?.userAggs?.maxSession
          ? Math.max(
              Math.floor(
                ((row.sessionCount ?? 0) / requestData.userAggs.maxSession) *
                  100
              ),
              1
            )
          : 0
    ),
    column(
      "downloadCount",
      () => (
        <HeaderCell
          key="downloadCount"
          name={UserFields.DOWNLOADS}
          ordering={ordering}
          toggleSorting={toggleSorting}
        >
          Downloads
        </HeaderCell>
      ),
      (row) => {
        if (row.id.startsWith("skeleton")) return <SkeletonText />;
        return `${row.downloadCount}`;
      },
      (row) =>
        requestData?.userAggs?.maxDownload
          ? Math.max(
              Math.floor(
                ((row.downloadCount ?? 0) / requestData.userAggs.maxDownload) *
                  100
              ),
              1
            )
          : 0
    ),
  ];

  const table = (
    <StyledTable>
      <thead>
        <tr>{columns.map(({ renderHeader }) => renderHeader())}</tr>
      </thead>
      <tbody>
        {!error &&
          rows.map((row, index) => (
            <tr key={row?.id}>
              {columns.map(({ name, cell, fillWidth }) => {
                if (name.endsWith("name"))
                  return (
                    <td key={name} className={`${name}-cell`}>
                      <span>{cell(row, index)}</span>
                    </td>
                  );
                const width = fillWidth(row);
                return (
                  <td key={name} className={`${name}-cell`}>
                    <div className={`bar-cell`}>
                      <BarText>{cell(row, index)}</BarText>
                      <ProgressBar>
                        <Fill
                          width={width}
                          color={width === 100 ? maxColor : color}
                        />
                      </ProgressBar>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
      </tbody>
    </StyledTable>
  );

  return (
    <BoundedBox>
      <TableTitle>Users</TableTitle>
      {table}
      {showLoadMore && (
        <ButtonRow>
          <SecondaryButton onClick={onLoadMore}>Load more</SecondaryButton>
        </ButtonRow>
      )}
      {!error && empty && <EmptyRow>No data found.</EmptyRow>}
      {error && (
        <EmptyRow>
          An error occured while loading the data. Please check again later.
        </EmptyRow>
      )}
    </BoundedBox>
  );
});
