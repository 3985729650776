import { gql } from "@apollo/client";
import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink, useLocation } from "react-router-dom";

import { PermissionType } from "~/api/types.generated";
import { useEventTracking } from "~/event-tracking/EventTrackingProvider";
import { NavbarViewerFragment } from "~/Navbar.generated";
import useViewerPermissions from "~/permissions/useViewerPermissions";

export const ORG_FEATURE_FLAG = gql`
  fragment FeatureFlag on OrgFeatureFlags {
    documentLabels
    copilot
  }
`;

export const USER_ORG_FRAGMENT = gql`
  fragment OrgFragment on Organization {
    id
    name
    slug
    permissions
    featureFlags {
      ...FeatureFlag
    }
  }
  ${ORG_FEATURE_FLAG}
`;

export const NAVBAR_VIEWER_FRAGMENT = gql`
  fragment NavbarViewer on User {
    id
    firstName
    email
    avatarUrl
    hasSalesforceToken
    isSuperUser
    organizations {
      ...OrgFragment
    }
    permissions {
      name
      holdingCompanyIds
      portfolioIds
    }
  }
  ${USER_ORG_FRAGMENT}
`;

export interface NavbarProps {
  onClickWelcome: () => void;
  viewer: NavbarViewerFragment;
}

export const Navbar = ({ onClickWelcome, viewer }: NavbarProps) => {
  const track = useEventTracking("Nav", {
    logo: "Clicked Logo",
    home: "Clicked Home",
    dealRoom: "Clicked Deal Room",
    projects: ["Clicked Projects", (orgSlug) => ({ "Org Slug": orgSlug })],
    reporting: ["Clicked Reporting", (orgSlug) => ({ "Org Slug": orgSlug })],
    oAndM: ["Clicked O&M", (orgSlug) => ({ "Org Slug": orgSlug })],
  });
  // Determine which navbar tabs the user should see
  const permissions = useViewerPermissions(viewer);
  const env = DJ_CONST.ENVIRONMENT_TAG || "live";
  const userName = viewer.firstName || viewer.email;
  const isSuperUser = viewer.isSuperUser;
  // We will hide the home tab if the user has deal room but not reporting (user will stay in the deal room)
  // Some users do not have either permission - e.g. Sellers that see a single portfolio as their "Home"
  const orgHideHome = viewer.organizations?.some(
    (org) =>
      org.permissions.includes(PermissionType.DealroomView) &&
      !org.permissions.includes(PermissionType.ReportsView)
  );
  const orgShowHome = !orgHideHome;
  const showDealRoom = Object.entries(permissions.byOrg).some(([_, perms]) =>
    perms.includes(PermissionType.DealroomView)
  );
  const orgAdminPages = viewer.organizations?.filter(
    (org) =>
      org.permissions.includes(PermissionType.OrgAdmin) ||
      org.permissions.includes(PermissionType.UsersAdmin)
  );
  const orgReporting = viewer.organizations?.filter((org) =>
    org.permissions.includes(PermissionType.ReportsView)
  );
  // The top-level navbar Projects tab requires both "projects:view" and "reports:view" permissions.
  const orgProjects = viewer.organizations?.filter(
    (org) =>
      org.permissions.includes(PermissionType.ReportsView) &&
      org.permissions.includes(PermissionType.ProjectsView)
  );
  const orgOAndMs = viewer.organizations?.filter((org) =>
    org.permissions.includes(PermissionType.OAndMView)
  );
  const location = useLocation();

  return (
    <header className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <a className="navbar-brand" href="/" onClick={track.logo}>
          Dock
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#headerNavigation"
          aria-controls="headerNavigation"
          aria-expanded="false"
        >
          <i className="icon icon--nav"></i>
        </button>

        <div className="collapse navbar-collapse" id="headerNavigation">
          {env !== "live" && (
            <div className="nav-env-label">{env.toUpperCase()}</div>
          )}
          <Nav className="navbar-nav mr-auto" as="ul">
            {orgShowHome && (
              <Nav.Item className="nav-item" as="li">
                <NavLink
                  to="/"
                  exact
                  className="nav-link home"
                  onClick={track.home}
                >
                  Home
                </NavLink>
              </Nav.Item>
            )}
            {orgProjects && orgProjects.length === 1 && (
              <Nav.Item className="nav-item" as="li">
                <NavLink
                  to={`/${orgProjects[0].slug}/projects`}
                  className="nav-link projects"
                  onClick={() => track.projects(orgProjects[0].slug)}
                >
                  Projects
                </NavLink>
              </Nav.Item>
            )}
            {orgProjects && orgProjects.length > 1 && (
              <Nav.Item className="nav-item" as="li">
                <NavDropdown
                  id="projects-nav"
                  active={
                    location.pathname.includes("/projects/") &&
                    !location.pathname.startsWith("/portfolios")
                  }
                  title="Projects"
                >
                  {orgProjects.map((thing) => (
                    <NavDropdown.Item
                      key={`${thing.slug}-projects`}
                      className="nav-dropdown-item projects"
                      href={`/${thing.slug}/projects`}
                      onClick={() => track.projects(thing.slug)}
                    >
                      {`Projects: ${thing.name}`}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Nav.Item>
            )}
            {orgReporting && orgReporting.length === 1 && (
              <Nav.Item className="nav-item" as="li">
                <NavLink
                  to={`/${orgReporting[0].slug}/reporting`}
                  className="nav-link reporting"
                  onClick={() => track.reporting(orgReporting[0].slug)}
                >
                  Reporting
                </NavLink>
              </Nav.Item>
            )}
            {orgReporting && orgReporting.length > 1 && (
              <Nav.Item className="nav-item" as="li">
                <NavDropdown
                  id="reporting-nav"
                  active={location.pathname.includes("/reporting/")}
                  title="Reporting"
                >
                  {orgReporting.map((thing) => (
                    <NavDropdown.Item
                      key={`${thing.slug}-reporting`}
                      className="nav-dropdown-item reporting"
                      href={`/${thing.slug}/reporting`}
                      onClick={() => track.reporting(thing.slug)}
                    >
                      {`Reporting: ${thing.name}`}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Nav.Item>
            )}
            {showDealRoom && (
              <Nav.Item className="nav-item" as="li">
                <NavLink
                  to="/dealroom"
                  className="nav-link dealroom"
                  onClick={track.dealRoom}
                >
                  Deal Room
                </NavLink>
              </Nav.Item>
            )}
            {orgOAndMs && orgOAndMs.length === 1 && (
              <Nav.Item className="nav-item" as="li">
                <NavLink
                  to={`/${orgOAndMs[0].slug}/o-and-m`}
                  className="nav-link oandm"
                  onClick={() => track.oAndM(orgOAndMs[0].slug)}
                >
                  O&M
                </NavLink>
              </Nav.Item>
            )}
            {orgOAndMs && orgOAndMs.length > 1 && (
              <Nav.Item className="nav-item" as="li">
                <NavDropdown
                  id="oandm-nav"
                  title="O&M"
                  active={location.pathname.includes("/o-and-m/")}
                >
                  {orgOAndMs.map((thing) => (
                    <NavDropdown.Item
                      key={`${thing.slug}-oandm`}
                      className="nav-dropdown-item oandm"
                      href={`/${thing.slug}/o-and-m`}
                      onClick={() => track.oAndM(thing.slug)}
                    >
                      {`O&M: ${thing.name}`}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Nav.Item>
            )}
            {orgAdminPages && orgAdminPages.length === 1 && (
              <Nav.Item className="nav-item" as="li">
                <NavLink
                  to={`/${orgAdminPages[0].slug}/admin`}
                  className="nav-link"
                >
                  Admin
                </NavLink>
              </Nav.Item>
            )}
            {orgAdminPages && orgAdminPages.length > 1 && (
              <Nav.Item className="nav-item" as="li">
                <NavDropdown
                  id="oandm-nav"
                  title="Admin"
                  active={location.pathname.includes("/admin/")}
                >
                  {orgAdminPages.map((org) => (
                    <NavDropdown.Item
                      key={`${org.slug}-admin`}
                      href={`/${org.slug}/admin`}
                      className="nav-dropdown-item"
                    >
                      {`Admin: ${org.name}`}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Nav.Item>
            )}
          </Nav>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <div className="dropdown">
                <button
                  className="nav-link nav-link-user"
                  id="dropdownUserMenu"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="nav-user clearfix">
                    <span className="nav-username">{userName}</span>
                    <span
                      className="nav-avatar"
                      style={{
                        backgroundImage: `url('${
                          viewer.avatarUrl ?? DJ_CONST.user.default_avatar_url
                        }')`,
                      }}
                    />
                  </span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownUserMenu"
                >
                  <button
                    className="dropdown-item icon icon--info"
                    onClick={onClickWelcome}
                  >
                    Welcome
                  </button>
                  <a className="dropdown-item icon icon--gear" href="/profile">
                    Settings
                  </a>
                  {isSuperUser && (
                    <a
                      className="dropdown-item icon icon--wrench"
                      href="/tagauks"
                    >
                      Dock Admin
                    </a>
                  )}
                  <a className="dropdown-item icon icon--logout" href="/logout">
                    Log out
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};
