import { AlertIcon, ClockIcon, OkIcon } from "components/icons";
import * as PropTypes from "prop-types";
import React from "react";

import {
  statusIsAttentionNeeded,
  statusIsDone,
  statusIsInProgress,
} from "~/components/Checklist/context/ChecklistContext";

const getStatusIcon = (status) => {
  if (statusIsDone(status)) {
    return OkIcon;
  } else if (statusIsInProgress(status)) {
    return ClockIcon;
  } else if (statusIsAttentionNeeded(status)) {
    return AlertIcon;
  } else {
    return null;
  }
};

class StatusIcon extends React.PureComponent {
  render() {
    const Icon = getStatusIcon(this.props.status);

    if (Icon) {
      return <Icon className={this.props.className} />;
    } else {
      return null;
    }
  }
}

StatusIcon.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
};

StatusIcon.defaultProps = {
  status: null,
  className: null,
};

export default StatusIcon;
