import { compact } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { UpdateMilestonePayload } from "~/api/types.generated";
import { PrimaryButton } from "~/components/common/buttons";
import { Text } from "~/components/common/text";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { Milestone } from "~/components/Timeline/Milestone";
import { MilestoneEditor } from "~/components/Timeline/MilestoneEditor";
import { RemoveMilestoneDialog } from "~/components/Timeline/RemoveMilestoneDialog";
import {
  useCreateMilestoneMutation,
  useDeleteMilestoneMutation,
  useReorderMilestoneMutation,
  useTimelineQuery,
  useUpdateMilestoneMutation,
} from "~/components/Timeline/TimelineAPI.generated";
import { useTimelineEventTracking } from "~/components/Timeline/useTimelineEventTracking";
import { orange, white, yellow } from "~/styles/theme/color";

export interface TimelineProps {
  id: string;
}

export const Timeline = ({ id }: TimelineProps) => {
  const { data, loading } = useTimelineQuery({
    variables: { timelineId: id },
  });

  const timeline = data?.timeline;
  const track = useTimelineEventTracking(timeline);

  useEffect(() => {
    if (timeline) track.view();
  }, [track, timeline]);

  const [reorder, { loading: isReordering }] = useReorderMilestoneMutation();
  const [update, { loading: isUpdating }] = useUpdateMilestoneMutation();
  const [remove, { loading: isRemoving }] = useDeleteMilestoneMutation();
  const [create, { loading: isCreating }] = useCreateMilestoneMutation();
  const isLoading = isCreating || isRemoving || isUpdating || isReordering;

  const [editId, setEditId] = useState<string | null>(null);
  const [removeId, setRemoveId] = useState<string | null>(null);

  if (loading) {
    return (
      <Text as="div">
        <LoadingSpinner /> Loading Timeline...
      </Text>
    );
  }

  const milestones = compact(data?.timeline?.milestones ?? []);
  const canEdit = editId === null && (data?.timeline?.canEdit ?? false);
  const canRemove = milestones.length > 1;

  const milestoneItems = milestones.map((milestone, index) => {
    const milestoneId = milestone.id;
    const isEditing = editId === milestone.id;
    const isFirst = index === 0;
    const isLast = index === milestones.length - 1;

    const handleEdit = () => {
      track.editClick(milestone);
      setEditId(milestoneId);
    };
    const handleStageRemove = () => {
      track.deleteClick(milestone);
      setRemoveId(milestoneId);
    };
    const handleSave = async (payload: UpdateMilestonePayload) => {
      track.editConfirm({ id: payload.milestoneId, title: payload.title });
      await update({ variables: { payload } });
      setEditId(null);
    };
    const handleCancel = () => {
      track.editCancel(milestone);
      setEditId(null);
    };
    const handleMoveUp = () => {
      track.reorderClick(milestone);
      reorder({
        variables: { payload: { milestoneId, up: true } },
      });
    };
    const handleMoveDown = () => {
      track.reorderClick(milestone);
      reorder({
        variables: { payload: { milestoneId, up: false } },
      });
    };

    return isEditing ? (
      <MilestoneEditor
        key={milestone.id}
        milestone={milestone}
        isFirst={isFirst}
        isLast={isLast}
        isMoving={isReordering}
        isSaving={isUpdating}
        onSave={handleSave}
        onCancel={handleCancel}
        onMoveUp={handleMoveUp}
        onMoveDown={handleMoveDown}
      />
    ) : (
      <Milestone
        key={milestone.id}
        milestone={milestone}
        isLast={isLast}
        isLoading={isLoading}
        canEdit={canEdit}
        canRemove={canRemove}
        onEdit={handleEdit}
        onRemove={handleStageRemove}
      />
    );
  });

  const handleCreate = async () => {
    if (data?.timeline) {
      const result = await create({
        variables: { payload: { timelineId: data.timeline.id } },
      });
      const newMilestones = compact(
        result.data?.createMilestone?.timeline?.milestones ?? []
      );
      const newMilestone = newMilestones[newMilestones.length - 1];
      track.createClick(newMilestone);
      setEditId(newMilestone.id);
    }
  };

  const newMilestoneButton = canEdit && (
    <NewMilestoneButton onClick={handleCreate} disabled={isLoading}>
      {isCreating && <LoadingSpinner color="light" />}
      <span>New Milestone</span>
    </NewMilestoneButton>
  );

  const removeMilestone =
    removeId === null ? null : milestones.find((m) => m.id === removeId);

  const handleConfirmRemove = async () => {
    if (!removeMilestone) return;
    track.deleteConfirm(removeMilestone);
    try {
      await remove({
        variables: { payload: { milestoneId: removeMilestone.id } },
      });
    } finally {
      setRemoveId(null);
    }
  };

  const handleCancelRemove = async () => {
    if (!removeMilestone) return;
    track.deleteCancel(removeMilestone);
    setRemoveId(null);
  };

  const removeMilestoneDialog = (
    <RemoveMilestoneDialog
      name={removeMilestone?.title}
      isOpen={removeId !== null}
      isRemoving={isRemoving}
      onConfirm={handleConfirmRemove}
      onCancel={handleCancelRemove}
    />
  );

  return (
    <>
      {milestoneItems}
      {newMilestoneButton}
      {removeMilestoneDialog}
    </>
  );
};

const NewMilestoneButton = styled(PrimaryButton)`
  background-color: ${yellow};
  color: ${white};
  border: 1px solid ${yellow};
  margin-bottom: 0.5rem;

  &.active,
  &:hover {
    background-color: ${orange};
    color: ${white};
  }
`;
