// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { DealRoomBannerFragment } from './DealRoomBanner.generated';
import { DealRoomGridFragment } from './DealRoomGrid.generated';
import { gql } from '@apollo/client';
import { DealRoomBannerFragmentDoc } from './DealRoomBanner.generated';
import { DealRoomGridFragmentDoc } from './DealRoomGrid.generated';
import * as Apollo from '@apollo/client';
export type DealRoomQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DealRoomQuery = (
  { readonly __typename: 'Query' }
  & { readonly diligence?: Types.Maybe<(
    { readonly __typename: 'FundEntityCollection' }
    & DealRoomGridFragment
  )>, readonly acquired?: Types.Maybe<(
    { readonly __typename: 'FundEntityCollection' }
    & DealRoomGridFragment
  )>, readonly market?: Types.Maybe<(
    { readonly __typename: 'FundEntityCollection' }
    & DealRoomGridFragment
  )> }
  & DealRoomBannerFragment
);


export const DealRoomDocument = gql`
    query DealRoom {
  ...DealRoomBanner
  diligence: fundEntities(category: DILIGENCE) {
    ...DealRoomGrid
  }
  acquired: fundEntities(category: ACQUIRED) {
    ...DealRoomGrid
  }
  market: fundEntities(category: MARKET) {
    ...DealRoomGrid
  }
}
    ${DealRoomBannerFragmentDoc}
${DealRoomGridFragmentDoc}`;

/**
 * __useDealRoomQuery__
 *
 * To run a query within a React component, call `useDealRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealRoomQuery({
 *   variables: {
 *   },
 * });
 */
export function useDealRoomQuery(baseOptions?: Apollo.QueryHookOptions<DealRoomQuery, DealRoomQueryVariables>) {
        return Apollo.useQuery<DealRoomQuery, DealRoomQueryVariables>(DealRoomDocument, baseOptions);
      }
export function useDealRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealRoomQuery, DealRoomQueryVariables>) {
          return Apollo.useLazyQuery<DealRoomQuery, DealRoomQueryVariables>(DealRoomDocument, baseOptions);
        }
export type DealRoomQueryHookResult = ReturnType<typeof useDealRoomQuery>;
export type DealRoomLazyQueryHookResult = ReturnType<typeof useDealRoomLazyQuery>;
export type DealRoomQueryResult = Apollo.QueryResult<DealRoomQuery, DealRoomQueryVariables>;