// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type DuplicateReportFragment = (
  { readonly __typename: 'SalesforceDuplicateReport' }
  & Pick<Types.SalesforceDuplicateReport, 'id' | 'status' | 'fileUrl' | 'fileName' | 'createdBy' | 'createdAt'>
);

export const DuplicateReportFragmentDoc = gql`
    fragment DuplicateReport on SalesforceDuplicateReport {
  id
  status
  fileUrl
  fileName
  createdBy
  createdAt
}
    `;