import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

const Alert = ({ message }) => (
  <div className="alert alert-info alert-dismissible fade show" role="alert">
    <button
      type="button"
      className="close"
      data-dismiss="alert"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    {message}
  </div>
);

Alert.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Alert;

/** An alert box that is shown again when the message is changed, even after dismissal
 * (the Alert component will not be shown again once dismissed)
 */
export const BetterAlert = ({ message, classNames }) => {
  const [shown, setShown] = useState(false);
  const [dismissed, setDismissed] = useState(false);

  // track the message prop
  useEffect(() => {
    setDismissed(false);
    setShown(message !== "");
  }, [message]);

  const dismiss = useCallback(() => {
    setDismissed(true);
    setShown(false);
  }, []);

  if (dismissed || !shown) {
    return null;
  }
  return (
    <div
      className={`alert alert-info fade show ${classNames.join(" ")}`}
      role="alert"
      title={message}
    >
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true" onClick={dismiss}>
          &times;
        </span>
      </button>
      {message}
    </div>
  );
};

BetterAlert.propTypes = {
  message: PropTypes.string.isRequired,
  classNames: PropTypes.array,
};

BetterAlert.defaultProps = {
  classNames: [],
};
