import { FILE_TYPE_DIRECTORY, FILE_TYPE_FILE } from "constants/portfolios";
import * as PropTypes from "prop-types";

export const categoryPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  paragraph: PropTypes.arrayOf(PropTypes.number).isRequired,
  parent: PropTypes.string,
});

export const projectPropType = PropTypes.shape({
  id: PropTypes.oneOfType([
    // we have mixed sources for models
    PropTypes.string, // SalesForce import
    PropTypes.number, // Local project model
  ]).isRequired,
  name: PropTypes.string.isRequired,
  first_commercial_online_date: PropTypes.string, // date
  nameplate_capacity: PropTypes.number,
  offtaker_name: PropTypes.string,
  plant_city: PropTypes.string,
  plant_state: PropTypes.string,
  plant_zip_code: PropTypes.string,
});

export const geoJsonType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      properties: PropTypes.shape({
        name: PropTypes.string,
        size: PropTypes.number,
      }),
      geometry: PropTypes.shape({
        type: PropTypes.string.isRequired,
        coordinates: PropTypes.arrayOf(PropTypes.string.isRequired),
      }),
    })
  ),
});

export const holdingCompanyType = PropTypes.shape({
  slug: PropTypes.string.isRequired,
});

export const subpagePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  description_markdownified: PropTypes.string.isRequired,
});

export const changeLogType = PropTypes.shape({
  arrival: PropTypes.string, // date
  change: PropTypes.string,
  id: PropTypes.string.isRequired,
  model: PropTypes.string,
  model_id: PropTypes.number,
  modified: PropTypes.string.isRequired, // date
  path: PropTypes.string.isRequired,
  size: PropTypes.number,
});

/**
 * @typedef BreadcrumbType
 *
 * @type {object}
 * @property {string} name
 * @property {string} shortlink
 *
 * @typedef EntryType
 *
 * @type {object}
 * @property {string} id
 * @property {string} name
 * @property {string} shortlink
 * @property {string} entry_model
 * @property {string} type
 * @property {Array<BreadcrumbType>} breadcrumbs
 */

export const entryType = PropTypes.shape({
  id: PropTypes.string,
  spe_id: PropTypes.number,
  created_date: PropTypes.string,
  modified: PropTypes.string,
  name: PropTypes.string,
  parent: PropTypes.string,
  path: PropTypes.string,
  path_display: PropTypes.string,
  root: PropTypes.bool,
  shortlink: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      shortlink: PropTypes.string,
    })
  ),
  entry_model: PropTypes.string,
  type: PropTypes.oneOf([
    FILE_TYPE_DIRECTORY,
    FILE_TYPE_FILE,
    "directory",
    "file",
  ]),
  roles: PropTypes.arrayOf(PropTypes.string),
});

export const spePropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  file_storage: PropTypes.number.isRequired,
  dynamic_system_size: PropTypes.number,
  dynamic_number_of_projects: PropTypes.number,
  average_offtaker_quality: PropTypes.string,
  dynamic_remaining_ppa_term: PropTypes.number.isRequired,
  holding_company: holdingCompanyType,
  admin: PropTypes.string,
  project_import: PropTypes.string,
  subpages: PropTypes.arrayOf(subpagePropType),
  folder_entries: PropTypes.arrayOf(entryType),
  s3_path: PropTypes.string,
  has_checklist: PropTypes.bool,
  enable_reports: PropTypes.bool,
  enable_projects: PropTypes.bool,
  shortlink: PropTypes.string,
  reports_shortlink: PropTypes.string,
});

// Same as spePropType, but when we need/have fewer fields
export const speReducedType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  dynamic_system_size: PropTypes.number,
  dynamic_number_of_projects: PropTypes.number,
  dynamic_remaining_ppa_term: PropTypes.string,
  deal_manager: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  cover_image: PropTypes.string,
});

export const S3DocumentsServerErrorsType = PropTypes.shape({
  non_field_errors: PropTypes.arrayOf(PropTypes.string.isRequired),
  name: PropTypes.arrayOf(PropTypes.string.isRequired),
});

export const fileActivityType = PropTypes.shape({
  id: PropTypes.number,
  activity_type: PropTypes.string,
  user: PropTypes.shape({
    fullname: PropTypes.string,
  }),
  user_groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  user_role: PropTypes.string,
  entry: entryType,
  recorded_file_name: PropTypes.string,
  portfolio: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    s3_path: PropTypes.string,
  }),
  created_at: PropTypes.string,
  has_active_entry: PropTypes.bool,
});
