import classNames from "classnames";
import * as PropTypes from "prop-types";
import React from "react";

const Avatar = ({ image, className }) => (
  <span
    className={classNames("avatar", className)}
    style={image && { backgroundImage: `url('${image}')` }}
  />
);

Avatar.propTypes = {
  image: PropTypes.string,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  image: null,
  className: null,
};

export default Avatar;
