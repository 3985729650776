import * as PropTypes from "prop-types";
import React from "react";

import { CommentsForm } from "./CommentsForm";
import { CommentsList } from "./CommentsList";

const initializeWebSocket = (portfolioId, checklistId) => {
  const socketProtocol =
    window.location.protocol === "https:" ? "wss://" : "ws://";
  const socketUrl = `${socketProtocol}${window.location.host}/ws/comments/${portfolioId}/${checklistId}/`;

  return new WebSocket(socketUrl);
};

class Comments extends React.Component {
  static propTypes = {
    portfolioId: PropTypes.string.isRequired,
    setNrOfComments: PropTypes.func.isRequired,
    selectedItems: PropTypes.array,
    checklistId: PropTypes.string,
  };

  static defaultProps = {
    selectedItems: [],
    checklistId: null,
  };

  state = {
    sentMultiple: false,
    comments: {},
  };

  componentDidMount() {
    this.handleWebSockets();
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    // we should reset sentMultiple if selectedItems were changed to hide latest comment
    if (
      this.state.sentMultiple &&
      this.props.selectedItems.length !== nextProps.selectedItems.length
    ) {
      this.setState({ sentMultiple: false });
    }
  }

  componentWillUnmount() {
    if (this.commentsSocket) {
      this.commentsSocket.close(1000);
      this.commentsSocket = undefined;
    }
  }

  submitComment = (commentMessage) => {
    this.commentsSocket.send(
      JSON.stringify({
        comment: commentMessage,
        checklistitems: this.props.selectedItems.map((x) => x.id),
      })
    );
    this.setState({ sentMultiple: this.props.selectedItems.length > 1 });
  };

  handleChecklistCommentsCount = (comments) => {
    // add comments icon if the first comment was added to the empty checklist comments
    Object.values(comments)
      .filter((comment) => comment.length === 1)
      .forEach((comment) => {
        // noinspection JSUnresolvedVariable
        this.props.setNrOfComments(comment[0].checklistitem_id, 1);
      });
  };

  handleWebSockets() {
    if (!this.commentsSocket) {
      this.commentsSocket = initializeWebSocket(
        this.props.portfolioId,
        this.props.checklistId
      );

      this.commentsSocket.onmessage = (e) => {
        if (this.commentsSocket === undefined) {
          // Socket is closed! do nothing
          console.error("Socket is closed: ", e);
          return;
        }
        const jsonData = JSON.parse(e.data);
        const { comments } = jsonData;

        this.handleChecklistCommentsCount(comments);

        this.setState((prevState) => ({
          // creating a new object for detecting changes in comments list (otherwise useEffect doesn't fire)
          comments: { ...prevState.comments, ...comments },
        }));
      };

      this.commentsSocket.close = (code) => {
        if (code !== 1000) {
          console.error("Comments socket closed unexpectedly", code);
          this.handleWebSockets();
        }
      };
    }
  }

  render = () => (
    <>
      <CommentsList
        selectedItems={this.props.selectedItems}
        comments={this.state.comments}
        sentMultiple={this.state.sentMultiple}
      />
      <CommentsForm
        cellsSelected={this.props.selectedItems.length}
        submitComment={this.submitComment}
      />
    </>
  );
}

export default Comments;
