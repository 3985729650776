import gql from "graphql-tag";
import React from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import {
  DevelopmentStage,
  InstallationType,
  MountingType,
  PermissionType,
  ProjectStatus,
  ProjectType,
} from "~/api/types.generated";
import { useProjectFormQuery } from "~/components/Projects/EditProjectPage.generated";
import ProjectForms, {
  ProjectValues,
} from "~/components/Projects/ProjectForms";
import { useUpdateProjectMutation } from "~/components/Projects/ProjectsAPI.generated";
import { NotFound } from "~/pages/NotFound";
import { OrgPermission } from "~/permissions/useViewerPermissions";

const PROJECT_LOCATION_FRAGMENT = gql`
  fragment Location on ProjectLocation {
    address
    city
    state
    zipCode
    elevation
    latitude
    longitude
  }
`;

const PROJECT_FORM_FRAGMENT = gql`
  fragment ProjectForm on Project {
    id
    name
    status
    type
    developmentStage
    isOwned
    noticeToProceed
    commercialOperatingDate
    permissionToOperateDate
    acquisitionDate
    capacity
    capacityAc
    location {
      ...Location
    }
    system {
      bessKw
      bessKwh
      panelWarranty
      inverterWarranty
      contractorWarranty
      installation
      mounting
    }
    contact {
      phoneNumber
      balancingAuthorityAreaName
    }
    ppaContracts {
      ...ProjectPPA
    }
    panels {
      ...ProjectPanels
    }
    inverters {
      ...ProjectInverters
    }
    offtakers {
      ...ProjectOfftaker
    }
    portfolios {
      ...ProjectPortfolio
    }
  }

  fragment ProjectPPA on PPAContract {
    startDate
    term
    rate
    escalator
  }

  fragment ProjectPanels on Panels {
    modelId
    count
  }

  fragment ProjectInverters on Inverters {
    modelId
    count
  }

  fragment ProjectOfftaker on Offtaker {
    id
  }

  fragment ProjectPortfolio on Portfolio {
    id
  }

  ${PROJECT_LOCATION_FRAGMENT}
`;

export const PROJECT_FORM_QUERY = gql`
  query ProjectForm($projectId: ID!) {
    project(projectId: $projectId) {
      ...ProjectForm
    }
  }

  ${PROJECT_FORM_FRAGMENT}
`;

interface EditProjectPageProps {
  permissionsByOrg: OrgPermission;
}

const EditProjectPage = ({ permissionsByOrg }: EditProjectPageProps) => {
  const { orgSlug, projectId } = useParams<{
    orgSlug: string;
    projectId: string;
  }>();
  const { data } = useProjectFormQuery({ variables: { projectId: projectId } });
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const portfolioSlug = queryParams.get("portfolioSlug");
  let baseRedirect = `/${orgSlug}/projects/${projectId}`;
  if (portfolioSlug)
    baseRedirect = `/portfolios/${portfolioSlug}/projects/${projectId}`;
  const isProjectsAdmin = (permissionsByOrg[orgSlug || ""] ?? []).includes(
    PermissionType.ProjectsAdmin
  );

  const [updateProject] = useUpdateProjectMutation({
    onCompleted: () => {
      const url = `${baseRedirect}/${projectId}`;
      history.push(url);
    },
  });

  const removeEmpty = (obj: any) => {
    return Object.entries(obj)
      .filter(([_, v]) => v !== null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  };

  const onSubmit = (values: ProjectValues) => {
    return updateProject({
      variables: {
        orgSlug: orgSlug,
        projectId: projectId,
        ...values,
        system: { ...removeEmpty(values.system) },
      },
    });
  };

  const initialValues: ProjectValues = {
    name: data?.project?.name || "",
    status: data?.project?.status || ProjectStatus.Operating,
    projectType: data?.project?.type || ProjectType.Solar,
    developmentStage:
      data?.project?.developmentStage || DevelopmentStage.Complete,
    isOwned: data?.project?.isOwned || false,
    noticeToProceed: data?.project?.noticeToProceed,
    commercialOperatingDate: data?.project?.commercialOperatingDate,
    permissionToOperateDate: data?.project?.permissionToOperateDate,
    acquisitionDate: data?.project?.acquisitionDate,
    capacity: data?.project?.capacity || null,
    capacityAc: data?.project?.capacityAc || null,
    location: {
      address: data?.project?.location.address || "",
      city: data?.project?.location.city || "",
      state: data?.project?.location.state || "",
      zipCode: data?.project?.location.zipCode || "",
      latitude: data?.project?.location.latitude || null,
      longitude: data?.project?.location.longitude || null,
    },
    system: {
      bessKw: data?.project?.system.bessKw || null,
      bessKwh: data?.project?.system.bessKwh || null,
      panelWarranty: data?.project?.system.panelWarranty || null,
      inverterWarranty: data?.project?.system.inverterWarranty || null,
      contractorWarranty: data?.project?.system.contractorWarranty || null,
      installation:
        data?.project?.system.installation || InstallationType.Ground,
      mounting: data?.project?.system.mounting || MountingType.FixedTilt,
    },
    contact: {
      phoneNumber: data?.project?.contact.phoneNumber,
      balancingAuthorityAreaName:
        data?.project?.contact.balancingAuthorityAreaName,
    },
    panels:
      data?.project?.panels?.map((panel) => ({
        modelId: panel.modelId,
        count: panel.count,
      })) || [],
    inverters:
      data?.project?.inverters?.map((inverter) => ({
        modelId: inverter.modelId,
        count: inverter.count,
      })) || [],
    ppaContracts:
      data?.project?.ppaContracts?.map((ppa) => ({
        startDate: ppa.startDate,
        term: ppa.term,
        escalator: ppa.escalator,
        rate: ppa.rate,
      })) || [],
    offtakers:
      data?.project?.offtakers?.map((offtake) => ({ id: offtake.id })) || [],
    portfolios: data?.project?.portfolios?.map((spe) => ({ id: spe.id })) || [],
  };

  if (!isProjectsAdmin) return <NotFound />;

  return (
    <ProjectForms
      initialValues={initialValues}
      orgSlug={orgSlug}
      allowPortfolioChanges={true}
      onSubmit={onSubmit}
    />
  );
};

export default EditProjectPage;
