// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type QuestionProjectsFragment = (
  { readonly __typename: 'Portfolio' }
  & { readonly projects?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Project' }
    & Pick<Types.Project, 'id' | 'name'>
  )>> }
);

export type PortfolioProjectsQueryVariables = Types.Exact<{
  portfolioId: Types.Scalars['ID'];
}>;


export type PortfolioProjectsQuery = (
  { readonly __typename: 'Query' }
  & { readonly portfolio?: Types.Maybe<(
    { readonly __typename: 'Portfolio' }
    & Pick<Types.Portfolio, 'id'>
    & QuestionProjectsFragment
  )> }
);

export const QuestionProjectsFragmentDoc = gql`
    fragment QuestionProjects on Portfolio {
  projects(ordering: "name") {
    id
    name
  }
}
    `;
export const PortfolioProjectsDocument = gql`
    query PortfolioProjects($portfolioId: ID!) {
  portfolio(id: $portfolioId) {
    id
    ...QuestionProjects
  }
}
    ${QuestionProjectsFragmentDoc}`;

/**
 * __usePortfolioProjectsQuery__
 *
 * To run a query within a React component, call `usePortfolioProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioProjectsQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function usePortfolioProjectsQuery(baseOptions: Apollo.QueryHookOptions<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>) {
        return Apollo.useQuery<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>(PortfolioProjectsDocument, baseOptions);
      }
export function usePortfolioProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>) {
          return Apollo.useLazyQuery<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>(PortfolioProjectsDocument, baseOptions);
        }
export type PortfolioProjectsQueryHookResult = ReturnType<typeof usePortfolioProjectsQuery>;
export type PortfolioProjectsLazyQueryHookResult = ReturnType<typeof usePortfolioProjectsLazyQuery>;
export type PortfolioProjectsQueryResult = Apollo.QueryResult<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>;