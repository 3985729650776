import { groupBy } from "lodash";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import { SummaryExtractItem, SummaryJobType } from "~/api/types.generated";
import {
  SummaryJobFragmentFragmentDoc,
  useCreateSummaryJobMutation,
  useSummaryExtractItemsQuery,
} from "~/components/Documents/DocumentsAPI.generated";
import {
  DocumentsFilterContextValue,
  useDocumentsFilter,
} from "~/components/Documents/DocumentsCopilot/DocumentsFilterContext";
import useSelection, { UseSelectionResult } from "~/hooks/useSelection";

export interface CopilotProps {
  treeId: string;
  isOpen: boolean;
  loading: boolean;
  error: string | null;
  disabled: boolean;
  open(): void;
  close(): void;
  submit(): Promise<void>;
  reset(): void;
  selectedJobType: SummaryJobType;
  setSelectedJobType: Dispatch<SetStateAction<SummaryJobType>>;
  selectionOptions: Array<SummaryExtractItem>;
  selection: UseSelectionResult<SummaryExtractItem>;
  documentsFilter: DocumentsFilterContextValue;
}

const jobLabelMap = {
  [SummaryJobType.Insurance]: ["COI"],
  [SummaryJobType.PpaAnalysis]: ["PPA"],
  [SummaryJobType.DamInspection]: ["Dam Inspection"],
};

/** Handles state and logic required for creating a new folder */
export const useCopilot = (treeId: string): CopilotProps => {
  const [selectedJobType, setSelectedJobType] = useState(
    SummaryJobType.PpaAnalysis
  );
  const labels = useMemo(() => jobLabelMap[selectedJobType], [selectedJobType]);

  const documentsFilter = useDocumentsFilter(treeId, labels);

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { data } = useSummaryExtractItemsQuery();

  const optionsByJobType = useMemo(() => {
    if (data?.summaryExtractItems)
      return groupBy(data.summaryExtractItems, "jobType");
    // initialize empty items:
    return Object.values(SummaryJobType).reduce((acc, value) => {
      return {
        ...acc,
        [value]: [],
      };
    }, {});
  }, [data?.summaryExtractItems]);

  const selectionOptions = useMemo(
    () => optionsByJobType[selectedJobType],
    [selectedJobType, optionsByJobType]
  );

  const selection = useSelection(
    selectionOptions,
    useCallback((item) => item.id, [])
  );

  const open = useCallback(() => setIsOpen(true), []);
  const close = () => setIsOpen(false);

  const reset = useCallback(() => {
    setIsOpen(false);
    setError(null);
  }, []);

  const [createSummaryJob, { loading }] = useCreateSummaryJobMutation();

  const disabled = loading || error != null;

  const submit = useCallback(async () => {
    if (disabled) return;
    try {
      const documentIds = documentsFilter.selection.values().map((d) => d.id);
      await createSummaryJob({
        variables: { treeId, documentIds, jobType: selectedJobType },
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              summaryJobs(existingSummaryJobs = []) {
                const targetRef = cache.writeFragment({
                  data: data?.createSummaryJob,
                  fragment: SummaryJobFragmentFragmentDoc,
                });
                return [targetRef, ...existingSummaryJobs];
              },
            },
          });
        },
      });
    } catch (error) {
      setError("A server error has occurred.");
    }
  }, [
    createSummaryJob,
    treeId,
    disabled,
    documentsFilter.selection,
    selectedJobType,
  ]);

  return {
    treeId: useMemo(() => treeId, [treeId]),
    isOpen,
    loading,
    error,
    disabled,
    open,
    close,
    submit,
    reset,
    selectedJobType,
    setSelectedJobType,
    selectionOptions,
    selection,
    documentsFilter,
  };
};
