import { ThemeProps } from "~/styles/theme";

import { ColorOptions, getColor, mapColors } from "./color";

/**
 * Helper mixins for setting background colors.
 *
 * @example
 *
 *   styled.div`
 *     ${bgColor.blue()};
 *     ${bgColor.gray500({ opacity: 0.75 })};
 *   `
 */
const bgColor = mapColors(
  (name) =>
    (options: ColorOptions = {}) =>
    (props: ThemeProps) => ({
      backgroundColor: getColor(name, options, props),
    })
);

export default bgColor;
