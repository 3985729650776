import React from "react";
import styled from "styled-components";

import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { border, fgColor } from "~/styles/mixins";

const LoadingState = () => (
  <Container>
    <LoadingSpinner big />
  </Container>
);

const Container = styled.div`
  ${border.gray300()};
  ${fgColor.black()};
  padding: 2rem;
  text-align: center;
`;

export default LoadingState;
