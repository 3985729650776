import { LocationDescriptorObject } from "history";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router";
import styled from "styled-components";

import { PrimaryButton } from "~/components/common/buttons";
import { AddIcon } from "~/components/common/icons";
import { SecondaryHeading } from "~/components/common/text";
import { black, blue, indigo, white } from "~/styles/theme/color";
import { borderRadius } from "~/styles/theme/common";
import { fromEvent } from "~/vendored/file-selector";

import {
  DocumentTree_InfoFragment,
  Document_BreadcrumbsFragment,
} from "../DocumentsAPI.generated";
import { View } from "../useDocumentsRouting";
import { useDocumentsUpload } from "./DocumentsUploadProvider";

export interface DocumentsUploadProps {
  tree: DocumentTree_InfoFragment;
  parent: Document_BreadcrumbsFragment;
  getLink: (documentId: string, view?: View) => LocationDescriptorObject;
}

export const DocumentsUploadNew = ({
  tree,
  parent,
  getLink,
}: DocumentsUploadProps) => {
  const uploadManager = useDocumentsUpload();
  const history = useHistory();

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    getFilesFromEvent: fromEvent,
    multiple: true,
    onDrop: (entries) => {
      uploadManager.queueUploads(tree, parent, entries);
      history.push(getLink(parent.id, "single"));
    },
  });

  return (
    <Root>
      <Dropzone {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <SecondaryHeading>Drop files and folders here...</SecondaryHeading>
        ) : (
          <AddButton>
            <AddIcon />
          </AddButton>
        )}
      </Dropzone>
      <ChooseButton onClick={open}>Choose...</ChooseButton>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  border: 2px solid ${blue};
  color: ${black};
  transition: color 150ms ease-in-out, background-color 150ms ease-in-out;

  svg {
    width: 1.75rem;
    height: 1.75rem;
  }
`;

const Dropzone = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
  margin-bottom: 1.125rem;
  border: 1px solid ${blue};
  border-radius: ${borderRadius};
  cursor: pointer;

  &:hover > ${AddButton} {
    background-color: ${blue};
    color: ${white};
  }

  &:active > ${AddButton} {
    background-color: ${indigo};
    border-color: ${indigo};
    color: ${white};
  }
`;

const ChooseButton = styled(PrimaryButton)`
  height: 2.5rem;
  font-size: 1.125rem;
  padding: 0 1.5rem;
`;
