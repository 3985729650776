// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type QuestionsQueryVariables = Types.Exact<{
  logId: Types.Scalars['ID'];
}>;


export type QuestionsQuery = (
  { readonly __typename: 'Query' }
  & { readonly questions?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'number' | 'isClosed' | 'isPriority' | 'createdAt' | 'modifiedAt' | 'firstViewedAt' | 'viewedAt' | 'body'>
    & { readonly categories: ReadonlyArray<(
      { readonly __typename: 'QuestionCategory' }
      & Pick<Types.QuestionCategory, 'id' | 'name'>
    )>, readonly topics: ReadonlyArray<(
      { readonly __typename: 'QuestionTopic' }
      & Pick<Types.QuestionTopic, 'id' | 'name'>
    )>, readonly projects: ReadonlyArray<(
      { readonly __typename: 'Project' }
      & Pick<Types.Project, 'id' | 'name'>
    )>, readonly portfolios: ReadonlyArray<(
      { readonly __typename: 'Portfolio' }
      & Pick<Types.Portfolio, 'id' | 'name'>
    )>, readonly answer: (
      { readonly __typename: 'QuestionAnswer' }
      & Pick<Types.QuestionAnswer, 'id' | 'body' | 'createdAt' | 'modifiedAt'>
    ), readonly followUps: ReadonlyArray<(
      { readonly __typename: 'Question' }
      & Pick<Types.Question, 'id' | 'number' | 'isClosed' | 'isPriority' | 'createdAt' | 'modifiedAt' | 'firstViewedAt' | 'viewedAt' | 'body' | 'parentId'>
      & { readonly categories: ReadonlyArray<(
        { readonly __typename: 'QuestionCategory' }
        & Pick<Types.QuestionCategory, 'id' | 'name'>
      )>, readonly topics: ReadonlyArray<(
        { readonly __typename: 'QuestionTopic' }
        & Pick<Types.QuestionTopic, 'id' | 'name'>
      )>, readonly projects: ReadonlyArray<(
        { readonly __typename: 'Project' }
        & Pick<Types.Project, 'id' | 'name'>
      )>, readonly portfolios: ReadonlyArray<(
        { readonly __typename: 'Portfolio' }
        & Pick<Types.Portfolio, 'id' | 'name'>
      )>, readonly answer: (
        { readonly __typename: 'QuestionAnswer' }
        & Pick<Types.QuestionAnswer, 'id' | 'body' | 'createdAt' | 'modifiedAt'>
      ) }
    )> }
  )>> }
);

export type CategoriesQueryVariables = Types.Exact<{
  logId: Types.Scalars['ID'];
}>;


export type CategoriesQuery = (
  { readonly __typename: 'Query' }
  & { readonly questionCategories?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'QuestionCategory' }
    & Pick<Types.QuestionCategory, 'id' | 'name'>
  )>> }
);

export type TopicsQueryVariables = Types.Exact<{
  logId: Types.Scalars['ID'];
}>;


export type TopicsQuery = (
  { readonly __typename: 'Query' }
  & { readonly questionTopics?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'QuestionTopic' }
    & Pick<Types.QuestionTopic, 'id' | 'name'>
  )>> }
);

export type PortfolioProjectsQueryVariables = Types.Exact<{
  portfolioId: Types.Scalars['ID'];
  ordering?: Types.Maybe<Types.Scalars['String']>;
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type PortfolioProjectsQuery = (
  { readonly __typename: 'Query' }
  & { readonly portfolio?: Types.Maybe<(
    { readonly __typename: 'Portfolio' }
    & { readonly projects?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'Project' }
      & Pick<Types.Project, 'id' | 'name' | 'status' | 'capacity' | 'ppaRemainingAvg'>
      & { readonly location: (
        { readonly __typename: 'ProjectLocation' }
        & Pick<Types.ProjectLocation, 'id' | 'city' | 'state' | 'latitude' | 'longitude'>
      ) }
    )>> }
  )> }
);


export const QuestionsDocument = gql`
    query Questions($logId: ID!) {
  questions(logId: $logId) {
    id
    number
    isClosed
    isPriority
    createdAt
    modifiedAt
    firstViewedAt
    viewedAt
    categories {
      id
      name
    }
    topics {
      id
      name
    }
    projects {
      id
      name
    }
    portfolios {
      id
      name
    }
    body
    answer {
      id
      body
      createdAt
      modifiedAt
    }
    followUps {
      id
      number
      isClosed
      isPriority
      createdAt
      modifiedAt
      firstViewedAt
      viewedAt
      categories {
        id
        name
      }
      topics {
        id
        name
      }
      projects {
        id
        name
      }
      portfolios {
        id
        name
      }
      body
      answer {
        id
        body
        createdAt
        modifiedAt
      }
      parentId
    }
  }
}
    `;

/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *      logId: // value for 'logId'
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions: Apollo.QueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
        return Apollo.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, baseOptions);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          return Apollo.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, baseOptions);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<QuestionsQuery, QuestionsQueryVariables>;
export const CategoriesDocument = gql`
    query Categories($logId: ID!) {
  questionCategories(logId: $logId) {
    id
    name
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      logId: // value for 'logId'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const TopicsDocument = gql`
    query Topics($logId: ID!) {
  questionTopics(logId: $logId) {
    id
    name
  }
}
    `;

/**
 * __useTopicsQuery__
 *
 * To run a query within a React component, call `useTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicsQuery({
 *   variables: {
 *      logId: // value for 'logId'
 *   },
 * });
 */
export function useTopicsQuery(baseOptions: Apollo.QueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
        return Apollo.useQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, baseOptions);
      }
export function useTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
          return Apollo.useLazyQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, baseOptions);
        }
export type TopicsQueryHookResult = ReturnType<typeof useTopicsQuery>;
export type TopicsLazyQueryHookResult = ReturnType<typeof useTopicsLazyQuery>;
export type TopicsQueryResult = Apollo.QueryResult<TopicsQuery, TopicsQueryVariables>;
export const PortfolioProjectsDocument = gql`
    query PortfolioProjects($portfolioId: ID!, $ordering: String, $search: String) {
  portfolio(id: $portfolioId) {
    projects(ordering: $ordering, search: $search) {
      id
      name
      status
      capacity
      ppaRemainingAvg
      location {
        id
        city
        state
        latitude
        longitude
      }
    }
  }
}
    `;

/**
 * __usePortfolioProjectsQuery__
 *
 * To run a query within a React component, call `usePortfolioProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioProjectsQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      ordering: // value for 'ordering'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePortfolioProjectsQuery(baseOptions: Apollo.QueryHookOptions<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>) {
        return Apollo.useQuery<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>(PortfolioProjectsDocument, baseOptions);
      }
export function usePortfolioProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>) {
          return Apollo.useLazyQuery<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>(PortfolioProjectsDocument, baseOptions);
        }
export type PortfolioProjectsQueryHookResult = ReturnType<typeof usePortfolioProjectsQuery>;
export type PortfolioProjectsLazyQueryHookResult = ReturnType<typeof usePortfolioProjectsLazyQuery>;
export type PortfolioProjectsQueryResult = Apollo.QueryResult<PortfolioProjectsQuery, PortfolioProjectsQueryVariables>;