import { gql } from "@apollo/client/core";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers as FormikActions,
} from "formik";
import { trim } from "lodash";
import React, { useCallback, useContext } from "react";

import ChecklistContext from "~/components/Checklist/context/ChecklistContext";
import { useUpdateChecklistItemLinkMutation } from "~/components/Checklist/modals/ChecklistItemLinkForm.generated";

interface FormFields {
  link: string;
}

export const UPDATE_CHECKLIST_ITEM_LINK_MUTATION_FRAGMENT = gql`
  mutation UpdateChecklistItemLink($id: ID!, $link: String!) {
    updateChecklistItem(id: $id, link: $link) {
      id
      link
    }
  }
`;

export const ChecklistItemLinkForm = () => {
  const { getSelectedItem } = useContext(ChecklistContext);
  const checklistItem = getSelectedItem();
  const [updateItemLink] = useUpdateChecklistItemLinkMutation();
  const handleSubmit = useCallback(
    async (formFields: FormFields, actions: FormikActions<FormFields>) => {
      if (!checklistItem) return;
      try {
        console.log("UpdateItemLink with: " + Object.entries(formFields));
        await updateItemLink({
          variables: {
            id: checklistItem.id,
            link: formFields.link,
          },
        });
      } catch (error) {
        actions.setFieldError("link", trim(error.message, "'"));
      } finally {
        actions.setSubmitting(false);
      }
    },
    [checklistItem, updateItemLink]
  );
  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={{ link: checklistItem?.link || "" }}
    >
      <Form>
        <div className="form-group row">
          <label
            htmlFor="link"
            className="col-form-label checklist-modal__label"
          >
            Link
          </label>
          <Field
            className="form-control checklist-modal__input checklist-modal-no-navigation"
            type="text"
            name="link"
            id="link"
            placeholder="Link to documents"
          />
          <p className="text-danger">
            <ErrorMessage name="link" />
          </p>
        </div>
        <hr />
        <div className="form-group py-2">
          <button className="btn btn-primary" type="submit">
            Save
          </button>
        </div>
      </Form>
    </Formik>
  );
};
