import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

import { Toolbar } from "~/components/common/toolbars";

import { CloseButton } from "./CloseButton";

interface DialogProps
  extends Omit<ReactModal.Props, "className" | "portalClassName"> {
  className?: string;
  titleVariant?: "regular" | "large";
  header: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode;
  widthVariant?: "small" | "medium" | "large";
  actionsVariant?: boolean;
  clearable?: boolean;
}

const Dialog = ({
  className,
  titleVariant = "regular",
  children,
  header,
  onRequestClose = () => {},
  actions,
  actionsVariant = true,
  clearable = true,
  ...rest
}: DialogProps) => {
  return (
    <ReactModal
      portalClassName={className}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      {...rest}
    >
      <Header>
        <Title variant={titleVariant}>{header}</Title>
        {clearable && <CloseButton onClick={onRequestClose} />}
      </Header>
      {children}
      {actionsVariant ? <Actions>{actions}</Actions> : null}
    </ReactModal>
  );
};

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(214, 213, 213);
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
  margin-bottom: 20px;
`;

const Title = styled.span<{ variant?: "regular" | "large" }>`
  font-size: ${(props) => (props.variant === "large" ? "24px" : "21px")};
`;

const Actions = styled.div`
  border-top: 1px solid rgb(214, 213, 213);
  text-align: center;
  padding: 19px;
  margin-top: 34px;

  ${Toolbar} {
    justify-content: center;
  }
`;

export default styled(Dialog)<{ widthVariant?: "small" | "medium" | "large" }>`
  & .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.85) !important;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: 3;
  }

  & .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  & .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  & .ReactModal__Content {
    margin: 0 auto;
    width: ${(props) =>
      props.widthVariant === "medium"
        ? "880px"
        : props.widthVariant === "large"
        ? "1140px"
        : "600px"};
    bottom: auto !important;
  }
`;
