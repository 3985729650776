import classNames from "classnames";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";

import BannerHeader from "~/components/Banners/BannerHeader";
import { SortIcon } from "~/components/icons";
import StatGrid from "~/components/layout/StatGrid";
import Stat from "~/components/typography/Stat";
import StatLabel from "~/components/typography/StatLabel";
import { bgColor } from "~/styles/mixins";
import {
  displayPercentChange,
  formatProduction,
  formatSize,
  formatThousands,
  percentChange,
} from "~/utils/calculations";
import { gettext } from "~/utils/text";

import {
  ReportingBannerOperatingStatsFragment,
  ReportingBannerProjectsStatsFragment,
} from "./ReportingBanner.generated";

export const REPORTING_BANNER_FRAGMENTS = gql`
  fragment ReportingBannerProjectsStats on ProjectsStats {
    count
    totalCapacity
  }

  fragment ReportingBannerOperatingStats on OperatingStats {
    startDate
    actualRevenue
    actualExpenses
    actualProduction
    forecastRevenue
    forecastExpenses
    forecastProduction
  }
`;

interface ReportingBannerProps {
  name?: React.ReactNode | null | undefined;
  projectsStats?: ReportingBannerProjectsStatsFragment | null | undefined;
  operatingStats?: ReportingBannerOperatingStatsFragment | null | undefined;
  showAdmin: boolean;
}

export const ReportingBanner = ({
  name = "",
  projectsStats,
  operatingStats,
  showAdmin,
}: ReportingBannerProps) => {
  const [collapsedHeader, setCollapsedHeader] = useState(false);
  const revenuePercentChange = percentChange(
    operatingStats?.actualRevenue,
    operatingStats?.forecastRevenue
  );
  const expensesPercentChange = percentChange(
    operatingStats?.actualExpenses,
    operatingStats?.forecastExpenses
  );
  const productionPercentChange = percentChange(
    operatingStats?.actualProduction,
    operatingStats?.forecastProduction
  );
  const productionDate = operatingStats?.startDate
    ? moment(operatingStats.startDate).format("Y")
    : "";

  return (
    <BlackBanner>
      <BannerHeader
        title={`Reporting ${name}`}
        collapsed={collapsedHeader}
        setCollapsed={setCollapsedHeader}
        showAdmin={showAdmin}
      />
      <StatGrid collapse={collapsedHeader} repeat={5}>
        <div>
          <StatLabel>Projects</StatLabel>
          <Stat>{projectsStats?.count}</Stat>
        </div>
        <div>
          <StatLabel>Total size</StatLabel>
          <Stat>{formatSize(projectsStats?.totalCapacity)}</Stat>
        </div>
        <div>
          <StatLabel>Revenue YTD {productionDate}</StatLabel>
          <Row>
            <Stat>
              {operatingStats?.actualRevenue
                ? formatThousands(operatingStats.actualRevenue)
                : "--"}
            </Stat>
            <StackedContainer>
              <Forecast
                className={classNames(
                  {
                    "text-success": revenuePercentChange >= 0,
                    "text-danger": revenuePercentChange < 0,
                  },
                  "d-flex",
                  "align-items-center",
                  "forecast-data"
                )}
              >
                <StatIcon
                  theme={revenuePercentChange >= 0 ? "success" : "danger"}
                  dir={revenuePercentChange < 0 ? "S" : "N"}
                />
                {displayPercentChange(revenuePercentChange)}
              </Forecast>
              <StatNote>{gettext("vs Forecast")}</StatNote>
            </StackedContainer>
          </Row>
        </div>
        <div>
          <StatLabel>Expenses YTD {productionDate}</StatLabel>
          <Row>
            <Stat>
              {operatingStats?.actualExpenses
                ? formatThousands(operatingStats.actualExpenses)
                : "--"}
            </Stat>
            <StackedContainer>
              <Forecast
                className={classNames(
                  {
                    "text-success": expensesPercentChange < 0,
                    "text-danger": expensesPercentChange >= 0,
                  },
                  "d-flex",
                  "align-items-center",
                  "forecast-data"
                )}
              >
                <StatIcon
                  theme={expensesPercentChange < 0 ? "success" : "danger"}
                  dir={expensesPercentChange < 0 ? "S" : "N"}
                />
                {displayPercentChange(expensesPercentChange)}
              </Forecast>
              <StatNote>{gettext("vs Forecast")}</StatNote>
            </StackedContainer>
          </Row>
        </div>
        <div>
          <StatLabel>Production YTD {productionDate}</StatLabel>
          <Row>
            <Stat>{formatProduction(operatingStats?.actualProduction)}</Stat>
            <StackedContainer>
              <Forecast
                className={classNames(
                  {
                    "text-success": productionPercentChange >= 0,
                    "text-danger": productionPercentChange < 0,
                  },
                  "d-flex",
                  "align-items-center",
                  "forecast-data"
                )}
              >
                <StatIcon
                  theme={productionPercentChange >= 0 ? "success" : "danger"}
                  dir={productionPercentChange < 0 ? "S" : "N"}
                />
                {displayPercentChange(productionPercentChange)}
              </Forecast>
              <StatNote>{gettext("vs Forecast")}</StatNote>
            </StackedContainer>
          </Row>
        </div>
      </StatGrid>
    </BlackBanner>
  );
};

const BlackBanner = styled.div`
  padding: 5px 25px 5px;
  ${bgColor.gray800()};
  border-radius: 4px;
  font-size: 14px;
  color: #fffbd2;
`;

const StackedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 0.4rem;
  margin-left: 0.25rem;
`;

const Forecast = styled.div`
  line-height: 12px;
`;

const StatNote = styled.div`
  color: #c0c0c0;
  font-size: 12px;
`;

const StatIcon = styled(SortIcon)`
  height: 10px;
  padding: 0;
  margin: 0 5px 0 0;
  width: 8px;
`;

const Row = styled.span`
  display: flex;
  flex-direction: row !important;
`;
