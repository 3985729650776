import Tab from "@material-ui/core/Tab";
import styled from "styled-components";

import { bgColor, borderBottom, centerContent, fgColor } from "~/styles/mixins";
import { ThemeProps } from "~/styles/theme";

interface VerticalTabProps {
  index: number;
  error?: boolean;
  selected?: boolean;
}

const VerticalTab = styled(Tab)
  .withConfig<VerticalTabProps>({
    shouldForwardProp: (prop) => prop !== "error" && prop !== "index",
  })
  .attrs((props: ThemeProps & VerticalTabProps) => ({
    id: `tab-${props.index}`,
    color: "error",
    classes: {
      wrapper: "label-icon-group",
    },
    style: {
      // New mui allows for selecting nested classes via "sx" attribute and &.Mui-selected
      backgroundColor: props.selected && "white",
      textTransform: "none",
    },
  }))`

  .label-icon-group {
    gap: 10px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  // Until we migrate to the latest mui, we need to raise the specificity here:  
  && {
    line-height: 28px;
    ${centerContent};
    ${bgColor.gray100()};
    ${(props: VerticalTabProps) =>
      props.error ? fgColor.red() : fgColor.black()};
    flex: 1;
    height: 40px;
    min-height: 40px;
    max-width: 100%;
    font-size: 14px;
    font-weight: bold;
    justify-content: left;
  
    &:last-child {
      border-bottom: none;
    }

    ${borderBottom.gray300()};
  
    &:focus {
      outline: 0;
    }
  
    &:hover {
      ${(props: VerticalTabProps) =>
        props.error ? fgColor.red() : fgColor.black()};
      text-decoration: none;
    }
  
    &.active {
      ${bgColor.white()};
      cursor: default;
      pointer-events: none;
    }
  }
`;

export default VerticalTab;
