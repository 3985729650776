import React from "react";
import styled from "styled-components";

import TwoFADisabledIcon from "./TwoFADisabledIcon";
import TwoFAEnabledIcon from "./TwoFAEnabledIcon";

interface TwoFAStatusProps {
  enabled: boolean;
}

const Root = styled.h3`
  font-size: 0.875rem;
`;

const Status = styled.span<{ enabled: boolean }>`
  color: ${(props) => (props.enabled ? "#8DC727" : "#E64632")};
`;

const TwoFAStatus = ({ enabled }: TwoFAStatusProps) => {
  return (
    <Root>
      Two-factor authentication
      <Status enabled={enabled}>
        {enabled ? <TwoFAEnabledIcon /> : <TwoFADisabledIcon />}
        <span>{enabled ? "Enabled" : "Disabled"}</span>
      </Status>
    </Root>
  );
};

export default TwoFAStatus;
