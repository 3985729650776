import Icon from "components/icons/Icon";
import * as PropTypes from "prop-types";
import React from "react";

class SpinIcon extends React.PureComponent {
  render() {
    return (
      <Icon
        color={this.props.color}
        withoutBackground={this.props.withoutBackground}
        viewBox="0 0 16 16"
        className={this.props.className}
      >
        <defs>
          <path
            id="loading-a"
            d="M8,0 C7.36607143,0 6.85714286,0.508928571 6.85714286,1.14285714
                        L6.85714286,3.42857143 C6.85714286,4.0625 7.36607143,4.57142857
                        8,4.57142857 C8.63392857,4.57142857 9.14285714,4.0625 9.14285714,3.42857143
                        L9.14285714,1.14285714 C9.14285714,0.508928571 8.63392857,0 8,0 Z M8,11.4285714
                        C7.36607143,11.4285714 6.85714286,11.9375 6.85714286,12.5714286 L6.85714286,14.8571429
                        C6.85714286,15.4910714 7.36607143,16 8,16 C8.63392857,16 9.14285714,15.4910714
                        9.14285714,14.8571429 L9.14285714,12.5714286 C9.14285714,11.9375
                        8.63392857,11.4285714 8,11.4285714 Z M3.95535714,2.33928571 C3.50892857,1.89285714
                        2.78571429,1.89285714 2.33928571,2.33928571 C1.89285714,2.79464286 1.89285714,3.50892857
                        2.33928571,3.95535714 L3.95535714,5.57142857 C4.17857143,5.79464286 4.47321429,5.91071429
                        4.76785714,5.91071429 C5.0625,5.91071429 5.34821429,5.79464286 5.57142857,5.57142857
                        C6.01785714,5.125 6.01785714,4.40178571 5.57142857,3.95535714 L3.95535714,2.33928571
                        Z M12.0357143,10.4285714 C11.5892857,9.98214286 10.8660714,9.98214286 10.4196429,10.4285714
                        C9.97321429,10.875 9.97321429,11.5982143 10.4196429,12.0446429 L12.0357143,13.6607143
                        C12.2678571,13.8839286 12.5535714,13.9910714 12.8482143,13.9910714 C13.1428571,13.9910714
                        13.4285714,13.8839286 13.6517857,13.6607143 C14.0982143,13.2053571 14.0982143,12.4910714
                        13.6517857,12.0446429 L12.0357143,10.4285714 Z M4.57142857,8 C4.57142857,7.36607143
                        4.0625,6.85714286 3.42857143,6.85714286 L1.14285714,6.85714286 C0.508928571,6.85714286
                        0,7.36607143 0,8 C0,8.63392857 0.508928571,9.14285714 1.14285714,9.14285714
                        L3.42857143,9.14285714 C4.0625,9.14285714 4.57142857,8.63392857 4.57142857,8 Z
                        M14.8571429,6.85714286 L12.5714286,6.85714286 C11.9375,6.85714286 11.4285714,7.36607143
                        11.4285714,8 C11.4285714,8.63392857 11.9375,9.14285714 12.5714286,9.14285714
                        L14.8571429,9.14285714 C15.4910714,9.14285714 16,8.63392857 16,8 C16,7.36607143
                        15.4910714,6.85714286 14.8571429,6.85714286 Z M3.95535714,10.4285714 L2.33928571,12.0446429
                        C1.89285714,12.4910714 1.89285714,13.2053571 2.33928571,13.6607143 C2.5625,13.8839286
                        2.85714286,13.9910714 3.15178571,13.9910714 C3.44642857,13.9910714 3.73214286,13.8839286
                        3.95535714,13.6607143 L5.57142857,12.0446429 C6.01785714,11.5982143 6.01785714,10.875
                        5.57142857,10.4285714 C5.125,9.98214286 4.40178571,9.98214286 3.95535714,10.4285714 Z
                        M11.2321429,5.91071429 C11.5267857,5.91071429 11.8125,5.79464286 12.0357143,5.57142857
                        L13.6517857,3.95535714 C14.0982143,3.50892857 14.0982143,2.79464286 13.6517857,2.33928571
                        C13.2053571,1.89285714 12.4910714,1.89285714 12.0357143,2.33928571 L10.4196429,3.95535714
                        C9.97321429,4.40178571 9.97321429,5.125 10.4196429,5.57142857 C10.6428571,5.79464286
                        10.9375,5.91071429 11.2321429,5.91071429 Z"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <use fill="#000" xlinkHref="#loading-a" />
        </g>
      </Icon>
    );
  }
}

SpinIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  withoutBackground: PropTypes.bool,
};

SpinIcon.defaultProps = {
  className: null,
  color: "orange",
  withoutBackground: false,
};

export default SpinIcon;
