import { gql } from "@apollo/client/core";
import classNames from "classnames";
import { Field, Form, Formik, FormikHelpers as FormikActions } from "formik";
import React from "react";
import * as yup from "yup";

import { ThematicBreak } from "~/components/common/layout";
import PasswordInput from "~/components/forms/PasswordInput";
import {
  ChangePasswordFormFragment,
  useUpdatePasswordMutation,
} from "~/components/Settings/ChangePasswordForm.generated";

export const CHANGE_PASSWORD_FORM_FRAGMENT = gql`
  fragment ChangePasswordForm on User {
    id
    email
  }
`;

export const CHANGE_PASSWORD_FORM_MUTATION_FRAGMENT = gql`
  mutation UpdatePassword(
    $id: ID!
    $oldPassword: String!
    $newPassword: String!
  ) {
    updateUserPassword(
      id: $id
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      ...ChangePasswordForm
    }
  }

  ${CHANGE_PASSWORD_FORM_FRAGMENT}
`;

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required("This field is required"),
  newPassword: yup
    .string()
    .min(8, "Must be 8 or more characters")
    .required("This field is required")
    .test("has-number", "Password must have at least 1 number", (value) =>
      /\d/.test(value ?? "")
    ),
});

export interface ChangePasswordFormProps {
  user: ChangePasswordFormFragment;
  passwordResetUrl: string;
  alertPasswordChanged: (message: string) => void;
}

interface FormFields {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const ChangePasswordForm = ({
  user,
  passwordResetUrl,
  alertPasswordChanged,
}: ChangePasswordFormProps) => {
  const [update] = useUpdatePasswordMutation({
    onCompleted: () => {
      alertPasswordChanged("Password updated successfully.");
    },
    onError: () => {
      alertPasswordChanged("Password update failed.");
    },
  });
  const handleSubmit = (
    formFields: FormFields,
    actions: FormikActions<ChangePasswordFormFragment & FormFields>
  ) => {
    update({
      variables: {
        id: user.id,
        oldPassword: formFields.oldPassword,
        newPassword: formFields.newPassword,
      },
    }).then(() => actions.setSubmitting(false));
  };

  return (
    <div>
      <Formik
        initialValues={{
          ...user,
          oldPassword: "",
          newPassword: "",
          newPasswordConfirm: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values, errors, touched }) => (
          <Form className="mb-5 mt-3">
            <h3 className="settings-page__h3">Change password</h3>
            <ThematicBreak />
            <div className="form-group mt-2">
              <label htmlFor="username">Username</label>
              <Field
                type="email"
                className="form-control col-md-6 col-sm-10"
                id="username"
                value={user.email}
                disabled
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="old-password">Old password</label>
              <Field
                type="password"
                className={classNames("form-control", "col-md-6", "col-sm-10", {
                  "is-invalid": errors.oldPassword && touched.oldPassword,
                })}
                id="oldPassword"
                name="oldPassword"
              />
              {touched.oldPassword && errors.oldPassword && (
                <p className="text-danger">{errors.oldPassword}</p>
              )}
            </div>
            <div className="form-group mt-3">
              <label htmlFor="new-password">New password</label>
              <div className="input-group">
                <PasswordInput
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldName="newPassword"
                />
                <label
                  className="password-rule text-muted"
                  htmlFor="password-rule"
                >
                  Must be 8 or more characters and contain at least 1 number
                </label>
              </div>
              {errors.newPassword && touched.newPassword && (
                <p className="text-danger">{errors.newPassword}</p>
              )}
            </div>

            <div className="form-group mt-3">
              <label htmlFor="new-password-confirm">Confirm password</label>
              <div className="input-group">
                <PasswordInput
                  values={values}
                  errors={errors}
                  touched={touched}
                  fieldName="newPasswordConfirm"
                />
              </div>
              {errors.newPasswordConfirm && touched.newPasswordConfirm && (
                <p className="text-danger">{errors.newPasswordConfirm}</p>
              )}
            </div>
            <div className="form-group mt-4">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Update password
              </button>
              <a href={passwordResetUrl} className="ml-4">
                Forgot password?
              </a>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
