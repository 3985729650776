import { formatSizeComponents } from "~/utils/calculations";

export const getAdminContact = (full_name, email) => {
  if (email) return `${full_name} at ${email}`;
  return "your administrator";
};

export const formattedNumber = (number, digits = 0, nullValue = "--") =>
  number
    ? number.toLocaleString(navigator.language, {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
      })
    : nullValue;

export const formatCapacityRange = (min, max) => {
  if (!min && !max) return "--";
  const { formatted: minFormatted, units: minUnits } =
    formatSizeComponents(min);
  const { formatted: maxFormatted, units: maxUnits } =
    formatSizeComponents(max);
  if (minUnits === maxUnits)
    return `${minFormatted} - ${maxFormatted} ${maxUnits}`;
  return `${minFormatted} ${minUnits}-${maxFormatted} ${maxUnits}`;
};
