import {
  ComponentType,
  EventHandler,
  HTMLAttributes,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import usePortal from "react-useportal";

import useDimensions, { Dimensions } from "~/hooks/useDimensions";

export type ARIAPopupType = HTMLAttributes<HTMLElement>["aria-haspopup"];

export interface DropdownTrigger {
  triggerRect: null | Dimensions;
  isOpen: boolean;
  openCount: number;
  open: EventHandler<any>;
  close: () => void;
  toggle: EventHandler<any>;
  measure: () => void;
  setAriaType: (ariaType: ARIAPopupType) => void;
}

export interface UseDropdownTrigger {
  Portal: ComponentType;
  triggerProps: HTMLAttributes<HTMLElement>;
  dropdownTrigger: DropdownTrigger;
}

export const useDropdownTrigger = (): UseDropdownTrigger => {
  const [open, close, isOpen, Portal] = usePortal();
  const [triggerRef, triggerRect, trigger, measure] = useDimensions({
    liveMeasure: isOpen,
  });

  const [ariaType, setAriaType] = useState<ARIAPopupType>();

  const [openCount, setOpenCount] = useState(0);

  const openAndMeasure = useCallback(
    (event?: Event) => {
      measure();
      open(event);
      setOpenCount((count) => count + 1);
    },
    [measure, open]
  );

  const closeAndFocus = useCallback(() => {
    close();
    if (trigger) trigger.focus();
  }, [close, trigger]);

  const toggle = isOpen ? closeAndFocus : openAndMeasure;

  const dropdownTrigger = useMemo<DropdownTrigger>(
    () => ({
      triggerRect,
      isOpen,
      openCount,
      open: openAndMeasure,
      close: closeAndFocus,
      toggle,
      measure,
      setAriaType,
    }),
    [
      triggerRect,
      isOpen,
      openCount,
      openAndMeasure,
      closeAndFocus,
      toggle,
      measure,
      setAriaType,
    ]
  );

  const triggerProps = {
    ref: triggerRef,
    "aria-expanded": isOpen.toString(),
    "aria-haspopup": ariaType,
  };

  return { Portal, triggerProps, dropdownTrigger };
};

const DropdownTriggerContext = createContext<null | DropdownTrigger>(null);

export default DropdownTriggerContext;
