import Icon from "components/icons/Icon";
import * as PropTypes from "prop-types";
import React from "react";

class FileIcon extends React.PureComponent {
  render() {
    return (
      <Icon
        color={this.props.color}
        withoutBackground={this.props.withoutBackground}
        viewBox="0 0 16 16"
        className={this.props.className}
      >
        <defs>
          <path
            id="a"
            d="M9 4.5c0 .485.445.875 1 .875h4V15H2V1h7v3.5zM10 1l4 3.5h-4V1z"
          />
        </defs>
        <use
          fill="#008CD8"
          xlinkHref="#a"
          fillRule="evenodd"
          transform="translate(-2 -1)"
        />
      </Icon>
    );
  }
}

FileIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  withoutBackground: PropTypes.bool,
};

FileIcon.defaultProps = {
  className: null,
  color: "gray",
  withoutBackground: false,
};

export default FileIcon;
