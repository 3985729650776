import React, { useCallback, useEffect, useMemo, useState } from "react";

import Dialog from "~/components/generic/Dialog";
import { deleteCookie, getCookie } from "~/utils/cookie";

export interface OnboardingDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const OnboardingDialog = ({
  isOpen,
  onClose,
}: OnboardingDialogProps) => {
  const userName = DJ_CONST.user.name;
  const overviewImg = `${DJ_CONST.STATIC_URL}images/onboarding/image-a.jpg`;
  const documentsImg = `${DJ_CONST.STATIC_URL}images/onboarding/image-b.jpg`;

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onClose}
      widthVariant="medium"
      titleVariant="large"
      header={userName ? `Welcome ${userName}` : "Welcome"}
      actions={
        <div className="text-center">
          <button
            className="btn btn-primary btn btn-primary btn-custom"
            onClick={onClose}
            type="button"
          >
            Start
          </button>
        </div>
      }
    >
      <hr />
      <div className="row">
        <div className="col-md-6 mb-1">
          <p>
            You can use our platform to find diligence materials for clean
            energy portfolios and projects. The first time you login you’ll see
            a list of portfolios to which you’ve been granted access.
          </p>

          <p>
            The <b>Overview</b> tab shows a high-level summary of some of the
            key facts about the portfolio: the average offtaker credit, the
            average PPA life remaining, the system size, etc. When you’re ready
            to begin diligence, the <b>Documents</b> tab includes a structured
            list of diligence materials.
          </p>
        </div>
        <div className="col-md-6 mb-1">
          <img
            className="mb-3"
            src={overviewImg}
            alt="Overview"
            style={{ width: "390px" }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-1">
          <p>
            You can either browse or search the documents. To search, simply
            begin typing the document name. This example shows a search for PPA.
            You can then click the document name to view a preview in your
            browser, or click <b>Download</b> to download the document to your
            desktop.
          </p>
          <p>
            If you need help, contact{" "}
            <a href="mailto:support@dock.energy">support@dock.energy</a>.
          </p>
        </div>
        <div className="col-md-6 mb-1">
          <img
            className="mb-3"
            src={documentsImg}
            alt="Documents"
            style={{ width: "390px" }}
          />
        </div>
      </div>
      <hr />
    </Dialog>
  );
};

/**
 * A hook that provides the logic for the onboarding dialog, while allowing its
 * state to be held and controlled from a higher-level component.
 *
 * The dialog will open the first time the user logs in based on a cookie
 * called "first_login". The cookie is cleared as immediately after the first
 * render. After that, the dialog can be re-opened by calling the `open`
 * function returned by this hook.
 */
export const useOnboardingDialog = () => {
  // Check if the first login cookie is set (only on the first render)
  const isFirstLogin = useMemo(() => getCookie("first_login") === "true", []);

  // Delete the cookie (only on the first render)
  useEffect(() => {
    if (isFirstLogin) deleteCookie("first_login");
  }, [isFirstLogin]);

  // Initialize the isOpen state based on isFirstLogin
  const [isOpen, setIsOpen] = useState(isFirstLogin);

  // Some convenient callbacks
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return { isOpen, open, close };
};
