import PropTypes from "prop-types";
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const TooltipOverlay = ({
  tooltipKey,
  tooltipPlacement,
  tooltipText,
  children,
}) => (
  <OverlayTrigger
    key={tooltipKey}
    placement={tooltipPlacement}
    overlay={
      <Tooltip id={`tooltip-${tooltipPlacement}`}>{tooltipText}</Tooltip>
    }
  >
    {children}
  </OverlayTrigger>
);

TooltipOverlay.propTypes = {
  tooltipKey: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TooltipOverlay;
