import classnames from "classnames";
import {
  MB_GL_LIGHT,
  MB_GL_SATELLITE,
  MINI_MAP_HEIGHT,
  MINI_MAP_WIDTH,
} from "components/maps/constants";
import { getMarkerStyle } from "components/maps/functions";
import * as GeoJSON from "geojson";
import { keyBy } from "lodash";
import polylabel from "polylabel";
import React, { useCallback, useMemo, useRef, useState } from "react";
import ReactMapGL, {
  FlyToInterpolator,
  Layer,
  LayerProps,
  MapEvent,
  MapRef,
  Marker,
  NavigationControl,
  Popup,
  Source,
  ViewportProps,
} from "react-map-gl";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import { formatSize } from "utils/calculations";

import { SecondaryButton } from "~/components/common/buttons";
import SliderToggle from "~/components/common/slidertoggle";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import MapLegend from "~/components/Projects/MapLegend";
import { ProjectDetailFragment } from "~/components/Projects/ProjectPage.generated";
import { useMoveProjectMutation } from "~/components/Projects/ProjectsAPI.generated";
import { ProjectsMapFragment } from "~/components/Projects/ProjectsMap.generated";
import {
  DEFAULT_VIEW_PORT,
  USA_BOUNDS,
  calculateMiniMapViewPort,
} from "~/components/Projects/utils/map";
import useDimensions from "~/hooks/useDimensions";
import useLocalStorage from "~/hooks/useLocalStorage";
import { black, gray500, red, white, yellow } from "~/styles/theme/color";
import { gettext } from "~/utils/text";

const circleColorPalate: Record<number, string> = {
  10: red,
  1: yellow,
};
const earthCircumference = 40075017;
const milesPerKilometers = 1.609344;

// The following functions compute the miles to pixel conversion for mapbox
// from the answer here: https://stackoverflow.com/a/30773300/1467365
const metersPerPixel = (latitude: number, zoomLevel: number) => {
  const latitudeRadians = latitude * (Math.PI / 180);
  return (
    (earthCircumference * Math.cos(latitudeRadians)) /
    Math.pow(2, zoomLevel + 9)
  ); // +9 for mapbox
};

const metersToPixels = (
  latitude: number,
  meters: number,
  zoomLevel: number
) => {
  return meters / metersPerPixel(latitude, zoomLevel);
};

const milesToPixels = (latitude: number, miles: number, zoomLevel: number) => {
  const meters = miles * milesPerKilometers * 1000;
  return metersToPixels(latitude, meters, zoomLevel);
};

const circleGeoJson = (
  lat: number,
  lon: number
):
  | GeoJSON.Feature<GeoJSON.Geometry>
  | GeoJSON.FeatureCollection<GeoJSON.Geometry>
  | string => ({
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: { type: "Point", coordinates: [lon, lat] },
      properties: {},
    },
  ],
});

const radiusLayerStyle = (
  miles: number,
  lat: number,
  color: string
): LayerProps => {
  return {
    id: `point-${miles}`,
    type: "circle",
    paint: {
      "circle-radius": {
        type: "exponential",
        base: 2,
        stops: [
          [0, 0],
          [20, milesToPixels(lat, miles, 20)],
        ],
      },
      "circle-opacity": 0,
      "circle-stroke-width": 2,
      "circle-stroke-color": `${color}`,
    },
  };
};

const drawProjectRadius = (
  project: ProjectDetailFragment,
  radiusArray: number[]
) => {
  if (!project?.location.latitude || !project?.location.longitude) return null;
  return (
    <Source
      id="radius"
      type="geojson"
      data={circleGeoJson(
        project.location.latitude,
        project.location.longitude
      )}
    >
      {radiusArray.map((radius, index) => (
        <Layer
          key={`${radius}-${index}`}
          source="radius"
          {...radiusLayerStyle(
            radius,
            project.location.latitude ?? 0,
            circleColorPalate[radius]
          )}
        />
      ))}
    </Source>
  );
};

const renderSpatial = (
  project: ProjectDetailFragment,
  selectedLayerIds: string[],
  visibleLayerIds: string[]
) => {
  if (!project.spatial) return null;
  if (project.spatial?.features?.length) {
    return (
      <>
        {project.spatial?.features.map((feature) => {
          const layerId = getLayerId(feature);
          const isSelected = selectedLayerIds.includes(layerId);
          const paint: Record<string, any> = {};
          const fillColor = isSelected
            ? "#627BC1"
            : feature.fill || feature.stroke;
          const fillOpacity = isSelected ? 1 : feature.fillOpacity || 0;
          if (feature.type === "Polygon") {
            paint["fill-color"] = fillColor;
            paint["fill-opacity"] = fillOpacity;
          } else {
            paint["line-color"] = feature.stroke;
            if (feature.strokeWidth) paint["line-width"] = feature.strokeWidth;
          }
          return (
            <Source
              id={`source-${feature.id}`}
              key={`project-spatial-${feature.name.split(" ").join("_")}`}
              type="geojson"
              data={feature.geoJsonUrl}
            >
              <Layer
                id={layerId}
                key={`project-spatial-${feature.name.split(" ").join("_")}`}
                source={`project-spatial-${feature.name.split(" ").join("_")}`}
                type={feature.type === "Polygon" ? "fill" : "line"}
                paint={paint}
                layout={{
                  visibility: visibleLayerIds.includes(layerId)
                    ? "visible"
                    : "none",
                }}
              />
            </Source>
          );
        })}
      </>
    );
  }
  return (
    <Source
      id="project-spatial"
      type="geojson"
      data={project.spatial?.geoJsonUrl}
    >
      <Layer
        key="geo-json-key"
        source="shape"
        type="fill"
        paint={{ "fill-color": "#226BA2", "fill-opacity": 0.4 }}
      />
    </Source>
  );
};

const renderGeneratedPoints = (points: Array<[number, number]>) => {
  const features: GeoJSON.Feature[] = points.map((point) => ({
    type: "Feature",
    geometry: { type: "Point", coordinates: point },
    properties: {},
  }));
  const geoJson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: features,
  };
  return (
    <Source id="generatedPoints" type="geojson" data={geoJson}>
      <Layer
        id="point"
        type="circle"
        paint={{
          "circle-radius": 2,
          "circle-color": red,
        }}
      />
    </Source>
  );
};

export interface ProjectLocationProps {
  activeProject: ProjectDetailFragment;
  loading: boolean;
  projects: ProjectsMapFragment["projects"];
  error?: string;
  canMoveMarkers: boolean;
}

const getLayerId = (feature: any) => `layer-${feature.id}`;

const ProjectLocation = ({
  projects,
  activeProject,
  loading,
  error,
  canMoveMarkers,
}: ProjectLocationProps) => {
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const match = useRouteMatch();
  const basePath = match.path.replace(":orgSlug", orgSlug);
  const [moveProject] = useMoveProjectMutation({
    onCompleted: () => {
      setSelectedProject(null);
    },
  });
  const [mapStyle, setMapStyle] = useLocalStorage("map-style", MB_GL_LIGHT);
  const [showOneMile, setShowOneMile] = useState(true);
  const [showTenMile, setShowTenMile] = useState(true);
  const [ref] = useDimensions({ liveMeasure: true });
  const mapRef = useRef<MapRef | null>(null);
  const [viewport, setViewport] = useState<ViewportProps>({
    latitude: activeProject?.location.latitude ?? DEFAULT_VIEW_PORT.latitude,
    longitude: activeProject?.location.longitude ?? DEFAULT_VIEW_PORT.longitude,
    zoom: 13,
    transitionDuration: 0,
  });
  const interactiveLayerIds = activeProject.spatial?.features?.map((feature) =>
    getLayerId(feature)
  );
  const [lastClick, setLastClick] = useState<[number, number]>([
    activeProject?.location.longitude ?? DEFAULT_VIEW_PORT.longitude,
    activeProject?.location.latitude ?? DEFAULT_VIEW_PORT.latitude,
  ]);
  const [selectedLayerIds, setSelectedLayerIds] = useState<Array<string>>([]);
  const [visibleLayerIds, setVisibleLayerIds] = useState<Array<string>>(
    interactiveLayerIds ?? []
  );
  const featuresById = useMemo(
    () => keyBy(activeProject?.spatial?.features ?? [], "id"),
    [activeProject]
  );
  const [generatedPoints, setGeneratedPoints] = useState<Array<
    [number, number]
  > | null>(null);

  type Project = NonNullable<typeof projects>[number];
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  const totalSize = useMemo(
    () => (projects || []).reduce((acc, obj) => acc + (obj.capacity ?? 0), 0),
    [projects]
  );

  const projectCount = useMemo(() => (projects || []).length, [projects]);

  // Derive the miniViewPort from the main map:
  const map = mapRef.current?.getMap();
  const mapBounds = map?.getBounds();
  /* eslint-disable-next-line no-underscore-dangle */
  const [neLng, neLat, swLng, swLat] = mapBounds
    ? [
        mapBounds._ne.lng,
        mapBounds._ne.lat,
        mapBounds._sw.lng,
        mapBounds._sw.lat,
      ]
    : USA_BOUNDS;
  const miniViewPort = calculateMiniMapViewPort(neLng, neLat, swLng, swLat);
  const [debouncedMiniViewport] = useDebounce(miniViewPort, 1000, {
    leading: true,
  });

  const handleClick = (event: MapEvent) => {
    if (event.features) {
      setLastClick(event.lngLat);
      setSelectedLayerIds(event.features.map((f) => f.layer.id));
    }
  };

  const generateGeoApprox = (feature: any) => {
    fetch(feature.geoJsonUrl)
      .then((data) => data.json())
      .then((result) => {
        const points = result.features
          .filter(
            (f: GeoJSON.Feature<GeoJSON.Geometry>) =>
              f.geometry.type === "Polygon"
          )
          .map((f: GeoJSON.Feature<GeoJSON.Polygon>) => {
            return polylabel(f.geometry.coordinates, 1.0);
          });
        // set state on generated points
        setGeneratedPoints(points);
      });
  };

  const onDoubleClick = useCallback(
    (clickedProject) => {
      // Only use the ref inside the callback:
      const newZoom =
        viewport.zoom && viewport.zoom < 8 ? 8 : (viewport.zoom ?? 8) * 1.75;
      setViewport({
        ...viewport,
        latitude: clickedProject.location.latitude,
        longitude: clickedProject.location.longitude,
        zoom: newZoom,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      });
    },
    [viewport]
  );

  const onDragEnd = useCallback(
    (projectId, dragEvent) => {
      moveProject({
        variables: {
          projectId,
          latitude: dragEvent.lngLat[1],
          longitude: dragEvent.lngLat[0],
        },
      });
    },
    [moveProject]
  );

  // useMemo to cache project data markers (optimizes for lots of projects)
  const markers = useMemo(
    () =>
      projects?.map(
        (p) =>
          p.location.latitude &&
          p.location.longitude && (
            <Marker
              key={p.id}
              longitude={p.location.longitude}
              latitude={p.location.latitude}
              draggable={canMoveMarkers && activeProject?.id === p.id}
              onDragEnd={(event) => onDragEnd(p.id, event)}
            >
              <div
                style={getMarkerStyle(p.capacity ?? 0, totalSize, projectCount)}
                onClick={() => setSelectedProject(p)}
                onDoubleClick={() => onDoubleClick(p)}
              >
                <div
                  className={classnames(
                    "map-marker",
                    activeProject?.id === p.id && "active",
                    activeProject?.id !== p.id && p.isOwned && "owned"
                  )}
                />
              </div>
            </Marker>
          )
      ),
    [
      canMoveMarkers,
      activeProject,
      projects,
      totalSize,
      projectCount,
      onDoubleClick,
      onDragEnd,
    ]
  );

  const toggleMapStyle = () => {
    let newStyle = MB_GL_SATELLITE;
    if (mapStyle === MB_GL_SATELLITE) newStyle = MB_GL_LIGHT;
    setMapStyle(newStyle);
  };

  if (!activeProject || loading)
    return (
      <div className={classnames("project-section", "map")}>
        <LoadingSpinner />
      </div>
    );

  return (
    <div className={classnames("project-section", "map")}>
      <InfoAndControls>
        <div className="item">
          <div className="project-header">LOCATION</div>
          <div className="project-content">
            {activeProject.location.address}
          </div>
          <div className="project-content">{`${activeProject.location.city}, ${activeProject.location.state} ${activeProject.location.zipCode}`}</div>
        </div>
        <div className="item">
          <MapLegend />
        </div>
        <div style={{ alignSelf: "flex-end" }} className="item">
          <ToggleDiv>
            <SliderToggle
              title={gettext("10 Miles")}
              value={showTenMile}
              onClick={() => setShowTenMile(!showTenMile)}
              activeLabel="10 Miles"
              inactiveLabel="10 Miles"
              inactiveColor={gray500}
              activeColor={red}
              labelColor={showTenMile ? red : gray500}
              loading={false}
            />
            <SliderToggle
              title={gettext("1 Mile")}
              value={showOneMile}
              onClick={() => setShowOneMile(!showOneMile)}
              activeLabel={"1 Mile"}
              inactiveLabel={"1 Mile"}
              inactiveColor={gray500}
              activeColor={yellow}
              labelColor={showOneMile ? yellow : gray500}
              loading={false}
            />
          </ToggleDiv>
        </div>
        {error && <div className="map-error">{error}</div>}
      </InfoAndControls>
      <div ref={ref} className="map-container">
        <ReactMapGL
          ref={mapRef}
          mapboxApiAccessToken={DJ_CONST.MAPBOX_ACCESS_TOKEN}
          mapStyle={mapStyle}
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={(newViewport: ViewportProps) =>
            setViewport({ ...newViewport })
          }
          onClick={handleClick}
          interactiveLayerIds={interactiveLayerIds}
        >
          <div
            style={{
              position: "absolute",
              left: 4,
              top: 3,
              backgroundColor: white,
            }}
          >
            {activeProject.spatial?.features && (
              <div style={{ alignSelf: "flex-start" }} className="item">
                <ToggleDiv>
                  {activeProject.spatial?.features?.map((f) => {
                    const thisLayerId = getLayerId(f);
                    return (
                      <SliderToggle
                        key={f.id}
                        title={f.name}
                        value={visibleLayerIds.includes(thisLayerId)}
                        onClick={() => {
                          if (visibleLayerIds.includes(thisLayerId)) {
                            setVisibleLayerIds([
                              ...visibleLayerIds.filter(
                                (visible) => visible !== thisLayerId
                              ),
                            ]);
                          } else {
                            setVisibleLayerIds([
                              ...visibleLayerIds,
                              thisLayerId,
                            ]);
                          }
                        }}
                        activeLabel={f.name}
                        inactiveLabel={f.name}
                        inactiveColor={gray500}
                        activeColor={f.stroke || f.fill || black}
                        labelColor={showTenMile ? black : gray500}
                        loading={false}
                      />
                    );
                  })}
                </ToggleDiv>
              </div>
            )}
          </div>
          <div style={{ position: "absolute", right: 24, top: 24 }}>
            <NavigationControl showCompass={false} />
          </div>
          <div
            className={classnames(
              "mini-map",
              mapStyle === MB_GL_SATELLITE && "light"
            )}
            onClick={toggleMapStyle}
          >
            <ReactMapGL
              style={{ borderRadius: 8, zIndex: 7 }}
              mapboxApiAccessToken={DJ_CONST.MAPBOX_ACCESS_TOKEN}
              mapStyle={
                mapStyle === MB_GL_SATELLITE ? MB_GL_LIGHT : MB_GL_SATELLITE
              }
              {...debouncedMiniViewport}
              width={MINI_MAP_WIDTH}
              height={MINI_MAP_HEIGHT}
            >
              <div className="mini-map-caption">
                {mapStyle === MB_GL_SATELLITE
                  ? gettext("Map")
                  : gettext("Satellite")}
              </div>
            </ReactMapGL>
          </div>
          {drawProjectRadius(
            activeProject,
            // @ts-ignore
            [showOneMile && 1, showTenMile && 10].filter((n) => n)
          )}
          {renderSpatial(activeProject, selectedLayerIds, visibleLayerIds)}
          {markers}
          {generatedPoints && renderGeneratedPoints(generatedPoints)}
          {selectedProject &&
            selectedProject.location.longitude &&
            selectedProject.location.latitude &&
            !error && (
              <Popup
                longitude={selectedProject.location.longitude}
                latitude={selectedProject.location.latitude}
                closeOnClick={false}
                onClose={() => setSelectedProject(null)}
              >
                <div>
                  {activeProject.id === selectedProject.id && (
                    <b>{selectedProject.name}</b>
                  )}
                  {activeProject.id !== selectedProject.id && (
                    <Link
                      to={`${basePath.replace(
                        ":projectId",
                        selectedProject.id || ""
                      )}`}
                    >
                      {selectedProject.name}
                    </Link>
                  )}
                </div>
                <div>
                  {`System Size: ${formatSize(selectedProject.capacity)}`}
                </div>
              </Popup>
            )}
          {selectedLayerIds.length > 0 && (
            <Popup
              longitude={lastClick[0]}
              latitude={lastClick[1]}
              closeOnClick={false}
              onClose={() => setSelectedLayerIds([])}
            >
              <div>
                <div>Select layer to generate geo points:</div>
                {selectedLayerIds.map((layerId) => {
                  const feature = featuresById[layerId.split("-")[1]];
                  return (
                    <LayerButton
                      key={layerId}
                      onClick={() => {
                        generateGeoApprox(feature);
                        setSelectedLayerIds([]);
                      }}
                    >
                      {feature.name}
                    </LayerButton>
                  );
                })}
              </div>
            </Popup>
          )}
        </ReactMapGL>
      </div>
    </div>
  );
};

const LayerButton = styled(SecondaryButton)``;

const InfoAndControls = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: space-between;

  margin-top: 0;
`;

const ToggleDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  font-size: 18px;
  line-height: 24px;
`;

export default ProjectLocation;
