import classNames from "classnames";
import React from "react";

import { ChecklistItemFragment } from "~/components/Checklist/ChecklistPage.generated";
import { AlertIcon, ClockIcon, OkIcon, UserIcon } from "~/components/icons";
import { gettext } from "~/utils/text";

export interface ChecklistItemCountsProps {
  doneItems?: readonly ChecklistItemFragment[];
  attentionNeededItems?: readonly ChecklistItemFragment[];
  assignedItems?: readonly ChecklistItemFragment[];
  inProgressItems?: readonly ChecklistItemFragment[];
  total?: number;
  simple?: boolean;
}

export const ChecklistItemCounts = ({
  doneItems,
  attentionNeededItems,
  inProgressItems,
  assignedItems,
  total = 0,
  simple = false,
}: ChecklistItemCountsProps) => {
  const className = classNames({
    "text-nowrap": true,
    "mr-3": !simple,
    "ml-2": simple,
  });

  const renderElement = (
    text: string,
    icon: React.ReactNode,
    count: number
  ) => {
    return (
      <div key={text} className={className} title={simple ? text : ""}>
        {icon}
        <span data-testid="counters">
          {simple ? count : `${text}: ${count}`}
        </span>
      </div>
    );
  };

  return (
    <div
      className={classNames({
        "align-items-center d-flex": true,
        "mb-3 flex-wrap": !simple,
        "ml-auto": simple,
      })}
    >
      {!simple && (
        <span key="total" data-testid="total" className="mr-3 text-nowrap">
          {gettext("Total:")} {total}
        </span>
      )}
      {doneItems &&
        renderElement(
          gettext("Done"),
          <OkIcon className="mr-1" />,
          doneItems.length
        )}
      {inProgressItems &&
        renderElement(
          gettext("In Progress"),
          <ClockIcon className="mr-1" />,
          inProgressItems.length
        )}
      {attentionNeededItems &&
        renderElement(
          gettext("Attention needed"),
          <AlertIcon className="mr-1" />,
          attentionNeededItems.length
        )}
      {assignedItems &&
        renderElement(
          gettext("Assigned"),
          <UserIcon className="mr-1" />,
          assignedItems.length
        )}
    </div>
  );
};
