import { createContext, useContext } from "react";
import { Object } from "ts-toolbelt";

import { QuestionsQuery } from "../api/queries.generated";

export type ParentQuestion = Object.Path<QuestionsQuery, ["questions", number]>;
export type ChildQuestion = Object.Path<
  QuestionsQuery,
  ["questions", number, "followUps", number]
>;
export type QuestionContextValue = ParentQuestion | ChildQuestion;

const QuestionContext = createContext<QuestionContextValue>(null as any);

export const useQuestion = () => {
  return useContext(QuestionContext);
};

export default QuestionContext;
