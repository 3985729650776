import gql from "graphql-tag";
import React, { useContext, useEffect } from "react";

import { ChecklistFilters } from "~/components/Checklist/ChecklistFilters";
import { ChecklistItemCounts } from "~/components/Checklist/ChecklistItemCounts";
import { ChecklistItemModal } from "~/components/Checklist/ChecklistItemModal";
import { ChecklistViewerFragment } from "~/components/Checklist/ChecklistPage.generated";
import ChecklistTable from "~/components/Checklist/ChecklistTable";
import ChecklistContext, {
  useChecklistContext,
} from "~/components/Checklist/context/ChecklistContext";
import ChecklistFiltersContext, {
  useChecklistFilters,
} from "~/components/Checklist/context/ChecklistFiltersContext";
import { SecondaryButton } from "~/components/common/buttons";
import { FileExcelIcon } from "~/components/common/icons";

export const CHECKLIST_VIEWER_FRAGMENT = gql`
  fragment ChecklistViewer on User {
    id
    isSuperUser
  }
`;

export const ACTIVE_CHECKLIST_FRAGMENT = gql`
  fragment ActiveChecklist on Checklist {
    id
    name
  }
`;

export const CHECKLIST_ITEM_FRAGMENT = gql`
  fragment ChecklistItem on Item {
    id
    link
    projectId
    categoryId
    commentCount
    status
    userId
  }
`;

export const CHECKLIST_CATEGORY_FRAGMENT = gql`
  fragment ChecklistCategory on Category {
    id
    parentId
    name
    rowNumber
    isDevelopment
    isOperating
    children {
      id
      parentId
      name
      rowNumber
      isDevelopment
      isOperating
    }
  }
`;

export const CHECKLIST_FRAGMENT = gql`
  fragment ChecklistData on Checklist {
    id
    name
    categories {
      ...ChecklistCategory
    }
  }
  ${CHECKLIST_CATEGORY_FRAGMENT}
`;

export const CHECKLIST_QUERY = gql`
  query ChecklistData($checklistId: ID!) {
    checklist(id: $checklistId) {
      ...ChecklistData
    }
    checklistItems(checklistId: $checklistId) {
      ...ChecklistItem
    }
  }
  ${CHECKLIST_ITEM_FRAGMENT}
  ${CHECKLIST_FRAGMENT}
`;

export const ACTIVE_CHECKLISTS_QUERY = gql`
  query ActiveChecklists($portfolioId: ID!) {
    activeChecklists(portfolioId: $portfolioId) {
      ...ActiveChecklist
    }
  }
  ${ACTIVE_CHECKLIST_FRAGMENT}
`;

export const CHECKLIST_PROJECT_FRAGMENT = gql`
  fragment ChecklistProject on Project {
    id
    name
    capacity
    cod
    offtakerName
    location {
      city
      state
      zipCode
    }
  }
`;

export const CHECKLIST_USER_FRAGMENT = gql`
  fragment ChecklistUser on User {
    id
    fullName
    companyName
    avatarUrl
  }
`;

export const PORTFOLIO_PROJECTS_QUERY = gql`
  query PortfolioProjects($portfolioId: ID!) {
    portfolio(id: $portfolioId) {
      projects {
        ...ChecklistProject
      }
    }
    portfolioUsers(portfolioId: $portfolioId) {
      ...ChecklistUser
    }
  }
  ${CHECKLIST_PROJECT_FRAGMENT}
  ${CHECKLIST_USER_FRAGMENT}
`;

export interface ChecklistPageProps {
  portfolioId: string;
  viewer: ChecklistViewerFragment;
  onView: () => void;
}

export const ChecklistPage = ({
  portfolioId,
  viewer,
  onView = () => {},
}: ChecklistPageProps) => {
  useEffect(() => onView(), [onView]);
  const checklistFilters = useChecklistFilters(portfolioId);
  const checklistContext = useChecklistContext(portfolioId, checklistFilters);

  return (
    <ChecklistFiltersContext.Provider value={checklistFilters}>
      <ChecklistContext.Provider value={checklistContext}>
        <Checklist viewer={viewer} />
      </ChecklistContext.Provider>
    </ChecklistFiltersContext.Provider>
  );
};

interface ChecklistProps {
  viewer: ChecklistViewerFragment;
}

const Checklist = ({ viewer }: ChecklistProps) => {
  const checklistFilters = useContext(ChecklistFiltersContext);
  const checklistContext = useContext(ChecklistContext);
  const isLoading = checklistContext.loading;
  const totalProjectCount = checklistFilters.availableProjects.length;

  const renderError = () => {
    if (!checklistContext.error) {
      return null;
    }

    return (
      <div className="alert alert-danger mt-2">{checklistContext.error}</div>
    );
  };

  const getChecklistBody = () => {
    let checklistBody = (
      <div className="checklist-table">
        <ChecklistTable />
      </div>
    );
    if (!isLoading) {
      if (!totalProjectCount) {
        checklistBody = (
          <h4 className="text-center text-warning my-5">
            Checklist missing projects!
          </h4>
        );
      }
      if (!checklistContext.availableCategories.length) {
        checklistBody = (
          <h4 className="text-center text-warning my-5">
            Checklist missing categories!
          </h4>
        );
      }
    } else {
      checklistBody = (
        <h4 className="text-center text-primary my-5">Loading</h4>
      );
    }
    return checklistBody;
  };

  return (
    <div className="checklist-page">
      <ChecklistItemModal />
      <h2 className="mb-0">
        {checklistContext.checklist?.name ?? "Checklist"}
      </h2>

      {renderError()}

      <div className="d-flex justify-content-between align-items-end flex-wrap">
        <ChecklistItemCounts
          total={checklistContext.filteredItems.length}
          doneItems={checklistContext.doneItems}
          attentionNeededItems={checklistContext.attentionNeededItems}
          inProgressItems={checklistContext.inProgressItems}
          assignedItems={checklistContext.assignedItems}
        />
        <ChecklistFilters viewer={viewer} />

        {checklistContext.selectedChecklistId && (
          <div className="btn-group ml-3 mb-3">
            <SecondaryButton
              as="a"
              target="_blank"
              href={`/checklist/${checklistContext.selectedChecklistId}/spreadsheet`}
              style={{ height: "10px" }}
            >
              <FileExcelIcon />
              <span>Export</span>
            </SecondaryButton>
          </div>
        )}
      </div>
      <div className="checklist-table__wrapper">{getChecklistBody()}</div>
    </div>
  );
};
