import { ThemeProps } from "~/styles/theme";

import { ColorOptions, getColor, mapColors } from "./color";

interface BorderOptions extends ColorOptions {
  width?: string;
  style?: string;
}

const borderMixin = (
  property:
    | "border"
    | "borderTop"
    | "borderRight"
    | "borderBottom"
    | "borderLeft"
) =>
  mapColors(
    (name) =>
      ({ width = "1px", style = "solid", ...options }: BorderOptions = {}) =>
      (props: ThemeProps) => ({
        [property]: `${width} ${style} ${getColor(name, options, props)}`,
      })
  );

/**
 * Helper mixins for setting borders
 *
 * @example
 *
 *   styled.div`
 *     ${border.blue()};
 *     ${borderTop.gray500({ opacity: 0.75 })};
 *     ${borderRight.black({ width: '5px', style: 'dashed', opacity: 0.75 })};
 *     ${borderBottom.red({ width: '2px' })};
 *     ${borderLeft.primary()};
 *   `
 */
const border = borderMixin("border");
export const borderTop = borderMixin("borderTop");
export const borderRight = borderMixin("borderRight");
export const borderBottom = borderMixin("borderBottom");
export const borderLeft = borderMixin("borderLeft");

export default border;
