import { createContext } from "react";
import { TransitionState } from "react-spring";

import { DropdownTrigger } from "./DropdownTriggerContext";

export interface Dropdown extends DropdownTrigger {
  transitionState: null | TransitionState;
}

const DropdownContext = createContext<null | Dropdown>(null);

export default DropdownContext;
