import classNames from "classnames";
import * as PropTypes from "prop-types";
import React from "react";

const directionClass = (direction) => {
  const base = "svg-icon--";
  switch (direction) {
    case "N":
      return `${base}north`;
    case "E":
      return `${base}east`;
    case "S":
      return `${base}south`;
    case "W":
      return `${base}west`;
    default:
      return `${base}north`;
  }
};

/**
 * Colors are defined and CSS is automatically generated in the
 * atoms/_icons.scss file. Add more colors to that list if you want to use them
 * here.
 */
const Icon = ({
  children,
  color,
  dir,
  viewBox,
  className,
  withoutBackground,
  onClick,
  inverted,
  isOpaque,
}) => (
  <svg
    className={classNames(
      "svg-icon",
      `svg-icon--${color}`,
      directionClass(dir),
      {
        "svg-icon--without-background": withoutBackground,
        "svg-icon--clickable": onClick,
        "svg-icon--inverted": inverted,
        "svg-icon--opaque": isOpaque,
      },
      className
    )}
    viewBox={viewBox}
    onClick={onClick}
  >
    {children}
  </svg>
);

Icon.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  withoutBackground: PropTypes.bool,
  onClick: PropTypes.func,
  dir: PropTypes.string,
  inverted: PropTypes.bool,
  isOpaque: PropTypes.bool,
};

Icon.defaultProps = {
  className: null,
  withoutBackground: false,
  onClick: null,
  dir: "N",
  inverted: false,
  isOpaque: false,
  children: null,
  viewBox: "",
};

export default Icon;
