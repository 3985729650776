// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type OrgRoleFragment = (
  { readonly __typename: 'Role' }
  & Pick<Types.Role, 'id' | 'name'>
  & { readonly permissions?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Permission' }
    & Pick<Types.Permission, 'id' | 'codename'>
  )>> }
);

export type CreateGroupMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  name: Types.Scalars['String'];
  roleId: Types.Scalars['ID'];
  portfolios?: Types.Maybe<ReadonlyArray<Types.PortfolioInput> | Types.PortfolioInput>;
}>;


export type CreateGroupMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createGroup?: Types.Maybe<(
    { readonly __typename: 'UserGroup' }
    & Pick<Types.UserGroup, 'id' | 'name' | 'portfolioIds'>
    & { readonly role?: Types.Maybe<(
      { readonly __typename: 'Role' }
      & Pick<Types.Role, 'id' | 'name'>
    )> }
  )> }
);

export const OrgRoleFragmentDoc = gql`
    fragment OrgRole on Role {
  id
  name
  permissions {
    id
    codename
  }
}
    `;
export const CreateGroupDocument = gql`
    mutation CreateGroup($orgSlug: String!, $name: String!, $roleId: ID!, $portfolios: [PortfolioInput!]) {
  createGroup(
    orgSlug: $orgSlug
    name: $name
    roleId: $roleId
    portfolios: $portfolios
  ) {
    id
    name
    role {
      id
      name
    }
    portfolioIds
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      name: // value for 'name'
 *      roleId: // value for 'roleId'
 *      portfolios: // value for 'portfolios'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, baseOptions);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;