import "react-popper-tooltip/dist/styles.css";

import copy from "copy-to-clipboard";
import isUrl from "is-url";
import { ellipsis } from "polished";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { Editor, Node, Range, Transforms, createEditor } from "slate";
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  Slate,
  withReact,
} from "slate-react";
import styled from "styled-components";

import { CopyIcon, DoneIcon, NewTabIcon } from "~/components/icons";
import { bgColor, centerContent, fgColor, noOutline } from "~/styles/mixins";
import { GetPropsWithoutRef } from "~/utils/types";

export interface CellEditorProps
  extends Omit<GetPropsWithoutRef<typeof Editable>, "value" | "onChange"> {
  value: Node[];
  readOnly: boolean;
  onChange: (value: Node[]) => void;
}

const CellEditor = ({ value, onChange, ...rest }: CellEditorProps) => {
  const editor = useMemo(() => withLinks(withReact(createEditor())), []);

  // Keep track of mouse state so we can detect the source of focus events
  const mouseDown = useRef(false);
  const onMouseDown = () => {
    mouseDown.current = true;
  };
  const onMouseUp = () => {
    mouseDown.current = false;
  };

  // When focused via the keyboard, move the cursor to the end of the content
  const onFocus = () => {
    if (mouseDown.current) return;

    setTimeout(() => {
      const [node, path] = Node.texts(editor, { reverse: true })
        [Symbol.iterator]()
        .next().value;

      const point = { path, offset: node.text.length };

      Transforms.setSelection(editor, { anchor: point, focus: point });
    });
  };

  return (
    <Slate editor={editor} value={value} onChange={onChange}>
      <Editable
        renderElement={(props) => <Element {...props} />}
        onFocus={onFocus}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        {...rest}
      />
    </Slate>
  );
};

const withLinks = (editor: Editor & ReactEditor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => {
    return element.type === "link" || isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === "link" });
  return !!link;
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === "link" });
};

const wrapLink = (editor: Editor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

const Element = ({ attributes, children, element }: RenderElementProps) => {
  switch (element.type) {
    case "link":
      return (
        <Tooltip attributes={attributes} element={element}>
          {children}
        </Tooltip>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Tooltip = ({ attributes, children, element }: RenderElementProps) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ trigger: "click", placement: "top" });

  return (
    <>
      <span ref={setTriggerRef}>
        <a
          href={element.url}
          target="_blank"
          rel="noopener noreferrer"
          {...attributes}
        >
          {children}
        </a>
      </span>
      {visible && (
        <TooltipContainer
          ref={setTooltipRef}
          contentEditable="false"
          {...getTooltipProps({ className: "tooltip-container" })}
        >
          <TooltipLinkIcon />
          <TooltipLink
            href={element.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {element.url}
          </TooltipLink>
          <CopyButton text={element.url} />
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
        </TooltipContainer>
      )}
    </>
  );
};

const TooltipContainer = styled.div`
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 300px;
`;

const TooltipLinkIcon = styled(NewTabIcon)`
  flex: 0 0 15px;
  margin-left: 4px;
`;

const TooltipLink = styled.a`
  ${ellipsis()};
  margin: 0 8px 0 4px;
`;

const CopyButton = ({ text }: { text: string }) => {
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    copy(text);
    setCopied(true);
  };

  useEffect(() => {
    let timeout: number | null = null;

    if (copied) {
      timeout = window.setTimeout(() => {
        setCopied(false);
      }, 1000);
    }

    return () => {
      if (timeout !== null) clearTimeout(timeout);
    };
  }, [copied]);

  return copied ? (
    <CopyButtonText>
      <DoneIcon /> Copied!
    </CopyButtonText>
  ) : (
    <CopyButtonBtn onClick={onCopy}>
      <CopyIcon />
    </CopyButtonBtn>
  );
};

const CopyButtonBtn = styled.button`
  border: none;
  ${noOutline};
  background-color: transparent;
  display: flex;
  border-radius: 14px;

  flex: 0 0 28px 28px;
  width: 28px;
  height: 28px;
  ${centerContent};

  &:hover {
    ${bgColor.gray300()};
  }

  &:active {
    ${bgColor.green({ opacity: 0.5 })};
  }
`;

const CopyButtonText = styled.span`
  flex: 0;
  white-space: nowrap;
  height: 28px;
  line-height: 28px;
  font-weight: bold;

  & > svg {
    ${fgColor.green()};
  }
`;

export default styled(CellEditor)`
  ${noOutline};
  height: 100%;
  padding: 0.5rem;
`;
