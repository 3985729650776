import { differenceInDays, max } from "date-fns";
import React from "react";
import styled from "styled-components";

import { Question } from "~/api/types.generated";
import { CellDropdownButton } from "~/components/DataGrid";
import ActionMenu from "~/components/menus/ActionMenu";
import ActionMenuItem from "~/components/menus/ActionMenuItem";
import Dropdown from "~/components/menus/Dropdown";
import { bgColor, borderRadius, fgColor } from "~/styles/mixins";

import { useUpdateQuestionMutation } from "../api/mutations.generated";
import { useSaving } from "../context/QAndAContext";
import { useQuestion } from "../context/QuestionContext";

interface QuestionStatusProps {
  canEditQuestions: boolean;
}

const QuestionStatus = ({ canEditQuestions }: QuestionStatusProps) => {
  const question = useQuestion();
  const [updateQuestion, { loading }] = useUpdateQuestionMutation();

  useSaving(loading);

  const { isClosed, isPriority } = question;

  const handleChange =
    (values: Partial<Pick<Question, "isClosed" | "isPriority">>) => () => {
      updateQuestion({
        variables: { questionId: question.id, ...values },
        optimisticResponse: {
          __typename: "Mutation",
          updateQuestion: { ...question, ...values },
        },
      });
    };

  const close = handleChange({ isClosed: true });
  const reopen = handleChange({ isClosed: false });
  const markPriority = handleChange({ isPriority: true });
  const markNormal = handleChange({ isPriority: false });

  const disabled = !canEditQuestions;

  const now = new Date();
  const viewedAt = max(
    question.viewedAt,
    question.modifiedAt,
    question.answer.modifiedAt
  );
  const isNew = differenceInDays(now, question.firstViewedAt) < 1;
  const isUpdated = !isNew && differenceInDays(now, viewedAt) < 1;

  return (
    <CellDropdownButton
      disabled={disabled}
      dropdown={
        <Dropdown anchor="right" origin="left">
          <ActionMenu>
            {isClosed && (
              <ActionMenuItem onSelect={reopen}>Reopen Question</ActionMenuItem>
            )}
            {!isClosed && (
              <ActionMenuItem onSelect={close}>Close Question</ActionMenuItem>
            )}
            {!isClosed && isPriority && (
              <ActionMenuItem onSelect={markNormal}>
                Mark Normal Priority
              </ActionMenuItem>
            )}
            {!isClosed && !isPriority && (
              <ActionMenuItem onSelect={markPriority}>
                Mark High Priority
              </ActionMenuItem>
            )}
          </ActionMenu>
        </Dropdown>
      }
    >
      <Label>{isClosed ? "Closed" : "Open"}</Label>
      {isPriority && !isClosed && <PriorityLabel />}
      {isNew && <NewLabel />}
      {isUpdated && <UpdatedLabel />}
    </CellDropdownButton>
  );
};

const Label = styled.span`
  display: block;
  align-self: flex-start;
`;

const PillLabel = styled(Label)`
  ${borderRadius()};
  ${fgColor.white()};
  padding: 0 0.375rem;
  margin: 0.125rem 0;
  line-height: 1.25rem;
  font-size: 0.75rem;
  font-weight: bold;
`;

const PriorityLabel = styled(PillLabel).attrs({ children: "Priority" })`
  ${bgColor.yellow()};
`;

const NewLabel = styled(PillLabel).attrs({ children: "New" })`
  ${bgColor.green()};
`;

const UpdatedLabel = styled(NewLabel).attrs({ children: "Updated" })``;

export default QuestionStatus;
