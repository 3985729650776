import { useEffect, useRef } from "react";

export const useKeyDown = (
  key: string,
  active: boolean,
  handler: (event: KeyboardEvent) => void
) => {
  const handlerRef = useRef(handler);
  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    if (active) {
      const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === key) handlerRef.current(event);
      };
      document.addEventListener("keydown", onKeyDown);
      return () => document.removeEventListener("keydown", onKeyDown);
    }
    return () => {};
  }, [key, active]);
};
