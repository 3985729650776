import classnames from "classnames";
import React, { MutableRefObject, useContext } from "react";

import { CellButton, DataGrid, GridScroller } from "~/components/DataGrid";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";
import { Direction, ariaSort, createOrdering, reverse } from "~/utils/ordering";

import OrderingContext, { Field } from "../context/OrderingContext";
import { ChildQuestion, ParentQuestion } from "../context/QuestionContext";
import EmptyState from "./EmptyState";
import LoadingState from "./LoadingState";
import QuestionRow from "./QuestionRow";

const { Header, Row, HeaderCell, Body } = DataGrid;

export interface QuestionsGridProps {
  questions: Array<ParentQuestion | ChildQuestion>;
  loading: boolean;
  canEditQuestions: boolean;
  canEditQuestionAnswers: boolean;
  gridRef?: MutableRefObject<HTMLTableElement | null>;
  subject: QAndASubjectFragment;
}

const QuestionsGrid = ({
  questions,
  loading,
  gridRef,
  subject,
  canEditQuestions,
  canEditQuestionAnswers,
}: QuestionsGridProps) => {
  const orderingContext = useContext(OrderingContext);
  const { ordering, setOrdering } = orderingContext;

  if (loading) return <LoadingState />;

  if (!questions || questions.length === 0) {
    return <EmptyState text={"No questions so far"} />;
  }

  const sortByNumber = () => {
    setOrdering(
      ordering.field === Field.NUMBER
        ? reverse(ordering)
        : createOrdering(Field.NUMBER, Direction.ASC)
    );
  };

  return (
    <GridScroller>
      <DataGrid innerRef={gridRef}>
        <Header>
          <Row>
            <HeaderCell numeric aria-sort={ariaSort(Field.NUMBER, ordering)}>
              <CellButton onClick={sortByNumber}>
                No
                <i
                  className={classnames(
                    "icon",
                    "icon--sort-up",
                    ordering.field === Field.NUMBER && {
                      active: true,
                      asc: ordering.direction === Direction.ASC,
                      desc: ordering.direction === Direction.DESC,
                    }
                  )}
                />
              </CellButton>
            </HeaderCell>
            <HeaderCell padded>Status</HeaderCell>
            <HeaderCell padded>Category / Topic</HeaderCell>
            {subject.__typename === "Portfolio" && (
              <HeaderCell padded>Project</HeaderCell>
            )}
            {subject.__typename === "HoldingCompany" && (
              <HeaderCell padded>Portfolio</HeaderCell>
            )}
            <HeaderCell padded>Question / Request</HeaderCell>
            <HeaderCell padded>Answer</HeaderCell>
          </Row>
        </Header>
        <Body>
          {questions?.map((question) => (
            <QuestionRow
              canEditQuestions={canEditQuestions}
              canEditQuestionAnswers={canEditQuestionAnswers}
              key={question.id}
              question={question}
              subject={subject}
            />
          ))}
        </Body>
      </DataGrid>
    </GridScroller>
  );
};

export default QuestionsGrid;
