import React, { EventHandler, MouseEvent } from "react";
import styled from "styled-components";

import { fgColor, noOutline } from "~/styles/mixins";
import { GetPropsWithoutRef } from "~/utils/types";

export interface DropdownMultiselectButtonsProps
  extends GetPropsWithoutRef<"div"> {
  onSelectAll?: EventHandler<MouseEvent<HTMLButtonElement>>;
  onDeselectAll?: EventHandler<MouseEvent<HTMLButtonElement>>;
}

const DropdownMultiselectButtons = ({
  onSelectAll,
  onDeselectAll,
  ...rest
}: DropdownMultiselectButtonsProps) => {
  return (
    <div {...rest}>
      <Button onClick={onSelectAll}>Select All</Button>
      <Button onClick={onDeselectAll}>Unselect All</Button>
    </div>
  );
};

const Button = styled.button.attrs({
  type: "button",
})`
  ${noOutline};
  ${fgColor.gray600()};
  background: none;
  border: none;
  margin: 0 1rem 0 0;
  padding: 0;
  font-size: 0.875rem;

  &:hover {
    ${fgColor.blue()};
  }
`;

export default styled(DropdownMultiselectButtons)`
  margin-top: 0.75rem;
`;
