function submitButton() {
  [...document.getElementsByClassName("js-disable-after-submit")].forEach(
    (el) => {
      el.addEventListener("submit", () => {
        [...el.querySelectorAll("input[type='submit']")].forEach((inp) => {
          inp.disabled = true; // eslint-disable-line
        });
      });
    }
  );
}

export default submitButton;
