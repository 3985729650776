import { gql } from "@apollo/client";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";

import {
  QuestionProjectsFragment,
  usePortfolioProjectsLazyQuery,
} from "~/components/QAndA/context/QuestionProjectsContext.generated";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";

import {
  useAddAllProjectsMutation,
  useAddProjectMutation,
  useRemoveAllProjectsMutation,
  useRemoveProjectMutation,
} from "../api/mutations.generated";
import { ProjectObj } from "./ProjectFilterContext";
import { useSaving } from "./QAndAContext";
import { QuestionContextValue } from "./QuestionContext";

export const QUESTION_PROJECTS_FRAGMENT = gql`
  fragment QuestionProjects on Portfolio {
    projects(ordering: "name") {
      id
      name
    }
  }
`;

export const PORTFOLIO_PROJECTS_QUERY = gql`
  query PortfolioProjects($portfolioId: ID!) {
    portfolio(id: $portfolioId) {
      id
      ...QuestionProjects
    }
  }

  ${QUESTION_PROJECTS_FRAGMENT}
`;

export interface QuestionProjectsContextValue {
  availableProjects: QuestionProjectsFragment["projects"];
  hasWritePermission: boolean;
  addProject: (question: QuestionContextValue, project: ProjectObj) => void;
  addAllProjects: (question: QuestionContextValue) => void;
  removeProject: (question: QuestionContextValue, project: ProjectObj) => void;
  removeAllProjects: (question: QuestionContextValue) => void;
}

const QuestionProjectsContext = createContext<QuestionProjectsContextValue>(
  null as any
);

export const useQuestionProjects = () => useContext(QuestionProjectsContext);

export const QuestionProjectsProvider: FC<{
  subject: QAndASubjectFragment;
  canEditQuestions: boolean;
}> = ({ subject, canEditQuestions, children }) => {
  const [loadPortfolioProjects, projectsQuery] =
    usePortfolioProjectsLazyQuery();
  useEffect(() => {
    if (subject.__typename === "Portfolio") {
      loadPortfolioProjects({ variables: { portfolioId: subject.id } });
    }
  }, [subject.__typename, subject.id, loadPortfolioProjects]);
  const [addProject, { loading: loadingAddProject }] = useAddProjectMutation();
  const [addAllProjects, { loading: loadingAddAllProjects }] =
    useAddAllProjectsMutation();
  const [removeProject, { loading: loadingRemoveProject }] =
    useRemoveProjectMutation();
  const [removeAllProjects, { loading: loadingRemoveAllProjects }] =
    useRemoveAllProjectsMutation();

  useSaving(loadingAddProject);
  useSaving(loadingAddAllProjects);
  useSaving(loadingRemoveProject);
  useSaving(loadingRemoveAllProjects);

  const hasWritePermission = canEditQuestions;

  const context: QuestionProjectsContextValue = useMemo(
    () => ({
      availableProjects: projectsQuery.data?.portfolio?.projects ?? [],

      hasWritePermission,

      addProject: (question, project) => {
        addProject({
          variables: { questionId: question.id, projectId: project.id },
          optimisticResponse: {
            __typename: "Mutation",
            addQuestionProject: {
              ...question,
              projects: [...question.projects, project],
            },
          },
        });
      },

      addAllProjects: (question) => {
        addAllProjects({
          variables: { questionId: question.id },
          optimisticResponse: {
            __typename: "Mutation",
            addAllQuestionProjects: {
              ...question,
              projects: projectsQuery.data?.portfolio?.projects ?? [],
            },
          },
        });
      },

      removeProject: (question, project) => {
        removeProject({
          variables: { questionId: question.id, projectId: project.id },
          optimisticResponse: {
            __typename: "Mutation",
            removeQuestionProject: {
              ...question,
              projects: question.projects.filter((p) => p.id !== project.id),
            },
          },
        });
      },

      removeAllProjects: (question) => {
        removeAllProjects({
          variables: { questionId: question.id },
          optimisticResponse: {
            __typename: "Mutation",
            removeAllQuestionProjects: { ...question, projects: [] },
          },
        });
      },
    }),
    [
      projectsQuery.data,
      hasWritePermission,
      addProject,
      addAllProjects,
      removeProject,
      removeAllProjects,
    ]
  );

  return (
    <QuestionProjectsContext.Provider value={context} children={children} />
  );
};

export default QuestionProjectsContext;
