import gql from "graphql-tag";
import React from "react";
import { Route, useParams } from "react-router-dom";

import { PageContainer } from "~/components/common/layout";
import { useOAndMQuery } from "~/components/Documents/OAndM.generated";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { OrgFeatureFlags } from "~/permissions/useViewerPermissions";

import { Documents } from "./Documents";
import { OAndMBanner, O_AND_M_BANNER_FRAGMENT } from "./OAndMBanner";

export const O_AND_M_QUERY = gql`
  query OAndM($orgSlug: String!) {
    ...OAndMBanner
    tree: oAndMTree(orgSlug: $orgSlug) {
      id
    }
  }

  ${O_AND_M_BANNER_FRAGMENT}
`;

interface OAndMProps {
  isAdmin: boolean;
  isOAndMAdmin: boolean;
  featureFlagsByOrg: OrgFeatureFlags;
  canViewDocumentsHistory: boolean;
  isUsersAdmin: boolean;
}

export const OAndM = ({
  isAdmin,
  isOAndMAdmin,
  featureFlagsByOrg,
  canViewDocumentsHistory,
  isUsersAdmin,
}: OAndMProps) => {
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const { data } = useOAndMQuery({
    variables: { orgSlug: orgSlug },
    returnPartialData: true,
    errorPolicy: "all",
  });
  const areLabelsEnabled = !!(featureFlagsByOrg[orgSlug || ""] ?? {})
    .documentLabels;

  return (
    <PageContainer>
      <OAndMBanner
        partners={data?.partners}
        showStats={isOAndMAdmin}
        showAdmin={isAdmin}
      />
      {data?.tree?.id != null ? (
        <Route path="/:orgSlug/o-and-m/:documentId?">
          <Documents
            basePath={`/${orgSlug}/o-and-m`}
            treeId={data.tree.id}
            isUsersAdmin={isUsersAdmin}
            isDocumentsAdmin={isOAndMAdmin}
            areLabelsEnabled={areLabelsEnabled}
            canViewDocumentsHistory={canViewDocumentsHistory}
          />
        </Route>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <LoadingSpinner big />
        </div>
      )}
    </PageContainer>
  );
};
