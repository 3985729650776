import * as PropTypes from "prop-types";
import React from "react";

import Category from "./Category"; // eslint-disable-line import/no-named-as-default
import InnerChecklistItem from "./InnerChecklistItem"; // eslint-disable-line import/no-named-as-default
import ParentChecklistItem from "./ParentChecklistItem"; // eslint-disable-line import/no-named-as-default
import Project from "./Project";

export const CellType = Object.freeze({
  InnerChecklistItem: Symbol("inner-checklist-item"),
  ParentChecklistItem: Symbol("parent-checklist-item"),
  Category: Symbol("category"),
  Project: Symbol("project"),
});

// A generic cell component, so that we can do some magic with react-window.
// Basically we have a data object for each cell and what to return different
// components based on the cell type.
const Cell = ({ columnIndex, rowIndex, style, data }) => {
  const element = data[rowIndex][columnIndex];

  // This is a hack. The old version of the checklist was rendering the whole thing
  // and use "border-collapse: collapse" to make a 1px border table. After we migrated to the
  // react-window, this property won't work anymore, since the library position the
  // cells absolutely. Please don't hate me, future developer.
  const itemStyles = {
    ...style,
    width: style.width + 1,
    left: style.left - 1,
    height: style.height + 1,
    top: style.top - 1,
  };

  // Switch because `const mapping = {[CellType.Category]: <Category />}` will be ugly too
  switch (element.cellType) {
    case CellType.Category:
      return (
        <Category
          {...element.props}
          style={style}
          key={`${columnIndex}.${rowIndex}`}
        />
      );
    case CellType.Project:
      return (
        <Project
          {...element.props}
          style={style}
          key={`${columnIndex}.${rowIndex}`}
        />
      );
    case CellType.InnerChecklistItem:
      return (
        <InnerChecklistItem
          {...element.props}
          style={itemStyles}
          key={`${columnIndex}.${rowIndex}`}
        />
      );
    case CellType.ParentChecklistItem:
      return (
        <ParentChecklistItem
          {...element.props}
          style={itemStyles}
          key={`${columnIndex}.${rowIndex}`}
        />
      );
    default:
      return null;
  }
};

Cell.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default Cell;
