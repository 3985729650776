// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateInviteMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  invitationToken: Types.Scalars['ID'];
  firstName?: Types.Maybe<Types.Scalars['String']>;
  lastName?: Types.Maybe<Types.Scalars['String']>;
  title?: Types.Maybe<Types.Scalars['String']>;
  groupId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type UpdateInviteMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateInvitation?: Types.Maybe<(
    { readonly __typename: 'OrgInvitation' }
    & Pick<Types.OrgInvitation, 'token' | 'firstName' | 'lastName' | 'title' | 'groupId'>
  )> }
);


export const UpdateInviteDocument = gql`
    mutation UpdateInvite($orgSlug: String!, $invitationToken: ID!, $firstName: String, $lastName: String, $title: String, $groupId: ID) {
  updateInvitation(
    orgSlug: $orgSlug
    invitationToken: $invitationToken
    firstName: $firstName
    lastName: $lastName
    title: $title
    groupId: $groupId
  ) {
    token
    firstName
    lastName
    title
    groupId
  }
}
    `;
export type UpdateInviteMutationFn = Apollo.MutationFunction<UpdateInviteMutation, UpdateInviteMutationVariables>;

/**
 * __useUpdateInviteMutation__
 *
 * To run a mutation, you first call `useUpdateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInviteMutation, { data, loading, error }] = useUpdateInviteMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      invitationToken: // value for 'invitationToken'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      title: // value for 'title'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useUpdateInviteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInviteMutation, UpdateInviteMutationVariables>) {
        return Apollo.useMutation<UpdateInviteMutation, UpdateInviteMutationVariables>(UpdateInviteDocument, baseOptions);
      }
export type UpdateInviteMutationHookResult = ReturnType<typeof useUpdateInviteMutation>;
export type UpdateInviteMutationResult = Apollo.MutationResult<UpdateInviteMutation>;
export type UpdateInviteMutationOptions = Apollo.BaseMutationOptions<UpdateInviteMutation, UpdateInviteMutationVariables>;