import classNames from "classnames";
import { Field } from "formik";
import * as PropTypes from "prop-types";
import React from "react";
import { gettext } from "utils/text";

class PasswordInput extends React.Component {
  state = {
    passwordVisible: false,
    showPasswordActive: false,
  };

  showHidePassword = () =>
    this.setState((prevState) => ({
      passwordVisible: !prevState.passwordVisible,
    }));

  handleFocus = () => this.setState({ showPasswordActive: true });

  handleBlur = (values) => {
    if (!values) {
      this.setState({
        showPasswordActive: false,
      });
    }
  };

  render() {
    const { fieldName, errors, touched, values } = this.props;
    return (
      <div className="password-input col-md-6 col-sm-10 mr-3">
        <Field
          type={this.state.passwordVisible ? "text" : "password"}
          className={classNames("form-control", "password-field", {
            "is-invalid": errors[fieldName] && touched[fieldName],
          })}
          id={fieldName}
          name={fieldName}
          onFocus={this.handleFocus}
          onBlur={() => {
            this.handleBlur(values[fieldName]);
          }}
        />
        {/* FIXME: This should be a <button /> instead of an <a /> */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={() => {
            this.showHidePassword();
          }}
          className={`show-password text-${
            this.state.showPasswordActive ? "primary" : "muted"
          }`}
        >
          {this.state.passwordVisible ? gettext("HIDE") : gettext("SHOW")}
        </a>
      </div>
    );
  }
}

PasswordInput.propTypes = {
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.func,
      PropTypes.bool,
    ])
  ).isRequired,
  fieldName: PropTypes.string.isRequired,
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default PasswordInput;
