import { NavLink } from "react-router-dom";
import styled from "styled-components";

import {
  bgColor,
  borderRadius,
  borderRight,
  centerContent,
  fgColor,
} from "~/styles/mixins";

const NavTab = styled(NavLink)`
  line-height: 28px;
  ${centerContent};
  ${bgColor.gray100()};
  ${fgColor.black()};
  ${borderRight.gray300()};
  flex: 1;
  height: 40px;
  font-weight: bold;

  &:first-child {
    ${borderRadius("left")};
  }

  &:last-child {
    ${borderRadius("right")};
    border-right: none;
  }

  &:hover {
    ${fgColor.black()};
    text-decoration: none;
  }

  &.active {
    ${bgColor.white()};
    cursor: default;
    pointer-events: none;
  }
`;

export default NavTab;
