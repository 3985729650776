import React, {
  ChangeEventHandler,
  ComponentProps,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import * as types from "~/api/types.generated";
import { TertiaryButton } from "~/components/common/buttons";
import { CloseIcon, PencilIcon, TriangleIcon } from "~/components/common/icons";
import { InputLabel, TextInput } from "~/components/common/inputs";
import * as panels from "~/components/common/panels";
import * as text from "~/components/common/text";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { useAutoFocus } from "~/hooks/useAutoFocus";
import { black, yellow } from "~/styles/theme/color";

export interface MilestoneEditorProps {
  milestone: types.Milestone;
  isSaving: boolean;
  isMoving: boolean;
  isFirst: boolean;
  isLast: boolean;
  onSave: (data: types.UpdateMilestonePayload) => void;
  onCancel: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
}

export const MilestoneEditor = ({
  milestone,
  isSaving,
  isMoving,
  isFirst,
  isLast,
  onSave,
  onCancel,
  onMoveUp,
  onMoveDown,
}: MilestoneEditorProps) => {
  const dueDateInput = useRef<HTMLInputElement>(null);
  useAutoFocus(true, dueDateInput);

  const [dueDate, setDueDate] = useState(milestone.dueDate ?? "");
  const [title, setTitle] = useState(milestone.title ?? "");
  const [body, setBody] = useState(milestone.body ?? "");
  const [status, setStatus] = useState(
    milestone.status ?? types.MilestoneStatus.Active
  );

  const handleDueDateChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setDueDate(event.currentTarget.value);
  };
  const handleTitleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setTitle(event.currentTarget.value);
  };
  const handleBodyChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setBody(event.currentTarget.value);
  };
  const handleStatusChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setStatus(event.target.value as types.MilestoneStatus);
  };
  const handleSave = () => {
    onSave({ milestoneId: milestone.id, dueDate, title, status, body });
  };

  const actions = (
    <Actions>
      <ActionButton onClick={handleSave} disabled={isSaving || isMoving}>
        {isSaving ? <LoadingSpinner /> : <PencilIcon />}
        <span>Save</span>
      </ActionButton>
      <ActionButton onClick={onCancel} disabled={isSaving || isMoving}>
        <CloseIcon />
        <span>Cancel</span>
      </ActionButton>
    </Actions>
  );

  const positionActions = (
    <PositionActions>
      <ActionButton onClick={onMoveUp} disabled={isMoving || isFirst}>
        <TriangleIcon style={{ transform: "rotate(-90deg) scale(0.75)" }} />
      </ActionButton>
      <ActionButton onClick={onMoveDown} disabled={isMoving || isLast}>
        <TriangleIcon style={{ transform: "rotate(90deg) scale(0.75)" }} />
      </ActionButton>
      {isMoving && <LoadingSpinner />}
    </PositionActions>
  );

  return (
    <>
      <Panel>
        <Head>
          <Position>Position {(milestone.order ?? 0) + 1}</Position>
          {positionActions}
          {actions}
        </Head>
        <Content>
          <SummarySection>
            <TitleSection>
              <InputLabel>
                <Label>Due</Label>
                <TextInput
                  ref={dueDateInput}
                  value={dueDate}
                  onChange={handleDueDateChange}
                />
              </InputLabel>
              <InputLabel>
                <Label>Title</Label>
                <TextInput value={title} onChange={handleTitleChange} />
              </InputLabel>
            </TitleSection>
            <FlagsSection>
              <InputLabel>
                <Label>Status</Label>
                <StatusRadio
                  status={status}
                  label="In progress"
                  value={types.MilestoneStatus.Active}
                  onChange={handleStatusChange}
                />
                <StatusRadio
                  status={status}
                  label="Complete"
                  value={types.MilestoneStatus.Complete}
                  onChange={handleStatusChange}
                />
              </InputLabel>
            </FlagsSection>
          </SummarySection>
          <InputLabel>
            <Label>Body</Label>
            <Body as="textarea" onChange={handleBodyChange}>
              {body}
            </Body>
          </InputLabel>
        </Content>
      </Panel>
      <Spacer isLast={isLast} />
    </>
  );
};

interface StatusRadioProps extends ComponentProps<"input"> {
  status: types.MilestoneStatus;
  value: types.MilestoneStatus;
  label: string;
}

const StatusRadio = ({ status, value, label, ...rest }: StatusRadioProps) => (
  <div className="custom-control custom-radio custom-control-inline">
    <input
      type="radio"
      id={`milestone-status-${value}`}
      className="custom-control-input"
      name="status"
      value={value}
      checked={status === value}
      {...rest}
    />
    <label
      className="custom-control-label"
      htmlFor={`milestone-status-${value}`}
    >
      {label}
    </label>
  </div>
);

const Panel = styled(panels.Panel)`
  overflow: hidden;
  border-color: ${yellow};
`;

const Head = styled(panels.PanelHead)`
  min-height: 2rem;
  border-radius: 0;
  background-color: ${yellow};
  border-bottom-color: ${yellow};
  color: ${black};
`;

const Actions = styled.div`
  margin-left: auto;
`;

const ActionButton = styled(TertiaryButton)`
  border: none;
  min-height: 1.5rem;
  background: transparent;
  text-transform: uppercase;
  display: inline-flex;
  margin-left: 0.5rem;

  &:hover {
    background: rgba(0, 0, 0, 0.375);
  }

  &:disabled {
    border: none;
  }
`;

const Content = styled.div`
  padding: 0.125rem 0.75rem 0.375rem;
`;

const Position = styled(text.Text)`
  display: inline;
  margin: 0;
`;

const PositionActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;

  ${ActionButton} {
    padding: 0;
    margin: 0;
    width: 1.5rem;
  }

  & .loading-spinner {
    margin-left: 0.5rem;
  }
`;

const SummarySection = styled.div`
  display: flex;
`;

const TitleSection = styled.div`
  width: 61.8%;
  flex: 0 0 61.8%;
`;

const FlagsSection = styled.div`
  flex: 1;
  margin-left: 1rem;
`;

const Body = styled(TextInput)`
  height: auto;
  min-height: 8rem;
  padding-top: 0.375rem;
`;

const Label = text.Text;

const Spacer = styled.div<{
  isLast: boolean;
}>`
  height: 1.5rem;
  margin-left: 1.25rem;
  border-left-width: ${(props) => (props.isLast ? 0 : 1)}px;
  border-left-color: ${yellow};
  border-left-style: solid;
`;
