import gql from "graphql-tag";
import React, { useCallback, useContext, useState } from "react";

import { DeleteGroupDialog } from "~/components/Admin/Group/DeleteGroupDialog";
import {
  useOrgGroupsQuery,
  useOrgRolesQuery,
} from "~/components/Admin/Group/Groups.generated";
import { useOrgAdminPortfoliosQuery } from "~/components/Admin/Group/GroupsAPI.generated";
import { GroupMenu } from "~/components/Admin/Group/GroupsMenu";
import GroupsTable, {
  GROUP_ROW_FRAGMENT,
} from "~/components/Admin/Group/GroupsTable";
import { GroupRowFragment } from "~/components/Admin/Group/GroupsTable.generated";
import {
  NewGroupDialog,
  ROLE_FRAGMENT,
} from "~/components/Admin/Group/NewGroupDialog";
import OrderingContext, {
  useOrderingContext,
} from "~/components/Admin/Group/OrderingContext";
import { UpdateGroupDialog } from "~/components/Admin/Group/UpdateGroupDialog";
import { useGroupManager } from "~/components/Admin/Group/useGroupManager";
import { useUserInvitationSearch } from "~/components/Admin/User/useUserInvitationSearch";
import { PrimaryButton } from "~/components/common/buttons";
import { AddIcon } from "~/components/common/icons";
import { ThematicBreak } from "~/components/common/layout";
import { Pagination } from "~/components/common/pagination";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { WorkflowStatus } from "~/components/Projects/constants";
import SearchBar from "~/components/SearchBar";
import { SettingsPageViewerFragment } from "~/components/Settings/SettingsPage.generated";

export const ROLES_QUERY = gql`
  query OrgRoles($orgSlug: String!) {
    roles(orgSlug: $orgSlug) {
      ...OrgRole
    }
  }
  ${ROLE_FRAGMENT}
`;

export const GROUPS_QUERY = gql`
  query OrgGroups(
    $orgSlug: String!
    $ordering: String
    $search: String
    $offset: Int
    $limit: Int
  ) {
    groupPages(
      orgSlug: $orgSlug
      ordering: $ordering
      search: $search
      limit: $limit
      offset: $offset
    ) {
      count
      groups {
        ...GroupRow
      }
    }
  }
  ${GROUP_ROW_FRAGMENT}
`;

export interface GroupsProps {
  viewer: SettingsPageViewerFragment;
  orgSlug: string;
}

export const GroupsPage = ({ viewer, orgSlug }: GroupsProps) => {
  const ordering = useOrderingContext();
  return (
    <OrderingContext.Provider value={ordering}>
      <RenderGroupsPage viewer={viewer} orgSlug={orgSlug} />
    </OrderingContext.Provider>
  );
};

export const RenderGroupsPage = ({ viewer, orgSlug }: GroupsProps) => {
  const { ordering, serializeOrdering } = useContext(OrderingContext);
  const search = useUserInvitationSearch();
  const [page, setPage] = useState<number>(1);
  const [addGroup, setAddGroup] = useState<boolean>(false);
  const [pageSize] = useState(25);
  // TODO: handle when org is missing (bad url)
  const org = viewer.organizations?.filter(
    (org) => org.slug === orgSlug
  )[0] ?? { name: "" };
  const { data: groupData, loading } = useOrgGroupsQuery({
    variables: {
      orgSlug: orgSlug,
      ordering: serializeOrdering(ordering),
      search: search.debouncedSearchTerm ? search.debouncedSearchTerm : "",
      limit: pageSize,
      offset: (page - 1) * pageSize,
    },
  });
  const { data: roleData } = useOrgRolesQuery({
    variables: {
      orgSlug: orgSlug,
    },
  });
  const { data: portfolios, loading: portfoliosLoading } =
    useOrgAdminPortfoliosQuery({
      variables: { orgSlug, excludeWorkflowStatuses: [WorkflowStatus.DRAFT] },
    });

  const groupManager = useGroupManager(orgSlug);
  const renderGroupMenu = useCallback(
    (group: GroupRowFragment) => <GroupMenu {...groupManager} group={group} />,
    [groupManager]
  );
  const dialogs = (
    <>
      <UpdateGroupDialog
        orgSlug={orgSlug}
        availableRoles={roleData?.roles ?? []}
        availablePortfolios={portfolios?.orgPortfolios ?? []}
        portfoliosLoading={portfoliosLoading}
        {...groupManager}
      />
      <NewGroupDialog
        orgSlug={orgSlug}
        visible={addGroup}
        close={() => setAddGroup(false)}
        availableRoles={roleData?.roles ?? []}
        availablePortfolios={portfolios?.orgPortfolios ?? []}
        portfoliosLoading={portfoliosLoading}
      />
      <DeleteGroupDialog orgName={org.name} {...groupManager} />
    </>
  );
  return (
    <>
      <h4 className="mb-4">{org.name} Groups</h4>
      <ThematicBreak />
      <div className="d-flex align-items-start justify-content-between mb-2 mt-3">
        <div className="col-5 p-0">
          <SearchBar
            keyword={search.searchTerm}
            onChange={(e) => search.setSearchTerm(e.target.value)}
            renderInputGroupPrepend={() => (
              <div className="prepend">
                {search.searchPending ? (
                  <LoadingSpinner />
                ) : (
                  <i className="icon icon--search-dark" />
                )}
              </div>
            )}
            clearable
            onClear={() => search.clearSearch()}
          />
        </div>
        <div
          className="col d-flex justify-content-end"
          style={{ paddingRight: 0 }}
        >
          <PrimaryButton onClick={() => setAddGroup(true)} disabled={addGroup}>
            <AddIcon />
            <span>Add Group</span>
          </PrimaryButton>
        </div>
      </div>
      <GroupsTable
        groups={groupData?.groupPages?.groups ?? []}
        isLoading={loading}
        renderGroupMenu={renderGroupMenu}
      />
      <Pagination
        page={page}
        pageSize={pageSize}
        count={groupData?.groupPages?.count ?? 0}
        setPage={(page: number) => setPage(page)}
      />
      {dialogs}
    </>
  );
};
