import { ThemeProps } from "~/styles/theme";

import { ColorOptions, getColor, mapColors } from "./color";

/**
 * Helper mixins for setting foreground/text colors.
 *
 * @example
 *
 *   styled.div`
 *     ${fgColor.blue()};
 *     ${fgColor.gray500({ opacity: 0.75 })};
 *   `
 */
const fgColor = mapColors(
  (name) =>
    (options: ColorOptions = {}) =>
    (props: ThemeProps) => ({
      color: getColor(name, options, props),
    })
);

export default fgColor;
