import { gql } from "@apollo/client";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation } from "react-router";
import { Link, NavLink, matchPath, useHistory } from "react-router-dom";

import {
  DropdownMenu,
  DropdownMenuTheme,
} from "~/components/menus/DropdownMenu";
import { PortfolioTabsFragment } from "~/components/Portfolio/PortfolioTabs.generated";

export const PORTFOLIO_TABS_FRAGMENT = gql`
  fragment PortfolioTabs on Portfolio {
    id
    slug
    hasChecklist
    enableProjects
    enableQAndA
    enableReports
  }
`;

interface PortfolioTabsProps {
  portfolio: PortfolioTabsFragment;
  showOverview: boolean;
  showDocuments: boolean;
  showProjects: boolean;
  showQAndA: boolean;
  checklistsEnabled: boolean;
}

export const PortfolioTabs = ({
  portfolio,
  showDocuments,
  showOverview,
  showProjects,
  showQAndA,
  checklistsEnabled,
}: PortfolioTabsProps) => {
  const history = useHistory();
  const location = useLocation();

  const path = history.location.pathname;

  // Remember the most recent documents location for the nav link
  const isDocs = matchPath(location.pathname, "/storage/documents");
  const prevDocsLocation = useRef({ pathname: "/storage/documents" });
  const docsLocation = isDocs ? location : prevDocsLocation.current;
  useEffect(() => {
    prevDocsLocation.current = docsLocation;
  });

  /**
   * These links are located inside a dropdown menu that becomes visible when the menu button
   * at the end of the tab row is clicked.
   * Contains the following items:
   *     * Change history
   *      * Documents
   *      * Checklists (if applicable)
   */
  const dropdownMenuContent = () => {
    const menuTabs = [];
    if (showDocuments) {
      menuTabs.push(
        <Link className="dropdown-item" to={docsLocation.pathname}>
          Documents
        </Link>
      );
    }

    if (checklistsEnabled) {
      menuTabs.push(
        <Link className="dropdown-item" to="/checklist">
          Checklists
        </Link>
      );
    }

    if (showQAndA) {
      menuTabs.push(
        <Link className="dropdown-item" to="/q-and-a">
          Q&A
        </Link>
      );
    }

    return menuTabs;
  };

  /**
   * Renders the following tabs in the tabs row:
   *      * Overview
   *      * Reports
   *      * a see-more button, that on clicking displays a dropdown with:
   *          * Change history
   *          * Documents
   *          * Checklist (if applicable)
   *
   *      **IF and only IF the tabs will overview and reports**
   */
  const tabsWithMenu = () => {
    // We show menu only if at least 2 permanent tabs are being rendered:
    const willShowMenu =
      [showOverview, showProjects].filter((v) => v).length > 1;
    const nrOfCols = willShowMenu
      ? 4
      : 12 /
        [showProjects, showOverview, showDocuments, checklistsEnabled].filter(
          (v) => v
        ).length;
    // These links are immediately visible in the tabs section
    const tabsRowItems = [];
    const overviewClasses = willShowMenu
      ? "nav-item"
      : `nav-item col-lg-${nrOfCols}`;
    if (showOverview) {
      // if we won't show menu, compute the column size and add it to the overview classes:
      tabsRowItems.push(
        <li
          key="overview-tab"
          data-testid="tab-item"
          className={`${overviewClasses}`}
        >
          <Link
            to="/overview"
            className={classNames("nav-link", {
              active: path.includes("/overview/"),
            })}
          >
            Overview
          </Link>
        </li>
      );
    }
    if (showProjects) {
      tabsRowItems.push(
        <li
          key="projects-tab"
          data-testid="tab-item"
          className={`nav-item col-lg-${nrOfCols}`}
        >
          <NavLink
            className={classNames("nav-link")}
            to="/projects"
            activeClassName="active"
          >
            Projects
          </NavLink>
        </li>
      );
    }
    if (showQAndA) {
      tabsRowItems.push(
        <li
          key="checklist-tab"
          data-testid="tab-item"
          className={`nav-item col-lg-${nrOfCols}`}
        >
          <Link
            to="/q-and-a"
            className={classNames("nav-link", {
              active: path.includes("q-and-a"),
            })}
          >
            Q&A
          </Link>
        </li>
      );
    }

    if (willShowMenu) {
      const menuTabs = dropdownMenuContent();
      const menuItem = (
        <li key="menu-tab" data-testid="tab-item" className="nav-item btn-more">
          <DropdownMenu theme={DropdownMenuTheme.LIGHT_BORDERLESS}>
            {menuTabs.map((tab, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Dropdown.Item key={`dropdown-item-${i}`} as="span">
                {tab}
              </Dropdown.Item>
            ))}
          </DropdownMenu>
        </li>
      );

      tabsRowItems.push(menuItem);
    } else {
      // TODO: duplication here, consider refactoring the functions tabsWithMenu and TabsWithoutMenu:
      if (showDocuments) {
        tabsRowItems.push(
          <li
            key="documents-tab"
            data-testid="tab-item"
            className={`${overviewClasses}`}
          >
            <Link
              to={docsLocation.pathname}
              className={classNames("nav-link", {
                active: path.includes("/documents"),
              })}
            >
              Documents
            </Link>
          </li>
        );
      }

      if (checklistsEnabled) {
        tabsRowItems.push(
          <li
            key="checklist-tab"
            data-testid="tab-item"
            className={`nav-item col-lg-${nrOfCols}`}
          >
            <Link
              to="/checklist"
              className={classNames("nav-link", {
                active: path.endsWith("/checklist"),
              })}
            >
              Checklist
            </Link>
          </li>
        );
      }

      if (showQAndA) {
        tabsRowItems.push(
          <li
            key="q-and-a-tab"
            data-testid="tab-item"
            className={`nav-item col-lg-${nrOfCols}`}
          >
            <Link
              to="/q-and-a"
              className={classNames("nav-link", {
                active: path.endsWith("/q-and-a"),
              })}
            >
              Q&A
            </Link>
          </li>
        );
      }
    }
    return tabsRowItems;
  };

  /** Renders the following tabs in the tabs row:
   *      * Overview
   *      * Change history
   *      * Documents
   *      * Checklist (if applicable)
   */
  const tabsWithoutMenu = () => {
    // Compute number of bootstrap columns per each tab row item, equivalent to 12 / <nr-of-items-in-tabs-row>
    const nrOfCols =
      12 /
      [
        showProjects,
        showOverview,
        showDocuments,
        checklistsEnabled,
        showQAndA,
      ].filter((v) => v).length;
    const tabsRowItems = [];
    if (showOverview) {
      tabsRowItems.push(
        <li
          key="overview-tab"
          data-testid="tab-item"
          className={`nav-item col-lg-${nrOfCols}`}
        >
          <Link
            to="/overview"
            className={classNames("nav-link", {
              active: path.includes("/overview"),
            })}
          >
            Overview
          </Link>
        </li>
      );
    }
    if (showProjects) {
      tabsRowItems.push(
        <li
          key="projects-tab"
          data-testid="tab-item"
          className={`nav-item col-lg-${nrOfCols}`}
        >
          <Link
            to="/projects"
            className={classNames("nav-link", {
              active: path.includes("/projects"),
            })}
          >
            Projects
          </Link>
        </li>
      );
    }
    if (showDocuments) {
      tabsRowItems.push(
        <li
          key="documents-tab"
          data-testid="tab-item"
          className={`nav-item col-lg-${nrOfCols}`}
        >
          <Link
            to={docsLocation.pathname}
            className={classNames("nav-link", {
              active: path.includes("/storage/documents"),
            })}
          >
            Documents
          </Link>
        </li>
      );
    }

    if (checklistsEnabled) {
      tabsRowItems.push(
        <li
          key="checklist-tab"
          data-testid="tab-item"
          className={`nav-item col-lg-${nrOfCols}`}
        >
          <Link
            to="/checklist"
            className={classNames("nav-link", {
              active: path.endsWith("/checklist"),
            })}
          >
            Checklist
          </Link>
        </li>
      );
    }

    if (showQAndA) {
      tabsRowItems.push(
        <li
          key="q-and-a-tab"
          data-testid="tab-item"
          className={`nav-item col-lg-${nrOfCols}`}
        >
          <Link
            to="/q-and-a"
            className={classNames("nav-link", {
              active: path.endsWith("/q-and-a"),
            })}
          >
            Q&A
          </Link>
        </li>
      );
    }

    return tabsRowItems;
  };

  // If the reports have been enabled for a portfolio, then the menu looks a bit different
  return (
    <div>
      <ul className="nav nav-tabs nav-tabs-custom row" id="tabs-selector">
        {portfolio.enableReports ? tabsWithMenu() : tabsWithoutMenu()}
      </ul>
    </div>
  );
};
