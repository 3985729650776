import { StatusIcon } from "components/icons";
import PropTypes from "prop-types";
import React from "react";
import { translationsByName } from "utils/checklistStatuses";
import { gettext } from "utils/text";

import { ChecklistItemStatus } from "~/api/types.generated";

import ModalSelect from "./ModalSelect";

const StatusSelect = (props) => {
  const items = Object.values(ChecklistItemStatus).map((value) => [
    value,
    translationsByName[value],
  ]);
  return (
    <ModalSelect
      name="status"
      inputLabel={gettext("Status")}
      items={items}
      extra={<StatusIcon status={props.defaultValue} />}
      {...props}
    />
  );
};

StatusSelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default StatusSelect;
