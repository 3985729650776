import React from "react";

import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { ProjectsSearch } from "~/components/Projects/useProjectsSearch";
import SearchBar from "~/components/SearchBar";

interface SearchEventProps {
  onSearchChange?: (query: string) => void;
}

const ProjectsSearchBar = ({
  searchTerm,
  setSearchTerm,
  clearSearch,
  onSearchChange = () => {},
  searchPending,
}: ProjectsSearch & SearchEventProps) => {
  return (
    <SearchBar
      keyword={searchTerm}
      onChange={(e) => {
        setSearchTerm(e.target.value);
        onSearchChange && onSearchChange(e.target.value);
      }}
      placeholderText="Search Projects"
      renderInputGroupPrepend={() => (
        <div className="prepend">
          {searchPending ? (
            <LoadingSpinner />
          ) : (
            <i className="icon icon--search-dark" />
          )}
        </div>
      )}
      clearable
      onClear={() => clearSearch()}
    />
  );
};

export default ProjectsSearchBar;
