import { gql } from "@apollo/client/core";
import { Field, Form, Formik, FormikHelpers as FormikActions } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

import { BetterAlert as Alert } from "~/components/profile/Alert";
import { ProfileFormFragment } from "~/components/Settings/ProfileForm.generated";
import { useUpdateProfileMutation } from "~/components/Settings/ProfileForm.generated";

export const PROFILE_FORM_FRAGMENT = gql`
  fragment ProfileForm on User {
    id
    firstName
    lastName
    title
    location
    companyName
  }
`;

export const PROFILE_FORM_MUTATION_FRAGMENT = gql`
  mutation UpdateProfile(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $location: String!
    $title: String!
  ) {
    updateUserProfile(
      id: $id
      firstName: $firstName
      lastName: $lastName
      title: $title
      location: $location
    ) {
      ...ProfileForm
    }
  }

  ${PROFILE_FORM_FRAGMENT}
`;

const validationSchema = yup.object().shape({
  firstName: yup.string().required("This field is required"),
  lastName: yup.string().required("This field is required"),
  title: yup.string(),
  location: yup.string(),
});

export interface ProfileFormProps {
  user: ProfileFormFragment;
}

export const ProfileForm = ({ user }: ProfileFormProps) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [update] = useUpdateProfileMutation({
    onCompleted: () => {
      setAlertMessage("Profile updated successfully.");
    },
    onError: () => {
      setAlertMessage("Profile update failed.");
    },
  });
  const handleSubmit = (
    formFields: ProfileFormFragment,
    actions: FormikActions<ProfileFormFragment>
  ) => {
    update({
      variables: {
        id: user.id,
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        title: formFields.title,
        location: formFields.location,
      },
    }).then(() => actions.setSubmitting(false));
  };

  return (
    <div>
      <Alert message={alertMessage} />
      <Formik
        onSubmit={handleSubmit}
        initialValues={user}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleReset, errors, touched }) => (
          <Form>
            <div className="mt-3">
              <label htmlFor="firstName">First Name</label>
              <Field id="firstName" className="form-control" name="firstName" />
              {touched.firstName && errors.firstName && (
                <p className="text-danger">{errors.firstName}</p>
              )}
            </div>
            <div className="mt-3">
              <label htmlFor="lastName">Last Name</label>
              <Field id="lastName" className="form-control" name="lastName" />
              {touched.lastName && errors.lastName && (
                <p className="text-danger">{errors.lastName}</p>
              )}
            </div>
            <div className="mt-3">
              <label htmlFor="companyName">Company</label>
              <Field
                id="companyName"
                className="form-control"
                name="companyName"
                readOnly
              />
            </div>
            <div className="mt-3">
              <label htmlFor="title">Title</label>
              <Field id="title" className="form-control" name="title" />
              {touched.title && errors.title && (
                <p className="text-danger">{errors.title}</p>
              )}
            </div>
            <div className="mt-3">
              <label htmlFor="location">Location</label>
              <Field id="location" className="form-control" name="location" />
              {touched.location && errors.location && (
                <p className="text-danger">{errors.location}</p>
              )}
            </div>
            <div className="form-group mt-4">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Update profile
              </button>
              <button
                type="button"
                className="btn btn-outline-primary ml-4"
                disabled={isSubmitting}
                onClick={() => {
                  setAlertMessage("");
                  handleReset();
                }}
              >
                Reset
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
