// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { ChangePasswordFormFragment } from './ChangePasswordForm.generated';
import { UpdatePhoneNumberFormFragment } from '../forms/UpdatePhoneNumberConfirmAuth.generated';
import { UpdatePhoneNumberSendAuthFormFragment } from '../forms/UpdatePhoneNumberSendAuth.generated';
import { gql } from '@apollo/client';
import { ChangePasswordFormFragmentDoc } from './ChangePasswordForm.generated';
import { UpdatePhoneNumberFormFragmentDoc } from '../forms/UpdatePhoneNumberConfirmAuth.generated';
import { UpdatePhoneNumberSendAuthFormFragmentDoc } from '../forms/UpdatePhoneNumberSendAuth.generated';
import * as Apollo from '@apollo/client';
export type SecurityPageQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SecurityPageQuery = (
  { readonly __typename: 'Query' }
  & { readonly user?: Types.Maybe<(
    { readonly __typename: 'User' }
    & ChangePasswordFormFragment
    & UpdatePhoneNumberFormFragment
    & UpdatePhoneNumberSendAuthFormFragment
  )> }
);

export type SecurityPageViewerFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id'>
);

export const SecurityPageViewerFragmentDoc = gql`
    fragment SecurityPageViewer on User {
  id
}
    `;
export const SecurityPageDocument = gql`
    query SecurityPage($id: ID!) {
  user(id: $id) {
    ...ChangePasswordForm
    ...UpdatePhoneNumberForm
    ...UpdatePhoneNumberSendAuthForm
  }
}
    ${ChangePasswordFormFragmentDoc}
${UpdatePhoneNumberFormFragmentDoc}
${UpdatePhoneNumberSendAuthFormFragmentDoc}`;

/**
 * __useSecurityPageQuery__
 *
 * To run a query within a React component, call `useSecurityPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSecurityPageQuery(baseOptions: Apollo.QueryHookOptions<SecurityPageQuery, SecurityPageQueryVariables>) {
        return Apollo.useQuery<SecurityPageQuery, SecurityPageQueryVariables>(SecurityPageDocument, baseOptions);
      }
export function useSecurityPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SecurityPageQuery, SecurityPageQueryVariables>) {
          return Apollo.useLazyQuery<SecurityPageQuery, SecurityPageQueryVariables>(SecurityPageDocument, baseOptions);
        }
export type SecurityPageQueryHookResult = ReturnType<typeof useSecurityPageQuery>;
export type SecurityPageLazyQueryHookResult = ReturnType<typeof useSecurityPageLazyQuery>;
export type SecurityPageQueryResult = Apollo.QueryResult<SecurityPageQuery, SecurityPageQueryVariables>;