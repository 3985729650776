// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DocumentTreeQueryVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
}>;


export type DocumentTreeQuery = (
  { readonly __typename: 'Query' }
  & { readonly documentTree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & Pick<Types.DocumentTree, 'id' | 'accessLogUrl'>
  )> }
);


export const DocumentTreeDocument = gql`
    query DocumentTree($treeId: ID!) {
  documentTree(id: $treeId) {
    id
    accessLogUrl
  }
}
    `;

/**
 * __useDocumentTreeQuery__
 *
 * To run a query within a React component, call `useDocumentTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTreeQuery({
 *   variables: {
 *      treeId: // value for 'treeId'
 *   },
 * });
 */
export function useDocumentTreeQuery(baseOptions: Apollo.QueryHookOptions<DocumentTreeQuery, DocumentTreeQueryVariables>) {
        return Apollo.useQuery<DocumentTreeQuery, DocumentTreeQueryVariables>(DocumentTreeDocument, baseOptions);
      }
export function useDocumentTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentTreeQuery, DocumentTreeQueryVariables>) {
          return Apollo.useLazyQuery<DocumentTreeQuery, DocumentTreeQueryVariables>(DocumentTreeDocument, baseOptions);
        }
export type DocumentTreeQueryHookResult = ReturnType<typeof useDocumentTreeQuery>;
export type DocumentTreeLazyQueryHookResult = ReturnType<typeof useDocumentTreeLazyQuery>;
export type DocumentTreeQueryResult = Apollo.QueryResult<DocumentTreeQuery, DocumentTreeQueryVariables>;