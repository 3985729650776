import { ellipsis } from "polished";
import React, { useContext } from "react";
import styled, { css } from "styled-components";

import { FileDocIcon } from "~/components/common/icons";
import DocumentsFilterContext from "~/components/Documents/DocumentsCopilot/DocumentsFilterContext";
import BaseDropdownButton from "~/components/menus/DropdownButton";
import ItemMenu from "~/components/QAndA/components/ItemMenu";
import {
  bgColor,
  border,
  borderRadius,
  fgColor,
  focusOutline,
} from "~/styles/mixins";

const DocumentsFilter = () => {
  const { availableDocuments, selection } = useContext(DocumentsFilterContext);
  const currentDocuments = selection.values();
  return (
    <DropdownButton
      hasSelection={selection.anySelected}
      dropdown={
        <ItemMenu
          title="Select Documents to Summarize"
          anchor="bottom"
          origin="right"
          currentItems={currentDocuments}
          availableItems={availableDocuments}
          onSelectItem={selection.select}
          onSelectAll={selection.selectAll}
          onDeselectItem={selection.deselect}
          onDeselectAll={selection.deselectAll}
          zIndex={7}
        />
      }
    >
      <div>
        <FileDocIcon /> Documents: {selection.count}
      </div>
      <div>{String.fromCharCode(9662)}</div>
    </DropdownButton>
  );
};

const DropdownButton = styled(BaseDropdownButton).withConfig<{
  hasSelection: boolean;
}>({
  shouldForwardProp: (prop) => prop !== "hasSelection",
})`
  display: flex;
  margin-right: auto;
  align-content: space-between;
  ${borderRadius()}
  ${focusOutline()};
  ${bgColor.transparent()};
  ${border.transparent()};
  ${ellipsis()};
  height: 1.75rem;

  &:hover {
    ${bgColor.gray300()};
  }

  &[aria-expanded="true"] {
    ${bgColor.primary()};
    ${fgColor.white()};
  }

  ${(props) =>
    props.hasSelection &&
    css`
      ${bgColor.white()};
      ${fgColor.primary()};
      ${border.primary()};
    `}
`;

export default DocumentsFilter;
