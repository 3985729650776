import gql from "graphql-tag";
import { compact } from "lodash";
import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

import { PageContainer } from "~/components/common/layout";
import {
  OrgFeatureFlags,
  SubjectPermission,
} from "~/permissions/useViewerPermissions";

import { REPORTING_DETAIL_FRAGMENT, ReportingDetail } from "./ReportingDetail";
import { REPORTING_LIST_FRAGMENT, ReportingList } from "./ReportingList";
import { useReportingPageQuery } from "./ReportingPage.generated";

export const REPORTING_PAGE_QUERY = gql`
  query ReportingPage($orgSlug: String!) {
    ...ReportingDetail
    ...ReportingList
  }

  ${REPORTING_DETAIL_FRAGMENT}
  ${REPORTING_LIST_FRAGMENT}
`;

export interface ReportingPageProps {
  holdingCompanyPermissions: SubjectPermission;
  featureFlagsByOrg: OrgFeatureFlags;
}

export const ReportingPage = ({
  holdingCompanyPermissions,
  featureFlagsByOrg,
}: ReportingPageProps) => {
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const { data } = useReportingPageQuery({ variables: { orgSlug: orgSlug } });
  const holdcos = compact(data?.reportingHoldingCompanies);
  const singleHoldco = holdcos.length === 1;
  const areLabelsEnabled = !!(featureFlagsByOrg[orgSlug || ""] ?? {})
    .documentLabels;

  return (
    <PageContainer>
      <Switch>
        {singleHoldco && (
          <Redirect
            from={`/:orgSlug/reporting`}
            exact
            to={`/:orgSlug/reporting/${holdcos[0].slug}/reports`}
          />
        )}
        <Route exact path={`/:orgSlug/reporting`}>
          <ReportingList orgSlug={orgSlug} data={data} />
        </Route>
        <Route path={`/:orgSlug/reporting/:slug/reports/:documentId?`}>
          <ReportingDetail
            data={data}
            holdingCompanyPermissions={holdingCompanyPermissions}
            areLabelsEnabled={areLabelsEnabled}
          />
        </Route>
      </Switch>
    </PageContainer>
  );
};
