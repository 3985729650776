// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type HoldingCompanyPortfoliosFragment = (
  { readonly __typename: 'HoldingCompany' }
  & Pick<Types.HoldingCompany, 'id'>
);

export const HoldingCompanyPortfoliosFragmentDoc = gql`
    fragment HoldingCompanyPortfolios on HoldingCompany {
  id
}
    `;