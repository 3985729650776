import { gql } from "@apollo/client";
import classnames from "classnames";
import React, { useContext } from "react";

import { SecondaryButton } from "~/components/common/buttons";
import { SkeletonText } from "~/components/common/skeletons";
import { EmptyRow } from "~/components/common/tables";
import {
  BarText,
  BoundedBox,
  ButtonRow,
  Fill,
  ProgressBar,
  StyledTable,
  TableTitle,
} from "~/components/DataroomAnalytics/CompanyTable";
import OrderingContext, {
  DocumentFields,
} from "~/components/DataroomAnalytics/context/OrderingContext";
import {
  DocumentAggsDataFragment,
  useTreeDocumentAnalyticsQuery,
} from "~/components/DataroomAnalytics/DocumentTable.generated";
import { Direction, Ordering, createOrdering, reverse } from "~/utils/ordering";

const maxColor = "#479DC6";
const color = "#6CC2D5";

export const DOCUMENT_AGGS_DATA_FRAGMENT = gql`
  fragment DocumentAggsData on DocumentAggs {
    id
    document {
      name
    }
    downloadCount
    viewCount
  }
`;

export const TREE_DOCUMENT_ANALYTICS_QUERY = gql`
  query TreeDocumentAnalytics(
    $treeId: ID!
    $limit: Int
    $offset: Int
    $ordering: String
  ) {
    documentAggs(
      treeId: $treeId
      limit: $limit
      offset: $offset
      ordering: $ordering
    ) {
      count
      maxDownload
      maxView
      documents {
        ...DocumentAggsData
      }
    }
  }

  ${DOCUMENT_AGGS_DATA_FRAGMENT}
`;

type Data = DocumentAggsDataFragment;

export interface DocumentTableProps {
  treeId: string;
}

const skeletonData = [1, 2, 3, 4, 5].map(
  (n) =>
    ({
      id: `skeleton-${n}`,
      document: null,
      downloadCount: null,
      viewCount: null,
    } as Data)
);

const column = (
  name: string,
  renderHeader: () => React.ReactNode,
  cell: (row: Data, index: number) => React.ReactNode,
  fillWidth: (row: Data) => number
) => ({ name, renderHeader, cell, fillWidth });

export interface HeaderCellProps {
  name: DocumentFields;
  key: string;
  ordering: Ordering<DocumentFields> | null;
  toggleSorting: (name: DocumentFields) => void;
  children?: any;
  style?: React.CSSProperties;
  arrowLeft?: boolean;
}

export const HeaderCell = ({
  name,
  key,
  ordering,
  toggleSorting,
  children,
  style = { textAlign: "left" },
  arrowLeft = false,
}: HeaderCellProps) => (
  <th
    key={key}
    scope="col"
    style={style}
    className={classnames(
      ordering && ordering.field.startsWith(name) && "active"
    )}
  >
    <button
      className={classnames(
        "btn",
        ordering && ordering.field && "btn-link",
        ordering && ordering.field && "btn-link-hovered"
      )}
      style={{ padding: 0, textAlign: style.textAlign }}
      type="button"
      onClick={() => toggleSorting(name)}
    >
      {!arrowLeft ? children : null}
      {ordering && ordering.field && (
        <i
          className={classnames(
            "icon",
            "icon--circle-sort-up",
            ordering.field.startsWith(name) && "active",
            ordering.field.startsWith(name) &&
              ordering.direction === Direction.ASC &&
              "asc",
            ordering.field.startsWith(name) &&
              ordering.direction === Direction.DESC &&
              "desc"
          )}
        />
      )}
      {arrowLeft ? children : null}
    </button>
  </th>
);

export const DocumentTable = React.memo(function DocumentTable({
  treeId,
}: DocumentTableProps) {
  const {
    documentsOrdering: ordering,
    setDocumentsOrdering: setOrdering,
    serializeOrdering,
  } = useContext(OrderingContext);
  const toggleSorting = (field: DocumentFields) => {
    if (ordering.field === field) {
      setOrdering(reverse(ordering));
    } else {
      setOrdering(createOrdering(field));
    }
  };
  const {
    loading,
    data: requestData,
    fetchMore,
    error,
  } = useTreeDocumentAnalyticsQuery({
    variables: {
      treeId: treeId,
      ordering: serializeOrdering(ordering),
    },
  });
  const data = requestData?.documentAggs?.documents;
  const onLoadMore = () => {
    fetchMore({
      variables: {
        offset: data?.length,
      },
    });
  };
  const empty = !loading && data?.length === 0;
  const rows = data ?? skeletonData;
  const showLoadMore = !!(
    requestData?.documentAggs?.count &&
    data?.length &&
    data?.length < requestData.documentAggs.count
  );
  const columns = [
    column(
      "document-name",
      () => (
        <th key="document-name" className="name-cell">
          <div className="Name">Name</div>
        </th>
      ),
      (row, index) => {
        if (row.id.startsWith("skeleton")) return <SkeletonText />;
        return `${index + 1}. ${row.document?.name ?? "-- (Deleted)"}`;
      },
      () => 0
    ),
    column(
      "viewCount",
      () => (
        <HeaderCell
          key="viewCount"
          name={DocumentFields.VIEWS}
          ordering={ordering}
          toggleSorting={toggleSorting}
        >
          Views
        </HeaderCell>
      ),
      (row) => {
        if (row.id.startsWith("skeleton")) return <SkeletonText />;
        return `${row.viewCount}`;
      },
      (row) =>
        requestData?.documentAggs?.maxView
          ? Math.max(
              Math.floor(
                ((row.viewCount ?? 0) / requestData.documentAggs.maxView) * 100
              ),
              1
            )
          : 0
    ),
    column(
      "downloadCount",
      () => (
        <HeaderCell
          key="downloadCount"
          name={DocumentFields.DOWNLOADS}
          ordering={ordering}
          toggleSorting={toggleSorting}
        >
          Downloads
        </HeaderCell>
      ),
      (row) => {
        if (row.id.startsWith("skeleton")) return <SkeletonText />;
        return `${row.downloadCount}`;
      },
      (row) =>
        requestData?.documentAggs?.maxDownload
          ? Math.max(
              Math.floor(
                ((row.downloadCount ?? 0) /
                  requestData.documentAggs.maxDownload) *
                  100
              ),
              1
            )
          : 0
    ),
  ];

  const table = (
    <StyledTable>
      <thead>
        <tr>{columns.map(({ renderHeader }) => renderHeader())}</tr>
      </thead>
      <tbody>
        {!error &&
          rows.map((row, index) => (
            <tr key={row?.id}>
              {columns.map(({ name, cell, fillWidth }) => {
                if (name.endsWith("name"))
                  return (
                    <td key={name} className={`${name}-cell`}>
                      <span>{cell(row, index)}</span>
                    </td>
                  );
                const width = fillWidth(row);
                return (
                  <td key={name} className={`${name}-cell`}>
                    <div className={`bar-cell`}>
                      <BarText>{cell(row, index)}</BarText>
                      <ProgressBar>
                        <Fill
                          width={width}
                          color={width === 100 ? maxColor : color}
                        />
                      </ProgressBar>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
      </tbody>
    </StyledTable>
  );

  return (
    <BoundedBox>
      <TableTitle>Documents</TableTitle>
      {table}
      {showLoadMore && (
        <ButtonRow>
          <SecondaryButton onClick={onLoadMore}>Load more</SecondaryButton>
        </ButtonRow>
      )}
      {!error && empty && <EmptyRow>No data found.</EmptyRow>}
      {error && (
        <EmptyRow>
          An error occured while loading the data. Please check again later.
        </EmptyRow>
      )}
    </BoundedBox>
  );
});
