// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { FundEntityOverview_HoldingCompany_Fragment, FundEntityOverview_Portfolio_Fragment } from '../Overview/OverviewPage.generated';
import { QAndASubject_HoldingCompany_Fragment, QAndASubject_Portfolio_Fragment } from '../QAndA/QAndA.generated';
import { DealRoomCard_HoldingCompany_Fragment, DealRoomCard_Portfolio_Fragment } from '../DealRoom/DealRoomCard.generated';
import { gql } from '@apollo/client';
import { FundEntityOverviewFragmentDoc } from '../Overview/OverviewPage.generated';
import { QAndASubjectFragmentDoc } from '../QAndA/QAndA.generated';
import { DealRoomCardFragmentDoc } from '../DealRoom/DealRoomCard.generated';
import * as Apollo from '@apollo/client';
export type PortfolioBodyFragment = (
  { readonly __typename: 'Portfolio' }
  & Pick<Types.Portfolio, 'id' | 'name' | 'slug' | 'hasChecklist' | 'enableReports' | 'enableQAndA' | 'enableProjects'>
  & { readonly org: (
    { readonly __typename: 'Organization' }
    & Pick<Types.Organization, 'slug'>
  ), readonly dealManager?: Types.Maybe<(
    { readonly __typename: 'User' }
    & Pick<Types.User, 'fullName' | 'email'>
  )>, readonly documentsTree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & Pick<Types.DocumentTree, 'id'>
  )>, readonly reportsTree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & Pick<Types.DocumentTree, 'id'>
  )> }
  & FundEntityOverview_Portfolio_Fragment
  & QAndASubject_Portfolio_Fragment
);

export type ViewPortfolioMutationVariables = Types.Exact<{
  portfolioId: Types.Scalars['ID'];
}>;


export type ViewPortfolioMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createRecentlyVisitedPortfolio?: Types.Maybe<(
    { readonly __typename: 'HoldingCompany' }
    & DealRoomCard_HoldingCompany_Fragment
  ) | (
    { readonly __typename: 'Portfolio' }
    & DealRoomCard_Portfolio_Fragment
  )> }
);

export const PortfolioBodyFragmentDoc = gql`
    fragment PortfolioBody on Portfolio {
  id
  name
  slug
  org {
    slug
  }
  hasChecklist
  enableReports
  enableQAndA
  enableProjects
  dealManager {
    fullName
    email
  }
  documentsTree {
    id
  }
  reportsTree {
    id
  }
  ...FundEntityOverview
  ...QAndASubject
}
    ${FundEntityOverviewFragmentDoc}
${QAndASubjectFragmentDoc}`;
export const ViewPortfolioDocument = gql`
    mutation ViewPortfolio($portfolioId: ID!) {
  createRecentlyVisitedPortfolio(id: $portfolioId) {
    ...DealRoomCard
  }
}
    ${DealRoomCardFragmentDoc}`;
export type ViewPortfolioMutationFn = Apollo.MutationFunction<ViewPortfolioMutation, ViewPortfolioMutationVariables>;

/**
 * __useViewPortfolioMutation__
 *
 * To run a mutation, you first call `useViewPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewPortfolioMutation, { data, loading, error }] = useViewPortfolioMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useViewPortfolioMutation(baseOptions?: Apollo.MutationHookOptions<ViewPortfolioMutation, ViewPortfolioMutationVariables>) {
        return Apollo.useMutation<ViewPortfolioMutation, ViewPortfolioMutationVariables>(ViewPortfolioDocument, baseOptions);
      }
export type ViewPortfolioMutationHookResult = ReturnType<typeof useViewPortfolioMutation>;
export type ViewPortfolioMutationResult = Apollo.MutationResult<ViewPortfolioMutation>;
export type ViewPortfolioMutationOptions = Apollo.BaseMutationOptions<ViewPortfolioMutation, ViewPortfolioMutationVariables>;