import React from "react";

import { PageContainer } from "~/components/common/layout";
import { PrimaryPanel } from "~/components/common/panels";
import { PrimaryHeading, Text } from "~/components/common/text";
import { ScrollToTop } from "~/components/utils/ScrollToTop";

export const About = () => {
  return (
    <PageContainer>
      <ScrollToTop />
      <PrimaryHeading>About</PrimaryHeading>
      <PrimaryPanel>
        <Text>
          <b>Dock.energy</b>
        </Text>
        <Text>Smart business infrastructure for clean energy</Text>
        <Text>
          Dock was conceived and built to achieve CleanCapital’s innovative
          vision of simplifying, accelerating, and reducing costs for clean
          energy transactions. Using Dock, CleanCapital grew from a startup to
          one of the top ten commercial solar asset owners in the country. More
          than $1 billion in transactions later, Dock is spun of as an
          independent investment grade enterprise cloud platform. In addition to
          the user-friendly data room that our customers currently enjoy, Dock
          has a rich roadmap in place to expand Dock’s capabilities, allowing
          you to manage and grow your business with smart workflows and data
          management.
        </Text>
        <Text>
          If your organization would benefit from the Dock platform, please
          reach us at <a href="mailto:hello@dock.energy">hello@dock.energy</a>{" "}
          to schedule a demo!
        </Text>
      </PrimaryPanel>
    </PageContainer>
  );
};
