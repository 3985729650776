import { ApolloClient, useApolloClient } from "@apollo/client";
import axios, { CancelTokenSource } from "axios";
import { chunk, compact, partition, sortBy } from "lodash";
import { useState } from "react";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { ConfirmUploadInput, FileInput } from "~/api/types.generated";
import useForceUpdate from "~/hooks/useForceUpdate";
import { FileWithPath } from "~/vendored/file-selector";

import {
  DocumentTree_InfoFragment,
  Document_BreadcrumbsFragment,
  DocumentsConfirmUploadsDocument,
  DocumentsConfirmUploadsMutation,
  DocumentsConfirmUploadsMutationVariables,
  DocumentsCreateFoldersDocument,
  DocumentsCreateFoldersMutation,
  DocumentsCreateFoldersMutationVariables,
  DocumentsPresignUploadsDocument,
  DocumentsPresignUploadsMutation,
  DocumentsPresignUploadsMutationVariables,
} from "../DocumentsAPI.generated";

export enum Status {
  WAITING,
  UPLOADING,
  CONFIRMING,
  DONE,
  CANCELED,
  FAILED,
  UNCONFIRMED,
}

export interface Task {
  path: string;
  file: FileWithPath;
  status: Status;
  progress: number;
  treeName?: string;
  url?: string;
  version?: string;
  document?: Document_BreadcrumbsFragment;
  cancelSource?: CancelTokenSource;
}

export interface Batch {
  canceled: boolean;
  type: "folder" | "file";
  tree: DocumentTree_InfoFragment;
  parent: Document_BreadcrumbsFragment;
  tasks: string[];
}

export class UploadManager {
  tasks: Record<string, Task> = {};
  complete: Batch[] = [];
  queued: Batch[] = [];
  open = false;
  busy = false;

  constructor(
    private readonly client: ApolloClient<{}>,
    private readonly onUpdate: () => void
  ) {}

  close() {
    if (this.busy) return;
    this.tasks = {};
    this.complete = [];
    this.queued = [];
    this.open = false;
    this.onUpdate();
  }

  cancel() {
    const currentBatch = this.queued[0];
    if (!currentBatch) return;

    for (const taskId of currentBatch.tasks) {
      this.tasks[taskId]?.cancelSource?.cancel();
    }

    for (const task of Object.values(this.tasks)) {
      if (task.status !== Status.FAILED && task.status !== Status.DONE) {
        task.status = Status.CANCELED;
      }
    }

    for (const batch of this.queued) {
      batch.canceled = true;
      this.complete.push(batch);
    }

    this.queued = [];
    this.onUpdate();
  }

  queueUploads = (
    tree: DocumentTree_InfoFragment,
    parent: Document_BreadcrumbsFragment,
    entries: FileWithPath[]
  ) => {
    // Store a reference to the parent folder
    const parentPath = getPathForDocument(tree, parent);
    this.tasks[parentPath] = { document: parent } as Task;

    // Sort all the files/folders by path
    const sortedEntries = sortBy(entries, "path");

    // We need to create folders before uploading files
    const [folders, files] = partition(sortedEntries, "isDirectory");

    // 50 folders at a time
    const folderBatches = chunk(folders, 50).map((folders) =>
      createBatch("folder", tree, parent, folders)
    );

    // 20 files at a time
    const fileBatches = chunk(files, 20).map((files) =>
      createBatch("file", tree, parent, files)
    );

    // Queue up the batches
    for (const [batch, tasks] of folderBatches) {
      for (const task of tasks) {
        this.tasks[task.path] = task;
      }
      this.queued.push(batch);
    }
    for (const [batch, tasks] of fileBatches) {
      for (const task of tasks) {
        this.tasks[task.path] = task;
      }
      this.queued.push(batch);
    }

    this.open = true;
    this.onUpdate();
    if (!this.busy) {
      this.startUploads();
    }
  };

  async startUploads() {
    // Lock so we only have one upload queue running
    if (this.busy) return;
    this.busy = true;
    this.onUpdate();

    // Upload each batch, one at a time
    while (this.queued.length > 0) {
      const batch = this.queued[0];
      if (batch.type === "folder") {
        await this.uploadFolderBatch();
      } else {
        await this.uploadFileBatch();
      }
    }

    this.busy = false;
    this.onUpdate();
  }

  async uploadFolderBatch() {
    const batch = this.queued[0];
    const tasks = batch.tasks.map((path) => this.tasks[path]);

    // Mark all the batch tasks as uploading
    for (const task of tasks) {
      if (task.status !== Status.CANCELED) {
        task.status = Status.UPLOADING;
      }
    }
    this.onUpdate();

    // Create all non-canceled folders
    const { data, errors } = await this.client.mutate<
      DocumentsCreateFoldersMutation,
      DocumentsCreateFoldersMutationVariables
    >({
      mutation: DocumentsCreateFoldersDocument,
      variables: {
        treeId: batch.tree.id,
        parentId: batch.parent?.id,
        folderPaths: tasks
          .filter((task) => task.status === Status.UPLOADING)
          .map((task) => task.file.path ?? ""),
      },
    });

    if (batch.canceled) return;

    // Handle GraphQL error response
    if (errors) {
      for (const task of tasks) {
        if (task.status === Status.UPLOADING) {
          task.status = Status.FAILED;
        }
      }
      this.complete.push(batch);
      this.queued.shift();
      this.onUpdate();
      return;
    }

    const results = compact(data?.createFolders ?? []);
    for (const result of results) {
      const path = getPathForDocument(batch.tree, result);
      this.tasks[path].document = result;
    }

    // Mark all the batch tasks as done
    for (const task of tasks) {
      if (task.status === Status.UPLOADING) {
        task.status = Status.DONE;
        task.progress = 1;
      }
    }
    this.complete.push(batch);
    this.queued.shift();
    this.onUpdate();
  }

  async uploadFileBatch() {
    const batch = this.queued[0];
    const tasks = batch.tasks.map((path) => this.tasks[path]);

    const fileInput: FileInput[] = [];
    for (const task of tasks) {
      if (task.status === Status.CANCELED) {
        continue;
      }

      const folderPath = task.path.split("/").slice(0, -1).join("/");
      const parentId = this.tasks[folderPath]?.document?.id;

      if (parentId) {
        task.status = Status.UPLOADING;
        fileInput.push({
          name: task.file.name,
          size: task.file.size,
          parentId,
        });
      } else {
        task.status = Status.FAILED;
      }
    }

    this.onUpdate();

    // Pre-sign the uploads
    const { data: presignData, errors: presignErrors } =
      await this.client.mutate<
        DocumentsPresignUploadsMutation,
        DocumentsPresignUploadsMutationVariables
      >({
        mutation: DocumentsPresignUploadsDocument,
        variables: {
          treeId: batch.tree.id,
          files: fileInput,
        },
      });

    if (batch.canceled) return;

    // Handle GraphQL error response
    if (presignErrors || !presignData) {
      for (const task of tasks) {
        if (task.status !== Status.CANCELED) {
          task.status = Status.FAILED;
        }
      }
      this.complete.push(batch);
      this.queued.shift();
      this.onUpdate();
      return;
    }

    // Store the created documents by tree & path
    const results = compact(presignData.createPresignedFiles ?? []);
    for (const result of results) {
      if (result.file) {
        const path = getPathForDocument(batch.tree, result.file);
        this.tasks[path].document = result.file;
        this.tasks[path].url = result.url;
        this.tasks[path].version = result.version;
      }
    }

    /** Process a single file upload, updating the file status */
    const request = async (task: Task) => {
      if (task.status === Status.CANCELED) return;
      if (batch.canceled) return;

      task.cancelSource = axios.CancelToken.source();

      try {
        if (!task.url) throw new Error();
        await axios.put(task.url, task.file, {
          headers: { "Content-Type": task.file.type },
          cancelToken: task.cancelSource.token,
          onUploadProgress: (event) => {
            task.progress = event.loaded / event.total;
            this.onUpdate();
          },
        });
        task.status = Status.CONFIRMING;
        task.progress = 1;
      } catch (error) {
        if (axios.isCancel(error)) {
          task.status = Status.CANCELED;
        } else {
          task.status = Status.FAILED;
        }
      }

      this.onUpdate();
    };

    /**
     * Start the upload requests. We will use 5 concurrent uploads per https://stackoverflow.com/a/985704
     * See: https://www.learnrxjs.io/learn-rxjs/operators/transformation/mergemap
     */
    const concurrent = 5;
    await from(tasks).pipe(mergeMap(request, concurrent)).toPromise();

    if (batch.canceled) return;

    /* Confirm the finished uploads */
    const uploads = tasks
      .filter(
        (t) => t.status === Status.CONFIRMING && t.version && t.document?.id
      )
      .map<ConfirmUploadInput>((t) => ({
        version: t.version!,
        documentId: t.document!.id,
      }));

    try {
      const { data: confirmData, errors: confirmErrors } =
        await this.client.mutate<
          DocumentsConfirmUploadsMutation,
          DocumentsConfirmUploadsMutationVariables
        >({
          mutation: DocumentsConfirmUploadsDocument,
          variables: {
            treeId: batch.tree.id,
            uploads,
          },
        });
      // Handle GraphQL error response
      if (confirmErrors || !confirmData) {
        for (const task of tasks) {
          if (task.status === Status.CONFIRMING) {
            task.status = Status.FAILED;
          }
        }
        this.complete.push(batch);
        this.queued.shift();
        this.onUpdate();
        return;
      }

      for (const doc of confirmData.confirmUploads.files) {
        const path = getPathForDocument(batch.tree, doc);
        const task = this.tasks[path];
        if (task) {
          task.status = Status.DONE;
        }
      }

      for (const failedId of confirmData.confirmUploads.failedIds) {
        const task = tasks.find((t) => t.document?.id === failedId);
        if (task) {
          task.status = Status.FAILED;
        }
      }
    } catch (error) {
      // TODO: we can get long waits here doing confirms (server does a head on each document)
      // A better solution would be to send individual api requests to confirm as soon as they are uploaded
      for (const task of tasks) {
        if (task.status === Status.CONFIRMING) {
          task.status = Status.UNCONFIRMED;
        }
      }
      this.complete.push(batch);
      this.queued.shift();
      this.onUpdate();
      return;
    }
    if (batch.canceled) return;

    this.complete.push(batch);
    this.queued.shift();
    this.onUpdate();
  }
}

export const useUploadManager = () => {
  const client = useApolloClient();
  const forceUpdate = useForceUpdate();
  const [manager] = useState(() => new UploadManager(client, forceUpdate));

  return manager;
};

const createBatch = (
  type: "folder" | "file",
  tree: DocumentTree_InfoFragment,
  parent: Document_BreadcrumbsFragment,
  files: FileWithPath[]
): [Batch, Task[]] => {
  const tasks: Task[] = files.map((file) => ({
    path: getPathForFile(tree, parent, file),
    file: file,
    treeName: tree.name ?? "",
    status: Status.WAITING,
    progress: 0,
  }));

  const batch = {
    canceled: false,
    type,
    tree,
    parent,
    tasks: tasks.map((t) => t.path),
  };

  return [batch, tasks];
};

const getPathForFile = (
  tree: DocumentTree_InfoFragment,
  parent: Document_BreadcrumbsFragment,
  file: FileWithPath
) => {
  let path = [
    tree.id,
    ...(parent?.ancestors?.map((a) => a?.name) ?? []),
    parent?.name,
    ...(file.path?.slice(1) ?? "").split("/"),
  ].join("/");

  return path;
};

const getPathForDocument = (
  tree: DocumentTree_InfoFragment,
  document: Document_BreadcrumbsFragment
) => {
  let path = [
    tree.id,
    ...(document.ancestors?.map((a) => a?.name ?? "") ?? []),
    document.name,
  ].join("/");

  return path;
};
