// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type HoldingCompanyTabsFragment = (
  { readonly __typename: 'HoldingCompany' }
  & Pick<Types.HoldingCompany, 'id' | 'enableReports' | 'enablePortfolios' | 'enableQAndA'>
  & { readonly documentsTree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & Pick<Types.DocumentTree, 'id'>
  )>, readonly reportsTree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & Pick<Types.DocumentTree, 'id'>
  )> }
);

export const HoldingCompanyTabsFragmentDoc = gql`
    fragment HoldingCompanyTabs on HoldingCompany {
  id
  documentsTree {
    id
  }
  reportsTree {
    id
  }
  enableReports
  enablePortfolios
  enableQAndA
}
    `;