import { createContext, useMemo, useState } from "react";

import { Direction, Ordering, createOrdering } from "~/utils/ordering";

export enum Field {
  USER_NAME = "user_name",
  COMPANY_NAME = "existing_user__company__name",
  EMAIL = "email",
  ACCEPTED = "accepted",
}

export interface OrderingContextValue {
  ordering: Ordering<Field>;
  setOrdering: (ordering: Ordering<Field>) => void;
  serializeOrdering: (ordering: Ordering<Field>) => string;
}

export const useOrderingContext = (): OrderingContextValue => {
  const [ordering, setOrdering] = useState(createOrdering(Field.USER_NAME));

  return useMemo(() => {
    const serializeOrdering = (orderObj: Ordering<Field>): string => {
      // Serialize ordering into syntax DRF wants
      if (orderObj.field === "user_name") {
        return orderObj.direction === Direction.ASC
          ? "existing_user__last_name,existing_user__first_name"
          : "-existing_user__last_name,-existing_user__first_name";
      }

      return orderObj.direction === Direction.ASC
        ? orderObj.field
        : `-${orderObj.field}`;
    };
    return { ordering, setOrdering, serializeOrdering };
  }, [ordering, setOrdering]);
};

const OrderingContext = createContext<OrderingContextValue>(null as any);

export default OrderingContext;
