import classNames from "classnames";
import React from "react";
import styled from "styled-components";

import { Skeleton } from "~/components/common/skeletons";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import SearchBar from "~/components/SearchBar";

import { Document_InfoFragment } from "./DocumentsAPI.generated";

export interface DocumentsSearchBarProps {
  track: DocumentsEventTracking;
  document?: null | Document_InfoFragment;
  isLoading: boolean;
  isRoot: boolean;
  searchAll: boolean;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  toggleSearchAll: (searchAll?: boolean) => void;
  clearSearch: () => void;
}

export const DocumentsSearchBar = React.memo(function DocumentsSearchBar(
  props: DocumentsSearchBarProps
) {
  const {
    track,
    document,
    isLoading,
    isRoot,
    searchAll,
    searchTerm,
    setSearchTerm,
    toggleSearchAll,
    clearSearch,
  } = props;

  const prepend = (
    <div className="prepend">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <i className="icon icon--search-dark" />
      )}
      {!isRoot &&
        (document?.name ? (
          <button
            type="button"
            className={classNames("btn text-truncate ml-2 p-1", {
              "not-in-folder": searchAll,
              "pr-4": !searchAll,
            })}
            onClick={() => {
              track.searchAllToggle();
              toggleSearchAll();
            }}
          >
            <i className="icon icon--folder mr-0" />
            {!searchAll && <span className="text ml-2">{document.name}</span>}
          </button>
        ) : (
          <SkeletonToggle />
        ))}
    </div>
  );

  return (
    <SearchBar
      keyword={searchTerm}
      onChange={(event) => {
        track.search(event.currentTarget.value);
        setSearchTerm(event.currentTarget.value);
      }}
      renderInputGroupPrepend={() => prepend}
      clearable
      onClear={() => {
        track.searchClear();
        clearSearch();
      }}
      placeholderText={searchAll ? "Search all documents" : "Search"}
    />
  );
});

const SkeletonToggle = styled(Skeleton)`
  height: 1.5rem;
  width: 5rem;
  margin-left: 0.5rem;
`;
