import { TextField } from "@material-ui/core";
import React, { ComponentPropsWithRef } from "react";
import styled from "styled-components";

import { focusOutline } from "~/styles/mixins";
import { blue, gray300, gray700, gray900 } from "~/styles/theme/color";
import { borderRadius } from "~/styles/theme/common";

export interface CheckboxProps extends ComponentPropsWithRef<"input"> {
  indeterminate?: boolean;
}

/** A checkbox component that allows declarative updates of the indeterminate property */
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox({ indeterminate, ...rest }, forwardedRef) {
    const ref = React.useRef<HTMLInputElement | null>(null);

    // Synchronize the forwarded ref with our own internal ref
    React.useEffect(() => {
      if (typeof forwardedRef === "function") {
        forwardedRef(ref.current);
      } else if (forwardedRef) {
        forwardedRef.current = ref.current;
      }
    });

    // Imperatively update the indeterminate propery to sync with the prop
    React.useEffect(() => {
      if (ref.current) {
        ref.current.indeterminate = indeterminate ?? false;
      }
    }, [indeterminate]);

    return <input type="checkbox" ref={ref} {...rest} />;
  }
);

export const InputLabel = styled.label`
  display: block;
  font-size: 0.875rem;
  color: ${gray700};
`;

export const TextInput = styled.input`
  ${focusOutline()};
  border-radius: ${borderRadius};
  border: 1px solid ${gray300};
  height: 2rem;
  flex: 1;
  padding: 1px 8px;

  &:focus {
    border: 1px solid ${blue};
  }

  ${InputLabel} > & {
    width: 100%;
  }
`;

export const StyledTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: gray900,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: gray300,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: gray300,
    },
    "&:hover fieldset": {
      borderColor: gray300,
    },
    "&.Mui-focused fieldset": {
      borderColor: gray300,
    },
  },
});
