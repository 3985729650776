import React, {
  Children,
  KeyboardEvent,
  cloneElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { isElement } from "react-is";
import styled from "styled-components";

import { GetPropsWithoutRef } from "~/utils/types";

import DropdownContext from "./DropdownContext";
import SelectMenuContext from "./SelectMenuContext";

export interface SelectMenuProps<T>
  extends Omit<GetPropsWithoutRef<"ul">, "onSelect"> {
  closeOnSelect?: boolean;
  isSelected: (value: T) => boolean;
  onSelect: (value: T) => unknown;
  onDeselect: (value: T) => unknown;
}

const SelectMenu = <T extends any>({
  children,
  closeOnSelect = false,
  isSelected,
  onSelect,
  onDeselect,
  ...rest
}: SelectMenuProps<T>) => {
  const dropdown = useContext(DropdownContext);
  const [focusIndex, setFocusIndex] = useState(0);

  const setAriaType = dropdown?.setAriaType;

  let options = children;

  useEffect(() => {
    setAriaType?.("menu");
  }, [setAriaType]);

  // While closing, don't change the menu contents
  const savedOptions = useRef(options);
  useEffect(() => {
    if (dropdown?.isOpen) savedOptions.current = options;
  });
  if (!dropdown?.isOpen) options = savedOptions.current;

  const onSelectOption = useCallback(
    (_: any, value: T) => {
      if (isSelected(value)) {
        onDeselect(value);
      } else {
        onSelect(value);
      }
    },
    [isSelected, onSelect, onDeselect]
  );

  // Provide each menu item with its index
  const optionArray = Children.toArray(options)
    .filter(isElement)
    .map((item, index) =>
      cloneElement(item, { index, onSelect: onSelectOption })
    );

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case "ArrowUp":
        event.preventDefault();
        setFocusIndex((index) => Math.max(0, index - 1));
        break;

      case "ArrowDown":
        event.preventDefault();
        setFocusIndex((index) => Math.min(optionArray.length - 1, index + 1));
        break;

      default:
        break;
    }
  };

  return (
    <SelectMenuContext.Provider
      value={{ focusIndex, setFocusIndex, closeOnSelect, isSelected }}
    >
      <Menu role="listbox" tabIndex={-1} onKeyDown={handleKeyDown} {...rest}>
        {optionArray}
      </Menu>
    </SelectMenuContext.Provider>
  );
};

const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export default SelectMenu;
