import { CSSObject } from "styled-components";

import { ThemeProps } from "~/styles/theme";

export type Corner = "top-right" | "bottom-right" | "bottom-left" | "top-left";
export type Side = "top" | "right" | "bottom" | "left";
export type Direction = Corner | Side;

const CORNERS: Record<Direction, Corner[]> = {
  "top-right": ["top-right"],
  "bottom-right": ["bottom-right"],
  "bottom-left": ["bottom-left"],
  "top-left": ["top-left"],
  top: ["top-right", "top-left"],
  right: ["top-right", "bottom-right"],
  bottom: ["bottom-right", "bottom-left"],
  left: ["bottom-left", "top-left"],
};

/**
 * Mixin for setting a standard border radius on one, many, or all corners/sides.
 *
 * @example
 *
 *   styled.div`
 *     ${borderRadius()};
 *     ${borderRadius('top-left', 'top-right')};
 *     ${borderRadius('top')};
 *   `
 */
const borderRadius =
  (...directions: Direction[]) =>
  (props: ThemeProps): CSSObject => {
    const radius = props.theme.common.borderRadius;

    if (directions.length === 0) {
      return { borderRadius: radius };
    }

    const corners = directions.reduce<Corner[]>(
      (corners, direction) => [...corners, ...CORNERS[direction]],
      []
    );

    return corners.reduce<CSSObject>(
      (styles, corner) => ({ ...styles, [`border-${corner}-radius`]: radius }),
      {}
    );
  };

export default borderRadius;
