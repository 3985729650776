import { gql } from "@apollo/client";
import React, { useEffect, useRef } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { HoldingCompanyTabsFragment } from "~/components/HoldingCompany/HoldingCompanyTabs.generated";
import NavTab from "~/components/navigation/NavTab";
import NavTabs from "~/components/navigation/NavTabs";

export const HOLDING_COMPANY_TABS_FRAGMENT = gql`
  fragment HoldingCompanyTabs on HoldingCompany {
    id
    documentsTree {
      id
    }
    reportsTree {
      id
    }
    enableReports
    enablePortfolios
    enableQAndA
  }
`;

interface HoldingCompanyTabsProps {
  holdco: HoldingCompanyTabsFragment;
  canViewQAndA: boolean;
}

export const HoldingCompanyTabs = ({
  holdco,
  canViewQAndA,
}: HoldingCompanyTabsProps) => {
  const location = useLocation();

  // Remember the most recent documents/reports location for the nav link
  const isDocs = matchPath(location.pathname, "/storage/documents");
  const isReports = matchPath(location.pathname, "/storage/reports");
  const prevDocsLocation = useRef({ pathname: "/storage/documents" });
  const prevReportsLocation = useRef({ pathname: "/storage/reports" });
  const docsLocation = isDocs ? location : prevDocsLocation.current;
  const reportsLocation = isReports ? location : prevReportsLocation.current;
  useEffect(() => {
    prevDocsLocation.current = docsLocation;
    prevReportsLocation.current = reportsLocation;
  });

  return (
    <NavTabs>
      <NavTab to="/overview" activeClassName="active">
        Overview
      </NavTab>
      {holdco.enablePortfolios && (
        <NavTab to="/portfolios" activeClassName="active">
          Portfolios
        </NavTab>
      )}
      <NavTab
        to={docsLocation}
        activeClassName="active"
        // isActive={location.pathname.includes("/storage/documents")}
      >
        Documents
      </NavTab>
      {holdco.enableReports && (
        <NavTab
          to={reportsLocation}
          activeClassName="active"
          // isActive={location.pathname.includes("/storage/reports")}
        >
          Reports
        </NavTab>
      )}
      {canViewQAndA && holdco.enableQAndA && (
        <NavTab to="/q-and-a">Q&amp;A</NavTab>
      )}
    </NavTabs>
  );
};
