// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from './api/types.generated';

import { gql } from '@apollo/client';
export type FooterViewerFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'isSuperUser'>
);

export const FooterViewerFragmentDoc = gql`
    fragment FooterViewer on User {
  id
  isSuperUser
}
    `;