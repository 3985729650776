import { ellipsis } from "polished";
import React, { useContext } from "react";
import styled, { css } from "styled-components";

import BaseDropdownButton from "~/components/menus/DropdownButton";
import ItemMenu from "~/components/QAndA/components/ItemMenu";
import {
  bgColor,
  border,
  borderRadius,
  fgColor,
  focusOutline,
} from "~/styles/mixins";

import PortfolioFilterContext from "../context/PortfolioFilterContext";

const PortfolioFilter = () => {
  const { availablePortfolios, selection } = useContext(PortfolioFilterContext);

  const currentPortfolios = selection.values();
  const allSelected = selection.allSelected || !selection.anySelected;
  const oneSelected = !allSelected && selection.count === 1;

  return (
    <DropdownButton
      hasSelection={selection.anySelected}
      dropdown={
        <ItemMenu
          title="Portfolio"
          anchor="bottom right"
          origin="top right"
          currentItems={currentPortfolios}
          availableItems={availablePortfolios}
          onSelectItem={selection.select}
          onSelectAll={selection.selectAll}
          onDeselectItem={selection.deselect}
          onDeselectAll={selection.deselectAll}
        />
      }
    >
      Portfolio:{" "}
      {allSelected
        ? "All"
        : oneSelected
        ? currentPortfolios[0].name
        : "Multiple"}{" "}
      {String.fromCharCode(9662)}
    </DropdownButton>
  );
};

const DropdownButton = styled(BaseDropdownButton).withConfig<{
  hasSelection: boolean;
}>({
  shouldForwardProp: (prop) => prop !== "hasSelection",
})`
  ${borderRadius()}
  ${focusOutline()};
  ${bgColor.transparent()};
  ${border.transparent()};
  ${ellipsis()};
  height: 1.75rem;
  margin-left: 0.5rem;

  &:hover {
    ${bgColor.gray300()};
  }

  &[aria-expanded="true"] {
    ${bgColor.primary()};
    ${fgColor.white()};
  }

  ${(props) =>
    props.hasSelection &&
    css`
      ${bgColor.white()};
      ${fgColor.primary()};
      ${border.primary()};
    `}
`;

export default PortfolioFilter;
