import mapboxgl from "mapbox-gl";
import * as PropTypes from "prop-types";
import { geoJsonType } from "propTypes/portfolios";
/* eslint-disable no-return-assign */
import React from "react";
import { HAVELOCK_BLUE, SUSHI } from "utils/colors";
import { gettext } from "utils/text";

export const NetYieldChart = () => (
  <div className="row mb-4">
    <div className="col-lg-12">
      <div className="google-chart-wrapper">
        <div id="net-yield-chart" className="google-chart-container" />
      </div>
    </div>
  </div>
);

export const SubpageMedia = ({ id }) => (
  <img src={`/subpage-media/${id}/`} alt={`subpage-${id}`} />
);

SubpageMedia.propTypes = {
  id: PropTypes.string,
};

SubpageMedia.defaultProps = {
  id: null,
};

export const HoldCoSubpageMedia = ({ id }) => (
  <img src={`/holding-company-subpage-media/${id}/`} alt={`hc-subpage-${id}`} />
);

HoldCoSubpageMedia.propTypes = {
  id: PropTypes.string,
};

HoldCoSubpageMedia.defaultProps = {
  id: null,
};

export const FactoidBlock = ({ children }) => (
  <div className="row">{children}</div>
);

FactoidBlock.propTypes = {
  children: PropTypes.node,
};

FactoidBlock.defaultProps = {
  children: null,
};

export const Factoid = ({ accent, header, text }) => (
  <div key={header + text} className="col-lg-6 factoid">
    <div className={`info-block info-block--${accent}`}>
      <div className="title">{header}</div>
      <div className="description">{text}</div>
    </div>
  </div>
);

Factoid.propTypes = {
  accent: PropTypes.oneOf([
    "blue-accent",
    "green-accent",
    "purple-accent",
    "yellow-accent",
  ]).isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

// noinspection JSUnresolvedVariable
mapboxgl.accessToken = DJ_CONST.MAPBOX_ACCESS_TOKEN;

export class Map extends React.Component {
  componentDidMount() {
    // Carefully calculated coordinates and zoom value to place the initial view
    // to the center of USA with most of the states visible
    // TODO: compute the center of the geo points like we do in the Map component.
    const lng = -97.48;
    const lat = 36.78;
    const zoom = 3.37;

    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/light-v9",
      center: [lng, lat],
      zoom,
    });

    // Add zoom buttons and a compass
    // https://www.mapbox.com/mapbox-gl-js/api/#navigationcontrol
    map.addControl(new mapboxgl.NavigationControl());

    // Add a toggle to go full screen mode
    // https://www.mapbox.com/mapbox-gl-js/api/#fullscreencontrol
    map.addControl(new mapboxgl.FullscreenControl());

    // Hold the DOM elements of a svg group for each marker, which is used to style the active marker
    const markerSvgGroups = {};

    this.props.projectsGeoJson.features.forEach((feature, index) => {
      // Add markers indicating the location of each project
      const marker = new mapboxgl.Marker({ color: HAVELOCK_BLUE })
        .setLngLat(feature.geometry.coordinates)
        .addTo(map);

      // The last child of the marker is the actual svg and the second group is the one that needs to be styled
      // eslint-disable-next-line prefer-destructuring
      markerSvgGroups[index] = marker
        .getElement()
        .lastChild.getElementsByTagName("g")[2];

      const popupHtml = `<div><b>${feature.properties.name}</b></div>
                <div>${gettext(
                  "System Size"
                )}: ${feature.properties.size.toLocaleString()} kW</div>`;

      const popup = new mapboxgl.Popup({
        className: "marker-popup",
        offset: 45,
        index,
      })
        .setHTML(popupHtml)
        .on("open", (event) => {
          markerSvgGroups[event.target.options.index].style.fill = SUSHI;
        })
        .on("close", (event) => {
          markerSvgGroups[event.target.options.index].style.fill =
            HAVELOCK_BLUE;
        });

      marker.setPopup(popup);
    });
  }

  render = () => (
    <span className="d-block map">
      <span className="d-block h-100" ref={(el) => (this.mapContainer = el)} />
    </span>
  );
}

Map.propTypes = {
  projectsGeoJson: geoJsonType.isRequired,
};
