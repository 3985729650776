import React from "react";
import styled from "styled-components";

interface TabProps {
  index: number;
  value: number;
  className?: string;
  children?: React.ReactNode;
}

const TabPanel = ({ className, value, index, children }: TabProps) => {
  return (
    <StyledDiv
      id={`panel-${index}`}
      role="tabpanel"
      hidden={index !== value}
      aria-labelledby={`tab-${index}`}
      className={className}
    >
      {children}
    </StyledDiv>
  );
};

export const StyledDiv = styled.div`
  width: 100%;
  min-height: 600px;
  display: block;
  padding: 0 15px;
  margin: 12px 0 0 15px;
  font-size: 14px;
`;

export default TabPanel;
