import { createContext, useMemo, useState } from "react";

import { Direction, Ordering, createOrdering } from "~/utils/ordering";

export enum DocumentFields {
  SESSIONS = "sessions",
  DOWNLOADS = "downloads",
  VIEWS = "views",
}

export enum UserFields {
  SESSIONS = "sessions",
  DOWNLOADS = "downloads",
  VIEWS = "views",
}

export enum CompanyFields {
  USERS = "users",
  SESSIONS = "sessions",
  DOWNLOADS = "downloads",
  VIEWS = "views",
}

type AnyFields = DocumentFields | UserFields | CompanyFields;

export interface OrderingContextValue {
  companyOrdering: Ordering<CompanyFields>;
  setCompanyOrdering: (ordering: Ordering<CompanyFields>) => void;
  usersOrdering: Ordering<UserFields>;
  setUsersOrdering: (ordering: Ordering<UserFields>) => void;
  documentsOrdering: Ordering<DocumentFields>;
  setDocumentsOrdering: (ordering: Ordering<DocumentFields>) => void;
  serializeOrdering: (ordering: Ordering<AnyFields>) => string;
}

export const useOrderingContext = (): OrderingContextValue => {
  const [companyOrdering, setCompanyOrdering] = useState(
    createOrdering(CompanyFields.DOWNLOADS, Direction.DESC)
  );
  const [usersOrdering, setUsersOrdering] = useState(
    createOrdering(UserFields.DOWNLOADS, Direction.DESC)
  );
  const [documentsOrdering, setDocumentsOrdering] = useState(
    createOrdering(DocumentFields.DOWNLOADS, Direction.DESC)
  );

  return useMemo(() => {
    const serializeOrdering = (orderObj: Ordering<AnyFields>): string => {
      return orderObj.direction === Direction.ASC
        ? orderObj.field
        : `-${orderObj.field}`;
    };
    return {
      companyOrdering,
      setCompanyOrdering,
      usersOrdering,
      setUsersOrdering,
      documentsOrdering,
      setDocumentsOrdering,
      serializeOrdering,
    };
  }, [
    companyOrdering,
    setCompanyOrdering,
    usersOrdering,
    setUsersOrdering,
    documentsOrdering,
    setDocumentsOrdering,
  ]);
};

const OrderingContext = createContext<OrderingContextValue>(null as any);

export default OrderingContext;
