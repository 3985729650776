import React, { useContext } from "react";
import styled from "styled-components";

import { QuestionCategory, QuestionTopic } from "~/api/types.generated";
import Dropdown, { DropdownProps } from "~/components/menus/Dropdown";
import DropdownHeader from "~/components/menus/DropdownHeader";
import DropdownTriggerContext from "~/components/menus/DropdownTriggerContext";
import SelectMenu from "~/components/menus/SelectMenu";
import SelectMenuOption from "~/components/menus/SelectMenuOption";
import { fgColor } from "~/styles/mixins";

export interface CategoryDropdownProps extends DropdownProps {
  currentCategories: readonly CategoryObj[];
  currentTopics: readonly TopicObj[];
  availableCategories: readonly CategoryObj[];
  availableTopics: readonly TopicObj[];
  onSelectCategory: (category: CategoryObj) => unknown;
  onSelectTopic: (topic: TopicObj) => unknown;
  onDeselectCategory: (category: CategoryObj) => unknown;
  onDeselectTopic: (topic: TopicObj) => unknown;
}

const CategoryMenu = ({
  currentCategories,
  currentTopics,
  availableCategories,
  availableTopics,
  onSelectCategory,
  onSelectTopic,
  onDeselectCategory,
  onDeselectTopic,
  ...rest
}: CategoryDropdownProps) => {
  const dropdownTrigger = useContext(DropdownTriggerContext);

  const onSelect = (obj: CategoryObj | TopicObj) => {
    if (obj.__typename === "QuestionCategory") {
      onSelectCategory(obj);
    } else {
      onSelectTopic(obj);
    }
    dropdownTrigger?.measure();
  };

  const onDeselect = (obj: CategoryObj | TopicObj) => {
    if (obj.__typename === "QuestionCategory") {
      onDeselectCategory(obj);
    } else {
      onDeselectTopic(obj);
    }
    dropdownTrigger?.measure();
  };

  const isCategorySelected = (category: CategoryObj) =>
    currentCategories.some((c) => c.id === category.id);

  const isTopicSelected = (topic: TopicObj) =>
    currentTopics.some((t) => t.id === topic.id);

  const isSelected = (obj: CategoryObj | TopicObj) =>
    obj.__typename === "QuestionCategory"
      ? isCategorySelected(obj)
      : isTopicSelected(obj);

  const sortedCategories = [...availableCategories].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );

  const sortedTopics = [...availableTopics].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );

  const sortedObjs = [...sortedCategories, ...sortedTopics];

  return (
    <Dropdown {...rest}>
      <DropdownHeader title="Category / Topic" />
      <MenuScroller>
        <SelectMenu
          isSelected={isSelected}
          onSelect={onSelect}
          onDeselect={onDeselect}
          aria-multiselectable="true"
        >
          {sortedObjs.map((obj) => (
            <SelectMenuOption
              key={obj.__typename + ":" + obj.id}
              value={obj}
              label={obj.name}
            >
              <OptionName>{obj.name}</OptionName>
              <OptionType>
                {obj.__typename === "QuestionCategory" ? "Category" : "Topic"}
              </OptionType>
            </SelectMenuOption>
          ))}
        </SelectMenu>
      </MenuScroller>
    </Dropdown>
  );
};

export const MenuScroller = styled.div`
  min-width: 20rem;
  max-height: 20rem;
  overflow-y: scroll;
`;

export const OptionName = styled.div``;
const OptionType = styled.div`
  ${fgColor.darkBlue()};
  font-size: 0.75rem;

  *:focus & {
    ${fgColor.lightBlue()};
  }
`;

type CategoryObj = Pick<QuestionCategory, "__typename" | "id" | "name">;
type TopicObj = Pick<QuestionTopic, "__typename" | "id" | "name">;

export default CategoryMenu;
