import { Icon } from "components/icons";
import * as PropTypes from "prop-types";
import React from "react";

class SortIcon extends React.PureComponent {
  render() {
    return (
      <Icon
        color={this.props.theme}
        className={this.props.className}
        dir={this.props.dir}
        viewBox="0 0 12 16"
        onClick={this.props.onClick}
        withoutBackground
      >
        <path
          d="M13.7578125,7.1953125 L8.7890625,0.3984375 C8.6015625,0.1484375 8.3125,0 8,0 C7.6953125,
                 0 7.40625,0.1484375 7.2109375,0.3984375 L2.2421875,7.1953125 C2.0859375,7.3984375 2,7.6484375 2,7.90625
                 C2,8.0625 2.03125,8.21875 2.1015625,8.3671875 C2.265625,8.765625 2.640625,9 3.0390625,9 L6.015625,9
                 L6.0078125,14 C6.0078125,15.1015625 6.8984375,16 8,16 C9.1015625,16 9.9921875,15.1015625 9.9921875,14
                 L10,9 L12.9765625,9 C13.375,9 13.734375,8.765625 13.90625,8.3671875 C13.96875,8.21875 14,8.0625
                 14,7.90625 C14,7.6484375 13.921875,7.3984375 13.7578125,7.1953125 Z"
          transform="translate(-2)"
        />
      </Icon>
    );
  }
}

SortIcon.propTypes = {
  className: PropTypes.string,
  dir: PropTypes.oneOf(["N", "E", "S", "W"]),
  theme: PropTypes.string,
  onClick: PropTypes.func,
};

SortIcon.defaultProps = {
  className: null,
  dir: "N",
  theme: null,
  onClick: null,
};

export default SortIcon;
