import React, { useEffect } from "react";

export const useAutoFocus = <T extends HTMLOrSVGElement>(
  active: boolean,
  ref: React.RefObject<T>
) => {
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      if (active && ref.current) ref.current.focus();
    }, 0);
    return () => window.clearTimeout(timeout);
  }, [active, ref]);
};
