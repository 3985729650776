// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type Company_InfoFragment = (
  { readonly __typename: 'Company' }
  & Pick<Types.Company, 'id' | 'name'>
);

export type User_InfoFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'avatarUrl' | 'fullName' | 'firstName' | 'lastName' | 'notificationsEnabled' | 'lastLogin'>
  & { readonly company?: Types.Maybe<(
    { readonly __typename: 'Company' }
    & Company_InfoFragment
  )> }
);

export type UserGroup_InfoFragment = (
  { readonly __typename: 'UserGroup' }
  & Pick<Types.UserGroup, 'id' | 'name'>
  & { readonly role?: Types.Maybe<(
    { readonly __typename: 'Role' }
    & Pick<Types.Role, 'name'>
  )> }
);

export type UserGroupMemberFragment = (
  { readonly __typename: 'UserGroupMembership' }
  & Pick<Types.UserGroupMembership, 'id'>
  & { readonly user?: Types.Maybe<(
    { readonly __typename: 'User' }
    & User_InfoFragment
  )>, readonly userGroup?: Types.Maybe<(
    { readonly __typename: 'UserGroup' }
    & UserGroup_InfoFragment
  )> }
);

export const Company_InfoFragmentDoc = gql`
    fragment Company_Info on Company {
  id
  name
}
    `;
export const User_InfoFragmentDoc = gql`
    fragment User_Info on User {
  id
  avatarUrl
  fullName
  firstName
  lastName
  company {
    ...Company_Info
  }
  notificationsEnabled(
    fundEntityId: $fundEntityId
    fundEntityType: $fundEntityType
  )
  lastLogin
}
    ${Company_InfoFragmentDoc}`;
export const UserGroup_InfoFragmentDoc = gql`
    fragment UserGroup_Info on UserGroup {
  id
  name
  role {
    name
  }
}
    `;
export const UserGroupMemberFragmentDoc = gql`
    fragment UserGroupMember on UserGroupMembership {
  id
  user {
    ...User_Info
  }
  userGroup {
    ...UserGroup_Info
  }
}
    ${User_InfoFragmentDoc}
${UserGroup_InfoFragmentDoc}`;