import { Icon } from "components/icons";
import * as PropTypes from "prop-types";
import React from "react";

class TriangleIcon extends React.PureComponent {
  render() {
    return (
      <Icon
        color={this.props.theme}
        className={this.props.className}
        viewBox="0 0 12 16"
        withoutBackground
      >
        <path d="M6 2L0 8l6 6V2z" />
      </Icon>
    );
  }
}

TriangleIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
};

TriangleIcon.defaultProps = {
  className: null,
  theme: null,
};

export default TriangleIcon;
