import styled from "styled-components";

import { noOutline } from "~/styles/mixins";

import DropdownButton from "../menus/DropdownButton";

const CellDropdownButton = styled(DropdownButton)`
  ${noOutline}
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  height: 100%;
  width: 100%;
  text-align: left;

  &[disabled] {
    user-select: text;
    cursor: auto;
  }
`;

export default CellDropdownButton;
