import React from "react";
import styled from "styled-components";

import { border, fgColor } from "~/styles/mixins";

export interface EmptyStateProps {
  text: React.ReactNode;
}

const EmptyState = ({ text }: EmptyStateProps) => (
  <Container>
    <Icon />
    <Heading>{text}</Heading>
  </Container>
);

const Container = styled.div`
  ${border.gray300()};
  ${fgColor.black()};
  padding: 2rem;
  text-align: center;
`;

const Icon = styled.i.attrs({
  className: "icon icon--question",
})`
  &&& {
    width: 1.5rem;
    height: 1.5rem;
  }
  margin-bottom: 0.5rem;
`;

const Heading = styled.p`
  font-size: 1.5rem;
`;

export default EmptyState;
