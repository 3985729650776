import useFilterDropdown, {
  stateReducerExamples,
} from "components/FilterDropdown/useFilterDropdown";
import CaretIcon from "components/icons/CaretIcon";
import * as PropTypes from "prop-types";
import React from "react";
import { gettext } from "utils/text";

import { filterItemsByKeyword } from "../../FilterDropdown/utils";
import {
  DropdownMenu,
  DropdownMenuAlign,
  DropdownMenuTheme,
} from "../../menus/DropdownMenu";

export const getDefaultButtonText = (items, selectedItems) => {
  if (selectedItems.length === items.length || selectedItems.length === 0) {
    return gettext("All");
  } else if (selectedItems.length === 1) {
    return items.find((item) => item.key === selectedItems[0])?.value;
  } else {
    return gettext("Multiple");
  }
};

export const getUserButtonText = (items, selectedItems) => {
  // Much the same as getDefaultButtonText(), but difficult to refactor
  if (selectedItems.length === items.length || selectedItems.length === 0) {
    return gettext("All");
  } else if (selectedItems.length === 1) {
    if (selectedItems[0] === null) {
      return gettext("None");
    }
    if (selectedItems[0] === DJ_CONST.user.id) {
      return gettext("Me");
    }
    return items.find((item) => item.key === selectedItems[0]).value;
  } else {
    return gettext("Multiple");
  }
};

export const DefaultGroupSelectBlock = ({ setSelected, items }) => (
  <>
    <div
      className="filter-dropdown__group-select"
      onClick={() => setSelected(items.map((item) => item.key))}
    >
      {gettext("Select All")}
    </div>
    <div
      className="filter-dropdown__group-select"
      onClick={() => setSelected([])}
    >
      {gettext("Unselect All")}
    </div>
  </>
);

DefaultGroupSelectBlock.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      alwaysShow: PropTypes.bool,
    })
  ).isRequired,
  setSelected: PropTypes.func.isRequired,
};

const renderGroupSelectBlock = (setSelected, items) => (
  <DefaultGroupSelectBlock setSelected={setSelected} items={items} />
);

/**
 * Render the header of the Assignee filter dropdown
 *
 * Returns a render function, because the header requires looking into the selectedUsers list
 * to be able to identify if the list contains the option selected in the header.
 * (The dropdown component has no references to the current selection!
 *  It is only maintained in the parent Checklist component.)
 *
 * NOTE:
 * the behavior of the header's filters 'me' and 'None' is not exactly toggling the filter, rather,
 * on activation, it also deselects all other options.
 * On deactivation, it just removes itself (as normally expected of a toggleable filter)
 *
 * @param {array} selectedUsers
 * @returns {function(*, *): *}
 */
export const renderUserGroupSelectBlock = (selectedUsers) => {
  const isSelected = (userId) => selectedUsers.includes(userId);

  return (setSelected, items) => {
    const currentUser = DJ_CONST.user.id;
    const canIbeAssigned = items.find((user) => user.key === currentUser);
    const select = (userId) => {
      if (isSelected(userId)) {
        setSelected(selectedUsers.filter((id) => id !== userId));
      } else {
        setSelected([userId]);
      }
    };

    return (
      <>
        <div className="_text">{gettext("Assigned to:")}</div>
        {canIbeAssigned && (
          <div
            className={`filter-dropdown__group-select ${
              isSelected(currentUser) ? "_current" : ""
            }`}
            onClick={() => select(currentUser)}
          >
            {gettext("me")}
          </div>
        )}
        <div
          className={`filter-dropdown__group-select ${
            isSelected(null) ? "_current" : ""
          }`}
          onClick={() => select(null)}
          style={{ marginRight: "3rem" }}
        >
          {gettext("None")}
        </div>
        <DefaultGroupSelectBlock setSelected={setSelected} items={items} />
      </>
    );
  };
};

const ChecklistFilterDropdown = ({
  items,
  label,
  title,
  placeholder,
  getButtonText,
  selectedOptions,
  onSelected,
  children,
  groupSelectBlock,
  multiple,
  className,
}) => {
  const {
    getItemProps,
    getInputProps,
    keyword,
    selected,
    internalSetState,
    isOpen,
    getToggleProps,
  } = useFilterDropdown({
    selected: selectedOptions,
    onSelected,
    stateReducer: multiple
      ? undefined
      : (state, nextState) =>
          stateReducerExamples.closeAfterSelected(
            state,
            stateReducerExamples.allowExactlyOneSelection(state, nextState)
          ),
  });

  const setSelected = (selectedItems) =>
    internalSetState({ selected: selectedItems });

  return (
    <div className="dropdown-filter">
      {label && <p className="text-secondary mb-0 ml-3">{label}</p>}
      <DropdownMenu
        withClose
        theme={DropdownMenuTheme.BLUE}
        menuAlign={DropdownMenuAlign.RIGHT}
        className={`filter-dropdown ml-3 top-label ${className}`}
        btnText={getButtonText(items, selectedOptions)}
        showIcon
        iconStyles={{
          top: "6px",
          right: "5px",
          position: "absolute",
        }}
        iconComponent={CaretIcon}
        iconColor="primary"
        hoverIconColor="white"
        isOpen={isOpen}
        toggleOpen={getToggleProps().onClick}
      >
        <div className="dropdown-header">
          <div className="filter-dropdown__title-container">
            <h4 className="filter-dropdown__title">{title}</h4>
          </div>

          <div className="d-flex align-items-center">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="icon icon--search" />
              </span>
            </div>
            <input
              {...getInputProps({
                className: "form-control form-control-sm",
                placeholder,
              })}
            />
          </div>

          {multiple && (
            <div key="gs" className="filter-dropdown__group-select-container">
              {groupSelectBlock(setSelected, items)}
            </div>
          )}
        </div>
        <div className="dropdown-body-down">
          <ul className="list-group list-group-flush">
            {filterItemsByKeyword(items, keyword).map((item) => (
              <li
                {...getItemProps({
                  key: item.key,
                  className: "dropdown-item",
                })}
              >
                {children({
                  item,
                  selected: selected.includes(item.key),
                })}
              </li>
            ))}
          </ul>
        </div>
      </DropdownMenu>
    </div>
  );
};

ChecklistFilterDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      alwaysShow: PropTypes.bool,
    })
  ).isRequired,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  getButtonText: PropTypes.func,
  selectedOptions: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  groupSelectBlock: PropTypes.func,
  multiple: PropTypes.bool,
  className: PropTypes.string,
};

ChecklistFilterDropdown.defaultProps = {
  groupSelectBlock: renderGroupSelectBlock,
  multiple: false,
  placeholder: gettext("Filter..."),
  getButtonText: getDefaultButtonText,
  className: "",
};

export default ChecklistFilterDropdown;
