import { ellipsis } from "polished";
import React, {
  Children,
  EventHandler,
  FocusEvent,
  KeyboardEvent,
  MutableRefObject,
  cloneElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { isElement } from "react-is";
import styled, { css } from "styled-components";

import {
  bgColor,
  border,
  borderBottom,
  fgColor,
  noOutline,
  outline,
} from "~/styles/mixins";
import { GetPropsWithoutRef } from "~/utils/types";

export interface DataGridProps extends GetPropsWithoutRef<"table"> {
  innerRef?: MutableRefObject<HTMLTableElement | null>;
}

export interface BodyProps extends GetPropsWithoutRef<"tbody"> {
  rowOffset?: number;
}

export interface CellProps extends GetPropsWithoutRef<"td"> {
  numeric?: boolean;
}

export interface HeaderProps extends GetPropsWithoutRef<"thead"> {}

export interface HeaderCellProps extends GetPropsWithoutRef<"th"> {
  numeric?: boolean;
}

export interface RowProps extends GetPropsWithoutRef<"tr"> {
  inactive?: boolean;
}

const FOCUSABLE = `
a[href],
area[href],
input:not([disabled]),
select:not([disabled]),
textarea:not([disabled]),
button:not([disabled]),
iframe,
[tabindex]:not([tabindex="-1"]),
[contentEditable=true]
`;

const AUTO_FOCUS =
  'a, button:not([disabled]), input[type="checkbox"]:not([disabled]), input[type="radio"]:not([disabled])';

const AUTO_FOCUS_OR_FOCUSED = ":focus, " + AUTO_FOCUS;

const DataGrid = ({ children, innerRef, ...rest }: DataGridProps) => {
  const [currentRow, setCurrentRow] = useState(0);
  const [currentCol, setCurrentCol] = useState(0);
  const [active, setActive] = useState(false);
  const currentCell = useRef<HTMLElement | null>(null);

  const table = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    if (!table.current) return;
    const cells = Array.from(table.current.querySelectorAll("[aria-colindex]"));
    for (const cell of cells) {
      const focusableChildren = Array.from(cell.querySelectorAll(FOCUSABLE));
      for (const f of focusableChildren) {
        f.setAttribute("tabindex", "-1");
      }

      if (
        focusableChildren.length === 1 &&
        focusableChildren[0].matches(AUTO_FOCUS_OR_FOCUSED)
      ) {
        focusableChildren[0].setAttribute("tabindex", "-1");
      } else {
        cell.setAttribute("tabindex", "-1");
      }
    }

    currentCell.current?.setAttribute("tabindex", "-1");
    currentCell.current =
      table.current?.querySelector(
        `[aria-rowindex="${currentRow + 1}"] > [aria-colindex="${
          currentCol + 1
        }"]`
      ) ?? null;

    const focusableChildren = Array.from(
      currentCell.current?.querySelectorAll(FOCUSABLE) || []
    );
    for (const f of focusableChildren) {
      f.setAttribute("tabindex", "-1");
    }
    let focusTarget: HTMLElement | null = currentCell.current;
    if (
      focusableChildren.length === 1 &&
      focusableChildren[0].matches(AUTO_FOCUS_OR_FOCUSED)
    ) {
      focusTarget = focusableChildren[0] as HTMLElement;
    }
    focusTarget?.setAttribute("tabindex", "0");
    if (active) {
      focusTarget?.focus();
      focusTarget
        ?.closest("[aria-colindex]")
        ?.scrollIntoView({ block: "nearest" });
    }
  }, [active, currentRow, currentCol]);

  let rowCount = 0;
  let colCount = 0;

  const rowGroups = Children.toArray(children)
    .filter(isElement)
    .map((rowGroup, index) => {
      if (index > 0) rowGroup = cloneElement(rowGroup, { rowOffset: rowCount });
      rowCount += Children.count(rowGroup.props.children);
      Children.forEach(rowGroup.props.children, (row) => {
        colCount = Math.max(colCount, Children.count(row.props.children));
      });
      return rowGroup;
    });

  useEffect(() => {
    if (currentRow > rowCount - 1) setCurrentRow(rowCount - 1);
    if (currentCol > colCount - 1) setCurrentCol(colCount - 1);
  }, [currentRow, rowCount, currentCol, colCount]);

  const onFocus = (event: FocusEvent<HTMLElement>) => {
    if (!table.current?.contains(event.target)) return;
    setActive(true);
    const cell = event.target.closest("[aria-colindex]");
    const row = cell?.closest("[aria-rowindex]");
    setCurrentCol(parseInt(cell?.getAttribute("aria-colindex") ?? "1") - 1);
    setCurrentRow(parseInt(row?.getAttribute("aria-rowindex") ?? "1") - 1);
  };

  const onBlur = () => {
    setActive(false);
  };

  const onKeyDown: EventHandler<KeyboardEvent> = (event) => {
    if (!(event.target instanceof HTMLElement)) return;
    const cell = event.target.closest("[aria-colindex]");
    const focusable = cell?.querySelectorAll(FOCUSABLE);

    const enableNavigation =
      event.target === cell ||
      (focusable?.length === 1 && event.target.matches(AUTO_FOCUS));

    switch (event.key) {
      case "ArrowUp": {
        if (!enableNavigation) return;
        event.preventDefault();
        setCurrentRow((currentRow) =>
          currentRow > 0 ? currentRow - 1 : currentRow
        );
        break;
      }

      case "ArrowDown": {
        if (!enableNavigation) return;
        event.preventDefault();
        setCurrentRow((currentRow) =>
          currentRow < rowCount - 1 ? currentRow + 1 : currentRow
        );
        break;
      }

      case "ArrowLeft": {
        if (!enableNavigation) return;
        event.preventDefault();
        setCurrentCol((currentCol) =>
          currentCol > 0 ? currentCol - 1 : currentCol
        );
        break;
      }

      case "ArrowRight": {
        if (!enableNavigation) return;
        event.preventDefault();
        setCurrentCol((currentCol) =>
          currentCol < colCount - 1 ? currentCol + 1 : currentCol
        );
        break;
      }

      case "Enter": {
        if (event.target === cell && (focusable?.length ?? 0) > 0) {
          event.preventDefault();
          (focusable?.[0] as HTMLElement)?.focus();
        } else if (
          cell?.contains(event.target) &&
          !enableNavigation &&
          !(event.shiftKey || event.altKey)
        ) {
          event.preventDefault();
          if (currentRow < rowCount - 1) {
            setCurrentRow((currentRow) => currentRow + 1);
          } else {
            event.target.closest<HTMLElement>("[aria-colindex]")?.focus();
          }
        }
        break;
      }

      case "Escape": {
        if (!enableNavigation) {
          event.target.closest<HTMLElement>("[aria-colindex]")?.focus();
        }
        break;
      }
    }
  };

  return (
    <table
      ref={(node) => {
        table.current = node;
        if (innerRef) innerRef.current = node;
      }}
      role="grid"
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      children={rowGroups}
      {...rest}
    />
  );
};

const DataGrid__Body = ({ children, rowOffset = 0, ...rest }: BodyProps) => {
  const rows = Children.toArray(children)
    .filter(isElement)
    .map((row, index) =>
      cloneElement(row, { "aria-rowindex": rowOffset + index + 1 })
    );

  return <tbody children={rows} {...rest} />;
};

const DataGrid__Cell = ({ numeric, children, ...rest }: CellProps) => {
  return (
    <td {...rest}>
      <CellContents>{children}</CellContents>
    </td>
  );
};

const DataGrid__Header = ({ children, ...rest }: HeaderProps) => {
  const rows = Children.toArray(children)
    .filter(isElement)
    .map((row, index) => cloneElement(row, { "aria-rowindex": index + 1 }));

  return <thead children={rows} {...rest} />;
};

const DataGrid__HeaderCell = ({
  numeric,
  children,
  ...rest
}: HeaderCellProps) => (
  <th {...rest}>
    <CellContents>{children}</CellContents>
  </th>
);

const DataGrid__Row = ({ children, inactive, ...rest }: RowProps) => {
  const cells = Children.toArray(children)
    .filter(isElement)
    .map((cell, index) => cloneElement(cell, { "aria-colindex": index + 1 }));

  return <tr children={cells} {...rest} />;
};

const Body = styled(DataGrid__Body)``;

const Cell = styled(DataGrid__Cell)`
  ${border.gray300()};
  ${noOutline};
  height: 100%;
  padding: 0;
  vertical-align: top;
  scroll-margin-top: 40px;

  ${(props) =>
    props.numeric &&
    css`
      text-align: right;
      font-variant-numeric: tabular-nums;
    `}

  &:focus-within {
    ${outline({ inset: true })};
  }

  &:focus {
    ${outline({ inset: true, secondary: true })};
  }
`;

const CellContents = styled.div`
  height: 100%;
  width: 100%;
`;

const Header = styled(DataGrid__Header)``;

const HeaderCell = styled(DataGrid__HeaderCell).withConfig<{
  padded?: boolean;
}>({
  shouldForwardProp: (name) => name !== "padded",
})`
  ${border.gray300()};
  ${borderBottom.black()};
  ${bgColor.white()};
  ${noOutline};
  ${ellipsis()};
  display: table-cell;
  font-weight: bold;
  height: 100%;
  padding: ${(props) => (props.padded ? "0.5rem" : 0)};
  position: relative;

  ${(props) =>
    props.numeric &&
    css`
      text-align: right;
      font-variant-numeric: tabular-nums;
    `}

  &:focus-within {
    ${outline({ inset: true })};
  }

  &:focus {
    ${outline({ inset: true, secondary: true })};
  }
`;

const Row = styled(DataGrid__Row)`
  height: 100%;
  position: relative;
  ${(props) => props.inactive && fgColor.gray600()};
`;

export default Object.assign(
  styled(DataGrid)`
    height: 1px;
    width: 100%;
    border-collapse: collapse;
    line-height: 24px;
  `,
  { Body, Cell, Header, HeaderCell, Row }
);
