import React from "react";
import isEqual from "react-fast-compare";
import { shallowEqualObjects } from "shallow-equal";
import styled from "styled-components";

import { DataGrid } from "~/components/DataGrid";
import QuestionPortfolios from "~/components/QAndA/components/QuestionPortfolios";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";
import { GetPropsWithoutRef } from "~/utils/types";

import QuestionContext, {
  QuestionContextValue,
} from "../context/QuestionContext";
import QuestionAnswer from "./QuestionAnswer";
import QuestionBody from "./QuestionBody";
import QuestionCategories from "./QuestionCategories";
import QuestionNumber from "./QuestionNumber";
import QuestionProjects from "./QuestionProjects";
import QuestionStatus from "./QuestionStatus";

export interface QuestionRowProps
  extends GetPropsWithoutRef<typeof DataGrid.Row> {
  question: QuestionContextValue;
  subject: QAndASubjectFragment;
  canEditQuestions: boolean;
  canEditQuestionAnswers: boolean;
}

const { Row, Cell } = DataGrid;

const QuestionRow = ({
  subject,
  question,
  canEditQuestions,
  canEditQuestionAnswers,
  ...rest
}: QuestionRowProps) => {
  return (
    <QuestionContext.Provider value={question}>
      <Row
        inactive={question.isClosed}
        data-question-id={question.id}
        {...rest}
      >
        <Cell
          numeric
          children={<QuestionNumber canEditQuestions={canEditQuestions} />}
        />
        <Cell
          children={<QuestionStatus canEditQuestions={canEditQuestions} />}
        />
        <Cell children={<QuestionCategories />} />
        {subject.__typename === "Portfolio" && (
          <Cell children={<QuestionProjects />} />
        )}
        {subject.__typename === "HoldingCompany" && (
          <Cell children={<QuestionPortfolios />} />
        )}
        <WideCell
          children={<QuestionBody canEditQuestions={canEditQuestions} />}
        />
        <WideCell
          children={
            <QuestionAnswer canEditQuestionAnswers={canEditQuestionAnswers} />
          }
        />
      </Row>
    </QuestionContext.Provider>
  );
};

const WideCell = styled(Cell)`
  min-width: 250px;
  width: 50%;
`;

export default React.memo(
  QuestionRow,
  /**
   * Apollo's optimisticResponse breaks React.memo/PureComponent optimization using ===,
   * so we need to do a deep equality check on questions until this is fixed.
   * See: https://github.com/apollographql/apollo-client/issues/4141
   */
  (
    { question: prevQuestion, subject: prevSubject, ...prevProps },
    { question: nextQuestion, subject: nextSubject, ...nextProps }
  ) => {
    return (
      isEqual(prevQuestion, nextQuestion) &&
      isEqual(prevSubject, nextSubject) &&
      shallowEqualObjects(prevProps, nextProps)
    );
  }
);
