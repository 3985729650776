import classNames from "classnames";
import * as PropTypes from "prop-types";
import React from "react";

const LoadingSpinner = ({ color, big, className }) => (
  <div
    className={classNames(
      "loading-spinner",
      `loading-spinner--${color}`,
      { big },
      className
    )}
  />
);

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["light", "dark", "arctic-blue"]),
  big: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  className: undefined,
  color: "dark",
  big: false,
};

export default LoadingSpinner;
