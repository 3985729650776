import {
  useDocumentsColumnsQuery,
  useDocumentsDetailQuery,
  useDocumentsHistoryQuery,
  useDocumentsSearchQuery,
} from "./DocumentsAPI.generated";
import { DocumentsHistoryFilters } from "./useDocumentsHistoryFilters";
import { DocumentsRouting, View } from "./useDocumentsRouting";
import { DocumentsSearch } from "./useDocumentsSearch";

export interface DocumentsQueries {
  detail: ReturnType<typeof useDocumentsDetailQuery>;
  columns: ReturnType<typeof useDocumentsColumnsQuery>;
  searchDocs: ReturnType<typeof useDocumentsSearchQuery>;
  history: ReturnType<typeof useDocumentsHistoryQuery>;
}

/** Manages all GQL query hooks, making sure only one is active at a time */
export const useDocumentsQueries = (
  treeId: string,
  { view, documentId, rootId }: DocumentsRouting,
  { searchId, debouncedSearchTerm, showSearch }: DocumentsSearch,
  historyFilters: DocumentsHistoryFilters
): DocumentsQueries => {
  const activeQuery = getActiveQuery(view, showSearch);

  /** Data required for the folder list & file preview views */
  const detail = useDocumentsDetailQuery({
    variables: { treeId, documentId },
    returnPartialData: true,
    fetchPolicy: "cache-and-network",
    skip: activeQuery !== "detail",
  });

  /** Data required for the columns view */
  const columns = useDocumentsColumnsQuery({
    variables: { treeId, documentId },
    returnPartialData: true,
    fetchPolicy: "cache-and-network",
    skip: activeQuery !== "columns",
  });

  /** Data required for searching documents */
  const searchDocs = useDocumentsSearchQuery({
    variables: { treeId, documentId: searchId, search: debouncedSearchTerm },
    returnPartialData: true,
    fetchPolicy: "cache-and-network",
    skip: activeQuery !== "searchDocs",
  });

  const history = useDocumentsHistoryQuery({
    variables: {
      treeId,
      rootId,
      limit: historyFilters.pageSize,
      offset: (historyFilters.page - 1) * historyFilters.pageSize,
      search: showSearch ? debouncedSearchTerm : undefined,
      ancestorIds: historyFilters.ancestorIds,
      activityTypes: historyFilters.activityTypes,
      fileTypes: historyFilters.fileTypes,
      userGroupIds: historyFilters.userGroupIds,
    },
    returnPartialData: true,
    fetchPolicy: "cache-and-network",
    skip: activeQuery !== "history",
  });

  return { detail, columns, searchDocs, history };
};

/** Gets the name of the query that should currently be subscribed */
export const getActiveQuery = (view: View, showSearch: boolean) => {
  switch (view) {
    case "single":
    case "upload":
      return showSearch ? "searchDocs" : "detail";
    case "columns":
      return showSearch ? "searchDocs" : "columns";
    case "history":
      return "history";
  }
};
