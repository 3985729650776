import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { bgColor, device } from "~/styles/mixins";

const logoDisplay = (company) => {
  if (company && company.logoUrl)
    return (
      <img
        src={company.logoUrl}
        style={{ maxHeight: "3.5rem" }}
        alt={`company-${company.id}`}
      />
    );
  return null;
};

const WelcomeBanner = ({ userName, company }) => (
  <BlackBanner>
    <div className="d-flex justify-content-between align-items-center">
      <div className="flex-grow-1">
        <Title>WELCOME</Title>
        <TitleUser>{userName}</TitleUser>
      </div>
      {logoDisplay(company)}
    </div>
  </BlackBanner>
);

WelcomeBanner.propTypes = {
  userName: PropTypes.string.isRequired,
};

const BlackBanner = styled.div`
  ${bgColor.gray800()};
  border-radius: 4px;
  font-size: 14px;
  color: #fffbd2;

  margin-bottom: 10px;
  padding: 0px 14px;
  @media ${device.xs} {
    margin-bottom: 2px;
    padding: 5px 25px 5px;
  }
`;

const Title = styled.label`
  font-size: 14px;
  color: #c0c0c0;
  margin-top: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
`;

const TitleUser = styled.h2`
  font-size: 24px;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 13px;
  padding-top: 0;
  text-transform: uppercase;
  @media ${device.xs} {
    font-size: 2rem;
  }
`;

export default WelcomeBanner;
