import type { Rect } from "./rect";

/** A two-dimensional vector */
export interface Vec2 {
  x: number;
  y: number;
}

export const zero: Vec2 = { x: 0, y: 0 };

/** Creates a vector from its x and y components */
export const create = (x: number, y: number): Vec2 => {
  return { x, y };
};

/** Scales a vector by a scalar factor */
export const scale = (vec: Vec2, factor: number): Vec2 => {
  return create(vec.x * factor, vec.y * factor);
};

/** Inverts a vector's components */
export const invert = (vec: Vec2): Vec2 => {
  return scale(vec, -1);
};

/** Returns the component-wise sum of two vectors */
export const add = (a: Vec2, b: Vec2): Vec2 => {
  return create(a.x + b.x, a.y + b.y);
};

/** Returns the component-wise difference of two vectors */
export const subtract = (a: Vec2, b: Vec2): Vec2 => {
  return create(a.x - b.x, a.y - b.y);
};

/** Returns the component-wise product of two vectors */
export const multiply = (a: Vec2, b: Vec2): Vec2 => {
  return create(a.x * b.x, a.y * b.y);
};

/** Returns the minimum components of two vectors */
export const min = (a: Vec2, b: Vec2): Vec2 => {
  return create(Math.min(a.x, b.x), Math.min(a.y, b.y));
};

/** Returns the maximum components of two vectors */
export const max = (a: Vec2, b: Vec2): Vec2 => {
  return create(Math.max(a.x, b.x), Math.max(a.y, b.y));
};

/** Constrains the vector to fit within the given rect */
export const constrain = (vec: Vec2, rect: Rect): Vec2 => {
  return min(max(vec, rect.min), rect.max);
};
