// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ProfileFormFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'firstName' | 'lastName' | 'title' | 'location' | 'companyName'>
);

export type UpdateProfileMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  location: Types.Scalars['String'];
  title: Types.Scalars['String'];
}>;


export type UpdateProfileMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateUserProfile?: Types.Maybe<(
    { readonly __typename: 'User' }
    & ProfileFormFragment
  )> }
);

export const ProfileFormFragmentDoc = gql`
    fragment ProfileForm on User {
  id
  firstName
  lastName
  title
  location
  companyName
}
    `;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($id: ID!, $firstName: String!, $lastName: String!, $location: String!, $title: String!) {
  updateUserProfile(
    id: $id
    firstName: $firstName
    lastName: $lastName
    title: $title
    location: $location
  ) {
    ...ProfileForm
  }
}
    ${ProfileFormFragmentDoc}`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      location: // value for 'location'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, baseOptions);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;