import TooltipOverlay from "components/utils/TooltipOverlay";
import * as PropTypes from "prop-types";
import React from "react";

const StatsBar = ({ stats }) => (
  <div className="statsbar">
    {stats.map(([key, value]) => (
      <div key={key}>
        <div className="statsbar--label">{key}</div>
        {key === "Deal Manager" && value !== null ? (
          <TooltipOverlay
            tooltipKey="top"
            tooltipPlacement="top"
            tooltipText={value}
          >
            <div className="statsbar--value">{value}</div>
          </TooltipOverlay>
        ) : (
          <div className="statsbar--value">{value !== null ? value : "-"}</div>
        )}
      </div>
    ))}
  </div>
);

StatsBar.propTypes = {
  stats: PropTypes.arrayOf(
    // Null values shown as "-"
    PropTypes.arrayOf(PropTypes.node).isRequired
  ).isRequired,
};

export default StatsBar;
