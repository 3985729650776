import Dialog from "components/generic/Dialog";
import React, { useState } from "react";
import { gettext } from "utils/text";

import { UpdatePhoneNumberConfirmAuth } from "~/components/forms/UpdatePhoneNumberConfirmAuth";
import { UpdatePhoneNumberSendAuth } from "~/components/forms/UpdatePhoneNumberSendAuth";
import {
  UpdatePhoneNumberSendAuthFormFragment,
  useUpdatePhoneNumberSendAuthMutation,
} from "~/components/forms/UpdatePhoneNumberSendAuth.generated";

import Alert from "./Alert";

export interface UpdatePhoneNumberDialogProps {
  user: UpdatePhoneNumberSendAuthFormFragment;
  phoneNumberChanged: (message: string) => void;
  togglePhoneUpdateVisible: () => void;
  phoneNumberUpdateVisible: boolean;
}

export const UpdatePhoneNumberDialog = ({
  user,
  phoneNumberChanged,
  togglePhoneUpdateVisible,
  phoneNumberUpdateVisible,
}: UpdatePhoneNumberDialogProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [newCountryCode, setNewCountryCode] = useState("");
  const handlePhoneNumberUpdate = (message: string) =>
    phoneNumberChanged(message);
  const [resendCode, { loading: isResending }] =
    useUpdatePhoneNumberSendAuthMutation({
      onCompleted: () => {
        setAlertVisible(true);
        setTimeout(() => {
          closeAlert();
        }, 4000);
      },
      onError: (error) => {
        // TODO: This is legacy from before converting to typescript/graphql. We should handle this error properly.
        console.error(error.message);
      },
    });

  const triggerTwoFactorAuth = (
    newPhoneNumber: string,
    newCountryCode: string
  ) => {
    setModalOpen(true);
    setNewPhoneNumber(newPhoneNumber);
    setNewCountryCode(newCountryCode);
  };

  const closeAlert = () => {
    setAlertVisible(false);
  };

  const handleResendCode = () => {
    resendCode({
      variables: {
        id: user.id,
        phoneNumber: newPhoneNumber,
        countryCode: newCountryCode,
      },
    });
  };

  const renderPhoneNumber2FaModal = () => (
    <Dialog
      isOpen={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      header={gettext("Two-Factor Authentication")}
      titleVariant="large"
      actionsVariant={false}
    >
      {alertVisible && <Alert message="Authentication code was resent." />}
      <div className="text-center">
        <UpdatePhoneNumberConfirmAuth
          user={user}
          togglePhoneUpdateVisible={togglePhoneUpdateVisible}
          newPhoneNumber={newPhoneNumber}
          newCountryCode={newCountryCode}
          handlePhoneNumberUpdate={handlePhoneNumberUpdate}
          closeModal={() => setModalOpen(false)}
          handleResendCode={handleResendCode}
          isResending={isResending}
        />
      </div>
    </Dialog>
  );

  return (
    <div>
      <UpdatePhoneNumberSendAuth
        user={user}
        phoneNumberUpdateVisible={phoneNumberUpdateVisible}
        togglePhoneUpdateVisible={togglePhoneUpdateVisible}
        phoneNumber={phoneNumberUpdateVisible ? "" : user.phoneNumber}
        triggerTwoFactorAuth={triggerTwoFactorAuth}
        code="+1"
      />
      {renderPhoneNumber2FaModal()}
    </div>
  );
};
