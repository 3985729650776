import gql from "graphql-tag";
import { keyBy } from "lodash";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import { Permissions } from "~/components/Admin/Group/constants";
import { useOrgRoleLazyQuery } from "~/components/Admin/Group/GroupAccessTable.generated";
import CheckIcon from "~/components/common/icons/CheckIcon";
import { gray100, gray300 } from "~/styles/theme/color";
import { borderRadius } from "~/styles/theme/common";

export const ROLE_QUERY = gql`
  query OrgRole($orgSlug: String!, $roleId: ID!) {
    role(orgSlug: $orgSlug, roleId: $roleId) {
      id
      name
      permissions {
        id
        codename
      }
    }
  }
`;

export interface GroupAccessTableProps {
  orgSlug: string;
  roleId: string | null;
}

const GroupAccessTable = ({ orgSlug, roleId }: GroupAccessTableProps) => {
  const [getData, { data }] = useOrgRoleLazyQuery();

  useEffect(() => {
    if (roleId)
      getData({
        variables: { orgSlug: orgSlug, roleId: roleId },
      });
  }, [orgSlug, roleId, getData]);
  const permittedMap = keyBy(data?.role?.permissions ?? [], "codename");

  return (
    <Box>
      <TableTitle>Permissions</TableTitle>
      <TableContainer>
        <Table>
          <Row header>
            <Cell></Cell>
            <Cell>{data?.role?.name}</Cell>
          </Row>
          {Permissions.map((p) => (
            <Row key={p.codename}>
              <Cell>{p.shortDescription}</Cell>
              <Cell>
                {permittedMap[p.codename] && <CheckIcon fontSize="small" />}
              </Cell>
            </Row>
          ))}
        </Table>
      </TableContainer>
    </Box>
  );
};

const Box = styled.div`
  border-radius: ${borderRadius};
  border: 1px solid ${gray300};
  margin: 0 14px;
  padding: 16px;
  max-height: 500px;
  overflow-y: scroll;
`;

const TableTitle = styled.div`
  font-size: 14;
  margin-bottom: 12px;
`;

const TableContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${gray100};
  display: flex;
  flex-direction: column;
`;

const Table = styled.div`
  min-width: 100%;
  font-variant-numeric: tabular-nums;
  height: 100%;
  border-left: 1px solid ${gray100};
  border-right: 1px solid ${gray100};
`;

const Row = styled.div<{ header?: boolean }>`
  display: flex;
  max-height: 50px;
  min-height: 29px;
  column-gap: 10px;
  border-bottom: 1px solid ${gray100};
  justify-content: space-between;
  ${(props) =>
    props.header &&
    css`
      font-weigth: 500;
      background-color: ${gray100};
    `}
  text-align: right;
  :first-child {
    text-align: left;
  }
`;

const Cell = styled.div`
  white-space: normal;
  position: relative;
  transition: all 60ms ease-in 0s;
  padding: 4px 8px;
  font-size: 12px;
`;

export default GroupAccessTable;
