import React from "react";
import styled from "styled-components";

interface MapLegendProps {
  showSelected?: boolean;
  direction?: string;
}

const MapLegend = ({
  showSelected = true,
  direction = "column",
}: MapLegendProps) => {
  return (
    <>
      <Label>LEGEND</Label>
      <Container>
        <ProjectSection className="project-section" direction={direction}>
          {showSelected && (
            <MarkerRow>
              <Marker
                style={{ width: 15, height: 15 }}
                className="map-marker active"
              ></Marker>
              <div>Selected</div>
            </MarkerRow>
          )}
          <MarkerRow>
            <Marker
              style={{ width: 15, height: 15 }}
              className="map-marker owned"
            ></Marker>
            <div>Owned</div>
          </MarkerRow>
          <MarkerRow>
            <Marker
              style={{ width: 15, height: 15 }}
              className="map-marker"
            ></Marker>
            <div>Pipeline</div>
          </MarkerRow>
        </ProjectSection>
      </Container>
    </>
  );
};

const Label = styled.div`
  font-size: 12px;
  line-height: 24px;
`;

const Container = styled.div`
  font-size: 18px;
  line-height: 24px;
`;

const ProjectSection = styled.div<{ direction?: string }>`
  // Override the project-section default style:
  &&& {
    flex-flow: ${(props) => props.direction} wrap;
    border: none;
    margin: 0;
    padding: 0;
    max-height: 50px;
  }
`;

const MarkerRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const Marker = styled.div`
  // Override marker positioning
  &&& {
    transform: none;
  }
`;

export default MapLegend;
