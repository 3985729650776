import React from "react";
import styled, { css } from "styled-components";

import { ButtonGroup, SecondaryButton } from "~/components/common/buttons";
import { CaretIcon, MoreIcon } from "~/components/common/icons";
import { Toolbar } from "~/components/common/toolbars";
import { gray600, gray800 } from "~/styles/theme/color";

export interface PaginationProps {
  page: number;
  pageSize: number;
  count: number;
  setPage: (page: number) => void;
}

export const Pagination = React.memo(function Pagination(
  props: PaginationProps
) {
  const { page, pageSize, count, setPage } = props;

  const pageCount = Math.ceil(count / pageSize);

  const buttons = [...new Array(pageCount)]
    .map((_, i) => i + 1)
    .filter((n) => n === 1 || n === pageCount || Math.abs(page - n) < 2);

  if (pageCount < 2) return null;
  return (
    <Container>
      <Button disabled={page === 1} onClick={() => setPage(page - 1)}>
        <CaretIcon style={{ transform: "rotate(180deg)" }} />
      </Button>
      <ButtonGroup>
        {buttons.map((n, i) => (
          <React.Fragment key={n}>
            <Button disabled={n === page} onClick={() => setPage(n)}>
              {n}
            </Button>
            {buttons[i + 1] && buttons[i + 1] !== n + 1 && (
              <Button disabled>
                <MoreIcon />
              </Button>
            )}
          </React.Fragment>
        ))}
      </ButtonGroup>
      <Button disabled={page === pageCount} onClick={() => setPage(page + 1)}>
        <CaretIcon />
      </Button>
    </Container>
  );
});

const Container = styled(Toolbar)`
  margin: 1rem 0;
  justify-content: center;
  position: relative;
  z-index: 0;
`;

const Button = styled(SecondaryButton)`
  min-width: 2rem;
  z-index: 1;
  ${(props) =>
    props.disabled &&
    css`
      border-color: ${gray600};
      color: ${gray800};
      z-index: 0;
    `};
`;
