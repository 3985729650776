// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteUserInvitationMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  token: Types.Scalars['ID'];
}>;


export type DeleteUserInvitationMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deleteUserInvitation?: Types.Maybe<(
    { readonly __typename: 'DeletedInvitationResult' }
    & Pick<Types.DeletedInvitationResult, 'token'>
  )> }
);

export type ResendUserInvitationMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  token: Types.Scalars['ID'];
}>;


export type ResendUserInvitationMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly resendUserInvitation?: Types.Maybe<(
    { readonly __typename: 'OrgInvitation' }
    & Pick<Types.OrgInvitation, 'token'>
  )> }
);


export const DeleteUserInvitationDocument = gql`
    mutation DeleteUserInvitation($orgSlug: String!, $token: ID!) {
  deleteUserInvitation(orgSlug: $orgSlug, token: $token) {
    token
  }
}
    `;
export type DeleteUserInvitationMutationFn = Apollo.MutationFunction<DeleteUserInvitationMutation, DeleteUserInvitationMutationVariables>;

/**
 * __useDeleteUserInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserInvitationMutation, { data, loading, error }] = useDeleteUserInvitationMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDeleteUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserInvitationMutation, DeleteUserInvitationMutationVariables>) {
        return Apollo.useMutation<DeleteUserInvitationMutation, DeleteUserInvitationMutationVariables>(DeleteUserInvitationDocument, baseOptions);
      }
export type DeleteUserInvitationMutationHookResult = ReturnType<typeof useDeleteUserInvitationMutation>;
export type DeleteUserInvitationMutationResult = Apollo.MutationResult<DeleteUserInvitationMutation>;
export type DeleteUserInvitationMutationOptions = Apollo.BaseMutationOptions<DeleteUserInvitationMutation, DeleteUserInvitationMutationVariables>;
export const ResendUserInvitationDocument = gql`
    mutation ResendUserInvitation($orgSlug: String!, $token: ID!) {
  resendUserInvitation(orgSlug: $orgSlug, token: $token) {
    token
  }
}
    `;
export type ResendUserInvitationMutationFn = Apollo.MutationFunction<ResendUserInvitationMutation, ResendUserInvitationMutationVariables>;

/**
 * __useResendUserInvitationMutation__
 *
 * To run a mutation, you first call `useResendUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendUserInvitationMutation, { data, loading, error }] = useResendUserInvitationMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResendUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendUserInvitationMutation, ResendUserInvitationMutationVariables>) {
        return Apollo.useMutation<ResendUserInvitationMutation, ResendUserInvitationMutationVariables>(ResendUserInvitationDocument, baseOptions);
      }
export type ResendUserInvitationMutationHookResult = ReturnType<typeof useResendUserInvitationMutation>;
export type ResendUserInvitationMutationResult = Apollo.MutationResult<ResendUserInvitationMutation>;
export type ResendUserInvitationMutationOptions = Apollo.BaseMutationOptions<ResendUserInvitationMutation, ResendUserInvitationMutationVariables>;