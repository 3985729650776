import React, { createContext, useContext } from "react";

import { DocumentsUploadDialog } from "./DocumentsUploadDialog";
import { UploadManager, useUploadManager } from "./useUploadManager";

const DocumentsUploadContext = createContext<UploadManager>(null as any);

export const useDocumentsUpload = () => useContext(DocumentsUploadContext);

export const DocumentsUploadProvider: React.FC = ({ children }) => {
  const manager = useUploadManager();

  return (
    <DocumentsUploadContext.Provider value={manager}>
      <DocumentsUploadDialog />
      {children}
    </DocumentsUploadContext.Provider>
  );
};
