import { useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";

import { DocumentsCurrent } from "~/components/Documents/useDocumentsCurrent";
import { DocumentsLinks } from "~/components/Documents/useDocumentsLinks";
import { DocumentsRouting } from "~/components/Documents/useDocumentsRouting";
import { DocumentsTableData } from "~/components/Documents/useDocumentsTableData";

import {
  DocumentsRemoveDryRunMutation,
  useDocumentsRemoveDryRunMutation,
  useDocumentsRemoveMutation,
} from "./DocumentsAPI.generated";

export type DocumentsRemoveDryRunResult =
  | DocumentsRemoveDryRunMutation["removeDocumentsDryRun"]
  | null;

export interface DocumentsRemove {
  stagedCount: number;
  stagedIds: readonly string[];
  error: string | null;
  dryRunResult: DocumentsRemoveDryRunResult;
  stageCurrent(): Promise<void>;
  stageDocument(documentId: string): Promise<void>;
  stageSelected(): Promise<void>;
  stageSelectedDisabled: boolean;
  confirm(): Promise<void>;
  confirmDisabled: boolean;
  confirmLoading: boolean;
  confirmVisible: boolean;
  reset(): void;
}

/** Handles state and logic required for removing documents. */
export const useDocumentsRemove = (
  treeId: string,
  { history, documentId }: DocumentsRouting,
  { getLink }: DocumentsLinks,
  { nearestId }: DocumentsCurrent,
  { selection }: DocumentsTableData
): DocumentsRemove => {
  const client = useApolloClient();
  const [stagedIds, setStagedIds] = useState<readonly string[]>([]);
  const stagedCount = stagedIds.length;
  const [error, setError] = useState<string | null>(null);
  const [dryRunResult, setDryRunResult] = useState<
    DocumentsRemoveDryRunMutation["removeDocumentsDryRun"] | null
  >(null);

  const [requestDryRun] = useDocumentsRemoveDryRunMutation();
  const [request, { loading: confirmLoading }] = useDocumentsRemoveMutation();
  const confirmDisabled = !dryRunResult || confirmLoading || error != null;
  const confirmVisible = stagedCount > 0;

  /**
   * Opens a confirmation dialog where the user can confirm and remove th
   * given set of documents
   */
  const stageDocuments = useCallback(
    async (documentIds: readonly string[]) => {
      setStagedIds(documentIds);
      try {
        const { data } = await requestDryRun({
          variables: { treeId, documentIds },
        });
        if (!data?.removeDocumentsDryRun?.length) {
          throw new Error("No documents returned from remove dry run");
        }
        setDryRunResult(data.removeDocumentsDryRun);
      } catch (error) {
        setError(error.message);
      }
    },
    [requestDryRun, treeId]
  );

  const stageCurrent = useCallback(
    () => stageDocuments([documentId]),
    [stageDocuments, documentId]
  );

  const stageDocument = useCallback(
    (id: string) => stageDocuments([id]),
    [stageDocuments]
  );

  const stageSelectedDisabled = selection.count === 0;
  const stageSelected = useCallback(
    () => stageDocuments(selection.values().map((d) => d.id)),
    [stageDocuments, selection]
  );

  /** Closes the confirmation dialog */
  const reset = useCallback(() => {
    setStagedIds([]);
    setError(null);
    setDryRunResult(null);
  }, []);

  /**
   * Removes deleted documents from the cache and redirects the user if they
   * were viewing a deleted document
   */
  const cleanup = useCallback(
    (deletedIds: readonly (string | null)[]) => {
      for (const id of deletedIds) {
        client.cache.evict({ id: `Document:${id}` });
      }
      if (deletedIds.includes(documentId)) {
        history.replace(getLink(nearestId));
      }
    },
    [client.cache, history, documentId, nearestId, getLink]
  );

  /**
   * Deletes all documents that are staged for removal and then closes the
   * confirmation dialog
   */
  const confirm = useCallback(async () => {
    try {
      const { data } = await request({
        variables: { treeId, documentIds: stagedIds },
        refetchQueries: ["OAndM"],
      });
      const deletedIds = data?.removeDocuments?.deletedIds ?? [];
      cleanup(deletedIds);
      reset();
    } catch (error) {
      setError("A server error has occurred");
      console.error(error);
    }
  }, [request, cleanup, reset, treeId, stagedIds]);

  return {
    stagedCount,
    stagedIds,
    error,
    dryRunResult,
    stageCurrent,
    stageDocument,
    stageSelected,
    stageSelectedDisabled,
    confirm,
    confirmDisabled,
    confirmLoading,
    confirmVisible,
    reset,
  };
};
