import { Milestone } from "~/api/types.generated";
import { Timeline_DetailsFragment } from "~/components/Timeline/TimelineAPI.generated";
import { useEventTracking } from "~/event-tracking/EventTrackingProvider";

const getMilestoneProps = (milestone: Pick<Milestone, "id" | "title">) => ({
  "Milestone ID": milestone.id,
  "Milestone Title": milestone.title,
});

export const useTimelineEventTracking = (
  timeline?: Timeline_DetailsFragment | null
) =>
  useEventTracking(
    "Timeline",
    {
      view: "Viewed",
      editClick: ["Clicked Edit Milestone", getMilestoneProps],
      editCancel: ["Canceled Editing Milestone", getMilestoneProps],
      editConfirm: ["Saved Milestone Edits", getMilestoneProps],
      deleteClick: ["Clicked Delete Milestone", getMilestoneProps],
      deleteConfirm: ["Deleted Milestone", getMilestoneProps],
      deleteCancel: ["Canceled Delete Milestone", getMilestoneProps],
      reorderClick: ["Reordered Milestones", getMilestoneProps],
      createClick: ["Created New Milestone", getMilestoneProps],
    },
    {
      "Timeline ID": timeline?.id,
      "Timeline Name": timeline?.name,
    }
  );
