import { useCallback, useEffect, useState } from "react";

import {
  Document_InfoFragment,
  useDocumentsRenameMutation,
} from "./DocumentsAPI.generated";
import { DocumentsRouting } from "./useDocumentsRouting";
import { DocumentsSearch } from "./useDocumentsSearch";

export interface DocumentsRenameError {
  message?: string | null;
  name: string;
  type: string;
}

export interface DocumentsRename {
  error: DocumentsRenameError | null;
  stageCurrent(): void;
  stageDocument(documentId: string): void;
  confirm(document: Document_InfoFragment, name: string): Promise<void>;
  confirmLoading: boolean;
  confirmVisible(documentId: string): boolean;
  reset(): void;
}

/** Handles state and logic required for renaming documents. */
export const useDocumentsRename = (
  treeId: string,
  { documentId, view }: DocumentsRouting,
  { showSearch }: DocumentsSearch
): DocumentsRename => {
  const [stagedId, setStagedId] = useState<string | null>(null);
  const [error, setError] = useState<DocumentsRenameError | null>(null);
  const [request, { loading: confirmLoading }] = useDocumentsRenameMutation();
  const confirmVisible = useCallback(
    (documentId: string) => documentId === stagedId,
    [stagedId]
  );

  const stageCurrent = useCallback(() => setStagedId(documentId), [documentId]);
  const stageDocument = useCallback((id: string) => setStagedId(id), []);

  const reset = useCallback(() => {
    setStagedId(null);
    setError(null);
  }, []);

  // Cancel the rename operation on navigation
  useEffect(reset, [documentId, view, showSearch, reset]);

  /** Commits a document rename request and resets the rename state */
  const confirm = useCallback(
    async (document: Document_InfoFragment, name: string) => {
      if (!name) {
        return setError(
          getError("This field may not be blank", name, document)
        );
      }

      try {
        const { data } = await request({
          variables: { treeId, documentId: document.id, name },
        });
        setStagedId(null);
        setError(getError(data?.renameDocument?.error, name, document));
      } catch (error) {
        setError(getError("A server error has occurred", name, document));
      }
    },
    [request, treeId]
  );

  return {
    error,
    stageCurrent,
    stageDocument,
    confirm,
    confirmLoading,
    confirmVisible,
    reset,
  };
};

const getError = (
  message: string | null | undefined,
  name: string,
  document: Document_InfoFragment
): DocumentsRenameError | null =>
  message ? { message, name, type: document.fileType ?? "Document" } : null;
