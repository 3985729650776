import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

const PowerIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M11.5 19h1v-1.85l3.5-3.5V9H8v4.65l3.5 3.5Zm-2 2v-3L6 14.5V9q0-.825.588-1.413Q7.175 7 8 7h1L8 8V3h2v4h4V3h2v5l-1-1h1q.825 0 1.413.587Q18 8.175 18 9v5.5L14.5 18v3Zm2.5-7Z" />
  </SvgIcon>
);

export default PowerIcon;
