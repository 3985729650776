import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { gettext, interpolate, ngettext } from "utils/text";

export const CommentsForm = ({ submitComment, cellsSelected }) => {
  const [buttonsVisible, setButtonsVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const renderWarning = () => {
    if (cellsSelected === 1) {
      return null;
    }
    const format = ngettext(
      "Note! Your comment will be added to %(count)s selected cell.",
      "Note! Your comment will be added to all %(count)s selected cells.",
      cellsSelected
    );
    const warning = interpolate(format, { count: cellsSelected }, true);
    return <span>{warning}</span>;
  };

  const submitCommentWrapper = () => {
    submitComment(inputValue);
    setInputValue("");
  };

  const renderButtons = () => (
    <div className="mb-2 mt-1">
      <button
        type="button"
        className="btn btn-primary"
        disabled={!inputValue}
        onClick={submitCommentWrapper}
      >
        {gettext("Submit")}
      </button>
      <button
        className="btn btn-outline-primary custom-margin-left"
        onClick={() => {
          setInputValue("");
          setButtonsVisible(false);
        }}
        type="button"
      >
        {gettext("Cancel")}
      </button>
    </div>
  );

  return (
    <div key="comments-form" className="comment-submit mb-2">
      <input
        type="text"
        className="form-control mt-3 mb-2 checklist-modal-no-navigation"
        onFocus={() => setButtonsVisible(true)}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter" && inputValue) submitCommentWrapper();
        }}
        value={inputValue}
        placeholder={gettext("New Comment")}
      />
      {renderWarning()}
      {buttonsVisible && renderButtons()}
    </div>
  );
};

CommentsForm.propTypes = {
  cellsSelected: PropTypes.number.isRequired,
  submitComment: PropTypes.func.isRequired,
};

export default CommentsForm;
