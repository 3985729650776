// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { ProjectsBannerFragment } from './ProjectsBanner.generated';
import { ProjectsMapFragment } from './ProjectsMap.generated';
import { ProjectsTableFragment } from './ProjectsTable.generated';
import { ProjectsRadiusTableFragment } from './ProjectsRadiusTable.generated';
import { ProjectDetailFragment } from './ProjectPage.generated';
import { gql } from '@apollo/client';
import { ProjectsBannerFragmentDoc } from './ProjectsBanner.generated';
import { ProjectsMapFragmentDoc } from './ProjectsMap.generated';
import { ProjectsTableFragmentDoc } from './ProjectsTable.generated';
import { ProjectsRadiusTableFragmentDoc } from './ProjectsRadiusTable.generated';
import { ProjectDetailFragmentDoc } from './ProjectPage.generated';
import * as Apollo from '@apollo/client';
export type ProjectsPageQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  portfolioIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
  workflowStatus?: Types.Maybe<Types.Scalars['Int']>;
  isOwned?: Types.Maybe<Types.Scalars['Boolean']>;
  search?: Types.Maybe<Types.Scalars['String']>;
  ordering?: Types.Maybe<Types.Scalars['String']>;
}>;


export type ProjectsPageQuery = (
  { readonly __typename: 'Query' }
  & { readonly projectsData?: Types.Maybe<(
    { readonly __typename: 'ProjectsCollection' }
    & ProjectsBannerFragment
    & ProjectsMapFragment
    & ProjectsTableFragment
    & ProjectsRadiusTableFragment
  )> }
);

export type ProjectQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID'];
  portfolioIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
  portfolioStatuses?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.PortfolioStatus>> | Types.Maybe<Types.PortfolioStatus>>;
}>;


export type ProjectQuery = (
  { readonly __typename: 'Query' }
  & { readonly project?: Types.Maybe<(
    { readonly __typename: 'Project' }
    & ProjectDetailFragment
  )> }
);


export const ProjectsPageDocument = gql`
    query ProjectsPage($orgSlug: String!, $portfolioIds: [ID], $workflowStatus: Int, $isOwned: Boolean, $search: String, $ordering: String) {
  projectsData: projects(
    orgSlug: $orgSlug
    portfolioIds: $portfolioIds
    workflowStatus: $workflowStatus
    isOwned: $isOwned
    search: $search
    ordering: $ordering
  ) {
    ...ProjectsBanner
    ...ProjectsMap
    ...ProjectsTable
    ...ProjectsRadiusTable
  }
}
    ${ProjectsBannerFragmentDoc}
${ProjectsMapFragmentDoc}
${ProjectsTableFragmentDoc}
${ProjectsRadiusTableFragmentDoc}`;

/**
 * __useProjectsPageQuery__
 *
 * To run a query within a React component, call `useProjectsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsPageQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      portfolioIds: // value for 'portfolioIds'
 *      workflowStatus: // value for 'workflowStatus'
 *      isOwned: // value for 'isOwned'
 *      search: // value for 'search'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useProjectsPageQuery(baseOptions: Apollo.QueryHookOptions<ProjectsPageQuery, ProjectsPageQueryVariables>) {
        return Apollo.useQuery<ProjectsPageQuery, ProjectsPageQueryVariables>(ProjectsPageDocument, baseOptions);
      }
export function useProjectsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsPageQuery, ProjectsPageQueryVariables>) {
          return Apollo.useLazyQuery<ProjectsPageQuery, ProjectsPageQueryVariables>(ProjectsPageDocument, baseOptions);
        }
export type ProjectsPageQueryHookResult = ReturnType<typeof useProjectsPageQuery>;
export type ProjectsPageLazyQueryHookResult = ReturnType<typeof useProjectsPageLazyQuery>;
export type ProjectsPageQueryResult = Apollo.QueryResult<ProjectsPageQuery, ProjectsPageQueryVariables>;
export const ProjectDocument = gql`
    query Project($projectId: ID!, $portfolioIds: [ID], $portfolioStatuses: [PortfolioStatus]) {
  project(projectId: $projectId) {
    ...ProjectDetail
  }
}
    ${ProjectDetailFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      portfolioIds: // value for 'portfolioIds'
 *      portfolioStatuses: // value for 'portfolioStatuses'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, baseOptions);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, baseOptions);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;