import PropTypes from "prop-types";
import React from "react";
import { gettext } from "utils/text";

import ModalSelect from "./ModalSelect";

const UserSelect = (props) => {
  const items = Object.values(props.users)
    .sort((a, b) => [a.fullName].toString().localeCompare(b.fullName))
    .map(({ id, fullName }) => [id, fullName]);
  return (
    <ModalSelect
      name="userId"
      inputLabel={gettext("Assigned to")}
      items={[["", "-----"], ...items]}
      {...props}
    />
  );
};

UserSelect.propTypes = {
  users: PropTypes.shape({}).isRequired,
};

export default UserSelect;
