// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type NotificationFragment = (
  { readonly __typename: 'NotificationSetting' }
  & Pick<Types.NotificationSetting, 'id' | 'enabled'>
);

export type PortfolioNotificationFragment = (
  { readonly __typename: 'PortfolioNotificationSetting' }
  & Pick<Types.PortfolioNotificationSetting, 'id' | 'name' | 'enabled'>
);

export type HoldCoNotificationFragment = (
  { readonly __typename: 'HoldCoNotificationSetting' }
  & Pick<Types.HoldCoNotificationSetting, 'id' | 'name' | 'enabled'>
);

export type NotificationPageQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
  orgSlug: Types.Scalars['String'];
}>;


export type NotificationPageQuery = (
  { readonly __typename: 'Query' }
  & { readonly notificationSettings?: Types.Maybe<(
    { readonly __typename: 'NotificationSetting' }
    & NotificationFragment
  )>, readonly portfolioNotifications?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'PortfolioNotificationSetting' }
    & PortfolioNotificationFragment
  )>>, readonly holdCoNotifications?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'HoldCoNotificationSetting' }
    & HoldCoNotificationFragment
  )>> }
);

export type UpdateNotificationSettingsMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
  orgSlug: Types.Scalars['String'];
  enabled: Types.Scalars['Boolean'];
}>;


export type UpdateNotificationSettingsMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateNotificationSettings?: Types.Maybe<(
    { readonly __typename: 'NotificationSetting' }
    & NotificationFragment
  )> }
);

export type UpdatePortfolioNotificationSettingsMutationVariables = Types.Exact<{
  portfolioId: Types.Scalars['ID'];
  userId: Types.Scalars['ID'];
  enabled: Types.Scalars['Boolean'];
}>;


export type UpdatePortfolioNotificationSettingsMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updatePortfolioNotification?: Types.Maybe<(
    { readonly __typename: 'PortfolioNotificationSetting' }
    & PortfolioNotificationFragment
  )> }
);

export type UpdateHoldCoNotificationSettingsMutationVariables = Types.Exact<{
  holdingCompanyId: Types.Scalars['ID'];
  userId: Types.Scalars['ID'];
  enabled: Types.Scalars['Boolean'];
}>;


export type UpdateHoldCoNotificationSettingsMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateHoldCoNotification?: Types.Maybe<(
    { readonly __typename: 'HoldCoNotificationSetting' }
    & HoldCoNotificationFragment
  )> }
);

export type NotificationsPageViewerFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id'>
);

export const NotificationFragmentDoc = gql`
    fragment Notification on NotificationSetting {
  id
  enabled
}
    `;
export const PortfolioNotificationFragmentDoc = gql`
    fragment PortfolioNotification on PortfolioNotificationSetting {
  id
  name
  enabled
}
    `;
export const HoldCoNotificationFragmentDoc = gql`
    fragment HoldCoNotification on HoldCoNotificationSetting {
  id
  name
  enabled
}
    `;
export const NotificationsPageViewerFragmentDoc = gql`
    fragment NotificationsPageViewer on User {
  id
}
    `;
export const NotificationPageDocument = gql`
    query NotificationPage($userId: ID!, $orgSlug: String!) {
  notificationSettings(userId: $userId, orgSlug: $orgSlug) {
    ...Notification
  }
  portfolioNotifications(userId: $userId, orgSlug: $orgSlug) {
    ...PortfolioNotification
  }
  holdCoNotifications(userId: $userId, orgSlug: $orgSlug) {
    ...HoldCoNotification
  }
}
    ${NotificationFragmentDoc}
${PortfolioNotificationFragmentDoc}
${HoldCoNotificationFragmentDoc}`;

/**
 * __useNotificationPageQuery__
 *
 * To run a query within a React component, call `useNotificationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationPageQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useNotificationPageQuery(baseOptions: Apollo.QueryHookOptions<NotificationPageQuery, NotificationPageQueryVariables>) {
        return Apollo.useQuery<NotificationPageQuery, NotificationPageQueryVariables>(NotificationPageDocument, baseOptions);
      }
export function useNotificationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationPageQuery, NotificationPageQueryVariables>) {
          return Apollo.useLazyQuery<NotificationPageQuery, NotificationPageQueryVariables>(NotificationPageDocument, baseOptions);
        }
export type NotificationPageQueryHookResult = ReturnType<typeof useNotificationPageQuery>;
export type NotificationPageLazyQueryHookResult = ReturnType<typeof useNotificationPageLazyQuery>;
export type NotificationPageQueryResult = Apollo.QueryResult<NotificationPageQuery, NotificationPageQueryVariables>;
export const UpdateNotificationSettingsDocument = gql`
    mutation UpdateNotificationSettings($userId: ID!, $orgSlug: String!, $enabled: Boolean!) {
  updateNotificationSettings(
    userId: $userId
    orgSlug: $orgSlug
    enabled: $enabled
  ) {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;
export type UpdateNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>;

/**
 * __useUpdateNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingsMutation, { data, loading, error }] = useUpdateNotificationSettingsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      orgSlug: // value for 'orgSlug'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>) {
        return Apollo.useMutation<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>(UpdateNotificationSettingsDocument, baseOptions);
      }
export type UpdateNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationResult = Apollo.MutationResult<UpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>;
export const UpdatePortfolioNotificationSettingsDocument = gql`
    mutation UpdatePortfolioNotificationSettings($portfolioId: ID!, $userId: ID!, $enabled: Boolean!) {
  updatePortfolioNotification(
    portfolioId: $portfolioId
    userId: $userId
    enabled: $enabled
  ) {
    ...PortfolioNotification
  }
}
    ${PortfolioNotificationFragmentDoc}`;
export type UpdatePortfolioNotificationSettingsMutationFn = Apollo.MutationFunction<UpdatePortfolioNotificationSettingsMutation, UpdatePortfolioNotificationSettingsMutationVariables>;

/**
 * __useUpdatePortfolioNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioNotificationSettingsMutation, { data, loading, error }] = useUpdatePortfolioNotificationSettingsMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      userId: // value for 'userId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdatePortfolioNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePortfolioNotificationSettingsMutation, UpdatePortfolioNotificationSettingsMutationVariables>) {
        return Apollo.useMutation<UpdatePortfolioNotificationSettingsMutation, UpdatePortfolioNotificationSettingsMutationVariables>(UpdatePortfolioNotificationSettingsDocument, baseOptions);
      }
export type UpdatePortfolioNotificationSettingsMutationHookResult = ReturnType<typeof useUpdatePortfolioNotificationSettingsMutation>;
export type UpdatePortfolioNotificationSettingsMutationResult = Apollo.MutationResult<UpdatePortfolioNotificationSettingsMutation>;
export type UpdatePortfolioNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdatePortfolioNotificationSettingsMutation, UpdatePortfolioNotificationSettingsMutationVariables>;
export const UpdateHoldCoNotificationSettingsDocument = gql`
    mutation UpdateHoldCoNotificationSettings($holdingCompanyId: ID!, $userId: ID!, $enabled: Boolean!) {
  updateHoldCoNotification(
    holdingCompanyId: $holdingCompanyId
    userId: $userId
    enabled: $enabled
  ) {
    ...HoldCoNotification
  }
}
    ${HoldCoNotificationFragmentDoc}`;
export type UpdateHoldCoNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateHoldCoNotificationSettingsMutation, UpdateHoldCoNotificationSettingsMutationVariables>;

/**
 * __useUpdateHoldCoNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateHoldCoNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHoldCoNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHoldCoNotificationSettingsMutation, { data, loading, error }] = useUpdateHoldCoNotificationSettingsMutation({
 *   variables: {
 *      holdingCompanyId: // value for 'holdingCompanyId'
 *      userId: // value for 'userId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateHoldCoNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHoldCoNotificationSettingsMutation, UpdateHoldCoNotificationSettingsMutationVariables>) {
        return Apollo.useMutation<UpdateHoldCoNotificationSettingsMutation, UpdateHoldCoNotificationSettingsMutationVariables>(UpdateHoldCoNotificationSettingsDocument, baseOptions);
      }
export type UpdateHoldCoNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateHoldCoNotificationSettingsMutation>;
export type UpdateHoldCoNotificationSettingsMutationResult = Apollo.MutationResult<UpdateHoldCoNotificationSettingsMutation>;
export type UpdateHoldCoNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateHoldCoNotificationSettingsMutation, UpdateHoldCoNotificationSettingsMutationVariables>;