import { Icon } from "components/icons";
import * as PropTypes from "prop-types";
import React from "react";

class PencilIcon extends React.PureComponent {
  render() {
    return (
      <Icon
        color="dark"
        className={this.props.className}
        viewBox="0 0 16 16"
        withoutBackground={this.props.withoutBackground}
        onClick={this.props.onClick}
      >
        <path
          fillRule="evenodd"
          d="M9.453125,2.74414062 L13.2578125,6.54882812 L4.75,15.0566406 L0,16.0019531 L0.9453125,11.2519531
                    L9.453125,2.74414062 Z M15.2109375,0.791015625 C16.265625,1.83789062 16.265625,3.54882812
                    15.2109375,4.59570312 L13.96875,5.84570312 L10.1640625,2.04101562 L11.40625,0.791015625
                    C12.4609375,-0.263671875 14.1640625,-0.263671875 15.2109375,0.791015625 Z"
        />
      </Icon>
    );
  }
}

PencilIcon.propTypes = {
  className: PropTypes.string,
  withoutBackground: PropTypes.bool,
  onClick: PropTypes.func,
};

PencilIcon.defaultProps = {
  className: null,
  withoutBackground: false,
  onClick: null,
};

export default PencilIcon;
