// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type OrgGroupFragment = (
  { readonly __typename: 'UserGroup' }
  & Pick<Types.UserGroup, 'id' | 'name'>
);

export type OrgPortfolioFragment = (
  { readonly __typename: 'Portfolio' }
  & Pick<Types.Portfolio, 'id' | 'name'>
);

export type InviteUserMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  email: Types.Scalars['String'];
  title?: Types.Maybe<Types.Scalars['String']>;
  groupId: Types.Scalars['ID'];
}>;


export type InviteUserMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createUserInvitation?: Types.Maybe<(
    { readonly __typename: 'OrgInvitation' }
    & Pick<Types.OrgInvitation, 'token'>
  )> }
);

export const OrgGroupFragmentDoc = gql`
    fragment OrgGroup on UserGroup {
  id
  name
}
    `;
export const OrgPortfolioFragmentDoc = gql`
    fragment OrgPortfolio on Portfolio {
  id
  name
}
    `;
export const InviteUserDocument = gql`
    mutation InviteUser($orgSlug: String!, $firstName: String!, $lastName: String!, $email: String!, $title: String, $groupId: ID!) {
  createUserInvitation(
    orgSlug: $orgSlug
    firstName: $firstName
    lastName: $lastName
    email: $email
    title: $title
    groupId: $groupId
  ) {
    token
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      title: // value for 'title'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, baseOptions);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;