import { gql } from "@apollo/client/core";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers as FormikActions,
} from "formik";
import { trim } from "lodash";
import React, { useCallback } from "react";
import * as yup from "yup";

import {
  CheckPasswordFormFragment,
  useCheckPasswordMutation,
} from "~/components/forms/PasswordConfirm.generated";

export const CHECK_PASSWORD_FORM_FRAGMENT = gql`
  fragment CheckPasswordForm on User {
    id
    email
  }
`;

export const CHECK_PASSWORD_FORM_MUTATION_FRAGMENT = gql`
  mutation CheckPassword($id: ID!, $password: String!) {
    checkUserPassword(id: $id, password: $password) {
      ...CheckPasswordForm
    }
  }

  ${CHECK_PASSWORD_FORM_FRAGMENT}
`;

export interface CheckPasswordFormProps {
  user: CheckPasswordFormFragment;
  closeModal: () => void;
  togglePhoneUpdateVisible: () => void;
}

const validationSchema = yup.object().shape({
  password: yup.string().required("This field is required"),
});

interface FormFields {
  password: string;
}

export const ConfirmPasswordForm = ({
  user,
  closeModal,
  togglePhoneUpdateVisible,
}: CheckPasswordFormProps) => {
  const [check, { loading: isSubmitting }] = useCheckPasswordMutation({
    onCompleted: () => {
      togglePhoneUpdateVisible();
      closeModal();
    },
  });
  const handleSubmit = useCallback(
    async (
      formFields: FormFields,
      actions: FormikActions<CheckPasswordFormFragment & FormFields>
    ) => {
      try {
        await check({
          variables: { id: user.id, password: formFields.password },
        });
        togglePhoneUpdateVisible();
        closeModal();
      } catch (error) {
        actions.setFieldError("password", trim(error.message, "'"));
      } finally {
        actions.setSubmitting(false);
      }
    },
    [check, closeModal, togglePhoneUpdateVisible, user.id]
  );
  return (
    <Formik
      initialValues={{
        ...user,
        password: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <p>
          <span>For security, please enter your password for </span>
          {DJ_CONST.user.email}
        </p>
        <div className="form-group">
          <Field
            className="input-group form-control"
            type="password"
            id="password"
            name="password"
          />
          <p className="text-danger">
            <ErrorMessage name="password" />
          </p>
        </div>
        <div className="form-group mt-4 p-2">
          <button
            type="button"
            className="btn btn-outline-primary btn-lg mr-4"
            disabled={isSubmitting}
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-lg"
            disabled={isSubmitting}
          >
            Next
          </button>
        </div>
      </Form>
    </Formik>
  );
};
