import CategoryEditor from "components/CategoryEditor";
import React from "react";
import ReactDOM from "react-dom";

export default function initCategoryEditor(selector, categoriesId) {
  const elem = document.querySelector(selector);
  if (!elem || !categoriesId) {
    return;
  }

  ReactDOM.render(<CategoryEditor categoriesId={categoriesId} />, elem);
}
