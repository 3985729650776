import React from "react";
import styled from "styled-components";

import { blue, gray300 } from "~/styles/theme/color";

export interface CircleProgressProps {
  percent?: number;
  size?: number;
  thickness?: number;
  color?: string;
  trackColor?: string;
}

export const CircleProgress = (props: CircleProgressProps) => {
  const {
    percent = 0,
    size = 16,
    thickness = 0.25,
    color = blue,
    trackColor = gray300,
  } = props;

  const radius = size / 2;
  const strokeWidth = radius * thickness;
  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDasharray = circumference + " " + circumference;
  const strokeDashoffset = circumference - (percent / 100) * circumference;

  return (
    <Root height={size} width={size}>
      <Circle
        stroke={trackColor}
        strokeWidth={strokeWidth}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <Circle
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </Root>
  );
};

const Root = styled.svg``;

const Circle = styled.circle`
  fill: transparent;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;
