import { LocationDescriptorObject } from "history";
import { compact } from "lodash";
import React from "react";
import { Link } from "react-router-dom";

import { Breadcrumbs } from "~/components/common/breadcrumbs";
import { SkeletonText } from "~/components/common/skeletons";

import {
  Document_BreadcrumbsFragment,
  Document_InfoFragment,
} from "./DocumentsAPI.generated";
import { View } from "./useDocumentsRouting";

export interface DocumentsBreadcrumbsProps {
  view: View;
  showSearch: boolean;
  document?: null | Document_BreadcrumbsFragment;
  getLink: (documentId: string, view?: View) => LocationDescriptorObject;
  error: string | null;
  basePath: string;
}

export const DocumentsBreadcrumbs = React.memo(function DocumentsBreadcrumbs(
  props: DocumentsBreadcrumbsProps
) {
  const { view, showSearch, document, getLink, error, basePath } = props;
  const ancestors = compact(document?.ancestors ?? []);

  const renderLink = (document?: Document_InfoFragment | null) => {
    if (!document?.id) return <SkeletonText />;
    const { id, name } = document;
    const url = getLink(
      id,
      view === "single" || view === "columns" ? view : "single"
    );
    return <Link key={id} to={url} children={name} />;
  };

  return (
    <Breadcrumbs>
      {ancestors.map(renderLink)}
      {(showSearch || view === "upload" || view === "history") &&
        renderLink(document)}
      {error &&
        ["1", "2"].map((n) => {
          if (n === "1")
            return (
              <Link key="Documents" to={basePath} children={"Documents"} />
            );
          return <span>{error}</span>;
        })}
      {!error && (
        <span>
          {(view === "single" || view === "columns") &&
            (showSearch ? "Search" : document?.name ?? <SkeletonText />)}
          {view === "history" && (showSearch ? "Search History" : "History")}
          {view === "upload" && "Upload"}
        </span>
      )}
    </Breadcrumbs>
  );
});
