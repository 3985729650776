import { SubpageOverviewFragment } from "~/components/Overview/OverviewPage.generated";
import { useEventTracking } from "~/event-tracking/EventTrackingProvider";

export const useHoldingCompanyEventTracking = () =>
  useEventTracking("Holding Company", {
    overviewView: [
      "Viewed Overview",
      (subpage: SubpageOverviewFragment) => ({
        "Subpage Title": subpage.title,
        "Subpage ID": subpage.id,
      }),
    ],
    portfoliosView: "Viewed Portfolios",
    portfoliosSearch: [
      "Searched Portfolios",
      (query: string) => ({ "Search Query": query }),
    ],
    documentsView: "Viewed Documents",
    reportsView: "Viewed Reports",
    qandaView: "Viewed Q&A",
  });
