// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type DealRoomCard_HoldingCompany_Fragment = (
  { readonly __typename: 'HoldingCompany' }
  & Pick<Types.HoldingCompany, 'id' | 'name' | 'slug' | 'status' | 'coverImage' | 'systemSize'>
  & { readonly dealManager?: Types.Maybe<(
    { readonly __typename: 'User' }
    & Pick<Types.User, 'fullName'>
  )>, readonly org: (
    { readonly __typename: 'Organization' }
    & Pick<Types.Organization, 'name' | 'slug'>
  ) }
);

export type DealRoomCard_Portfolio_Fragment = (
  { readonly __typename: 'Portfolio' }
  & Pick<Types.Portfolio, 'buildStatus' | 'id' | 'name' | 'slug' | 'status' | 'coverImage' | 'systemSize'>
  & { readonly dealManager?: Types.Maybe<(
    { readonly __typename: 'User' }
    & Pick<Types.User, 'fullName'>
  )>, readonly org: (
    { readonly __typename: 'Organization' }
    & Pick<Types.Organization, 'name' | 'slug'>
  ) }
);

export type DealRoomCardFragment = DealRoomCard_HoldingCompany_Fragment | DealRoomCard_Portfolio_Fragment;

export const DealRoomCardFragmentDoc = gql`
    fragment DealRoomCard on FundEntity {
  __typename
  id
  name
  slug
  status
  coverImage
  systemSize
  dealManager {
    fullName
  }
  org {
    name
    slug
  }
  ... on Portfolio {
    buildStatus
  }
}
    `;