// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "DocumentPrincipal": [
      "UserGroup",
      "Role"
    ],
    "FundEntity": [
      "HoldingCompany",
      "Portfolio"
    ],
    "Manufacturer": [
      "InverterManufacturer",
      "PanelManufacturer"
    ],
    "ManufacturerModel": [
      "InverterModel",
      "PanelModel"
    ],
    "SubpageInterface": [
      "HoldCoSubpage",
      "Subpage"
    ]
  }
};
      export default result;
    
export type CategoryKeySpecifier = ('id' | 'name' | 'order' | 'isDevelopment' | 'isOperating' | 'children' | 'parentId' | 'rowNumber' | CategoryKeySpecifier)[];
export type CategoryFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	order?: FieldPolicy<any> | FieldReadFunction<any>,
	isDevelopment?: FieldPolicy<any> | FieldReadFunction<any>,
	isOperating?: FieldPolicy<any> | FieldReadFunction<any>,
	children?: FieldPolicy<any> | FieldReadFunction<any>,
	parentId?: FieldPolicy<any> | FieldReadFunction<any>,
	rowNumber?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ChecklistKeySpecifier = ('id' | 'name' | 'items' | 'categories' | ChecklistKeySpecifier)[];
export type ChecklistFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	items?: FieldPolicy<any> | FieldReadFunction<any>,
	categories?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CompanyKeySpecifier = ('id' | 'name' | 'logo' | 'logoUrl' | CompanyKeySpecifier)[];
export type CompanyFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	logo?: FieldPolicy<any> | FieldReadFunction<any>,
	logoUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CompanyAggsKeySpecifier = ('id' | 'company' | 'userCount' | 'sessionCount' | 'downloadCount' | 'viewCount' | CompanyAggsKeySpecifier)[];
export type CompanyAggsFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	company?: FieldPolicy<any> | FieldReadFunction<any>,
	userCount?: FieldPolicy<any> | FieldReadFunction<any>,
	sessionCount?: FieldPolicy<any> | FieldReadFunction<any>,
	downloadCount?: FieldPolicy<any> | FieldReadFunction<any>,
	viewCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ConfirmUploadsResultKeySpecifier = ('files' | 'failedIds' | ConfirmUploadsResultKeySpecifier)[];
export type ConfirmUploadsResultFieldPolicy = {
	files?: FieldPolicy<any> | FieldReadFunction<any>,
	failedIds?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateMilestoneMutationKeySpecifier = ('timeline' | CreateMilestoneMutationKeySpecifier)[];
export type CreateMilestoneMutationFieldPolicy = {
	timeline?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreatePresignedFilesResultKeySpecifier = ('url' | 'version' | 'file' | CreatePresignedFilesResultKeySpecifier)[];
export type CreatePresignedFilesResultFieldPolicy = {
	url?: FieldPolicy<any> | FieldReadFunction<any>,
	version?: FieldPolicy<any> | FieldReadFunction<any>,
	file?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateTimelineMutationKeySpecifier = ('timeline' | CreateTimelineMutationKeySpecifier)[];
export type CreateTimelineMutationFieldPolicy = {
	timeline?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteMilestoneMutationKeySpecifier = ('timeline' | DeleteMilestoneMutationKeySpecifier)[];
export type DeleteMilestoneMutationFieldPolicy = {
	timeline?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletedInvitationResultKeySpecifier = ('token' | DeletedInvitationResultKeySpecifier)[];
export type DeletedInvitationResultFieldPolicy = {
	token?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletedObjectResultKeySpecifier = ('id' | DeletedObjectResultKeySpecifier)[];
export type DeletedObjectResultFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DocumentKeySpecifier = ('id' | 'name' | 'fileType' | 'size' | 'modifiedTimestamp' | 'active' | 'labels' | 'parentId' | 'currentVersion' | 'filePath' | 'dirPath' | 'thumbnailUrl' | 'previewUrl' | 'permissions' | 'link' | 'children' | 'parent' | 'ancestors' | 'descendants' | DocumentKeySpecifier)[];
export type DocumentFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	fileType?: FieldPolicy<any> | FieldReadFunction<any>,
	size?: FieldPolicy<any> | FieldReadFunction<any>,
	modifiedTimestamp?: FieldPolicy<any> | FieldReadFunction<any>,
	active?: FieldPolicy<any> | FieldReadFunction<any>,
	labels?: FieldPolicy<any> | FieldReadFunction<any>,
	parentId?: FieldPolicy<any> | FieldReadFunction<any>,
	currentVersion?: FieldPolicy<any> | FieldReadFunction<any>,
	filePath?: FieldPolicy<any> | FieldReadFunction<any>,
	dirPath?: FieldPolicy<any> | FieldReadFunction<any>,
	thumbnailUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	previewUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	permissions?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	children?: FieldPolicy<any> | FieldReadFunction<any>,
	parent?: FieldPolicy<any> | FieldReadFunction<any>,
	ancestors?: FieldPolicy<any> | FieldReadFunction<any>,
	descendants?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DocumentActivityKeySpecifier = ('id' | 'activityType' | 'createdAt' | 'user' | 'version' | 'recordedSize' | 'recordedPath' | 'document' | 'userGroups' | 'active' | DocumentActivityKeySpecifier)[];
export type DocumentActivityFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	activityType?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	version?: FieldPolicy<any> | FieldReadFunction<any>,
	recordedSize?: FieldPolicy<any> | FieldReadFunction<any>,
	recordedPath?: FieldPolicy<any> | FieldReadFunction<any>,
	document?: FieldPolicy<any> | FieldReadFunction<any>,
	userGroups?: FieldPolicy<any> | FieldReadFunction<any>,
	active?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DocumentAggsKeySpecifier = ('id' | 'document' | 'downloadCount' | 'viewCount' | DocumentAggsKeySpecifier)[];
export type DocumentAggsFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	document?: FieldPolicy<any> | FieldReadFunction<any>,
	downloadCount?: FieldPolicy<any> | FieldReadFunction<any>,
	viewCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DocumentPermissionKeySpecifier = ('id' | 'principal' | 'permissible' | DocumentPermissionKeySpecifier)[];
export type DocumentPermissionFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	principal?: FieldPolicy<any> | FieldReadFunction<any>,
	permissible?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DocumentTreeKeySpecifier = ('id' | 'name' | 'type' | 'permissionsType' | 'accessLogUrl' | 'changeLogUrl' | 'fileIndexUrl' | 'canRename' | 'canEdit' | 'canDelete' | 'canEditPermissions' | 'canViewAccessLog' | 'canViewHistory' | 'allowRootFiles' | DocumentTreeKeySpecifier)[];
export type DocumentTreeFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	permissionsType?: FieldPolicy<any> | FieldReadFunction<any>,
	accessLogUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	changeLogUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	fileIndexUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	canRename?: FieldPolicy<any> | FieldReadFunction<any>,
	canEdit?: FieldPolicy<any> | FieldReadFunction<any>,
	canDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	canEditPermissions?: FieldPolicy<any> | FieldReadFunction<any>,
	canViewAccessLog?: FieldPolicy<any> | FieldReadFunction<any>,
	canViewHistory?: FieldPolicy<any> | FieldReadFunction<any>,
	allowRootFiles?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FileUrlKeySpecifier = ('url' | 'name' | FileUrlKeySpecifier)[];
export type FileUrlFieldPolicy = {
	url?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FundEntityKeySpecifier = ('id' | 'org' | 'name' | 'slug' | 'status' | 'coverImage' | 'systemSize' | 'systemSizeAc' | 'systemSizeEss' | 'dealManager' | 'hasSubpages' | 'subpages' | 'userGroups' | 'netYields' | 'documentsTree' | 'reportsTree' | 'operatingStats' | 'projectsStats' | 'qAndALogs' | 'projects' | FundEntityKeySpecifier)[];
export type FundEntityFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	org?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	coverImage?: FieldPolicy<any> | FieldReadFunction<any>,
	systemSize?: FieldPolicy<any> | FieldReadFunction<any>,
	systemSizeAc?: FieldPolicy<any> | FieldReadFunction<any>,
	systemSizeEss?: FieldPolicy<any> | FieldReadFunction<any>,
	dealManager?: FieldPolicy<any> | FieldReadFunction<any>,
	hasSubpages?: FieldPolicy<any> | FieldReadFunction<any>,
	subpages?: FieldPolicy<any> | FieldReadFunction<any>,
	userGroups?: FieldPolicy<any> | FieldReadFunction<any>,
	netYields?: FieldPolicy<any> | FieldReadFunction<any>,
	documentsTree?: FieldPolicy<any> | FieldReadFunction<any>,
	reportsTree?: FieldPolicy<any> | FieldReadFunction<any>,
	operatingStats?: FieldPolicy<any> | FieldReadFunction<any>,
	projectsStats?: FieldPolicy<any> | FieldReadFunction<any>,
	qAndALogs?: FieldPolicy<any> | FieldReadFunction<any>,
	projects?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FundEntityCollectionKeySpecifier = ('count' | 'stats' | 'entities' | FundEntityCollectionKeySpecifier)[];
export type FundEntityCollectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	stats?: FieldPolicy<any> | FieldReadFunction<any>,
	entities?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FundEntityCollectionStatsKeySpecifier = ('projectCount' | 'totalSize' | 'maxSize' | 'minSize' | FundEntityCollectionStatsKeySpecifier)[];
export type FundEntityCollectionStatsFieldPolicy = {
	projectCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalSize?: FieldPolicy<any> | FieldReadFunction<any>,
	maxSize?: FieldPolicy<any> | FieldReadFunction<any>,
	minSize?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HoldCoNotificationSettingKeySpecifier = ('id' | 'enabled' | 'name' | HoldCoNotificationSettingKeySpecifier)[];
export type HoldCoNotificationSettingFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	enabled?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HoldCoSubpageKeySpecifier = ('title' | 'description' | 'order' | 'id' | 'slug' | HoldCoSubpageKeySpecifier)[];
export type HoldCoSubpageFieldPolicy = {
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	order?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HoldingCompanyKeySpecifier = ('id' | 'name' | 'slug' | 'status' | 'coverImage' | 'systemSize' | 'enableReports' | 'enablePortfolios' | 'enableQAndA' | 'org' | 'systemSizeAc' | 'systemSizeEss' | 'dealManager' | 'hasSubpages' | 'subpages' | 'userGroups' | 'netYields' | 'documentsTree' | 'reportsTree' | 'operatingStats' | 'projectsStats' | 'qAndALogs' | 'projects' | HoldingCompanyKeySpecifier)[];
export type HoldingCompanyFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	coverImage?: FieldPolicy<any> | FieldReadFunction<any>,
	systemSize?: FieldPolicy<any> | FieldReadFunction<any>,
	enableReports?: FieldPolicy<any> | FieldReadFunction<any>,
	enablePortfolios?: FieldPolicy<any> | FieldReadFunction<any>,
	enableQAndA?: FieldPolicy<any> | FieldReadFunction<any>,
	org?: FieldPolicy<any> | FieldReadFunction<any>,
	systemSizeAc?: FieldPolicy<any> | FieldReadFunction<any>,
	systemSizeEss?: FieldPolicy<any> | FieldReadFunction<any>,
	dealManager?: FieldPolicy<any> | FieldReadFunction<any>,
	hasSubpages?: FieldPolicy<any> | FieldReadFunction<any>,
	subpages?: FieldPolicy<any> | FieldReadFunction<any>,
	userGroups?: FieldPolicy<any> | FieldReadFunction<any>,
	netYields?: FieldPolicy<any> | FieldReadFunction<any>,
	documentsTree?: FieldPolicy<any> | FieldReadFunction<any>,
	reportsTree?: FieldPolicy<any> | FieldReadFunction<any>,
	operatingStats?: FieldPolicy<any> | FieldReadFunction<any>,
	projectsStats?: FieldPolicy<any> | FieldReadFunction<any>,
	qAndALogs?: FieldPolicy<any> | FieldReadFunction<any>,
	projects?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InverterManufacturerKeySpecifier = ('slug' | 'name' | 'id' | InverterManufacturerKeySpecifier)[];
export type InverterManufacturerFieldPolicy = {
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InverterModelKeySpecifier = ('slug' | 'id' | 'modelNumber' | 'manufacturer' | InverterModelKeySpecifier)[];
export type InverterModelFieldPolicy = {
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	modelNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	manufacturer?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InvertersKeySpecifier = ('id' | 'count' | 'inverterModelId' | 'modelId' | InvertersKeySpecifier)[];
export type InvertersFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	inverterModelId?: FieldPolicy<any> | FieldReadFunction<any>,
	modelId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InvitationsDataKeySpecifier = ('accepted' | 'waiting' | InvitationsDataKeySpecifier)[];
export type InvitationsDataFieldPolicy = {
	accepted?: FieldPolicy<any> | FieldReadFunction<any>,
	waiting?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ItemKeySpecifier = ('id' | 'link' | 'status' | 'category' | 'commentCount' | 'projectId' | 'categoryId' | 'userId' | ItemKeySpecifier)[];
export type ItemFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	category?: FieldPolicy<any> | FieldReadFunction<any>,
	commentCount?: FieldPolicy<any> | FieldReadFunction<any>,
	projectId?: FieldPolicy<any> | FieldReadFunction<any>,
	categoryId?: FieldPolicy<any> | FieldReadFunction<any>,
	userId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LabelKeySpecifier = ('id' | 'label' | 'color' | LabelKeySpecifier)[];
export type LabelFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	color?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManufacturerKeySpecifier = ('id' | 'name' | 'slug' | ManufacturerKeySpecifier)[];
export type ManufacturerFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManufacturerModelKeySpecifier = ('modelNumber' | 'slug' | 'manufacturer' | ManufacturerModelKeySpecifier)[];
export type ManufacturerModelFieldPolicy = {
	modelNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	manufacturer?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MilestoneKeySpecifier = ('id' | 'dueDate' | 'title' | 'body' | 'order' | 'status' | MilestoneKeySpecifier)[];
export type MilestoneFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	dueDate?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	order?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ModifiedFolderOutputKeySpecifier = ('parents' | 'deletedIds' | ModifiedFolderOutputKeySpecifier)[];
export type ModifiedFolderOutputFieldPolicy = {
	parents?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedIds?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('createTimeline' | 'createMilestone' | 'updateMilestone' | 'deleteMilestone' | 'reorderMilestone' | 'uploadDataImport' | 'createDuplicateReport' | 'createQuestionLog' | 'updateQuestionLog' | 'createQuestion' | 'updateQuestion' | 'addFollowUpQuestion' | 'updateQuestionAnswer' | 'addQuestionCategory' | 'removeQuestionCategory' | 'addQuestionTopic' | 'removeQuestionTopic' | 'addQuestionProject' | 'addAllQuestionProjects' | 'removeQuestionProject' | 'removeAllQuestionProjects' | 'addQuestionPortfolio' | 'addAllQuestionPortfolios' | 'removeQuestionPortfolio' | 'removeAllQuestionPortfolios' | 'moveProject' | 'createProject' | 'updateProject' | 'createRecentlyVisitedPortfolio' | 'createRecentlyVisitedHoldingCompany' | 'updatePortfolio' | 'createUserInvitation' | 'updateInvitation' | 'updateUserGroups' | 'resendUserInvitation' | 'deleteUserInvitation' | 'createGroup' | 'updateGroup' | 'deleteGroup' | 'createSummaryJob' | 'createFolder' | 'createFolders' | 'createPresignedFiles' | 'confirmUploads' | 'renameDocument' | 'moveDocument' | 'removeDocuments' | 'removeDocumentsDryRun' | 'updateDocumentPermission' | 'downloadFile' | 'submitZipDownload' | 'updateChecklistItem' | 'updateNotificationSettings' | 'updatePortfolioNotification' | 'updateHoldCoNotification' | 'updateUserProfile' | 'updateUserPassword' | 'updateUserPhoneNumberSendAuth' | 'updateUserPhoneNumber' | 'updateUserAgreements' | 'checkUserPassword' | 'uploadAvatar' | 'deleteAvatar' | 'createSupportTicket' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	createTimeline?: FieldPolicy<any> | FieldReadFunction<any>,
	createMilestone?: FieldPolicy<any> | FieldReadFunction<any>,
	updateMilestone?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteMilestone?: FieldPolicy<any> | FieldReadFunction<any>,
	reorderMilestone?: FieldPolicy<any> | FieldReadFunction<any>,
	uploadDataImport?: FieldPolicy<any> | FieldReadFunction<any>,
	createDuplicateReport?: FieldPolicy<any> | FieldReadFunction<any>,
	createQuestionLog?: FieldPolicy<any> | FieldReadFunction<any>,
	updateQuestionLog?: FieldPolicy<any> | FieldReadFunction<any>,
	createQuestion?: FieldPolicy<any> | FieldReadFunction<any>,
	updateQuestion?: FieldPolicy<any> | FieldReadFunction<any>,
	addFollowUpQuestion?: FieldPolicy<any> | FieldReadFunction<any>,
	updateQuestionAnswer?: FieldPolicy<any> | FieldReadFunction<any>,
	addQuestionCategory?: FieldPolicy<any> | FieldReadFunction<any>,
	removeQuestionCategory?: FieldPolicy<any> | FieldReadFunction<any>,
	addQuestionTopic?: FieldPolicy<any> | FieldReadFunction<any>,
	removeQuestionTopic?: FieldPolicy<any> | FieldReadFunction<any>,
	addQuestionProject?: FieldPolicy<any> | FieldReadFunction<any>,
	addAllQuestionProjects?: FieldPolicy<any> | FieldReadFunction<any>,
	removeQuestionProject?: FieldPolicy<any> | FieldReadFunction<any>,
	removeAllQuestionProjects?: FieldPolicy<any> | FieldReadFunction<any>,
	addQuestionPortfolio?: FieldPolicy<any> | FieldReadFunction<any>,
	addAllQuestionPortfolios?: FieldPolicy<any> | FieldReadFunction<any>,
	removeQuestionPortfolio?: FieldPolicy<any> | FieldReadFunction<any>,
	removeAllQuestionPortfolios?: FieldPolicy<any> | FieldReadFunction<any>,
	moveProject?: FieldPolicy<any> | FieldReadFunction<any>,
	createProject?: FieldPolicy<any> | FieldReadFunction<any>,
	updateProject?: FieldPolicy<any> | FieldReadFunction<any>,
	createRecentlyVisitedPortfolio?: FieldPolicy<any> | FieldReadFunction<any>,
	createRecentlyVisitedHoldingCompany?: FieldPolicy<any> | FieldReadFunction<any>,
	updatePortfolio?: FieldPolicy<any> | FieldReadFunction<any>,
	createUserInvitation?: FieldPolicy<any> | FieldReadFunction<any>,
	updateInvitation?: FieldPolicy<any> | FieldReadFunction<any>,
	updateUserGroups?: FieldPolicy<any> | FieldReadFunction<any>,
	resendUserInvitation?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteUserInvitation?: FieldPolicy<any> | FieldReadFunction<any>,
	createGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	updateGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	createSummaryJob?: FieldPolicy<any> | FieldReadFunction<any>,
	createFolder?: FieldPolicy<any> | FieldReadFunction<any>,
	createFolders?: FieldPolicy<any> | FieldReadFunction<any>,
	createPresignedFiles?: FieldPolicy<any> | FieldReadFunction<any>,
	confirmUploads?: FieldPolicy<any> | FieldReadFunction<any>,
	renameDocument?: FieldPolicy<any> | FieldReadFunction<any>,
	moveDocument?: FieldPolicy<any> | FieldReadFunction<any>,
	removeDocuments?: FieldPolicy<any> | FieldReadFunction<any>,
	removeDocumentsDryRun?: FieldPolicy<any> | FieldReadFunction<any>,
	updateDocumentPermission?: FieldPolicy<any> | FieldReadFunction<any>,
	downloadFile?: FieldPolicy<any> | FieldReadFunction<any>,
	submitZipDownload?: FieldPolicy<any> | FieldReadFunction<any>,
	updateChecklistItem?: FieldPolicy<any> | FieldReadFunction<any>,
	updateNotificationSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	updatePortfolioNotification?: FieldPolicy<any> | FieldReadFunction<any>,
	updateHoldCoNotification?: FieldPolicy<any> | FieldReadFunction<any>,
	updateUserProfile?: FieldPolicy<any> | FieldReadFunction<any>,
	updateUserPassword?: FieldPolicy<any> | FieldReadFunction<any>,
	updateUserPhoneNumberSendAuth?: FieldPolicy<any> | FieldReadFunction<any>,
	updateUserPhoneNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	updateUserAgreements?: FieldPolicy<any> | FieldReadFunction<any>,
	checkUserPassword?: FieldPolicy<any> | FieldReadFunction<any>,
	uploadAvatar?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteAvatar?: FieldPolicy<any> | FieldReadFunction<any>,
	createSupportTicket?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NetYieldKeySpecifier = ('id' | 'year' | 'actualEnergy' | 'p50' | 'p90' | NetYieldKeySpecifier)[];
export type NetYieldFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>,
	actualEnergy?: FieldPolicy<any> | FieldReadFunction<any>,
	p50?: FieldPolicy<any> | FieldReadFunction<any>,
	p90?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationSettingKeySpecifier = ('id' | 'enabled' | NotificationSettingKeySpecifier)[];
export type NotificationSettingFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	enabled?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OAndMPartnerKeySpecifier = ('id' | 'name' | 'projectCount' | 'totalCapacity' | OAndMPartnerKeySpecifier)[];
export type OAndMPartnerFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	projectCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCapacity?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OfftakerKeySpecifier = ('id' | 'name' | 'quality' | 'note' | OfftakerKeySpecifier)[];
export type OfftakerFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	quality?: FieldPolicy<any> | FieldReadFunction<any>,
	note?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OperatingStatsKeySpecifier = ('startDate' | 'endDate' | 'nextReportDate' | 'actualExpenses' | 'actualProduction' | 'actualRevenue' | 'forecastExpenses' | 'forecastProduction' | 'forecastRevenue' | OperatingStatsKeySpecifier)[];
export type OperatingStatsFieldPolicy = {
	startDate?: FieldPolicy<any> | FieldReadFunction<any>,
	endDate?: FieldPolicy<any> | FieldReadFunction<any>,
	nextReportDate?: FieldPolicy<any> | FieldReadFunction<any>,
	actualExpenses?: FieldPolicy<any> | FieldReadFunction<any>,
	actualProduction?: FieldPolicy<any> | FieldReadFunction<any>,
	actualRevenue?: FieldPolicy<any> | FieldReadFunction<any>,
	forecastExpenses?: FieldPolicy<any> | FieldReadFunction<any>,
	forecastProduction?: FieldPolicy<any> | FieldReadFunction<any>,
	forecastRevenue?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OrgFeatureFlagsKeySpecifier = ('documentLabels' | 'copilot' | OrgFeatureFlagsKeySpecifier)[];
export type OrgFeatureFlagsFieldPolicy = {
	documentLabels?: FieldPolicy<any> | FieldReadFunction<any>,
	copilot?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OrgInvitationKeySpecifier = ('token' | 'firstName' | 'lastName' | 'title' | 'email' | 'existingUser' | 'accepted' | 'createdAt' | 'id' | 'groupId' | 'groupIds' | OrgInvitationKeySpecifier)[];
export type OrgInvitationFieldPolicy = {
	token?: FieldPolicy<any> | FieldReadFunction<any>,
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	existingUser?: FieldPolicy<any> | FieldReadFunction<any>,
	accepted?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	groupId?: FieldPolicy<any> | FieldReadFunction<any>,
	groupIds?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OrganizationKeySpecifier = ('id' | 'name' | 'slug' | 'company' | 'permissions' | 'featureFlags' | OrganizationKeySpecifier)[];
export type OrganizationFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	company?: FieldPolicy<any> | FieldReadFunction<any>,
	permissions?: FieldPolicy<any> | FieldReadFunction<any>,
	featureFlags?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PPAContractKeySpecifier = ('id' | 'startDate' | 'term' | 'rate' | 'escalator' | PPAContractKeySpecifier)[];
export type PPAContractFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	startDate?: FieldPolicy<any> | FieldReadFunction<any>,
	term?: FieldPolicy<any> | FieldReadFunction<any>,
	rate?: FieldPolicy<any> | FieldReadFunction<any>,
	escalator?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaginatedCompanyAggsKeySpecifier = ('companies' | 'companyCount' | 'count' | 'maxUser' | 'maxSession' | 'maxDownload' | 'maxView' | PaginatedCompanyAggsKeySpecifier)[];
export type PaginatedCompanyAggsFieldPolicy = {
	companies?: FieldPolicy<any> | FieldReadFunction<any>,
	companyCount?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	maxUser?: FieldPolicy<any> | FieldReadFunction<any>,
	maxSession?: FieldPolicy<any> | FieldReadFunction<any>,
	maxDownload?: FieldPolicy<any> | FieldReadFunction<any>,
	maxView?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaginatedDocumentActivityKeySpecifier = ('activity' | 'count' | PaginatedDocumentActivityKeySpecifier)[];
export type PaginatedDocumentActivityFieldPolicy = {
	activity?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaginatedDocumentAggsKeySpecifier = ('id' | 'documents' | 'count' | 'maxDownload' | 'maxView' | PaginatedDocumentAggsKeySpecifier)[];
export type PaginatedDocumentAggsFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	documents?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	maxDownload?: FieldPolicy<any> | FieldReadFunction<any>,
	maxView?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaginatedGroupsKeySpecifier = ('groups' | 'count' | PaginatedGroupsKeySpecifier)[];
export type PaginatedGroupsFieldPolicy = {
	groups?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaginatedOrgInvitationKeySpecifier = ('invitations' | 'count' | PaginatedOrgInvitationKeySpecifier)[];
export type PaginatedOrgInvitationFieldPolicy = {
	invitations?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaginatedUserAggsKeySpecifier = ('users' | 'count' | 'maxSession' | 'maxDownload' | 'maxView' | PaginatedUserAggsKeySpecifier)[];
export type PaginatedUserAggsFieldPolicy = {
	users?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	maxSession?: FieldPolicy<any> | FieldReadFunction<any>,
	maxDownload?: FieldPolicy<any> | FieldReadFunction<any>,
	maxView?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaginatedUserGroupMembershipsKeySpecifier = ('userGroupMemberships' | 'count' | PaginatedUserGroupMembershipsKeySpecifier)[];
export type PaginatedUserGroupMembershipsFieldPolicy = {
	userGroupMemberships?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PanelManufacturerKeySpecifier = ('id' | 'slug' | 'name' | PanelManufacturerKeySpecifier)[];
export type PanelManufacturerFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PanelModelKeySpecifier = ('id' | 'slug' | 'modelNumber' | 'manufacturer' | PanelModelKeySpecifier)[];
export type PanelModelFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	modelNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	manufacturer?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PanelsKeySpecifier = ('id' | 'count' | 'panelModelId' | 'modelId' | PanelsKeySpecifier)[];
export type PanelsFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	panelModelId?: FieldPolicy<any> | FieldReadFunction<any>,
	modelId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PermissionKeySpecifier = ('id' | 'name' | 'codename' | PermissionKeySpecifier)[];
export type PermissionFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	codename?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PortfolioKeySpecifier = ('id' | 'name' | 'slug' | 'systemSize' | 'averageOfftakerQuality' | 'enableProjects' | 'enableQAndA' | 'coverImage' | 'org' | 'status' | 'systemSizeAc' | 'systemSizeEss' | 'dealManager' | 'hasSubpages' | 'subpages' | 'userGroups' | 'netYields' | 'documentsTree' | 'reportsTree' | 'operatingStats' | 'projectsStats' | 'qAndALogs' | 'projects' | 'buildStatus' | 'projectCount' | 'remainingPpaTerm' | 'hasChecklist' | 'enableReports' | 'headerEnabled' | PortfolioKeySpecifier)[];
export type PortfolioFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	systemSize?: FieldPolicy<any> | FieldReadFunction<any>,
	averageOfftakerQuality?: FieldPolicy<any> | FieldReadFunction<any>,
	enableProjects?: FieldPolicy<any> | FieldReadFunction<any>,
	enableQAndA?: FieldPolicy<any> | FieldReadFunction<any>,
	coverImage?: FieldPolicy<any> | FieldReadFunction<any>,
	org?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	systemSizeAc?: FieldPolicy<any> | FieldReadFunction<any>,
	systemSizeEss?: FieldPolicy<any> | FieldReadFunction<any>,
	dealManager?: FieldPolicy<any> | FieldReadFunction<any>,
	hasSubpages?: FieldPolicy<any> | FieldReadFunction<any>,
	subpages?: FieldPolicy<any> | FieldReadFunction<any>,
	userGroups?: FieldPolicy<any> | FieldReadFunction<any>,
	netYields?: FieldPolicy<any> | FieldReadFunction<any>,
	documentsTree?: FieldPolicy<any> | FieldReadFunction<any>,
	reportsTree?: FieldPolicy<any> | FieldReadFunction<any>,
	operatingStats?: FieldPolicy<any> | FieldReadFunction<any>,
	projectsStats?: FieldPolicy<any> | FieldReadFunction<any>,
	qAndALogs?: FieldPolicy<any> | FieldReadFunction<any>,
	projects?: FieldPolicy<any> | FieldReadFunction<any>,
	buildStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	projectCount?: FieldPolicy<any> | FieldReadFunction<any>,
	remainingPpaTerm?: FieldPolicy<any> | FieldReadFunction<any>,
	hasChecklist?: FieldPolicy<any> | FieldReadFunction<any>,
	enableReports?: FieldPolicy<any> | FieldReadFunction<any>,
	headerEnabled?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PortfolioNotificationSettingKeySpecifier = ('id' | 'enabled' | 'name' | PortfolioNotificationSettingKeySpecifier)[];
export type PortfolioNotificationSettingFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	enabled?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectKeySpecifier = ('id' | 'name' | 'commercialOperatingDate' | 'permissionToOperateDate' | 'noticeToProceed' | 'acquisitionDate' | 'status' | 'developmentStage' | 'isOwned' | 'capacity' | 'capacityAc' | 'spatial' | 'geoJsonUrl' | 'location' | 'system' | 'utility' | 'contact' | 'type' | 'cod' | 'ntp' | 'systemSizeDc' | 'systemSizeAc' | 'ppaLength' | 'ppaRemainingAvg' | 'panelCount' | 'inverterCount' | 'offtakerName' | 'offtakerCredit' | 'offtakers' | 'ppaContracts' | 'panels' | 'inverters' | 'portfolios' | 'moduleManufacturer' | 'inverterManufacturer' | 'acquiredPortfolio' | 'withinOneCount' | 'withinTenCount' | 'withinOneSumCapacityAc' | 'withinTenSumCapacityAc' | 'withinOne' | 'withinTen' | ProjectKeySpecifier)[];
export type ProjectFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	commercialOperatingDate?: FieldPolicy<any> | FieldReadFunction<any>,
	permissionToOperateDate?: FieldPolicy<any> | FieldReadFunction<any>,
	noticeToProceed?: FieldPolicy<any> | FieldReadFunction<any>,
	acquisitionDate?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	developmentStage?: FieldPolicy<any> | FieldReadFunction<any>,
	isOwned?: FieldPolicy<any> | FieldReadFunction<any>,
	capacity?: FieldPolicy<any> | FieldReadFunction<any>,
	capacityAc?: FieldPolicy<any> | FieldReadFunction<any>,
	spatial?: FieldPolicy<any> | FieldReadFunction<any>,
	geoJsonUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	location?: FieldPolicy<any> | FieldReadFunction<any>,
	system?: FieldPolicy<any> | FieldReadFunction<any>,
	utility?: FieldPolicy<any> | FieldReadFunction<any>,
	contact?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	cod?: FieldPolicy<any> | FieldReadFunction<any>,
	ntp?: FieldPolicy<any> | FieldReadFunction<any>,
	systemSizeDc?: FieldPolicy<any> | FieldReadFunction<any>,
	systemSizeAc?: FieldPolicy<any> | FieldReadFunction<any>,
	ppaLength?: FieldPolicy<any> | FieldReadFunction<any>,
	ppaRemainingAvg?: FieldPolicy<any> | FieldReadFunction<any>,
	panelCount?: FieldPolicy<any> | FieldReadFunction<any>,
	inverterCount?: FieldPolicy<any> | FieldReadFunction<any>,
	offtakerName?: FieldPolicy<any> | FieldReadFunction<any>,
	offtakerCredit?: FieldPolicy<any> | FieldReadFunction<any>,
	offtakers?: FieldPolicy<any> | FieldReadFunction<any>,
	ppaContracts?: FieldPolicy<any> | FieldReadFunction<any>,
	panels?: FieldPolicy<any> | FieldReadFunction<any>,
	inverters?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolios?: FieldPolicy<any> | FieldReadFunction<any>,
	moduleManufacturer?: FieldPolicy<any> | FieldReadFunction<any>,
	inverterManufacturer?: FieldPolicy<any> | FieldReadFunction<any>,
	acquiredPortfolio?: FieldPolicy<any> | FieldReadFunction<any>,
	withinOneCount?: FieldPolicy<any> | FieldReadFunction<any>,
	withinTenCount?: FieldPolicy<any> | FieldReadFunction<any>,
	withinOneSumCapacityAc?: FieldPolicy<any> | FieldReadFunction<any>,
	withinTenSumCapacityAc?: FieldPolicy<any> | FieldReadFunction<any>,
	withinOne?: FieldPolicy<any> | FieldReadFunction<any>,
	withinTen?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectContactKeySpecifier = ('id' | 'phoneNumber' | 'balancingAuthorityAreaName' | ProjectContactKeySpecifier)[];
export type ProjectContactFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	phoneNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	balancingAuthorityAreaName?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectCrossLinkKeySpecifier = ('portfolio' | 'document' | ProjectCrossLinkKeySpecifier)[];
export type ProjectCrossLinkFieldPolicy = {
	portfolio?: FieldPolicy<any> | FieldReadFunction<any>,
	document?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectDictKeySpecifier = ('id' | 'name' | ProjectDictKeySpecifier)[];
export type ProjectDictFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectLocationKeySpecifier = ('id' | 'address' | 'city' | 'state' | 'zipCode' | 'latitude' | 'longitude' | 'elevation' | ProjectLocationKeySpecifier)[];
export type ProjectLocationFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	address?: FieldPolicy<any> | FieldReadFunction<any>,
	city?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	zipCode?: FieldPolicy<any> | FieldReadFunction<any>,
	latitude?: FieldPolicy<any> | FieldReadFunction<any>,
	longitude?: FieldPolicy<any> | FieldReadFunction<any>,
	elevation?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectSpatialKeySpecifier = ('id' | 'geoJsonFile' | 'features' | 'geoJsonUrl' | ProjectSpatialKeySpecifier)[];
export type ProjectSpatialFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	geoJsonFile?: FieldPolicy<any> | FieldReadFunction<any>,
	features?: FieldPolicy<any> | FieldReadFunction<any>,
	geoJsonUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectsCollectionKeySpecifier = ('aggregations' | 'projects' | ProjectsCollectionKeySpecifier)[];
export type ProjectsCollectionFieldPolicy = {
	aggregations?: FieldPolicy<any> | FieldReadFunction<any>,
	projects?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectsCollectionAggregationsKeySpecifier = ('count' | 'totalCapacity' | 'maxCapacity' | 'minCapacity' | ProjectsCollectionAggregationsKeySpecifier)[];
export type ProjectsCollectionAggregationsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCapacity?: FieldPolicy<any> | FieldReadFunction<any>,
	maxCapacity?: FieldPolicy<any> | FieldReadFunction<any>,
	minCapacity?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectsStatsKeySpecifier = ('count' | 'totalCapacity' | 'maxCapacity' | 'minCapacity' | 'emissionsOffset' | ProjectsStatsKeySpecifier)[];
export type ProjectsStatsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCapacity?: FieldPolicy<any> | FieldReadFunction<any>,
	maxCapacity?: FieldPolicy<any> | FieldReadFunction<any>,
	minCapacity?: FieldPolicy<any> | FieldReadFunction<any>,
	emissionsOffset?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('timeline' | 'dataImports' | 'duplicateReports' | 'questions' | 'questionCategories' | 'questionTopics' | 'panelManufacturers' | 'panelModels' | 'inverterManufacturers' | 'inverterModels' | 'offtakers' | 'projects' | 'project' | 'projectCrossLinks' | 'acquiredProjectsStats' | 'reportingProjectsStats' | 'reportingOperatingStats' | 'reportingHoldingCompanies' | 'recentlyVisited' | 'fundEntities' | 'portfolio' | 'portfolios' | 'holdingCompany' | 'organizations' | 'orgUserGroups' | 'orgPortfolios' | 'role' | 'roles' | 'invitation' | 'invitationPages' | 'groupPages' | 'invitationsData' | 'oAndMPartners' | 'oAndMTree' | 'documentTree' | 'companyAggs' | 'userAggs' | 'documentAggs' | 'document' | 'documents' | 'userGroups' | 'writeHistory' | 'fileUrl' | 'filePreviewUrl' | 'checkRemoveDocuments' | 'summaryJobs' | 'summaryExtractItems' | 'checklist' | 'checklistItem' | 'checklistItems' | 'activeChecklists' | 'notificationSettings' | 'portfolioNotifications' | 'holdCoNotifications' | 'user' | 'portfolioUsers' | 'viewer' | 'userGroupMembers' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	timeline?: FieldPolicy<any> | FieldReadFunction<any>,
	dataImports?: FieldPolicy<any> | FieldReadFunction<any>,
	duplicateReports?: FieldPolicy<any> | FieldReadFunction<any>,
	questions?: FieldPolicy<any> | FieldReadFunction<any>,
	questionCategories?: FieldPolicy<any> | FieldReadFunction<any>,
	questionTopics?: FieldPolicy<any> | FieldReadFunction<any>,
	panelManufacturers?: FieldPolicy<any> | FieldReadFunction<any>,
	panelModels?: FieldPolicy<any> | FieldReadFunction<any>,
	inverterManufacturers?: FieldPolicy<any> | FieldReadFunction<any>,
	inverterModels?: FieldPolicy<any> | FieldReadFunction<any>,
	offtakers?: FieldPolicy<any> | FieldReadFunction<any>,
	projects?: FieldPolicy<any> | FieldReadFunction<any>,
	project?: FieldPolicy<any> | FieldReadFunction<any>,
	projectCrossLinks?: FieldPolicy<any> | FieldReadFunction<any>,
	acquiredProjectsStats?: FieldPolicy<any> | FieldReadFunction<any>,
	reportingProjectsStats?: FieldPolicy<any> | FieldReadFunction<any>,
	reportingOperatingStats?: FieldPolicy<any> | FieldReadFunction<any>,
	reportingHoldingCompanies?: FieldPolicy<any> | FieldReadFunction<any>,
	recentlyVisited?: FieldPolicy<any> | FieldReadFunction<any>,
	fundEntities?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolio?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolios?: FieldPolicy<any> | FieldReadFunction<any>,
	holdingCompany?: FieldPolicy<any> | FieldReadFunction<any>,
	organizations?: FieldPolicy<any> | FieldReadFunction<any>,
	orgUserGroups?: FieldPolicy<any> | FieldReadFunction<any>,
	orgPortfolios?: FieldPolicy<any> | FieldReadFunction<any>,
	role?: FieldPolicy<any> | FieldReadFunction<any>,
	roles?: FieldPolicy<any> | FieldReadFunction<any>,
	invitation?: FieldPolicy<any> | FieldReadFunction<any>,
	invitationPages?: FieldPolicy<any> | FieldReadFunction<any>,
	groupPages?: FieldPolicy<any> | FieldReadFunction<any>,
	invitationsData?: FieldPolicy<any> | FieldReadFunction<any>,
	oAndMPartners?: FieldPolicy<any> | FieldReadFunction<any>,
	oAndMTree?: FieldPolicy<any> | FieldReadFunction<any>,
	documentTree?: FieldPolicy<any> | FieldReadFunction<any>,
	companyAggs?: FieldPolicy<any> | FieldReadFunction<any>,
	userAggs?: FieldPolicy<any> | FieldReadFunction<any>,
	documentAggs?: FieldPolicy<any> | FieldReadFunction<any>,
	document?: FieldPolicy<any> | FieldReadFunction<any>,
	documents?: FieldPolicy<any> | FieldReadFunction<any>,
	userGroups?: FieldPolicy<any> | FieldReadFunction<any>,
	writeHistory?: FieldPolicy<any> | FieldReadFunction<any>,
	fileUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	filePreviewUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	checkRemoveDocuments?: FieldPolicy<any> | FieldReadFunction<any>,
	summaryJobs?: FieldPolicy<any> | FieldReadFunction<any>,
	summaryExtractItems?: FieldPolicy<any> | FieldReadFunction<any>,
	checklist?: FieldPolicy<any> | FieldReadFunction<any>,
	checklistItem?: FieldPolicy<any> | FieldReadFunction<any>,
	checklistItems?: FieldPolicy<any> | FieldReadFunction<any>,
	activeChecklists?: FieldPolicy<any> | FieldReadFunction<any>,
	notificationSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolioNotifications?: FieldPolicy<any> | FieldReadFunction<any>,
	holdCoNotifications?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolioUsers?: FieldPolicy<any> | FieldReadFunction<any>,
	viewer?: FieldPolicy<any> | FieldReadFunction<any>,
	userGroupMembers?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QuestionKeySpecifier = ('id' | 'body' | 'number' | 'isClosed' | 'isPriority' | 'createdAt' | 'modifiedAt' | 'answer' | 'followUps' | 'categories' | 'topics' | 'projects' | 'portfolios' | 'parentId' | 'firstViewedAt' | 'viewedAt' | QuestionKeySpecifier)[];
export type QuestionFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	number?: FieldPolicy<any> | FieldReadFunction<any>,
	isClosed?: FieldPolicy<any> | FieldReadFunction<any>,
	isPriority?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	modifiedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	answer?: FieldPolicy<any> | FieldReadFunction<any>,
	followUps?: FieldPolicy<any> | FieldReadFunction<any>,
	categories?: FieldPolicy<any> | FieldReadFunction<any>,
	topics?: FieldPolicy<any> | FieldReadFunction<any>,
	projects?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolios?: FieldPolicy<any> | FieldReadFunction<any>,
	parentId?: FieldPolicy<any> | FieldReadFunction<any>,
	firstViewedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	viewedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QuestionAnswerKeySpecifier = ('id' | 'body' | 'createdAt' | 'modifiedAt' | QuestionAnswerKeySpecifier)[];
export type QuestionAnswerFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	modifiedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QuestionCategoryKeySpecifier = ('id' | 'name' | QuestionCategoryKeySpecifier)[];
export type QuestionCategoryFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QuestionLogKeySpecifier = ('id' | 'name' | 'restricted' | 'groups' | QuestionLogKeySpecifier)[];
export type QuestionLogFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	restricted?: FieldPolicy<any> | FieldReadFunction<any>,
	groups?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QuestionTopicKeySpecifier = ('id' | 'name' | QuestionTopicKeySpecifier)[];
export type QuestionTopicFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RenameDocumentMutationResultKeySpecifier = ('document' | 'error' | RenameDocumentMutationResultKeySpecifier)[];
export type RenameDocumentMutationResultFieldPolicy = {
	document?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ReorderMilestoneMutationKeySpecifier = ('timeline' | ReorderMilestoneMutationKeySpecifier)[];
export type ReorderMilestoneMutationFieldPolicy = {
	timeline?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RoleKeySpecifier = ('id' | 'name' | 'permissions' | RoleKeySpecifier)[];
export type RoleFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	permissions?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SalesforceDataImportKeySpecifier = ('id' | 'file' | 'createdAt' | 'status' | 'fileName' | 'fileUrl' | 'vendor' | 'importedBy' | SalesforceDataImportKeySpecifier)[];
export type SalesforceDataImportFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	file?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	fileName?: FieldPolicy<any> | FieldReadFunction<any>,
	fileUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	vendor?: FieldPolicy<any> | FieldReadFunction<any>,
	importedBy?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SalesforceDuplicateReportKeySpecifier = ('id' | 'file' | 'createdAt' | 'status' | 'fileName' | 'fileUrl' | 'createdBy' | SalesforceDuplicateReportKeySpecifier)[];
export type SalesforceDuplicateReportFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	file?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	fileName?: FieldPolicy<any> | FieldReadFunction<any>,
	fileUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	createdBy?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SpatialFeatureKeySpecifier = ('id' | 'name' | 'type' | 'stroke' | 'strokeOpacity' | 'strokeWidth' | 'fill' | 'fillOpacity' | 'geoJsonUrl' | SpatialFeatureKeySpecifier)[];
export type SpatialFeatureFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	stroke?: FieldPolicy<any> | FieldReadFunction<any>,
	strokeOpacity?: FieldPolicy<any> | FieldReadFunction<any>,
	strokeWidth?: FieldPolicy<any> | FieldReadFunction<any>,
	fill?: FieldPolicy<any> | FieldReadFunction<any>,
	fillOpacity?: FieldPolicy<any> | FieldReadFunction<any>,
	geoJsonUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubpageKeySpecifier = ('id' | 'title' | 'description' | 'order' | 'slug' | SubpageKeySpecifier)[];
export type SubpageFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	order?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubpageInterfaceKeySpecifier = ('id' | 'title' | 'slug' | 'description' | 'order' | SubpageInterfaceKeySpecifier)[];
export type SubpageInterfaceFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	order?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SummaryExtractItemKeySpecifier = ('id' | 'jobType' | 'displayName' | SummaryExtractItemKeySpecifier)[];
export type SummaryExtractItemFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	jobType?: FieldPolicy<any> | FieldReadFunction<any>,
	displayName?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SummaryJobKeySpecifier = ('id' | 'name' | 'complete' | 'outputFileUrl' | SummaryJobKeySpecifier)[];
export type SummaryJobFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	complete?: FieldPolicy<any> | FieldReadFunction<any>,
	outputFileUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SupportTicketOutputKeySpecifier = ('success' | SupportTicketOutputKeySpecifier)[];
export type SupportTicketOutputFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SystemKeySpecifier = ('id' | 'system' | 'solarCapacityDc' | 'solarCapacityAc' | 'bessKw' | 'bessKwh' | 'panelWarranty' | 'inverterWarranty' | 'contractorWarranty' | 'installation' | 'mounting' | SystemKeySpecifier)[];
export type SystemFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	system?: FieldPolicy<any> | FieldReadFunction<any>,
	solarCapacityDc?: FieldPolicy<any> | FieldReadFunction<any>,
	solarCapacityAc?: FieldPolicy<any> | FieldReadFunction<any>,
	bessKw?: FieldPolicy<any> | FieldReadFunction<any>,
	bessKwh?: FieldPolicy<any> | FieldReadFunction<any>,
	panelWarranty?: FieldPolicy<any> | FieldReadFunction<any>,
	inverterWarranty?: FieldPolicy<any> | FieldReadFunction<any>,
	contractorWarranty?: FieldPolicy<any> | FieldReadFunction<any>,
	installation?: FieldPolicy<any> | FieldReadFunction<any>,
	mounting?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TimelineKeySpecifier = ('id' | 'name' | 'milestones' | 'canEdit' | TimelineKeySpecifier)[];
export type TimelineFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	milestones?: FieldPolicy<any> | FieldReadFunction<any>,
	canEdit?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdateMilestoneMutationKeySpecifier = ('milestone' | UpdateMilestoneMutationKeySpecifier)[];
export type UpdateMilestoneMutationFieldPolicy = {
	milestone?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserKeySpecifier = ('id' | 'isSuperuser' | 'email' | 'firstName' | 'lastName' | 'title' | 'location' | 'phoneNumber' | 'company' | 'companyName' | 'fullName' | 'avatarUrl' | 'hasSalesforceToken' | 'isSuperUser' | 'permissions' | 'organizations' | 'lastActivity' | 'lastLogin' | 'notificationsEnabled' | 'agreements' | UserKeySpecifier)[];
export type UserFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isSuperuser?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	location?: FieldPolicy<any> | FieldReadFunction<any>,
	phoneNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	company?: FieldPolicy<any> | FieldReadFunction<any>,
	companyName?: FieldPolicy<any> | FieldReadFunction<any>,
	fullName?: FieldPolicy<any> | FieldReadFunction<any>,
	avatarUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	hasSalesforceToken?: FieldPolicy<any> | FieldReadFunction<any>,
	isSuperUser?: FieldPolicy<any> | FieldReadFunction<any>,
	permissions?: FieldPolicy<any> | FieldReadFunction<any>,
	organizations?: FieldPolicy<any> | FieldReadFunction<any>,
	lastActivity?: FieldPolicy<any> | FieldReadFunction<any>,
	lastLogin?: FieldPolicy<any> | FieldReadFunction<any>,
	notificationsEnabled?: FieldPolicy<any> | FieldReadFunction<any>,
	agreements?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserAggsKeySpecifier = ('id' | 'user' | 'company' | 'sessionCount' | 'downloadCount' | 'viewCount' | UserAggsKeySpecifier)[];
export type UserAggsFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	company?: FieldPolicy<any> | FieldReadFunction<any>,
	sessionCount?: FieldPolicy<any> | FieldReadFunction<any>,
	downloadCount?: FieldPolicy<any> | FieldReadFunction<any>,
	viewCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserAgreementsKeySpecifier = ('id' | 'eulaRequired' | 'acceptedEula' | UserAgreementsKeySpecifier)[];
export type UserAgreementsFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	eulaRequired?: FieldPolicy<any> | FieldReadFunction<any>,
	acceptedEula?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserGroupKeySpecifier = ('id' | 'name' | 'role' | 'portfolioIds' | 'memberCount' | UserGroupKeySpecifier)[];
export type UserGroupFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	role?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolioIds?: FieldPolicy<any> | FieldReadFunction<any>,
	memberCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserGroupMembershipKeySpecifier = ('id' | 'user' | 'userGroup' | UserGroupMembershipKeySpecifier)[];
export type UserGroupMembershipFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	userGroup?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserPermissionsKeySpecifier = ('name' | 'portfolioIds' | 'holdingCompanyIds' | UserPermissionsKeySpecifier)[];
export type UserPermissionsFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolioIds?: FieldPolicy<any> | FieldReadFunction<any>,
	holdingCompanyIds?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserTypeKeySpecifier = ('id' | 'firstName' | 'lastName' | 'fullName' | UserTypeKeySpecifier)[];
export type UserTypeFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	fullName?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UtilityKeySpecifier = ('id' | 'name' | 'zone' | 'iso' | UtilityKeySpecifier)[];
export type UtilityFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	zone?: FieldPolicy<any> | FieldReadFunction<any>,
	iso?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ZipDownloadOutputKeySpecifier = ('exportable' | 'unexportable' | ZipDownloadOutputKeySpecifier)[];
export type ZipDownloadOutputFieldPolicy = {
	exportable?: FieldPolicy<any> | FieldReadFunction<any>,
	unexportable?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TypedTypePolicies = TypePolicies & {
	Category?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CategoryKeySpecifier | (() => undefined | CategoryKeySpecifier),
		fields?: CategoryFieldPolicy,
	},
	Checklist?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ChecklistKeySpecifier | (() => undefined | ChecklistKeySpecifier),
		fields?: ChecklistFieldPolicy,
	},
	Company?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanyKeySpecifier | (() => undefined | CompanyKeySpecifier),
		fields?: CompanyFieldPolicy,
	},
	CompanyAggs?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanyAggsKeySpecifier | (() => undefined | CompanyAggsKeySpecifier),
		fields?: CompanyAggsFieldPolicy,
	},
	ConfirmUploadsResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ConfirmUploadsResultKeySpecifier | (() => undefined | ConfirmUploadsResultKeySpecifier),
		fields?: ConfirmUploadsResultFieldPolicy,
	},
	CreateMilestoneMutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateMilestoneMutationKeySpecifier | (() => undefined | CreateMilestoneMutationKeySpecifier),
		fields?: CreateMilestoneMutationFieldPolicy,
	},
	CreatePresignedFilesResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreatePresignedFilesResultKeySpecifier | (() => undefined | CreatePresignedFilesResultKeySpecifier),
		fields?: CreatePresignedFilesResultFieldPolicy,
	},
	CreateTimelineMutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateTimelineMutationKeySpecifier | (() => undefined | CreateTimelineMutationKeySpecifier),
		fields?: CreateTimelineMutationFieldPolicy,
	},
	DeleteMilestoneMutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteMilestoneMutationKeySpecifier | (() => undefined | DeleteMilestoneMutationKeySpecifier),
		fields?: DeleteMilestoneMutationFieldPolicy,
	},
	DeletedInvitationResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletedInvitationResultKeySpecifier | (() => undefined | DeletedInvitationResultKeySpecifier),
		fields?: DeletedInvitationResultFieldPolicy,
	},
	DeletedObjectResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletedObjectResultKeySpecifier | (() => undefined | DeletedObjectResultKeySpecifier),
		fields?: DeletedObjectResultFieldPolicy,
	},
	Document?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DocumentKeySpecifier | (() => undefined | DocumentKeySpecifier),
		fields?: DocumentFieldPolicy,
	},
	DocumentActivity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DocumentActivityKeySpecifier | (() => undefined | DocumentActivityKeySpecifier),
		fields?: DocumentActivityFieldPolicy,
	},
	DocumentAggs?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DocumentAggsKeySpecifier | (() => undefined | DocumentAggsKeySpecifier),
		fields?: DocumentAggsFieldPolicy,
	},
	DocumentPermission?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DocumentPermissionKeySpecifier | (() => undefined | DocumentPermissionKeySpecifier),
		fields?: DocumentPermissionFieldPolicy,
	},
	DocumentTree?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DocumentTreeKeySpecifier | (() => undefined | DocumentTreeKeySpecifier),
		fields?: DocumentTreeFieldPolicy,
	},
	FileUrl?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FileUrlKeySpecifier | (() => undefined | FileUrlKeySpecifier),
		fields?: FileUrlFieldPolicy,
	},
	FundEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FundEntityKeySpecifier | (() => undefined | FundEntityKeySpecifier),
		fields?: FundEntityFieldPolicy,
	},
	FundEntityCollection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FundEntityCollectionKeySpecifier | (() => undefined | FundEntityCollectionKeySpecifier),
		fields?: FundEntityCollectionFieldPolicy,
	},
	FundEntityCollectionStats?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FundEntityCollectionStatsKeySpecifier | (() => undefined | FundEntityCollectionStatsKeySpecifier),
		fields?: FundEntityCollectionStatsFieldPolicy,
	},
	HoldCoNotificationSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HoldCoNotificationSettingKeySpecifier | (() => undefined | HoldCoNotificationSettingKeySpecifier),
		fields?: HoldCoNotificationSettingFieldPolicy,
	},
	HoldCoSubpage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HoldCoSubpageKeySpecifier | (() => undefined | HoldCoSubpageKeySpecifier),
		fields?: HoldCoSubpageFieldPolicy,
	},
	HoldingCompany?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HoldingCompanyKeySpecifier | (() => undefined | HoldingCompanyKeySpecifier),
		fields?: HoldingCompanyFieldPolicy,
	},
	InverterManufacturer?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InverterManufacturerKeySpecifier | (() => undefined | InverterManufacturerKeySpecifier),
		fields?: InverterManufacturerFieldPolicy,
	},
	InverterModel?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InverterModelKeySpecifier | (() => undefined | InverterModelKeySpecifier),
		fields?: InverterModelFieldPolicy,
	},
	Inverters?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InvertersKeySpecifier | (() => undefined | InvertersKeySpecifier),
		fields?: InvertersFieldPolicy,
	},
	InvitationsData?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InvitationsDataKeySpecifier | (() => undefined | InvitationsDataKeySpecifier),
		fields?: InvitationsDataFieldPolicy,
	},
	Item?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ItemKeySpecifier | (() => undefined | ItemKeySpecifier),
		fields?: ItemFieldPolicy,
	},
	Label?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LabelKeySpecifier | (() => undefined | LabelKeySpecifier),
		fields?: LabelFieldPolicy,
	},
	Manufacturer?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManufacturerKeySpecifier | (() => undefined | ManufacturerKeySpecifier),
		fields?: ManufacturerFieldPolicy,
	},
	ManufacturerModel?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManufacturerModelKeySpecifier | (() => undefined | ManufacturerModelKeySpecifier),
		fields?: ManufacturerModelFieldPolicy,
	},
	Milestone?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MilestoneKeySpecifier | (() => undefined | MilestoneKeySpecifier),
		fields?: MilestoneFieldPolicy,
	},
	ModifiedFolderOutput?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ModifiedFolderOutputKeySpecifier | (() => undefined | ModifiedFolderOutputKeySpecifier),
		fields?: ModifiedFolderOutputFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	NetYield?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NetYieldKeySpecifier | (() => undefined | NetYieldKeySpecifier),
		fields?: NetYieldFieldPolicy,
	},
	NotificationSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationSettingKeySpecifier | (() => undefined | NotificationSettingKeySpecifier),
		fields?: NotificationSettingFieldPolicy,
	},
	OAndMPartner?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OAndMPartnerKeySpecifier | (() => undefined | OAndMPartnerKeySpecifier),
		fields?: OAndMPartnerFieldPolicy,
	},
	Offtaker?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OfftakerKeySpecifier | (() => undefined | OfftakerKeySpecifier),
		fields?: OfftakerFieldPolicy,
	},
	OperatingStats?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OperatingStatsKeySpecifier | (() => undefined | OperatingStatsKeySpecifier),
		fields?: OperatingStatsFieldPolicy,
	},
	OrgFeatureFlags?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OrgFeatureFlagsKeySpecifier | (() => undefined | OrgFeatureFlagsKeySpecifier),
		fields?: OrgFeatureFlagsFieldPolicy,
	},
	OrgInvitation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OrgInvitationKeySpecifier | (() => undefined | OrgInvitationKeySpecifier),
		fields?: OrgInvitationFieldPolicy,
	},
	Organization?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OrganizationKeySpecifier | (() => undefined | OrganizationKeySpecifier),
		fields?: OrganizationFieldPolicy,
	},
	PPAContract?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PPAContractKeySpecifier | (() => undefined | PPAContractKeySpecifier),
		fields?: PPAContractFieldPolicy,
	},
	PaginatedCompanyAggs?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedCompanyAggsKeySpecifier | (() => undefined | PaginatedCompanyAggsKeySpecifier),
		fields?: PaginatedCompanyAggsFieldPolicy,
	},
	PaginatedDocumentActivity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedDocumentActivityKeySpecifier | (() => undefined | PaginatedDocumentActivityKeySpecifier),
		fields?: PaginatedDocumentActivityFieldPolicy,
	},
	PaginatedDocumentAggs?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedDocumentAggsKeySpecifier | (() => undefined | PaginatedDocumentAggsKeySpecifier),
		fields?: PaginatedDocumentAggsFieldPolicy,
	},
	PaginatedGroups?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedGroupsKeySpecifier | (() => undefined | PaginatedGroupsKeySpecifier),
		fields?: PaginatedGroupsFieldPolicy,
	},
	PaginatedOrgInvitation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedOrgInvitationKeySpecifier | (() => undefined | PaginatedOrgInvitationKeySpecifier),
		fields?: PaginatedOrgInvitationFieldPolicy,
	},
	PaginatedUserAggs?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedUserAggsKeySpecifier | (() => undefined | PaginatedUserAggsKeySpecifier),
		fields?: PaginatedUserAggsFieldPolicy,
	},
	PaginatedUserGroupMemberships?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedUserGroupMembershipsKeySpecifier | (() => undefined | PaginatedUserGroupMembershipsKeySpecifier),
		fields?: PaginatedUserGroupMembershipsFieldPolicy,
	},
	PanelManufacturer?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PanelManufacturerKeySpecifier | (() => undefined | PanelManufacturerKeySpecifier),
		fields?: PanelManufacturerFieldPolicy,
	},
	PanelModel?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PanelModelKeySpecifier | (() => undefined | PanelModelKeySpecifier),
		fields?: PanelModelFieldPolicy,
	},
	Panels?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PanelsKeySpecifier | (() => undefined | PanelsKeySpecifier),
		fields?: PanelsFieldPolicy,
	},
	Permission?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PermissionKeySpecifier | (() => undefined | PermissionKeySpecifier),
		fields?: PermissionFieldPolicy,
	},
	Portfolio?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PortfolioKeySpecifier | (() => undefined | PortfolioKeySpecifier),
		fields?: PortfolioFieldPolicy,
	},
	PortfolioNotificationSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PortfolioNotificationSettingKeySpecifier | (() => undefined | PortfolioNotificationSettingKeySpecifier),
		fields?: PortfolioNotificationSettingFieldPolicy,
	},
	Project?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectKeySpecifier | (() => undefined | ProjectKeySpecifier),
		fields?: ProjectFieldPolicy,
	},
	ProjectContact?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectContactKeySpecifier | (() => undefined | ProjectContactKeySpecifier),
		fields?: ProjectContactFieldPolicy,
	},
	ProjectCrossLink?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectCrossLinkKeySpecifier | (() => undefined | ProjectCrossLinkKeySpecifier),
		fields?: ProjectCrossLinkFieldPolicy,
	},
	ProjectDict?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectDictKeySpecifier | (() => undefined | ProjectDictKeySpecifier),
		fields?: ProjectDictFieldPolicy,
	},
	ProjectLocation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectLocationKeySpecifier | (() => undefined | ProjectLocationKeySpecifier),
		fields?: ProjectLocationFieldPolicy,
	},
	ProjectSpatial?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectSpatialKeySpecifier | (() => undefined | ProjectSpatialKeySpecifier),
		fields?: ProjectSpatialFieldPolicy,
	},
	ProjectsCollection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectsCollectionKeySpecifier | (() => undefined | ProjectsCollectionKeySpecifier),
		fields?: ProjectsCollectionFieldPolicy,
	},
	ProjectsCollectionAggregations?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectsCollectionAggregationsKeySpecifier | (() => undefined | ProjectsCollectionAggregationsKeySpecifier),
		fields?: ProjectsCollectionAggregationsFieldPolicy,
	},
	ProjectsStats?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectsStatsKeySpecifier | (() => undefined | ProjectsStatsKeySpecifier),
		fields?: ProjectsStatsFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	Question?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QuestionKeySpecifier | (() => undefined | QuestionKeySpecifier),
		fields?: QuestionFieldPolicy,
	},
	QuestionAnswer?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QuestionAnswerKeySpecifier | (() => undefined | QuestionAnswerKeySpecifier),
		fields?: QuestionAnswerFieldPolicy,
	},
	QuestionCategory?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QuestionCategoryKeySpecifier | (() => undefined | QuestionCategoryKeySpecifier),
		fields?: QuestionCategoryFieldPolicy,
	},
	QuestionLog?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QuestionLogKeySpecifier | (() => undefined | QuestionLogKeySpecifier),
		fields?: QuestionLogFieldPolicy,
	},
	QuestionTopic?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QuestionTopicKeySpecifier | (() => undefined | QuestionTopicKeySpecifier),
		fields?: QuestionTopicFieldPolicy,
	},
	RenameDocumentMutationResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RenameDocumentMutationResultKeySpecifier | (() => undefined | RenameDocumentMutationResultKeySpecifier),
		fields?: RenameDocumentMutationResultFieldPolicy,
	},
	ReorderMilestoneMutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ReorderMilestoneMutationKeySpecifier | (() => undefined | ReorderMilestoneMutationKeySpecifier),
		fields?: ReorderMilestoneMutationFieldPolicy,
	},
	Role?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RoleKeySpecifier | (() => undefined | RoleKeySpecifier),
		fields?: RoleFieldPolicy,
	},
	SalesforceDataImport?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SalesforceDataImportKeySpecifier | (() => undefined | SalesforceDataImportKeySpecifier),
		fields?: SalesforceDataImportFieldPolicy,
	},
	SalesforceDuplicateReport?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SalesforceDuplicateReportKeySpecifier | (() => undefined | SalesforceDuplicateReportKeySpecifier),
		fields?: SalesforceDuplicateReportFieldPolicy,
	},
	SpatialFeature?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SpatialFeatureKeySpecifier | (() => undefined | SpatialFeatureKeySpecifier),
		fields?: SpatialFeatureFieldPolicy,
	},
	Subpage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubpageKeySpecifier | (() => undefined | SubpageKeySpecifier),
		fields?: SubpageFieldPolicy,
	},
	SubpageInterface?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubpageInterfaceKeySpecifier | (() => undefined | SubpageInterfaceKeySpecifier),
		fields?: SubpageInterfaceFieldPolicy,
	},
	SummaryExtractItem?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SummaryExtractItemKeySpecifier | (() => undefined | SummaryExtractItemKeySpecifier),
		fields?: SummaryExtractItemFieldPolicy,
	},
	SummaryJob?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SummaryJobKeySpecifier | (() => undefined | SummaryJobKeySpecifier),
		fields?: SummaryJobFieldPolicy,
	},
	SupportTicketOutput?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SupportTicketOutputKeySpecifier | (() => undefined | SupportTicketOutputKeySpecifier),
		fields?: SupportTicketOutputFieldPolicy,
	},
	System?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SystemKeySpecifier | (() => undefined | SystemKeySpecifier),
		fields?: SystemFieldPolicy,
	},
	Timeline?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TimelineKeySpecifier | (() => undefined | TimelineKeySpecifier),
		fields?: TimelineFieldPolicy,
	},
	UpdateMilestoneMutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdateMilestoneMutationKeySpecifier | (() => undefined | UpdateMilestoneMutationKeySpecifier),
		fields?: UpdateMilestoneMutationFieldPolicy,
	},
	User?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier),
		fields?: UserFieldPolicy,
	},
	UserAggs?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserAggsKeySpecifier | (() => undefined | UserAggsKeySpecifier),
		fields?: UserAggsFieldPolicy,
	},
	UserAgreements?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserAgreementsKeySpecifier | (() => undefined | UserAgreementsKeySpecifier),
		fields?: UserAgreementsFieldPolicy,
	},
	UserGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserGroupKeySpecifier | (() => undefined | UserGroupKeySpecifier),
		fields?: UserGroupFieldPolicy,
	},
	UserGroupMembership?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserGroupMembershipKeySpecifier | (() => undefined | UserGroupMembershipKeySpecifier),
		fields?: UserGroupMembershipFieldPolicy,
	},
	UserPermissions?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserPermissionsKeySpecifier | (() => undefined | UserPermissionsKeySpecifier),
		fields?: UserPermissionsFieldPolicy,
	},
	UserType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserTypeKeySpecifier | (() => undefined | UserTypeKeySpecifier),
		fields?: UserTypeFieldPolicy,
	},
	Utility?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UtilityKeySpecifier | (() => undefined | UtilityKeySpecifier),
		fields?: UtilityFieldPolicy,
	},
	ZipDownloadOutput?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ZipDownloadOutputKeySpecifier | (() => undefined | ZipDownloadOutputKeySpecifier),
		fields?: ZipDownloadOutputFieldPolicy,
	}
};