// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type LocationFragment = (
  { readonly __typename: 'ProjectLocation' }
  & Pick<Types.ProjectLocation, 'address' | 'city' | 'state' | 'zipCode' | 'elevation' | 'latitude' | 'longitude'>
);

export type ProjectFormFragment = (
  { readonly __typename: 'Project' }
  & Pick<Types.Project, 'id' | 'name' | 'status' | 'type' | 'developmentStage' | 'isOwned' | 'noticeToProceed' | 'commercialOperatingDate' | 'permissionToOperateDate' | 'acquisitionDate' | 'capacity' | 'capacityAc'>
  & { readonly location: (
    { readonly __typename: 'ProjectLocation' }
    & LocationFragment
  ), readonly system: (
    { readonly __typename: 'System' }
    & Pick<Types.System, 'bessKw' | 'bessKwh' | 'panelWarranty' | 'inverterWarranty' | 'contractorWarranty' | 'installation' | 'mounting'>
  ), readonly contact: (
    { readonly __typename: 'ProjectContact' }
    & Pick<Types.ProjectContact, 'phoneNumber' | 'balancingAuthorityAreaName'>
  ), readonly ppaContracts?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'PPAContract' }
    & ProjectPpaFragment
  )>>, readonly panels?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Panels' }
    & ProjectPanelsFragment
  )>>, readonly inverters?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Inverters' }
    & ProjectInvertersFragment
  )>>, readonly offtakers?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Offtaker' }
    & ProjectOfftakerFragment
  )>>, readonly portfolios?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Portfolio' }
    & ProjectPortfolioFragment
  )>> }
);

export type ProjectPpaFragment = (
  { readonly __typename: 'PPAContract' }
  & Pick<Types.PpaContract, 'startDate' | 'term' | 'rate' | 'escalator'>
);

export type ProjectPanelsFragment = (
  { readonly __typename: 'Panels' }
  & Pick<Types.Panels, 'modelId' | 'count'>
);

export type ProjectInvertersFragment = (
  { readonly __typename: 'Inverters' }
  & Pick<Types.Inverters, 'modelId' | 'count'>
);

export type ProjectOfftakerFragment = (
  { readonly __typename: 'Offtaker' }
  & Pick<Types.Offtaker, 'id'>
);

export type ProjectPortfolioFragment = (
  { readonly __typename: 'Portfolio' }
  & Pick<Types.Portfolio, 'id'>
);

export type ProjectFormQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID'];
}>;


export type ProjectFormQuery = (
  { readonly __typename: 'Query' }
  & { readonly project?: Types.Maybe<(
    { readonly __typename: 'Project' }
    & ProjectFormFragment
  )> }
);

export const LocationFragmentDoc = gql`
    fragment Location on ProjectLocation {
  address
  city
  state
  zipCode
  elevation
  latitude
  longitude
}
    `;
export const ProjectPpaFragmentDoc = gql`
    fragment ProjectPPA on PPAContract {
  startDate
  term
  rate
  escalator
}
    `;
export const ProjectPanelsFragmentDoc = gql`
    fragment ProjectPanels on Panels {
  modelId
  count
}
    `;
export const ProjectInvertersFragmentDoc = gql`
    fragment ProjectInverters on Inverters {
  modelId
  count
}
    `;
export const ProjectOfftakerFragmentDoc = gql`
    fragment ProjectOfftaker on Offtaker {
  id
}
    `;
export const ProjectPortfolioFragmentDoc = gql`
    fragment ProjectPortfolio on Portfolio {
  id
}
    `;
export const ProjectFormFragmentDoc = gql`
    fragment ProjectForm on Project {
  id
  name
  status
  type
  developmentStage
  isOwned
  noticeToProceed
  commercialOperatingDate
  permissionToOperateDate
  acquisitionDate
  capacity
  capacityAc
  location {
    ...Location
  }
  system {
    bessKw
    bessKwh
    panelWarranty
    inverterWarranty
    contractorWarranty
    installation
    mounting
  }
  contact {
    phoneNumber
    balancingAuthorityAreaName
  }
  ppaContracts {
    ...ProjectPPA
  }
  panels {
    ...ProjectPanels
  }
  inverters {
    ...ProjectInverters
  }
  offtakers {
    ...ProjectOfftaker
  }
  portfolios {
    ...ProjectPortfolio
  }
}
    ${LocationFragmentDoc}
${ProjectPpaFragmentDoc}
${ProjectPanelsFragmentDoc}
${ProjectInvertersFragmentDoc}
${ProjectOfftakerFragmentDoc}
${ProjectPortfolioFragmentDoc}`;
export const ProjectFormDocument = gql`
    query ProjectForm($projectId: ID!) {
  project(projectId: $projectId) {
    ...ProjectForm
  }
}
    ${ProjectFormFragmentDoc}`;

/**
 * __useProjectFormQuery__
 *
 * To run a query within a React component, call `useProjectFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFormQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectFormQuery(baseOptions: Apollo.QueryHookOptions<ProjectFormQuery, ProjectFormQueryVariables>) {
        return Apollo.useQuery<ProjectFormQuery, ProjectFormQueryVariables>(ProjectFormDocument, baseOptions);
      }
export function useProjectFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectFormQuery, ProjectFormQueryVariables>) {
          return Apollo.useLazyQuery<ProjectFormQuery, ProjectFormQueryVariables>(ProjectFormDocument, baseOptions);
        }
export type ProjectFormQueryHookResult = ReturnType<typeof useProjectFormQuery>;
export type ProjectFormLazyQueryHookResult = ReturnType<typeof useProjectFormLazyQuery>;
export type ProjectFormQueryResult = Apollo.QueryResult<ProjectFormQuery, ProjectFormQueryVariables>;