import React from "react";
import styled from "styled-components";

import { CellDropdownButton } from "~/components/DataGrid";
import ItemMenu from "~/components/QAndA/components/ItemMenu";

import { useQuestion } from "../context/QuestionContext";
import { useQuestionPortfolios } from "../context/QuestionPortfoliosContext";

const QuestionPortfolios = () => {
  const question = useQuestion();
  const {
    availablePortfolios,
    hasWritePermission,
    addPortfolio,
    addAllPortfolios,
    removePortfolio,
    removeAllPortfolios,
  } = useQuestionPortfolios();

  const disabled = question.isClosed || !hasWritePermission;
  const currentPortfolios = [...question.portfolios].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  const allPortfolios =
    currentPortfolios.length === availablePortfolios?.length;

  return (
    <CellDropdownButton
      disabled={disabled}
      dropdown={
        <ItemMenu
          title="Portfolio"
          anchor="top right"
          origin="top left"
          currentItems={currentPortfolios}
          availableItems={availablePortfolios}
          onSelectItem={(item) => addPortfolio(question, item)}
          onSelectAll={() => addAllPortfolios(question)}
          onDeselectItem={(item) => removePortfolio(question, item)}
          onDeselectAll={() => removeAllPortfolios(question)}
        />
      }
    >
      {allPortfolios ? (
        <Item>All portfolios</Item>
      ) : (
        currentPortfolios.map(({ id, name }) => <Item key={id}>{name}</Item>)
      )}
    </CellDropdownButton>
  );
};

const Item = styled.div`
  white-space: nowrap;
`;

export default QuestionPortfolios;
