import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";

import { WorkflowStatus } from "~/api/types.generated";
import Card from "~/components/PortfolioCard/Card";
import { defaultCoverImage } from "~/components/PortfolioCard/PortfolioCard";
import { formatSize } from "~/utils/calculations";

import { DealRoomCardFragment } from "./DealRoomCard.generated";

export const DEAL_ROOM_CARD_FRAGMENT = gql`
  fragment DealRoomCard on FundEntity {
    __typename
    id
    name
    slug
    status
    coverImage
    systemSize
    dealManager {
      fullName
    }
    org {
      name
      slug
    }
    ... on Portfolio {
      buildStatus
    }
  }
`;

export interface DealRoomCardProps {
  entity: DealRoomCardFragment;
  acquired?: boolean;
  onClick?: (entity: DealRoomCardFragment) => void;
}

const entityUrl = (entity: DealRoomCardFragment) =>
  entity.__typename === "Portfolio"
    ? `/portfolios/${entity.slug}`
    : `/holding-company/${entity.slug}`;

export const DealRoomCard = ({ entity, onClick }: DealRoomCardProps) => {
  const stats = [
    [
      "System Size",
      formatSize(
        entity.systemSize,
        null,
        entity.org.slug === "segue-infra" ? "MW" : null
      ),
    ],
    [
      entity.org.slug === "segue-infra" ? "Developer" : "Owner",
      entity.org.name,
    ],
  ];

  if (entity.__typename === "Portfolio") {
    if (entity.status === WorkflowStatus.Acquired) {
      stats.push(["Status", entity.buildStatus ?? ""]);
    } else {
      stats.push(["Deal Manager", entity.dealManager?.fullName ?? ""]);
    }
  } else {
    stats.push(["Status", "Operating"]);
  }

  return (
    <Link
      className="link-unstyled"
      to={entityUrl(entity)}
      onClick={() => onClick?.(entity)}
    >
      <Card
        title={entity.name}
        stats={stats}
        picture={
          <img
            src={entity.coverImage ? entity.coverImage : defaultCoverImage}
            alt="portfolio"
          />
        }
      />
    </Link>
  );
};
