import { ellipsis } from "polished";
import styled from "styled-components";

import { InputLabel } from "~/components/common/inputs";
import { fgColor } from "~/styles/mixins";
import { red } from "~/styles/theme/color";

export const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.5;

  ${InputLabel} > & {
    margin: 0.625rem 0 0.375rem;
  }

  mark {
    background: none;
    padding: 0;
    font-weight: bold;
  }
`;

export const ErrorText = styled(Text)`
  color: ${red};
`;

export const EllipsisText = styled(Text)`
  ${ellipsis()};
  margin: 0;
`;

export const PrimaryHeading = styled.h1`
  font-size: 1.5rem;
  line-height: 1.333333;
  margin-bottom: 1rem;
`;

export const SecondaryHeading = styled(EllipsisText)`
  font-size: 1.125rem;
`;

export const Title = styled.h2`
  ${ellipsis()};
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: bold;
  margin: 0;
`;

export const Label = styled.p`
  ${ellipsis()};
  display: block;
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: normal;
  margin: 0;
`;

export const LightLabel = styled(Label)`
  ${fgColor.gray600()};
  line-height: 1;
`;
