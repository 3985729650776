import React from "react";

import { SecondaryButton } from "~/components/common/buttons";
import { FileExcelIcon } from "~/components/common/icons";
import { ProjectsSearch } from "~/components/Projects/useProjectsSearch";

export interface ApiProps {
  orgSlug: string;
}

export const ProjectsExportButton = React.memo(function ProjectsExportButton(
  props: ProjectsSearch & ApiProps
) {
  const { orgSlug, portfolioIds } = props;
  const queryParams = portfolioIds
    .map((portfolioId) => `portfolio_ids=${portfolioId}`)
    .join("&");
  return (
    <SecondaryButton
      download
      as="a"
      target="_blank"
      href={`/${orgSlug}/projects-list-export?${queryParams}`}
    >
      <FileExcelIcon />
      <span>Export list</span>
    </SecondaryButton>
  );
});
