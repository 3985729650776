import styled from "styled-components";

import { noOutline } from "~/styles/mixins";

const CellButton = styled.button`
  ${noOutline};
  color: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  height: 100%;
  width: 100%;
  text-align: left;
  font-weight: inherit;

  &[disabled] {
    user-select: text;
    cursor: auto;
  }

  & > i.icon {
    width: 0.625rem;
    height: 0.625rem;
    margin: 0 0 0 0.125rem;
  }
`;

export default CellButton;
