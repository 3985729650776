// @ts-nocheck
// TODO: convert remaining js checklist to tsx components! Use DataGrid (see QAndA)
import * as PropTypes from "prop-types";
import React, { forwardRef, useContext } from "react";
// react-window is a rewriting of the react-virtualized
// and because they have shared API, we can use react-virtualized
// helper to make the table responsive
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeGrid as Grid } from "react-window";

import { ChecklistProjectFragment } from "~/components/Checklist/ChecklistPage.generated";
import ChecklistContext, {
  ChecklistContextValue,
} from "~/components/Checklist/context/ChecklistContext";
import ChecklistFiltersContext, {
  ChecklistFiltersContextValue,
} from "~/components/Checklist/context/ChecklistFiltersContext";

import Cell, { CellType } from "./Cell";

// Settings
const tableHeight = 680;
const tableWidth = 1200;
const rowHeight = 63;
const regularColumnWidth = 230;
const categoryColumnWidth = 300;

export const getHeaderCells = (
  projects: readonly ChecklistProjectFragment[]
) => {
  const edgeCell = {
    cellType: CellType.Project,
    props: { project: { id: "0", name: "Category" } },
  };
  const projectCells = projects.map((project) => ({
    cellType: CellType.Project,
    props: { project },
  }));
  return [edgeCell].concat(projectCells);
};

export const getRows = (
  checklistContext: ChecklistContextValue,
  checklistFiltersContext: ChecklistFiltersContextValue
) =>
  checklistContext.visibleCategories.map((category) => {
    if (!category.parentId) {
      const categoryCell = {
        cellType: CellType.Category,
        props: {
          hasChildren: true,
          categoryId: category.id,
          categoryName: `${category.rowNumber}.0 ${category.name}`,
          toggleCollapsedCategory: checklistContext.toggleCollapsedCategory,
          collapsed: checklistContext.collapsedCategories[category.id],
          category,
        },
      };
      return [
        categoryCell,
        ...checklistContext.visibleProjects.map((project) => ({
          cellType: CellType.ParentChecklistItem,
          props: {
            isLoading: checklistContext.loading,
            item: checklistContext.getItem(category.id, project.id),
            setSelectedChecklistItemId:
              checklistContext.setSelectedChecklistItemId,
            selectedChecklistItemId: checklistContext.selectedChecklistItemId,
          },
        })),
      ];
    } else {
      const parent =
        checklistContext.availableCategoriesById[category.parentId];
      const subCategoryCell = {
        cellType: CellType.Category,
        props: {
          hasChildren: false,
          categoryId: category.id,
          categoryName: `${parent.rowNumber}.${category.rowNumber} ${category.name}`,
          category: category,
          toggleCollapsedCategory: checklistContext.toggleCollapsedCategory,
        },
      };
      return [
        subCategoryCell,
        ...checklistContext.visibleProjects.map((project) => ({
          cellType: CellType.InnerChecklistItem,
          props: {
            isLoading: checklistContext.loading,
            item: checklistContext.getItem(category.id, project.id),
            selectedChecklistItemId: checklistContext.selectedChecklistItemId,
            setSelectedChecklistItemId:
              checklistContext.setSelectedChecklistItemId,
            toggleSelectedChecklistItemId:
              checklistContext.toggleSelectedChecklistItemId,
            users: checklistFiltersContext.usersById,
          },
        })),
      ];
    }
  });

const getStickyWrapper = (rowsCount, columnCount, itemData, height, width) => {
  const style = { width, height };
  const makeCells = (count, isHeader = true) =>
    // Fixme: Ugly! Need to exclude 0:0 cell, because it contains special "Category" cell
    //  performance-wise it is better than slicing itemData because it will be a copy operation
    [...Array(count - 1).keys()].map((idx) => (
      <Cell
        key={`Sticky ${isHeader ? "Header" : "Category"} ${idx}`}
        data={itemData}
        // If it is a header we want to cycle on th first row of itemData matrix
        columnIndex={isHeader ? idx + 1 : 0}
        // If it is a column, we want to cycle all the rows in itemData and take first element index
        rowIndex={isHeader ? 0 : idx + 1}
        style={{
          ...style,
          minWidth: isHeader ? regularColumnWidth : categoryColumnWidth,
        }}
      />
    ));
  const StickyWrapper = forwardRef(({ children, ...rest }, ref) => (
    <div ref={ref} {...rest}>
      <div
        key="sticky-edge"
        className="sticky sticky-header"
        style={{
          top: 0,
          left: 0,
          width: "100%",
          height: rowHeight,
        }}
      >
        <div
          className="sticky-table-header sticky-table-cell sticky-edge-category"
          style={{ ...style, minWidth: categoryColumnWidth }}
        >
          Category
        </div>
        {makeCells(columnCount)}
      </div>
      <div
        className="sticky sticky-column"
        style={{
          top: 0,
          left: 0,
          width: categoryColumnWidth,
          height: "100%",
        }}
      >
        {makeCells(rowsCount, false)}
      </div>
      <div>{children}</div>
    </div>
  ));
  StickyWrapper.propTypes = { children: PropTypes.node.isRequired };
  return StickyWrapper;
};

const ChecklistTable = () => {
  const checklistContext = useContext(ChecklistContext);
  const checklistFilters = useContext(ChecklistFiltersContext);
  const headerCells = getHeaderCells(checklistContext.visibleProjects);
  const rows = getRows(checklistContext, checklistFilters);
  const itemData = [headerCells].concat(rows);

  const columnCount = itemData.length ? itemData[0].length : 0;
  const rowsCount = itemData.length || 0;

  return (
    // Wrapper, adds responsiveness to the react-window grid
    <AutoSizer disableHeight>
      {({ height, width }) => (
        <Grid
          innerElementType={getStickyWrapper(
            rowsCount,
            columnCount,
            itemData,
            rowHeight,
            regularColumnWidth
          )}
          // Total amounts of rows and columns
          columnCount={columnCount}
          rowCount={rowsCount}
          // With VariableSizeGrid we now can pass a function to determine
          // if some column needs different sizing
          columnWidth={(index) =>
            index === 0 ? categoryColumnWidth : regularColumnWidth
          }
          rowHeight={() => rowHeight}
          // Actual table size
          height={height || tableHeight}
          width={width || tableWidth}
          // This item will be passed to every cell with row and column index
          itemData={itemData}
        >
          {Cell}
        </Grid>
      )}
    </AutoSizer>
  );
};

export default ChecklistTable;
