import { gql } from "@apollo/client";
import classNames from "classnames";
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { FundEntityOverviewFragment } from "~/components/Overview/OverviewPage.generated";

export const SUBPAGE_MENU_FRAGMENT = gql`
  fragment SubpagesMenu on SubpageInterface {
    id
    slug
    title
  }
`;

interface SubpagesMenuProps {
  subpages: FundEntityOverviewFragment["subpages"];
  currentSubpageSlug: String;
  parentNodeRef: MutableRefObject<any>;
}

export const SubpagesMenu = ({
  subpages,
  currentSubpageSlug,
  parentNodeRef,
}: SubpagesMenuProps) => {
  const [headerIsVisible, setHeaderIsVisible] = useState(true);
  const [windowResized, setWindowResized] = useState(false);

  const handleScroll = useCallback(() => {
    const scrollFromTop =
      parentNodeRef && parentNodeRef.current.getBoundingClientRect().top;
    setHeaderIsVisible(scrollFromTop > 0);
  }, [parentNodeRef]);

  const handleResize = useCallback(
    () => setWindowResized(!windowResized),
    [windowResized]
  );

  /**
   * When position becomes fixed, the width can change. This method is used to keep the width consistent when the
   * element becomes fixed/sticky
   *
   */
  const calculateWidth = () => {
    if (parentNodeRef.current) {
      const width = parentNodeRef.current.childNodes[1].clientWidth;
      const nodeStyle = window.getComputedStyle(
        parentNodeRef.current.childNodes[1]
      );
      const paddingRight = parseInt(
        nodeStyle.getPropertyValue("padding-right"),
        10
      );
      const paddingLeft = parseInt(
        nodeStyle.getPropertyValue("padding-left"),
        10
      );

      return {
        width,
        paddingRight: `${paddingRight + paddingLeft}px`,
      };
    }
    return {};
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize, handleScroll, windowResized]);

  return (
    <div
      className={classNames({ "sticky-spe": !headerIsVisible })}
      style={calculateWidth()}
    >
      <div className="font-weight-light text-uppercase fs-sm mb-2 pl-3 mt-2">
        Table of contents
      </div>
      <div
        className="nav flex-column nav-pills"
        id="v-pills-tab"
        role="tablist"
      >
        {subpages?.map((subpage) => (
          <Link
            key={`l-${subpage.id}`}
            to={`/overview/${subpage.slug}`}
            className={classNames("nav-link", {
              active: subpage.slug === currentSubpageSlug,
            })}
          >
            {subpage.title}
          </Link>
        ))}
      </div>
    </div>
  );
};
