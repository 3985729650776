import { Grid, MenuItem, TextField } from "@material-ui/core";
import Dialog from "components/generic/Dialog";
import {
  FieldArray,
  Form,
  Formik,
  FormikHelpers as FormikActions,
  getIn,
} from "formik";
import gql from "graphql-tag";
import { trim } from "lodash";
import React, { EventHandler, useCallback, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";

import { PortfolioInput } from "~/api/types.generated";
import GroupAccessTable from "~/components/Admin/Group/GroupAccessTable";
import {
  OrgRoleFragment,
  useCreateGroupMutation,
} from "~/components/Admin/Group/NewGroupDialog.generated";
import { OrgPortfolioFragment } from "~/components/Admin/User/NewUserDialog.generated";
import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { AddIcon } from "~/components/common/icons";
import CancelCircle from "~/components/common/icons/CancelCircle";
import { StyledTextField } from "~/components/common/inputs";
import { BetterAlert as Alert } from "~/components/profile/Alert";
import { blue, danger, gray100, gray300, indigo } from "~/styles/theme/color";
import { borderRadius } from "~/styles/theme/common";

export const ROLE_FRAGMENT = gql`
  fragment OrgRole on Role {
    id
    name
    permissions {
      id
      codename
    }
  }
`;

export const CREATE_GROUP_MUTATION = gql`
  mutation CreateGroup(
    $orgSlug: String!
    $name: String!
    $roleId: ID!
    $portfolios: [PortfolioInput!]
  ) {
    createGroup(
      orgSlug: $orgSlug
      name: $name
      roleId: $roleId
      portfolios: $portfolios
    ) {
      id
      name
      role {
        id
        name
      }
      portfolioIds
    }
  }
`;

export const validationSchema = yup
  .object()
  .shape({
    name: yup.string().required("Name is a required field"),
    roleId: yup.string().required("Role is required"),
    portfolios: yup.array().of(
      yup.object().shape({
        id: yup
          .string()
          .trim()
          .typeError("Please select a portfolio.")
          .required("Please select a portfolio."),
      })
    ),
  })
  .required();
export type NewGroupValues = yup.InferType<typeof validationSchema>;

export interface NewGroupDialogProps {
  orgSlug: string;
  visible: boolean;
  portfoliosLoading: boolean;
  availableRoles: readonly OrgRoleFragment[];
  availablePortfolios: readonly OrgPortfolioFragment[];
  close(): void;
}

export const NewGroupDialog = (props: NewGroupDialogProps) => {
  const {
    orgSlug,
    visible,
    close,
    availableRoles,
    availablePortfolios,
    portfoliosLoading,
  } = props;
  const [alertMessage, setAlertMessage] = useState("");

  const initialValues: NewGroupValues = {
    name: "",
    roleId: "",
    portfolios: [],
  };

  const [create] = useCreateGroupMutation({
    onError: () =>
      setAlertMessage(
        "Unable to add group. Please contact support@dock.energy for assistance."
      ),
  });

  const handleSubmit = useCallback(
    async (
      formFields: NewGroupValues,
      actions: FormikActions<NewGroupValues>
    ) => {
      try {
        await create({
          variables: {
            orgSlug: orgSlug,
            name: formFields.name,
            roleId: formFields.roleId,
            portfolios: formFields.portfolios,
          },
          refetchQueries: ["OrgGroups", "OrgData"],
        });
        close();
        actions.resetForm({
          values: {
            orgSlug: "",
            name: "",
            roleId: "",
            portfolios: [],
          },
        });
      } catch (error) {
        actions.setFieldError("name", trim(error.message, "'"));
      } finally {
        actions.setSubmitting(false);
      }
    },
    [create, close, orgSlug]
  );
  const submitButton = (disabled: boolean, submitForm: EventHandler<any>) => (
    <div>
      <PrimaryButton type="submit" disabled={disabled} onClick={submitForm}>
        Save Group
      </PrimaryButton>
    </div>
  );
  return (
    <div>
      <Alert message={alertMessage} />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          submitForm,
          isSubmitting,
          handleChange,
          handleBlur,
          errors,
          values,
          touched,
        }) => (
          <Dialog
            isOpen={visible}
            onRequestClose={close}
            header={
              <span>
                <i
                  className="thumbnail icon icon--lock-active"
                  style={{ width: "20px", height: "20px", marginBottom: "5px" }}
                />
                <span style={{ paddingLeft: "10px" }}>Add Group</span>
              </span>
            }
            actions={submitButton(isSubmitting, submitForm)}
          >
            <div className="settings-page mb-5">
              <Form>
                <Grid
                  container
                  direction="column"
                  spacing={7}
                  style={{ paddingTop: 24, minHeight: 500 }}
                >
                  <Grid item>
                    <StyledTextField
                      className="form-control"
                      variant="outlined"
                      id="name"
                      name="name"
                      label="Name*"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item>
                    <Tile>
                      <TableTitle>Deal Room Access</TableTitle>
                      <TableContainer>
                        <FieldArray
                          name="portfolios"
                          render={(arrayHelpers) => (
                            <>
                              {values.portfolios.length > 0 &&
                                values.portfolios.map(
                                  (p: PortfolioInput, index: number) => {
                                    const wasTouched =
                                      touched.portfolios &&
                                      Boolean(
                                        getIn(touched, `portfolios.${index}.id`)
                                      );
                                    const hasError =
                                      wasTouched &&
                                      Boolean(
                                        getIn(errors, `portfolios.${index}.id`)
                                      );
                                    return (
                                      <InputRow
                                        key={`${index}-${p.id}`}
                                        error={hasError}
                                      >
                                        <div style={{ flexGrow: 16 }}>
                                          <TextField
                                            InputProps={{
                                              disableUnderline: true,
                                            }}
                                            defaultValue={p.id}
                                            disabled={portfoliosLoading}
                                            name={`portfolios.${index}.id`}
                                            className="form-control"
                                            variant="standard"
                                            select
                                            onChange={handleChange}
                                            error={hasError}
                                          >
                                            <MenuItem
                                              key="---Select a portfolio---"
                                              value=""
                                            >
                                              ---Select a portfolio---
                                            </MenuItem>
                                            {availablePortfolios.map(
                                              ({ id, name }) => (
                                                <MenuItem
                                                  selected={p.id === id}
                                                  key={`${name}`}
                                                  value={id}
                                                >{`${name}`}</MenuItem>
                                              )
                                            )}
                                          </TextField>
                                        </div>
                                        <div
                                          style={{
                                            flexGrow: 1,
                                            alignSelf: "center",
                                          }}
                                        >
                                          <DeleteButton
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          />
                                        </div>
                                      </InputRow>
                                    );
                                  }
                                )}
                              <InputRow last>
                                <SecondaryButton
                                  style={{ width: "100%" }}
                                  onClick={() => {
                                    arrayHelpers.push({
                                      id: "",
                                    });
                                  }}
                                >
                                  <AddIcon />
                                  <span>Allow Deal Room Access</span>
                                </SecondaryButton>
                              </InputRow>
                            </>
                          )}
                        />
                      </TableContainer>
                      {touched.portfolios && errors.portfolios && (
                        <TableError>Please select a portfolio.</TableError>
                      )}
                    </Tile>
                  </Grid>
                  <Grid item>
                    <StyledTextField
                      id="roleId"
                      className="form-control"
                      name="roleId"
                      select
                      defaultValue={null}
                      label="Role*"
                      variant="outlined"
                      value={values.roleId}
                      onChange={handleChange}
                      error={touched.roleId && Boolean(errors.roleId)}
                      helperText={touched.roleId && errors.roleId}
                    >
                      <MenuItem key="---" value="">
                        ---
                      </MenuItem>
                      {availableRoles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </StyledTextField>
                  </Grid>
                  <Grid item>
                    <GroupAccessTable
                      orgSlug={orgSlug}
                      roleId={values.roleId}
                    />
                  </Grid>
                </Grid>
              </Form>
            </div>
          </Dialog>
        )}
      </Formik>
    </div>
  );
};

const Tile = styled.div`
  border-radius: ${borderRadius};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid ${gray300};
  padding: 16px;
`;

const TableTitle = styled.div`
  font-size: 14;
  margin-bottom: 12px;
`;

const TableError = styled.div`
  color: ${danger};
  margin-top: 12px;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  border-top: 1px solid ${gray100};
  display: flex;
  flex-direction: column;
`;

const InputRow = styled.div<{ last?: boolean; error?: boolean }>`
  display: flex;
  max-height: 50px;
  column-gap: 10px;
  justify-content: ${(props) => (props.last ? "flex-start" : "space-between")};
  border: ${(props) =>
    props.last
      ? "none"
      : props.error
      ? `1px solid ${danger}`
      : `1px solid ${gray100}`};
`;

const DeleteButton = styled(CancelCircle)`
  color: ${indigo};

  &:hover {
    color: ${blue};
  }
`;
