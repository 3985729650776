// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type PortfoliosQueryVariables = Types.Exact<{
  holdingCompanyId?: Types.Maybe<Types.Scalars['ID']>;
  orgSlug?: Types.Maybe<Types.Scalars['String']>;
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type PortfoliosQuery = (
  { readonly __typename: 'Query' }
  & { readonly portfolios?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Portfolio' }
    & Pick<Types.Portfolio, 'id' | 'name' | 'slug' | 'status' | 'projectCount' | 'systemSize' | 'remainingPpaTerm' | 'averageOfftakerQuality'>
  )>> }
);

export type OrgPortfoliosQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
}>;


export type OrgPortfoliosQuery = (
  { readonly __typename: 'Query' }
  & { readonly portfolios?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Portfolio' }
    & Pick<Types.Portfolio, 'id' | 'name'>
  )>> }
);


export const PortfoliosDocument = gql`
    query Portfolios($holdingCompanyId: ID, $orgSlug: String, $search: String) {
  portfolios(
    holdingCompanyId: $holdingCompanyId
    orgSlug: $orgSlug
    search: $search
  ) {
    id
    name
    slug
    status
    projectCount
    systemSize
    remainingPpaTerm
    averageOfftakerQuality
  }
}
    `;

/**
 * __usePortfoliosQuery__
 *
 * To run a query within a React component, call `usePortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfoliosQuery({
 *   variables: {
 *      holdingCompanyId: // value for 'holdingCompanyId'
 *      orgSlug: // value for 'orgSlug'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePortfoliosQuery(baseOptions?: Apollo.QueryHookOptions<PortfoliosQuery, PortfoliosQueryVariables>) {
        return Apollo.useQuery<PortfoliosQuery, PortfoliosQueryVariables>(PortfoliosDocument, baseOptions);
      }
export function usePortfoliosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortfoliosQuery, PortfoliosQueryVariables>) {
          return Apollo.useLazyQuery<PortfoliosQuery, PortfoliosQueryVariables>(PortfoliosDocument, baseOptions);
        }
export type PortfoliosQueryHookResult = ReturnType<typeof usePortfoliosQuery>;
export type PortfoliosLazyQueryHookResult = ReturnType<typeof usePortfoliosLazyQuery>;
export type PortfoliosQueryResult = Apollo.QueryResult<PortfoliosQuery, PortfoliosQueryVariables>;
export const OrgPortfoliosDocument = gql`
    query OrgPortfolios($orgSlug: String!) {
  portfolios(orgSlug: $orgSlug) {
    id
    name
  }
}
    `;

/**
 * __useOrgPortfoliosQuery__
 *
 * To run a query within a React component, call `useOrgPortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgPortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgPortfoliosQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useOrgPortfoliosQuery(baseOptions: Apollo.QueryHookOptions<OrgPortfoliosQuery, OrgPortfoliosQueryVariables>) {
        return Apollo.useQuery<OrgPortfoliosQuery, OrgPortfoliosQueryVariables>(OrgPortfoliosDocument, baseOptions);
      }
export function useOrgPortfoliosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgPortfoliosQuery, OrgPortfoliosQueryVariables>) {
          return Apollo.useLazyQuery<OrgPortfoliosQuery, OrgPortfoliosQueryVariables>(OrgPortfoliosDocument, baseOptions);
        }
export type OrgPortfoliosQueryHookResult = ReturnType<typeof useOrgPortfoliosQuery>;
export type OrgPortfoliosLazyQueryHookResult = ReturnType<typeof useOrgPortfoliosLazyQuery>;
export type OrgPortfoliosQueryResult = Apollo.QueryResult<OrgPortfoliosQuery, OrgPortfoliosQueryVariables>;