import gql from "graphql-tag";
import React from "react";
import {
  BrowserRouter,
  NavLink,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { AdminPageViewerFragment } from "~/components/Admin/AdminPage.generated";
import { GroupsPage } from "~/components/Admin/Group/Groups";
import { UsersPage } from "~/components/Admin/User/Users";
import { PageContainer } from "~/components/common/layout";
import { PrimaryHeading } from "~/components/common/text";

export const ADMIN_PAGE_VIEWER_FRAGMENT = gql`
  fragment AdminPageViewer on User {
    id
    organizations {
      id
      name
      slug
    }
  }
`;

interface AdminPageProps {
  viewer: AdminPageViewerFragment;
}

export const AdminPage = ({ viewer }: AdminPageProps) => {
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const match = useRouteMatch();
  const baseUrl = match.path.replace(":orgSlug", orgSlug);
  return (
    <PageContainer>
      <div className="profile-update">
        <PrimaryHeading style={{ marginTop: "10px" }}>Admin</PrimaryHeading>
        <BrowserRouter basename={baseUrl}>
          <div className="row">
            <div className="col-lg-2 mb-3">
              <ul className="nav nav-tabs flex-column nav-settings">
                <li className="nav-item">
                  <NavLink to="/users" className="nav-link">
                    People
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/groups" className="nav-link">
                    Groups
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-10">
              <div className="tab-content">
                <div className="container box--white box--white--profile">
                  <Switch>
                    <Route path="/users" exact>
                      <UsersPage viewer={viewer} orgSlug={orgSlug} />
                    </Route>
                    <Route path="/groups" exact>
                      <GroupsPage viewer={viewer} orgSlug={orgSlug} />
                    </Route>
                    <Redirect to="/users" />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </BrowserRouter>
      </div>
    </PageContainer>
  );
};
