// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type OrgAdminPortfoliosQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  excludeWorkflowStatuses?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['Int']>> | Types.Maybe<Types.Scalars['Int']>>;
}>;


export type OrgAdminPortfoliosQuery = (
  { readonly __typename: 'Query' }
  & { readonly orgPortfolios?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Portfolio' }
    & Pick<Types.Portfolio, 'id' | 'name'>
  )>> }
);

export type DeleteGroupMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  groupId: Types.Scalars['ID'];
}>;


export type DeleteGroupMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deleteGroup?: Types.Maybe<(
    { readonly __typename: 'DeletedObjectResult' }
    & Pick<Types.DeletedObjectResult, 'id'>
  )> }
);


export const OrgAdminPortfoliosDocument = gql`
    query OrgAdminPortfolios($orgSlug: String!, $excludeWorkflowStatuses: [Int]) {
  orgPortfolios(
    orgSlug: $orgSlug
    excludeWorkflowStatuses: $excludeWorkflowStatuses
  ) {
    id
    name
  }
}
    `;

/**
 * __useOrgAdminPortfoliosQuery__
 *
 * To run a query within a React component, call `useOrgAdminPortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgAdminPortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgAdminPortfoliosQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      excludeWorkflowStatuses: // value for 'excludeWorkflowStatuses'
 *   },
 * });
 */
export function useOrgAdminPortfoliosQuery(baseOptions: Apollo.QueryHookOptions<OrgAdminPortfoliosQuery, OrgAdminPortfoliosQueryVariables>) {
        return Apollo.useQuery<OrgAdminPortfoliosQuery, OrgAdminPortfoliosQueryVariables>(OrgAdminPortfoliosDocument, baseOptions);
      }
export function useOrgAdminPortfoliosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgAdminPortfoliosQuery, OrgAdminPortfoliosQueryVariables>) {
          return Apollo.useLazyQuery<OrgAdminPortfoliosQuery, OrgAdminPortfoliosQueryVariables>(OrgAdminPortfoliosDocument, baseOptions);
        }
export type OrgAdminPortfoliosQueryHookResult = ReturnType<typeof useOrgAdminPortfoliosQuery>;
export type OrgAdminPortfoliosLazyQueryHookResult = ReturnType<typeof useOrgAdminPortfoliosLazyQuery>;
export type OrgAdminPortfoliosQueryResult = Apollo.QueryResult<OrgAdminPortfoliosQuery, OrgAdminPortfoliosQueryVariables>;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($orgSlug: String!, $groupId: ID!) {
  deleteGroup(orgSlug: $orgSlug, groupId: $groupId) {
    id
  }
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, baseOptions);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;