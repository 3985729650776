import React, { useContext } from "react";

import ChecklistFilterDropdown, {
  renderUserGroupSelectBlock,
} from "~/components/Checklist/ChecklistFilterDropdown/ChecklistFilterDropdown";
import { ChecklistItemCounts } from "~/components/Checklist/ChecklistItemCounts";
import {
  ChecklistUserFragment,
  ChecklistViewerFragment,
} from "~/components/Checklist/ChecklistPage.generated";
import ChecklistContext from "~/components/Checklist/context/ChecklistContext";
import ChecklistFiltersContext from "~/components/Checklist/context/ChecklistFiltersContext";
import { gettext } from "~/utils/text";

type Item = {
  key: string;
  value: string;
};

interface RenderDropdownItemProps {
  item?: Item;
  selected: boolean;
}

const getUserButtonText = (
  items: Array<Item>,
  selectedItems: Array<string>
) => {
  // Much the same as getDefaultButtonText(), but difficult to refactor
  if (selectedItems.length === items.length || selectedItems.length === 0) {
    return gettext("All");
  } else if (selectedItems.length === 1) {
    if (selectedItems[0] === null) {
      return gettext("None");
    }
    if (selectedItems[0] === DJ_CONST.user.id) {
      return gettext("Me");
    }
    return items.find((item) => item.key === selectedItems[0])?.value;
  } else {
    return gettext("Multiple");
  }
};

export const prepareUsersList = (
  viewer: ChecklistViewerFragment,
  users: readonly ChecklistUserFragment[]
) => {
  const currentUserId = viewer.id;

  const getUserDisplay = (user: ChecklistUserFragment) => ({
    key: user.id,
    value: `${user.fullName} ${user.companyName}`, // used for searching
    displayValue: user.fullName, // displayed
    company: user.companyName,
    user,
  });

  return [
    // Add the current user to the beginning of the list if the user is in user groups
    ...Object.values(users)
      .filter((user) => user.id === currentUserId)
      .map((user) => ({
        ...getUserDisplay(user),
        displayValue: `${user.fullName} (me)`,
      })),
    ...Object.values(users)
      .filter((user) => user.id !== currentUserId)
      .map((user) => getUserDisplay(user)),
  ];
};

export const renderDropdownItem = ({
  item,
  selected,
}: RenderDropdownItemProps) => {
  return [
    <span key="ok" className="filter-dropdown__selected">
      {selected && <i className="icon icon--ok-blue" />}
    </span>,
    item?.value,
  ];
};

export interface ChecklistFiltersProps {
  viewer: ChecklistViewerFragment;
}
export const ChecklistFilters = React.memo(function ChecklistFilters(
  props: ChecklistFiltersProps
) {
  const checklistContext = useContext(ChecklistContext);
  const checklistFilters = useContext(ChecklistFiltersContext);
  const renderDropDownChecklistItemCounter = (item: any) => {
    if (item.key) {
      const inProgressItems = checklistContext.inProgressItems.filter(
        (c) => c.userId === item.user.id
      );
      const attentionNeededItems = checklistContext.attentionNeededItems.filter(
        (c) => c.userId === item.user.id
      );

      return (
        <ChecklistItemCounts
          key="item-counter"
          simple
          attentionNeededItems={
            attentionNeededItems.length > 0 ? attentionNeededItems : undefined
          }
          inProgressItems={
            inProgressItems.length > 0 ? inProgressItems : undefined
          }
        />
      );
    } else {
      const unassignedItems =
        checklistContext.filteredItems.length -
        checklistContext.assignedItems.length;
      return (
        <div key="item-counter" className="ml-auto">
          {unassignedItems}
        </div>
      );
    }
  };
  return (
    <div className="btn-group ml-auto mb-3">
      {checklistContext.activeChecklists.length > 1 && (
        <ChecklistFilterDropdown
          items={checklistContext.activeChecklists.map((checklist) => ({
            key: checklist.id,
            value: checklist.name,
          }))}
          label={gettext("Checklists")}
          title={gettext("Show checklist")}
          selectedOptions={[checklistContext.selectedChecklistId]}
          onSelected={([selected]) =>
            checklistContext.setSelectedChecklistId(selected)
          }
        >
          {renderDropdownItem}
        </ChecklistFilterDropdown>
      )}

      <ChecklistFilterDropdown
        items={checklistFilters.availableStatuses}
        label={gettext("Statuses")}
        title={gettext("Filter by status")}
        selectedOptions={checklistFilters.selectedStatuses}
        multiple
        onSelected={checklistFilters.setSelectedStatuses}
      >
        {renderDropdownItem}
      </ChecklistFilterDropdown>

      <ChecklistFilterDropdown
        items={prepareUsersList(props.viewer, checklistFilters.availableUsers)}
        label={gettext("Assignee")}
        title={gettext("Filter by Who's Assigned")}
        placeholder={gettext("Filter by name or company...")}
        getButtonText={getUserButtonText}
        className="_assignee"
        selectedOptions={checklistFilters.selectedUserIds}
        onSelected={checklistFilters.setSelectedUserIds}
        multiple
        groupSelectBlock={renderUserGroupSelectBlock(
          checklistFilters.selectedUserIds
        )}
      >
        {({ item, selected }) => [
          renderDropdownItem({ selected }),
          item.key && (
            <i
              key="avatar"
              className="thumbnail icon icon-cover icon--avatar-default mr-2"
              style={
                item.user.avatarUrl && {
                  backgroundImage: `url('${item.user.avatarUrl}')`,
                }
              }
            />
          ),
          <div
            key="assignee"
            className="assignee-name"
            title={item.displayValue}
          >
            {item.displayValue}
            <br />
            <span className="_company">{item.company}</span>
          </div>,
          renderDropDownChecklistItemCounter(item),
        ]}
      </ChecklistFilterDropdown>

      <ChecklistFilterDropdown
        items={checklistFilters.availableProjects.map((project) => ({
          key: project.id,
          value: project.name,
        }))}
        label={gettext("Columns")}
        title={gettext("Show/Hide Columns")}
        selectedOptions={checklistFilters.selectedProjectIds}
        onSelected={checklistFilters.setSelectedProjectIds}
        multiple
      >
        {renderDropdownItem}
      </ChecklistFilterDropdown>
    </div>
  );
});
