// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type QuestionPortfolioFragment = (
  { readonly __typename: 'Portfolio' }
  & Pick<Types.Portfolio, 'id' | 'name'>
);

export type PortfoliosQueryVariables = Types.Exact<{
  holdingCompanyId: Types.Scalars['ID'];
}>;


export type PortfoliosQuery = (
  { readonly __typename: 'Query' }
  & { readonly portfolios?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Portfolio' }
    & QuestionPortfolioFragment
  )>> }
);

export type AddPortfolioMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
  portfolioId: Types.Scalars['ID'];
}>;


export type AddPortfolioMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly addQuestionPortfolio?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'modifiedAt'>
    & { readonly portfolios: ReadonlyArray<(
      { readonly __typename: 'Portfolio' }
      & QuestionPortfolioFragment
    )> }
  )> }
);

export type AddPortfoliosMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
}>;


export type AddPortfoliosMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly addAllQuestionPortfolios?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'modifiedAt'>
    & { readonly portfolios: ReadonlyArray<(
      { readonly __typename: 'Portfolio' }
      & QuestionPortfolioFragment
    )> }
  )> }
);

export type RemovePortfolioMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
  portfolioId: Types.Scalars['ID'];
}>;


export type RemovePortfolioMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly removeQuestionPortfolio?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'modifiedAt'>
    & { readonly portfolios: ReadonlyArray<(
      { readonly __typename: 'Portfolio' }
      & QuestionPortfolioFragment
    )> }
  )> }
);

export type RemovePortfoliosMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
}>;


export type RemovePortfoliosMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly removeAllQuestionPortfolios?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'modifiedAt'>
    & { readonly portfolios: ReadonlyArray<(
      { readonly __typename: 'Portfolio' }
      & QuestionPortfolioFragment
    )> }
  )> }
);

export const QuestionPortfolioFragmentDoc = gql`
    fragment QuestionPortfolio on Portfolio {
  id
  name
}
    `;
export const PortfoliosDocument = gql`
    query Portfolios($holdingCompanyId: ID!) {
  portfolios(holdingCompanyId: $holdingCompanyId) {
    ...QuestionPortfolio
  }
}
    ${QuestionPortfolioFragmentDoc}`;

/**
 * __usePortfoliosQuery__
 *
 * To run a query within a React component, call `usePortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfoliosQuery({
 *   variables: {
 *      holdingCompanyId: // value for 'holdingCompanyId'
 *   },
 * });
 */
export function usePortfoliosQuery(baseOptions: Apollo.QueryHookOptions<PortfoliosQuery, PortfoliosQueryVariables>) {
        return Apollo.useQuery<PortfoliosQuery, PortfoliosQueryVariables>(PortfoliosDocument, baseOptions);
      }
export function usePortfoliosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortfoliosQuery, PortfoliosQueryVariables>) {
          return Apollo.useLazyQuery<PortfoliosQuery, PortfoliosQueryVariables>(PortfoliosDocument, baseOptions);
        }
export type PortfoliosQueryHookResult = ReturnType<typeof usePortfoliosQuery>;
export type PortfoliosLazyQueryHookResult = ReturnType<typeof usePortfoliosLazyQuery>;
export type PortfoliosQueryResult = Apollo.QueryResult<PortfoliosQuery, PortfoliosQueryVariables>;
export const AddPortfolioDocument = gql`
    mutation AddPortfolio($questionId: ID!, $portfolioId: ID!) {
  addQuestionPortfolio(questionId: $questionId, portfolioId: $portfolioId) {
    id
    portfolios {
      ...QuestionPortfolio
    }
    modifiedAt
  }
}
    ${QuestionPortfolioFragmentDoc}`;
export type AddPortfolioMutationFn = Apollo.MutationFunction<AddPortfolioMutation, AddPortfolioMutationVariables>;

/**
 * __useAddPortfolioMutation__
 *
 * To run a mutation, you first call `useAddPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPortfolioMutation, { data, loading, error }] = useAddPortfolioMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useAddPortfolioMutation(baseOptions?: Apollo.MutationHookOptions<AddPortfolioMutation, AddPortfolioMutationVariables>) {
        return Apollo.useMutation<AddPortfolioMutation, AddPortfolioMutationVariables>(AddPortfolioDocument, baseOptions);
      }
export type AddPortfolioMutationHookResult = ReturnType<typeof useAddPortfolioMutation>;
export type AddPortfolioMutationResult = Apollo.MutationResult<AddPortfolioMutation>;
export type AddPortfolioMutationOptions = Apollo.BaseMutationOptions<AddPortfolioMutation, AddPortfolioMutationVariables>;
export const AddPortfoliosDocument = gql`
    mutation AddPortfolios($questionId: ID!) {
  addAllQuestionPortfolios(questionId: $questionId) {
    id
    portfolios {
      ...QuestionPortfolio
    }
    modifiedAt
  }
}
    ${QuestionPortfolioFragmentDoc}`;
export type AddPortfoliosMutationFn = Apollo.MutationFunction<AddPortfoliosMutation, AddPortfoliosMutationVariables>;

/**
 * __useAddPortfoliosMutation__
 *
 * To run a mutation, you first call `useAddPortfoliosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPortfoliosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPortfoliosMutation, { data, loading, error }] = useAddPortfoliosMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useAddPortfoliosMutation(baseOptions?: Apollo.MutationHookOptions<AddPortfoliosMutation, AddPortfoliosMutationVariables>) {
        return Apollo.useMutation<AddPortfoliosMutation, AddPortfoliosMutationVariables>(AddPortfoliosDocument, baseOptions);
      }
export type AddPortfoliosMutationHookResult = ReturnType<typeof useAddPortfoliosMutation>;
export type AddPortfoliosMutationResult = Apollo.MutationResult<AddPortfoliosMutation>;
export type AddPortfoliosMutationOptions = Apollo.BaseMutationOptions<AddPortfoliosMutation, AddPortfoliosMutationVariables>;
export const RemovePortfolioDocument = gql`
    mutation RemovePortfolio($questionId: ID!, $portfolioId: ID!) {
  removeQuestionPortfolio(questionId: $questionId, portfolioId: $portfolioId) {
    id
    portfolios {
      ...QuestionPortfolio
    }
    modifiedAt
  }
}
    ${QuestionPortfolioFragmentDoc}`;
export type RemovePortfolioMutationFn = Apollo.MutationFunction<RemovePortfolioMutation, RemovePortfolioMutationVariables>;

/**
 * __useRemovePortfolioMutation__
 *
 * To run a mutation, you first call `useRemovePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePortfolioMutation, { data, loading, error }] = useRemovePortfolioMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useRemovePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<RemovePortfolioMutation, RemovePortfolioMutationVariables>) {
        return Apollo.useMutation<RemovePortfolioMutation, RemovePortfolioMutationVariables>(RemovePortfolioDocument, baseOptions);
      }
export type RemovePortfolioMutationHookResult = ReturnType<typeof useRemovePortfolioMutation>;
export type RemovePortfolioMutationResult = Apollo.MutationResult<RemovePortfolioMutation>;
export type RemovePortfolioMutationOptions = Apollo.BaseMutationOptions<RemovePortfolioMutation, RemovePortfolioMutationVariables>;
export const RemovePortfoliosDocument = gql`
    mutation RemovePortfolios($questionId: ID!) {
  removeAllQuestionPortfolios(questionId: $questionId) {
    id
    portfolios {
      ...QuestionPortfolio
    }
    modifiedAt
  }
}
    ${QuestionPortfolioFragmentDoc}`;
export type RemovePortfoliosMutationFn = Apollo.MutationFunction<RemovePortfoliosMutation, RemovePortfoliosMutationVariables>;

/**
 * __useRemovePortfoliosMutation__
 *
 * To run a mutation, you first call `useRemovePortfoliosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePortfoliosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePortfoliosMutation, { data, loading, error }] = useRemovePortfoliosMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useRemovePortfoliosMutation(baseOptions?: Apollo.MutationHookOptions<RemovePortfoliosMutation, RemovePortfoliosMutationVariables>) {
        return Apollo.useMutation<RemovePortfoliosMutation, RemovePortfoliosMutationVariables>(RemovePortfoliosDocument, baseOptions);
      }
export type RemovePortfoliosMutationHookResult = ReturnType<typeof useRemovePortfoliosMutation>;
export type RemovePortfoliosMutationResult = Apollo.MutationResult<RemovePortfoliosMutation>;
export type RemovePortfoliosMutationOptions = Apollo.BaseMutationOptions<RemovePortfoliosMutation, RemovePortfoliosMutationVariables>;