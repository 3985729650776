export const checklistStatuses = DJ_CONST.CHECKLIST_STATUSES;

export const statusIsInProgress = (status) =>
  [
    DJ_CONST.CHECKLIST_STATUSES.SUBMITTED_FOR_REVIEW,
    DJ_CONST.CHECKLIST_STATUSES.THIRD_PARTY_REVIEW,
    DJ_CONST.CHECKLIST_STATUSES.UNDER_REVIEW,
    DJ_CONST.CHECKLIST_STATUSES.REQUESTED,
  ].includes(status);

export const statusIsDone = (status) =>
  status === DJ_CONST.CHECKLIST_STATUSES.DONE;

export const statusIsAttentionNeeded = (status) =>
  [
    DJ_CONST.CHECKLIST_STATUSES.FOLLOW_UP_NEEDED,
    DJ_CONST.CHECKLIST_STATUSES.SIGNATURE_REQUIRED,
  ].includes(status);

export const translationsByName = {
  DONE: "Done",
  STATE_REQUESTED: "Requested",
  SUBMITTED_FOR_REVIEW: "Submitted for review",
  UNDER_REVIEW: "Under review",
  THIRD_PARTY_REVIEW: "Third party review",
  FOLLOW_UP_NEEDED: "Follow up needed",
  SIGNATURE_REQUIRED: "Signature required",
};

export const translationsById = Object.entries(
  DJ_CONST.CHECKLIST_STATUSES
).reduce((res, [value, code]) => {
  res[code] = translationsByName[value];
  return res;
}, {});
