import { Tabs } from "@material-ui/core";
import styled from "styled-components";

import { border, borderRadius } from "~/styles/mixins";

const VerticalTabs = styled(Tabs).attrs({
  orientation: "vertical",
  TabIndicatorProps: { hidden: true },
})`
  ${border.gray300()};
  ${borderRadius()};
  font-size: 14px;
  margin: 12px 0;
`;

export default VerticalTabs;
