import { css } from "styled-components";

/**
 * Mixin that centers the element's content using flexbox.
 *
 * @example
 *
 *   styled.div`
 *     ${centerContent};
 *   `
 */
const centerContent = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default centerContent;
