import { useCallback, useState } from "react";

import { ZipDownloadOutput } from "~/api/types.generated";
import { DocumentsTableData } from "~/components/Documents/useDocumentsTableData";

import { useDocumentsSubmitZipDownloadsMutation } from "./DocumentsAPI.generated";

export interface DocumentsZipDownloads {
  stagedCount: number;
  error: string | null;
  result: ZipDownloadOutput | null;
  stageDocument(documentId: string): void;
  stageSelected(): void;
  stageSelectedDisabled: boolean;
  confirm(): Promise<void>;
  confirmAvailable: boolean;
  confirmLoading: boolean;
  confirmVisible: boolean;
  reset(): void;
}

/** Handles state and logic required for ZIP downloads. */
export const useDocumentsZipDownloads = (
  treeId: string,
  { selection }: DocumentsTableData
): DocumentsZipDownloads => {
  const [stagedIds, setStagedIds] = useState<readonly string[]>([]);
  const stagedCount = stagedIds.length;
  const [result, setResult] = useState<ZipDownloadOutput | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [request, { loading: confirmLoading }] =
    useDocumentsSubmitZipDownloadsMutation({
      onError: (error) => {
        setError(error.message);
      },
    });

  const confirmAvailable = !result && !error;
  const confirmVisible = stagedCount > 0;

  const stageSelectedDisabled = selection.count === 0;
  const stageSelected = useCallback(() => {
    setStagedIds(selection.values().map((d) => d.id));
  }, [selection]);

  const stageDocument = useCallback((id: string) => setStagedIds([id]), []);

  /** Closes the confirmation dialog */
  const reset = useCallback(() => {
    setStagedIds([]);
    setResult(null);
    setError(null);
  }, []);

  /** Submits the staged file ids as a ZIP download request */
  const confirm = useCallback(async () => {
    try {
      const { data } = await request({
        variables: { treeId, documentIds: stagedIds },
        refetchQueries: ["OAndM"],
      });
      setResult(data?.submitZipDownload ?? null);
    } catch (error) {
      console.error(error);
    }
  }, [request, treeId, stagedIds]);

  return {
    stagedCount,
    error,
    result,
    stageDocument,
    stageSelected,
    stageSelectedDisabled,
    confirm,
    confirmAvailable,
    confirmLoading,
    confirmVisible,
    reset,
  };
};
