import React from "react";
import { matchPath } from "react-router-dom";

import ChecklistFilterDropdown from "~/components/Checklist/ChecklistFilterDropdown/ChecklistFilterDropdown";
import { useProjectsFilterOptions } from "~/components/Projects/useProjectsFilterOptions";
import { ProjectsSearch } from "~/components/Projects/useProjectsSearch";
import { gettext } from "~/utils/text";

type Item = {
  key: string;
  value: string;
};

interface RenderDropdownItemProps {
  item: Item;
  selected: boolean;
}

const getButtonText = (items: Array<Item>, selectedItems: Array<string>) => {
  if (selectedItems.length === items.length || selectedItems.length === 0) {
    return gettext("Portfolios: All");
  } else if (selectedItems.length === 1) {
    return items.find((item) => item.key === selectedItems[0])?.value;
  } else {
    return gettext("Multiple");
  }
};

const getOwnershipText = (items: Array<Item>, selectedItems: Array<string>) => {
  if (selectedItems.length === 1) {
    const value = items.find((item) => item.key === selectedItems[0])?.value;
    if (value === "All") return gettext("Projects: All");
    return gettext(value);
  }
  return gettext("Projects: All");
};

const decodeSelectedOwnership = (selected: string) => {
  if (selected === "null") return null;
  return selected === "true";
};

const ownershipItems = [
  {
    key: "null",
    value: "All",
  },
  {
    key: "true",
    value: "Owned",
  },
  {
    key: "false",
    value: "Pipeline",
  },
];
const encodeSelectedOwnership = (ownership: boolean | null) => {
  if (ownership === null) return "null";
  return ownership ? "true" : "false";
};

const renderDropdownItem = ({ item, selected }: RenderDropdownItemProps) => [
  <span key="ok" className="filter-dropdown__selected">
    {selected && <i className="icon icon--ok-blue" />}
  </span>,
  item.value,
];

export const ProjectsFilters = React.memo(function ProjectsFilters(
  props: ProjectsSearch
) {
  const match = matchPath<{ orgSlug: string }>(
    window.location.pathname,
    "/:orgSlug/projects/"
  );
  const orgSlug = match && match.params.orgSlug;

  const { isOwned, setIsOwned, portfolioIds, setPortfolioIds } = props;
  const { portfolioItems } = useProjectsFilterOptions(orgSlug ?? "");

  return (
    <div className="btn-group ml-auto">
      <ChecklistFilterDropdown
        getButtonText={getOwnershipText}
        items={ownershipItems}
        title={"Filter by Project Ownership"}
        selectedOptions={[encodeSelectedOwnership(isOwned)]}
        onSelected={([selected]) =>
          setIsOwned(decodeSelectedOwnership(selected))
        }
      >
        {renderDropdownItem}
      </ChecklistFilterDropdown>
      <ChecklistFilterDropdown
        getButtonText={getButtonText}
        items={portfolioItems}
        title={"Filter by Portfolios"}
        selectedOptions={portfolioIds ?? []}
        onSelected={(selected) => setPortfolioIds(selected)}
        multiple
      >
        {renderDropdownItem}
      </ChecklistFilterDropdown>
    </div>
  );
});
