// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { ReportingBannerOperatingStatsFragment, ReportingBannerProjectsStatsFragment } from './ReportingBanner.generated';
import { gql } from '@apollo/client';
import { ReportingBannerOperatingStatsFragmentDoc, ReportingBannerProjectsStatsFragmentDoc } from './ReportingBanner.generated';
export type ReportingListFragment = (
  { readonly __typename: 'Query' }
  & { readonly reportingOperatingStats?: Types.Maybe<(
    { readonly __typename: 'OperatingStats' }
    & ReportingBannerOperatingStatsFragment
  )>, readonly reportingProjectsStats?: Types.Maybe<(
    { readonly __typename: 'ProjectsStats' }
    & ReportingBannerProjectsStatsFragment
  )>, readonly reportingHoldingCompanies?: Types.Maybe<ReadonlyArray<Types.Maybe<(
    { readonly __typename: 'HoldingCompany' }
    & Pick<Types.HoldingCompany, 'id' | 'name' | 'slug'>
  )>>> }
);

export const ReportingListFragmentDoc = gql`
    fragment ReportingList on Query {
  reportingOperatingStats(orgSlug: $orgSlug) {
    ...ReportingBannerOperatingStats
  }
  reportingProjectsStats(orgSlug: $orgSlug) {
    ...ReportingBannerProjectsStats
  }
  reportingHoldingCompanies(orgSlug: $orgSlug) {
    id
    name
    slug
  }
}
    ${ReportingBannerOperatingStatsFragmentDoc}
${ReportingBannerProjectsStatsFragmentDoc}`;