// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdatePortfolioMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  portfolioId: Types.Scalars['ID'];
  status: Types.WorkflowStatus;
}>;


export type UpdatePortfolioMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updatePortfolio?: Types.Maybe<(
    { readonly __typename: 'Portfolio' }
    & Pick<Types.Portfolio, 'id' | 'status'>
  )> }
);


export const UpdatePortfolioDocument = gql`
    mutation UpdatePortfolio($orgSlug: String!, $portfolioId: ID!, $status: WorkflowStatus!) {
  updatePortfolio(orgSlug: $orgSlug, portfolioId: $portfolioId, status: $status) {
    id
    status
  }
}
    `;
export type UpdatePortfolioMutationFn = Apollo.MutationFunction<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>;

/**
 * __useUpdatePortfolioMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioMutation, { data, loading, error }] = useUpdatePortfolioMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      portfolioId: // value for 'portfolioId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdatePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>) {
        return Apollo.useMutation<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>(UpdatePortfolioDocument, baseOptions);
      }
export type UpdatePortfolioMutationHookResult = ReturnType<typeof useUpdatePortfolioMutation>;
export type UpdatePortfolioMutationResult = Apollo.MutationResult<UpdatePortfolioMutation>;
export type UpdatePortfolioMutationOptions = Apollo.BaseMutationOptions<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>;