import gql from "graphql-tag";
import React, { useCallback, useContext, useState } from "react";

import { DeleteUserDialog } from "~/components/Admin/User/DeleteUserDialog";
import {
  NewUserDialog,
  ORG_FRAGMENTS,
} from "~/components/Admin/User/NewUserDialog";
import OrderingContext, {
  useOrderingContext,
} from "~/components/Admin/User/OrderingContext";
import { UpdateInviteDialog } from "~/components/Admin/User/UpdateInviteDialog";
import { UpdateUserDialog } from "~/components/Admin/User/UpdateUserDialog";
import { UserMenu } from "~/components/Admin/User/UserMenu";
import {
  useInvitationCountsQuery,
  useInvitationsQuery,
  useOrgDataQuery,
} from "~/components/Admin/User/Users.generated";
import UsersTable, {
  INVITATION_FRAGMENT,
} from "~/components/Admin/User/UsersTable";
import { UserInviteFragment } from "~/components/Admin/User/UsersTable.generated";
import { useUserInvitationSearch } from "~/components/Admin/User/useUserInvitationSearch";
import { useUserInvitationsManager } from "~/components/Admin/User/useUserInvitationsManager";
import { PrimaryButton, TextButton } from "~/components/common/buttons";
import { AddIcon } from "~/components/common/icons";
import { ThematicBreak } from "~/components/common/layout";
import { Pagination } from "~/components/common/pagination";
import { Toolbar } from "~/components/common/toolbars";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import SearchBar from "~/components/SearchBar";
import { SettingsPageViewerFragment } from "~/components/Settings/SettingsPage.generated";

export const ORG_DATA_QUERY = gql`
  query OrgData($orgSlug: String!) {
    orgUserGroups(orgSlug: $orgSlug) {
      ...OrgGroup
    }
  }

  ${ORG_FRAGMENTS}
`;

export const INVITATION_COUNTS_QUERY = gql`
  query InvitationCounts($orgSlug: String!) {
    invitationsData(orgSlug: $orgSlug) {
      accepted
      waiting
    }
  }
`;

export const INVITATIONS_QUERY = gql`
  query Invitations(
    $orgSlug: String!
    $ordering: String
    $search: String
    $offset: Int
    $limit: Int
    $accepted: Boolean
  ) {
    invitationPages(
      orgSlug: $orgSlug
      ordering: $ordering
      search: $search
      limit: $limit
      offset: $offset
      accepted: $accepted
    ) {
      count
      invitations {
        ...UserInvite
      }
    }
  }
  ${INVITATION_FRAGMENT}
`;

export interface UsersProps {
  viewer: SettingsPageViewerFragment;
  orgSlug: string;
}

export const UsersPage = ({ viewer, orgSlug }: UsersProps) => {
  const ordering = useOrderingContext();
  return (
    <OrderingContext.Provider value={ordering}>
      <RenderUsersPage viewer={viewer} orgSlug={orgSlug} />
    </OrderingContext.Provider>
  );
};

export const RenderUsersPage = ({ viewer, orgSlug }: UsersProps) => {
  const { ordering, serializeOrdering } = useContext(OrderingContext);
  const search = useUserInvitationSearch();
  const [page, setPage] = useState<number>(1);
  const [acceptedFilter, setAcceptedFilter] = useState<boolean | null>(null);
  const [addUser, setAddUser] = useState<boolean>(false);
  const [pageSize] = useState(25);
  // TODO: handle when org is missing (bad url)
  const org = viewer.organizations?.filter(
    (org) => org.slug === orgSlug
  )[0] ?? { name: "" };
  const { data: orgData } = useOrgDataQuery({
    variables: {
      orgSlug: orgSlug,
    },
  });
  const { data: counts } = useInvitationCountsQuery({
    variables: { orgSlug: orgSlug },
  });
  const { data, loading } = useInvitationsQuery({
    variables: {
      orgSlug: orgSlug,
      ordering: serializeOrdering(ordering),
      search: search.debouncedSearchTerm ? search.debouncedSearchTerm : "",
      limit: pageSize,
      offset: (page - 1) * pageSize,
      accepted: acceptedFilter,
    },
  });
  const userManager = useUserInvitationsManager(orgSlug);
  const renderUserMenu = useCallback(
    (invite: UserInviteFragment) => (
      <UserMenu invite={invite} {...userManager} />
    ),
    [userManager]
  );
  const dialogs = (
    <>
      <NewUserDialog
        orgSlug={orgSlug}
        visible={addUser}
        close={() => setAddUser(false)}
        availableGroups={orgData?.orgUserGroups ?? []}
      />
      <UpdateUserDialog
        orgSlug={orgSlug}
        {...userManager}
        availableGroups={orgData?.orgUserGroups ?? []}
      />
      <UpdateInviteDialog
        orgSlug={orgSlug}
        {...userManager}
        availableGroups={orgData?.orgUserGroups ?? []}
      />
      <DeleteUserDialog orgName={org.name} {...userManager} />
    </>
  );
  return (
    <>
      <h4 className="mb-4">People</h4>
      <ThematicBreak />
      <div>
        <TextButton
          disabled={acceptedFilter === true}
          onClick={() => {
            setAcceptedFilter(true);
            search.clearSearch();
          }}
        >
          {counts?.invitationsData?.accepted} Accepted
        </TextButton>{" "}
        |
        <TextButton
          disabled={acceptedFilter === false}
          onClick={() => {
            setAcceptedFilter(false);
            search.clearSearch();
          }}
        >
          {counts?.invitationsData?.waiting} Pending
        </TextButton>
      </div>
      <Toolbar>
        <SearchBar
          keyword={search.searchTerm}
          onChange={(e) => search.setSearchTerm(e.target.value)}
          renderInputGroupPrepend={() => (
            <div className="prepend">
              {search.searchPending ? (
                <LoadingSpinner />
              ) : (
                <i className="icon icon--search-dark" />
              )}
            </div>
          )}
          clearable
          onClear={() => search.clearSearch()}
        />
        <PrimaryButton onClick={() => setAddUser(true)} disabled={addUser}>
          <AddIcon />
          <span>Invite User</span>
        </PrimaryButton>
      </Toolbar>
      <UsersTable
        users={data?.invitationPages?.invitations ?? []}
        isLoading={loading}
        renderUserMenu={renderUserMenu}
      />
      <Pagination
        page={page}
        pageSize={pageSize}
        count={data?.invitationPages?.count ?? 0}
        setPage={(page: number) => setPage(page)}
      />
      {dialogs}
    </>
  );
};
