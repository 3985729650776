import { ellipsis } from "polished";
import React, { useContext } from "react";
import styled, { css } from "styled-components";

import { SummaryJobType } from "~/api/types.generated";
import Dropdown from "~/components/menus/Dropdown";
import BaseDropdownButton from "~/components/menus/DropdownButton";
import DropdownTriggerContext from "~/components/menus/DropdownTriggerContext";
import SelectMenu from "~/components/menus/SelectMenu";
import SelectMenuOption from "~/components/menus/SelectMenuOption";
import {
  MenuScroller,
  OptionName,
} from "~/components/QAndA/components/CategoryMenu";
import {
  bgColor,
  border,
  borderRadius,
  fgColor,
  focusOutline,
} from "~/styles/mixins";

const JobTypeDisplay = {
  [SummaryJobType.Insurance]: "Summarize Insurance [Marsh]",
  [SummaryJobType.PpaAnalysis]: "PPA Analysis",
  [SummaryJobType.DamInspection]: "Dam Inspection",
};

interface JobTypeDropdownProps {
  selectedType: SummaryJobType | null;
  setSelectedType: (log: SummaryJobType) => void;
}

export const JobTypeDropdown = ({
  selectedType,
  setSelectedType,
  ...rest
}: JobTypeDropdownProps) => {
  const dropdownTrigger = useContext(DropdownTriggerContext);

  const onSelect = (obj: SummaryJobType) => {
    setSelectedType(obj);
    dropdownTrigger?.measure();
  };

  return (
    <DropdownButton
      hasSelection={selectedType !== null}
      dropdown={
        <Dropdown zIndex={6} {...rest}>
          <MenuScroller>
            <SelectMenu
              closeOnSelect
              isSelected={(obj) => obj === selectedType}
              onSelect={onSelect}
              onDeselect={() => {}}
              aria-multiselectable="true"
            >
              {Object.values(SummaryJobType).map((value) => (
                <SelectMenuOption
                  key={value}
                  value={value}
                  label={JobTypeDisplay[value]}
                >
                  <OptionName>{JobTypeDisplay[value]}</OptionName>
                </SelectMenuOption>
              ))}
            </SelectMenu>
          </MenuScroller>
        </Dropdown>
      }
    >
      {selectedType ? JobTypeDisplay[selectedType] : "Select prompt..."}{" "}
      {String.fromCharCode(9662)}
    </DropdownButton>
  );
};

const DropdownButton = styled(BaseDropdownButton).withConfig<{
  hasSelection: boolean;
}>({
  shouldForwardProp: (prop) => prop !== "hasSelection",
})`
  margin-right: auto;
  display: inline-flex;
  align-items: center;
  ${borderRadius()}
  ${focusOutline()};
  ${bgColor.transparent()};
  ${border.transparent()};
  ${ellipsis()};
  height: 2rem;

  &:hover {
    ${bgColor.gray300()};
  }

  &[aria-expanded="true"] {
    ${bgColor.primary()};
    ${fgColor.white()};
  }

  ${(props) =>
    props.hasSelection &&
    css`
      ${bgColor.white()};
      ${fgColor.primary()};
      ${border.primary()};
    `}
`;

export default JobTypeDropdown;
