// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateQuestionLogMutationVariables = Types.Exact<{
  subjectType: Types.QuestionSubjectType;
  subjectId: Types.Scalars['ID'];
  name: Types.Scalars['String'];
  restricted?: Types.Maybe<Types.Scalars['Boolean']>;
  groupIds?: Types.Maybe<ReadonlyArray<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type CreateQuestionLogMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createQuestionLog?: Types.Maybe<(
    { readonly __typename: 'QuestionLog' }
    & Pick<Types.QuestionLog, 'id' | 'name' | 'restricted'>
    & { readonly groups?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'UserGroup' }
      & Pick<Types.UserGroup, 'id'>
    )>> }
  )> }
);

export type UpdateQuestionLogMutationVariables = Types.Exact<{
  logId: Types.Scalars['ID'];
  name?: Types.Maybe<Types.Scalars['String']>;
  restricted?: Types.Maybe<Types.Scalars['Boolean']>;
  groupIds?: Types.Maybe<ReadonlyArray<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type UpdateQuestionLogMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateQuestionLog?: Types.Maybe<(
    { readonly __typename: 'QuestionLog' }
    & Pick<Types.QuestionLog, 'id' | 'name' | 'restricted'>
    & { readonly groups?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'UserGroup' }
      & Pick<Types.UserGroup, 'id'>
    )>> }
  )> }
);

export type CreateQuestionMutationVariables = Types.Exact<{
  logId: Types.Scalars['ID'];
  categoryIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
  topicIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
  projectIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
  portfolioIds?: Types.Maybe<ReadonlyArray<Types.Maybe<Types.Scalars['ID']>> | Types.Maybe<Types.Scalars['ID']>>;
}>;


export type CreateQuestionMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createQuestion?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'number'>
  )> }
);

export type UpdateQuestionMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
  body?: Types.Maybe<Types.Scalars['String']>;
  isClosed?: Types.Maybe<Types.Scalars['Boolean']>;
  isPriority?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type UpdateQuestionMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateQuestion?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'body' | 'isClosed' | 'isPriority' | 'modifiedAt'>
  )> }
);

export type AddFollowUpMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
}>;


export type AddFollowUpMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly addFollowUpQuestion?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id'>
    & { readonly followUps: ReadonlyArray<(
      { readonly __typename: 'Question' }
      & Pick<Types.Question, 'id' | 'number' | 'isClosed' | 'isPriority' | 'createdAt' | 'modifiedAt' | 'firstViewedAt' | 'viewedAt' | 'body' | 'parentId'>
      & { readonly categories: ReadonlyArray<(
        { readonly __typename: 'QuestionCategory' }
        & Pick<Types.QuestionCategory, 'id' | 'name'>
      )>, readonly topics: ReadonlyArray<(
        { readonly __typename: 'QuestionTopic' }
        & Pick<Types.QuestionTopic, 'id' | 'name'>
      )>, readonly projects: ReadonlyArray<(
        { readonly __typename: 'Project' }
        & Pick<Types.Project, 'id' | 'name'>
      )>, readonly answer: (
        { readonly __typename: 'QuestionAnswer' }
        & Pick<Types.QuestionAnswer, 'id' | 'body' | 'createdAt' | 'modifiedAt'>
      ) }
    )> }
  )> }
);

export type UpdateAnswerMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
  body: Types.Scalars['String'];
}>;


export type UpdateAnswerMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateQuestionAnswer?: Types.Maybe<(
    { readonly __typename: 'QuestionAnswer' }
    & Pick<Types.QuestionAnswer, 'id' | 'body' | 'modifiedAt'>
  )> }
);

export type AddCategoryMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
  categoryId: Types.Scalars['ID'];
}>;


export type AddCategoryMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly addQuestionCategory?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'modifiedAt'>
    & { readonly categories: ReadonlyArray<(
      { readonly __typename: 'QuestionCategory' }
      & Pick<Types.QuestionCategory, 'id' | 'name'>
    )> }
  )> }
);

export type RemoveCategoryMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
  categoryId: Types.Scalars['ID'];
}>;


export type RemoveCategoryMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly removeQuestionCategory?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'modifiedAt'>
    & { readonly categories: ReadonlyArray<(
      { readonly __typename: 'QuestionCategory' }
      & Pick<Types.QuestionCategory, 'id' | 'name'>
    )> }
  )> }
);

export type AddTopicMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
  topicId: Types.Scalars['ID'];
}>;


export type AddTopicMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly addQuestionTopic?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'modifiedAt'>
    & { readonly topics: ReadonlyArray<(
      { readonly __typename: 'QuestionTopic' }
      & Pick<Types.QuestionTopic, 'id' | 'name'>
    )> }
  )> }
);

export type RemoveTopicMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
  topicId: Types.Scalars['ID'];
}>;


export type RemoveTopicMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly removeQuestionTopic?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'modifiedAt'>
    & { readonly topics: ReadonlyArray<(
      { readonly __typename: 'QuestionTopic' }
      & Pick<Types.QuestionTopic, 'id' | 'name'>
    )> }
  )> }
);

export type AddProjectMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
  projectId: Types.Scalars['ID'];
}>;


export type AddProjectMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly addQuestionProject?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'modifiedAt'>
    & { readonly projects: ReadonlyArray<(
      { readonly __typename: 'Project' }
      & Pick<Types.Project, 'id' | 'name'>
    )> }
  )> }
);

export type AddAllProjectsMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
}>;


export type AddAllProjectsMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly addAllQuestionProjects?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'modifiedAt'>
    & { readonly projects: ReadonlyArray<(
      { readonly __typename: 'Project' }
      & Pick<Types.Project, 'id' | 'name'>
    )> }
  )> }
);

export type RemoveProjectMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
  projectId: Types.Scalars['ID'];
}>;


export type RemoveProjectMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly removeQuestionProject?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'modifiedAt'>
    & { readonly projects: ReadonlyArray<(
      { readonly __typename: 'Project' }
      & Pick<Types.Project, 'id' | 'name'>
    )> }
  )> }
);

export type RemoveAllProjectsMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
}>;


export type RemoveAllProjectsMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly removeAllQuestionProjects?: Types.Maybe<(
    { readonly __typename: 'Question' }
    & Pick<Types.Question, 'id' | 'modifiedAt'>
    & { readonly projects: ReadonlyArray<(
      { readonly __typename: 'Project' }
      & Pick<Types.Project, 'id' | 'name'>
    )> }
  )> }
);


export const CreateQuestionLogDocument = gql`
    mutation CreateQuestionLog($subjectType: QuestionSubjectType!, $subjectId: ID!, $name: String!, $restricted: Boolean, $groupIds: [ID!]) {
  createQuestionLog(
    subjectType: $subjectType
    subjectId: $subjectId
    name: $name
    restricted: $restricted
    groupIds: $groupIds
  ) {
    id
    name
    restricted
    groups {
      id
    }
  }
}
    `;
export type CreateQuestionLogMutationFn = Apollo.MutationFunction<CreateQuestionLogMutation, CreateQuestionLogMutationVariables>;

/**
 * __useCreateQuestionLogMutation__
 *
 * To run a mutation, you first call `useCreateQuestionLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionLogMutation, { data, loading, error }] = useCreateQuestionLogMutation({
 *   variables: {
 *      subjectType: // value for 'subjectType'
 *      subjectId: // value for 'subjectId'
 *      name: // value for 'name'
 *      restricted: // value for 'restricted'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useCreateQuestionLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionLogMutation, CreateQuestionLogMutationVariables>) {
        return Apollo.useMutation<CreateQuestionLogMutation, CreateQuestionLogMutationVariables>(CreateQuestionLogDocument, baseOptions);
      }
export type CreateQuestionLogMutationHookResult = ReturnType<typeof useCreateQuestionLogMutation>;
export type CreateQuestionLogMutationResult = Apollo.MutationResult<CreateQuestionLogMutation>;
export type CreateQuestionLogMutationOptions = Apollo.BaseMutationOptions<CreateQuestionLogMutation, CreateQuestionLogMutationVariables>;
export const UpdateQuestionLogDocument = gql`
    mutation UpdateQuestionLog($logId: ID!, $name: String, $restricted: Boolean, $groupIds: [ID!]) {
  updateQuestionLog(
    logId: $logId
    name: $name
    restricted: $restricted
    groupIds: $groupIds
  ) {
    id
    name
    restricted
    groups {
      id
    }
  }
}
    `;
export type UpdateQuestionLogMutationFn = Apollo.MutationFunction<UpdateQuestionLogMutation, UpdateQuestionLogMutationVariables>;

/**
 * __useUpdateQuestionLogMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionLogMutation, { data, loading, error }] = useUpdateQuestionLogMutation({
 *   variables: {
 *      logId: // value for 'logId'
 *      name: // value for 'name'
 *      restricted: // value for 'restricted'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useUpdateQuestionLogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionLogMutation, UpdateQuestionLogMutationVariables>) {
        return Apollo.useMutation<UpdateQuestionLogMutation, UpdateQuestionLogMutationVariables>(UpdateQuestionLogDocument, baseOptions);
      }
export type UpdateQuestionLogMutationHookResult = ReturnType<typeof useUpdateQuestionLogMutation>;
export type UpdateQuestionLogMutationResult = Apollo.MutationResult<UpdateQuestionLogMutation>;
export type UpdateQuestionLogMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionLogMutation, UpdateQuestionLogMutationVariables>;
export const CreateQuestionDocument = gql`
    mutation CreateQuestion($logId: ID!, $categoryIds: [ID], $topicIds: [ID], $projectIds: [ID], $portfolioIds: [ID]) {
  createQuestion(
    logId: $logId
    categoryIds: $categoryIds
    topicIds: $topicIds
    projectIds: $projectIds
    portfolioIds: $portfolioIds
  ) {
    id
    number
  }
}
    `;
export type CreateQuestionMutationFn = Apollo.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>;

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      logId: // value for 'logId'
 *      categoryIds: // value for 'categoryIds'
 *      topicIds: // value for 'topicIds'
 *      projectIds: // value for 'projectIds'
 *      portfolioIds: // value for 'portfolioIds'
 *   },
 * });
 */
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>) {
        return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, baseOptions);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>;
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<CreateQuestionMutation, CreateQuestionMutationVariables>;
export const UpdateQuestionDocument = gql`
    mutation UpdateQuestion($questionId: ID!, $body: String, $isClosed: Boolean, $isPriority: Boolean) {
  updateQuestion(
    questionId: $questionId
    body: $body
    isClosed: $isClosed
    isPriority: $isPriority
  ) {
    id
    body
    isClosed
    isPriority
    modifiedAt
  }
}
    `;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      body: // value for 'body'
 *      isClosed: // value for 'isClosed'
 *      isPriority: // value for 'isPriority'
 *   },
 * });
 */
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
        return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, baseOptions);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export const AddFollowUpDocument = gql`
    mutation AddFollowUp($questionId: ID!) {
  addFollowUpQuestion(questionId: $questionId) {
    id
    followUps {
      id
      number
      isClosed
      isPriority
      createdAt
      modifiedAt
      firstViewedAt
      viewedAt
      categories {
        id
        name
      }
      topics {
        id
        name
      }
      projects {
        id
        name
      }
      body
      answer {
        id
        body
        createdAt
        modifiedAt
      }
      parentId
    }
  }
}
    `;
export type AddFollowUpMutationFn = Apollo.MutationFunction<AddFollowUpMutation, AddFollowUpMutationVariables>;

/**
 * __useAddFollowUpMutation__
 *
 * To run a mutation, you first call `useAddFollowUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFollowUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFollowUpMutation, { data, loading, error }] = useAddFollowUpMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useAddFollowUpMutation(baseOptions?: Apollo.MutationHookOptions<AddFollowUpMutation, AddFollowUpMutationVariables>) {
        return Apollo.useMutation<AddFollowUpMutation, AddFollowUpMutationVariables>(AddFollowUpDocument, baseOptions);
      }
export type AddFollowUpMutationHookResult = ReturnType<typeof useAddFollowUpMutation>;
export type AddFollowUpMutationResult = Apollo.MutationResult<AddFollowUpMutation>;
export type AddFollowUpMutationOptions = Apollo.BaseMutationOptions<AddFollowUpMutation, AddFollowUpMutationVariables>;
export const UpdateAnswerDocument = gql`
    mutation UpdateAnswer($questionId: ID!, $body: String!) {
  updateQuestionAnswer(questionId: $questionId, body: $body) {
    id
    body
    modifiedAt
  }
}
    `;
export type UpdateAnswerMutationFn = Apollo.MutationFunction<UpdateAnswerMutation, UpdateAnswerMutationVariables>;

/**
 * __useUpdateAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnswerMutation, { data, loading, error }] = useUpdateAnswerMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnswerMutation, UpdateAnswerMutationVariables>) {
        return Apollo.useMutation<UpdateAnswerMutation, UpdateAnswerMutationVariables>(UpdateAnswerDocument, baseOptions);
      }
export type UpdateAnswerMutationHookResult = ReturnType<typeof useUpdateAnswerMutation>;
export type UpdateAnswerMutationResult = Apollo.MutationResult<UpdateAnswerMutation>;
export type UpdateAnswerMutationOptions = Apollo.BaseMutationOptions<UpdateAnswerMutation, UpdateAnswerMutationVariables>;
export const AddCategoryDocument = gql`
    mutation AddCategory($questionId: ID!, $categoryId: ID!) {
  addQuestionCategory(questionId: $questionId, categoryId: $categoryId) {
    id
    categories {
      id
      name
    }
    modifiedAt
  }
}
    `;
export type AddCategoryMutationFn = Apollo.MutationFunction<AddCategoryMutation, AddCategoryMutationVariables>;

/**
 * __useAddCategoryMutation__
 *
 * To run a mutation, you first call `useAddCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoryMutation, { data, loading, error }] = useAddCategoryMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useAddCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddCategoryMutation, AddCategoryMutationVariables>) {
        return Apollo.useMutation<AddCategoryMutation, AddCategoryMutationVariables>(AddCategoryDocument, baseOptions);
      }
export type AddCategoryMutationHookResult = ReturnType<typeof useAddCategoryMutation>;
export type AddCategoryMutationResult = Apollo.MutationResult<AddCategoryMutation>;
export type AddCategoryMutationOptions = Apollo.BaseMutationOptions<AddCategoryMutation, AddCategoryMutationVariables>;
export const RemoveCategoryDocument = gql`
    mutation RemoveCategory($questionId: ID!, $categoryId: ID!) {
  removeQuestionCategory(questionId: $questionId, categoryId: $categoryId) {
    id
    categories {
      id
      name
    }
    modifiedAt
  }
}
    `;
export type RemoveCategoryMutationFn = Apollo.MutationFunction<RemoveCategoryMutation, RemoveCategoryMutationVariables>;

/**
 * __useRemoveCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCategoryMutation, { data, loading, error }] = useRemoveCategoryMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useRemoveCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCategoryMutation, RemoveCategoryMutationVariables>) {
        return Apollo.useMutation<RemoveCategoryMutation, RemoveCategoryMutationVariables>(RemoveCategoryDocument, baseOptions);
      }
export type RemoveCategoryMutationHookResult = ReturnType<typeof useRemoveCategoryMutation>;
export type RemoveCategoryMutationResult = Apollo.MutationResult<RemoveCategoryMutation>;
export type RemoveCategoryMutationOptions = Apollo.BaseMutationOptions<RemoveCategoryMutation, RemoveCategoryMutationVariables>;
export const AddTopicDocument = gql`
    mutation AddTopic($questionId: ID!, $topicId: ID!) {
  addQuestionTopic(questionId: $questionId, topicId: $topicId) {
    id
    topics {
      id
      name
    }
    modifiedAt
  }
}
    `;
export type AddTopicMutationFn = Apollo.MutationFunction<AddTopicMutation, AddTopicMutationVariables>;

/**
 * __useAddTopicMutation__
 *
 * To run a mutation, you first call `useAddTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTopicMutation, { data, loading, error }] = useAddTopicMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useAddTopicMutation(baseOptions?: Apollo.MutationHookOptions<AddTopicMutation, AddTopicMutationVariables>) {
        return Apollo.useMutation<AddTopicMutation, AddTopicMutationVariables>(AddTopicDocument, baseOptions);
      }
export type AddTopicMutationHookResult = ReturnType<typeof useAddTopicMutation>;
export type AddTopicMutationResult = Apollo.MutationResult<AddTopicMutation>;
export type AddTopicMutationOptions = Apollo.BaseMutationOptions<AddTopicMutation, AddTopicMutationVariables>;
export const RemoveTopicDocument = gql`
    mutation RemoveTopic($questionId: ID!, $topicId: ID!) {
  removeQuestionTopic(questionId: $questionId, topicId: $topicId) {
    id
    topics {
      id
      name
    }
    modifiedAt
  }
}
    `;
export type RemoveTopicMutationFn = Apollo.MutationFunction<RemoveTopicMutation, RemoveTopicMutationVariables>;

/**
 * __useRemoveTopicMutation__
 *
 * To run a mutation, you first call `useRemoveTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTopicMutation, { data, loading, error }] = useRemoveTopicMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useRemoveTopicMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTopicMutation, RemoveTopicMutationVariables>) {
        return Apollo.useMutation<RemoveTopicMutation, RemoveTopicMutationVariables>(RemoveTopicDocument, baseOptions);
      }
export type RemoveTopicMutationHookResult = ReturnType<typeof useRemoveTopicMutation>;
export type RemoveTopicMutationResult = Apollo.MutationResult<RemoveTopicMutation>;
export type RemoveTopicMutationOptions = Apollo.BaseMutationOptions<RemoveTopicMutation, RemoveTopicMutationVariables>;
export const AddProjectDocument = gql`
    mutation AddProject($questionId: ID!, $projectId: ID!) {
  addQuestionProject(questionId: $questionId, projectId: $projectId) {
    id
    projects {
      id
      name
    }
    modifiedAt
  }
}
    `;
export type AddProjectMutationFn = Apollo.MutationFunction<AddProjectMutation, AddProjectMutationVariables>;

/**
 * __useAddProjectMutation__
 *
 * To run a mutation, you first call `useAddProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectMutation, { data, loading, error }] = useAddProjectMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAddProjectMutation(baseOptions?: Apollo.MutationHookOptions<AddProjectMutation, AddProjectMutationVariables>) {
        return Apollo.useMutation<AddProjectMutation, AddProjectMutationVariables>(AddProjectDocument, baseOptions);
      }
export type AddProjectMutationHookResult = ReturnType<typeof useAddProjectMutation>;
export type AddProjectMutationResult = Apollo.MutationResult<AddProjectMutation>;
export type AddProjectMutationOptions = Apollo.BaseMutationOptions<AddProjectMutation, AddProjectMutationVariables>;
export const AddAllProjectsDocument = gql`
    mutation AddAllProjects($questionId: ID!) {
  addAllQuestionProjects(questionId: $questionId) {
    id
    projects {
      id
      name
    }
    modifiedAt
  }
}
    `;
export type AddAllProjectsMutationFn = Apollo.MutationFunction<AddAllProjectsMutation, AddAllProjectsMutationVariables>;

/**
 * __useAddAllProjectsMutation__
 *
 * To run a mutation, you first call `useAddAllProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAllProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAllProjectsMutation, { data, loading, error }] = useAddAllProjectsMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useAddAllProjectsMutation(baseOptions?: Apollo.MutationHookOptions<AddAllProjectsMutation, AddAllProjectsMutationVariables>) {
        return Apollo.useMutation<AddAllProjectsMutation, AddAllProjectsMutationVariables>(AddAllProjectsDocument, baseOptions);
      }
export type AddAllProjectsMutationHookResult = ReturnType<typeof useAddAllProjectsMutation>;
export type AddAllProjectsMutationResult = Apollo.MutationResult<AddAllProjectsMutation>;
export type AddAllProjectsMutationOptions = Apollo.BaseMutationOptions<AddAllProjectsMutation, AddAllProjectsMutationVariables>;
export const RemoveProjectDocument = gql`
    mutation RemoveProject($questionId: ID!, $projectId: ID!) {
  removeQuestionProject(questionId: $questionId, projectId: $projectId) {
    id
    projects {
      id
      name
    }
    modifiedAt
  }
}
    `;
export type RemoveProjectMutationFn = Apollo.MutationFunction<RemoveProjectMutation, RemoveProjectMutationVariables>;

/**
 * __useRemoveProjectMutation__
 *
 * To run a mutation, you first call `useRemoveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectMutation, { data, loading, error }] = useRemoveProjectMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRemoveProjectMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProjectMutation, RemoveProjectMutationVariables>) {
        return Apollo.useMutation<RemoveProjectMutation, RemoveProjectMutationVariables>(RemoveProjectDocument, baseOptions);
      }
export type RemoveProjectMutationHookResult = ReturnType<typeof useRemoveProjectMutation>;
export type RemoveProjectMutationResult = Apollo.MutationResult<RemoveProjectMutation>;
export type RemoveProjectMutationOptions = Apollo.BaseMutationOptions<RemoveProjectMutation, RemoveProjectMutationVariables>;
export const RemoveAllProjectsDocument = gql`
    mutation RemoveAllProjects($questionId: ID!) {
  removeAllQuestionProjects(questionId: $questionId) {
    id
    projects {
      id
      name
    }
    modifiedAt
  }
}
    `;
export type RemoveAllProjectsMutationFn = Apollo.MutationFunction<RemoveAllProjectsMutation, RemoveAllProjectsMutationVariables>;

/**
 * __useRemoveAllProjectsMutation__
 *
 * To run a mutation, you first call `useRemoveAllProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllProjectsMutation, { data, loading, error }] = useRemoveAllProjectsMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useRemoveAllProjectsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAllProjectsMutation, RemoveAllProjectsMutationVariables>) {
        return Apollo.useMutation<RemoveAllProjectsMutation, RemoveAllProjectsMutationVariables>(RemoveAllProjectsDocument, baseOptions);
      }
export type RemoveAllProjectsMutationHookResult = ReturnType<typeof useRemoveAllProjectsMutation>;
export type RemoveAllProjectsMutationResult = Apollo.MutationResult<RemoveAllProjectsMutation>;
export type RemoveAllProjectsMutationOptions = Apollo.BaseMutationOptions<RemoveAllProjectsMutation, RemoveAllProjectsMutationVariables>;