import Icon from "components/icons/Icon";
import * as PropTypes from "prop-types";
import React from "react";

class ClockIcon extends React.PureComponent {
  render() {
    return (
      <Icon
        color={this.props.color}
        withoutBackground={this.props.withoutBackground}
        viewBox="0 0 16 16"
        className={this.props.className}
      >
        <defs>
          <path
            d="M8 3.375c.32 0 .594.242.625.563l.5 5.296c0
                        .22-.164.391-.375.391h-.008l-4.805-.5a.627.627 0 1 1
                        0-1.25l3.079-.305.367-3.633A.62.62 0 0 1 8 3.375zM8 0c4.422 0 8 3.578
                        8 8a8 8 0 1 1-8-8zm0 14c3.313 0 6-2.695 6-6 0-3.313-2.688-6-6-6-3.305
                        0-6 2.688-6 6 0 3.305 2.695 6 6 6z"
            id="clock-icon-a"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="clock-icon-b" fill="#fff">
            <use xlinkHref="#clock-icon-a" />
          </mask>
          <use xlinkHref="#clock-icon-a" />
          <g mask="url(#clock-icon-b)">
            <path d="M0 0h16v16H0z" />
          </g>
        </g>
      </Icon>
    );
  }
}

ClockIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  withoutBackground: PropTypes.bool,
};

ClockIcon.defaultProps = {
  className: null,
  color: "orange",
  withoutBackground: false,
};

export default ClockIcon;
