import { useEffect, useState } from "react";

import usePrevious from "./usePrevious";
import useThrottled from "./useThrottled";

export interface UseRemoteStateOptions<T> {
  merge?: (localState: T, remoteState: T) => T;
  throttleTime?: number;
}

export type UseRemoteStateResult<T> = [T, (value: T) => unknown];

/**
 * A hook that helps for synchronizing some local and remote state, while
 * throttling requests to change the remote state.
 *
 * By default, the local state is not automatically updated when the remote
 * state changes. A `merge` function can be provided to implement real-time
 * features such as collaborative editing.
 */
const useRemoteState = <T>(
  remoteState: T,
  setRemoteState: (value: T) => unknown,
  {
    // By default, don't merge new remote values down
    merge = (localState, _remoteState) => localState,
    throttleTime = 1000,
  }: UseRemoteStateOptions<T> = {}
): UseRemoteStateResult<T> => {
  const setRemoteStateThrottled = useThrottled(setRemoteState, throttleTime);
  const prevRemoteState = usePrevious(remoteState);

  const [localState, setLocalState] = useState(remoteState);

  const setState: typeof setRemoteState = (nextState) => {
    if (nextState === localState) return;
    setLocalState(nextState);
    setRemoteStateThrottled(nextState);
  };

  useEffect(() => {
    if (remoteState === prevRemoteState) return;

    const mergedState = merge(localState, remoteState);
    if (mergedState === localState) return;

    setLocalState(mergedState);
  }, [localState, remoteState, prevRemoteState, merge]);

  return [localState, setState];
};

export default useRemoteState;
