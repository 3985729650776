import { LocationDescriptorObject } from "history";
import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { SecondaryButton } from "~/components/common/buttons";
import { CaretIcon } from "~/components/common/icons";

export interface DocumentsPrevNextProps {
  prevId?: string;
  nextId?: string;
  getLink: (documentId: string) => LocationDescriptorObject;
}

export const DocumentsPrevNext = (props: DocumentsPrevNextProps) => {
  const { getLink, prevId, nextId } = props;
  const history = useHistory();

  const prevIcon = <CaretIcon style={{ transform: "rotate(180deg)" }} />;
  const nextIcon = <CaretIcon />;

  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft" && prevId) {
        history.push(getLink(prevId));
      } else if (event.key === "ArrowRight" && nextId) {
        history.push(getLink(nextId));
      }
    };

    window.addEventListener("keydown", onKeyPress);
    return () => {
      window.removeEventListener("keydown", onKeyPress);
    };
  }, [history, getLink, prevId, nextId]);

  return (
    <>
      {prevId ? (
        <SecondaryButton as={Link} to={getLink(prevId)} children={prevIcon} />
      ) : (
        <SecondaryButton disabled children={prevIcon} />
      )}
      {nextId ? (
        <SecondaryButton as={Link} to={getLink(nextId)} children={nextIcon} />
      ) : (
        <SecondaryButton disabled children={nextIcon} />
      )}
    </>
  );
};
