import BannerHeader from "components/Banners/BannerHeader";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";
import { formatSize } from "utils/calculations";

import LoadingSpinner from "~/components/generic/LoadingSpinner";
import BlackBanner from "~/components/layout/BlackBanner";
import Stat from "~/components/typography/Stat";
import StatLabel from "~/components/typography/StatLabel";
import { formattedNumber } from "~/components/utils/portfolio";

import { ProjectsBannerFragment } from "./ProjectsBanner.generated";

export const PROJECTS_BANNER_FRAGMENT = gql`
  fragment ProjectsBanner on ProjectsCollection {
    aggregations {
      minCapacity
      maxCapacity
      count
      totalCapacity
    }
  }
`;

export interface ProjectsBannerProps {
  data?: ProjectsBannerFragment | null;
  showAdmin: boolean;
}

const renderBannerData = (
  data: ProjectsBannerFragment | null,
  showLoading: boolean = false
) => {
  return (
    <>
      <div>
        <StatLabel>Number</StatLabel>
        <Stat>
          {showLoading ? (
            <LoadingSpinner color="light" />
          ) : (
            formattedNumber(data?.aggregations?.count)
          )}
        </Stat>
      </div>
      <div>
        <StatLabel>Total System Size</StatLabel>
        <Stat>
          {showLoading ? (
            <LoadingSpinner color="light" />
          ) : (
            formatSize(data?.aggregations?.totalCapacity)
          )}
        </Stat>
      </div>
      <div>
        <StatLabel>System Size Range</StatLabel>
        <Row>
          <Icon />
          {showLoading ? (
            <StackedContainer>
              <LoadingSpinner color="light" />
            </StackedContainer>
          ) : (
            <StackedContainer>
              <StackedText>
                {formatSize(data?.aggregations?.maxCapacity)}
              </StackedText>
              <StackedText>
                {formatSize(data?.aggregations?.minCapacity)}
              </StackedText>
            </StackedContainer>
          )}
        </Row>
      </div>
    </>
  );
};

export const ProjectsBanner = ({ data, showAdmin }: ProjectsBannerProps) => {
  const [collapsedHeader, setCollapsedHeader] = useState(false);

  return (
    <BlackBanner>
      <BannerHeader
        title="Projects"
        adminLink="/tagauks/projects/newproject/"
        collapsed={collapsedHeader}
        setCollapsed={setCollapsedHeader}
        showAdmin={showAdmin}
      />
      <StatHolder collapse={collapsedHeader}>
        {data ? renderBannerData(data) : renderBannerData(null, true)}
      </StatHolder>
    </BlackBanner>
  );
};

const Icon = styled.i.attrs({
  className: "icon icon--sun",
})`
  &&& {
    width: 1.5rem;
    height: 1.5rem;
  }
  margin-top: 0.4rem;
  margin-bottom: 0.5rem;
`;

const StatHolder = styled.div<{ collapse: boolean }>`
  display: ${(props) => (props.collapse ? "none" : "grid")};
  grid-gap: 10px;
  grid-template-columns: repeat(5, 1fr);
  color: #fff !important;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: flex-start;
  min-height: 46px;
`;

const StackedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 0.4rem;
  margin-left: 0.5rem;
  text-align: right;
`;

const StackedText = styled.div`
  line-height: 13px;
  font-size: 13px;
`;
