import React, { useRef } from "react";

import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { InputLabel, TextInput } from "~/components/common/inputs";
import { ErrorText, Text } from "~/components/common/text";
import { Toolbar } from "~/components/common/toolbars";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";
import Dialog from "~/components/generic/Dialog";
import { useAutoFocus } from "~/hooks/useAutoFocus";
import { useKeyDown } from "~/hooks/useKeyDown";

import { DocumentsCreateFolder } from "./useDocumentsCreateFolder";

export interface DocumentsCreateFolderDialogProps
  extends DocumentsCreateFolder {
  track: DocumentsEventTracking;
}

export const DocumentsCreateFolderDialog = React.memo(
  function DocumentsCreateFolderDialog(
    props: DocumentsCreateFolderDialogProps
  ) {
    const { track, isOpen, name, error, validationError, disabled } = props;
    const { change, submit, reset } = props;
    const input = useRef<HTMLInputElement>(null);

    useAutoFocus(isOpen, input);
    useKeyDown("Enter", isOpen && !disabled, submit);

    const close = () => {
      track.newFolderCancel();
      reset();
    };

    return (
      <Dialog
        isOpen={isOpen}
        header="Create New Folder"
        onRequestClose={close}
        actions={
          <Toolbar>
            <SecondaryButton onClick={close}>Cancel</SecondaryButton>
            <PrimaryButton
              disabled={disabled}
              onClick={() => {
                track.newFolderConfirm(name);
                submit();
              }}
            >
              Create
            </PrimaryButton>
          </Toolbar>
        }
      >
        {error && <div className="alert alert-danger">{error}</div>}
        <InputLabel>
          <Text>Folder Name</Text>
          <TextInput ref={input} value={name} onChange={change} />
          {validationError && <ErrorText>{validationError}</ErrorText>}
        </InputLabel>
      </Dialog>
    );
  }
);
