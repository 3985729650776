import PropTypes from "prop-types";
import React from "react";

const ProjectInfoItem = ({ header, children }) => (
  <div>
    <div className="project-header" style={{ textTransform: "uppercase" }}>
      {header}
    </div>
    <div className="project-content">{children || "--"}</div>
  </div>
);

ProjectInfoItem.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.any,
};

ProjectInfoItem.defaultProps = {
  children: null,
};

export default ProjectInfoItem;
