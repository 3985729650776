import * as PropTypes from "prop-types";
import React from "react";
import { formatCommentsTitleDate, formatDateAgo } from "utils/formats";

const CommentBox = ({ commentObj }) => {
  const { user, comment, timestamp } = commentObj;
  const formattedTimestamp = formatCommentsTitleDate(timestamp);
  const date = formatDateAgo(timestamp);
  return (
    <div className="comment-box mt-3 mb-3">
      <div className="comment-header">
        <div
          className="comments__user-pic float-left"
          style={user.avatar && { backgroundImage: `url(${user.avatar})` }}
        />
        <div className="comments__user-name float-left pl-2">
          <span>{user.fullname}</span>
        </div>
        <div className="float-left pl-2 text-muted">
          <span title={formattedTimestamp}>{date}</span>
        </div>
      </div>
      <div className="comment__text">{comment}</div>
    </div>
  );
};

CommentBox.propTypes = {
  commentObj: PropTypes.shape({
    user: PropTypes.shape().isRequired,
    comment: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
  }).isRequired,
};

export default CommentBox;
