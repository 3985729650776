import { ThemeProps } from "../theme";

export const xs = (props: ThemeProps) =>
  `(min-width: ${props.theme.breakpoints.values.xs}px)`;
export const sm = (props: ThemeProps) =>
  `(min-width: ${props.theme.breakpoints.values.sm}px)`;
export const md = (props: ThemeProps) =>
  `(min-width: ${props.theme.breakpoints.values.md}px)`;
export const lg = (props: ThemeProps) =>
  `(min-width: ${props.theme.breakpoints.values.lg}px)`;
export const xl = (props: ThemeProps) =>
  `(min-width: ${props.theme.breakpoints.values.xl}px)`;
