import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { compact, groupBy, keyBy } from "lodash";
import React, { useMemo } from "react";
import { useState } from "react";
import styled from "styled-components";

import { Document, Maybe } from "~/api/types.generated";
import { CaretIcon, FileFolderIcon, FileIcon } from "~/components/common/icons";
import { SkeletonIcon, SkeletonText } from "~/components/common/skeletons";
import { EllipsisText, Title } from "~/components/common/text";
import { sortByName } from "~/components/Documents/utils";
import { black, blue, gray200, gray300, gray700 } from "~/styles/theme/color";
import { borderRadius } from "~/styles/theme/common";

export interface DocumentsTreeProps {
  className?: string;
  documents:
    | readonly Maybe<Pick<Document, "id" | "fileType" | "name" | "parentId">>[]
    | null;
  skeletons: number;
}

export const DocumentsTree = (props: DocumentsTreeProps) => {
  const { className, documents, skeletons } = props;

  const { nodes, fileCount, folderCount } = useMemo(
    () => createTrees(documents ?? []),
    [documents]
  );

  return (
    <>
      <Title>
        {documents ? formatCounts(fileCount, folderCount) : <SkeletonText />}
      </Title>
      <Container className={className}>
        {documents
          ? nodes.map((node) => (
              <DocumentsTreeNode key={node.document.id} node={node} />
            ))
          : [...new Array(skeletons)].map((_, i) => (
              <div key={i}>
                <SkeletonIcon />
                <SkeletonText key={i} />
              </div>
            ))}
      </Container>
    </>
  );
};

export interface DocumentsTreeNodeProps {
  node: DocumentNode;
}

export const DocumentsTreeNode = (props: DocumentsTreeNodeProps) => {
  const { node } = props;
  const { document, children, fileCount, folderCount } = node;
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded((isExpanded) => !isExpanded);
  const counts = " - " + formatCounts(fileCount, folderCount);

  return (
    <>
      <div
        className={document.fileType?.toLowerCase()}
        onClick={children ? toggleExpanded : undefined}
      >
        {document.fileType === "Folder" && (
          <CaretIcon
            className={clsx("expander-icon", isExpanded && "expanded")}
          />
        )}
        {document.fileType === "Folder" ? <FileFolderIcon /> : <FileIcon />}
        <EllipsisText>{document.name}</EllipsisText>
        {document.fileType === "Folder" && (
          <span className="counts">{counts}</span>
        )}
      </div>
      {node.children && (
        <AnimatePresence initial={false}>
          {isExpanded && (
            <motion.ol
              key="children"
              initial={{ height: 0, opacity: 0, x: -20 }}
              animate={{ height: "auto", opacity: 1, x: 0 }}
              exit={{ height: 0, opacity: 0, x: -20 }}
              transition={{ ease: "easeOut" }}
            >
              {node.children.map((child) => (
                <li key={child.document.id}>
                  <DocumentsTreeNode node={child} />
                </li>
              ))}
            </motion.ol>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

const formatCounts = (fileCount: number, folderCount: number) =>
  `${fileCount} ${fileCount === 1 ? "file" : "files"}, ` +
  `${folderCount} ${folderCount === 1 ? "folder" : "folders"}`;

const Container = styled.div`
  background-color: ${gray200};
  border-radius: ${borderRadius};
  margin-top: 0.5rem;

  ol {
    list-style-type: none;
    padding-left: 1.5rem;
    margin-bottom: 0;
    overflow: hidden;
  }

  > div,
  li > div {
    display: flex;
    align-items: center;
    height: 2rem;
    padding: 0 0.5rem;

    &.folder {
      cursor: pointer;
      &:hover {
        background-color: ${gray300};
      }
      > .counts {
        flex: 0 0 auto;
        margin-left: 0.25rem;
        color: ${gray700};
        font-size: 0.875rem;
        font-style: italic;
        white-space: nowrap;
      }
    }

    &.file {
      padding-left: 2rem;
    }

    svg {
      flex: 0 0 auto;
      color: ${blue};
      margin-right: 0.5rem;
    }

    .expander-icon {
      color: ${black};
      transition: transform 150ms ease-out;
      &.expanded {
        transform: rotate(90deg);
      }
    }
  }
`;

export interface DocumentNode {
  readonly document: Pick<Document, "id" | "fileType" | "name" | "parentId">;
  readonly children: readonly DocumentNode[] | null;
  readonly fileCount: number;
  readonly folderCount: number;
}

/**
 * Constructs an array of disjoint sorted DocumentNode trees from a flat array
 * of Documents.
 */
export const createTrees = (
  documents: readonly Maybe<DocumentNode["document"]>[]
) => {
  // Compact and sort the entire list once. This will ensure that all subtrees
  // are sorted. The document hierarchy is processed in reverse order
  const allDocuments = compact(documents ?? [])
    .sort(sortByName)
    .reverse();
  const documentsById = keyBy(allDocuments, "id");
  const documentsByParentId = groupBy(allDocuments, "parentId");
  const rootDocuments = allDocuments
    .filter((d) => !documentsById[d.parentId!])
    .reverse();

  // Using a stack instead of a recursive function. This prevents overflowing
  // the call stack for an arbitrarily deep tree
  let stack = [...rootDocuments];
  let fileCount = 0;
  let folderCount = 0;
  const nodesById: Record<string, DocumentNode> = {};

  while (stack.length > 0) {
    const document = stack[stack.length - 1];

    // We visit each node in the stack twice. The second time, add the node to
    // its parent and pop it off the stack
    const visited = nodesById[document.id];
    if (visited) {
      const parent = document.parentId != null && nodesById[document.parentId];
      if (parent) {
        // Override the readonly type annotations while building the tree
        (parent.fileCount as number) += visited.fileCount;
        (parent.folderCount as number) += visited.folderCount;
        (parent.children as DocumentNode[] | null)?.push(visited);
      } else {
        fileCount += visited.fileCount;
        folderCount += visited.folderCount;
      }
      stack.pop();
      continue;
    }

    // Copy the Document data into a DocumentNode object with empty children
    const isFolder = document.fileType === "Folder";
    const node: DocumentNode = isFolder
      ? { document, children: [], fileCount: 0, folderCount: 1 }
      : { document, children: null, fileCount: 1, folderCount: 0 };
    nodesById[document.id] = node;

    // Add this node's children to the stack
    if (isFolder) stack = stack.concat(documentsByParentId[document.id] ?? []);
  }

  const nodes = rootDocuments.map((document) => nodesById[document.id]);
  return { nodes, fileCount, folderCount };
};
