import { gql } from "@apollo/client";
import classnames from "classnames";
import { ellipsis } from "polished";
import React, { useContext } from "react";
import styled from "styled-components";

import { SecondaryButton } from "~/components/common/buttons";
import { SkeletonText } from "~/components/common/skeletons";
import { EmptyRow, Table } from "~/components/common/tables";
import {
  CompanyAggsDataFragment,
  useTreeCompanyAnalyticsQuery,
} from "~/components/DataroomAnalytics/CompanyTable.generated";
import OrderingContext, {
  CompanyFields,
} from "~/components/DataroomAnalytics/context/OrderingContext";
import { black, gray300 } from "~/styles/theme/color";
import { borderRadius } from "~/styles/theme/common";
import { Direction, Ordering, createOrdering, reverse } from "~/utils/ordering";

const maxColor = "#479DC6";
const color = "#6CC2D5";

export const COMPANY_AGGS_DATA_FRAGMENT = gql`
  fragment CompanyAggsData on CompanyAggs {
    id
    company {
      name
    }
    userCount
    sessionCount
    downloadCount
    viewCount
  }
`;

export const TREE_COMPANY_ANALYTICS_QUERY = gql`
  query TreeCompanyAnalytics(
    $treeId: ID!
    $limit: Int
    $offset: Int
    $ordering: String
  ) {
    companyAggs(
      treeId: $treeId
      limit: $limit
      offset: $offset
      ordering: $ordering
    ) {
      count
      maxUser
      maxSession
      maxDownload
      maxView
      companies {
        ...CompanyAggsData
      }
    }
  }

  ${COMPANY_AGGS_DATA_FRAGMENT}
`;

type Data = CompanyAggsDataFragment;

export interface CompanyTableProps {
  treeId: string;
}

const skeletonData = [1, 2, 3, 4, 5].map(
  (n) =>
    ({
      id: `skeleton-${n}`,
      company: null,
      userCount: null,
      sessionCount: null,
      downloadCount: null,
      viewCount: null,
    } as Data)
);

export interface HeaderCellProps {
  name: CompanyFields;
  key: string;
  ordering: Ordering<CompanyFields> | null;
  toggleSorting: (name: CompanyFields) => void;
  children?: any;
  style?: React.CSSProperties;
  arrowLeft?: boolean;
}

export const HeaderCell = ({
  name,
  key,
  ordering,
  toggleSorting,
  children,
  style = { textAlign: "left" },
  arrowLeft = false,
}: HeaderCellProps) => (
  <th
    key={key}
    scope="col"
    style={style}
    className={classnames(
      ordering && ordering.field.startsWith(name) && "active"
    )}
  >
    <button
      className={classnames(
        "btn",
        ordering && ordering.field && "btn-link",
        ordering && ordering.field && "btn-link-hovered"
      )}
      style={{ padding: 0, textAlign: style.textAlign }}
      type="button"
      onClick={() => toggleSorting(name)}
    >
      {!arrowLeft ? children : null}
      {ordering && ordering.field && (
        <i
          className={classnames(
            "icon",
            "icon--circle-sort-up",
            ordering.field.startsWith(name) && "active",
            ordering.field.startsWith(name) &&
              ordering.direction === Direction.ASC &&
              "asc",
            ordering.field.startsWith(name) &&
              ordering.direction === Direction.DESC &&
              "desc"
          )}
        />
      )}
      {arrowLeft ? children : null}
    </button>
  </th>
);

export const CompanyTable = React.memo(function CompanyTable({
  treeId,
}: CompanyTableProps) {
  const {
    companyOrdering: ordering,
    setCompanyOrdering: setOrdering,
    serializeOrdering,
  } = useContext(OrderingContext);
  const toggleSorting = (field: CompanyFields) => {
    if (ordering.field === field) {
      setOrdering(reverse(ordering));
    } else {
      setOrdering(createOrdering(field));
    }
  };
  const {
    loading,
    data: requestData,
    fetchMore,
    error,
  } = useTreeCompanyAnalyticsQuery({
    variables: {
      treeId: treeId,
      ordering: serializeOrdering(ordering),
    },
  });
  const data = requestData?.companyAggs?.companies;

  const onLoadMore = () => {
    fetchMore({
      variables: {
        offset: data?.length,
      },
    });
  };
  const empty = !loading && data?.length === 0;
  const rows = data ?? skeletonData;
  const showLoadMore = !!(
    requestData?.companyAggs?.count &&
    data?.length &&
    data?.length < requestData.companyAggs.count
  );
  const columns = [
    column(
      "name",
      () => (
        <th key="company-name" className="name-cell">
          <div className="Name">Name</div>
        </th>
      ),
      (row, index) => {
        if (row.id.startsWith("skeleton")) return <SkeletonText />;
        return `${index + 1}. ${row.company?.name ?? "--"}`;
      },
      () => 0
    ),
    column(
      "userCount",
      () => (
        <HeaderCell
          key="userCount"
          name={CompanyFields.USERS}
          ordering={ordering}
          toggleSorting={toggleSorting}
        >
          Users
        </HeaderCell>
      ),
      (row) => {
        if (row.id.startsWith("skeleton")) return <SkeletonText />;
        return `${row.userCount}`;
      },
      (row) => {
        return requestData?.companyAggs?.maxUser
          ? Math.max(
              Math.floor(
                ((row.userCount ?? 0) / requestData.companyAggs.maxUser) * 100
              ),
              1
            )
          : 0;
      }
    ),
    column(
      "sessionCount",
      () => (
        <HeaderCell
          key="sessionCount"
          name={CompanyFields.SESSIONS}
          ordering={ordering}
          toggleSorting={toggleSorting}
        >
          Sessions
        </HeaderCell>
      ),
      (row) => {
        if (row.id.startsWith("skeleton")) return <SkeletonText />;
        return `${row.sessionCount}`;
      },
      (row) =>
        requestData?.companyAggs?.maxSession
          ? Math.max(
              Math.floor(
                ((row.sessionCount ?? 0) / requestData.companyAggs.maxSession) *
                  100
              ),
              1
            )
          : 0
    ),
    column(
      "downloadCount",
      () => (
        <HeaderCell
          key="downloadCount"
          name={CompanyFields.DOWNLOADS}
          ordering={ordering}
          toggleSorting={toggleSorting}
        >
          Downloads
        </HeaderCell>
      ),
      (row) => {
        if (row.id.startsWith("skeleton")) return <SkeletonText />;
        return `${row.downloadCount}`;
      },
      (row) =>
        requestData?.companyAggs?.maxDownload
          ? Math.max(
              Math.floor(
                ((row.downloadCount ?? 0) /
                  requestData.companyAggs.maxDownload) *
                  100
              ),
              1
            )
          : 0
    ),
  ];

  const table = (
    <StyledTable>
      <thead>
        <tr>{columns.map(({ renderHeader }) => renderHeader())}</tr>
      </thead>
      <tbody>
        {!error &&
          rows.map((row, index) => (
            <tr key={row?.id}>
              {columns.map(({ name, cell, fillWidth }) => {
                if (name === "name")
                  return (
                    <td key={name} className={`${name}-cell`}>
                      <span>{cell(row, index)}</span>
                    </td>
                  );
                const width = fillWidth(row);
                return (
                  <td key={name} className={`${name}-cell`}>
                    <div className={`bar-cell`}>
                      <BarText>{cell(row, index)}</BarText>
                      <ProgressBar>
                        <Fill
                          width={width}
                          color={width === 100 ? maxColor : color}
                        />
                      </ProgressBar>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
      </tbody>
    </StyledTable>
  );

  return (
    <BoundedBox>
      <TableTitle>Companies</TableTitle>
      {table}
      {showLoadMore && (
        <ButtonRow>
          <SecondaryButton onClick={onLoadMore}>Load more</SecondaryButton>
        </ButtonRow>
      )}
      {!error && empty && <EmptyRow>No data found.</EmptyRow>}
      {error && (
        <EmptyRow>
          An error occured while loading the data. Please check again later.
        </EmptyRow>
      )}
    </BoundedBox>
  );
});

const column = (
  name: string,
  renderHeader: () => React.ReactNode,
  cell: (row: Data, index: number) => React.ReactNode,
  fillWidth: (row: Data) => number
) => ({ name, renderHeader, cell, fillWidth });

export const BoundedBox = styled.div`
  border-radius: ${borderRadius};
  border: 1px solid ${gray300};
  padding: 16px;
  overflow-y: auto;
  max-height: 400px;
`;

export const TableTitle = styled.div`
  margin-bottom: 12px;
  color: #231f20;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
`;

export const StyledTable = styled(Table)`
  border: 0 !important;
  min-width: 100%;
  font-size: 0.875rem;
  border-collapse: separate;

  th.active {
    border-bottom: 1px solid ${black};
  }

  thead tr {
    max-height: 35px;
    height: 35px;
  }

  && thead tr th {
    background-color: white;
    height: 35px;
  }

  && tbody tr {
    height: 35px;
    max-height: 35px;

    border-color: ${gray300};

    :first-child td {
      border-color: ${gray300};
    }

    td {
      border-color: ${gray300};
    }
  }

  && tbody tr.skeleton {
    background-color: transparent;
    border: none;
  }

  td.name-cell,
  td.company-name-cell,
  td.user-name-cell {
    max-width: 6rem;
    > span {
      ${ellipsis()};
      padding-left: 0.5rem;
    }
  }

  td.document-name-cell {
    max-width: 10rem;
    > span {
      ${ellipsis()};
      padding-left: 0.5rem;
    }
  }

  .bar-cell {
    text-align: center;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const BarText = styled.div`
  display: flex;
  align-content: flex-end;
  text-align: right;
  margin-left: auto;
  padding-right: 5px;
`;

export const ProgressBar = styled.div`
  display: flex;
  height: 1.25rem;
  margin-left: 2px;
  width: 85%;
`;

export const Fill = styled.div<{ width: number; color: string }>`
  margin-left: 2px;
  width: ${(props) => props.width}%;
  background: ${(props) => props.color};
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
`;
