import * as PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { gettext } from "utils/text";

import { CloseButton } from "../generic/CloseButton";

const SearchBar = ({
  keyword,
  onChange,
  renderInputGroupPrepend,
  clearable,
  onClear,
  placeholderText,
}) => {
  const inputElem = useRef(null);

  const onKeyPress = (e) => {
    // noinspection JSUnresolvedFunction
    if (e.key === "Escape" && document.activeElement === inputElem.current) {
      onClear();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyPress);
    return () => {
      window.removeEventListener("keydown", onKeyPress);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="input-group search-input-group">
      <div className="input-group-prepend">{renderInputGroupPrepend()}</div>
      <input
        type="text"
        className="form-control ml-1 pl-0"
        aria-label={gettext("Search")}
        // these spaces are used for move placeholder a bit right (as it's in design) in Firefox
        placeholder={`${gettext(placeholderText)}...`}
        value={keyword}
        onChange={onChange}
        ref={inputElem}
      />
      {clearable && keyword && (
        <div className="input-group-append">
          <CloseButton onClick={onClear} />
        </div>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  keyword: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  renderInputGroupPrepend: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
};

SearchBar.defaultProps = {
  clearable: false,
  placeholderText: "Search",
};

export default SearchBar;
