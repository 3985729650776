import path from "path";

import { LocationDescriptorObject } from "history";
import { useCallback } from "react";

import { DocumentsRouting, View } from "./useDocumentsRouting";

export interface DocumentsLinks {
  /** Gets the base router-relative path to the given document */
  getBasePath(id: string): string;
  /** Gets a router-relative path to the given document & view mode */
  getLink(id: string, newView?: View): LocationDescriptorObject;
  /** Gets a fully-qualified URL to the given document & view mode */
  getFullLink(id: string, newView?: View): string;
}

/** Creates helper functions for generating links to documents */
export const useDocumentsLinks = (
  basePath: string,
  { rootId, view, history }: DocumentsRouting
): DocumentsLinks => {
  const getBasePath = useCallback(
    (id: string) => (id === rootId ? basePath : path.join(basePath, id)),
    [basePath, rootId]
  );

  const getLink = useCallback(
    (id: string, newView: View = view): LocationDescriptorObject => {
      const pathname = getBasePath(id);
      if (newView === "single") return { pathname };
      return { pathname, search: `?view=${newView}` };
    },
    [getBasePath, view]
  );

  const getFullLink = useCallback(
    (id: string, newView: View = view) =>
      window.location.origin + history.createHref(getLink(id, newView)),
    [getLink, history, view]
  );

  return { getBasePath, getLink, getFullLink };
};
