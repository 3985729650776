import { gql } from "@apollo/client";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import ReactModal from "react-modal";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import GraphQLProvider from "~/api/context";
import { useViewerQuery } from "~/App.generated";
import { AppContent } from "~/components/common/layout";
import { DocumentsUploadProvider } from "~/components/Documents/DocumentsUpload/DocumentsUploadProvider";
import { Agreements } from "~/components/generic/Agreements";
import { HOME_PAGE_VIEWER_FRAGMENT } from "~/components/HomePage/HomePage";
import {
  OnboardingDialog,
  useOnboardingDialog,
} from "~/components/OnboardingDialog";
import { SETTINGS_PAGE_VIEWER_FRAGMENT } from "~/components/Settings/SettingsPage";
import { FOOTER_VIEWER_FRAGMENT, Footer } from "~/Footer";
import { NAVBAR_VIEWER_FRAGMENT, Navbar } from "~/Navbar";
import { RestrictedNavBar } from "~/RestrictedNavBar";
import { Routes } from "~/Routes";
import theme from "~/styles/theme";

/**
 * The primary entry point into the React application.
 * Wraps the main content with routing, theming, and GraphQL context providers.
 */
export const App = () => {
  ReactModal.setAppElement("#app");
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <GraphQLProvider>
            <DocumentsUploadProvider>
              <Main />
            </DocumentsUploadProvider>
          </GraphQLProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

/**
 * The main application content.
 * NOTE: This can't be inlined directly in the App component because it contains a GraphQL query,
 *       which requires a GraphQLProvider in a higher-level component.
 */
const Main = () => {
  const onboardingDialog = useOnboardingDialog();
  const { data } = useViewerQuery();
  if (DJ_CONST.user.show_eula_agreement) {
    return (
      <>
        <GlobalStyle />
        <>
          <RestrictedNavBar />
          <AppContent children={<Agreements />} />
        </>
      </>
    );
  }
  return (
    <>
      <GlobalStyle />
      <OnboardingDialog
        isOpen={onboardingDialog.isOpen}
        onClose={onboardingDialog.close}
      />
      {data?.viewer && (
        <>
          <Navbar viewer={data.viewer} onClickWelcome={onboardingDialog.open} />
          <AppContent children={<Routes viewer={data.viewer} />} />
          <Footer viewer={data.viewer} />
        </>
      )}
    </>
  );
};

export const VIEWER_QUERY = gql`
  query Viewer {
    viewer {
      id
      ...NavbarViewer
      ...FooterViewer
      ...HomePageViewer
      ...SettingsPageViewer
    }
  }

  ${NAVBAR_VIEWER_FRAGMENT}
  ${FOOTER_VIEWER_FRAGMENT}
  ${HOME_PAGE_VIEWER_FRAGMENT}
  ${SETTINGS_PAGE_VIEWER_FRAGMENT}
`;

const GlobalStyle = createGlobalStyle`
  html {
    /*
    Prevent the page from resizing due to the main scrollbar appearing/disappearing.
     - "overflow-y: scroll" would cause a disabled scrollbar to appear on pages that aren't scrollable
     - "overflow-y: overlay" causes fixed/absolute positioned elements to be partially hidden (Django Debug Toolbar)
     - The best fix is still experimental: https://caniuse.com/mdn-css_properties_scrollbar-gutter
    */
    width: 100vw;
    overflow-x: hidden;
  }
`;
