import { groupBy, keyBy, mapValues } from "lodash";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Checklist, ChecklistItemStatus } from "~/api/types.generated";
import {
  ActiveChecklistFragment,
  ChecklistCategoryFragment,
  ChecklistItemFragment,
  ChecklistProjectFragment,
  useActiveChecklistsQuery,
  useChecklistDataLazyQuery,
} from "~/components/Checklist/ChecklistPage.generated";
import { ChecklistFiltersContextValue } from "~/components/Checklist/context/ChecklistFiltersContext";

export type ItemByCategoryAndProjectMap = Record<
  string,
  Record<string, ChecklistItemFragment>
>;
export type FilterMap = Record<
  string,
  {
    projectIds: Record<string, any>;
    categoryIds: Record<string, any>;
  }
>;
export type CategorySelectionMap = Record<string, boolean>;
export type ChecklistObj = Pick<Checklist, "__typename" | "id" | "name">;
// Support for legacy API, we add a paragraph tuple for ordering purposes:
export type Direction = "N" | "S" | "E" | "W";
export const statusIsDone = (status: ChecklistItemStatus) =>
  status === ChecklistItemStatus.Done;

export const statusIsInProgress = (status: ChecklistItemStatus) =>
  [
    ChecklistItemStatus.SubmittedForReview,
    ChecklistItemStatus.ThirdPartyReview,
    ChecklistItemStatus.UnderReview,
    ChecklistItemStatus.StateRequested,
  ].includes(status);

export const statusIsAttentionNeeded = (status: ChecklistItemStatus) =>
  [
    ChecklistItemStatus.FollowUpNeeded,
    ChecklistItemStatus.SignatureRequired,
  ].includes(status);

export interface ChecklistContextValue {
  portfolioId: string;
  loading: boolean;
  error: string | null;
  checklist?: ChecklistObj | null;
  selectedChecklistId: string | null;
  selectedChecklistItemId: string | null;
  activeChecklists: readonly ActiveChecklistFragment[];
  availableCategories: readonly ChecklistCategoryFragment[];
  availableCategoriesById: Record<string, ChecklistCategoryFragment>;
  visibleCategories: readonly ChecklistCategoryFragment[];
  filteredItems: readonly ChecklistItemFragment[];
  visibleProjects: readonly ChecklistProjectFragment[];
  doneItems: readonly ChecklistItemFragment[];
  inProgressItems: readonly ChecklistItemFragment[];
  attentionNeededItems: readonly ChecklistItemFragment[];
  assignedItems: readonly ChecklistItemFragment[];
  itemsByCategoryAndProjectId: ItemByCategoryAndProjectMap;
  setSelectedChecklistId: Dispatch<SetStateAction<string | null>>;
  setSelectedChecklistItemId: Dispatch<SetStateAction<string | null>>;
  toggleSelectedChecklistItemId: (itemId: string) => void;
  collapsedCategories: CategorySelectionMap;
  toggleCollapsedCategory: (categoryId: string) => void;
  getItem: (categoryId: string, projectId: string) => ChecklistItemFragment;
  getSelectedItem: () => ChecklistItemFragment | null;
  isOpen: (categoryId: string, projectId: string) => boolean;
  moveSelectedChecklistItem: (direction: Direction, callback: () => {}) => void;
}

export const useChecklistContext = (
  portfolioId: string,
  checklistFilters: ChecklistFiltersContextValue
): ChecklistContextValue => {
  const [selectedChecklistId, setSelectedChecklistId] = useState<string | null>(
    null
  );
  const [selectedChecklistItemId, setSelectedChecklistItemId] = useState<
    string | null
  >(null);
  const [collapsedCategories, setCollapsedCategories] =
    useState<CategorySelectionMap>({});
  const [error, setError] = useState<string | null>(null);
  const { data, loading: activeChecklistsLoading } = useActiveChecklistsQuery({
    variables: { portfolioId: portfolioId },
    onCompleted: (data) => {
      if (data?.activeChecklists) {
        setSelectedChecklistId(data.activeChecklists[0].id);
      }
    },
    onError: (error) => setError(error.message),
  });
  const [fetchChecklist, { data: checklistData, loading: checklistLoading }] =
    useChecklistDataLazyQuery();
  useEffect(() => {
    if (selectedChecklistId)
      fetchChecklist({ variables: { checklistId: selectedChecklistId } });
  }, [selectedChecklistId, fetchChecklist]);
  const activeChecklists = useMemo(() => data?.activeChecklists ?? [], [data]);
  const checklist = useMemo(() => checklistData?.checklist, [checklistData]);
  const items = useMemo(
    () => checklistData?.checklistItems ?? [],
    [checklistData?.checklistItems]
  );
  const itemsById = useMemo(() => keyBy(items, "id"), [items]);

  const availableCategories = useMemo(
    () => checklistData?.checklist?.categories ?? [],
    [checklistData?.checklist]
  );

  const availableCategoriesById: Record<string, ChecklistCategoryFragment> =
    useMemo(() => keyBy(availableCategories, "id"), [availableCategories]);

  const childCategoriesById: Record<string, ChecklistCategoryFragment> =
    useMemo(
      () =>
        keyBy(
          availableCategories.reduce(
            (
              acc: ChecklistCategoryFragment[],
              category: ChecklistCategoryFragment
            ) => [...acc, ...(category.children ?? [])],
            []
          ),
          "id"
        ),
      [availableCategories]
    );

  const loading = useMemo(
    () => activeChecklistsLoading || checklistLoading,
    [activeChecklistsLoading, checklistLoading]
  );

  // TODO: Lets add a graphql for the counts. The following is legacy logic that aggregates
  // items belonging to child categories (filters out parents)
  const filteredChecklist = useMemo(
    () =>
      items.filter(
        (c: ChecklistItemFragment) =>
          c.categoryId && childCategoriesById[c.categoryId]
      ),
    [items, childCategoriesById]
  );

  const doneItems = useMemo(
    () =>
      filteredChecklist.filter(
        (c: ChecklistItemFragment) => c.status && statusIsDone(c.status)
      ),
    [filteredChecklist]
  );

  const inProgressItems = useMemo(
    () =>
      filteredChecklist.filter(
        (c: ChecklistItemFragment) => c.status && statusIsInProgress(c.status)
      ),
    [filteredChecklist]
  );

  const attentionNeededItems = useMemo(
    () =>
      filteredChecklist.filter(
        (c: ChecklistItemFragment) =>
          c.status && statusIsAttentionNeeded(c.status)
      ),
    [filteredChecklist]
  );

  const assignedItems = useMemo(
    () => filteredChecklist.filter((c: ChecklistItemFragment) => c.userId),
    [filteredChecklist]
  );

  const itemsByCategoryAndProjectId = useMemo(() => {
    const byCategoryId = groupBy(items, "categoryId");
    return mapValues(byCategoryId, (values) => keyBy(values, "projectId"));
  }, [items]);

  const projectAndCategoriesByStatus = useMemo(() => {
    const byStatus = groupBy(items, "status");
    return mapValues(byStatus, (values) => ({
      projectIds: values.reduce((acc: Record<string, any>, value) => {
        if (value.projectId) acc[value.projectId] = true;
        return acc;
      }, {}),
      categoryIds: values.reduce((acc: Record<string, any>, value) => {
        if (value.categoryId) acc[value.categoryId] = true;
        return acc;
      }, {}),
    }));
  }, [items]);

  const projectAndCategoriesByUserId: FilterMap = useMemo(() => {
    const byUserId = groupBy(assignedItems, "userId");
    return mapValues(byUserId, (values) => ({
      projectIds: values.reduce((acc: Record<string, any>, value) => {
        if (value.projectId) acc[value.projectId] = true;
        return acc;
      }, {}),
      categoryIds: values.reduce((acc: Record<string, any>, value) => {
        if (value.categoryId) acc[value.categoryId] = true;
        return acc;
      }, {}),
    }));
  }, [assignedItems]);

  const permittedCategories: Record<string, any> | null = useMemo(() => {
    if (
      checklistFilters.selectedStatuses.length === 0 &&
      checklistFilters.selectedUserIds.length === 0
    ) {
      return null;
    }
    const permittedByUser = checklistFilters.selectedUserIds.reduce(
      (acc, userId) => {
        if (projectAndCategoriesByUserId[userId] !== undefined) {
          return {
            ...projectAndCategoriesByUserId[userId]["categoryIds"],
            ...acc,
          };
        }
        return acc;
      },
      {}
    );
    const permittedByStatus: Record<string, any> =
      checklistFilters.selectedStatuses.reduce((acc, status) => {
        if (projectAndCategoriesByStatus[status] !== undefined) {
          return {
            ...projectAndCategoriesByStatus[status]["categoryIds"],
            ...acc,
          };
        }
        return acc;
      }, {});
    // with both filters on, we need to return an intersection of them:
    if (
      checklistFilters.selectedStatuses.length &&
      checklistFilters.selectedUserIds.length
    ) {
      return Object.keys(permittedByUser)
        .filter((key) => permittedByStatus[key])
        .reduce((acc, value) => ({ [value]: true, ...acc }), {});
    }
    if (checklistFilters.selectedUserIds.length) return permittedByUser;
    return permittedByStatus;
  }, [
    projectAndCategoriesByUserId,
    projectAndCategoriesByStatus,
    checklistFilters.selectedStatuses,
    checklistFilters.selectedUserIds,
  ]);

  const visibleCategories = useMemo(() => {
    return availableCategories.reduce((categories: any, category) => {
      const collapsed = collapsedCategories[category.id];
      if (!collapsed) {
        const children = category.children ?? [];
        if (permittedCategories !== null)
          return [
            ...categories,
            category,
            ...children.filter((child) => permittedCategories[child.id]),
          ];
        return [...categories, category, ...children];
      }
      return [...categories, category];
    }, []);
  }, [availableCategories, collapsedCategories, permittedCategories]);

  const permittedProjects: Record<string, any> | null = useMemo(() => {
    if (
      checklistFilters.selectedStatuses.length === 0 &&
      checklistFilters.selectedUserIds.length === 0
    ) {
      return null;
    }
    const permittedByUser = checklistFilters.selectedUserIds.reduce(
      (acc, userId) => {
        if (projectAndCategoriesByUserId[userId] !== undefined) {
          return {
            ...projectAndCategoriesByUserId[userId]["projectIds"],
            ...acc,
          };
        }
        return acc;
      },
      {}
    );
    const permittedByStatus: Record<string, any> =
      checklistFilters.selectedStatuses.reduce((acc, status) => {
        if (projectAndCategoriesByStatus[status] !== undefined) {
          return {
            ...projectAndCategoriesByStatus[status]["projectIds"],
            ...acc,
          };
        }
        return acc;
      }, {});
    // with both filters on, we need to return an intersection of them:
    if (
      checklistFilters.selectedStatuses.length &&
      checklistFilters.selectedUserIds.length
    ) {
      return Object.keys(permittedByUser)
        .filter((key) => permittedByStatus[key])
        .reduce((acc, value) => ({ [value]: true, ...acc }), {});
    }
    if (checklistFilters.selectedUserIds.length) return permittedByUser;
    return permittedByStatus;
  }, [
    projectAndCategoriesByUserId,
    projectAndCategoriesByStatus,
    checklistFilters.selectedStatuses,
    checklistFilters.selectedUserIds,
  ]);

  const visibleProjects = useMemo(() => {
    if (checklistFilters.selectedProjectIds.length > 0)
      return checklistFilters.availableProjects.filter(
        ({ id }) =>
          checklistFilters.selectedProjectIds.includes(id) &&
          (permittedProjects === null || permittedProjects[id])
      );
    return checklistFilters.availableProjects.filter(
      ({ id }) => permittedProjects === null || permittedProjects[id]
    );
  }, [
    checklistFilters.availableProjects,
    checklistFilters.selectedProjectIds,
    permittedProjects,
  ]);

  const getItem = useCallback(
    (categoryId: string, projectId: string) =>
      itemsByCategoryAndProjectId[categoryId][projectId],
    [itemsByCategoryAndProjectId]
  );

  const getSelectedItem = useCallback(() => {
    if (!selectedChecklistItemId) return null;
    return itemsById[selectedChecklistItemId];
  }, [itemsById, selectedChecklistItemId]);

  const isOpen = useCallback(
    (categoryId: string, projectId: string) => {
      return (
        itemsByCategoryAndProjectId[categoryId][projectId].id ===
        selectedChecklistItemId
      );
    },
    [itemsByCategoryAndProjectId, selectedChecklistItemId]
  );

  const toggleCollapsedCategoryById = useCallback(
    (categoryId: string) => {
      collapsedCategories[categoryId] = !collapsedCategories[categoryId];
      setCollapsedCategories({ ...collapsedCategories });
    },
    [collapsedCategories, setCollapsedCategories]
  );

  const toggleSelectedChecklistItemId = useCallback(
    (itemId: string) => {
      itemId === selectedChecklistItemId
        ? setSelectedChecklistItemId(null)
        : setSelectedChecklistItemId(itemId);
    },
    [selectedChecklistItemId, setSelectedChecklistItemId]
  );

  const moveSelectedChecklistItem = useCallback(
    (direction: Direction, callback: () => {}) => {
      if (!selectedChecklistItemId) return;
      const item = itemsById[selectedChecklistItemId];

      const categoryIndex = visibleCategories.findIndex(
        (c: ChecklistCategoryFragment) => c.id === item.categoryId
      );
      const projectIndex = visibleProjects.findIndex(
        (p) => p.id === item.projectId
      );

      let categoryId = item.categoryId;
      let projectId = item.projectId;
      try {
        switch (direction) {
          case "N":
            categoryId = visibleCategories[categoryIndex - 1].id;
            break;
          case "E":
            projectId = visibleProjects[projectIndex + 1].id;
            break;
          case "S":
            categoryId = visibleCategories[categoryIndex + 1].id;
            break;
          case "W":
            projectId = visibleProjects[projectIndex - 1].id;
            break;
          default:
            break;
        }
      } catch (IndexOutOfRangeException) {
        // out of bounds, keep the current active category and project
      }
      if (!categoryId || !projectId) return;
      const newItem = itemsByCategoryAndProjectId[categoryId][projectId];
      setSelectedChecklistItemId(newItem.id);
      callback();
    },
    [
      itemsById,
      itemsByCategoryAndProjectId,
      selectedChecklistItemId,
      setSelectedChecklistItemId,
      visibleCategories,
      visibleProjects,
    ]
  );

  return useMemo(
    () => ({
      portfolioId,
      loading,
      error,
      checklist,
      selectedChecklistId,
      selectedChecklistItemId,
      activeChecklists,
      availableCategories,
      availableCategoriesById,
      visibleCategories,
      visibleProjects,
      filteredItems: filteredChecklist,
      doneItems,
      inProgressItems,
      attentionNeededItems,
      assignedItems,
      setSelectedChecklistId,
      setSelectedChecklistItemId,
      collapsedCategories,
      toggleCollapsedCategory: toggleCollapsedCategoryById,
      toggleSelectedChecklistItemId,
      getItem,
      getSelectedItem,
      isOpen,
      itemsByCategoryAndProjectId,
      moveSelectedChecklistItem,
    }),
    [
      portfolioId,
      loading,
      error,
      checklist,
      selectedChecklistId,
      selectedChecklistItemId,
      activeChecklists,
      availableCategories,
      availableCategoriesById,
      visibleCategories,
      doneItems,
      visibleProjects,
      filteredChecklist,
      inProgressItems,
      attentionNeededItems,
      assignedItems,
      setSelectedChecklistId,
      setSelectedChecklistItemId,
      collapsedCategories,
      toggleCollapsedCategoryById,
      toggleSelectedChecklistItemId,
      getItem,
      getSelectedItem,
      isOpen,
      itemsByCategoryAndProjectId,
      moveSelectedChecklistItem,
    ]
  );
};

const ChecklistContext = createContext<ChecklistContextValue>(null as any);

export default ChecklistContext;
