import React, { useMemo } from "react";
import { Node } from "slate";

import { CellEditor } from "~/components/DataGrid";
import useRemoteState from "~/hooks/useRemoteState";

import { useUpdateQuestionMutation } from "../api/mutations.generated";
import { useSaving } from "../context/QAndAContext";
import { useQuestion } from "../context/QuestionContext";

interface QuestionBodyProps {
  canEditQuestions: boolean;
}

const QuestionBody = ({ canEditQuestions }: QuestionBodyProps) => {
  const question = useQuestion();
  const [updateQuestion, { loading }] = useUpdateQuestionMutation();

  useSaving(loading);

  const readOnly = question.isClosed || !canEditQuestions;

  // TODO: Implement a `merge` function for realtime collaboration
  const [value, handleChange] = useRemoteState<Node[]>(
    useMemo(() => JSON.parse(question.body), [question.body]),
    (value) => {
      updateQuestion({
        variables: { questionId: question.id, body: JSON.stringify(value) },
      });
    }
  );

  return (
    <CellEditor readOnly={readOnly} value={value} onChange={handleChange} />
  );
};

export default QuestionBody;
