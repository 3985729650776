import gql from "graphql-tag";
import { compact, fromPairs, sumBy } from "lodash";
import React, { useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { formatSize } from "utils/calculations";

import BannerHeader from "~/components/Banners/BannerHeader";
import { OAndMBannerFragment } from "~/components/Documents/OAndMBanner.generated";
import StatSection from "~/components/HomePage/StatSection";
import Stat from "~/components/typography/Stat";
import StatLabel from "~/components/typography/StatLabel";
import { bgColor } from "~/styles/mixins";

export const O_AND_M_BANNER_FRAGMENT = gql`
  fragment OAndMBanner on Query {
    partners: oAndMPartners(orgSlug: $orgSlug) {
      id
      name
      projectCount
      totalCapacity
    }
  }
`;

const colorPalette = [
  "#FAD250",
  "#5CAC73",
  "#508AD6",
  "#A06EAA",
  "#D66050",
  "#E8974C",
];

export interface OAndMBannerProps {
  partners: OAndMBannerFragment["partners"];
  showStats: boolean;
  showAdmin: boolean;
}

export const OAndMBanner = ({
  partners,
  showStats,
  showAdmin,
}: OAndMBannerProps) => {
  const allPartners = compact(partners);

  const { totalCapacity, projectCount, colorById, percentById } =
    useMemo(() => {
      const totalCapacity = sumBy(allPartners, "totalCapacity");
      const projectCount = sumBy(allPartners, "projectCount");
      const colorById = fromPairs(
        allPartners.map((partner, i) => [
          partner.id,
          colorPalette[i % colorPalette.length],
        ])
      );
      const percentById = fromPairs(
        allPartners.map((partner) => [
          partner.id,
          totalCapacity
            ? Math.floor(((partner.totalCapacity ?? 0) / totalCapacity) * 100)
            : 0,
        ])
      );
      return { totalCapacity, projectCount, colorById, percentById };
    }, [allPartners]);

  const [collapsedHeader, setCollapsedHeader] = useState(false);
  const [highlightedId, setHighlightedId] = useState<string | null>(null);
  const highlightedPartner = allPartners.find((p) => p?.id === highlightedId);

  return (
    <ContainerDiv>
      <BannerHeader
        adminLink={`/tagauks`}
        title="O&M"
        collapsed={collapsedHeader}
        setCollapsed={setCollapsedHeader}
        collapsable={showStats}
        showAdmin={showAdmin}
      />
      {showStats && (
        <StatSection collapse={collapsedHeader}>
          <Block width={10}>
            <StatLabel>Projects</StatLabel>
            <Stat>{projectCount}</Stat>
          </Block>
          <Block width={10}>
            <StatLabel>Partners</StatLabel>
            <Stat>{allPartners.length ?? 0}</Stat>
          </Block>
          <Block width={70} marginRight={200}>
            {highlightedPartner ? (
              <StatLabel color={colorById[highlightedPartner.id]}>
                {highlightedPartner.name}: {highlightedPartner.projectCount}{" "}
                Projects ({formatSize(highlightedPartner.totalCapacity)})
              </StatLabel>
            ) : (
              <StatLabel>{"kW by Partner"}</StatLabel>
            )}
            <ProgressStat>
              <ProgressBar>
                {allPartners.map((partner) => (
                  <Fill
                    key={partner.id}
                    width={percentById[partner.id]}
                    highlight={partner.id === highlightedId}
                    color={colorById[partner.id]}
                    onMouseEnter={() => setHighlightedId(partner.id)}
                    onMouseLeave={() => setHighlightedId(null)}
                  />
                ))}
              </ProgressBar>
              <ProgressBarLabel>{formatSize(totalCapacity)}</ProgressBarLabel>
            </ProgressStat>
          </Block>
        </StatSection>
      )}
    </ContainerDiv>
  );
};

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 25px 5px;
  ${bgColor.gray800()};
  border-radius: 4px;
  flex-grow: 1;
`;

const Block = styled.div<{ width: number; marginRight?: number }>`
  width: ${(props) => props.width}% !important;
  ${(props) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight}px;
    `};
  flex-grow: 1;
`;

const ProgressStat = styled(Stat)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const ProgressBar = styled.div`
  display: flex;
  height: 0.9em;
  // to match the label size (1.2 line height), use a slight margin on top:
  margin-top: 5px;
  // negate the first segments margin:
  margin-left: -2px;
  width: 50%;
`;

const ProgressBarLabel = styled.div`
  padding-left: 10px;
  padding-bottom: 5px;
`;

const Fill = styled.div<{ width: number; color: string; highlight: boolean }>`
  margin-left: 2px;
  width: ${(props) => props.width}%;
  background: ${(props) => props.color};
  opacity: ${(props) => (props.highlight ? 1 : 0.75)};
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
`;
