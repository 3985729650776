import { ellipsis } from "polished";
import React, { useContext } from "react";
import styled, { css } from "styled-components";

import { QuestionLog } from "~/api/types.generated";
import Dropdown from "~/components/menus/Dropdown";
import BaseDropdownButton from "~/components/menus/DropdownButton";
import DropdownHeader from "~/components/menus/DropdownHeader";
import DropdownTriggerContext from "~/components/menus/DropdownTriggerContext";
import SelectMenu from "~/components/menus/SelectMenu";
import SelectMenuOption from "~/components/menus/SelectMenuOption";
import {
  MenuScroller,
  OptionName,
} from "~/components/QAndA/components/CategoryMenu";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";
import {
  bgColor,
  border,
  borderRadius,
  fgColor,
  focusOutline,
} from "~/styles/mixins";

interface QuestionLogDropdownProps {
  subject: QAndASubjectFragment;
  selectedLog: QuestionLog | null;
  setSelectedLog: (log: QuestionLog) => void;
}

export const QuestionLogDropdown = ({
  subject,
  selectedLog,
  setSelectedLog,
  ...rest
}: QuestionLogDropdownProps) => {
  const dropdownTrigger = useContext(DropdownTriggerContext);

  const onSelect = (obj: QuestionLog) => {
    setSelectedLog(obj);
    dropdownTrigger?.measure();
  };

  return (
    <DropdownButton
      hasSelection={selectedLog !== null}
      dropdown={
        <Dropdown {...rest}>
          <DropdownHeader title="Q&A log" />
          <MenuScroller>
            <SelectMenu
              closeOnSelect
              isSelected={(obj) => obj.id === selectedLog?.id}
              onSelect={onSelect}
              onDeselect={() => {}}
              aria-multiselectable="true"
            >
              {subject.qAndALogs?.map((obj) => (
                <SelectMenuOption key={obj.id} value={obj} label={obj.name}>
                  <OptionName>{obj.name}</OptionName>
                </SelectMenuOption>
              ))}
            </SelectMenu>
          </MenuScroller>
        </Dropdown>
      }
    >
      Q&A: {selectedLog?.name} {String.fromCharCode(9662)}
    </DropdownButton>
  );
};

const DropdownButton = styled(BaseDropdownButton).withConfig<{
  hasSelection: boolean;
}>({
  shouldForwardProp: (prop) => prop !== "hasSelection",
})`
  margin-left: auto;
  margin-right: 0.25rem;
  display: inline-flex;
  align-items: center;
  ${borderRadius()}
  ${focusOutline()};
  ${bgColor.transparent()};
  ${border.transparent()};
  ${ellipsis()};
  height: 2rem;

  &:hover {
    ${bgColor.gray300()};
  }

  &[aria-expanded="true"] {
    ${bgColor.primary()};
    ${fgColor.white()};
  }

  ${(props) =>
    props.hasSelection &&
    css`
      ${bgColor.white()};
      ${fgColor.primary()};
      ${border.primary()};
    `}
`;

export default QuestionLogDropdown;
