import React, { useEffect, useState } from "react";

import { MenuButton, SecondaryButton } from "~/components/common/buttons";
import {
  DownloadIcon,
  FileExcelIcon,
  FileFolderIcon,
  LockIcon,
  MoreIcon,
  MoveIcon,
  TrashIcon,
} from "~/components/common/icons";
import {
  MenuTooltipContainer,
  useMenuTooltip,
} from "~/components/common/tooltips";
import { DocumentsCurrent } from "~/components/Documents/useDocumentsCurrent";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";

import DocumentsExportIndexModal from "./DocumentsExportIndexModal";

export interface DocumentsTreeMenuProps {
  track: DocumentsEventTracking;
  canEdit: boolean;
  canDelete: boolean;
  canEditPermissions(): boolean;
  canViewAccessLog: boolean;
  accessLogUrl: string | null;
  fileIndexUrl: string | null;
  moveDisabled: boolean;
  removeDisabled: boolean;
  zipDownloadDisabled: boolean;
  permissionsDisabled: boolean;
  setUpdateSelectedVisible: (visible: boolean) => void;
  createFolder: () => void;
  move: () => void;
  remove: () => void;
  zipDownload: () => void;
  current: DocumentsCurrent;
}

export const DocumentsTreeMenu = React.memo(function DocumentsTreeMenu(
  props: DocumentsTreeMenuProps
) {
  const {
    track,
    canEdit,
    canDelete,
    canViewAccessLog,
    canEditPermissions,
    accessLogUrl,
    fileIndexUrl,
    moveDisabled,
    removeDisabled,
    zipDownloadDisabled,
    permissionsDisabled,
    setUpdateSelectedVisible,
    createFolder,
    move,
    remove,
    zipDownload,
    current,
  } = props;
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    useMenuTooltip();

  useEffect(() => {
    if (visible) track.treeMenuOpen();
  }, [visible, track]);
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const handleExportIndexClick = () => {
    setExportModalOpen(true);
  };

  const handleExportModalClose = () => {
    setExportModalOpen(false);
  };

  const handleExportModalSubmit = (selectedColumns: string[]) => {
    var queryParams = selectedColumns
      .map((col) => `columns=${encodeURIComponent(col)}`)
      .join("&");
    const folder = current.folder;
    if (folder && !folder.id.startsWith("tree:")) {
      queryParams += `&folderId=${folder.id}`;
    }
    const exportUrl = `${fileIndexUrl}?${queryParams}`;
    window.open(exportUrl, "_blank");
    setExportModalOpen(false);
  };

  return (
    <>
      <SecondaryButton ref={setTriggerRef} children={<MoreIcon />} />
      {visible && (
        <MenuTooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
          {canEdit && (
            <MenuButton
              onClick={() => {
                track.newFolderClick();
                createFolder();
              }}
            >
              <FileFolderIcon />
              <span>New folder</span>
            </MenuButton>
          )}

          <MenuButton
            onClick={() => {
              track.zipDownloadClick();
              zipDownload();
            }}
            disabled={zipDownloadDisabled}
          >
            <DownloadIcon />
            <span>Export as Zip</span>
          </MenuButton>

          {canEdit && (
            <MenuButton
              onClick={() => {
                track.moveClick();
                move();
              }}
              disabled={moveDisabled}
            >
              <MoveIcon />
              <span>Move</span>
            </MenuButton>
          )}

          {canEditPermissions() && (
            <MenuButton
              onClick={() => {
                setUpdateSelectedVisible(true);
              }}
              disabled={permissionsDisabled}
            >
              <LockIcon />
              <span>Permissions</span>
            </MenuButton>
          )}

          {fileIndexUrl && (
            <MenuButton onClick={handleExportIndexClick}>
              <FileExcelIcon />
              <span>Export index</span>
            </MenuButton>
          )}

          {canViewAccessLog && accessLogUrl && (
            <MenuButton
              as="a"
              target="_blank"
              href={accessLogUrl}
              onClick={() => {
                track.exportAccessLogClick();
              }}
            >
              <FileExcelIcon />
              <span>Export access log</span>
            </MenuButton>
          )}

          {canDelete && (
            <MenuButton
              danger
              onClick={() => {
                track.deleteClick();
                remove();
              }}
              disabled={removeDisabled}
            >
              <TrashIcon />
              <span>Delete (admin)</span>
            </MenuButton>
          )}
        </MenuTooltipContainer>
      )}

      <DocumentsExportIndexModal
        isOpen={isExportModalOpen}
        onRequestClose={handleExportModalClose}
        onSubmit={handleExportModalSubmit}
        track={track}
      />
    </>
  );
});
