import { useMemo } from "react";

import { DocumentType } from "~/api/types.generated";
import { Document_InfoFragment } from "~/components/Documents/DocumentsAPI.generated";
import { DocumentsCurrent } from "~/components/Documents/useDocumentsCurrent";
import { DocumentsRouting } from "~/components/Documents/useDocumentsRouting";
import { useEventTracking } from "~/event-tracking/EventTrackingProvider";

export type DocumentsEventTracking = ReturnType<
  typeof useDocumentsEventTracking
>;

const TreeType = {
  [DocumentType.Documents]: "Documents",
  [DocumentType.Reports]: "Reports",
  [DocumentType.OAndM]: "O&M",
};

const getTargetProps = (target?: Document_InfoFragment | null) => ({
  "Target Document ID": target?.id,
  "Target Document Name": target?.name,
  "Target Document Type": target?.fileType,
});

export const useDocumentsEventTracking = (
  { view }: DocumentsRouting,
  { tree, document, ancestors }: DocumentsCurrent
) => {
  const featureName = TreeType[tree?.type ?? DocumentType.Documents];

  const treeId = tree?.id;
  const documentId = document?.id;
  const documentName = document?.name;
  const documentPath = [...(ancestors ?? []), document]
    .map((d) => d?.name)
    .join("/");

  const extraContext = useMemo(
    () => ({
      "Tree ID": treeId,
      "Document ID": documentId,
      "Document Name": documentName,
      "Document Path": documentPath,
      "View Mode": view,
    }),
    [treeId, documentId, documentName, documentPath, view]
  );

  return useEventTracking(
    featureName,
    {
      folderView: "Viewed Folder",
      filePreview: "Previewed File",
      fileDownload: [
        "Downloaded File",
        (id: string, name: string) => ({ "File ID": id, "File Name": name }),
      ],
      previewClick: "Clicked Preview Button",
      folderClick: "Clicked Folder Row",
      listViewClick: "Clicked List View",
      columnsViewClick: "Clicked Columns View",
      historyViewClick: "Clicked History View",
      historyPageChange: [
        "Changed History Page",
        (page: number) => ({ "Page Number": page }),
      ],
      treeMenuOpen: "Opened Tree Dropdown Menu",
      newFolderClick: "Clicked New Folder",
      newFolderConfirm: [
        "Created New Folder",
        (name: string) => ({ "New Folder Name": name }),
      ],
      newFolderCancel: "Canceled New Folder",
      exportIndexClick: "Clicked Export Index",
      exportAccessLogClick: "Clicked Export Access Log",
      documentMenuOpen: ["Opened Document Dropdown Menu", getTargetProps],
      copyLinkClick: ["Clicked Copy Link", getTargetProps],
      newTabClick: ["Clicked Open in New Tab", getTargetProps],
      zipDownloadClick: ["Clicked Download as ZIP", getTargetProps],
      zipDownloadConfirm: "Created Zip Download",
      zipDownloadCancel: "Canceled Zip Download",
      permissionsClick: ["Clicked Permissions", getTargetProps],
      permissionsChange: ["Changed Permissions", getTargetProps],
      moveClick: ["Clicked Move", getTargetProps],
      moveConfirm: [
        "Moved Document(s)",
        (ids: readonly string[]) => ({ "Moved Document IDs": ids }),
      ],
      moveCancel: "Canceled Move",
      renameClick: ["Clicked Rename", getTargetProps],
      renameConfirm: ["Renamed Document", getTargetProps],
      renameCancel: ["Canceled Rename", getTargetProps],
      deleteClick: ["Clicked Delete", getTargetProps],
      deleteConfirm: [
        "Deleted Document(s)",
        (ids: readonly string[]) => ({ "Deleted Document IDs": ids }),
      ],
      deleteCancel: "Canceled Delete",
      search: [
        "Searched Documents",
        (term: string) => ({ "Search Term": term }),
      ],
      searchAllToggle: "Toggled Search All",
      searchClear: "Cleared Search",
      exportChangeLog: "Exported Change Log",
      uploadClick: "Clicked Upload",
      uploadStart: "Started Upload",
      uploadEnd: "Uploaded Documents",
      uploadCancel: "Canceled Upload",
      uploadCancelOne: "Canceled Individual Upload",
      uploadShowInFolder: "Clicked Show Upload in Folder",
    },

    extraContext
  );
};
