import { gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { formatSize } from "utils/calculations";

import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { HoldingCompanyPortfoliosFragment } from "~/components/HoldingCompany/Portfolios.generated";
import {
  PortfoliosQuery,
  usePortfoliosQuery,
} from "~/components/HomePage/api/queries.generated";
import { Field } from "~/components/Projects/context/OrderingContext";
import { HeaderCell } from "~/components/Projects/ProjectsTable";
import EmptyState from "~/components/QAndA/components/EmptyState";
import SearchBar from "~/components/SearchBar";
import SubHeader from "~/components/typography/SubHeader";
import { formattedNumber } from "~/components/utils/portfolio";

const getBody = (portfolios: PortfoliosQuery["portfolios"]) => {
  return (
    <>
      {portfolios &&
        portfolios.map((portfolio) => (
          <a
            key={portfolio.id}
            href={`/portfolios/${portfolio.slug}`}
            className="projects-table-row"
          >
            <div className="large">{portfolio.name}</div>
            <div>{portfolio.status}</div>
            <div style={{ width: "10%", textAlign: "right" }}>
              {formatSize(portfolio.systemSize)}
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
              {formattedNumber(portfolio.projectCount)}
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
              {formattedNumber(portfolio.remainingPpaTerm, 1)}
            </div>
          </a>
        ))}
    </>
  );
};

export const HOLDING_COMPANY_PORTFOLIOS_FRAGMENT = gql`
  fragment HoldingCompanyPortfolios on HoldingCompany {
    id
  }
`;

export interface PortfoliosProps {
  holdingCompany: HoldingCompanyPortfoliosFragment;
  onView?: () => void;
  onSearchChange?: (query: string) => void;
}

const Portfolios = ({
  holdingCompany,
  onView = () => {},
  onSearchChange = () => {},
}: PortfoliosProps) => {
  useEffect(() => onView(), [onView]);
  const [search, setSearch] = useState("");
  const holdingCompanyId = holdingCompany.id.toString();

  const { data, loading } = usePortfoliosQuery({
    variables: { holdingCompanyId, search },
  });

  return (
    <>
      <SubHeader>Portfolios</SubHeader>
      <div className="d-flex align-items-start justify-content-between mb-2 mt-3">
        <div className="col-5 p-0">
          <SearchBar
            keyword={search}
            onChange={(e) => {
              setSearch(e.target.value);
              onSearchChange(e.target.value);
            }}
            placeholderText="Search Projects"
            renderInputGroupPrepend={() => (
              <div className="prepend">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <i className="icon icon--search-dark" />
                )}
              </div>
            )}
            clearable
            onClear={() => setSearch("")}
          />
        </div>
      </div>
      <div className="projects-table">
        <div className="projects-table-header">
          <HeaderCell
            name={Field.NAME}
            ordering={null}
            toggleSorting={() => null}
          >
            {"Name"}
          </HeaderCell>
          <HeaderCell
            name={Field.STATUS}
            ordering={null}
            toggleSorting={() => null}
          >
            {"Status"}
          </HeaderCell>
          <HeaderCell
            style={{ padding: "0", width: "16%", textAlign: "right" }}
            name={Field.LOCATION}
            ordering={null}
            toggleSorting={() => null}
          >
            {"System Size (kW DC)"}
          </HeaderCell>
          <HeaderCell
            style={{ padding: "0", width: "16%", textAlign: "right" }}
            name={Field.CAPACITY}
            ordering={null}
            toggleSorting={() => null}
          >
            {"# of Projects"}
          </HeaderCell>
          <HeaderCell
            style={{ padding: "0", width: "16%", textAlign: "right" }}
            name={Field.PPA_REMAINING_AVG}
            ordering={null}
            toggleSorting={() => null}
          >
            {"PPA Remaining (Years)"}
          </HeaderCell>
        </div>
        {getBody(data?.portfolios)}
      </div>
      {(!data?.portfolios || !data?.portfolios.length) && !loading && (
        <EmptyState text={"No portfolios found."} />
      )}
    </>
  );
};

export default Portfolios;
