import { Icon } from "components/icons";
import React from "react";

const CaretIcon = (props) => (
  <Icon viewBox="0 0 16 10" withoutBackground {...props}>
    <path
      fillRule="evenodd"
      d="M8.00097644,9.05199561 L2.56414012,3.45111681 C1.97827414,2.84962773
                    1.02526547,2.84962773 0.439399487,3.45111681 C-0.146466496,4.05260588
                    -0.146466496,5.03686073 0.439399487,5.63834981 L7.29012572,12.6921762
                    C7.68070304,13.0983767 8.32124985,13.0983767 8.71182717,12.6921762
                    L15.5625534,5.63834981 C15.8515806,5.3336995 16,4.93531063 16,4.54473331
                    C16,4.14634444 15.8515806,3.75576712 15.5625534,3.45111681 C14.9766874,2.84962773
                    14.0236788,2.84962773 13.4378128,3.45111681 L8.00097644,9.05199561 Z"
      transform="translate(0 -3)"
    />
  </Icon>
);

CaretIcon.propTypes = Icon.propTypes;

CaretIcon.defaultProps = {
  className: null,
  color: "primary",
  isOpaque: true,
};

export default CaretIcon;
