import gql from "graphql-tag";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useParams, useRouteMatch } from "react-router-dom";

import { PermissionType } from "~/api/types.generated";
import { PageContainer } from "~/components/common/layout";
import { Toolbar } from "~/components/common/toolbars";
import WhiteBox from "~/components/layout/WhiteBox";
import NavTab from "~/components/navigation/NavTab";
import NavTabs from "~/components/navigation/NavTabs";
import OrderingContext, {
  useOrderingContext,
} from "~/components/Projects/context/OrderingContext";
import { NewProjectButton } from "~/components/Projects/NewProjectButton";
import { ProjectBanner } from "~/components/Projects/ProjectBanner";
import ProjectPage from "~/components/Projects/ProjectPage";
import { ProjectsExportButton } from "~/components/Projects/ProjectsExportButton";
import { ProjectsFilters } from "~/components/Projects/ProjectsFilters";
import ProjectsMap, {
  PROJECTS_MAP_FRAGMENT,
  ProjectsMapProps,
} from "~/components/Projects/ProjectsMap";
import ProjectsRadiusTable, {
  PROJECTS_RADIUS_TABLE_FRAGMENT,
  ProjectsRadiusTableProps,
} from "~/components/Projects/ProjectsRadiusTable";
import ProjectsSearchBar from "~/components/Projects/ProjectsSearchBar";
import ProjectsTable, {
  PROJECTS_TABLE_FRAGMENT,
  ProjectsTableProps,
} from "~/components/Projects/ProjectsTable";
import { QualifyingFacilitiesHeader } from "~/components/Projects/QualifyingFacilitiesHeader";
import {
  ProjectsSearch,
  useProjectsSearch,
} from "~/components/Projects/useProjectsSearch";
import SubHeader from "~/components/typography/SubHeader";
import { OrgPermission } from "~/permissions/useViewerPermissions";

import { PROJECT_DETAIL_FRAGMENT } from "./ProjectPage";
import { PROJECTS_BANNER_FRAGMENT, ProjectsBanner } from "./ProjectsBanner";
import {
  ProjectsPageQuery,
  useProjectLazyQuery,
  useProjectsPageQuery,
} from "./ProjectsLayout.generated";

export type View = "list" | "map" | "radius";

/** Gets the current projects view mode from the URL */
const getView = (queryParams: URLSearchParams): View => {
  const view = queryParams.get("view");
  return view === "list" || view === "map" || view === "radius" ? view : "list";
};

export const PROJECTS_QUERY = gql`
  query ProjectsPage(
    $orgSlug: String!
    $portfolioIds: [ID]
    $workflowStatus: Int
    $isOwned: Boolean
    $search: String
    $ordering: String
  ) {
    projectsData: projects(
      orgSlug: $orgSlug
      portfolioIds: $portfolioIds
      workflowStatus: $workflowStatus
      isOwned: $isOwned
      search: $search
      ordering: $ordering
    ) {
      ...ProjectsBanner
      ...ProjectsMap
      ...ProjectsTable
      ...ProjectsRadiusTable
    }
  }

  ${PROJECT_DETAIL_FRAGMENT}
  ${PROJECTS_BANNER_FRAGMENT}
  ${PROJECTS_MAP_FRAGMENT}
  ${PROJECTS_TABLE_FRAGMENT}
  ${PROJECTS_RADIUS_TABLE_FRAGMENT}
`;

export const PROJECT_QUERY = gql`
  query Project(
    $projectId: ID!
    $portfolioIds: [ID]
    $portfolioStatuses: [PortfolioStatus]
  ) {
    project(projectId: $projectId) {
      ...ProjectDetail
    }
  }

  ${PROJECT_DETAIL_FRAGMENT}
`;

interface ProjectsProps {
  permissionsByOrg: OrgPermission;
}

interface RenderProps {
  orgSlug: string;
  view: View;
}

export const Projects = ({ permissionsByOrg }: ProjectsProps) => {
  const ordering = useOrderingContext();
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const view = getView(queryParams);
  return (
    <OrderingContext.Provider value={ordering}>
      <RenderProjects
        view={view}
        orgSlug={orgSlug}
        permissionsByOrg={permissionsByOrg}
      />
    </OrderingContext.Provider>
  );
};

const RenderProjects = ({
  orgSlug,
  view,
  permissionsByOrg,
}: ProjectsProps & RenderProps) => {
  const { projectId } = useParams<{ projectId: string }>();
  const isProjectsAdmin = (permissionsByOrg[orgSlug || ""] ?? []).includes(
    PermissionType.ProjectsAdmin
  );
  const search = useProjectsSearch();
  const [data, setData] = useState<ProjectsPageQuery | null>(null);
  const { ordering, serializeOrdering } = useContext(OrderingContext);
  const [loadProject, { data: projectDetail, loading: loadingProjectDetail }] =
    useProjectLazyQuery();
  const match = useRouteMatch();
  useEffect(() => {
    if (projectId) {
      loadProject({
        variables: {
          projectId: projectId,
        },
      });
    }
  }, [projectId, loadProject]);
  const { previousData, loading } = useProjectsPageQuery({
    variables: {
      orgSlug: orgSlug,
      search: search.debouncedSearchTerm,
      ordering: serializeOrdering(ordering),
      portfolioIds: search.portfolioIds,
      isOwned: search.isOwned,
    },
    returnPartialData: true,
    onCompleted: setData,
  });
  const getSearch = (view: View) => {
    if (search.searchTerm) return "?view=" + view + "&q=" + search.searchTerm;
    return "?view=" + view;
  };

  return (
    <PageContainer>
      {!projectId && (
        <ProjectsBanner data={data?.projectsData} showAdmin={isProjectsAdmin} />
      )}
      {projectId && (
        <ProjectBanner
          project={projectDetail?.project}
          loading={loadingProjectDetail}
          showAdmin={isProjectsAdmin}
          orgSlug={orgSlug}
        />
      )}
      {!projectId && (
        <NavTabs>
          <NavTab
            to={{
              pathname: `${match.url}`,
              search: getSearch("list"),
            }}
            activeClassName="active"
            isActive={() => view === "list"}
          >
            List
          </NavTab>
          <NavTab
            to={{
              pathname: `${match.url}`,
              search: getSearch("map"),
            }}
            activeClassName="active"
            isActive={() => view === "map"}
          >
            Map
          </NavTab>
          <NavTab
            to={{
              pathname: `${match.url}`,
              search: getSearch("radius"),
            }}
            activeClassName="active"
            isActive={() => view === "radius"}
          >
            Qualifying Facilities
          </NavTab>
        </NavTabs>
      )}
      {!projectId && view === "list" && (
        <ProjectsListPage
          orgSlug={orgSlug}
          search={search}
          loading={loading}
          projects={data?.projectsData?.projects}
          previousProjects={previousData?.projectsData?.projects}
        />
      )}
      {!projectId && view === "map" && (
        <ProjectsMapPage
          orgSlug={orgSlug}
          search={search}
          projects={data?.projectsData?.projects}
        />
      )}
      {!projectId && view === "radius" && (
        <ProjectsRadiusPage
          orgSlug={orgSlug}
          search={search}
          loading={loading}
          projects={data?.projectsData?.projects}
          previousProjects={previousData?.projectsData?.projects}
        />
      )}
      {projectId && (
        <ProjectDetailPage
          projects={data?.projectsData?.projects}
          isProjectsAdmin={isProjectsAdmin}
          orgSlug={orgSlug}
        />
      )}
    </PageContainer>
  );
};

export interface ProjectsPageProps {
  orgSlug: string;
  search: ProjectsSearch;
}

const ProjectsListPage = ({
  orgSlug,
  projects,
  previousProjects,
  loading,
  search,
}: ProjectsTableProps & { search: ProjectsSearch; orgSlug: string }) => (
  <WhiteBox>
    <SubHeader>List</SubHeader>
    <Toolbar>
      <ProjectsSearchBar {...search} />
      <ProjectsFilters {...search} />
      <NewProjectButton orgSlug={orgSlug} />
    </Toolbar>
    <ProjectsTable
      projects={projects}
      previousProjects={previousProjects}
      loading={loading}
    />
  </WhiteBox>
);

const ProjectsRadiusPage = ({
  orgSlug,
  projects,
  previousProjects,
  loading,
  search,
}: ProjectsRadiusTableProps & { search: ProjectsSearch; orgSlug: string }) => (
  <WhiteBox>
    <QualifyingFacilitiesHeader />
    <Toolbar>
      <ProjectsSearchBar {...search} />
      <ProjectsFilters {...search} />
      <NewProjectButton orgSlug={orgSlug} />
      <ProjectsExportButton orgSlug={orgSlug} {...search} />
    </Toolbar>
    <ProjectsRadiusTable
      projects={projects}
      previousProjects={previousProjects}
      loading={loading}
    />
  </WhiteBox>
);

const ProjectsMapPage = ({
  orgSlug,
  projects,
  search,
}: ProjectsPageProps & ProjectsMapProps) => (
  <WhiteBox>
    <SubHeader>Map</SubHeader>
    <Toolbar>
      <ProjectsSearchBar {...search} />
      <ProjectsFilters {...search} />
      <NewProjectButton orgSlug={orgSlug} />
    </Toolbar>
    <ProjectsMap projects={projects} />
  </WhiteBox>
);

export const ProjectDetailPage = ({
  projects,
  isProjectsAdmin,
  orgSlug,
}: ProjectsMapProps & { isProjectsAdmin: boolean; orgSlug: string }) => {
  const { projectId } = useParams<{ projectId?: string }>();
  const [loadProject, { data, loading }] = useProjectLazyQuery();

  useEffect(() => {
    if (projectId) {
      loadProject({ variables: { projectId: projectId } });
    }
  }, [projectId, loadProject]);

  return (
    <WhiteBox>
      <ProjectPage
        project={data?.project}
        projects={projects}
        loading={loading}
        isProjectsAdmin={isProjectsAdmin}
        orgSlug={orgSlug}
        showRadiusBar={true}
      />
    </WhiteBox>
  );
};
