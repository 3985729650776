import path from "path";

import React from "react";
import styled from "styled-components";

import { Maybe } from "~/api/types.generated";
import {
  FileDocIcon,
  FileExcelIcon,
  FileFolderIcon,
  FileIcon,
  FilePdfIcon,
} from "~/components/common/icons";
import { SkeletonThumbnail } from "~/components/common/skeletons";
import { fgColor } from "~/styles/mixins";

import { Document_InfoFragment } from "./DocumentsAPI.generated";

export interface DocumentsThumbnailProps {
  document?: Maybe<Document_InfoFragment>;
}

const DocumentsThumbnail = (props: DocumentsThumbnailProps) => {
  const { document } = props;

  if (!document?.fileType) return <SkeletonThumbnail />;
  if (document.fileType === "Folder") return <Thumbnail as={FileFolderIcon} />;

  switch (path.extname(document.name?.toLowerCase() ?? "")) {
    case ".pdf":
      return <Thumbnail as={FilePdfIcon} />;

    case ".doc":
    case ".docx":
      return <Thumbnail as={FileDocIcon} />;

    case ".xls":
    case ".xlsx":
    case ".xlsb":
      return <Thumbnail as={FileExcelIcon} />;

    case ".png":
    case ".jpg":
    case ".jpeg":
      return document.thumbnailUrl ? (
        <Thumbnail as="img" alt="" src={document.thumbnailUrl} />
      ) : (
        <Thumbnail as={FileIcon} />
      );

    default:
      return <Thumbnail as={FileIcon} />;
  }
};

export const Thumbnail = styled.span`
  ${fgColor.blue()};
  display: inline-block;
  flex: 0 0 2rem;
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
`;

export default DocumentsThumbnail;
