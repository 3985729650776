import { keyBy } from "lodash";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";

import { ChecklistItemStatus } from "~/api/types.generated";
import {
  ChecklistProjectFragment,
  ChecklistUserFragment,
  usePortfolioProjectsQuery,
} from "~/components/Checklist/ChecklistPage.generated";
import { translationsByName } from "~/utils/checklistStatuses";

export interface ChecklistFiltersContextValue {
  loading: boolean;
  availableProjects: readonly ChecklistProjectFragment[];
  availableUsers: readonly ChecklistUserFragment[];
  usersById: Record<string, ChecklistUserFragment>;
  availableStatuses: Array<{ key: string; value: string }>;
  selectedStatuses: string[];
  setSelectedStatuses: Dispatch<SetStateAction<string[]>>;
  selectedUserIds: string[];
  setSelectedUserIds: Dispatch<SetStateAction<string[]>>;
  selectedProjectIds: string[];
  setSelectedProjectIds: Dispatch<SetStateAction<string[]>>;
}

export const useChecklistFilters = (
  portfolioId: string
): ChecklistFiltersContextValue => {
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
  const { data, loading } = usePortfolioProjectsQuery({
    variables: { portfolioId: portfolioId },
  });
  const availableStatuses = Object.values(ChecklistItemStatus).map((value) => ({
    key: value,
    value: translationsByName[value],
  }));
  const availableProjects = useMemo(
    () => data?.portfolio?.projects ?? [],
    [data]
  );

  const availableUsers = useMemo(() => data?.portfolioUsers ?? [], [data]);
  const usersById = useMemo(
    () => keyBy(availableUsers, "id"),
    [availableUsers]
  );

  return useMemo(
    () => ({
      loading,
      availableProjects,
      availableUsers,
      usersById,
      availableStatuses,
      selectedStatuses,
      setSelectedStatuses,
      selectedUserIds,
      setSelectedUserIds,
      selectedProjectIds,
      setSelectedProjectIds,
    }),
    [
      loading,
      availableProjects,
      availableUsers,
      usersById,
      availableStatuses,
      selectedStatuses,
      setSelectedStatuses,
      selectedUserIds,
      setSelectedUserIds,
      selectedProjectIds,
      setSelectedProjectIds,
    ]
  );
};

const ChecklistFiltersContext = createContext<ChecklistFiltersContextValue>(
  null as any
);

export default ChecklistFiltersContext;
