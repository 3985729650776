import React from "react";
import styled from "styled-components";

import { CellDropdownButton } from "~/components/DataGrid";
import ItemMenu from "~/components/QAndA/components/ItemMenu";

import { useQuestion } from "../context/QuestionContext";
import { useQuestionProjects } from "../context/QuestionProjectsContext";

const QuestionProjects = () => {
  const question = useQuestion();
  const {
    availableProjects,
    hasWritePermission,
    addProject,
    addAllProjects,
    removeProject,
    removeAllProjects,
  } = useQuestionProjects();

  const disabled = question.isClosed || !hasWritePermission;
  const currentProjects = [...question.projects].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  const allProjects = currentProjects.length === availableProjects?.length;

  return (
    <CellDropdownButton
      disabled={disabled}
      dropdown={
        <ItemMenu
          title="Project"
          anchor="top right"
          origin="top left"
          currentItems={currentProjects}
          availableItems={availableProjects ?? []}
          onSelectItem={(project) => addProject(question, project)}
          onSelectAll={() => addAllProjects(question)}
          onDeselectItem={(project) => removeProject(question, project)}
          onDeselectAll={() => removeAllProjects(question)}
        />
      }
    >
      {allProjects ? (
        <Item>All projects</Item>
      ) : (
        currentProjects.map(({ id, name }) => <Item key={id}>{name}</Item>)
      )}
    </CellDropdownButton>
  );
};

const Item = styled.div`
  white-space: nowrap;
`;

export default QuestionProjects;
