import React from "react";
import styled from "styled-components";

import { QuestionIcon } from "~/components/common/icons";
import ToolTip from "~/components/DocumentUpload/ToolTip";
import SubHeader from "~/components/typography/SubHeader";
import { blue } from "~/styles/theme/color";

export const QualifyingFacilitiesHeader = () => {
  return (
    <Container>
      FERC - PURPA Qualifying Facilities (QF)
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.ferc.gov/qf"
      >
        <ToolTip
          placement="top"
          tooltip="QFs may enjoy certain benefits under Federal, State, and local laws. Click for more info on QFs and Self-Certification."
          delayShow={250}
        >
          <QuestionIcon />
        </ToolTip>
      </a>
    </Container>
  );
};

const Container = styled(SubHeader)`
  svg {
    flex: 0 0 auto;
    color: ${blue};
    margin-bottom: 1rem;
    width: 1rem;
    height: 1rem;
  }
`;
