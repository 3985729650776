import { compact, last } from "lodash";
import { useMemo } from "react";

import { Document } from "~/api/types.generated";
import {
  DocumentTree_InfoFragment,
  Document_BreadcrumbsFragment,
  Document_InfoFragment,
} from "~/components/Documents/DocumentsAPI.generated";
import { sortByName } from "~/components/Documents/utils";

import { DocumentsQueries } from "./useDocumentsQueries";
import { DocumentsRouting } from "./useDocumentsRouting";

export interface DocumentsCurrent {
  isFile: boolean;
  isPreview: boolean;
  index: number;
  nearestId: string;
  document: Document_BreadcrumbsFragment | null;
  error: string | null;
  ancestors: readonly Document_InfoFragment[] | null;
  parent: Document_InfoFragment | null;
  siblings: readonly Document_InfoFragment[] | null;
  prev: Document_InfoFragment | null;
  next: Document_InfoFragment | null;
  folder: Document_InfoFragment | null;
  tree: DocumentTree_InfoFragment | null;
}

/**
 * Extracts data related to the current file/folder and its relations from
 * routing and query data.
 */
export const useDocumentsCurrent = (
  { view, rootId }: DocumentsRouting,
  { detail, columns, searchDocs, history }: DocumentsQueries
): DocumentsCurrent => {
  const data =
    detail.data ?? columns.data ?? searchDocs.data ?? history.data ?? null;
  const document = data?.document ?? null;
  const error = detail.error?.message ?? null;
  const tree = data?.documentTree ?? null;

  const isFile = document?.fileType === "File";
  const isPreview = isFile && view === "single";

  const ancestors = useMemo(
    () => compact(document?.ancestors) ?? null,
    [document]
  );
  const parent = (document as Document).parent ?? last(ancestors) ?? null;
  const siblings = useMemo(
    () =>
      compact((parent as Document)?.children)
        .filter((child) => child.fileType === "File")
        .sort(sortByName),
    [parent]
  );

  const index = siblings.findIndex((file) => file.id === document?.id);
  const prev = index === -1 || index === 0 ? null : siblings[index - 1];
  const next =
    index === -1 || index === siblings.length - 1 ? null : siblings[index + 1];
  const nearestId = next?.id ?? prev?.id ?? parent?.id ?? rootId;

  const folder = isFile ? parent : document;

  return {
    document,
    error,
    isFile,
    isPreview,
    ancestors,
    parent,
    siblings,
    index,
    prev,
    next,
    nearestId,
    folder,
    tree,
  };
};
