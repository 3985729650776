import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

const FileExcelIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 12 14">
    <path
      d="M8 4.375h4V14H0V0h7v3.5c0 .485.445.875 1 .875zM8 0l4 3.5H8V0zM6.443 11.375v-1.75H9.12v1.75H6.443zm-3.552 0v-1.75h2.673v1.75H2.891zM6.443 8.75V7H9.12v1.75H6.443zm-3.552 0V7h2.673v1.75H2.891zm6.609 3.5c.259 0 .467-.17.498-.384l-.009-5.35c-.027-.217-.227-.391-.489-.391h-7c-.273 0-.5.198-.5.438l.001 5.293h.001c.033.214.246.394.498.394h7z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default FileExcelIcon;
