import React from "react";
import styled from "styled-components";

import { CellDropdownButton } from "~/components/DataGrid";

import { useQuestionCategories } from "../context/QuestionCategoriesContext";
import { useQuestion } from "../context/QuestionContext";
import CategoryMenu from "./CategoryMenu";

const QuestionCategories = () => {
  const question = useQuestion();
  const {
    availableCategories,
    availableTopics,
    hasWritePermission,
    addCategory,
    removeCategory,
    addTopic,
    removeTopic,
  } = useQuestionCategories();

  const disabled = question.isClosed || !hasWritePermission;
  const currentCategories = [...question.categories].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  const currentTopics = [...question.topics].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );

  return (
    <CellDropdownButton
      disabled={disabled}
      dropdown={
        <CategoryMenu
          anchor="top right"
          origin="top left"
          currentCategories={currentCategories}
          currentTopics={currentTopics}
          availableCategories={availableCategories}
          availableTopics={availableTopics}
          onSelectCategory={(category) => addCategory(question, category)}
          onDeselectCategory={(category) => removeCategory(question, category)}
          onSelectTopic={(topic) => addTopic(question, topic)}
          onDeselectTopic={(topic) => removeTopic(question, topic)}
        />
      }
    >
      <div>
        {currentCategories.map(({ id, name }) => (
          <Label key={id}>{name}</Label>
        ))}
        {currentCategories.length > 0 && currentTopics.length > 0 && " /"}
      </div>
      <div>
        {currentTopics.map(({ id, name }) => (
          <Label key={id}>{name}</Label>
        ))}
      </div>
    </CellDropdownButton>
  );
};

const Label = styled.span`
  &:after {
    content: ", ";
    display: inline;
  }
  &:last-child:after {
    display: none;
  }
`;

export default QuestionCategories;
