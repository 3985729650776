import { useMemo } from "react";

import { useAcquiredPortfoliosQuery } from "~/components/Projects/ProjectsAPI.generated";

export interface ProjectsFilters {
  portfolioItems: any[];
}

export const useProjectsFilterOptions = (orgSlug: string): ProjectsFilters => {
  const portfoliosQuery = useAcquiredPortfoliosQuery({
    variables: { orgSlug: orgSlug },
  });
  const portfolioItems = useMemo(
    () =>
      portfoliosQuery.data?.portfolios?.map(({ id, name }) => ({
        key: id,
        value: name ?? "",
      })) ?? [],
    [portfoliosQuery.data]
  );

  return { portfolioItems };
};
