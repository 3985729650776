import { LocationDescriptorObject } from "history";
import React from "react";
import { NavLink } from "react-router-dom";

import { ButtonGroup, SecondaryButton } from "~/components/common/buttons";
import { ColumnsIcon, HistoryIcon, ListIcon } from "~/components/common/icons";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";

import { View } from "./useDocumentsRouting";

export interface DocumentsViewSwitcherProps {
  track: DocumentsEventTracking;
  canViewHistory?: boolean | null;
  view: View;
  rootId: string;
  documentId: string;
  folderId: string;
  getLink: (documentId: string, view?: View) => LocationDescriptorObject;
}

export const DocumentsViewSwitcher = React.memo(function DocumentsViewSwitcher(
  props: DocumentsViewSwitcherProps
) {
  const { track, canViewHistory, view, rootId, documentId, folderId, getLink } =
    props;

  return (
    <ButtonGroup>
      <SecondaryButton
        as={NavLink}
        to={getLink(folderId, "single")}
        isActive={() => view === "single"}
        children={<ListIcon />}
        onClick={track.listViewClick}
      />
      <SecondaryButton
        as={NavLink}
        to={getLink(documentId, "columns")}
        isActive={() => view === "columns"}
        children={<ColumnsIcon />}
        onClick={track.columnsViewClick}
      />
      {canViewHistory && (
        <SecondaryButton
          as={NavLink}
          to={getLink(rootId, "history")}
          isActive={() => view === "history"}
          children={<HistoryIcon />}
          onClick={track.historyViewClick}
        />
      )}
    </ButtonGroup>
  );
});
