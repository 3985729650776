import React from "react";

import { SecondaryButton } from "~/components/common/buttons";
import { AddIcon } from "~/components/common/icons";

interface NewProjectButtonProps {
  orgSlug: string;
  portfolioId?: string;
  portfolioSlug?: string;
}

export const NewProjectButton = React.memo(function ProjectsExportButton(
  props: NewProjectButtonProps
) {
  const { orgSlug, portfolioId, portfolioSlug } = props;
  const search =
    (portfolioId &&
      portfolioSlug &&
      `?portfolioId=${portfolioId}&portfolioSlug=${portfolioSlug}`) ||
    "";

  return (
    <SecondaryButton as="a" href={`/${orgSlug}/projects/new${search}`}>
      <AddIcon />
      <span>New Project</span>
    </SecondaryButton>
  );
});
