import * as PropTypes from "prop-types";
import React, { Component } from "react";
import { gettext } from "utils/text";

class CategoryEditor extends Component {
  static propTypes = {
    // ID of django-admin form field containing categories in JSON format
    // for example "id_categories"
    categoriesId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.categoriesInput = document.querySelector(props.categoriesId);
    const initialJsonValue = this.categoriesInput.value;
    let categories;
    try {
      categories = JSON.parse(initialJsonValue);
    } catch (e) {
      categories = [];
    }
    this.state = {
      categories: categories.length ? categories : [{ name: "" }],
    };
  }

  componentWillUpdate(nextProps, nextState) {
    // on input change update the hidden json field `categories` also
    this.categoriesInput.value = JSON.stringify(
      nextState.categories,
      null,
      "  "
    );
  }

  /**
   * Update category if one of checkboxes is clicked.
   *
   * @param parent {Number} Index of parent element in multidimensional array
   * @param child {Number} Index of child element in multidimensional array
   * @param attribute {String} 'developmentActive' or 'operatingActive'
   * @param checked {Boolean} is Checkbox checked
   */
  handleCheckboxChanged([parent, child], attribute, checked) {
    this.handleChange([parent, child], { [attribute]: checked });

    // If a parent checkbox gets unchecked
    if (!checked && typeof child === "undefined") {
      this.state.categories[parent].children.forEach((category, childIndex) => {
        this.handleChange([parent, childIndex], { [attribute]: checked });
      });
    }
  }

  /**
   * Update category logic.
   *
   * @param parent {Number} Index of parent element in multidimensional array
   * @param child {Number} Index of child element in multidimensional array
   * @param parameters {Object} Additional parameters
   */
  handleChange([parent, child], parameters) {
    let { categories } = this.state;
    if (!categories.length) {
      categories = [parameters];
    } else {
      const category = categories[parent];
      const { children } = category;

      if (child !== undefined) {
        if (children && children.length) {
          children[child] = {
            ...children[child],
            ...parameters,
          };
        } else {
          category.children = [parameters];
        }
      } else {
        Object.keys(parameters).forEach((parameterKey) => {
          category[parameterKey] = parameters[parameterKey];
        });
      }
    }
    this.setState({ categories });
  }

  /**
   * Insert new category
   *
   * @param parent {Number} Index of parent element in multidimensional array
   * @param child {Number} Index of child element in multidimensional array
   */
  handleInsert([parent, child]) {
    const newCategory = {
      name: "",
      developmentActive: true,
      operatingActive: true,
    };

    const { categories } = this.state;
    if (child === undefined) {
      categories.splice(parent + 1, 0, newCategory);
    } else {
      const category = categories[parent];
      const { children } = category;
      if (!children || !children.length) {
        category.children = [];
      }
      category.children.splice(child + 1, 0, newCategory);
    }

    this.setState({ categories });
  }

  /**
   * Delete existing category
   *
   * @param parent {Number} Index of parent element in multidimensional array
   * @param child {Number} Index of child element in multidimensional array
   */
  handleDelete([parent, child]) {
    let { categories } = this.state;
    if (categories.length) {
      if (child === undefined) {
        categories.splice(parent, 1);
      } else {
        const category = categories[parent];
        const { children } = category;
        if (children && children.length) {
          category.children.splice(child, 1);
          if (!children.length) {
            category.children = undefined;
          }
        }
      }
    }
    if (!categories.length) {
      categories = [{ name: "" }];
    }
    this.setState({ categories });
  }

  renderCheckbox = (label, name, checked, disabled, onChange) => (
    <label
      // Add some resetting styles since labels look
      // different depending on admin parent classes
      className="float-none pr-1"
      style={{ width: "auto" }}
      htmlFor={name}
    >
      {label}
      <input
        className="ml-1"
        type="checkbox"
        checked={checked}
        id={name}
        disabled={disabled}
        onChange={onChange}
      />
    </label>
  );

  renderInput = (
    { name, children, developmentActive = true, operatingActive = true },
    [index, childIndex],
    isChild
  ) => {
    const addNew = (i, c, text) => (
      <button
        className="btn btn-sm btn-success ml-3"
        type="button"
        onClick={() => this.handleInsert([i, c])}
      >
        {text}
      </button>
    );
    const parent = this.state.categories[index];

    return (
      <div key={`${index}-${childIndex}`} className="my-3">
        <div>
          <span className={isChild ? "ml-5" : "mr-5"}>
            <span className="mr-2">
              {index + 1}
              {isChild ? `.${childIndex + 1}` : ".0"}
            </span>
            <input
              className="mr-2"
              type="text"
              value={name}
              required
              onChange={(e) =>
                this.handleChange([index, childIndex], { name: e.target.value })
              }
            />
            {this.renderCheckbox(
              gettext("Development"),
              `development-active-${index}-${childIndex}`,
              developmentActive,
              isChild &&
                (Object.prototype.hasOwnProperty.call(
                  parent,
                  "developmentActive"
                )
                  ? !parent.developmentActive
                  : false),
              (e) =>
                this.handleCheckboxChanged(
                  [index, childIndex],
                  "developmentActive",
                  e.target.checked
                )
            )}
            {this.renderCheckbox(
              gettext("Operating"),
              `operating-active-${index}-${childIndex}`,
              operatingActive,
              isChild &&
                (Object.prototype.hasOwnProperty.call(parent, "operatingActive")
                  ? !parent.operatingActive
                  : false),
              (e) =>
                this.handleCheckboxChanged(
                  [index, childIndex],
                  "operatingActive",
                  e.target.checked
                )
            )}
          </span>
          {addNew(index, childIndex, gettext("Insert after"))}
          <button
            className="btn btn-sm btn-danger ml-3"
            type="button"
            onClick={() => this.handleDelete([index, childIndex])}
          >
            {gettext("×")}
          </button>
        </div>
        {!isChild && (
          <div>
            {children && children.length ? (
              children.map((e, i) => this.renderInput(e, [index, i], true))
            ) : (
              <div className="my-3">
                {addNew(index, 0, gettext("Add new sub item"))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  renderResult = (categories) =>
    categories.map((category, index) => (
      <div key={category.name} data-testid="top-category" className="mb-3">
        {`${index + 1}.0 ${category.name}`}
        {category.children
          ? category.children.map((childCategory, childIndex) => (
              <div
                key={childCategory.name}
                data-testid="child-category"
                className="ml-3 mt-3"
              >
                {`${index + 1}.${childIndex + 1} ${childCategory.name}`}
              </div>
            ))
          : null}
      </div>
    ));

  /* eslint-enable react/no-array-index-key */
  renderInputs() {
    return (
      <div className="row">
        <div className="col-md-5">
          <pre className="bg-light p-3">
            {this.renderResult(this.state.categories)}
          </pre>
        </div>
        <div className="col-md-7">
          {this.state.categories.map((e, i) => this.renderInput(e, [i], false))}
        </div>
      </div>
    );
  }

  render() {
    return this.renderInputs();
  }
}

export default CategoryEditor;
