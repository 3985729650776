import cx from "classnames";
import React, { useContext } from "react";
import styled from "styled-components";

import BaseLoadingSpinner from "~/components/generic/LoadingSpinner";
import { GetPropsWithoutRef } from "~/utils/types";

import { useCreateQuestionMutation } from "../api/mutations.generated";
import CategoryFilterContext from "../context/CategoryFilterContext";
import ProjectFilterContext from "../context/ProjectFilterContext";
import { useQAndA, useSaving } from "../context/QAndAContext";

export interface NewQuestionButtonProps extends GetPropsWithoutRef<"button"> {}

interface NewQuestionButtonComponentProps {
  logId: string;
  canEditQuestions: boolean;
}

const NewQuestionButton = ({
  logId,
  className,
  canEditQuestions,
  ...rest
}: NewQuestionButtonProps & NewQuestionButtonComponentProps) => {
  const qAndA = useQAndA();
  const categoryFilter = useContext(CategoryFilterContext);
  const projectFilter = useContext(ProjectFilterContext);
  const [createQuestion, { loading }] = useCreateQuestionMutation();

  useSaving(loading);

  const handleClick = () => {
    (async () => {
      const categoryIds = categoryFilter.categorySelection
        .values()
        .map((c) => c.id);
      const topicIds = categoryFilter.topicSelection.values().map((t) => t.id);
      const projectIds = projectFilter.selection.values().map((p) => p.id);

      const result = await createQuestion({
        variables: {
          logId,
          categoryIds,
          topicIds,
          projectIds,
        },
        refetchQueries: ["Questions"],
        awaitRefetchQueries: true,
      });

      if (result.data?.createQuestion) {
        qAndA.focusQuestion(result.data.createQuestion.id);
      }
    })();
  };

  if (!canEditQuestions) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      className={cx("btn btn-primary", className)}
      {...rest}
    >
      New Question {loading && <LoadingSpinner color="light" />}
    </button>
  );
};

const LoadingSpinner = styled(BaseLoadingSpinner)`
  margin: 0 0 0 4px;
`;

export default styled(NewQuestionButton)`
  margin: 0 0.25rem;
  display: inline-flex;
  align-items: center;
`;
