import { ellipsis } from "polished";
import React, { useContext } from "react";
import styled, { css } from "styled-components";

import BaseDropdownButton from "~/components/menus/DropdownButton";
import {
  bgColor,
  border,
  borderRadius,
  fgColor,
  focusOutline,
} from "~/styles/mixins";

import CategoryFilterContext from "../context/CategoryFilterContext";
import CategoryMenu from "./CategoryMenu";

const CategoryFilter = () => {
  const {
    availableCategories,
    availableTopics,
    categorySelection,
    topicSelection,
  } = useContext(CategoryFilterContext);

  const currentCategories = categorySelection.values();
  const currentTopics = topicSelection.values();
  const current = [...currentCategories, ...currentTopics];
  const allSelected =
    (categorySelection.allSelected || !categorySelection.anySelected) &&
    (topicSelection.anySelected || !topicSelection.anySelected);
  const oneSelected =
    !allSelected && categorySelection.count + topicSelection.count === 1;

  return (
    <DropdownButton
      hasSelection={categorySelection.anySelected || topicSelection.anySelected}
      dropdown={
        <CategoryMenu
          anchor="bottom right"
          origin="top right"
          currentCategories={currentCategories}
          currentTopics={currentTopics}
          availableCategories={availableCategories}
          availableTopics={availableTopics}
          onSelectCategory={categorySelection.select}
          onSelectTopic={topicSelection.select}
          onDeselectCategory={categorySelection.deselect}
          onDeselectTopic={topicSelection.deselect}
        />
      }
    >
      Category / Topic:{" "}
      {allSelected ? "All" : oneSelected ? current[0].name : "Multiple"}{" "}
      {String.fromCharCode(9662)}
    </DropdownButton>
  );
};

const DropdownButton = styled(BaseDropdownButton).withConfig<{
  hasSelection: boolean;
}>({
  shouldForwardProp: (prop) => prop !== "hasSelection",
})`
  ${borderRadius()}
  ${focusOutline()};
  ${bgColor.transparent()};
  ${border.transparent()};
  ${ellipsis()};
  height: 1.75rem;

  &:hover {
    ${bgColor.gray300()};
  }

  &[aria-expanded="true"] {
    ${bgColor.primary()};
    ${fgColor.white()};
  }

  ${(props) =>
    props.hasSelection &&
    css`
      ${bgColor.white()};
      ${fgColor.primary()};
      ${border.primary()};
    `}
`;

export default CategoryFilter;
