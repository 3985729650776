import { Link } from "react-router-dom";
import styled from "styled-components";

import { device } from "~/styles/mixins";

const TitleAnchor = styled(Link)`
  align-self: center;
  font-size: 24px;
  color: ${(props) => props.theme.color.cyan};
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
  @media ${device.xs} {
    font-size: 2rem;
  }
`;

export default TitleAnchor;
