import { createContext, useMemo, useState } from "react";

import { Direction, Ordering, createOrdering } from "~/utils/ordering";

export enum Field {
  NUMBER = "number",
}

export interface OrderingContextValue {
  ordering: Ordering<Field>;
  setOrdering: (ordering: Ordering<Field>) => void;
}

export const useOrderingContext = (): OrderingContextValue => {
  const [ordering, setOrdering] = useState(
    createOrdering(Field.NUMBER, Direction.ASC)
  );

  return useMemo(() => ({ ordering, setOrdering }), [ordering, setOrdering]);
};

const OrderingContext = createContext<OrderingContextValue>(null as any);

export default OrderingContext;
