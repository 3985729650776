import { History, Location } from "history";
import { keyBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { QuestionLog } from "~/api/types.generated";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";

export interface QuestionLogRouting {
  history: History<unknown>;
  location: Location<unknown>;
  logId: string | null;
  selectedLog: QuestionLog | null;
  setSelectedLog(log: QuestionLog): void;
  queryParams: URLSearchParams;
}

/**
 * Extracts state for documents that is stored in the URL and query params.
 *
 * | URL Pattern                        | Description                      |
 * | ---------------------------------  | -------------------------------- |
 * | /?logId=<logId>                   | Selected log to load              |
 */
export const useQuestionLogRouting = (
  subject: QAndASubjectFragment
): QuestionLogRouting => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const logsById = keyBy(subject.qAndALogs ?? [], "id");
  const logId = queryParams.get("logId");
  /** Updates the search term state via the "q" query parameter */
  const setLogId = useCallback(
    (logId: string) => {
      const queryParams = new URLSearchParams(history.location.search);
      queryParams.set("logId", logId);
      history.push({ search: `?${queryParams.toString()}` });
    },
    [history]
  );

  let initialLogId = logId;
  if (
    initialLogId === null &&
    subject.qAndALogs &&
    subject.qAndALogs.length > 0
  ) {
    initialLogId = subject.qAndALogs[0].id;
    setLogId(initialLogId);
  }
  const [selectedLog, setSelection] = useState<QuestionLog | null>(
    initialLogId !== null ? logsById[initialLogId] : null
  );

  const setSelectedLog = (log: QuestionLog) => {
    setLogId(log.id);
    setSelection(log);
  };
  return { history, location, logId, selectedLog, queryParams, setSelectedLog };
};
