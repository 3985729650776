import React, { useState } from "react";
import { useRef } from "react";
import Highlighter from "react-highlight-words";
import styled from "styled-components";

import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { CloseIcon } from "~/components/common/icons";
import { TextInput } from "~/components/common/inputs";
import { SkeletonText } from "~/components/common/skeletons";
import { EllipsisText, LightLabel } from "~/components/common/text";
import { Toolbar } from "~/components/common/toolbars";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { useAutoFocus } from "~/hooks/useAutoFocus";
import { useKeyDown } from "~/hooks/useKeyDown";
import { blue } from "~/styles/theme/color";

import {
  Document_BreadcrumbsFragment,
  Document_InfoFragment,
} from "./DocumentsAPI.generated";

export interface DocumentsNameProps {
  track: DocumentsEventTracking;
  document: Document_BreadcrumbsFragment;
  showPath: boolean;
  highlight: string | null;
  renameVisible: (documentId: string) => boolean;
  renameLoading: boolean;
  rename: (document: Document_InfoFragment, name: string) => Promise<void>;
  cancel: () => void;
}

/**
 * A document name component that supports inline renaming, search highlighting,
 * search path, and skeleton loading states.
 */
export const DocumentsName = React.memo(function DocumentsName(
  props: DocumentsNameProps
) {
  const {
    track,
    document,
    showPath,
    highlight,
    renameVisible,
    renameLoading,
    rename,
    cancel,
  } = props;
  const [value, setValue] = useState(document.name ?? "");
  const input = useRef<HTMLInputElement>(null);

  const renameActive = renameVisible(document.id);
  useAutoFocus(renameActive, input);
  useKeyDown("Escape", renameActive, cancel);

  const change: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.currentTarget.value);
  };

  const submit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    track.renameConfirm(document);
    rename(document, value);
  };

  const { name, ancestors } = document;

  const renameForm = renameActive && (
    <Toolbar as="form" onSubmit={submit}>
      <TextInput
        ref={input}
        value={value}
        disabled={renameLoading}
        onChange={change}
      />
      <PrimaryButton type="submit" disabled={renameLoading}>
        {renameLoading && <LoadingSpinner color="light" />}
        <span>{renameLoading ? "Saving..." : "Save"}</span>
      </PrimaryButton>
      <CancelButton
        type="button"
        disabled={renameLoading}
        onClick={() => {
          track.renameCancel(document);
          cancel();
        }}
      >
        <CloseIcon />
      </CancelButton>
    </Toolbar>
  );

  const title =
    !renameActive &&
    (name ? (
      <EllipsisText>
        {highlight ? (
          <Highlighter
            searchWords={highlight.split(/\s+/)}
            textToHighlight={name}
            autoEscape
          />
        ) : (
          name
        )}
      </EllipsisText>
    ) : (
      <SkeletonText />
    ));

  const path = !renameActive && showPath && (
    <LightLabel>
      {ancestors ? (
        <>in {ancestors.map((d) => d?.name).join(" > ")}</>
      ) : (
        <SkeletonText />
      )}
    </LightLabel>
  );

  return (
    <Container>
      {renameForm}
      {title}
      {path}
    </Container>
  );
});

const Container = styled.div`
  flex: 1;
  max-width: 100%;
  line-height: 1;

  > ${Toolbar} {
    margin: 0;
  }

  > ${LightLabel} {
    margin: 0 0 0.125rem 0;
  }

  tr:hover & > ${EllipsisText} {
    color: ${blue};
  }
`;

const CancelButton = styled(SecondaryButton)`
  width: 2rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
`;
