import { StatusIcon, UserIcon } from "components/icons";
import * as PropTypes from "prop-types";
import React from "react";
import { translationsByName } from "utils/checklistStatuses";
import { gettext } from "utils/text";

import {
  statusIsAttentionNeeded,
  statusIsDone,
} from "~/components/Checklist/context/ChecklistContext";

export class InnerChecklistItem extends React.PureComponent {
  static propTypes = {
    toggleSelectedChecklistItemId: PropTypes.func.isRequired,
    setSelectedChecklistItemId: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    selectedChecklistItemId: PropTypes.string,
    style: PropTypes.object,
    users: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
    item: PropTypes.shape({
      status: PropTypes.string,
      userId: PropTypes.string,
      commentCount: PropTypes.number,
    }).isRequired,
  };

  render() {
    const { item, isLoading, selectedChecklistItemId, users, style } =
      this.props;
    const isOpen = selectedChecklistItemId === item.id;

    const checklistItem = item;
    const itemUser = checklistItem.userId;
    const itemStatus = translationsByName[checklistItem.status];
    const comments = checklistItem.commentCount;

    let cellHighlightClass = "";
    if (isOpen) {
      cellHighlightClass = "cell--highlighted info";
    }

    let cellClass = "";
    let userIcon;
    let commentsIcon;
    let tdBody;

    // Conditionally give variables values
    const statusIcon = (
      <span className="d-inline-block" title={itemStatus}>
        <StatusIcon status={checklistItem.status} className="mr-1" />
      </span>
    );
    if (statusIsDone(checklistItem.status)) {
      cellClass += "cell--transparent ";
    } else if (statusIsAttentionNeeded(checklistItem.status)) {
      if (!cellHighlightClass) {
        cellHighlightClass = "cell--highlighted";
      }
      cellHighlightClass += " danger";
    }
    cellClass += cellHighlightClass;
    if (itemUser) {
      userIcon = (
        <span
          className="d-inline-block"
          title={users[itemUser] && users[itemUser].fullName}
        >
          <UserIcon />
        </span>
      );
    }

    if (comments) {
      commentsIcon = <i className="icon icon--comment-active ml-1" />;
    }

    if (isLoading) {
      tdBody = <div>{gettext("Loading...")}</div>;
    } else {
      tdBody = (
        <div>
          <div className="checklist-table__icons-container">
            {statusIcon}
            {userIcon}
            {commentsIcon}
          </div>
          {itemStatus}
        </div>
      );
    }

    const changeSelection = (itemId, toggle) => {
      if (toggle) {
        this.props.toggleSelectedChecklistItemId(itemId);
      } else {
        this.props.setSelectedChecklistItemId(itemId);
      }
    };

    if (isOpen) {
      cellClass += " cell--selected";
    }
    const onClick = (e) =>
      changeSelection(checklistItem.id, e.ctrlKey || e.metaKey);
    return (
      <div
        className={`checklist-entry sticky-table-cell ${cellClass}`}
        key={item.id}
        onClick={onClick}
        style={style}
        // Firefox selects the contents of a cell when ctrl clicking, which looks
        // weird when doing multiple-selections in the checklist,
        // this allows us to prevent that while still allowing normal copy+paste
        onMouseDown={(e) => (e.ctrlKey || e.metaKey) && e.preventDefault()}
      >
        {tdBody}
      </div>
    );
  }
}

InnerChecklistItem.defaultProps = { style: {} };

export default InnerChecklistItem;
