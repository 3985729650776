import { Icon } from "components/icons";
import * as PropTypes from "prop-types";
import React from "react";

class CommentIcon extends React.PureComponent {
  render() {
    return (
      <Icon
        color="orange"
        className={this.props.className}
        viewBox="0 0 14 14"
        withoutBackground={this.props.withoutBackground}
        inverted={this.props.inverted}
      >
        <defs>
          <path
            d="M10.625 1A4.388 4.388 0 0 1 15 5.375v1.75a4.388 4.388 0 0 1-4.375 4.375H9.75L5.375
                        15v-3.5A4.388 4.388 0 0 1 1 7.125v-1.75A4.388 4.388 0 0 1 5.375 1h5.25z"
            id="comment-a"
          />
        </defs>
        <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
          <mask id="comment-b" fill="#fff">
            <use xlinkHref="#comment-a" />
          </mask>
          <use fill="#0F1419" xlinkHref="#a" />
          <g mask="url(#comment-b)" fill="#201F23">
            <path d="M0 0h16v16H0z" />
          </g>
        </g>
      </Icon>
    );
  }
}

CommentIcon.propTypes = {
  className: PropTypes.string,
  withoutBackground: PropTypes.bool,
  inverted: PropTypes.bool,
};

CommentIcon.defaultProps = {
  className: null,
  withoutBackground: false,
  inverted: true,
};

export default CommentIcon;
