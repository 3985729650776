import StatsBar from "components/generic/StatsBar";
import * as PropTypes from "prop-types";
import React from "react";

const Card = ({ title, stats, picture }) => (
  <section className="portfoliocard">
    {/*
        We put the full title in the `title` attribute so that even if the normal
        text got cropped it's possible to hover and see the original.
        */}
    <h3 title={title}>{title}</h3>

    <StatsBar stats={stats} />

    <div className="portfoliocard--picture">{picture}</div>
  </section>
);

Card.propTypes = {
  title: PropTypes.node.isRequired,
  stats: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any).isRequired)
    .isRequired,
  picture: PropTypes.node.isRequired,
};

export default Card;
