import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { KeyboardDatePicker as DatePicker } from "@material-ui/pickers";
import {
  FieldArray,
  Form,
  Formik,
  FormikHelpers as FormikActions,
  FormikErrors,
  getIn,
} from "formik";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import * as yup from "yup";

import {
  DevelopmentStage,
  InstallationType,
  InverterInput,
  MountingType,
  OfftakerInput,
  PanelInput,
  PortfolioInput,
  PpaContractInput,
  ProjectStatus,
  ProjectType,
} from "~/api/types.generated";
import BannerHeader from "~/components/Banners/BannerHeader";
import {
  AddIcon,
  FileDocIcon,
  InfoIcon,
  InverterIcon,
  LocationIcon,
  PhoneEnabledIcon,
  PowerIcon,
  SettingsIcon,
  SolarPowerIcon,
  WorkOutlineIcon,
} from "~/components/common/icons";
import { StyledTextField } from "~/components/common/inputs";
import { PageContainer } from "~/components/common/layout";
import { CloseButton } from "~/components/generic/CloseButton";
import { useOrgPortfoliosQuery } from "~/components/HomePage/api/queries.generated";
import BlackBanner from "~/components/layout/BlackBanner";
import WhiteBox from "~/components/layout/WhiteBox";
import TabPanel from "~/components/menus/TabPanel";
import VerticalTab from "~/components/menus/VerticalTab";
import VerticalTabs from "~/components/menus/VerticalTabs";
import {
  useModuleModelsQuery,
  useOfftakersQuery,
} from "~/components/Projects/ProjectsAPI.generated";
import { bgColor, border, fgColor } from "~/styles/mixins";
import { gray300 } from "~/styles/theme/color";

const validationSchema = yup
  .object()
  .shape({
    name: yup.string().required("This field is required"),
    status: yup
      .mixed<ProjectStatus>()
      .oneOf(Object.values(ProjectStatus))
      .required(),
    projectType: yup
      .mixed<ProjectType>()
      .oneOf(Object.values(ProjectType))
      .required(),
    developmentStage: yup
      .mixed<DevelopmentStage>()
      .oneOf(Object.values(DevelopmentStage)),
    isOwned: yup.boolean(),
    noticeToProceed: yup
      .date()
      .nullable(true)
      .typeError("Please enter a valid date."),
    commercialOperatingDate: yup
      .date()
      .nullable(true)
      .typeError("Please enter a valid date."),
    permissionToOperateDate: yup
      .date()
      .nullable(true)
      .typeError("Please enter a valid date."),
    acquisitionDate: yup
      .date()
      .nullable(true)
      .typeError("Please enter a valid date."),
    capacity: yup.number().nullable(true),
    capacityAc: yup.number().nullable(true),
    location: yup
      .object()
      .shape({
        address: yup.string(),
        city: yup.string(),
        state: yup.string(),
        zipCode: yup.string().length(5).trim().nullable(true),
        latitude: yup
          .number()
          .typeError("Please enter a number.")
          .min(-90, "Must be within -90 and +90")
          .max(90, "Must be within -90 and +90")
          .nullable(true),
        longitude: yup
          .number()
          .typeError("Please enter a number.")
          .min(-180, "Must be within -180 and +180")
          .max(180, "Must be within -180 and +180")
          .nullable(true),
      })
      .required(),
    system: yup
      .object()
      .shape({
        bessKw: yup.number().typeError("Please enter a number.").nullable(true),
        bessKwh: yup
          .number()
          .typeError("Please enter a number.")
          .nullable(true),
        panelWarranty: yup
          .number()
          .typeError("Please enter a number.")
          .nullable(true),
        inverterWarranty: yup
          .number()
          .typeError("Please enter a number.")
          .nullable(true),
        contractorWarranty: yup
          .number()
          .typeError("Please enter a number.")
          .nullable(true),
        installation: yup
          .mixed<InstallationType>()
          .oneOf(Object.values(InstallationType)),
        mounting: yup.mixed<MountingType>().oneOf(Object.values(MountingType)),
      })
      .required(),
    contact: yup
      .object()
      .shape({
        phoneNumber: yup.string(),
        balancingAuthorityAreaName: yup.string(),
      })
      .required(),
    panels: yup.array().of(
      yup.object().shape({
        modelId: yup
          .string()
          .trim()
          .typeError("Please select a model.")
          .required("Please select a model."),
        count: yup
          .number()
          .typeError("Please enter a number.")
          .min(0, "Must be one or more"),
      })
    ),
    inverters: yup.array().of(
      yup.object().shape({
        modelId: yup
          .string()
          .trim()
          .typeError("Please select a model.")
          .required("Please select a model."),
        count: yup
          .number()
          .nullable()
          .typeError("Please enter a number.")
          .min(1, "Must be one or more"),
      })
    ),
    ppaContracts: yup.array().of(
      yup.object().shape({
        startDate: yup
          .date()
          .nullable(true)
          .typeError("Please enter a valid date."),
        term: yup.number().typeError("Please enter a number."), // (years)
        rate: yup.number().typeError("Please enter a number."), // ($/kWh)
        escalator: yup
          .number()
          .typeError("Please enter a valid decimal.")
          .min(0),
      })
    ),
    offtakers: yup.array().of(
      yup.object().shape({
        id: yup
          .string()
          .trim()
          .typeError("Please select an offtaker.")
          .required("Please select an offtaker."),
      })
    ),
    portfolios: yup.array().of(
      yup.object().shape({
        id: yup
          .string()
          .trim()
          .typeError("Please select a portfolio.")
          .required("Please select a portfolio."),
      })
    ),
  })
  .required();

export type ProjectValues = yup.InferType<typeof validationSchema>;

interface ProjectFormsProps {
  initialValues: ProjectValues;
  orgSlug: string;
  allowPortfolioChanges: boolean;
  onSubmit: (values: ProjectValues) => Promise<any>;
}

const ProjectForms = ({
  initialValues,
  orgSlug,
  allowPortfolioChanges,
  onSubmit,
}: ProjectFormsProps) => {
  // TODO: let the higher level component determine what to call for mutations and redirects
  // AKA move history/mutation up a level
  const [tabValue, setTabValue] = useState<number>(0);
  // TODO: use an array to store the tabs on the page and a map of the fields for each tab.
  const maxTabs = 8;
  const { data: moduleData } = useModuleModelsQuery();
  const { data: offtakers } = useOfftakersQuery({ variables: { orgSlug } });
  const { data: portfolios, loading: portfoliosLoading } =
    useOrgPortfoliosQuery({
      variables: { orgSlug },
    });

  const handleSubmit = (values: ProjectValues, actions: FormikActions<any>) => {
    // TODO: handle updateProject logic and add redirects
    onSubmit(values)
      .then(() => actions.setSubmitting(false))
      .catch((error) => {
        // TODO: Error handling goes here
        console.log("Unknown error occurred: ", error.message);
        actions.setSubmitting(false);
      });
  };

  const submitButtonCluster = (saving: boolean = false) => (
    <SubmitButtonContainer>
      <ButtonCluster>
        <div>* Required</div>
        <PrimaryButton
          onClick={() => setTabValue(tabValue - 1)}
          disabled={saving || tabValue === 0}
        >
          Back
        </PrimaryButton>
        <PrimaryButton
          onClick={() => setTabValue(tabValue + 1)}
          disabled={saving || tabValue === maxTabs}
        >
          Next
        </PrimaryButton>
      </ButtonCluster>
      <ButtonCluster>
        <SecondaryButton
          disabled={saving}
          type="button"
          variant="outlined"
          href={`/${orgSlug}/projects`}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton disabled={saving} type="submit">
          Save
        </PrimaryButton>
      </ButtonCluster>
    </SubmitButtonContainer>
  );

  const getTabError = (errors: FormikErrors<ProjectValues>, index: number) => {
    if (index === 0)
      return (
        Boolean(errors.name) ||
        Boolean(errors.noticeToProceed) ||
        Boolean(errors.commercialOperatingDate) ||
        Boolean(errors.acquisitionDate) ||
        Boolean(errors.permissionToOperateDate) ||
        Boolean(errors.capacity) ||
        Boolean(errors.capacityAc)
      );
    if (index === 1)
      return (
        Boolean(getIn(errors, "location.address")) ||
        Boolean(getIn(errors, "location.city")) ||
        Boolean(getIn(errors, "location.state")) ||
        Boolean(getIn(errors, "location.zipCode")) ||
        Boolean(getIn(errors, "location.latitude")) ||
        Boolean(getIn(errors, "location.longitude"))
      );
    if (index === 2)
      return (
        Boolean(getIn(errors, "contact.phoneNumber")) ||
        Boolean(getIn(errors, "contact.balancingAuthorityAreaName"))
      );
    if (index === 3)
      return (
        Boolean(getIn(errors, "system.panelWarranty")) ||
        Boolean(getIn(errors, "system.inverterWarranty")) ||
        Boolean(getIn(errors, "system.contractorWarranty")) ||
        Boolean(getIn(errors, "system.installation")) ||
        Boolean(getIn(errors, "system.mounting"))
      );
    if (index === 4) return Boolean(getIn(errors, "panels"));
    if (index === 5) return Boolean(getIn(errors, "inverters"));
    if (index === 6) return Boolean(getIn(errors, "ppaContracts"));
    if (index === 7) return Boolean(getIn(errors, "offtakers"));
    if (index === 8) return Boolean(getIn(errors, "portfolios"));
    return false;
  };

  return (
    <PageContainer>
      <BlackBanner>
        <BannerHeader
          title={initialValues.name ? initialValues.name : "New Project"}
          collapsed={true}
          collapsable={false}
          setCollapsed={() => {}}
          showAdmin={false}
        />
      </BlackBanner>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {({
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          values,
          touched,
        }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <VerticalTabs
                value={tabValue}
                onChange={(_: object, value: number) => setTabValue(value)}
              >
                <VerticalTab
                  icon={<InfoIcon style={{ marginBottom: 0 }} />}
                  label="Info"
                  index={0}
                  error={getTabError(errors, 0)}
                />
                <VerticalTab
                  icon={<LocationIcon style={{ marginBottom: 0 }} />}
                  label="Location"
                  index={1}
                  error={getTabError(errors, 1)}
                />
                <VerticalTab
                  icon={<PhoneEnabledIcon style={{ marginBottom: 0 }} />}
                  label="Contact"
                  index={2}
                  error={getTabError(errors, 2)}
                />
                <VerticalTab
                  icon={<SettingsIcon style={{ marginBottom: 0 }} />}
                  label="System"
                  index={3}
                  error={getTabError(errors, 3)}
                />
                <VerticalTab
                  icon={<SolarPowerIcon style={{ marginBottom: 0 }} />}
                  label="Panels"
                  index={4}
                  error={getTabError(errors, 4)}
                />
                <VerticalTab
                  icon={<InverterIcon style={{ marginBottom: 0 }} />}
                  label="Inverters"
                  index={5}
                  error={getTabError(errors, 5)}
                />
                <VerticalTab
                  icon={
                    <Thumbnail as={FileDocIcon} style={{ marginBottom: 0 }} />
                  }
                  label="PPA Contracts"
                  index={6}
                  error={getTabError(errors, 6)}
                />
                <VerticalTab
                  icon={<PowerIcon style={{ marginBottom: 0 }} />}
                  label="Offtakers"
                  index={7}
                  error={getTabError(errors, 7)}
                />
                <VerticalTab
                  icon={<WorkOutlineIcon style={{ marginBottom: 0 }} />}
                  label="Deal Rooms"
                  index={8}
                  error={getTabError(errors, 8)}
                />
              </VerticalTabs>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormSection>
                <Form>
                  <TabPanel index={0} value={tabValue} className="">
                    <FormTitle>Info</FormTitle>
                    <Grid
                      container
                      direction={"column"}
                      spacing={7}
                      style={{ maxWidth: 584, minHeight: 500 }}
                    >
                      <Grid item>
                        <StyledTextField
                          id="name"
                          className="form-control"
                          name="name"
                          label="Project Name*"
                          variant="outlined"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="status"
                          className="form-control"
                          name="status"
                          select
                          defaultValue={ProjectStatus.Operating}
                          label="Project Status*"
                          variant="outlined"
                          value={values.status}
                          onChange={handleChange}
                          error={touched.status && Boolean(errors.status)}
                          helperText={touched.status && errors.status}
                        >
                          {Object.entries(ProjectStatus).map(([key, value]) => (
                            <MenuItem
                              selected={values.status === value}
                              key={key}
                              value={value}
                            >
                              {key}
                            </MenuItem>
                          ))}
                        </StyledTextField>
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="projectType"
                          className="form-control"
                          name="projectType"
                          select
                          defaultValue={ProjectType.Solar}
                          label="Project Type*"
                          variant="outlined"
                          value={values.projectType}
                          onChange={handleChange}
                          error={
                            touched.projectType && Boolean(errors.projectType)
                          }
                          helperText={touched.projectType && errors.projectType}
                        >
                          {Object.entries(ProjectType).map(([key, value]) => (
                            <MenuItem
                              selected={values.projectType === value}
                              key={key}
                              value={value}
                            >
                              {key}
                            </MenuItem>
                          ))}
                        </StyledTextField>
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="developmentStage"
                          className="form-control"
                          name="developmentStage"
                          select
                          defaultValue={DevelopmentStage.Complete}
                          label="Development Stage"
                          variant="outlined"
                          value={values.developmentStage}
                          onChange={handleChange}
                          error={
                            touched.developmentStage &&
                            Boolean(errors.developmentStage)
                          }
                          helperText={
                            touched.developmentStage && errors.developmentStage
                          }
                        >
                          {Object.entries(DevelopmentStage).map(
                            ([key, value]) => (
                              <MenuItem
                                selected={values.developmentStage === value}
                                key={key}
                                value={value}
                              >
                                {key}
                              </MenuItem>
                            )
                          )}
                        </StyledTextField>
                      </Grid>
                      <Grid item>
                        <DatePicker
                          onChange={(date: moment.Moment | null) =>
                            setFieldValue(
                              "noticeToProceed",
                              date?.format("YYYY-MM-DD"),
                              true
                            )
                          }
                          variant="inline"
                          inputVariant="outlined"
                          format="MM/DD/YYYY"
                          value={values.noticeToProceed}
                          error={
                            touched.noticeToProceed &&
                            Boolean(errors.noticeToProceed)
                          }
                          helperText={
                            touched.noticeToProceed && errors.noticeToProceed
                          }
                          className="form-control"
                          label="NTP Date"
                        />
                      </Grid>
                      <Grid item>
                        <DatePicker
                          onChange={(date: moment.Moment | null) =>
                            setFieldValue(
                              "commercialOperatingDate",
                              date?.format("YYYY-MM-DD"),
                              true
                            )
                          }
                          variant="inline"
                          inputVariant="outlined"
                          format="MM/DD/YYYY"
                          value={values.commercialOperatingDate}
                          error={
                            touched.commercialOperatingDate &&
                            Boolean(errors.commercialOperatingDate)
                          }
                          helperText={
                            touched.commercialOperatingDate &&
                            errors.commercialOperatingDate
                          }
                          className="form-control"
                          label="COD Date"
                        />
                      </Grid>
                      <Grid item>
                        <DatePicker
                          onChange={(date: moment.Moment | null) =>
                            setFieldValue(
                              "permissionToOperateDate",
                              date?.format("YYYY-MM-DD"),
                              true
                            )
                          }
                          variant="inline"
                          inputVariant="outlined"
                          format="MM/DD/YYYY"
                          value={values.permissionToOperateDate}
                          error={
                            touched.permissionToOperateDate &&
                            Boolean(errors.permissionToOperateDate)
                          }
                          helperText={
                            touched.permissionToOperateDate &&
                            errors.permissionToOperateDate
                          }
                          className="form-control"
                          label="Permission To Operate Date"
                        />
                      </Grid>
                      <Grid item>
                        <DatePicker
                          onChange={(date: moment.Moment | null) =>
                            setFieldValue(
                              "acquisitionDate",
                              date?.format("YYYY-MM-DD"),
                              true
                            )
                          }
                          variant="inline"
                          inputVariant="outlined"
                          format="MM/DD/YYYY"
                          value={values.acquisitionDate}
                          error={
                            touched.acquisitionDate &&
                            Boolean(errors.acquisitionDate)
                          }
                          helperText={
                            touched.acquisitionDate && errors.acquisitionDate
                          }
                          className="form-control"
                          label="Acquisition Date"
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="capacity"
                          className="form-control"
                          name="capacity"
                          label="System Capacity (kW, DC)"
                          variant="outlined"
                          value={values.capacity}
                          // TODO: undefined number values that get rendered after data load overlaps here.
                          //  Server side rendering will fix this
                          InputLabelProps={{ shrink: values.capacity }}
                          onChange={handleChange}
                          error={touched.capacity && Boolean(errors.capacity)}
                          helperText={touched.capacity && errors.capacity}
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="capacityAc"
                          className="form-control"
                          name="capacityAc"
                          label="System Capacity (kW, AC)"
                          variant="outlined"
                          value={values.capacityAc}
                          // TODO: undefined number values that get rendered after data load overlaps here.
                          //  Server side rendering will fix this
                          InputLabelProps={{ shrink: values.capacityAc }}
                          onChange={handleChange}
                          error={
                            touched.capacityAc && Boolean(errors.capacityAc)
                          }
                          helperText={touched.capacityAc && errors.capacityAc}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          label="Do you own this project?"
                          control={
                            <Checkbox
                              id="isOwned"
                              checked={values.isOwned}
                              onChange={handleChange}
                              inputProps={{ "aria-label": "Owned" }}
                              color="primary"
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                    {submitButtonCluster(isSubmitting)}
                  </TabPanel>
                  <TabPanel index={1} value={tabValue} className="">
                    <FormTitle>Project Location</FormTitle>
                    <Grid
                      container
                      direction={"column"}
                      spacing={7}
                      style={{ maxWidth: 584, minHeight: 500 }}
                    >
                      <Grid item>
                        <StyledTextField
                          id="location.address"
                          className="form-control"
                          name="location.address"
                          value={values.location.address}
                          label="Address"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            getIn(touched, "location.address") &&
                            Boolean(getIn(errors, "location.address"))
                          }
                          helperText={
                            getIn(touched, "location.address") &&
                            getIn(errors, "location.address")
                          }
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="location.city"
                          className="form-control"
                          name="location.city"
                          value={values.location.city}
                          label="City"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            getIn(touched, "location.city") &&
                            Boolean(getIn(errors, "location.city"))
                          }
                          helperText={
                            getIn(touched, "location.city") &&
                            getIn(errors, "location.city")
                          }
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="location.state"
                          className="form-control"
                          name="location.state"
                          value={values.location.state}
                          label="State"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            getIn(touched, "location.state") &&
                            Boolean(getIn(errors, "location.state"))
                          }
                          helperText={
                            getIn(touched, "location.state") &&
                            getIn(errors, "location.state")
                          }
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="location.zipCode"
                          className="form-control"
                          name="location.zipCode"
                          value={values.location.zipCode}
                          label="Zip Code"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            getIn(touched, "location.zipCode") &&
                            Boolean(getIn(errors, "location.zipCode"))
                          }
                          helperText={
                            getIn(touched, "location.zipCode") &&
                            getIn(errors, "location.zipCode")
                          }
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="location.latitude"
                          className="form-control"
                          name="location.latitude"
                          value={values.location.latitude}
                          label="Latitude"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            getIn(touched, "location.latitude") &&
                            Boolean(getIn(errors, "location.latitude"))
                          }
                          helperText={
                            getIn(touched, "location.latitude") &&
                            getIn(errors, "location.latitude")
                          }
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="location.longitude"
                          className="form-control"
                          name="location.longitude"
                          value={values.location.longitude}
                          label="Longitude"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            getIn(touched, "location.longitude") &&
                            Boolean(getIn(errors, "location.longitude"))
                          }
                          helperText={
                            getIn(touched, "location.longitude") &&
                            getIn(errors, "location.longitude")
                          }
                        />
                      </Grid>
                    </Grid>
                    {submitButtonCluster(isSubmitting)}
                  </TabPanel>
                  <TabPanel index={2} value={tabValue} className="">
                    <FormTitle>Project Contact</FormTitle>
                    <Grid
                      container
                      direction={"column"}
                      spacing={7}
                      style={{ maxWidth: 584, minHeight: 500 }}
                    >
                      <Grid item>
                        <StyledTextField
                          id="contact.phoneNumber"
                          className="form-control"
                          name="contact.phoneNumber"
                          value={values.contact.phoneNumber}
                          label="Phone Number"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            getIn(touched, "contact.phoneNumber") &&
                            Boolean(getIn(errors, "contact.phoneNumber"))
                          }
                          helperText={
                            getIn(touched, "contact.phoneNumber") &&
                            getIn(errors, "contact.phoneNumber")
                          }
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="contact.balancingAuthorityAreaName"
                          className="form-control"
                          name="contact.balancingAuthorityAreaName"
                          value={values.contact.balancingAuthorityAreaName}
                          label="Balancing Area Authority Name"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            getIn(
                              touched,
                              "contact.balancingAuthorityAreaName"
                            ) &&
                            Boolean(
                              getIn(
                                errors,
                                "contact.balancingAuthorityAreaName"
                              )
                            )
                          }
                          helperText={
                            getIn(
                              touched,
                              "contact.balancingAuthorityAreaName"
                            ) &&
                            getIn(errors, "contact.balancingAuthorityAreaName")
                          }
                        />
                      </Grid>
                    </Grid>
                    {submitButtonCluster(isSubmitting)}
                  </TabPanel>
                  <TabPanel index={3} value={tabValue} className="">
                    <FormTitle>Project System</FormTitle>
                    <Grid
                      container
                      direction={"column"}
                      spacing={7}
                      style={{ maxWidth: 584, minHeight: 500 }}
                    >
                      <Grid item>
                        <StyledTextField
                          id="bessKw"
                          className="form-control"
                          name="system.bessKw"
                          label="ESS (kW)"
                          variant="outlined"
                          value={values.system.bessKw}
                          // InputLabelProps={{ shrink: values.system.bessKw }}
                          onChange={handleChange}
                          error={
                            getIn(touched, "system.bessKw") &&
                            Boolean(getIn(errors, "system.bessKw"))
                          }
                          helperText={
                            getIn(touched, "system.bessKw") &&
                            getIn(errors, "system.bessKw")
                          }
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="bessKwh"
                          className="form-control"
                          name="system.bessKwh"
                          label="ESS (kWh)"
                          variant="outlined"
                          value={values.system.bessKwh}
                          // InputLabelProps={{ shrink: values.system.bessKwh }}
                          onChange={handleChange}
                          error={
                            getIn(touched, "system.bessKwh") &&
                            Boolean(getIn(errors, "system.bessKwh"))
                          }
                          helperText={
                            getIn(touched, "system.bessKwh") &&
                            getIn(errors, "system.bessKwh")
                          }
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="system.panelWarranty"
                          className="form-control"
                          name="system.panelWarranty"
                          value={values.system.panelWarranty ?? ""}
                          label="Panel Warranty (years)"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            getIn(touched, "system.panelWarranty") &&
                            Boolean(getIn(errors, "system.panelWarranty"))
                          }
                          helperText={
                            getIn(touched, "system.panelWarranty") &&
                            getIn(errors, "system.panelWarranty")
                          }
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="system.inverterWarranty"
                          className="form-control"
                          name="system.inverterWarranty"
                          value={values.system.inverterWarranty ?? ""}
                          label="Inverter Warranty (years)"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            getIn(touched, "system.inverterWarranty") &&
                            Boolean(getIn(errors, "system.inverterWarranty"))
                          }
                          helperText={
                            getIn(touched, "system.inverterWarranty") &&
                            getIn(errors, "system.inverterWarranty")
                          }
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="system.contractorWarranty"
                          className="form-control"
                          name="system.contractorWarranty"
                          value={values.system.contractorWarranty ?? ""}
                          label="Contractor Warranty (years)"
                          variant="outlined"
                          onChange={handleChange}
                          error={
                            getIn(touched, "system.contractorWarranty") &&
                            Boolean(getIn(errors, "system.contractorWarranty"))
                          }
                          helperText={
                            getIn(touched, "system.contractorWarranty") &&
                            getIn(errors, "system.contractorWarranty")
                          }
                        />
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="system.installation"
                          className="form-control"
                          name="system.installation"
                          select
                          label="Installation"
                          variant="outlined"
                          value={values.system.installation ?? ""}
                          onChange={handleChange}
                          error={
                            getIn(touched, "system.installation") &&
                            Boolean(getIn(errors, "system.installation"))
                          }
                          helperText={
                            getIn(touched, "system.installation") &&
                            getIn(errors, "system.installation")
                          }
                        >
                          <MenuItem
                            selected={values.system.installation === ""}
                            key=""
                            value=""
                          >
                            --
                          </MenuItem>
                          {Object.entries(InstallationType).map(
                            ([key, value]) => (
                              <MenuItem
                                selected={values.system.installation === value}
                                key={key}
                                value={value}
                              >
                                {key}
                              </MenuItem>
                            )
                          )}
                        </StyledTextField>
                      </Grid>
                      <Grid item>
                        <StyledTextField
                          id="system.mounting"
                          className="form-control"
                          name="system.mounting"
                          select
                          label="Mounting"
                          variant="outlined"
                          value={values.system.mounting ?? ""}
                          onChange={handleChange}
                          error={
                            getIn(touched, "system.mounting") &&
                            Boolean(getIn(errors, "system.mounting"))
                          }
                          helperText={
                            getIn(touched, "system.mounting") &&
                            getIn(errors, "system.mounting")
                          }
                        >
                          <MenuItem
                            selected={values.system.mounting === ""}
                            key=""
                            value=""
                          >
                            --
                          </MenuItem>
                          {Object.entries(MountingType).map(([key, value]) => (
                            <MenuItem
                              selected={values.system.mounting === value}
                              key={key}
                              value={value}
                            >
                              {key}
                            </MenuItem>
                          ))}
                        </StyledTextField>
                      </Grid>
                    </Grid>
                    {submitButtonCluster(isSubmitting)}
                  </TabPanel>
                  <TabPanel index={4} value={tabValue} className="">
                    <FormTitle>Project Panels</FormTitle>
                    <FieldArray
                      name="panels"
                      render={(arrayHelpers) => (
                        <Grid
                          container
                          direction={"column"}
                          spacing={7}
                          style={{ minHeight: 500 }}
                        >
                          {values.panels.length > 0 &&
                            values.panels.map(
                              (panel: PanelInput, index: number) => (
                                <Grid item key={`panels-${index}`}>
                                  <Grid container spacing={2}>
                                    <Grid item style={{ minWidth: 400 }}>
                                      <StyledTextField
                                        defaultValue={panel.modelId}
                                        name={`panels.${index}.modelId`}
                                        className="form-control"
                                        label="Model"
                                        variant="outlined"
                                        select
                                        onChange={handleChange}
                                        error={
                                          touched.panels &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `panels.${index}.modelId`
                                            )
                                          ) &&
                                          Boolean(
                                            getIn(
                                              errors,
                                              `panels.${index}.modelId`
                                            )
                                          )
                                        }
                                        helperText={
                                          touched.panels &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `panels.${index}.modelId`
                                            )
                                          ) &&
                                          getIn(
                                            errors,
                                            `panels.${index}.modelId`
                                          )
                                        }
                                      >
                                        {moduleData?.panelModels?.map(
                                          (panelModel) => (
                                            <MenuItem
                                              selected={
                                                panel.modelId === panelModel.id
                                              }
                                              key={`${panelModel.manufacturer.name}: ${panelModel.modelNumber}`}
                                              value={panelModel.id}
                                            >{`${panelModel.manufacturer.name}: ${panelModel.modelNumber}`}</MenuItem>
                                          )
                                        )}
                                      </StyledTextField>
                                    </Grid>
                                    <Grid item>
                                      <StyledTextField
                                        name={`panels.${index}.count`}
                                        value={panel.count}
                                        className="form-control"
                                        label="Count"
                                        variant="outlined"
                                        onChange={handleChange}
                                        error={
                                          touched.panels &&
                                          Boolean(
                                            getIn(
                                              errors,
                                              `panels.${index}.count`
                                            )
                                          )
                                        }
                                        helperText={
                                          touched.panels &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `panels.${index}.count`
                                            )
                                          ) &&
                                          getIn(errors, `panels.${index}.count`)
                                        }
                                      />
                                    </Grid>
                                    <Grid item>
                                      <CloseButton
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          <Grid item>
                            <SecondaryButton
                              onClick={() => {
                                arrayHelpers.push({
                                  modelId: null,
                                  count: "",
                                });
                              }}
                            >
                              <AddIcon />
                              <span>Add another panel</span>
                            </SecondaryButton>
                          </Grid>
                        </Grid>
                      )}
                    />
                    {submitButtonCluster(isSubmitting)}
                  </TabPanel>
                  <TabPanel index={5} value={tabValue} className="">
                    <FormTitle>Project Inverters</FormTitle>
                    <FieldArray
                      name="inverters"
                      render={(arrayHelpers) => (
                        <Grid
                          container
                          direction={"column"}
                          spacing={7}
                          style={{ minHeight: 500 }}
                        >
                          {values.inverters.length > 0 &&
                            values.inverters.map(
                              (inverter: InverterInput, index: number) => (
                                <Grid item key={`inverters-${index}`}>
                                  <Grid container spacing={2}>
                                    <Grid item style={{ minWidth: 400 }}>
                                      <StyledTextField
                                        defaultValue={inverter.modelId}
                                        name={`inverters.${index}.modelId`}
                                        className="form-control"
                                        label="Model"
                                        variant="outlined"
                                        select
                                        onChange={handleChange}
                                        error={
                                          touched.inverters &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `inverters.${index}.modelId`
                                            )
                                          ) &&
                                          Boolean(
                                            getIn(
                                              errors,
                                              `inverters.${index}.modelId`
                                            )
                                          )
                                        }
                                        helperText={
                                          touched.inverters &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `inverters.${index}.modelId`
                                            )
                                          ) &&
                                          getIn(
                                            errors,
                                            `inverters.${index}.modelId`
                                          )
                                        }
                                      >
                                        {moduleData?.inverterModels?.map(
                                          (inverterModel) => (
                                            <MenuItem
                                              selected={
                                                inverter.modelId ===
                                                inverterModel.id
                                              }
                                              key={`${inverterModel.manufacturer.name}: ${inverterModel.modelNumber}`}
                                              value={inverterModel.id}
                                            >{`${inverterModel.manufacturer.name}: ${inverterModel.modelNumber}`}</MenuItem>
                                          )
                                        )}
                                      </StyledTextField>
                                    </Grid>
                                    <Grid item>
                                      <StyledTextField
                                        name={`inverters.${index}.count`}
                                        value={inverter.count}
                                        className="form-control"
                                        label="Count"
                                        variant="outlined"
                                        onChange={handleChange}
                                        error={
                                          touched.inverters &&
                                          Boolean(
                                            getIn(
                                              errors,
                                              `inverters.${index}.count`
                                            )
                                          )
                                        }
                                        helperText={
                                          touched.inverters &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `inverters.${index}.count`
                                            )
                                          ) &&
                                          getIn(
                                            errors,
                                            `inverters.${index}.count`
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item>
                                      <CloseButton
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          <Grid item>
                            <SecondaryButton
                              onClick={() => {
                                arrayHelpers.push({
                                  modelId: null,
                                  count: "",
                                });
                              }}
                            >
                              <AddIcon />
                              <span>Add another inverter</span>
                            </SecondaryButton>
                          </Grid>
                        </Grid>
                      )}
                    />
                    {submitButtonCluster(isSubmitting)}
                  </TabPanel>
                  <TabPanel index={6} value={tabValue} className="">
                    <FormTitle>Project PPA Contracts</FormTitle>
                    <FieldArray
                      name="ppaContracts"
                      render={(arrayHelpers) => (
                        <Grid
                          container
                          direction={"column"}
                          spacing={7}
                          style={{ minHeight: 500 }}
                        >
                          {values.ppaContracts.length > 0 &&
                            values.ppaContracts.map(
                              (
                                ppaContract: PpaContractInput,
                                index: number
                              ) => (
                                <Grid item key={`ppa-contract-${index}`}>
                                  <Grid container spacing={2}>
                                    <Grid item>
                                      <DatePicker
                                        onChange={(
                                          date: moment.Moment | null
                                        ) =>
                                          setFieldValue(
                                            `ppaContracts.${index}.startDate`,
                                            date?.format("YYYY-MM-DD"),
                                            true
                                          )
                                        }
                                        value={getIn(
                                          values,
                                          `ppaContracts.${index}.startDate`
                                        )}
                                        className="form-control"
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="MM/DD/YYYY"
                                        label="Start Date"
                                        error={
                                          getIn(
                                            touched,
                                            `ppaContracts.${index}.startDate`
                                          ) &&
                                          Boolean(
                                            getIn(
                                              errors,
                                              `ppaContracts.${index}.startDate`
                                            )
                                          )
                                        }
                                        helperText={
                                          getIn(
                                            touched,
                                            `ppaContracts.${index}.startDate`
                                          ) &&
                                          getIn(
                                            errors,
                                            `ppaContracts.${index}.startDate`
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item>
                                      <StyledTextField
                                        name={`ppaContracts.${index}.term`}
                                        value={ppaContract.term}
                                        className="form-control"
                                        label="Term (in years)"
                                        variant="outlined"
                                        onChange={handleChange}
                                        error={
                                          touched.ppaContracts &&
                                          Boolean(
                                            getIn(
                                              errors,
                                              `ppaContracts.${index}.term`
                                            )
                                          )
                                        }
                                        helperText={
                                          touched.ppaContracts &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `ppaContracts.${index}.term`
                                            )
                                          ) &&
                                          getIn(
                                            errors,
                                            `ppaContracts.${index}.term`
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item>
                                      <StyledTextField
                                        name={`ppaContracts.${index}.rate`}
                                        value={ppaContract.rate}
                                        className="form-control"
                                        label="PPA Rate ($/kWh)"
                                        variant="outlined"
                                        onChange={handleChange}
                                        error={
                                          touched.ppaContracts &&
                                          Boolean(
                                            getIn(
                                              errors,
                                              `ppaContracts.${index}.rate`
                                            )
                                          )
                                        }
                                        helperText={
                                          touched.ppaContracts &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `ppaContracts.${index}.rate`
                                            )
                                          ) &&
                                          getIn(
                                            errors,
                                            `ppaContracts.${index}.rate`
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item>
                                      <StyledTextField
                                        name={`ppaContracts.${index}.escalator`}
                                        value={ppaContract.escalator}
                                        className="form-control"
                                        label="PPA Escalator (%)"
                                        variant="outlined"
                                        onChange={handleChange}
                                        error={
                                          touched.ppaContracts &&
                                          Boolean(
                                            getIn(
                                              errors,
                                              `ppaContracts.${index}.escalator`
                                            )
                                          )
                                        }
                                        helperText={
                                          touched.ppaContracts &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `ppaContracts.${index}.escalator`
                                            )
                                          ) &&
                                          getIn(
                                            errors,
                                            `ppaContracts.${index}.escalator`
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item>
                                      <CloseButton
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          <Grid item>
                            <SecondaryButton
                              onClick={() => {
                                arrayHelpers.push({
                                  startDate: null,
                                  term: null,
                                  rate: null,
                                  escalator: null,
                                });
                              }}
                            >
                              <AddIcon />
                              <span>Add another PPA</span>
                            </SecondaryButton>
                          </Grid>
                        </Grid>
                      )}
                    />
                    {submitButtonCluster(isSubmitting)}
                  </TabPanel>
                  <TabPanel index={7} value={tabValue} className="">
                    <FormTitle>Project Offtakers</FormTitle>
                    <FieldArray
                      name="offtakers"
                      render={(arrayHelpers) => (
                        <Grid
                          container
                          direction={"column"}
                          spacing={7}
                          style={{ minHeight: 500 }}
                        >
                          {values.offtakers.length > 0 &&
                            values.offtakers.map(
                              (o: OfftakerInput, index: number) => (
                                <Grid item key={`offtakers-${index}`}>
                                  <Grid container spacing={2}>
                                    <Grid item style={{ minWidth: 400 }}>
                                      <StyledTextField
                                        defaultValue={o.id}
                                        name={`offtakers.${index}.id`}
                                        className="form-control"
                                        label="Offtaker"
                                        variant="outlined"
                                        select
                                        onChange={handleChange}
                                        error={
                                          touched.offtakers &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `offtakers.${index}.id`
                                            )
                                          ) &&
                                          Boolean(
                                            getIn(
                                              errors,
                                              `offtakers.${index}.id`
                                            )
                                          )
                                        }
                                        helperText={
                                          touched.offtakers &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `offtakers.${index}.id`
                                            )
                                          ) &&
                                          getIn(errors, `offtakers.${index}.id`)
                                        }
                                      >
                                        {offtakers?.offtakers?.map(
                                          ({ id, name }) => (
                                            <MenuItem
                                              selected={o.id === id}
                                              key={`${name}`}
                                              value={id}
                                            >{`${name}`}</MenuItem>
                                          )
                                        )}
                                      </StyledTextField>
                                    </Grid>
                                    <Grid item>
                                      <CloseButton
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          <Grid item>
                            <SecondaryButton
                              onClick={() => {
                                arrayHelpers.push({
                                  id: null,
                                });
                              }}
                            >
                              <AddIcon />
                              <span>Add another offtaker</span>
                            </SecondaryButton>
                          </Grid>
                        </Grid>
                      )}
                    />
                    {submitButtonCluster(isSubmitting)}
                  </TabPanel>
                  <TabPanel index={8} value={tabValue} className="">
                    <FormTitle>Deal Rooms</FormTitle>
                    <FieldArray
                      name="portfolios"
                      render={(arrayHelpers) => (
                        <Grid
                          container
                          direction={"column"}
                          spacing={7}
                          style={{ minHeight: 500 }}
                        >
                          {values.portfolios.length > 0 &&
                            values.portfolios.map(
                              (p: PortfolioInput, index: number) => (
                                <Grid item key={`portfolios-${index}`}>
                                  <Grid container spacing={2}>
                                    <Grid item style={{ minWidth: 400 }}>
                                      <StyledTextField
                                        defaultValue={p.id}
                                        disabled={
                                          portfoliosLoading ||
                                          !allowPortfolioChanges
                                        }
                                        name={`portfolios.${index}.id`}
                                        className="form-control"
                                        label="Portfolio"
                                        variant="outlined"
                                        select
                                        onChange={handleChange}
                                        error={
                                          touched.offtakers &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `portfolios.${index}.id`
                                            )
                                          ) &&
                                          Boolean(
                                            getIn(
                                              errors,
                                              `portfolios.${index}.id`
                                            )
                                          )
                                        }
                                        helperText={
                                          touched.offtakers &&
                                          Boolean(
                                            getIn(
                                              touched,
                                              `portfolios.${index}.id`
                                            )
                                          ) &&
                                          getIn(
                                            errors,
                                            `portfolios.${index}.id`
                                          )
                                        }
                                      >
                                        {portfolios?.portfolios?.map(
                                          ({ id, name }) => (
                                            <MenuItem
                                              selected={p.id === id}
                                              key={`${name}`}
                                              value={id}
                                            >{`${name}`}</MenuItem>
                                          )
                                        )}
                                      </StyledTextField>
                                    </Grid>
                                    {allowPortfolioChanges && (
                                      <Grid item>
                                        <CloseButton
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              )
                            )}
                          <Grid item>
                            <SecondaryButton
                              onClick={() => {
                                arrayHelpers.push({
                                  id: null,
                                });
                              }}
                              disabled={!allowPortfolioChanges}
                            >
                              <AddIcon />
                              <span>Add Deal Room</span>
                            </SecondaryButton>
                          </Grid>
                        </Grid>
                      )}
                    />
                    {submitButtonCluster(isSubmitting)}
                  </TabPanel>
                </Form>
              </FormSection>
            </Grid>
          </Grid>
        )}
      </Formik>
    </PageContainer>
  );
};

const FormTitle = styled.div`
  color: #231f20;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  padding-bottom: 24px;
  padding-bottom: 24px;
`;

const FormSection = styled(WhiteBox)`
  margin-left: 24px;
  padding-bottom: 24px;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  padding-top: 24px;
  border-top: 1px solid;
  border-color: ${gray300};
`;

const ButtonCluster = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 24px;
`;

const PrimaryButton = styled(Button)`
  && {
    &.Mui-disabled {
      ${bgColor.gray100()};
      ${border.gray100()};
    }

    text-transform: none;
    ${bgColor.blue()};
    ${fgColor.white()};
    ${border.blue()};

    &.active,
    &:hover {
      ${bgColor.darkBlue()};
      ${fgColor.white()};
    }
  }
`;

const SecondaryButton = styled(Button)`
  && {
    text-transform: none;
  }
`;

const Thumbnail = styled.span`
  width: 1.5em;
  height: 1.5em;
`;

export default ProjectForms;
