import { gql } from "@apollo/client";
import { trim } from "lodash";
import React, { useCallback, useContext, useState } from "react";

import { ChecklistItemStatus } from "~/api/types.generated";
import {
  useUpdateChecklistItemStatusMutation,
  useUpdateChecklistItemUserMutation,
} from "~/components/Checklist/ChecklistItemModal.generated";
import ChecklistContext from "~/components/Checklist/context/ChecklistContext";
import ChecklistFiltersContext from "~/components/Checklist/context/ChecklistFiltersContext";

import ChecklistModal from "./modals/ChecklistModal";

export const UPDATE_CHECKLIST_ITEM_MUTATION_FRAGMENT = gql`
  mutation UpdateChecklistItemStatus($id: ID!, $status: ChecklistItemStatus) {
    updateChecklistItem(id: $id, status: $status) {
      id
      status
    }
  }

  mutation UpdateChecklistItemUser($id: ID!, $userId: ID!) {
    updateChecklistItem(id: $id, userId: $userId) {
      id
      userId
    }
  }
`;

interface Fields {
  status?: ChecklistItemStatus;
  userId?: string;
  link?: string;
}

export const ChecklistItemModal = () => {
  const [updateUser, { loading: userLoading }] =
    useUpdateChecklistItemUserMutation();
  const [updateStatus, { loading: statusLoading }] =
    useUpdateChecklistItemStatusMutation();
  const [error, setError] = useState("");
  const checklistContext = useContext(ChecklistContext);
  const checklistFilters = useContext(ChecklistFiltersContext);
  const activeChecklistItem = checklistContext.getSelectedItem();
  const activeCategory = activeChecklistItem
    ? checklistContext.visibleCategories.find(
        (category) => category.id === activeChecklistItem.categoryId
      )
    : null;
  const activeProject = activeChecklistItem
    ? checklistContext.visibleProjects.find(
        (project) => project.id === activeChecklistItem.projectId
      )
    : null;

  const handleUpdateChecklistItem = useCallback(
    async (itemId: string, fields: Fields) => {
      console.log("handleUpdateChecklistItem with: " + Object.entries(fields));
      if (fields.status) {
        try {
          await updateStatus({
            variables: { id: itemId, status: fields.status },
          });
        } catch (error) {
          setError(trim(error.message, "'"));
        }
      }
      if (fields.userId) {
        try {
          await updateUser({
            variables: { id: itemId, userId: fields.userId },
          });
        } catch (error) {
          setError(trim(error.message, "'"));
        }
      }
    },
    [updateStatus, updateUser]
  );

  return (
    <div data-testid="modals-wrapper">
      <ChecklistModal
        portfolioId={checklistContext.portfolioId}
        checklistId={checklistContext.selectedChecklistId}
        isOpen={!!checklistContext.selectedChecklistItemId}
        activeChecklistItem={checklistContext.getSelectedItem()}
        activeCategory={activeCategory}
        categoryName={activeCategory?.name ?? ""}
        activeProject={activeProject}
        users={checklistFilters.usersById}
        itemsLoading={checklistContext.loading || userLoading || statusLoading}
        itemsError={error}
        hideModal={() => checklistContext.setSelectedChecklistItemId(null)}
        moveActiveChecklistItem={checklistContext.moveSelectedChecklistItem}
        updateChecklistItem={handleUpdateChecklistItem}
      />
    </div>
  );
};
