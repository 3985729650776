import React, {
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from "react";
import styled from "styled-components";

import { bgColor, fgColor } from "~/styles/mixins";

import ActionMenuContext from "./ActionMenuContext";
import DropdownContext from "./DropdownContext";

export interface ActionMenuItemProps {
  children?: ReactNode;
  className?: string;
  index?: number;
  onSelect: (event: MouseEvent | KeyboardEvent) => unknown;
}

const ActionMenuItem = ({
  children,
  index = -1,
  onSelect,
}: ActionMenuItemProps) => {
  const actionMenu = useContext(ActionMenuContext);
  const dropdown = useContext(DropdownContext);
  const ref = useRef<HTMLLIElement>(null);

  // Synchronize the menu focus state to the browser focus state
  useEffect(() => {
    if (
      ref.current &&
      dropdown?.isOpen &&
      dropdown?.transitionState?.phase !== "leave" &&
      actionMenu.focusIndex === index
    ) {
      ref.current.focus();
    }
  }, [
    index,
    dropdown?.isOpen,
    dropdown?.transitionState?.phase,
    actionMenu.focusIndex,
  ]);

  const handleSelect = (event: MouseEvent | KeyboardEvent) => {
    if (actionMenu.closeOnSelect) dropdown?.close();
    onSelect(event);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case "Enter":
      case " ":
        event.preventDefault();
        handleSelect(event);
        break;

      case "Escape":
      case "Tab": {
        if (dropdown) {
          event.preventDefault();
          dropdown.close();
        }
        break;
      }

      default:
        break;
    }
  };

  const handleMouseEnter = () => {
    actionMenu.setFocusIndex(index);
  };

  return (
    <Item
      onClick={handleSelect}
      onKeyDown={handleKeyDown}
      onMouseEnter={handleMouseEnter}
      ref={ref}
    >
      {children}
    </Item>
  );
};

const Item = styled.li.attrs({
  role: "menuitem",
  tabIndex: -1,
})`
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 40px;
  padding: 0 8px;

  &:not([disabled]) {
    cursor: pointer;
  }

  &:focus {
    ${bgColor.primary()};
    ${fgColor.white()};
  }
`;

export default ActionMenuItem;
