// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type OrgRoleQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  roleId: Types.Scalars['ID'];
}>;


export type OrgRoleQuery = (
  { readonly __typename: 'Query' }
  & { readonly role?: Types.Maybe<(
    { readonly __typename: 'Role' }
    & Pick<Types.Role, 'id' | 'name'>
    & { readonly permissions?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'Permission' }
      & Pick<Types.Permission, 'id' | 'codename'>
    )>> }
  )> }
);


export const OrgRoleDocument = gql`
    query OrgRole($orgSlug: String!, $roleId: ID!) {
  role(orgSlug: $orgSlug, roleId: $roleId) {
    id
    name
    permissions {
      id
      codename
    }
  }
}
    `;

/**
 * __useOrgRoleQuery__
 *
 * To run a query within a React component, call `useOrgRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgRoleQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useOrgRoleQuery(baseOptions: Apollo.QueryHookOptions<OrgRoleQuery, OrgRoleQueryVariables>) {
        return Apollo.useQuery<OrgRoleQuery, OrgRoleQueryVariables>(OrgRoleDocument, baseOptions);
      }
export function useOrgRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgRoleQuery, OrgRoleQueryVariables>) {
          return Apollo.useLazyQuery<OrgRoleQuery, OrgRoleQueryVariables>(OrgRoleDocument, baseOptions);
        }
export type OrgRoleQueryHookResult = ReturnType<typeof useOrgRoleQuery>;
export type OrgRoleLazyQueryHookResult = ReturnType<typeof useOrgRoleLazyQuery>;
export type OrgRoleQueryResult = Apollo.QueryResult<OrgRoleQuery, OrgRoleQueryVariables>;