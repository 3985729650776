import { flatten } from "lodash";
import { ellipsis } from "polished";
import React, { useCallback, useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import styled from "styled-components";

import { PrimaryButton } from "~/components/common/buttons";
import { FileFolderIcon, FileIcon } from "~/components/common/icons";
import { CircleProgress } from "~/components/common/progress/CircleProgress";
import { Text } from "~/components/common/text";
import { Thumbnail } from "~/components/Documents/DocumentsThumbnail";
import { AlertIcon, ClockIcon, OkIcon } from "~/components/icons";
import { Window } from "~/components/Window";
import { cyan, gray100, gray300, gray500 } from "~/styles/theme/color";
import { borderRadius } from "~/styles/theme/common";
import * as rect from "~/utils/rect";
import * as vec2 from "~/utils/vec2";

import { useDocumentsUpload } from "./DocumentsUploadProvider";
import { Status } from "./useUploadManager";

const getBounds = () =>
  rect.create(
    vec2.create(12, 66),
    vec2.create(window.innerWidth - 12, window.innerHeight - 12)
  );

const getInitialPosition = (bounds: rect.Rect) =>
  rect.create(vec2.subtract(bounds.max, vec2.create(640, 480)), bounds.max);

const constraints = rect.create(vec2.create(386, 150), vec2.create(720, 560));

export const DocumentsUploadDialog = () => {
  const [bounds, setBounds] = useState(getBounds);
  const [initialPosition] = useState(() => getInitialPosition(bounds));

  useEffect(() => {
    const handleViewportResize = () => setBounds(getBounds);
    window.addEventListener("resize", handleViewportResize);
    return () => window.removeEventListener("resize", handleViewportResize);
  }, []);

  const manager = useDocumentsUpload();

  const tasks = flatten(
    [...manager.complete, ...manager.queued].map((batch) =>
      batch.tasks.map((path) => manager.tasks[path])
    )
  );

  const totalSize = tasks.reduce((total, task) => total + task.file.size, 0);
  const completedSize = tasks.reduce(
    (completed, task) =>
      completed + (task.file.size > 0 ? task.progress * task.file.size : 0),
    0
  );
  const totalProgress = totalSize === 0 ? 0 : completedSize / totalSize;

  const totalUploads = tasks.length;
  const remainingUploads = tasks.filter(
    (t) =>
      t.status === Status.WAITING ||
      t.status === Status.UPLOADING ||
      t.status === Status.CONFIRMING
  ).length;

  const onClose = useCallback(() => {
    manager.close();
  }, [manager]);

  useEffect(() => {
    if (manager.busy) {
      const beforeUnloadListener = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        return (event.returnValue =
          "Are you sure you want to leave? The current upload will be canceled.");
      };

      window.addEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });

      return () => {
        window.removeEventListener("beforeunload", beforeUnloadListener, {
          capture: true,
        });
      };
    }
    return () => {};
  }, [manager.busy]);

  if (!manager.open) {
    return null;
  }

  return (
    <Window
      title={
        manager.busy ? (
          <>
            <span className="mr-2">Uploading...</span>{" "}
            <CircleProgress
              percent={totalProgress * 100}
              color={cyan}
              trackColor="rgba(255, 255, 255, 0.5)"
              size={20}
            />
            <span className="ml-2" style={{ color: cyan }}>
              {Math.floor(totalProgress * 100)}%
            </span>
          </>
        ) : (
          <>
            <span className="mr-2">Done!</span> <OkIcon withoutBackground />
          </>
        )
      }
      initialPosition={initialPosition}
      bounds={bounds}
      constraints={constraints}
      canClose={!manager.busy}
      onClose={onClose}
    >
      <UploadControls>
        <Text>
          {remainingUploads} of {totalUploads} items remaining
        </Text>
        <PrimaryButton
          onClick={() => manager.cancel()}
          disabled={!manager.busy}
        >
          Cancel Upload
        </PrimaryButton>
      </UploadControls>
      <Table>
        <div className="header-row">
          <div className="header thumbnail-cell"></div>
          <div className="header name-cell">Name</div>
          <div className="header source-cell">Destination</div>
          <div className="header status-cell">Status</div>
        </div>
        <div className="body">
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemCount={tasks.length}
                itemSize={40}
              >
                {({ index, style }) => {
                  const task = tasks[index];
                  return (
                    <div className="row" key={index} style={style as any}>
                      <div className="cell thumbnail-cell">
                        {task.file.isDirectory ? (
                          <FileThumbnail as={FileFolderIcon} />
                        ) : (
                          <FileThumbnail as={FileIcon} />
                        )}
                      </div>
                      <div className="cell name-cell">
                        <span>{task.file.name}</span>
                      </div>
                      <div className="cell source-cell">
                        <span>{task.treeName}</span>
                      </div>
                      <div className="cell status-cell">
                        {task.status === Status.WAITING && (
                          <>
                            <ClockIcon
                              color="arctic-blue"
                              withoutBackground
                              className="mr-2"
                            />
                            Waiting
                          </>
                        )}
                        {task.status === Status.UPLOADING && (
                          <>
                            <CircleProgress percent={task.progress * 100} />
                            <span className="ml-2">Uploading...</span>
                          </>
                        )}
                        {task.status === Status.CONFIRMING && (
                          <>
                            <CircleProgress percent={100} />
                            <span className="ml-2">Confirming...</span>
                          </>
                        )}
                        {task.status === Status.DONE && (
                          <>
                            <OkIcon withoutBackground className="mr-2" />
                            Done
                          </>
                        )}
                        {task.status === Status.UNCONFIRMED && (
                          <>
                            <AlertIcon
                              color="warning"
                              withoutBackground
                              className="mr-2"
                            />
                            Unconfirmed
                          </>
                        )}
                        {task.status === Status.FAILED && (
                          <>
                            <AlertIcon
                              color="danger"
                              withoutBackground
                              className="mr-2"
                            />
                            Failed
                          </>
                        )}
                        {task.status === Status.CANCELED && (
                          <>
                            <AlertIcon withoutBackground className="mr-2" />
                            Canceled
                          </>
                        )}
                      </div>
                    </div>
                  );
                }}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      </Table>
    </Window>
  );
};

const FileThumbnail = styled(Thumbnail)`
  color: ${gray500};
  width: 1rem;
  height: 1rem;
  display: block;
  flex: 1 1 auto;
`;

const UploadControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;

  ${Text} {
    margin: 0;
  }

  ${PrimaryButton} {
    flex 0 0 auto;
  }
`;

const Table = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid ${gray300};
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  overflow: hidden;
  font-size: 0.875rem;

  .body {
    flex: 1 1 auto;
    overflow: hidden;
    width: 100%;
  }

  .header-row {
    background-color: ${gray100};
    font-weight: 700;
  }

  .header-row,
  .row {
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 1px solid ${gray300};
  }

  .header,
  .cell {
    height: 2.5rem;
    display: flex;
    align-items: center;
  }

  .thumbnail-cell {
    width: 2.5rem;
    flex: 0 0 2.5rem;
  }

  .name-cell {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    > span {
      ${ellipsis()};
    }
  }

  .source-cell {
    width: 10rem;
    flex: 0 0 10rem;
    overflow: hidden;
    > span {
      ${ellipsis()};
    }
  }

  .status-cell {
    width: 8rem;
    flex: 0 0 8rem;
  }
`;
