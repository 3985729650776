// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type ReportingBannerProjectsStatsFragment = (
  { readonly __typename: 'ProjectsStats' }
  & Pick<Types.ProjectsStats, 'count' | 'totalCapacity'>
);

export type ReportingBannerOperatingStatsFragment = (
  { readonly __typename: 'OperatingStats' }
  & Pick<Types.OperatingStats, 'startDate' | 'actualRevenue' | 'actualExpenses' | 'actualProduction' | 'forecastRevenue' | 'forecastExpenses' | 'forecastProduction'>
);

export const ReportingBannerProjectsStatsFragmentDoc = gql`
    fragment ReportingBannerProjectsStats on ProjectsStats {
  count
  totalCapacity
}
    `;
export const ReportingBannerOperatingStatsFragmentDoc = gql`
    fragment ReportingBannerOperatingStats on OperatingStats {
  startDate
  actualRevenue
  actualExpenses
  actualProduction
  forecastRevenue
  forecastExpenses
  forecastProduction
}
    `;